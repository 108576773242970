import { lazy, Suspense, useState } from 'react'
import { ICreateAffiliateUser } from 'services/user/types'
import { GLazyLoading, GTransition } from '../../../../components/basic-blocks'

const AffiliateSignup = lazy(() =>
	import('./signup')
		.then((module) => {
			return { default: module.AffiliateSignup }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)

const AffiliateEmailVerification = lazy(() =>
	import('./affiliate-email-verification')
		.then((module) => {
			return { default: module.AffiliateEmailVerification }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)

export const AffiliateSetupWizard = () => {
	const [step, setStep] = useState('signup')
	const [data, setData] = useState<ICreateAffiliateUser>({
		email: '',
		first_name: '',
		last_name: '',
		password: '',
		confirm_password: ''
	})

	return (
		<div>
			<GTransition show={step === 'signup'}>
				<Suspense fallback={<GLazyLoading />}>
					<AffiliateSignup setStep={setStep} data={data} setData={setData} />
				</Suspense>
			</GTransition>
			<GTransition show={step === 'email'}>
				<Suspense fallback={<GLazyLoading />}>
					<AffiliateEmailVerification email={data.email} />
				</Suspense>
			</GTransition>
		</div>
	)
}
