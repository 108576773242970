import { useEffect, useState } from 'react'
import { RiQuestionLine } from 'react-icons/ri'
import { twMerge } from 'tailwind-merge'

import { useThemeStore } from 'layout/navbar/store'
import { GTooltip } from '../g-tooltip'
import { GRadioProps } from './types'

export function GRadio<T>(props: GRadioProps<T>) {
	const {
		label,
		description,
		value,
		name,
		checked,
		disabled,
		tooltipPosition = 'top',
		labelClass,
		className,
		inputContainerClassName,
		onChange,
		helpToolTip
	} = props
	const { theme } = useThemeStore()

	const [isChecked, changeIsChecked] = useState(checked ? checked : false)

	useEffect(() => {
		changeIsChecked(checked)
	}, [checked])

	const inputClassName =
		theme === 'dark'
			? 'checked:bg-primary-500 checked:border-primary-500 checked:hover:bg-primary-500 checked:focus:hover:border-primary-500'
			: 'checked:border-primary-500 checked:hover:border-primary-500 checked:focus:border-primary-500'

	return (
		<div className={twMerge('flex items-center', className)}>
			<div className={twMerge('mr-3 -mt-1', inputContainerClassName)}>
				<input
					id={`radio-${name}`}
					name={name}
					type="radio"
					value={value}
					disabled={disabled}
					checked={isChecked}
					onChange={(e) => onChange(e)}
					className={twMerge(
						`
							bg-gray-50 h-4 w-4 text-button border-2 cursor-pointer
							border-gray-300 checked:border-4 disabled:opacity-50 focus:ring-white
						`,
						inputClassName
					)}
				/>
			</div>
			<div>
				<label
					htmlFor={`radio-${name}`}
					className={twMerge(
						'text-md font-medium text-t-dark flex items-center cursor-pointer',
						labelClass
					)}
				>
					{label ? label : ''}
					&nbsp;
					{helpToolTip && (
						<GTooltip
							className="font-normal"
							content={helpToolTip}
							position={tooltipPosition}
						>
							<RiQuestionLine className="w-3 h-3 text-t-secondary" />
						</GTooltip>
					)}
				</label>
				<div className="flex">
					{description && (
						<p className="mt-1 text-sm text-t-secondary">{description}</p>
					)}
				</div>
			</div>
		</div>
	)
}
