import { GRateBadged } from 'components/basic-blocks/g-rate-badge'
import { AverageMrrPanelProps } from './types'

export const AverageMrrPanel = ({ data }: AverageMrrPanelProps) => {
	if (!data) return null

	return (
		<section className="bg-white px-4 py-4 text-t-default divide-y divide-gray-100 rounded mb-6">
			<div className="pb-3">
				<div className="flex items-center mb-3">
					<div className="bg-primary-400 h-3 w-3 rounded-full mr-1" />
					<span className="font-bold text-md">Average MRR</span>
				</div>

				<div className="flex items-center mb-3">
					<span className="font-bold mr-2 text-2xl">
						{data.average_mrr_in_currency}
					</span>
					{data.average_mrr_rate ? (
						<GRateBadged rate={data.average_mrr_rate} />
					) : null}
				</div>

				<div className="text-sm">
					<div className="flex justify-between mb-2">
						<span>Period start MRR</span>
						<span className="font-bold">{data.start_mrr_in_currency}</span>
					</div>
					<div className="flex justify-between">
						<span>Period end MRR</span>
						<span className="font-bold">{data.end_mrr_in_currency}</span>
					</div>
				</div>
			</div>
			<div className="pt-3">
				<div className="text-md">
					<div className="flex justify-between mb-2">
						<span>Average ARR</span>
						<div className="flex items-center">
							<span className="font-bold mr-1">
								{data.average_arr_in_currency}
							</span>
							{data.average_arr_rate ? (
								<GRateBadged rate={data.average_arr_rate} />
							) : null}
						</div>
					</div>
					<div className="flex justify-between mb-2 ">
						<span>Average revenue per user</span>
						<div className="flex items-center">
							<span className="font-bold mr-1">{data.arpu_in_currency}</span>
							{data.avg_per_user_rate ? (
								<GRateBadged rate={data.avg_per_user_rate} />
							) : null}
						</div>
					</div>
					<div className="flex justify-between mb-2 ">
						<span>Lifetime value</span>
						<div className="flex items-center">
							<span className="font-bold mr-1">
								{data.life_time_value_in_currency}
							</span>
							{data.life_time_value_rate ? (
								<GRateBadged rate={data.life_time_value_rate} />
							) : null}
						</div>
					</div>
					<div className="flex justify-between mb-2 ">
						<span>Customer acquisition cost</span>
						<div className="flex items-center">
							<span className="font-bold mr-1">N/A</span>
							{/* {data.customer_acquisition_cost_rate ? (
								<RateBadged rate={data.customer_acquisition_cost_rate} />
							) : null} */}
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
