import { GLoading } from 'components/basic-blocks'
import { useStores, useUrlQuery } from 'hooks'
import { useEffect } from 'react'

export function FacebookOAuthPage() {
	const query = useUrlQuery()
	const { accountStore } = useStores()
	const { createOAuthLink, syncAccountLink } = accountStore

	useEffect(() => {
		;(async () => {
			const token =
				query.long_lived_token || query.access_token || query['#access_token']
			const reAuth = window.localStorage.getItem('facebook-reauth')
			if (reAuth) {
				const accountId = window.localStorage.getItem('account-id')
				await createOAuthLink(token, 'facebook', accountId)

				const linkId = window.localStorage.getItem('link-id')
				if (linkId) await syncAccountLink(linkId)

				window.localStorage.removeItem('account-id')
				window.localStorage.removeItem('facebook-reauth')
				window.localStorage.removeItem('link-id')
			}
			window.localStorage.setItem('facebook_oauth_long_lived_token', token)
			if (window && window.opener)
				window.opener.dispatchEvent(new Event('beforeunload'))
			window.close()
		})()
	}, [])

	return (
		<div className="w-full h-full flex items-center justify-center">
			<GLoading />
		</div>
	)
}
