import { GButton, GDialog } from 'components/basic-blocks'
import { GVirtualizedList } from 'components/basic-blocks/g-virtualized-list'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { useUiStore, useUserStore } from 'store'
import { OfferView } from './offers-view'
import { AdminAccessTypes, hasAdminAccess } from '../../utils/admin'

export const OffersList = (props: { userId?: string; accountId?: string }) => {
	const { userId, accountId } = props
	const { adminStore } = useStores()
	const { offers, findOffers, deleteOffersById, sendOfferEmail } = adminStore
	const isLoading = useUiStore((s) => s.isLoading)
	const loginUser = useUserStore((s) => s.user)

	const [offerId, setOfferId] = useState('')
	const [openDelDialogue, setOpenDelDialogue] = useState(false)
	const query = accountId ? { accountId: accountId } : { userId: userId }

	const handleDeleteOffer = async (id: string) => {
		setOpenDelDialogue(true)
		setOfferId(id)
	}

	const handleDialogClose = () => {
		setOpenDelDialogue(false)
		setOfferId('')
	}

	const handleConfirmDelete = async (offerId: string) => {
		try {
			await deleteOffersById(offerId, {
				...query,
				limit: 10,
				sort: '-created_at'
			})
			handleDialogClose()
		} catch (error) {
			return
		}
	}

	useEffect(() => {
		;(async () => {
			await findOffers({ ...query, limit: 10, sort: '-created_at' })
		})()
	}, [userId, accountId])

	return (
		<div>
			<div className="max-h-72">
				{offers && offers.length > 0 ? (
					<GVirtualizedList length={offers.length}>
						{(index) => {
							return (
								<OfferView
									singleOffer={offers.length === 1}
									offer={offers[index]}
									onDeleteOffer={handleDeleteOffer}
									onSendEmail={sendOfferEmail}
								/>
							)
						}}
					</GVirtualizedList>
				) : (
					<p className="text-t-secondary w-full flex items-center justify-center text-sm mt-4">
						{!hasAdminAccess(loginUser, AdminAccessTypes.view_offers)
							? 'You do not have permission to view offers'
							: 'No offers to display'}
					</p>
				)}
			</div>
			<GDialog
				title={'Delete offer'}
				subtitle={'Are you sure you want to delete this offer'}
				open={openDelDialogue}
				onClose={handleDialogClose}
			>
				<div className="mt-10 sm:mt-11 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense space-x-3 flex justify-end">
					<GButton onClick={handleDialogClose}>Cancel</GButton>
					<GButton
						label="Confirm"
						color="primary"
						onClick={() => handleConfirmDelete(offerId)}
						loading={isLoading('ADMIN_DELETE_OFFER')}
					/>
				</div>
			</GDialog>
		</div>
	)
}
