import { PageHeader } from '../../../components/page-header'
import { Outlet, useLocation } from 'react-router-dom'
import { lazy, Suspense, useMemo } from 'react'
import { useUserStore } from '../../../store'
import { Tabs } from '../../../components/tabs'
import { GLazyLoading } from '../../../components/basic-blocks'

const AffiliateSetting = lazy(() =>
	import('./setting/setting')
		.then((module) => {
			return { default: module.AffiliateSetting }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)

export const affiliatePageHeaderData = [
	{
		key: 'dashboard',
		title: 'Affiliate',
		subtitle: 'Explore the performance of your affiliate link '
	},
	{
		key: 'about',
		title: 'About ClickGUARD’s Affiliate Program',
		subtitle: 'Explore the perks of participating in our program'
	},
	{
		key: 'resources',
		title: 'Resources to supercharge your affiliate journey',
		subtitle:
			'Find what you need so you can start sharing your affiliate link with maximum results'
	},
	{
		key: 'legal',
		title: 'ClickGUARD’s Affiliate Program- Terms and Conditions',
		subtitle:
			'By participating in our affiliate program, you are agreeing to the following: '
	},
	{
		key: 'settings',
		title: 'Affiliate',
		subtitle:
			'Refer new customers to ClickGUARD to earn commission and other benefits'
	}
]

export const AffiliateIndex = () => {
	const user = useUserStore((state) => state.user)
	const pathname = useLocation().pathname.split('/')[2].toLowerCase()
	const getPageHeaderData = useMemo(() => {
		return (
			affiliatePageHeaderData.find((item) => item.key.includes(pathname)) ||
			affiliatePageHeaderData[0]
		)
	}, [pathname])
	return (
		<>
			{user?.affiliate_profile?.affiliate_id && pathname !== 'dashboard' && (
				<PageHeader
					title={getPageHeaderData?.title || ''}
					subtitle={getPageHeaderData?.subtitle || ''}
				/>
			)}
			{!user?.affiliate_profile?.affiliate_id && (
				<PageHeader
					title="Affiliate"
					subtitle="Refer new customers to ClickGUARD to earn commission and other benefits"
				/>
			)}
			<div className="px-8">
				{!user?.affiliate_profile?.affiliate_id ? (
					<div>
						<div className="flex justify-between items-center gap-x-6 mb-6">
							<div className="w-full">
								<Tabs
									current={''}
									tabs={[
										{
											label: 'Set up your Affiliate Account',
											key: '',
											to: () => '#'
										}
									]}
								/>
							</div>
						</div>
						<Suspense fallback={<GLazyLoading />}>
							<AffiliateSetting />
						</Suspense>
					</div>
				) : (
					<Outlet />
				)}
			</div>
		</>
	)
}
