import { GLoading } from 'components/basic-blocks'
import { MainLayout } from 'layout'
import { AdminHome } from 'pages/admin/home-page'
import React, { useEffect, useMemo, useState } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import {
	useAccountStore,
	useAdminStore,
	useAssetStore,
	useUserStore
} from 'store'
import { publicRoutes } from 'utils/public-routes'
import { useShallow } from 'zustand/react/shallow'
import { isNoClickGuardAffiliate } from '../utils/user'
import { AdminAccessTypes, hasAdminAccess } from '../utils/admin'
import {
	getFromLocalStorage,
	removeFromLocalStorage,
	setItemsToLocalStorage
} from '../utils'
import { AppBase } from './app-base'
import { DashboardPage } from './workspace/asset/dashboard'
import { PageNotFound } from './pagenotfound'
import { AdminBase } from './admin/admin-base'
import { AdminUserPage } from './admin/entites/user/user-page'
import { AdminAccountsPage } from './admin/entites/accounts'
import { ProfileSetting } from './user/settings/profile-setting'
import { SecuritySetting } from './user/settings/security-setting'
import { AssetBase } from './workspace/asset/asset-base'
import { NotificationSetting } from './user/settings/notification-setting'
import { Settings } from './user/settings/settings'
import { OffersSetting } from './user/settings/offers-setting'
import { ClicksPage } from './workspace/asset/clicks'
import { BlacklistPage } from './workspace/asset/blacklist'
import { ExclusionsPage } from './workspace/asset/exclusions'
import { AnalyticsPage } from './workspace/asset/analytics/anlytics-page'
import { AdminAccountPage } from './admin/entites/account/account-page'
import { AdminPPCAccountPage } from './admin/entites/ppc-account-page'
import { AdminPPCAccountsPage } from './admin/entites/ppc-accounts-page'
import { AdminUsersPage } from './admin/entites/users'
import { AdminWebsitePage } from './admin/entites/website-page'
import { AdminWebsitesPage } from './admin/entites/websites-page'
import { AdminMetricsPage } from './admin/metrics'
import { AdminDomainsPage } from './admin/references/domains-page'
import { AdminPPCAccountsReferencesPage } from './admin/references/ppc-accounts-references-page'
import { AdminRevenuePage } from './admin/revenue'
import { PpcAccountSetting } from './workspace/asset/ppc-accounts-page/setting/ppc-account-setting'
import { ProtectionPage } from './workspace/asset/protection'
import { ReportsPage } from './workspace/asset/reports'
import { AssetSettingsPage } from './workspace/asset/settings-page'
import { TrackingSection } from './workspace/asset/settings-page/tracking-section'
import { ThreatPage } from './workspace/asset/threat/threat-page'
import { BillingPage } from './workspace/billing'
import { PPCAccessPage } from './workspace/ppc-access-page'
import { PreferencesSettingsPage } from './workspace/preferences/settings-page'
import { TeamAccessPage } from './workspace/team-access'
import { AffiliateResources } from './user/affiliate/resources/resources'
import { AffiliateIndex } from './user/affiliate/affiliate-index'
import { NonClickGuardAffiliateDashboard } from './user/affiliate/dashboard/non-clickguard-dashboard'
import { AffiliateLegal } from './user/affiliate/legal/legal'
import { AffiliateAbout } from './user/affiliate/about/about'
import { AffiliateSetting } from './user/affiliate/setting/setting'
import { Affiliate } from './user/affiliate/view'
import { AdminTeam } from './admin/team'
import { LegacyUser } from './admin/legacy-user/legacy-user'

export const MainPage = () => {
	const navigate = useNavigate()

	const { account, setAccount, wantsToSeeDemoData, accounts } =
		useAccountStore(
			useShallow((s) => ({
				account: s.account,
				setAccount: s.setAccount,
				wantsToSeeDemoData: s.wantsToSeeDemoData,
				accounts: s.accounts,
				getAllAccounts: s.getAllAccounts
			}))
		)
	const { asset, setAsset, setAssetForId, setDemoMode, allAssets } =
		useAssetStore(
			useShallow((s) => ({
				asset: s.asset,
				setAsset: s.setAsset,
				setAssetForId: s.setAssetForId,
				setDemoMode: s.setDemoMode,
				allAssets: s.allAssets
			}))
		)
	const imperId = useAdminStore((s) => s.imperId)
	const user = useUserStore((s) => s.user)

	const [loading, setLoading] = useState(true)

	const path = window.location.pathname.split('/')
	const { accountId, assetId, isPublicRoute, isInSettings } = useMemo(() => {
		const accountId =
			path.filter(
				(link) => link.startsWith('acc') || link === 'workspace-demo'
			)[0] || getFromLocalStorage('selected_account')
		const assetId =
			path.filter(
				(link) => link.startsWith('ast') || link === 'website-demo'
			)[0] || getFromLocalStorage('selected_asset')
		const isInSettings = path.includes('settings')
		const isPublicRoute = publicRoutes.find((route) =>
			window.location.pathname.startsWith(route)
		)
		return { accountId, assetId, isPublicRoute, isInSettings }
	}, [path])

	useEffect(() => {
		async function checkWorkspace() {
			// await Promise.all([accounts, getAllAccounts()])
			if (wantsToSeeDemoData || path[1] === 'admin' || isPublicRoute) {
				setLoading(false)
				return
			}
			if (isNoClickGuardAffiliate(user)) {
				setLoading(false)
				return
			}
			const noAccounts = !accounts || accounts.length === 0
			if (noAccounts && !imperId) {
				navigate('/start/standard')
				setLoading(false)
				return
			}

			if (accountId === 'workspace-demo') {
				setDemoMode(true)
				setAccount(null)
				await setAssetForId('workspace-demo', 'website-demo')
				setLoading(false)
				return
			}

			const selectedAccount = accountId
				? accounts.find((a) => a.id === accountId)
				: accounts[0]

			if (!selectedAccount) {
				setAccount(null)
				setAsset(null)
				setLoading(false)
				removeFromLocalStorage(['selected_asset', 'selected_account'])
				navigate('page-not-found')
				return
			}

			setAccount(selectedAccount)

			if (
				selectedAccount?.status === 'SETUP' &&
				selectedAccount.setup?.step !== 'subscription' &&
				!user?.admin &&
				!isInSettings
			) {
				setLoading(false)
				navigate(`/setup/${selectedAccount.id}`)
				return
			}

			if (assetId === 'website-demo') {
				setLoading(false)
				await setAssetForId('workspace-demo', 'website-demo')
				return
			}

			const workspaceWebsites = allAssets.filter(
				(a) => a.account_id === selectedAccount.id
			)

			if (workspaceWebsites.length === 0) {
				setLoading(false)
				setDemoMode(true)
				await setAssetForId('workspace-demo', 'website-demo')
				return
			}

			const selectedWebsite = assetId
				? workspaceWebsites?.find((a) => a.id === assetId)
				: workspaceWebsites[0]

			if (!selectedWebsite) {
				setLoading(false)
				setAsset(null)
				removeFromLocalStorage(['selected_asset', 'selected_account'])
				navigate('asset-not-found')
				return
			}

			setAsset(selectedWebsite)
			await setAssetForId(selectedWebsite.account_id, selectedWebsite.id)
			setItemsToLocalStorage([
				{ key: 'selected_asset', value: selectedWebsite.id },
				{ key: 'selected_account', value: selectedWebsite.account_id }
			])
			setLoading(false)
		}

		checkWorkspace()
	}, [])

	if (loading) {
		return (
			<MainLayout>
				<GLoading />
			</MainLayout>
		)
	}

	return (
		<MainLayout>
			<Routes>
				<Route path="/" element={<AppBase />}>
					<Route
						index
						element={
							<Navigate
								to={`workspace/${account?.id || 'workspace-demo'}/asset/${
									asset?.id || 'website-demo'
								}`}
								replace
							/>
						}
					/>
					<Route path="/affiliate" element={<AffiliateIndex />}>
						<Route path="resources" element={<AffiliateResources />} />
						<Route
							path="dashboard"
							element={<NonClickGuardAffiliateDashboard />}
						/>
						<Route path="legal" element={<AffiliateLegal />} />
						<Route path="about" element={<AffiliateAbout />} />
						<Route path="setting" element={<AffiliateSetting />} />
					</Route>
					<Route path="workspace/affiliate" element={<Affiliate />} />
					<Route path="*" element={<PageNotFound />} />
					<Route path="/settings" element={<Settings />}>
						<Route
							path="workspace/:accountId/asset/:assetId/protection"
							element={<ProtectionPage />}
						/>
						<Route
							path="workspace/:accountId/asset/:assetId/site-preference"
							element={<AssetSettingsPage />}
						/>
						<Route
							path="workspace/:accountId/asset/:assetId/tracking-code"
							element={<TrackingSection />}
						/>
						<Route
							path="workspace/:accountId/asset/:assetId/ppc-accounts"
							element={<PpcAccountSetting />}
						/>
						<Route
							path="workspace/:accountId/preferences"
							element={<PreferencesSettingsPage />}
						/>
						<Route
							path="workspace/:accountId/team-access"
							element={<TeamAccessPage />}
						/>
						<Route
							path="workspace/:accountId/billing"
							element={<BillingPage />}
						/>{' '}
						<Route
							path="workspace/:accountId/connections"
							element={<PPCAccessPage />}
						/>
						<Route path="user/notification" element={<NotificationSetting />} />
						<Route path="user/profile" element={<ProfileSetting />} />
						<Route path="user/offers" element={<OffersSetting />} />
						<Route path="user/security" element={<SecuritySetting />} />
					</Route>
					<Route
						path="/workspace/:accountId/asset/:assetId"
						element={<AssetBase />}
					>
						<Route index element={<DashboardPage />} />
						<Route path="clicks" element={<ClicksPage />} />
						<Route path="exclusions" element={<ExclusionsPage />} />
						<Route path="reports" element={<ReportsPage />} />
						<Route path="blacklist" element={<BlacklistPage />} />
						<Route path="threat" element={<ThreatPage />} />
						<Route path="analytics" element={<AnalyticsPage />} />
					</Route>
				</Route>
				<Route path="/admin" element={<AdminBase />}>
					<Route index element={<AdminHome />} />
					{hasAdminAccess(user, AdminAccessTypes.view_metrics) ? (
						<>
							<Route path="metrics" element={<AdminMetricsPage />} />
							<Route path="revenue" element={<AdminRevenuePage />} />
						</>
					) : (
						<></>
					)}
					{hasAdminAccess(user, AdminAccessTypes.view_cg_staff) ? (
						<Route path="team" element={<AdminTeam />} />
					) : (
						<></>
					)}

					<Route path="legacy" element={<LegacyUser />} />
					<Route path="users" element={<AdminUsersPage />} />
					<Route path="user/:userId" element={<AdminUserPage />} />
					<Route path="subscriptions" element={<AdminAccountsPage />} />
					<Route
						path="subscription/:accountId"
						element={<AdminAccountPage />}
					/>
					<Route path="websites" element={<AdminWebsitesPage />} />
					<Route path="website/:assetId" element={<AdminWebsitePage />} />
					<Route path="ppc-accounts" element={<AdminPPCAccountsPage />} />
					<Route
						path="ppc-account/:ppcAccountId"
						element={<AdminPPCAccountPage />}
					/>
					<Route path="references/domains" element={<AdminDomainsPage />} />
					<Route
						path="references/ppc-accounts"
						element={<AdminPPCAccountsReferencesPage />}
					/>
				</Route>
				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</MainLayout>
	)
}
