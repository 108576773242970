import { RiQuestionLine } from 'react-icons/ri'

import { GTooltip } from 'components/basic-blocks'
import { LineChart } from 'components/charts/line-chart'

import { TimelineProps } from './types'

const colors = [
	{ color: 'primary', shade: 400 },
	{ color: 'green', shade: 400 },
	{ color: 'red', shade: 400 },
	{ color: 'yellow', shade: 400 }
]

export const Timeline = ({ data, formatter }: TimelineProps) => {
	return (
		<section className="bg-white px-4 py-4 text-t-default divide-gray-100 rounded mb-6">
			<div className="text-t-default text-md flex flex-row items-center gap-x-1">
				<span className="font-bold">Timeline</span>
				<GTooltip
					content={
						<div className="flex flex-col">
							<span>Timeline showing daily values, includes average MRR,</span>
							<span>gainer MRR, churned MRR and net change for each day.</span>
						</div>
					}
				>
					<RiQuestionLine className="w-4 h-4 text-t-dark" />
				</GTooltip>
			</div>
			<LineChart data={data} colors={colors} formatter={formatter} negative />
		</section>
	)
}
