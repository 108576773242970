import { AssetDTO } from 'api-models'
import { EventsList } from 'components/events-list'
import { InfoSection } from './info-section'
import { PPCAccountSection } from './ppc-account-section'
import { ProtectionSection } from './protection-section'

export const InfoTab = (props: { asset: AssetDTO }) => {
	const { asset } = props
	return (
		<div className="pt-2">
			<div className="flex flex-wrap gap-6">
				<div className="flex-1">
					<InfoSection asset={asset} />
					<ProtectionSection asset={asset} />
				</div>
				<div className="flex flex-col flex-1">
					<EventsList id={asset.id} type="website" />
					<PPCAccountSection asset={asset} />
				</div>
			</div>
		</div>
	)
}
