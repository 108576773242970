import { GButton, GDialog } from 'components/basic-blocks'
import { MCCLinkConnectionType } from './types'
import { useAccountStore, useAssetStore, useUiStore } from '../../store'
import { useShallow } from 'zustand/react/shallow'
import { useEffect } from 'react'

export const MccLinkConnection = ({
	open,
	onClose,
	adAccount,
	heading,
	state
}: MCCLinkConnectionType) => {
	const account = useAccountStore((a) => a.account)
	const { skipMccSupport, sendMccLinkRequest } = useAssetStore(
		useShallow((s) => ({
			skipMccSupport: s.skipMccSupport,
			sendMccLinkRequest: s.sendMccLinkRequest
		}))
	)

	const isLoading = useUiStore((s) => s.isLoading)

	const handleSkip = async () => {
		if (!adAccount || !account) return
		await skipMccSupport(account.id, adAccount.id)
		onClose()
	}
	const handleMccLinkRequest = async () => {
		if (!adAccount || !account) return
		await sendMccLinkRequest(account.id, adAccount.id)
		onClose()
	}

	useEffect(() => {
		if (adAccount?.details.mcc_support_connection !== 'PENDING') {
			onClose()
		}
	}, [adAccount])

	return (
		<>
			<GDialog title={heading} open={open} onClose={onClose} maxWidth="xl">
				<div className="flex flex-col gap-2 font-medium text-t-secondary pb-6">
					<p>
						{state === 'CONNECTION'
							? 'The Google Ads account was successfully added.'
							: 'Congratulations! Your workspace was successfully activated.'}
					</p>
					<p>
						{state === 'CONNECTION'
							? "To unlock advanced support from ClickGUARD, you can connect your recently added Google Ads account to ClickGUARD's Manager Account (MCC)."
							: "To unlock advanced support from ClickGUARD, you can connect your Google Ads account to ClickGUARD's Manager Account (MCC)."}
					</p>
					<p>
						If you choose this option, a link request will be sent to the email
						address linked to your Google Ads account, Simply accept the
						request, and you're all set!
					</p>
				</div>
				<div className="flex items-center justify-between">
					<GButton
						variant="contained"
						label="Skip"
						color="neutral"
						loading={
							isLoading('SKIP_AD_ACCOUNT_MCC_LINK_REQUEST') ||
							isLoading('AD_ACCOUNT_MCC_LINK_REQUEST')
						}
						className="h-[30px] min-w-[116px] border-t-input-border"
						labelClassName="font-bold text-base"
						onClick={handleSkip}
					/>
					<GButton
						variant="contained"
						label={'Send Link Request'}
						color="primary"
						loading={
							isLoading('SKIP_AD_ACCOUNT_MCC_LINK_REQUEST') ||
							isLoading('AD_ACCOUNT_MCC_LINK_REQUEST')
						}
						className="h-[30px] min-w-[116px] border-t-input-border"
						labelClassName="font-bold text-base"
						onClick={handleMccLinkRequest}
					/>
				</div>
			</GDialog>
		</>
	)
}
