/* This example requires Tailwind CSS v2.0+ */
import { VisualPreferencesItem } from 'api-models/src'
import { GNewTable } from 'components/basic-blocks'
import { PageHeader } from 'components/page-header'
import { TableAvailableColumns } from 'components/table-available-columns'
import { useStores } from 'hooks'
import { useContext, useEffect, useMemo, useState } from 'react'
import { RiLoader5Line, RiRefreshLine } from 'react-icons/ri'
import { useSearchParams } from 'react-router-dom'
import { useUiStore } from 'store'
import { PageTitle } from 'utils/page-title'
import { AccountPageFiltersContext } from './context'
import { Filters } from './filters'
import { columns as NewColumns } from './new-columns'
import { QuickViews } from './quick-views'
import { AccountsReference, State } from './types'

const PAGE_SIZE = 10

const querySelector = (key: string) => {
	if (key === 'all') return {}
	if (key === 'active')
		return { subscription_status: 'ACTIVE', subscription_type: 'STANDARD' }
	if (key === 'setup') return { account_status: 'SETUP' }
	if (key === 'inactive') return { account_status: 'INACTIVE' }
	if (key === 'trial')
		return { subscription_status: 'ACTIVE,PENDING', subscription_type: 'TRIAL' }
	if (key === 'cancelled trials')
		return {
			subscription_status: 'CANCELLED',
			subscription_type: 'TRIAL'
		}
	if (key === 'cancelled') return { subscription_status: 'CANCELLED' }
	if (key === 'offer requests') return { offer_request: 'true' }
	if (key === 'cancelling') return { subscription_status: 'CANCELLING' }
	if (key === 'limit breached') return { limit_breached: 'true' }
	if (key === 'limit breach soon') return { limit_breached: 'soon' }

	return {}
}

export const AdminAccountsPageView = () => {
	const { filters, selectedAdAccount, selectedCampaign, quickView } =
		useContext(AccountPageFiltersContext)
	const { adminStore, userStore } = useStores()
	const { visualPreferences } = userStore
	const isLoading = useUiStore((s) => s.isLoading)
	const { loadAccounts } = adminStore
	const [search] = useSearchParams()

	const [state, setState] = useState<State>({ pageSize: 10, skip: 0 })
	const [query, setQuery] = useState<Record<string, any>>(
		querySelector(quickView.key || 'all')
	)
	const [accountData, setAccountData] = useState<AccountsReference>({
		total: 0,
		data: []
	})

	const [selectedColumns, setSelectedColumns] = useState(new Set<string>())
	const availableColumns = NewColumns.map((c) => ({
		key: c.key,
		label: c.label
	}))

	const pageListener = (page: number) => {
		setState({ ...state, skip: page })
	}

	const queryFilters = {
		limit: state.pageSize,
		skip: state.skip,
		...filters
	}

	useEffect(() => {
		;(async () => {
			try {
				const currentQuery = query
					? { ...query, ...queryFilters }
					: { ...queryFilters }
				const data = await loadAccounts(currentQuery)
				data && setAccountData(data || { total: 0, data: [] })
			} catch (err) {
				return
			}
		})()
	}, [query, state.skip])

	useEffect(() => {
		setQuery(querySelector('all'))
		setState({ ...state, skip: 0 })
	}, [search])

	useEffect(() => {
		setQuery(querySelector(quickView.key || 'all'))
		setState({ ...state, skip: 0 })
	}, [quickView])

	const handleSort = (key: string) => {
		setState({ ...state, skip: 0 })
		setQuery({ ...query, sort: key })
	}

	useEffect(() => {
		try {
			if (!visualPreferences) {
				setSelectedColumns(new Set(availableColumns.map((c) => c.key)))
				return
			}

			const { columns } = JSON.parse(visualPreferences).find(
				(v: VisualPreferencesItem) => v.page === 'admin_workspaces'
			)
			if (columns) {
				setSelectedColumns(new Set(columns))
			} else {
				setSelectedColumns(new Set(availableColumns.map((c) => c.key)))
			}
		} catch (e) {
			setSelectedColumns(new Set(availableColumns.map((c) => c.key)))
		}
	}, [selectedAdAccount, selectedCampaign])

	const memoData = useMemo(
		() => accountData?.data || [],
		[accountData?.data, accountData?.total]
	)
	const memoColumns = useMemo(
		() => NewColumns.filter((c) => selectedColumns.has(c.key)),
		[selectedColumns]
	)

	return (
		<>
			<PageTitle pageName="Workspaces" />
			<PageHeader
				title="Workspaces"
				subtitle="Workspaces created and managed by ClickGuard users"
			/>
			<main className="mx-6 border border-gray-100 rounded overflow-clip px-">
				<>
					<div className="flex justify-between bg-white py-4 px-6">
						<div className="flex justify-center items-center">
							<QuickViews />
							<Filters />
						</div>
						<div className="flex gap-2 items-start">
							<button
								className="w-[99px] h-[30px] font-bold text-t-default bg-white py-2 px-4 flex items-center justify-center rounded-md border border-gray-200 hover:bg-gray-100 focus:outline-none"
								onClick={() => loadAccounts(query)}
							>
								{isLoading('ADMIN_GET_ACCOUNTS') ? (
									<RiLoader5Line className="animate-spin !shadow-none !bg-transparent border-none pointer-events-none h-4 w-4" />
								) : (
									<>
										<RiRefreshLine className="h-3.5 w-3.5 text-t-default mr-1.5" />
										Refresh
									</>
								)}
							</button>

							<TableAvailableColumns
								availableColumns={availableColumns}
								selectedColumns={selectedColumns}
								setSelectedColumns={setSelectedColumns}
								assetId={''}
								page="admin_workspaces"
							/>
						</div>
					</div>
					<GNewTable
						isLoading={isLoading('ADMIN_GET_ACCOUNTS')}
						data={memoData}
						columns={memoColumns}
						pageSize={PAGE_SIZE}
						pageListener={(e: number) => pageListener(e)}
						sortListener={handleSort}
						totalCount={accountData?.total || 0}
						paginationClassName="mt-0 py-4 px-6"
						sort={query.sort}
					/>
				</>
			</main>
		</>
	)
}
