import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useStores } from '../../../hooks'
import { MenuLink } from '../../../layout/navbar/menu-link'

export const Settings = () => {
	const {
		assetStore,
		accountStore,
		billingStore: { offers }
	} = useStores()
	const { asset, demoMode } = assetStore
	const { account, setWantsToSeeDemoData } = accountStore
	const accountId = demoMode && !account?.id ? 'workspace-demo' : account?.id
	const assetId = demoMode && !asset?.id ? 'website-demo' : asset?.id
	const isOffersAvailable = offers.length > 0

	useEffect(() => {
		if (!account?.id) {
			setWantsToSeeDemoData(true)
		} else {
			setWantsToSeeDemoData(false)
		}
		return () => {
			setWantsToSeeDemoData(false)
		}
	}, [account])

	return (
		<div className="flex">
			<nav className="pt-[100px] space-y-4 bg-background-semi min-w-[170px] fixed h-full bg-opacity-50 border-r border-solid border-gray-100 overflow-y-scroll overflow-x-hidden top-0 bottom-0">
				<div className="space-y-2">
					<div
						className={`pl-6 text-sm mt-3 pb-2 ${
							assetId === 'website-demo'
								? 'text-t-tertiary'
								: 'text-t-secondary'
						}`}
					>
						WEBSITE
					</div>
					<MenuLink
						disabled={
							assetId === 'website-demo' ||
							(account?.setup?.step !== 'done' &&
								!account?.billing?.subscription)
						}
						className="px-3"
						label="Rules"
						highlightLabel={true}
						to={() =>
							`/settings/workspace/${accountId}/asset/${assetId}/protection`
						}
					/>
					<MenuLink
						disabled={
							assetId === 'website-demo' ||
							(account?.setup?.step !== 'done' &&
								!account?.billing?.subscription)
						}
						className="px-3"
						label="Preferences"
						highlightLabel={true}
						to={() =>
							`/settings/workspace/${accountId}/asset/${assetId}/site-preference`
						}
					/>
					<MenuLink
						disabled={
							assetId === 'website-demo' ||
							(account?.setup?.step !== 'done' &&
								!account?.billing?.subscription)
						}
						className="px-3"
						label="Tracking code"
						highlightLabel={true}
						to={() =>
							`/settings/workspace/${accountId}/asset/${assetId}/tracking-code`
						}
					/>
					<MenuLink
						disabled={
							assetId === 'website-demo' ||
							(account?.setup?.step !== 'done' &&
								!account?.billing?.subscription)
						}
						className="px-3"
						label="PPC accounts"
						highlightLabel={true}
						to={() =>
							`/settings/workspace/${accountId}/asset/${assetId}/ppc-accounts`
						}
					/>
				</div>
				<div className="pt-4 space-y-2">
					<div
						className={`pl-6 text-sm mt-3 pb-2 ${
							accountId === 'workspace-demo'
								? 'text-t-tertiary'
								: 'text-t-secondary'
						}`}
					>
						WORKSPACE
					</div>
					<MenuLink
						disabled={accountId === 'workspace-demo'}
						className="px-3"
						label="Preferences"
						highlightLabel={true}
						to={() => `/settings/workspace/${accountId}/preferences`}
					/>
					<MenuLink
						disabled={accountId === 'workspace-demo'}
						className="px-3"
						label="Connections"
						highlightLabel={true}
						to={() => `/settings/workspace/${accountId}/connections`}
					/>
					<MenuLink
						disabled={accountId === 'workspace-demo'}
						className="px-3"
						label="Team access"
						highlightLabel={true}
						to={() => `/settings/workspace/${accountId}/team-access`}
					/>
					<MenuLink
						disabled={accountId === 'workspace-demo'}
						className="px-3"
						label="Billing"
						highlightLabel={true}
						to={() => `/settings/workspace/${accountId}/billing`}
					/>
				</div>
				<div className="pt-4 space-y-2">
					<div className="text-t-secondary pl-6 text-sm mt-3 pb-2">
						PERSONAL
					</div>
					<MenuLink
						className="px-3"
						label={
							<div className="flex gap-1 items-center">
								Offers{' '}
								{isOffersAvailable && (
									<span className="bg-red-100 px-2.5 h-[23px] rounded-md text-red-700">
										!
									</span>
								)}
							</div>
						}
						highlightLabel={true}
						to={() => '/settings/user/offers'}
					/>
					<MenuLink
						className="px-3"
						label="Profile"
						highlightLabel={true}
						to={() => '/settings/user/profile'}
					/>
					<MenuLink
						className="px-3"
						label="Notifications"
						highlightLabel={true}
						to={() => '/settings/user/notification'}
					/>
					<MenuLink
						className="px-3"
						label="Security"
						highlightLabel={true}
						to={() => '/settings/user/security'}
					/>
				</div>
			</nav>
			<div className="ml-[175px] w-full">
				<Outlet context={{ accountId: accountId, assetId: assetId }} />
			</div>
		</div>
	)
}
