import { useAuth0 } from '@auth0/auth0-react'
import { GLoading } from 'components/basic-blocks'
import { useStores, useUrlQuery } from 'hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Users } from 'services/user'
import { JoinWorkspaceState } from './types'
import { ExistingUserView } from './existing-user-view'

export const JoinWorkspacePage = () => {
	const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
	const { userStore, accountStore, assetStore } = useStores()
	const { user } = userStore

	const navigate = useNavigate()
	const { code } = useUrlQuery()

	const [state, setState] = useState<JoinWorkspaceState>({
		invite: null
	})

	const load = async () => {
		if (code) {
			try {
				const _invite = await Users.getUserAccessInvite(code)
				setState({ invite: _invite })
			} catch (err) {
				toast.error('Your invitation is invalid!')
				navigate('/')
			}
		}
	}

	useEffect(() => {
		if (user) {
			load()
		}
	}, [user, code])

	const goToLogin = () => {
		loginWithRedirect({
			appState: {
				returnTo: window.location.pathname + window.location.search
			}
		})
	}

	useEffect(() => {
		!isAuthenticated && goToLogin()
	}, [isAuthenticated])

	const acceptInvite = async () => {
		try {
			await userStore.acceptInvite(code)
			await accountStore.getAllAccounts()
			await assetStore.getAllAssets()
			const account = accountStore.accounts.find(
				(a) => a.id === state.invite?.account_id
			)
			const accountWithAsset = assetStore.accountsWithAssets.find(
				(a) => a.id === state.invite?.account_id
			)
			if (account) {
				const assetId = accountWithAsset?.assets?.length
					? accountWithAsset.assets[0]?.id
					: 'website-demo'
				accountStore.setAccount(account)
				navigate(`/workspace/${state.invite?.account_id}/asset/${assetId}`)
			} else {
				navigate('/')
			}
		} catch (err) {
			//
		}
	}

	if (isLoading) {
		return <GLoading />
	}

	if (!user) {
		return <GLoading />
	}

	return <ExistingUserView state={state} user={user} accept={acceptInvite} />
}
