import {
	AdAccountDTO,
	AssetDTO,
	BingAdsAdAccountDTO,
	FacebookAdsAdAccountDTO,
	GoogleAdsAdAccountDTO
} from 'api-models'

import { GSection } from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'
import { InfoItemGroup } from 'components/basic-blocks/g-info-table'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useUiStore } from 'store'
import { removeFadPrefix } from 'utils'
import { PlatformIcon } from '../../../../../../components/platform-icon'

type AdAccountsData = {
	total: number
	data: AdAccountDTO[]
}

export const PPCAccountSection = (props: { asset: AssetDTO }) => {
	const { asset } = props
	const { adminStore } = useStores()
	const { getAdAccounts } = adminStore
	const isLoading = useUiStore((s) => s.isLoading)

	const [adAccounts, setAdAccounts] = useState<AdAccountsData>({
		total: 0,
		data: []
	})

	useEffect(() => {
		;(async () => {
			const query = { asset_id: asset.id }
			const data = await getAdAccounts(query)
			data && setAdAccounts(data)
		})()
	}, [asset])

	return (
			<GSection loading={isLoading('ADMIN_GET_ADACCOUNTS')}>
				<InfoItemGroup
					title="PPC accounts"
					className="-mt-5 border-t-0 rounded-t-lg"
				>
					<AdAccountsList adAccounts={adAccounts.data} />
				</InfoItemGroup>
			</GSection>
	)
}

const AdAccountsList = (props: { adAccounts: AdAccountDTO[] }) => {
	const { adAccounts } = props
	if (adAccounts.length === 0)
		return (
			<div className="py-4 flex w-full items-center justify-center -mb-5">
				No ad-account available
			</div>
		)
	return (
		<div className="-mb-5 divide-y max-h-72 overflow-auto">
			{adAccounts.map((adAccount) => {
				return <AdAccountItem adAccount={adAccount} key={adAccount.id} />
			})}
		</div>
	)
}

const AdAccountItem = (props: { adAccount: AdAccountDTO }) => {
	const { adAccount } = props
	if (adAccount.platform === 'GADS') {
		const platformAdAccount = adAccount as GoogleAdsAdAccountDTO
		return <AdAccount platformAdAccount={platformAdAccount} />
	}
	if (adAccount.platform === 'FADS') {
		const platformAdAccount = adAccount as FacebookAdsAdAccountDTO
		return <AdAccount platformAdAccount={platformAdAccount} />
	}
	if (adAccount.platform === 'BADS') {
		const platformAdAccount = adAccount as BingAdsAdAccountDTO
		return <AdAccount platformAdAccount={platformAdAccount} />
	}
	return <div>Unknown</div>
}

const AdAccount = (props: {
	platformAdAccount:
		| GoogleAdsAdAccountDTO
		| FacebookAdsAdAccountDTO
		| BingAdsAdAccountDTO
}) => {
	const { platformAdAccount } = props
	return (
		<div className="flex justify-between items-center w-full px-6">
			<div className="flex justify-start items-center space-x-4 py-4">
				<PlatformIcon platform={platformAdAccount.platform} />
				<div className="flex flex-col justify-start items-start">
					<NavLink to={`/admin/ppc-account/${platformAdAccount.id}`}>
						<div className=" font-medium text-sm text-primary-500">
							{platformAdAccount.name || 'Name: N/A'}
						</div>
					</NavLink>
					<div className="text-xs  text-t-secondary">
						{removeFadPrefix(platformAdAccount.details.account_number) ||
							'Account Number: N/A'}
					</div>
				</div>
			</div>
			<div className="flex gap-2">
				<div className="w-fit h-fit">
					{platformAdAccount.status === 'ACTIVE' ? (
						<GBadge text={platformAdAccount.status} color="green" />
					) : (
						<GBadge text={platformAdAccount.status} color="red" />
					)}
				</div>
				<div className="w-fit h-fit">
					{platformAdAccount.protection && (
						<GBadge text="Protection override" color="primary" />
					)}
				</div>
			</div>
		</div>
	)
}
