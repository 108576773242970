import { Dialog, Transition } from '@headlessui/react'
import { Fragment, ReactNode } from 'react'
import { RiCloseLine } from 'react-icons/ri'

type DrawerProps = {
	children: ReactNode
	isOpen: boolean
	label?: string
	subLabel?: string
	handleClose: VoidFunction
	headerClassName?: string
	labelClassName?: string
}

export default function Drawer({
	children,
	isOpen,
	handleClose,
	label,
	subLabel,
	headerClassName,
	labelClassName
}: DrawerProps) {
	return (
		<Transition show={isOpen} as={Fragment}>
			<Dialog
				unmount={false}
				onClose={handleClose}
				className="fixed z-20 inset-y-0 right-0 overflow-y-auto"
			>
				<div className="flex h-screen">
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-in duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-30"
						entered="opacity-30"
						leave="transition-opacity ease-out duration-300"
						leaveFrom="opacity-30"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="z-40 fixed inset-0 bg-black/30" />
					</Transition.Child>

					<Transition.Child
						as={Fragment}
						enter="transition ease-in-out duration-300 transform"
						enterFrom="-translate-x"
						enterTo="translate-x-0"
						leave="transition ease-in-out duration-300 transform"
						leaveFrom="-translate-x-0"
						leaveTo="translate-x-full"
					>
						<div
							className={`${headerClassName} z-50 relative bg-white w-max overflow-y-scroll text-left align-middle shadow-xl py-8`}
						>
							{label && (
								<div
									className={`absolute text-drawer-text-color font-bold text-2xl left-[32px] top-[24px] ${labelClassName}`}
								>
									{label}
									{subLabel && (
										<p className="text-t-secondary text-base font-normal">
											{subLabel}
										</p>
									)}
								</div>
							)}
							<button
								className="absolute right-[32px] top-[24px]"
								onClick={handleClose}
							>
								<RiCloseLine size="24" />
							</button>
							<div className="pt-6"> {children}</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	)
}
