import { ReactNode, useState } from 'react'
import { NavItem } from '../sidebar/nav-item'
import {
	RiDonutChartLine,
	RiListSettingsLine,
	RiMessage3Line,
	RiShieldFlashLine
} from 'react-icons/ri'
import DarkLogo from '../../assets/img/cg-logo-white.svg'
import Logo from '../../assets/img/cg-logo-light.svg'
import miniLogo from '../../assets/img/cg-logo-affiliate.png'
import DarkLogoIcon from '../../assets/img/apple-touch-icon-dark.svg'
import LogoIcon from '../../assets/img/apple-touch-icon.svg'
import { HiOutlineSquare3Stack3D } from 'react-icons/hi2'
import { PiMouseSimple } from 'react-icons/pi'
import { UserAvatar } from '../../components'
import { GButton, GDropdown, GTooltip } from '../../components/basic-blocks'
import { useUiStore, useUserStore } from '../../store'
import { ZendeskService } from '../../services/zendesk'
import { EXTERNAL_APP_BASE_URL } from '../../utils/app-links'
import { useAuth0 } from '@auth0/auth0-react'
import { MenuLink } from '../navbar/menu-link'
import { CollapseSidebar } from '../sidebar/menus/collapse-sidebar'
import { Impersonation } from '../navbar/impersonation/impersonation'

const removeImpersonationId = () => {
	if (localStorage.getItem('imper_userId')) {
		localStorage.removeItem('imper_userId')
	}
}

export const AffiliateLayout = (props: { children: ReactNode }) => {
	const { children } = props
	const user = useUserStore((s) => s.user)
	const desktopSidebarCollapsed = useUiStore((s) => s.desktopSidebarCollapsed)
	const [copyStatus, setCopyStatus] = useState(false)
	async function copy(text: string) {
		await navigator.clipboard.writeText(text)
		setCopyStatus(true)
		setTimeout(() => {
			setCopyStatus(false)
		}, 2000)
	}
	return (
		<div className={'flex flex-col-reverse'}>
			<div className="flex min-h-[calc(100vh-72px)]">
				<main
					className={`flex-1 overflow-hidden pt-[4.5rem] pb-16 ${
						desktopSidebarCollapsed ? 'md:ml-20' : 'md:ml-[200px]'
					}`}
				>
					{children}
				</main>
				<Sidebar />
			</div>
			<div
				className={`fixed top-0 left-0 flex-shrink-0 flex h-[72px] z-20 ${
					desktopSidebarCollapsed
						? 'w-[calc(100%-64px)] md:ml-16'
						: 'w-[calc(100%-200px)] md:ml-[200px]'
				}`}
			>
				<div className="p-8 flex w-full items-center justify-between">
					{user?.affiliate_profile?.affiliate_id ? (
						<div className="flex items-center gap-x-4">
							<div className="bg-white p-2 border-b-[2px] border-b-primary-200 flex items-center gap-x-2">
								<img src={miniLogo} alt="logo" />
								<p className="text-primary-500 font-bold text-lg">
									{user?.affiliate_profile?.link ||
										`${EXTERNAL_APP_BASE_URL}?/via=first_name_last_name`}
								</p>
							</div>
							<GButton
								onClick={() => copy(user?.affiliate_profile?.link || '')}
								variant="contained"
								color="primary"
								labelClassName="text-sm font-bold"
							>
								<GTooltip
									content={copyStatus ? 'Copied!' : 'Copy'}
									position="top"
								>
									<>Copy Link</>
								</GTooltip>
							</GButton>
						</div>
					) : (
						<div></div>
					)}
					<div>
						<Impersonation />
					</div>
				</div>
			</div>
		</div>
	)
}

const Sidebar = () => {
	const user = useUserStore((s) => s.user)
	const isProfileActive = !user?.affiliate_profile?.affiliate_id
	const desktopSidebarCollapsed = useUiStore((s) => s.desktopSidebarCollapsed)
	return (
		<>
			<div
				className={
					'hidden md:flex md:flex-col md:fixed  md:inset-y-0 bg-white border-r border-r-gray-100 relative z-20'
				}
			>
				<div className="flex items-center justify-center h-[28px] my-[22px] relative">
					{!desktopSidebarCollapsed ? (
						<picture>
							<source
								srcSet={DarkLogo}
								className="h-[28px]"
								media="(prefers-color-scheme: dark)"
							/>
							<img className="h-[28px]" src={Logo} alt="logo" />
						</picture>
					) : (
						<picture>
							<source
								srcSet={DarkLogoIcon}
								className="h-[28px]"
								media="(prefers-color-scheme: dark)"
							/>
							<img className="h-[28px]" src={LogoIcon} alt="logo" />
						</picture>
					)}
					<div className={'flex transition-all absolute -right-3'}>
						<CollapseSidebar />
					</div>
				</div>
				<div className="border-b mx-4"></div>
				<div
					className={`px-2 flex flex-col justify-center gap-y-2 py-4 z-40 overflow-auto overflow-x-hidden transition-all ${
						!desktopSidebarCollapsed ? 'w-[200px]' : 'w-auto'
					}`}
				>
					<NavItem
						label="Dashboard"
						disabled={isProfileActive}
						to={() => '/affiliate/dashboard'}
						icon={RiDonutChartLine}
					/>
					<NavItem
						label="About"
						disabled={isProfileActive}
						to={() => '/affiliate/about'}
						icon={HiOutlineSquare3Stack3D}
					/>
					<NavItem
						label="Resources"
						disabled={isProfileActive}
						to={() => '/affiliate/resources'}
						icon={PiMouseSimple}
					/>
					<NavItem
						label="Legal"
						disabled={isProfileActive}
						to={() => '/affiliate/legal'}
						icon={RiShieldFlashLine}
					/>
				</div>
				<div
					className={`divide-y-2 px-2 gap-4 divide-card-border mb-[24px] z-50 flex flex-col flex-grow flex-1 justify-end ${
						!desktopSidebarCollapsed ? 'w-[200px]' : 'w-auto'
					}`}
				>
					<div className="space-y-2">
						<NavItem
							label="Settings"
							to={() => '/affiliate/setting'}
							icon={RiListSettingsLine}
						/>
						<NavItem
							label="Support"
							disabled={isProfileActive}
							onClick={ZendeskService.open}
							to={() => '#'}
							icon={RiMessage3Line}
						/>
					</div>
				</div>
				<div className="border-b mx-4"></div>
				<UserHeaderDropdown />
			</div>
		</>
	)
}

export const UserHeaderDropdown = () => {
	return (
		<div className="relative z-[10000000]">
			<GDropdown position="top" menuButton={<MenuButton />}>
				{() => <UserMenuContent />}
			</GDropdown>
		</div>
	)
}

const UserMenuContent = () => {
	const { logout } = useAuth0()
	const user = useUserStore((s) => s.user)

	return (
		<div
			className={
				'ml-6 -mb-8  border w-60 px-2 whitespace-nowrap text-ellipsis rounded-md shadow-lg bg-card-background ring-opacity-5 divide-y divide-gray-100 '
			}
		>
			<div className="py-1">
				<div className="flex items-center space-x-2 px-2 pt-4 pb-2">
					<UserAvatar user={user} size={8} />
					<div className="text-left flex-col flex-1 hidden md:block">
						<p className="text-sm font-bold text-t-heading w-32 truncate">
							{user?.affiliate_profile?.first_name}{' '}
							{user?.affiliate_profile?.last_name}
						</p>
						<p className="text-sm text-t-secondary w-32 truncate">
							{user?.email}
						</p>
					</div>
				</div>
			</div>

			<div className="py-1">
				<MenuLink
					label="Logout"
					to={() => '#'}
					onClickWithoutNavigation={() => {
						logout()
						removeImpersonationId()
					}}
				/>
			</div>
		</div>
	)
}

const MenuButton = () => {
	const user = useUserStore((s) => s.user)
	const desktopSidebarCollapsed = useUiStore((s) => s.desktopSidebarCollapsed)

	return (
		<div className="flex max-w-full items-center cursor-pointer px-2 py-2 rounded-md  space-x-2 hover:bg-gray-50">
			<div className="w-8 h-8 flex items-center justify-center rounded-md ">
				<UserAvatar user={user} size={6} />
			</div>
			{!desktopSidebarCollapsed && (
				<div className="text-left flex-1 hidden md:block w-[110px]">
					<p className="text-sm font-bold text-t-heading w-32 truncate">
						{user?.affiliate_profile?.first_name}{' '}
						{user?.affiliate_profile?.last_name}
					</p>
				</div>
			)}
		</div>
	)
}
