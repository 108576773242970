"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleAdsExclusionType = void 0;
var GoogleAdsExclusionType;
(function (GoogleAdsExclusionType) {
    GoogleAdsExclusionType["IP_BLOCK"] = "IP_BLOCK";
    GoogleAdsExclusionType["PLACEMENT"] = "PLACEMENT";
    GoogleAdsExclusionType["MOBILE_APPLICATION"] = "MOBILE_APPLICATION";
    GoogleAdsExclusionType["AUDIENCE_EXCLUSIONS"] = "AUDIENCE_EXCLUSIONS";
})(GoogleAdsExclusionType = exports.GoogleAdsExclusionType || (exports.GoogleAdsExclusionType = {}));
