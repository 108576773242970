import { ApiClient } from '../api-client'

const sendPasswordReset = async (email: string): Promise<any> => {
	const { data } = await ApiClient.client.post('/users/reset-password', {
		email
	})
	return data.data
}

const changePassword = async (
	password: string,
	password_confirm: string,
	code: string
): Promise<any> => {
	const { data } = await ApiClient.client.post('/users/change-password', {
		password,
		password_confirm,
		code
	})
	return data.data
}
const getPasswordRequest = async (code: string): Promise<any> => {
	const { data } = await ApiClient.client.get(
		`/users/change-password-code?code=${code}`
	)
	return data.data
}

export const ResetPasswordService = {
	sendPasswordReset,
	changePassword,
	getPasswordRequest
}
