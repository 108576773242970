import { ProductAccountDTO, SubscriptionDTO } from 'api-models'
import { UserAvatar } from 'components'
import { GButton, GSection, GTransition } from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'
import { GDateTime } from 'components/basic-blocks/g-datetime'
import { InfoItem, InfoItemGroup } from 'components/basic-blocks/g-info-table'
import { ReferencesInfoSection } from 'components/references-info-section'

import { useAdminStore, useUiStore } from 'store'
import { useShallow } from 'zustand/react/shallow'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FaPercent } from 'react-icons/fa'
import {
	RiArrowDownSLine,
	RiArrowGoBackLine,
	RiArrowRightLine,
	RiArrowUpSLine,
	RiDeleteBin2Line,
	RiFileEditLine,
	RiLoginCircleLine
} from 'react-icons/ri'
import { NavLink, useNavigate } from 'react-router-dom'
import {
	discountText,
	formatCurrency,
	formatCurrencyDiscount,
	formatPriceInterval,
	formatSpend,
	planStepText
} from 'utils'
import { SubscriptionColors } from 'utils/colors'
import { PageTitle } from 'utils/page-title'
import {
	AddDiscount,
	CancelSubscription,
	RevertSubscription,
	Trial
} from './admin-subscription-actions/'

export const Info = (props: { account: ProductAccountDTO }) => {
	const { account } = props
	const navigate = useNavigate()

	const { getSubscription, setImpersonation } = useAdminStore(
		useShallow((state) => ({
			getSubscription: state.getSubscription,
			setImpersonation: state.setImpersonation
		}))
	)
	const isLoading = useUiStore((s) => s.isLoading)

	const [subscription, setSubscription] = useState<SubscriptionDTO>()

	const managers = account.user_access.filter(
		(user) => user.access_level === 'WRITE'
	)
	const analysts = account.user_access.filter(
		(user) => user.access_level === 'READ'
	)

	const accountTypeIsStandard = account.type === 'STANDARD'
	const accountTypeIsAGENCY = account.type === 'AGENCY'

	const accountIsSetup = account.status === 'SETUP'
	const accountIsActive = account.status === 'ACTIVE'
	const accountIsInactive = account.status === 'INACTIVE'

	// Subscription info
	const subNextBillingDate = account.billing?.subscription?.next_billing_date
	const subEndsAt = account.billing?.subscription?.ends_at
	const subEndedAt = account.billing?.subscription?.ended_at

	const subTypeIsTrial = account.billing?.subscription?.type === 'TRIAL'

	const subscriptionStatus = account.billing?.subscription?.status
	const subIsActive = account.billing?.subscription?.status === 'ACTIVE'
	const subIsCancelling = account.billing?.subscription?.status === 'CANCELLING'
	const subIsSwitching = account.billing?.subscription?.status === 'SWITCHING'
	const subIsCancelled = account.billing?.subscription?.status === 'CANCELLED'
	const subIsPending = account.billing?.subscription?.status === 'PENDING'

	// Plans (Current & Next)
	const currentPlan = account.billing?.subscription?.plan
	const nextPlan = account.billing?.subscription?.next_plan

	const impersonate = async () => {
		try {
			await setImpersonation(account.user_info?.user_id ?? '')
			navigate('/')
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		;(async () => {
			if (account.billing?.subscription?.id) {
				const subscription = await getSubscription(
					account.billing?.subscription?.id
				)
				if (subscription) setSubscription(subscription)
			}
		})()
	}, [])

	return (
		<>
			<PageTitle pageName="Workspace" />
			<GSection>
				<InfoItemGroup title="Basic information" className="-mt-6 border-t-0">
					<InfoItem label="Workspace ID">
						<code className="text-red-600">{account.id}</code>
					</InfoItem>
					<InfoItem label="Owner">
						<div className="flex space-x-2 items-center">
							<div className="flex items-center mr-2">
								<GButton
									shape="square"
									type="icon"
									icon={RiLoginCircleLine}
									loading={isLoading('ADMIN_SET_IMPERSONATION')}
									variant="contained"
									color="primary"
									size="xs"
									onClick={impersonate}
								>
									Impersonate
								</GButton>
							</div>
							<NavLink to={`/admin/user/${account.user_info?.user_id}`}>
								<div className="flex space-x-2 items-center">
									<UserAvatar size={8} src={account.user_info?.avatar} />
									<div className="flex flex-col group">
										<div className="font-semibold text-primary-500 group-hover:text-primary-600 truncate max-w-[200px]">
											{account.user_info?.name}
										</div>
										<div className="text-t-secondary">
											{account.user_info?.email}
										</div>
									</div>
								</div>
							</NavLink>
						</div>
					</InfoItem>
					<InfoItem label="Status">
						<div className="flex">
							{!accountIsInactive ? (
								<GBadge text={account.status} color="green" />
							) : (
								<GBadge text={account.status} color="red" />
							)}
						</div>
					</InfoItem>
					<InfoItem label="Created">
						<span className="text-gray-500">
							{moment(account.created_at).format('MMMM Do YYYY - h:mm a')}
						</span>
					</InfoItem>
					<InfoItem label="Subscribed">
						<span className="text-gray-500">
							{moment(account.created_at).format('MMMM Do YYYY - h:mm a')}
						</span>
					</InfoItem>
					{managers.length > 0 && (
						<InfoItem label="Managers">
							<ExpandableList limit={1} length={managers.length || 0}>
								{managers.map((manager) => (
									<NavLink
										key={manager.user_id}
										to={`/admin/user/${manager.user_id}`}
									>
										<div className="flex space-x-2 items-center mb-2">
											<UserAvatar size={8} src={manager?.avatar} />
											<div className="flex flex-col group">
												<div className="font-semibold text-gray-500">
													{manager?.user_name}
												</div>
												<div className="text-t-secondary">
													{manager?.user_email}
												</div>
											</div>
										</div>
									</NavLink>
								))}
							</ExpandableList>
						</InfoItem>
					)}
					{analysts.length > 0 && (
						<InfoItem label="Analysts">
							<ExpandableList limit={1} length={managers.length || 0}>
								{analysts.map((analyst) => (
									<NavLink
										key={analyst.user_id}
										to={`/admin/user/${analyst.user_id}`}
									>
										<div className="flex space-x-2 items-center mb-2">
											<UserAvatar size={8} src={analyst?.avatar} />
											<div className="flex flex-col group">
												<div className="font-semibold text-gray-500">
													{analyst?.user_name}
												</div>
												<div className="text-t-secondary">
													{analyst?.user_email}
												</div>
											</div>
										</div>
									</NavLink>
								))}
							</ExpandableList>
						</InfoItem>
					)}
				</InfoItemGroup>

				<GTransition show={accountIsSetup}>
					<InfoItemGroup title="Setup">
						<InfoItem label="Type">
							{accountTypeIsStandard && (
								<div className="flex">
									<GBadge text={account.type} color="primary" />
								</div>
							)}
							{accountTypeIsAGENCY && (
								<div className="flex">
									<GBadge text={account.type} color="purple" />
								</div>
							)}
						</InfoItem>
						<InfoItem label="Current step">
							{planStepText(account.setup?.step)}
						</InfoItem>
						{accountTypeIsStandard && (
							<InfoItem label="Domain">{account.setup?.domain}</InfoItem>
						)}
						{accountTypeIsStandard && (
							<InfoItem label="Industry">{account.setup?.industry}</InfoItem>
						)}
						{accountTypeIsAGENCY && (
							<InfoItem label="Managed clients">
								{account.setup?.brands}
							</InfoItem>
						)}

						<InfoItem label="Advertising platform">
							{account.setup?.platforms?.map((platform, index) => (
								<span key={platform}>
									{index !== 0 && ', '}
									{platform}
								</span>
							))}
						</InfoItem>
						<InfoItem label="Ad spend">
							<div className="flex flex-row gap-x-3">
								<div className="flex items-center gap-x-1">
									Selected:{' '}
									<GBadge
										text={formatSpend(account.setup?.spend)}
										color="purple"
									/>
								</div>
								<div className="flex items-center gap-x-1">
									Detected:{' '}
									<GBadge
										text={formatSpend(account.setup?.detected_spend)}
										color="purple"
									/>
								</div>
							</div>
						</InfoItem>
					</InfoItemGroup>
				</GTransition>

				<GTransition show={accountIsActive || accountIsInactive}>
					<InfoItemGroup title="Subscription">
						<InfoItem label="Status">
							<div className="flex flex-row justify-between items-center">
								<div className="flex flex-col items-start gap-y-2">
									{subTypeIsTrial && (subIsCancelling || subIsCancelled) && (
										<GBadge text={'TRIAL'} color="primary" />
									)}
									{subTypeIsTrial && subIsActive ? (
										<GBadge text={'TRIAL'} color="primary" />
									) : (
										<GBadge
											text={subscriptionStatus || 'NONE'}
											color={
												SubscriptionColors[
													account.billing?.subscription?.status || 'NONE'
												]
											}
										/>
									)}
								</div>
								{<StatusAction account={account} plan={'current'} />}
							</div>
						</InfoItem>
						{subTypeIsTrial && (
							<InfoItem label="Trial clicks">
								<span
									className={'px-3 py-1 rounded-full bg-gray-100 text-gray-500'}
								>
									{account.billing?.subscription?.plan.clicks}
								</span>
							</InfoItem>
						)}

						{account.offer_request && (
							<InfoItem label="Offer request">
								<div className="flex flex-col gap-y-1">
									<span>
										{formatSpend(account.offer_request.spend)} ad spend
										{account.offer_request.interval === 'any'
											? ''
											: `, billed ${account.offer_request.interval}`}
									</span>
									<span className="text-gray-500">
										{account.offer_request?.created_at &&
											moment(account.offer_request?.created_at).format(
												'MMMM Do YYYY - h:mm a'
											)}
									</span>
								</div>
							</InfoItem>
						)}
						{subIsActive && account.cancel_request && (
							<InfoItem label="Cancel request" color="red">
								<div className="flex flex-col gap-y-1">
									<span>{account.cancel_request.note}</span>
									<span className="text-gray-500">
										{account.cancel_request?.created_at &&
											moment(account.cancel_request?.created_at).format(
												'MMMM Do YYYY - hh:mm a'
											)}
									</span>
								</div>
							</InfoItem>
						)}

						<InfoItem label={subTypeIsTrial && nextPlan ? 'Next plan' : 'Plan'}>
							{subTypeIsTrial && nextPlan && nextPlan?.name}

							{subTypeIsTrial && !nextPlan && 'NONE'}
							{!subTypeIsTrial &&
								(subIsCancelling ||
									subIsActive ||
									subIsCancelled ||
									subIsPending) &&
								currentPlan?.name}

							{subIsSwitching && (
								<div className="flex flex-row items-center gap-x-2">
									<span className="text-gray-500">{currentPlan?.name}</span>
									<RiArrowRightLine className="text-t-secondary w-4 h-4" />
									<span className="text-gray-500">{nextPlan?.name}</span>
								</div>
							)}
						</InfoItem>

						{(!subTypeIsTrial || nextPlan) && (
							<InfoItem
								label={subIsCancelled ? 'Last plan ad spend' : 'Ad spend'}
							>
								{subIsSwitching && (
									<div className="flex flex-row items-center gap-x-2">
										<GBadge
											text={formatSpend(currentPlan?.spend)}
											color="gray"
										/>
										<RiArrowRightLine className="text-t-secondary w-4 h-4" />
										<GBadge
											text={formatSpend(nextPlan?.spend)}
											color="purple"
										/>
									</div>
								)}
								{!subIsSwitching && !accountIsInactive && (
									<div className="flex">
										<GBadge
											text={
												subTypeIsTrial
													? formatSpend(nextPlan?.spend)
													: `${formatSpend(currentPlan?.spend)}`
											}
											color="purple"
										/>
									</div>
								)}
								{accountIsInactive && (
									<div className="flex">
										<GBadge
											text={formatSpend(currentPlan?.spend)}
											color="gray"
										/>
									</div>
								)}
							</InfoItem>
						)}

						{(!subTypeIsTrial || nextPlan) && (
							<InfoItem label={subIsCancelled ? 'Last plan price' : 'Price'}>
								<PriceRowData
									subTypeIsTrial={subTypeIsTrial}
									subIsSwitching={subIsSwitching}
									currentPlan={currentPlan}
									nextPlan={nextPlan}
									account={account}
									accountIsInactive={accountIsInactive}
									subscription={subscription}
								/>
							</InfoItem>
						)}

						{/* Next Event section starts*/}
						<InfoItem label="Next event">
							{subTypeIsTrial && subIsActive && nextPlan && (
								<div className="flex flex-col gap-y-1">
									<span>
										Starts <GDateTime date={subNextBillingDate} />
									</span>
									<span className="text-gray-500">
										{subNextBillingDate &&
											moment(subNextBillingDate).format(
												'MMMM Do YYYY - hh:mm a'
											)}
									</span>
								</div>
							)}
							{((subTypeIsTrial && subIsActive && !nextPlan) ||
								subIsCancelling) && (
								<div className="flex flex-col gap-y-1">
									<span>
										Cancels <GDateTime date={subEndsAt} />
									</span>
									<span className="text-gray-500">
										{subEndsAt &&
											moment(subEndsAt).format('MMMM Do YYYY - hh:mm a')}
									</span>
								</div>
							)}
							{!subTypeIsTrial && subIsActive && (
								<div className="flex flex-col gap-y-1">
									<span>
										Renews <GDateTime date={subNextBillingDate} />
									</span>
									<span className="text-gray-500">
										{subNextBillingDate &&
											moment(subNextBillingDate).format(
												'MMMM Do YYYY - hh:mm a'
											)}
									</span>
								</div>
							)}
							{subIsSwitching && (
								<div className="flex flex-col gap-y-1">
									<span>
										Switches <GDateTime date={subNextBillingDate} />
									</span>
									<span className="text-gray-500">
										{subNextBillingDate &&
											moment(subNextBillingDate).format(
												'MMMM Do YYYY - hh:mm a'
											)}
									</span>
								</div>
							)}
							{subIsPending && 'Waiting payment confirmation'}
						</InfoItem>
						{/* Next Event section ends */}

						{accountIsInactive && (
							<InfoItem label="Cancelled">
								<div className="flex flex-col gap-y-1">
									<span>
										Cancelled <GDateTime date={subEndsAt} />
									</span>
									<span className="text-gray-500">
										{moment(subEndedAt).format('MMMM Do YYYY - h:mm a')}
									</span>
								</div>
							</InfoItem>
						)}
						<InfoItem label="Stripe subscription">
							<div className="overflow-hidden break-words">
								{subscription?.details?.id ? (
									<div className="text-primary-500 hover:text-primary-400 ">
										<a
											target="_blank"
											href={`https://dashboard.stripe.com/subscriptions/${subscription?.details?.id}`}
										>
											{subscription?.details?.id}
										</a>
									</div>
								) : (
									<div> N/A </div>
								)}
							</div>
						</InfoItem>
						<InfoItem label="Total revenue">
							<div className="flex flex-row gap-x-2">
								<span>Paid: {formatCurrency(account.billing?.total_paid)}</span>
								<span>
									Refunded:{formatCurrency(account.billing?.total_refunded)}
								</span>
							</div>
						</InfoItem>
					</InfoItemGroup>
				</GTransition>
				<ReferencesInfoSection accountId={account.id} />
			</GSection>
		</>
	)
}

const StatusAction = (props: {
	account: ProductAccountDTO
	plan: 'current' | 'next'
}) => {
	const { account, plan } = props
	const status = account.billing?.subscription?.status
	const isTrial = account.billing?.subscription?.type === 'TRIAL'

	const editIcon = () => <RiFileEditLine className="w-4 h-4 mr-2" />
	const deleteIcon = () => <RiDeleteBin2Line className="w-4 h-4 mr-2" />
	const revert = () => <RiArrowGoBackLine className="w-4 h-4 mr-2" />

	if (!plan) return <></>
	return (
		<div className="">
			<GTransition show={plan === 'current'}>
				{isTrial && status !== 'CANCELLING' && status !== 'CANCELLED' && (
					<div className="flex flex-wrap gap-2 justify-start">
						<Trial account={account} type="update">
							{(open) => (
								<GButton
									icon={editIcon}
									label="Update"
									color="primary"
									size="xs"
									onClick={() => open()}
								/>
							)}
						</Trial>
						<CancelSubscription account={account}>
							{(open) => (
								<GButton
									icon={deleteIcon}
									label="Cancel"
									color="danger"
									size="xs"
									onClick={() => open()}
								/>
							)}
						</CancelSubscription>
					</div>
				)}
				{status === 'ACTIVE' && !isTrial && (
					<div className="flex space-x-2 justify-center">
						<CancelSubscription account={account}>
							{(open) => (
								<GButton
									icon={deleteIcon}
									label="Cancel"
									color="danger"
									size="xs"
									onClick={() => open()}
								/>
							)}
						</CancelSubscription>
					</div>
				)}
				{status === 'SWITCHING' && (
					<div className="flex space-x-2 justify-center">
						<RevertSubscription account={account}>
							{(open) => (
								<GButton
									icon={revert}
									label="Revert"
									color="warning"
									size="xs"
									onClick={() => open()}
								/>
							)}
						</RevertSubscription>
						<CancelSubscription account={account}>
							{(open) => (
								<GButton
									icon={deleteIcon}
									label="Cancel"
									color="danger"
									size="xs"
									onClick={() => open()}
								/>
							)}
						</CancelSubscription>
					</div>
				)}
				{status === 'CANCELLING' && (
					<div className="flex space-x-2 justify-center">
						<Trial account={account} type="inactive">
							{(open) => (
								<GButton
									icon={editIcon}
									label="New trial"
									color="primary"
									size="xs"
									onClick={() => open()}
								/>
							)}
						</Trial>
						<RevertSubscription account={account}>
							{(open) => (
								<GButton
									icon={revert}
									label="Revert"
									color="warning"
									size="xs"
									onClick={() => open()}
								/>
							)}
						</RevertSubscription>
						<CancelSubscription account={account}>
							{(open) => (
								<GButton
									icon={deleteIcon}
									label="Cancel"
									color="danger"
									size="xs"
									onClick={() => open()}
								/>
							)}
						</CancelSubscription>
					</div>
				)}

				{status === 'CANCELLED' && (
					<div className="flex space-x-2 justify-center">
						<Trial account={account} type="inactive">
							{(open) => (
								<GButton
									icon={editIcon}
									label="New trial"
									color="primary"
									size="xs"
									onClick={() => open()}
								/>
							)}
						</Trial>
					</div>
				)}

				{status === 'PENDING' && (
					<div className="flex space-x-2 justify-center">
						<CancelSubscription account={account}>
							{(open) => (
								<GButton
									icon={deleteIcon}
									label="Cancel"
									color="danger"
									size="xs"
									onClick={() => open()}
								/>
							)}
						</CancelSubscription>
					</div>
				)}
			</GTransition>
		</div>
	)
}

const PriceRowData = (props: any) => {
	const {
		subTypeIsTrial,
		subIsSwitching,
		currentPlan,
		nextPlan,
		account,
		accountIsInactive,
		subscription
	} = props
	return (
		<div>
			{subIsSwitching && (
				<div className="flex justify-between items-center gap-2">
					<div className="flex flex-row items-center gap-x-2">
						<div className="flex">
							{subscription?.discount ? (
								<GBadge
									color="gray"
									text={
										<span>
											{formatCurrencyDiscount(currentPlan.price, {
												amountOff: subscription?.discount.amount_off,
												percentOff: subscription?.discount.percent_off
											})}
											{formatPriceInterval(
												currentPlan?.interval,
												currentPlan?.interval_count
											)}
										</span>
									}
								/>
							) : (
								<GBadge
									color="gray"
									text={
										<span>
											{formatCurrency(currentPlan?.price)}
											{formatPriceInterval(
												currentPlan?.interval,
												currentPlan?.interval_count
											)}
										</span>
									}
								/>
							)}
						</div>
						<RiArrowRightLine className="text-t-secondary w-4 h-4" />
						<div className="flex">
							{subscription?.discount ? (
								<GBadge
									color="green"
									text={
										<span>
											{formatCurrencyDiscount(nextPlan.price, {
												amountOff: subscription?.discount.amount_off,
												percentOff: subscription?.discount.percent_off
											})}
											{formatPriceInterval(
												nextPlan?.interval,
												nextPlan?.interval_count
											)}
										</span>
									}
								/>
							) : (
								<GBadge
									color="green"
									text={
										<span>
											{formatCurrency(nextPlan?.price)}
											{formatPriceInterval(
												nextPlan?.interval,
												nextPlan?.interval_count
											)}
										</span>
									}
								/>
							)}
						</div>
					</div>
					<PriceActions account={account} plan={'current'} />
				</div>
			)}
			{!subIsSwitching && !accountIsInactive && (
				<div className="flex justify-between items-center gap-2">
					<div className="flex items-center gap-2">
						<GBadge
							text={
								subTypeIsTrial ? (
									<span
										className={`${
											subscription?.discount &&
											'line-through text-t-secondary mr-1'
										}`}
									>
										{formatCurrency(nextPlan?.price)}
										{formatPriceInterval(
											nextPlan?.interval,
											nextPlan?.interval_count
										)}
									</span>
								) : (
									<span
										className={`${
											subscription?.discount &&
											'line-through text-t-secondary mr-1'
										}`}
									>
										{formatCurrency(currentPlan?.price)}
										{formatPriceInterval(
											currentPlan?.interval,
											currentPlan?.interval_count
										)}
									</span>
								)
							}
							color={`${subscription?.discount ? 'gray' : 'green'}`}
						/>

						{subscription?.discount && (
							<>
								<GBadge
									text={<FaPercent />}
									color="primary"
									tooltip={discountText(subscription.discount)}
								/>
								<GBadge
									text={
										subTypeIsTrial ? (
											<span>
												{formatPriceInterval(
													nextPlan?.interval,
													nextPlan?.interval_count
												)}
											</span>
										) : (
											<span>
												{formatCurrencyDiscount(subscription?.plan.price, {
													amountOff: subscription?.discount.amount_off,
													percentOff: subscription?.discount.percent_off
												})}
												{formatPriceInterval(
													currentPlan?.interval,
													currentPlan?.interval_count
												)}
											</span>
										)
									}
									color="green"
								/>
							</>
						)}
					</div>
					<div>
						{!subIsSwitching ? (
							<PriceActions account={account} plan={'current'} />
						) : (
							<PriceActions account={account} plan={'next'} />
						)}
					</div>
				</div>
			)}
			{accountIsInactive && (
				<div className="flex">
					<GBadge
						text={
							<span
								className={`${
									subscription?.discount && 'line-through text-t-secondary mr-1'
								}`}
							>
								{formatCurrency(currentPlan?.price)}
								{formatPriceInterval(
									currentPlan?.interval,
									currentPlan?.interval_count
								)}
							</span>
						}
						color={subscription?.discount ? 'gray' : 'green'}
					/>

					{subscription?.discount && (
						<>
							<GBadge
								text={<FaPercent />}
								color="primary"
								tooltip={discountText(subscription.discount)}
							/>
							<GBadge
								text={
									<span>
										{formatCurrencyDiscount(currentPlan.price, {
											amountOff: subscription?.discount.amount_off,
											percentOff: subscription?.discount.percent_off
										})}
										{formatPriceInterval(
											currentPlan?.interval,
											currentPlan?.interval_count
										)}
									</span>
								}
								color="green"
							/>
						</>
					)}
				</div>
			)}
		</div>
	)
}

const PriceActions = (props: {
	account: ProductAccountDTO
	plan: 'current' | 'next'
}) => {
	const { account, plan } = props
	const status = account.billing?.subscription?.status
	const isTrial = account.billing?.subscription?.type === 'TRIAL'

	if (!plan) return <></>

	return (
		<div className="">
			<GTransition show={plan === 'current'}>
				{isTrial && (
					<div className="flex flex-wrap gap-2 justify-start">
						<AddDiscount account={account}>
							{(open) => (
								<GButton
									label="Add Discount"
									color="primary"
									size="xs"
									onClick={() => open()}
								/>
							)}
						</AddDiscount>
					</div>
				)}
				{status === 'ACTIVE' && !isTrial && (
					<div className="flex space-x-2 justify-center">
						<AddDiscount account={account}>
							{(open) => (
								<GButton
									label="Add Discount"
									color="primary"
									size="xs"
									onClick={() => open()}
								/>
							)}
						</AddDiscount>
					</div>
				)}
				{status === 'SWITCHING' && (
					<div className="flex space-x-2 justify-center">
						<AddDiscount account={account}>
							{(open) => (
								<GButton
									label="Add Discount"
									color="primary"
									size="xs"
									onClick={() => open()}
								/>
							)}
						</AddDiscount>
					</div>
				)}
			</GTransition>
			<GTransition show={plan === 'next'}>
				<div className="flex flex-wrap gap-2 justify-start">
					<AddDiscount account={account}>
						{(open) => (
							<GButton
								label="Add Discount"
								color="primary"
								size="xs"
								onClick={() => open()}
							/>
						)}
					</AddDiscount>
				</div>
			</GTransition>
		</div>
	)
}
const ExpandableList = (props: {
	children: any
	length?: number
	limit?: number
}) => {
	const { length, limit, children } = props
	const [expanded, setExpanded] = useState(false)
	return (
		<div className="flex justify-between items-start overflow-hidden">
			<div className={expanded ? 'h-fit' : 'h-8'}>{children}</div>
			{(length === undefined || limit === undefined || length > limit) && (
				<div className="flex items-center justify-center cursor-pointer px-1 hover:bg-slate-100 rounded-full h-fit ml-2">
					<div onClick={() => setExpanded(!expanded)}>
						{expanded ? (
							<RiArrowUpSLine className="text-primary-500 w-4 h-4 " />
						) : (
							<RiArrowDownSLine className="text-primary-500 w-4 h-4" />
						)}
					</div>
				</div>
			)}
		</div>
	)
}
