import { ReferenceAdsAccountDTO, ReferenceDomainDTO } from 'api-models'
import { AccountInfo } from 'components/account-info'
import { GLoading } from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'
import { InfoItem, InfoItemGroup } from 'components/basic-blocks/g-info-table'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import { removeFadPrefix } from 'utils'
import { State } from './types'

export const ReferencesInfoSection = (props: {
	userId?: string
	accountId?: string
}) => {
	const { userId, accountId } = props
	const { adminStore } = useStores()
	const { getDomainsReferences, getGadsReferences } = adminStore
	const [loading, setLoading] = useState(false)
	const [state, setState] = useState<State>({
		domainsReferences: [],
		gadsReferences: []
	})

	const setReferences = async () => {
		const params: any = {}
		userId && (params.user_id = userId)
		accountId && (params.account_id = accountId)

		const [domainsReferences, gadsReferences] = await Promise.all([
			getDomainsReferences(params),
			getGadsReferences(params)
		])
		setState({
			...state,
			domainsReferences: domainsReferences?.data || [],
			gadsReferences: gadsReferences?.data || []
		})
	}

	useEffect(() => {
		;(async () => {
			try {
				setLoading(true)
				await setReferences()
				setLoading(false)
			} catch (e) {
				setLoading(false)
				return
			}
		})()
	}, [userId, accountId])

	return (
		<InfoItemGroup title="References" className="border-t-0">
			<InfoItem label="Domains">
				<ExpandableList length={state.domainsReferences.length} limit={3}>
					{loading ? (
						<GLoading />
					) : (
						<DomainsReferences domainsReferences={state.domainsReferences} />
					)}
				</ExpandableList>
			</InfoItem>
			<InfoItem label="PPC Accounts">
				<ExpandableList length={state.gadsReferences.length} limit={2}>
					{loading ? (
						<GLoading />
					) : (
						<PPCAccountReferences
							PPCAccountReferences={state.gadsReferences}
							updateReferences={setReferences}
						/>
					)}
				</ExpandableList>
			</InfoItem>
		</InfoItemGroup>
	)
}

const ExpandableList = (props: {
	children: any
	length?: number
	limit?: number
}) => {
	const { length, limit, children } = props
	const [expanded, setExpanded] = useState(false)
	return (
		<div className="flex justify-between overflow-hidden">
			<div className={expanded ? 'h-fit' : 'h-7'}>{children}</div>
			{(length === undefined || limit === undefined || length > limit) && (
				<div className="flex items-center justify-center cursor-pointer p-1 hover:bg-slate-100 rounded-full h-fit">
					<div onClick={() => setExpanded(!expanded)}>
						{expanded ? (
							<RiArrowUpSLine className="text-t-secondary w-6 h-6 " />
						) : (
							<RiArrowDownSLine className="text-t-secondary w-6 h-6" />
						)}
					</div>
				</div>
			)}
		</div>
	)
}

const DomainsReferences = (props: {
	domainsReferences: ReferenceDomainDTO[]
}) => {
	const { domainsReferences } = props
	if (domainsReferences.length === 0) return <div>No Items to display</div>
	return (
		<div className="flex gap-2 flex-wrap">
			{domainsReferences.map((domain, index) => (
				<div key={index}>
					<AccountInfo data={domain}>
						{(open) => (
							<div
								className="flex flex-col mb-2 cursor-pointer"
								onClick={() => open()}
							>
								<GBadge
									className="truncate max-w-[250px]"
									text={domain.domain || 'Unknown'}
									color="primary"
								/>
							</div>
						)}
					</AccountInfo>
				</div>
			))}
		</div>
	)
}

const PPCAccountReferences = (props: {
	PPCAccountReferences: ReferenceAdsAccountDTO[]
	updateReferences: () => void
}) => {
	const { PPCAccountReferences, updateReferences } = props
	if (PPCAccountReferences.length === 0) return <div>No Items to display</div>
	return (
		<div className="flex gap-2 flex-wrap">
			{PPCAccountReferences.map((account, index) => (
				<div key={index}>
					<AccountInfo data={account} updateReferences={updateReferences}>
						{(open) => (
							<div
								className="flex flex-col mb-2 cursor-pointer"
								onClick={() => open()}
							>
								<GBadge
									text={removeFadPrefix(account.account_number) || 'Unknown'}
									color="primary"
								/>
							</div>
						)}
					</AccountInfo>
				</div>
			))}
		</div>
	)
}
