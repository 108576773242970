import { FacebookAdsAdAccountDTO, FacebookAdsLinkInfoDTO } from 'api-models'
import {
	ChangeEvent,
	Dispatch,
	SetStateAction,
	useEffect,
	useState
} from 'react'
import { BsMeta } from 'react-icons/bs'
import { RiQuestionLine } from 'react-icons/ri'
import { useQuery } from 'react-query'
import timezones from 'timezones-list'
import { useShallow } from 'zustand/react/shallow'

import {
	GButton,
	GComboBox,
	GConfirm,
	GInput,
	GToggle,
	GTransition
} from 'components/basic-blocks'
import { GCheckbox } from 'components/basic-blocks/g-checkbox'
import Drawer from 'components/basic-blocks/g-drawer/g-drawer'
import { GNewTooltip } from 'components/basic-blocks/g-tooltip/g-new-tooltip'
import { FacebookAuthConfig } from 'config'
import { AdAccounts } from 'services/user'
import { ZendeskService } from 'services/zendesk'
import { useAccountStore, useAssetStore, useUiStore, useUserStore } from 'store'
import { currenciesOptions } from '../../constants'
import { transformPeriodToSeconds, transformSecondsToPeriod } from '../utils'
import { adAccountSettingsFormSchema } from '../validations'
import { AccessLinkUpdated, SettingsForm } from './types'

const periodList = ['minutes', 'hours', 'days']

export const FacebookSettings = (props: {
	adAccount: FacebookAdsAdAccountDTO
	links: FacebookAdsLinkInfoDTO[]
	dialogOpen: boolean
	setDialogOpen: Dispatch<SetStateAction<boolean>>
}) => {
	const { adAccount, links, dialogOpen, setDialogOpen } = props

	const isLoading = useUiStore((s) => s.isLoading)
	const user = useUserStore((s) => s.user)
	const { account, userAccess, refreshAccountLinks } = useAccountStore(
		useShallow((s) => ({
			account: s.account,
			userAccess: s.userAccess,
			refreshAccountLinks: s.refreshAccountLinks
		}))
	)
	const {
		asset,
		updateFadsSettingsAccessLink,
		updateFadsAdAccountSettings,
		updateSettingsAccountProtection,
		synchronizeAdAccount,
		deleteAdAccountAccount,
		demoMode
	} = useAssetStore(
		useShallow((s) => ({
			asset: s.asset,
			demoMode: s.demoMode,
			updateFadsSettingsAccessLink: s.updateFadsSettingsAccessLink,
			updateFadsAdAccountSettings: s.updateFadsAdAccountSettings,
			updateSettingsAccountProtection: s.updateSettingsAccountProtection,
			synchronizeAdAccount: s.synchronizeAdAccount,
			deleteAdAccountAccount: s.deleteAdAccountAccount
		}))
	)

	const [errors, setErrors] = useState<Record<string, string> | null>()
	const [checked, setChecked] = useState(adAccount.status === 'ACTIVE')
	const [modalSync, setModalSync] = useState(false)
	const [modalDelete, setModalDelete] = useState(false)
	const [accessLinkUpdated, setAccessLinkUpdated] = useState<AccessLinkUpdated>(
		{}
	)
	const tzList = timezones.map((item) => item.tzCode)

	const adPixels = useQuery(
		['adPixels', adAccount?.id],
		() => AdAccounts.getAccountAdPixels(account!.id, adAccount?.id),
		{ enabled: !!account && !!adAccount }
	)

	const [settingsForm, setSettingsForm] = useState<SettingsForm>({
		name: adAccount?.name || '',
		color: adAccount?.color || '#3be380',
		currency: adAccount?.currency || '',
		timezone: adAccount?.timezone || '',
		access: undefined,
		tracking: Boolean(adAccount.preferences.set_custom_audience),
		period: '30',
		adPixelTime: 'days',
		pixel: adPixels?.data?.[0]
	})

	const setSelectedLink = () => {
		return links.find((link) => link.id === adAccount.details?.link?.id) as any
	}

	useEffect(() => {
		setChecked(adAccount.status === 'ACTIVE')

		setSettingsForm({
			name: adAccount?.name || '',
			color: adAccount?.color || '#2461FD',
			currency: adAccount?.currency || '',
			timezone: adAccount?.timezone || '',
			access: setSelectedLink(),
			tracking: Boolean(adAccount?.preferences?.set_custom_audience),
			period:
				adAccount && adAccount?.details?.audience_exclusion_period
					? transformSecondsToPeriod(
							+adAccount?.details?.audience_exclusion_period
					  ).period || '30'
					: '30',
			adPixelTime:
				adAccount && adAccount?.details?.audience_exclusion_period
					? transformSecondsToPeriod(
							+adAccount?.details?.audience_exclusion_period
					  ).type || 'days'
					: 'days',
			pixel:
				adAccount && adAccount?.details?.ads_pixel_id
					? adAccount?.details?.ads_pixel_id || adPixels?.data?.[0]
					: undefined
		})

		setAccessLinkUpdated({
			...accessLinkUpdated,
			originalValue: setSelectedLink()
		})
	}, [adAccount, links])

	const handleDialogClose = () => {
		setDialogOpen(false)
		ZendeskService.show()
	}

	const onSynchronizeAccount = async () => {
		if (!account) return
		await synchronizeAdAccount(
			account?.id,
			adAccount?.id,
			adAccount.platform.toLowerCase()
		)
		setModalSync(false)
	}

	const onDeletePPCAccount = async () => {
		if (!adAccount) return
		try {
			await deleteAdAccountAccount(adAccount?.account_id, adAccount?.id)
			setModalDelete(false)
		} catch (e) {
			return
		}
	}

	const handleChange = (e: any, key: keyof SettingsForm) => {
		const input = e.currentTarget
		const form = { ...settingsForm }

		if (key === 'tracking') {
			form[key] = Boolean(e)
			setSettingsForm(form)
			return
		}

		if (!input) {
			form[key] = e
			setSettingsForm(form)
			return
		}

		form[key] = input.value
		setSettingsForm(form)
	}

	const confirmCreate = async () => {
		const payload = {
			name: settingsForm.name,
			color: settingsForm.color,
			currency: settingsForm.currency,
			timezone: settingsForm.timezone,
			set_custom_audience: settingsForm.tracking,
			pixel_id: settingsForm.pixel,
			exclusion_period: transformPeriodToSeconds(
				settingsForm.period!,
				settingsForm.adPixelTime!
			).toString()
		}
		const protectionPayload = { active: checked }

		const errors = validate()
		setErrors(errors)
		if (errors) return

		handleDialogClose()
		updateFadsAdAccountSettings(account!.id, adAccount!.id, payload as any)

		if (checked !== (adAccount.status === 'ACTIVE')) {
			updateSettingsAccountProtection(
				account!.id,
				adAccount!.id,
				protectionPayload
			)
		}

		if (!settingsForm?.access?.id) return
		if (settingsForm?.access?.id === accessLinkUpdated.originalValue?.id) return

		const linkPayload = { link_id: settingsForm.access?.id }
		try {
			await updateFadsSettingsAccessLink(
				account!.id,
				adAccount!.id,
				linkPayload
			)
			setAccessLinkUpdated({
				...accessLinkUpdated,
				originalValue: settingsForm.access as any
			})
		} catch (e) {
			return
		}
	}

	const validate = () => {
		const options = { abortEarly: false }
		const { error } = adAccountSettingsFormSchema.validate(
			{
				active: checked,
				name: settingsForm.name,
				color: settingsForm.color,
				currency: settingsForm.currency,
				timeZone: settingsForm.timezone,
				access: settingsForm.access
			},
			options
		)

		if (!error) return null

		const errors: Record<string, string> = {}

		error.details.forEach((item) => {
			errors[item.path[0]] = item.message
		})
		return errors
	}

	const handleNewAuthorization = () => {
		let newTab: Window | null = null

		newTab = window.open(FacebookAuthConfig.AUTH_URL, '_blank')

		window.addEventListener('beforeunload', async () => {
			const refreshedAccountLinks = await refreshAccountLinks()
			if (refreshedAccountLinks)
				handleChange(refreshedAccountLinks.slice(-1), 'access')

			if (newTab) newTab.close()
		})
	}

	return (
		<Drawer
			isOpen={dialogOpen}
			handleClose={handleDialogClose}
			label="Account settings"
			headerClassName="pt-6"
			labelClassName="text-xl"
		>
			<GTransition show={true} swap>
				<div className="w-[750px] mx-auto bg-white px-6 mt-6 py-6">
					<div className="bg-gray-50 px-3 py-3 flex justify-between">
						<div className="flex items-center">
							<BsMeta className="text-blue-500 w-6 h-6" />
							<p className="text-[#343741] font-bold text-lg flex items-center">
								&nbsp; &nbsp;
								<span className="block max-w-[200px] truncate text-t-default">
									{adAccount.name}
								</span>
								<span className="text-md font-normal text-t-secondary px-2">
									&nbsp;
									{adAccount.details.account_number.replace('act_', '')}
								</span>
							</p>
						</div>
						<GConfirm
							title="Synchronize PPC account"
							description="This action will pull fresh account and campaign information. Proceed?"
							open={
								demoMode || userAccess(user!.id) === 'READ' ? false : modalSync
							}
							setOpen={setModalSync}
							loading={isLoading('ASSET_SYNC_ACCOUNT')}
							onConfirm={() => onSynchronizeAccount()}
						>
							<GNewTooltip
								content={
									demoMode
										? 'This action is disabled when demo data is displayed'
										: ''
								}
							>
								<GButton
									disabled={demoMode || userAccess(user!.id) === 'READ'}
									variant="text"
									label="Synchronize"
									color="neutral"
									labelClassName="font-bold text-primary-500 text-base"
									className="h-[32px] min-w-[112px]"
									onClick={() => setModalSync(true)}
									loading={isLoading('ASSET_SYNC_ACCOUNT')}
								/>
							</GNewTooltip>
						</GConfirm>
					</div>
					<div className="flex flex-col justify-between h-[calc(100vh-100px)] py-3">
						<div>
							<div className="flex items-center py-3">
								<p className="text-t-secondary text-lg font-bold min-w-[300px]">
									Protection enabled
								</p>
								<GToggle
									checked={checked}
									activeColor={'success'}
									onChange={() => {
										setChecked((prevState) => !prevState)
									}}
								/>
							</div>
							<div className="flex items-center py-3">
								<p className="text-t-secondary text-lg font-bold min-w-[300px]">
									Account name
								</p>
								<div className="w-full">
									<GInput
										inputClassName="text-base w-full font-medium"
										placeholder="acmecorp.com"
										errorPosition="flex"
										value={settingsForm.name}
										onChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleChange(e, 'name')
										}
										error={errors?.name}
									/>
								</div>
							</div>
							<div className="flex items-center py-3">
								<p className="text-t-secondary text-lg font-bold min-w-[300px]">
									Currency
								</p>
								<div className="w-full">
									<GComboBox
										options={currenciesOptions}
										inputClass="text-base font-medium"
										value={settingsForm.currency}
										onChange={(e) => handleChange(e, 'currency')}
										filter={(item, query) =>
											item.toLowerCase().includes(query.toLocaleLowerCase())
										}
										renderOption={(i) => i}
										renderValue={(i) => i}
									/>
								</div>
							</div>
							<div className="flex items-center py-3">
								<p className="text-t-secondary text-lg font-bold min-w-[300px]">
									Timezone
								</p>
								<div className="w-full">
									<GComboBox
										options={tzList}
										value={settingsForm.timezone}
										inputClass="text-base font-medium"
										onChange={(e) => handleChange(e, 'timezone')}
										filter={(item, query) =>
											item.toLowerCase().includes(query.toLocaleLowerCase())
										}
										renderOption={(i) => i}
										renderValue={(i) => i}
									/>
								</div>
							</div>
							<div
								className={`flex items-center ${
									!settingsForm.access ? 'my-8' : 'py-3'
								}`}
							>
								<div className="text-t-secondary text-lg font-bold min-w-[300px] flex items-center">
									Authorization access &nbsp;
									<GNewTooltip content="Select a connection to Google Ads used to access this account">
										<RiQuestionLine className="text-gray-500 h-3 w-3" />
									</GNewTooltip>
								</div>
								<div className="flex justify-between items-center w-full gap-2">
									<div className="w-full">
										<div className="flex-1 relative">
											<GComboBox<any>
												options={links as []}
												inputClass="text-base font-medium"
												placeholder={
													links.length === 0
														? 'No access links for Google'
														: 'Select a connection'
												}
												value={settingsForm.access}
												onChange={(e) => handleChange(e, 'access')}
												disabled={links.length === 0}
												filter={(item, query) =>
													item?.name ||
													item?.email
														.toLowerCase()
														.includes(query.toLocaleLowerCase())
												}
												renderOption={(i) =>
													i?.name || i?.email || i?.account_number
												}
												renderValue={(i) =>
													i?.name || i?.email || i?.account_number
												}
												error={errors?.access}
											/>
											{!settingsForm.access && (
												<p className="text-base text-red-500 absolute top-[45px] left-[5px] font-medium w-[20rem]">
													Selected authorization has lost access to this account
												</p>
											)}
										</div>
									</div>
									<GNewTooltip
										content={
											demoMode
												? 'This action is disabled when demo data is displayed'
												: userAccess(user!.id) === 'READ'
												? "Analysts don't have permission for this action"
												: undefined
										}
									>
										<GButton
											variant="text"
											label="New authorization"
											disabled={demoMode || userAccess(user!.id) === 'READ'}
											color="buttonPrimary"
											className="font-bold h-[40px] w-[160px] border-t-input-border"
											size="lg"
											onClick={handleNewAuthorization}
										/>
									</GNewTooltip>
								</div>
							</div>

							<div className="flex items-center my-8">
								<p className="text-t-secondary font-bold text-base min-w-[300px]">
									Audience management
								</p>
								<div className="flex gap-2 items-center">
									<GCheckbox
										name="tracking"
										checked={settingsForm.tracking}
										onChange={(v) => handleChange(v.target.checked, 'tracking')}
									/>
									<p>Automatically set excluded audience to ad sets</p>
								</div>
							</div>
							<div className="flex items-center my-8">
								<div className="flex items-center gap-2 min-w-[300px]">
									<span className="text-t-secondary font-bold text-base">
										Audience exclusion period
									</span>
									<GNewTooltip content="How long a suspicious visitor should be prevented from seeing your ads">
										<RiQuestionLine className="text-gray-500 h-3 w-3" />
									</GNewTooltip>
								</div>
								<div className="flex justify-start items-center w-full gap-2">
									<div>
										<GInput
											inputClassName="text-base w-12 font-medium"
											placeholder="30"
											errorPosition="flex"
											defaultValue={settingsForm.period}
											value={settingsForm.period}
											onChange={(e: ChangeEvent<HTMLInputElement>) =>
												handleChange(e, 'period')
											}
											error={errors?.period}
										/>
									</div>
									<div className="w-32">
										<GComboBox
											options={periodList}
											placeholder={settingsForm.adPixelTime}
											value={settingsForm.adPixelTime}
											inputClass="text-base font-medium"
											onChange={(e) => handleChange(e, 'adPixelTime')}
											filter={(item, query) =>
												item.toLowerCase().includes(query.toLocaleLowerCase())
											}
											renderOption={(i) => i}
											renderValue={(i) => i}
										/>
									</div>
								</div>
							</div>
							<div className="flex items-center my-8">
								<div className="flex items-center gap-2 min-w-[300px]">
									<span className="text-t-secondary font-bold text-base">
										Selected pixel
									</span>
									<GNewTooltip content="Web ID to be used to track your audience behaviour">
										<RiQuestionLine className="text-gray-500 h-3 w-3" />
									</GNewTooltip>
								</div>
								<div className="w-full">
									<GComboBox
										options={adPixels?.data || []}
										placeholder={settingsForm.pixel}
										value={settingsForm.pixel}
										inputClass="text-base font-medium"
										onChange={(e) => handleChange(e, 'pixel')}
										filter={(item, query) =>
											item.toLowerCase().includes(query.toLocaleLowerCase())
										}
										renderOption={(i) => i}
										renderValue={(i) => i}
									/>
								</div>
							</div>

							<div className="flex items-center my-8 border-t border-gray-100 pt-6">
								<p className="text-t-secondary text-base font-bold min-w-[300px]">
									Delete account
								</p>
								<GConfirm
									title={`Remove ${adAccount.name}`}
									type="danger"
									description={`Are you sure you want to remove this PPC account from ${asset?.name}`}
									open={
										demoMode || userAccess(user!.id) === 'READ'
											? false
											: modalDelete
									}
									setOpen={setModalDelete}
									loading={isLoading('ASSET_DELETE_AD_ACCOUNT')}
									onConfirm={() => onDeletePPCAccount()}
								>
									<GNewTooltip
										content={
											demoMode
												? 'This action is disabled when demo data is displayed'
												: userAccess(user!.id) === 'READ'
												? "Analysts don't have permission for this action"
												: undefined
										}
									>
										<GButton
											label="Delete"
											variant="contained"
											labelClassName="font-bold"
											className="text-white h-[25px] w-[70px]"
											color="danger"
											size="lg"
											onClick={() => setModalDelete(true)}
											disabled={userAccess(user!.id) === 'READ' || demoMode}
										/>
									</GNewTooltip>
								</GConfirm>
							</div>
						</div>
						<div className="flex items-center justify-between min-h-[70px] px-4 border-t border-green-100]">
							<GButton
								variant="text"
								label="Close"
								size="lg"
								color="neutral"
								labelClassName="font-bold text-t-default"
								className="h-[30px] w-[68px] border-t-input-border"
								onClick={handleDialogClose}
							/>
							<GButton
								disabled={
									userAccess(user!.id) === 'READ' ||
									account?.billing?.subscription?.status === 'CANCELLED' ||
									demoMode
								}
								label="Save"
								variant="contained"
								color="primary"
								labelClassName="font-bold text-base"
								className="h-[30px] w-[68px]"
								onClick={() => confirmCreate()}
								loading={isLoading('ASSET_SAVE_ACCOUNT_SETTINGS')}
							/>
						</div>
					</div>
				</div>
			</GTransition>
		</Drawer>
	)
}
