import _ from 'lodash'
import { classNames } from 'utils'
import { GTableBodyProps, GTableColumn } from '../types'

export function TableBody<T>(props: GTableBodyProps<T>) {
	const { column, cell } = props
	const renderCell = (item: T, column: GTableColumn<T>) => {
		if (column.render) return column.render(item)
		if (column.path) return _.get(item, column.path)
		return <span className="text-xs text-gray-500">N/A</span>
	}

	return (
		<td
			style={
				column?.width
					? {
							width: column.width,
							maxWidth: column.width,
							minWidth: column.width,
							overflow: 'hidden'
					  }
					: {}
			}
			className={classNames(
				'whitespace-nowrap px-4 py-4 text-md text-gray-500',
				column.hidden ? 'hidden' : '',
				column.mobileHide ? 'hidden md:table-cell' : '',
				column.tabletHide ? 'hidden lg:table-cell' : ''
			)}
			{...cell.getCellProps()}
		>
			<div
				className={classNames(
					'flex w-full h-full px-2 truncate max-w-[35rem]',
					column.className || ''
				)}
			>
				{renderCell(cell.value, column)}
			</div>
		</td>
	)
}
