import {
	GoogleAdsAccountBasicInfoDTO,
	GoogleOAuthAccountLinkDTO
} from 'api-models'
import {
	GButton,
	GToggle,
	GTooltip,
	GTransition
} from 'components/basic-blocks'
import { GWindowedSelect } from 'components/basic-blocks/g-virtualized-select'
import { useState } from 'react'
import { RiLink, RiQuestionLine } from 'react-icons/ri'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { classNames } from 'utils'
import { AgencyAddAccountProps, AgencyAddAccountState } from '../types'

export const AgencyAddAccount = ({
	state,
	addMCC,
	addAccounts
}: AgencyAddAccountProps) => {
	const link = state.googleOAuthLink as GoogleOAuthAccountLinkDTO

	const [data, setData] = useState<AgencyAddAccountState>({
		mode: 'mcc',
		mcc: null,
		selected: [],
		error: null,
		tracking: true
	})

	const linkMCC = async () => {
		if (!data.mcc) return
		const err = await addMCC(data.mcc.account_number)
		setData({ ...data, error: err || null })
	}

	const submit = async () => {
		if (!data.selected) return
		const err = await addAccounts(
			data.selected.map((i) => i.account_number),
			data.tracking
		)
		setData({ ...data, error: err || null })
	}

	return (
		<div className="w-full flex flex-col items-center">
			<div className="w-full flex flex-col space-y-5 mb-4">
				<div className="flex items-center space-x-4 text-t-dark bg-[#F3F4F6] shadow rounded-lg p-4">
					<RiLink className="w-6 h-6" />
					<div className="flex flex-col text-sm">
						<span className="font-bold">{link.name}</span>
						<span className="text-t-secondary">Connected</span>
					</div>
				</div>
				<div className="text-sm text-t-secondary">
					Do you want to add your agency's MCC account as a link?
				</div>
				<div className="grid grid-cols-2 gap-2 font-medium">
					<div
						onClick={() =>
							setData({ ...data, mode: 'mcc', error: null, selected: [] })
						}
						className={classNames(
							'text-center py-2 rounded-md cursor-pointer',
							data.mode === 'mcc' ? 'bg-gray-200' : 'hover:bg-gray-100'
						)}
					>
						Add MCC link
					</div>
					<div
						onClick={() =>
							setData({ ...data, mode: 'oauth', error: null, selected: [] })
						}
						className={classNames(
							'text-center py-2 rounded-md cursor-pointer',
							data.mode === 'oauth' ? 'bg-gray-200' : 'hover:bg-gray-100'
						)}
					>
						Add directly
					</div>
				</div>
				<GTransition swap show={data.mode === 'mcc'}>
					<div className="space-y-4">
						<div className="text-sm text-t-secondary">
							After linking your MCC account, you will be able to add client
							accounts.
						</div>
						<div>
							<GWindowedSelect
								label="MCC account number"
								options={[...(link.clients || [])]}
								value={data.mcc}
								isDisabled={state.loading || !!state.mccLink}
								onChange={(newValue) =>
									setData({
										...data,
										mcc: newValue as GoogleAdsAccountBasicInfoDTO
									})
								}
								placeholder="Find account"
								isOptionSelected={(option) =>
									(option as GoogleAdsAccountBasicInfoDTO).account_number ===
									data.mcc?.account_number
								}
								formatOptionLabel={(option) =>
									(option as GoogleAdsAccountBasicInfoDTO).account_number
								}
								filterOption={(option, query) => {
									return query
										? (
												option as FilterOptionOption<GoogleAdsAccountBasicInfoDTO>
										  ).data.account_number
												?.toLocaleLowerCase()
												.includes(query)
										: true
								}}
							/>
						</div>
						<GTransition swap show={!state.mccLink}>
							<div className="flex space-x-1 items-center justify-center">
								<GButton
									color="primary"
									className="w-full mt-4"
									onClick={() => linkMCC()}
									disabled={state.loading || !data.mcc}
									loading={state.loading}
								>
									<div className="flex items-center">
										{data.mcc ? (
											<span>Continue</span>
										) : (
											<span>Link MCC account</span>
										)}
									</div>
								</GButton>
							</div>
						</GTransition>
						<GTransition swap show={!!state.mccLink}>
							<div className="space-y-4">
								<GWindowedSelect
									label="Client accounts"
									options={[...(state.mccLink?.clients || [])]}
									value={data.selected}
									isMulti={true}
									isDisabled={state.loading}
									optionKey="account_number"
									onChange={(newValue) =>
										setData({
											...data,
											selected: newValue as GoogleAdsAccountBasicInfoDTO[]
										})
									}
									placeholder="Find account"
									getOptionValue={(option) =>
										(option as GoogleAdsAccountBasicInfoDTO).account_number
									}
									getOptionLabel={(option) =>
										(option as GoogleAdsAccountBasicInfoDTO).account_number
									}
									isOptionSelected={(option) =>
										data.selected.includes(
											option as GoogleAdsAccountBasicInfoDTO
										)
									}
									formatOptionLabel={(option) =>
										(option as GoogleAdsAccountBasicInfoDTO).account_number
									}
									filterOption={(option, query) => {
										return query
											? (
													option as FilterOptionOption<GoogleAdsAccountBasicInfoDTO>
											  ).data.account_number
													?.toLocaleLowerCase()
													.includes(query)
											: true
									}}
								/>
								<div className="flex space-x-1 items-center">
									<GToggle
										checked={data.tracking}
										onChange={(v) => setData({ ...data, tracking: v })}
										label="Automatically configure click tracking"
									/>
									<GTooltip content="Sets ClickGUARD tracking template on your Google Ads account">
										<RiQuestionLine className="text-t-secondary w-3" />
									</GTooltip>
								</div>
								<div className="flex space-x-1 items-center justify-center">
									<GButton
										color="primary"
										onClick={() => submit()}
										disabled={state.loading || data.selected.length === 0}
										loading={state.loading}
									>
										<div className="flex items-center">
											<span>
												Add {data.selected?.length || ''} selected accounts
											</span>
										</div>
									</GButton>
								</div>
							</div>
						</GTransition>
					</div>
				</GTransition>
				<GTransition swap show={data.mode === 'oauth'}>
					<div className="space-y-4">
						<div className="text-sm text-t-secondary">
							Add managed clients directly from your connected Google account.
						</div>
						<div>
							<GWindowedSelect
								label="Available accounts"
								options={[...(link.clients || [])]}
								value={data.selected}
								isDisabled={state.loading}
								isMulti={true}
								onChange={(newValue) =>
									setData({
										...data,
										selected: newValue as GoogleAdsAccountBasicInfoDTO[]
									})
								}
								placeholder="Find account"
								getOptionValue={(option) =>
									(option as GoogleAdsAccountBasicInfoDTO).account_number
								}
								getOptionLabel={(option) =>
									(option as GoogleAdsAccountBasicInfoDTO).account_number
								}
								isOptionSelected={(option) =>
									data.selected.includes(option as GoogleAdsAccountBasicInfoDTO)
								}
								formatOptionLabel={(option) =>
									(option as GoogleAdsAccountBasicInfoDTO).account_number
								}
								filterOption={(option, query) => {
									return query
										? (
												option as FilterOptionOption<GoogleAdsAccountBasicInfoDTO>
										  ).data.account_number
												?.toLocaleLowerCase()
												.includes(query)
										: true
								}}
							/>
						</div>
						<div className="flex space-x-1 items-center">
							<GToggle
								checked={data.tracking}
								onChange={(v) => setData({ ...data, tracking: v })}
								label="Automatically configure click tracking"
							/>
							<GTooltip content="Sets ClickGUARD tracking template on your Google Ads account">
								<RiQuestionLine className="text-t-secondary w-3" />
							</GTooltip>
						</div>
						<div className="flex space-x-1 items-center justify-center">
							<GButton
								color="primary"
								onClick={() => submit()}
								disabled={state.loading || data.selected.length === 0}
								loading={state.loading}
							>
								<div className="flex items-center">
									<span>
										Add {data.selected?.length || ''} selected accounts
									</span>
								</div>
							</GButton>
						</div>
					</div>
				</GTransition>

				{data.error && (
					<div className="flex space-x-1 items-center justify-center text-red-700">
						{data.error}
					</div>
				)}
			</div>
		</div>
	)
}
