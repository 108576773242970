import {
	Elements,
	PaymentElement,
	useElements,
	useStripe
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { GDialog, GLoading } from 'components/basic-blocks'
import { GButton } from 'components/basic-blocks/g-button'
import { StripeConfig } from 'config'
import { useStores } from 'hooks'
import { useThemeStore } from 'layout/navbar/store'
import { useEffect, useState } from 'react'
import { RiLoader3Line } from 'react-icons/ri'
import { NewPaymentMethodProps } from './types'

const stripe = loadStripe(StripeConfig.publicKey)

export const NewPaymentMethod = ({
	show,
	onSelect,
	onClose,
	newWorkspace = false
}: NewPaymentMethodProps) => {
	const {
		billingStore,
		accountStore: { account }
	} = useStores()

	useEffect(() => {
		;(async () => {
			await billingStore.initBilling()
		})()
	}, [])

	const { theme } = useThemeStore()

	const select = async (pmData: string) => {
		if (!newWorkspace) {
			const pm = await billingStore.addPaymentMethod(
				pmData,
				account?.id || null
			)
			if (pm) {
				onSelect(pm)
				onClose()
			}
		} else {
			onSelect(pmData)
			onClose()
		}
	}

	if (!billingStore.intent) return <></>

	return (
		<GDialog title="New Payment Method" open={show} onClose={onClose}>
			<Elements
				stripe={stripe}
				options={{
					clientSecret: billingStore.intent.client_secret,
					appearance: { theme: theme === 'dark' ? 'night' : 'none' }
				}}
			>
				<PaymentMethodForm onSelect={select} />
			</Elements>
		</GDialog>
	)
}

export const PaymentMethodForm = (props: {
	buttonLabel?: string
	buttonIcon?: any
	loading?: boolean
	onSelect: (pm: string) => void
}) => {
	const stripe = useStripe()
	const elements = useElements()
	const [ready, setReady] = useState(true)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)

	const submit = async () => {
		if (!stripe || !elements) {
			return
		}
		setLoading(true)
		const setup = await stripe.confirmSetup({
			elements: elements,
			redirect: 'if_required'
		})

		if (setup.error) {
			setLoading(false)
			setError(setup.error.message || 'Unexpected error, please try again')
			return
		}

		setLoading(false)

		const pmData = setup.setupIntent?.payment_method

		if (!pmData) {
			return
		}
		if (typeof pmData === 'string') {
			props.onSelect(pmData)
			return
		}
		props.onSelect(pmData.id)
	}

	if (!stripe || !elements || !ready) {
		return <GLoading />
	}

	return (
		<div className="mt-6 sm:flex sm:items-center sm:justify-center flex-col">
			<div className="w-full ">
				<PaymentElement onReady={() => setReady(true)} />
			</div>
			{error && (
				<p className="mt-1 text-sm text-red-500" id="email-description">
					{error}
				</p>
			)}
			<div className="w-3xl mt-5 space-x-4">
				<GButton
					disabled={!stripe || loading || props.loading}
					color="primary"
					onClick={() => submit()}
					className="flex"
					loading={loading || props.loading}
					icon={props.buttonIcon}
				>
					{loading ? (
						<RiLoader3Line className="animate-spin w-6 h-6" />
					) : props.buttonLabel ? (
						props.buttonLabel
					) : (
						'Authorize Payment Method'
					)}
				</GButton>
			</div>
		</div>
	)
}
