import {
	GButton,
	GComboBox,
	GInput,
	GTransition
} from 'components/basic-blocks'
import { usePostHog } from 'posthog-js/react'
import { OnBoarding } from 'components/onboarding/onboarding'
import { useEffect, useState } from 'react'
import { ConfirmUserViewProps } from './types'
import timezones from 'timezones-list'
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
import { useThemeStore } from '../../../layout/navbar/store'
import { parsePhoneNumber } from 'awesome-phonenumber'

export const ConfirmUserView = ({
	state,
	user,
	setName,
	setPhone,
	setTimezone,
	onNext
}: ConfirmUserViewProps) => {
	const { theme } = useThemeStore()
	const [errors, setErrors] = useState<string[]>([])

	const posthog = usePostHog()

	const tzList = timezones.map((item) => item.tzCode)

	const submit = () => {
		const err = []
		if (!state.name) err.push('name')
		const phoneNumber = parsePhoneNumber(
			state.phone_number?.startsWith('+')
				? state.phone_number
				: `+${state.phone_number}`
		)

		if (!phoneNumber.valid) {
			err.push('phone_number')
		}
		setErrors(err)
		if (err.length === 0) {
			posthog?.capture('click-on-cta-onboarding-screen-1')
			onNext()
		}
	}

	const handlePhoneChange = (data: any, value: string) => {
		if (value.startsWith(`${data.dialCode}`)) {
			setPhone(value)
		} else {
			setPhone(`${data.dialCode}`)
		}
	}

	useEffect(() => {
		setErrors([])
	}, [state])

	return (
		<OnBoarding
			state={state}
			setStep={() => null}
			title={
				<div className="text-3xl text-[#FFF] font-[800]">
					Welcome to <span className="text-green-400">ClickGUARD</span>{' '}
				</div>
			}
			description="We are thrilled to have you here! Please take a couple of minutes to setup your account."
		>
			<GTransition show={state.step === 'confirmation'} swap>
				<GInput
					label="Your email"
					className="mb-4"
					value={user.email}
					disabled={true}
					onChange={() => null}
				/>
				<GInput
					label="Your name"
					className="mb-4"
					inputClassName="sm:text-sm placeholder:text-sm placeholder:text-t-default text-t-default w-full"
					placeholder="John Doe"
					value={state.name || undefined}
					disabled={state.loading}
					error={
						errors.includes('name') ? 'You need to set your name' : undefined
					}
					onChange={(e) => setName(e.target.value)}
				/>
				<div className="pb-4">
					<label className="text-left block text-t-default mb-1 text-md font-bold">
						Phone number
					</label>

					<PhoneInput
						defaultErrorMessage="Invalid phone number"
						countryCodeEditable={false}
						inputStyle={{
							width: '100%',
							borderColor: theme === 'dark' ? '#282d3e' : '#e3e7fc',
							background: 'transparent'
						}}
						buttonStyle={{
							borderColor: theme === 'dark' ? '#282d3e' : '#e3e7fc',
							background: 'transparent'
						}}
						dropdownStyle={{
							borderColor: theme === 'dark' ? '#282d3e' : '#e3e7fc',
							background: theme === 'dark' ? '#242838' : '#FFFFFF'
						}}
						value={state.phone_number}
						onChange={(e, data) => handlePhoneChange(data, e)}
					/>
					<p className="text-xs text-red-800">
						{errors.includes('phone_number')
							? 'Please enter a valid phone number'
							: undefined}
					</p>
				</div>
				<GComboBox
					label="Timezone"
					className="text-t-default"
					options={tzList}
					value={state.timezone}
					onChange={(e) => setTimezone(e || '')}
					disabled={state.loading}
					filter={(item, query) =>
						!!item?.toLowerCase().includes(query.toLocaleLowerCase())
					}
					renderOption={(i) => i || 'Select your timezone'}
					renderValue={(i) => i || 'Select your timezone'}
				/>
				{state.error && (
					<div className="text-sm text-red-700 text-center">{state.error}</div>
				)}
				<div className="flex justify-center">
					<GButton
						color="primary"
						iconPlacement="right"
						className="w-full mt-6"
						onClick={submit}
						disabled={state.loading}
						loading={state.loading}
					>
						<span className="px-4">Looks good!</span>
					</GButton>
				</div>
				<GTransition show={state.loading}>
					<div className="flex justify-center text-sm">
						Preparing your account...
					</div>
				</GTransition>
			</GTransition>
		</OnBoarding>
	)
}
