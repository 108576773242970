import { Virtuoso } from 'react-virtuoso'
import { FC } from 'react'

interface IVirtuosoVirtualizedListProps {
	length: number
	children: any
	height?: string | number
}

export const GVirtuosoList: FC<IVirtuosoVirtualizedListProps> = ({
	length,
	children,
	height
}) => {
	return (
		<Virtuoso
			style={{ height: height || 400 }}
			totalCount={length}
			itemContent={(index) => {
				return <>{children(index)}</>
			}}
		/>
	)
}
