import {
	ChangeEvent,
	createContext,
	ReactNode,
	useEffect,
	useState
} from 'react'
import { useSearchParams } from 'react-router-dom'

export type HandleSubItemChangeProps = {
	event: ChangeEvent<HTMLInputElement>
	key: string
	value: string | string[]
}

export type OptionSubContentProps = {
	handleSubItemChange: ({ event, key, value }: HandleSubItemChangeProps) => void
	mapState: Map<any, any>
}

export type FiltersType = {
	[key: string]: string | string[] | undefined
	status?: string[]
	tier?: string
}

interface AffiliatePageFiltersContextType {
	filters: FiltersType
	mapState: Map<any, any>
	handleSubItemChange: ({ event, key, value }: HandleSubItemChangeProps) => void
	handleRemoveFilter: (filterKeyToRemove: string) => void
	handleOpenClickDetailsChange: (open: boolean) => void
}
export const AffiliatePageFiltersContext = createContext(
	{} as AffiliatePageFiltersContextType
)

interface AffiliatePageFiltersContextProviderProps {
	children: ReactNode
}
export function AffiliatesPageFiltersContextProvider({
	children
}: AffiliatePageFiltersContextProviderProps) {
	const [searchParams, setSearchParams] = useSearchParams()
	const [filters, setFilters] = useState({} as FiltersType)
	const [mapState, setMapState] = useState(new Map())
	const isAffiliatePage = window.location.pathname.includes('affiliate')

	useEffect(() => {
		if (!isAffiliatePage) return

		const filters = {}
		const map = new Map()

		searchParams.forEach((value, key) => {
			if (['status', 'tier'].includes(key)) {
				Object.assign(filters, { [key]: value })
				map.set(key, value)
			}
		})

		setFilters(filters)
		setMapState(map)
		setSearchParams(searchParams)
	}, [])

	const handleApplyFilters = (newFilters: FiltersType) => {
		setFilters(newFilters)
		Object.keys(newFilters).map((key) => {
			const value = newFilters[key]
			if (value)
				searchParams.set(key, Array.isArray(value) ? value.join(',') : value)
		})
		setSearchParams(searchParams)
	}

	const handleSubItemChange = ({
		event,
		key,
		value
	}: HandleSubItemChangeProps) => {
		event.stopPropagation()
		if (value.length === 0) {
			handleRemoveFilter(key)
			return
		}
		setMapState((map) => new Map(map.set(key, value)))
	}

	const handleRemoveFilter = (filterKeyToRemove: string) => {
		const copy = mapState
		copy.delete(filterKeyToRemove)
		setMapState(new Map(copy))
		const filters = mapFilters(copy)
		setFilters(filters)
		searchParams.delete(filterKeyToRemove)
		setSearchParams(searchParams)
	}

	const handleOpenClickDetailsChange = (open: boolean) => {
		if (!open) {
			const filters = mapFilters(mapState)
			handleApplyFilters(filters)
		}
	}

	const mapFilters = (map: Map<string, string>) => {
		const filters = {}
		map.forEach((value, key) => Object.assign(filters, { [key]: value }))
		return filters as FiltersType
	}

	return (
		<AffiliatePageFiltersContext.Provider
			value={{
				filters,
				mapState,
				handleRemoveFilter,
				handleOpenClickDetailsChange,
				handleSubItemChange
			}}
		>
			{children}
		</AffiliatePageFiltersContext.Provider>
	)
}
