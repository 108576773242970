import { GLoading, GTransition } from 'components/basic-blocks'
import { PageHeader } from 'components/page-header'
import { TabItem } from 'components/tabs/types'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { RiMailLine } from 'react-icons/ri'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { InfoTab } from './info-tab/info-tab'
import { NotesTab } from './notes-tab'

export const AdminUserPage = () => {
	const { userId } = useParams()

	const navigate = useNavigate()
	const { adminStore } = useStores()
	const { getUserById, user, getNotes } = adminStore

	const [total, setTotal] = useState(0)

	const fetchData = async (skip: number) => {
		if (!userId) return
		const { total } = await getNotes(userId, {
			type: 'website',
			skip: skip,
			limit: 6
		})
		setTotal(total)
	}

	useEffect(() => {
		;(async () => {
			await fetchData(0)
		})()
	}, [])

	const [search] = useSearchParams('tab=info')

	const tabs: Array<TabItem> = [
		{
			key: 'info',
			label: 'Information',
			to: () => `/admin/user/${user?.id}/?tab=info`
		},
		{
			key: 'notes',
			label: (
				<div>
					Notes{' '}
					<span
						className={`${
							search.get('tab') === 'notes'
								? 'bg-badge-selected text-badge-text-selected'
								: 'bg-badge-default text-badge-text-t-default'
						} text-sm font-medium mr-2 mx-2.5 px-1.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300`}
					>
						{total}
					</span>
				</div>
			),
			to: () => `/admin/user/${user?.id}/?tab=notes`
		}
	]

	const [selectedTab, setSelectedTab] = useState(
		search.get('tab') || tabs[0].key
	)

	const init = async () => {
		if (userId) {
			await getUserById(userId)
			return
		}
		navigate('/admin/users')
	}

	useEffect(() => {
		init()
	}, [userId])

	useEffect(() => {
		const tab = search.get('tab') || tabs[0].key
		setSelectedTab(tab)
	}, [search, userId])

	if (!user) {
		return <GLoading />
	}

	return (
		<div className="">
			<PageHeader
				title={user.name || '[No name]'}
				tags={[{ icon: RiMailLine, label: user.contact_email || 'No email' }]}
				tabs={tabs}
				currentTab={selectedTab}
			/>
			<div className="w-full px-4 sm:px-6">
				<GTransition swap show={selectedTab === 'info'}>
					<InfoTab user={user} />
				</GTransition>
				<GTransition swap show={selectedTab === 'notes'}>
					<NotesTab topicId={user.id} total={total} setTotal={setTotal} />
				</GTransition>
			</div>
		</div>
	)
}
