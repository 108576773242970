import { useAuth0 } from '@auth0/auth0-react'
import {
	AccountSetupInfoDTO,
	BingAccountLinkDTO,
	FacebookAccountLinkDTO,
	GoogleOAuthAccountLinkDTO,
	MCCAccountLinkDTO
} from 'api-models'
import { BingAuthConfig, FacebookAuthConfig, GoogleAuthConfig } from 'config'
import { useStores } from 'hooks'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AdAccountLinks, Onboarding, ProductAccounts } from 'services/user'
import { parseError } from 'utils/errors'
import { getOAuthCodeKey } from 'utils/oauth'
import { AddAccountProps, Platform, SetupState } from './types'
import { SetupView } from './view'
import { setItemsToLocalStorage } from '../../utils'

export const Setup = () => {
	const navigate = useNavigate()
	const { isAuthenticated, loginWithRedirect } = useAuth0()
	const { accountId } = useParams()
	const { accountStore, assetStore } = useStores()
	const { setWantsToSeeDemoData, setAccountForId, getAllAccounts } =
		accountStore
	const { setAssetForId } = assetStore

	if (!isAuthenticated) {
		loginWithRedirect({
			appState: {
				returnTo: window.location.pathname + window.location.search
			}
		})
		return <></>
	}

	const [state, setState] = useState<SetupState>({
		account: null,
		googleOAuthLink: null,
		fbLink: null,
		bingOAuthLink: null,
		mccLink: null,
		loading: false,
		redirectLoading: false,
		waiting: false,
		step: null,
		platform: 'GADS',
		email: null,
		newWebsite: false,
		newPPCAccount: false
	})

	const init = async (accountId: string) => {
		setState({ ...state, loading: true })
		const account = await ProductAccounts.getById(accountId)
		const setup = account.setup as AccountSetupInfoDTO

		if (setup.link_id) {
			const link = await AdAccountLinks.getById(accountId, setup.link_id)
			setState({
				...state,
				loading: false,
				redirectLoading: false,
				account: account,
				step: setup.step,
				platform: link.platform,
				googleOAuthLink:
					link.platform === 'GADS' ? (link as GoogleOAuthAccountLinkDTO) : null,
				fbLink:
					link.platform === 'FADS' ? (link as FacebookAccountLinkDTO) : null,
				bingOAuthLink:
					link.platform === 'BADS' ? (link as BingAccountLinkDTO) : null
			})
			return
		}

		setState({ ...state, loading: false, account: account, step: setup.step })
	}

	const createLink = async (code: string) => {
		if (!accountId) return
		await Onboarding.setAccountLink(accountId, code, state.platform)
		await init(accountId)
	}

	const setStep = (step: string) => {
		if (!state.account) return

		const setup = state.account.setup as AccountSetupInfoDTO
		if (setup.step === 'link') return

		setState({ ...state, step: step, waiting: false, mccLink: null })
	}

	const authorizePlatform = (platform: Platform) => {
		if (platform === 'GADS') {
			setState({ ...state, platform: 'GADS' })
			window.open(GoogleAuthConfig.AUTH_URL, '_blank')
		}
		if (platform === 'FADS') {
			setState({ ...state, platform: 'FADS' })
			window.open(FacebookAuthConfig.AUTH_URL, '_blank')
		}
		if (platform === 'BADS') {
			setState({ ...state, platform: 'BADS' })
			window.open(BingAuthConfig.AUTH_URL, '_blank')
		}

		setState({ ...state, waiting: true, platform: platform })
	}

	const resetAuth = () => {
		setState({ ...state, waiting: false })
	}

	const addAccount = async ({
		number,
		linkId,
		setTrackingTemplate,
		setCustomAudience
	}: AddAccountProps) => {
		if (!accountId) return

		try {
			setState({ ...state, loading: true })
			const resp = await Onboarding.addAccount(accountId, {
				account_number: number,
				link_id: linkId,
				set_tracking_template: setTrackingTemplate,
				set_custom_audience: setCustomAudience
			})

			if (resp.type === 'link') {
				const mccLink = resp.data as MCCAccountLinkDTO
				setState({ ...state, loading: false, mccLink: mccLink })
				return
			}

			setState({ ...state, loading: false })
			await Promise.all([
				setAccountForId(accountId),
				getAllAccounts(),
				setAssetForId(accountId, resp.data.asset_id)
			])
			navigate(`/workspace/${accountId}/asset/${resp.data.asset_id}`)
		} catch (err) {
			setState({ ...state, loading: false })
			return parseError(err)
		}
		return
	}

	const addMCC = async (number: string) => {
		if (!accountId) return

		try {
			setState({ ...state, loading: true })
			const resp = await Onboarding.addAccount(accountId, {
				account_number: number,
				set_tracking_template: false,
				manager: true
			})

			if (resp.type === 'link') {
				const mccLink = resp.data as MCCAccountLinkDTO
				setState({ ...state, loading: false, mccLink: mccLink })
				return
			}

			setState({ ...state, loading: false })
		} catch (err) {
			setState({ ...state, loading: false })
			return parseError(err)
		}
		return
	}

	const addAccounts = async (
		numbers: string[],
		tracking: boolean,
		set_custom_audience?: boolean
	) => {
		if (!accountId) return

		try {
			setState({ ...state, loading: true })
			await Onboarding.addAccounts(accountId, {
				account_numbers: numbers,
				link_id: state.mccLink?.id,
				set_tracking_template: tracking,
				set_custom_audience: set_custom_audience
			})

			setState({ ...state, loading: false })

			navigate(`/workspace/${accountId}`)
		} catch (err) {
			setState({ ...state, loading: false })
			return parseError(err)
		}
		return
	}

	const handleBack = useCallback(async () => {
		if (state.account) {
			setState({ ...state, redirectLoading: true })
			setWantsToSeeDemoData()
			await Promise.all([
				setAccountForId(state.account.id),
				setAssetForId(state.account.id, 'website-demo')
			])
			setState({ ...state, redirectLoading: false })
			setItemsToLocalStorage([
				{ key: 'selected_asset', value: 'website-demo' },
				{ key: 'selected_account', value: state.account.id }
			])
			navigate(`/workspace/${state.account.id}/asset/website-demo`)
		}
	}, [state.account])

	useEffect(() => {
		if (state.step === 'link' && state.waiting) {
			const interval = setInterval(() => {
				const codeKey = getOAuthCodeKey(state.platform)
				const code = window.localStorage.getItem(codeKey)
				if (code) {
					clearInterval(interval)
					window.localStorage.removeItem(codeKey)
					createLink(code)
				}
			}, 1000)
		}
	}, [state.step, state.waiting])

	useEffect(() => {
		if (accountId) {
			init(accountId)
		}
	}, [accountId])

	return (
		<SetupView
			setState={setState}
			state={state}
			setStep={setStep}
			authorizePlatform={authorizePlatform}
			resetAuth={resetAuth}
			addAccount={addAccount}
			addMCC={addMCC}
			addAccounts={addAccounts}
			handleBack={handleBack}
		/>
	)
}
