import { FC } from 'react'
import './note-list.css'
import { createMarkUp } from './types'

interface HTMLRendererProps {
	htmlString: string
}

export const HTMLRenderer: FC<HTMLRendererProps> = ({ htmlString }) => {
	return <div dangerouslySetInnerHTML={createMarkUp(htmlString)} />
}
