import { useEffect, useMemo, useState } from 'react'

import { useStores } from 'hooks'
import { Onboarding } from 'services/user'
import {
	ActivateAccountProps,
	ActivateAccountState,
	ProtectionPlanState,
	TrialStartState
} from './types'
import { ActivateAccountView } from './view'
import { GTransition } from '../basic-blocks'
import { MccLinkConnection } from '../mcc-link-connection'
import { GoogleAdsAdAccountDTO } from 'api-models'
import { useNavigate } from 'react-router-dom'

export const ActivateAccount = ({
	open,
	close,
	isPlanChange
}: ActivateAccountProps) => {
	const navigate = useNavigate()
	const [step, setStep] = useState<'ACTIVATION' | 'LINK'>('ACTIVATION')
	const { billingStore, userStore, accountStore, assetStore } = useStores()
	const { user } = userStore
	const { account, setAccountForId, getAllAccounts, changeSubscription } =
		accountStore

	const [state, setState] = useState<ActivateAccountState>({
		step: 'plan',
		has_trial: !user?.used_trial,
		qualified_lead: false,
		legacy: false,
		legacy_lost: false,
		protection_plan: null,
		loading: false,
		isPlanChange: Boolean(isPlanChange)
	})
	const { setDemoMode, adAccounts, accountsWithAssets, setAssetForId } =
		assetStore

	const adAccount: GoogleAdsAdAccountDTO | null = useMemo(() => {
		const ad = adAccounts.find(
			(c: any) => c?.details?.mcc_support_connection === 'PENDING'
		) as GoogleAdsAdAccountDTO
		return ad || null
	}, [adAccounts])

	useEffect(() => {
		setState({
			...state,
			qualified_lead: !!user?.tags.find(
				(item) => item.tag_name === 'qualified_lead'
			),
			legacy: !!user?.tags.find((item) => item.tag_name === 'legacy'),
			legacy_lost: !!user?.tags.find((item) => item.tag_name === 'legacy_lost'),
			isPlanChange: Boolean(isPlanChange)
		})
	}, [user, isPlanChange])

	useEffect(() => {
		billingStore.initBilling()
		billingStore.getPaymentMethods()
	}, [])

	const setProtectionPlan = (plan: ProtectionPlanState) => {
		setState({ ...state, protection_plan: plan, step: 'subscription' })
	}

	const handleNavigate = (accountId: string) => {
		const asset =
			accountsWithAssets.find((a) => a.id === accountId)?.assets?.[0] ?? null
		if (asset?.id) {
			setAssetForId(accountId, asset.id)
			navigate(`/workspace/${accountId}/asset/${asset.id}`)
		}
	}
	const confirmTrial = async (
		trial: TrialStartState,
		extendedTrial: boolean
	) => {
		if (!account || !state.protection_plan) {
			return
		}
		const plan = state.protection_plan
		if (plan.custom_plan) {
			setState({ ...state, loading: true })
			await Onboarding.activateWithQuote(account.id, {
				spend: plan.spend,
				billing_period: plan.billing_period,
				pm_id: trial.pm_id,
				pm_data: trial.pm_token
			})
			getAllAccounts()
			if (adAccount?.details.mcc_support_connection === 'PENDING') {
				setStep('LINK')
			} else {
				await Promise.all([getAllAccounts(), setAccountForId(account.id)])
				handleNavigate(account.id)
				close()
			}
			setState({ ...state, loading: false })
			return
		}
		if (!plan.selected_tier || !plan.selected_price) {
			return
		}
		setState({ ...state, loading: true })
		await Onboarding.activate(account.id, {
			tier: plan.selected_tier.tier,
			price_id: plan.selected_price.price_id,
			auto_upgrade: plan.auto_upgade,
			extended_trial: extendedTrial,
			pm_id: trial.pm_id,
			pm_data: trial.pm_token,
			coupon_code: trial.coupon_code
		})
		setState({ ...state, loading: false })
		setDemoMode(false)
		getAllAccounts()
		if (adAccount?.details.mcc_support_connection === 'PENDING') {
			setStep('LINK')
		} else {
			await Promise.all([
				setAccountForId(account.id),
				setTimeout(
					() => accountStore?.getSubscriptionByAccountId(account.id),
					6000
				),
				getAllAccounts()
			])
			handleNavigate(account.id)
			close()
		}
	}

	const handleChangePlan = async (pm: string) => {
		if (!account || !state.protection_plan || !isPlanChange) {
			return
		}
		const plan = state.protection_plan
		if (!plan || !plan.selected_tier || !plan.selected_price) {
			return
		}
		setState({ ...state, loading: true })
		const payload = {
			tier: plan.selected_tier.tier,
			price_id: plan.selected_price.price_id,
			coupon_code: plan.coupon_code,
			pm_id: pm || null
		}
		await changeSubscription(account!.id, payload)
		setState({ ...state, loading: false })
		setStep('ACTIVATION')
		close()
		return
	}

	const confirmLegacy = async () => {
		if (!account) {
			return
		}
		setState({ ...state, loading: true })

		await Onboarding.legacy(account.id)
		await Promise.all([getAllAccounts(), setAccountForId(account.id)])

		setState({ ...state, loading: false })
		setStep('ACTIVATION')
		close()
	}

	const closeLinkDialog = async () => {
		if (!account) return
		getAllAccounts()
		if (state?.protection_plan?.custom_plan) {
			await Promise.all([getAllAccounts(), setAccountForId(account.id)])
		} else {
			await Promise.all([
				setAccountForId(account.id),
				setTimeout(
					() => accountStore?.getSubscriptionByAccountId(account.id),
					6000
				)
			])
		}
		setStep('ACTIVATION')
		handleNavigate(account.id)
		close()
	}

	return (
		<>
			<GTransition show={step === 'ACTIVATION'} swap>
				<ActivateAccountView
					open={open}
					close={close}
					state={state}
					setStep={(s) => setState({ ...state, step: s })}
					setProtectionPlan={setProtectionPlan}
					confirmTrial={confirmTrial}
					confirmLegacy={confirmLegacy}
					handleChangePlan={handleChangePlan}
				/>
			</GTransition>
			<GTransition show={step === 'LINK'} swap>
				<MccLinkConnection
					open={step === 'LINK'}
					onClose={closeLinkDialog}
					heading="ClickGUARD Workspace Activation"
					state="ACTIVATION"
					adAccount={adAccount}
				/>
			</GTransition>
		</>
	)
}
