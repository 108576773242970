export const transformPeriodToSeconds = (period: string, type: string) => {
	if (type === 'hours') {
		return Number(period) * 60 * 60
	}
	if (type === 'minutes') {
		return Number(period) * 60
	}
	if (type === 'days') {
		return Number(period) * 60 * 60 * 24
	}
	return Number(period)
}

export const transformSecondsToPeriod = (seconds: number) => {
	if (seconds < 60) {
		return {
			period: seconds.toString(),
			type: 'seconds'
		}
	}
	if (seconds < 3600) {
		return {
			period: Math.floor(seconds / 60).toString(),
			type: 'minutes'
		}
	}
	if (seconds < 86400) {
		return {
			period: Math.floor(seconds / 3600).toString(),
			type: 'hours'
		}
	}
	return {
		period: Math.floor(seconds / 86400).toString(),
		type: 'days'
	}
}
