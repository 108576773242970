/* eslint-disable no-prototype-builtins */
import {VisualPreferencesItem} from 'api-models'
import {ActivateAccount} from 'components/activate-account/activate-account'
import {AddNewPPCAccount} from 'components/add-new-ppc-account'
import {AddNewSite} from 'components/add-new-site'
import {GButton, GTooltip} from 'components/basic-blocks'
import {AppConfig} from 'config'
import {AssetSwitcher} from 'layout/sidebar/menus/asset-switcher'
import {CollapseSidebar} from 'layout/sidebar/menus/collapse-sidebar'
import {useEffect, useState} from 'react'
import {AiOutlineCheck} from 'react-icons/ai'
import {GoChevronRight} from 'react-icons/go'
import {RiAddLine, RiBook3Line, RiMenu2Line, RiMoonLine, RiSunLine} from 'react-icons/ri'
import {useLocation, useNavigate} from 'react-router-dom'
import {AdAccountsList} from './ad-accounts'
import {Impersonation} from './impersonation/impersonation'
import {Notifications} from './notifications'
import {AdminSearch} from './search-website/admin-search'

import {useAccountStore, useAssetStore, useUiStore} from 'store'
import {AdPlatformTypesDTO} from 'utils/platform'
import {publicRoutes} from 'utils/public-routes'
import {useShallow} from 'zustand/react/shallow'
import {useThemeStore} from './store'
import {useStores} from 'hooks'

export const Navbar = () => {
	const navigate = useNavigate()
	const { accountStore, userStore, assetStore } = useStores()
	const { setMobileSidebarOpen, mobileSidebarOpen, desktopSidebarCollapsed } =
		useUiStore(
			useShallow((s) => ({
				setMobileSidebarOpen: s.setMobileSidebarOpen,
				mobileSidebarOpen: s.mobileSidebarOpen,
				desktopSidebarCollapsed: s.desktopSidebarCollapsed
			}))
		)

	const { getAllAccounts, isInSetup } = accountStore
	const { getAllAssets } = assetStore

	const { account, accounts } = useAccountStore(
		useShallow((s) => ({
			account: s.account,
			accounts: s.accounts
		}))
	)

	const { adAccounts, accountsWithAssets, demoMode } = useAssetStore(
		useShallow((s) => ({
			adAccounts: s.adAccounts,
			accountsWithAssets: s.accountsWithAssets,
			demoMode: s.demoMode
		}))
	)

	const { user, visualPreferences, updateVisualPreferences } = userStore
	const { theme, switchTheme } = useThemeStore()
	const location = useLocation()

	const [hasAccess, setHasAccess] = useState(false)
	const [activeTheme, setActiveTheme] = useState(theme || 'light')

	useEffect(() => {
		getAllAccounts()
		getAllAssets()
	}, [demoMode])

	useEffect(() => {
		if (user?.id) {
			const userAccessInfo =
				account?.user_access &&
				account.user_access.find(
					(userAccess) => userAccess.user_id === user?.id
				)
			const access = userAccessInfo?.access_level === 'READ'
			setHasAccess(access)
		}
	}, [account?.id])

	const selectedAccount = accountsWithAssets.find((oneAccount) => {
		return oneAccount?.id === account?.id
	})

	const accountHasAssets =
		selectedAccount?.assets && selectedAccount.assets.length > 0
	const castedAdAccounts = adAccounts as AdPlatformTypesDTO[]

	/**
	 * Make <picture> <source> elements with media="(prefers-color-scheme:)"
	 * respect custom theme preference overrides.
	 * Otherwise the `media` preference will only respond to the OS-level setting
	 */
	const updateSourceMedia = (colorPreference: 'light' | 'dark'): void => {
		const pictures = document.querySelectorAll('picture')

		pictures.forEach((picture) => {
			const sources: NodeListOf<HTMLSourceElement> = picture.querySelectorAll(`
        source[media*="prefers-color-scheme"],
        source[data-media*="prefers-color-scheme"]
      `)

			sources.forEach((source) => {
				// Preserve the source `media` as a data-attribute
				// to be able to switch between preferences
				if (source?.media.includes('prefers-color-scheme')) {
					source.dataset.media = source.media
				}

				// If the source element `media` target is the `preference`,
				// override it to 'all' to show
				// or set it to 'none' to hide
				if (source?.dataset?.media?.includes(colorPreference)) {
					source.media = 'all'
				} else if (source) {
					source.media = 'none'
				}
			})
		})
	}

	const isPublicRoute = publicRoutes.find((route) =>
		window.location.pathname.startsWith(route)
	)

	useEffect(() => {
		if (isPublicRoute) return

		if (
			!visualPreferences ||
			JSON.parse(visualPreferences).find((v: VisualPreferencesItem) =>
				v.hasOwnProperty('theme')
			) === undefined
		) {
			if (
				localStorage.theme === 'dark' ||
				(!('theme' in localStorage) &&
					window.matchMedia('(prefers-color-scheme: dark)').matches)
			) {
				switchTheme('dark')
				setActiveTheme('dark')
				updateVisualPreferences(JSON.stringify([{ theme: 'dark' }]))
			} else {
				switchTheme('light')
				setActiveTheme('light')
				updateVisualPreferences(JSON.stringify([{ theme: 'light' }]))
			}
		} else {
			const { theme } =
				JSON.parse(visualPreferences).find((v: VisualPreferencesItem) =>
					v.hasOwnProperty('theme')
				) || {}
			changeTheme(theme)
		}
	}, [isPublicRoute])

	const changeTheme = (theme: 'light' | 'dark') => {
		switchTheme(theme)
		setActiveTheme(theme)
		updateSourceMedia(theme)

		if (visualPreferences) {
			const preferences = JSON.parse(
				visualPreferences
			) as VisualPreferencesItem[]
			const hasThemeKey = preferences.some((v: VisualPreferencesItem) =>
				v.hasOwnProperty('theme')
			)
			if (hasThemeKey) {
				const newPreferences = preferences.map((v: VisualPreferencesItem) => {
					if (v.hasOwnProperty('theme')) return { theme }
					return v
				})
				updateVisualPreferences(JSON.stringify(newPreferences))
			} else {
				const newPreferences = [...preferences, { theme }]
				updateVisualPreferences(JSON.stringify(newPreferences))
			}
		} else {
			updateVisualPreferences(JSON.stringify([{ theme }]))
		}
	}

	const [open, setOpen] = useState(false)

	return (
		<div
			className={`fixed top-0 left-0 flex-shrink-0 flex h-[72px] bg-white z-20 ${desktopSidebarCollapsed
				? 'w-[calc(100%-64px)] md:ml-16'
				: 'w-[calc(100%-200px)] ml-[200px]'
				}`}
		>
			{/* <div className="md:hidden flex justify-center items-center">
				<button
					type="button"
					className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
					onClick={() => setMobileSidebarOpen(!mobileSidebarOpen)}
				>
					<RiMenu2Line className="h-8 w-8" aria-hidden="true" />
				</button>
			</div> */}
			<div className="flex-1 flex justify-between gap-4 mx-auto items-center relative">
				<div className={'flex transition-all absolute'}>
					<CollapseSidebar />
				</div>
				{location.pathname.startsWith('/admin') && (
					<div className="flex-1 mr-4">
						<AdminSearch />
					</div>
				)}
				{!location.pathname.startsWith('/admin') && (
					<div className="flex gap-8 divide-x-2 divide-select-field">
						<div>
							{accountsWithAssets &&
								accountHasAssets &&
								account?.setup?.step !== 'subscription' && (
									<div>
										<AssetSwitcher />{' '}
									</div>
								)}
							{!account && !accountHasAssets && (
								<div className="ml-6">
									<GButton
										color="white"
										variant="colored"
										label={'Create workspace'}
										icon={RiAddLine}
										iconPlacement="left"
										className="font-bold text-lg py-2.5 px-4 w-48 h-10"
										onClick={() => navigate('/start/standard')}
									/>
								</div>
							)}
							<div className="flex justify-center items-center gap-x-4">
								{account && !accountHasAssets && !isInSetup() && (
									<AddNewSite>
										{(open) => (
											<div className="ml-6">
												<GTooltip
													content={
														demoMode && account?.id === 'workspace-demo'
															? 'This action is disabled when demo data is displayed'
															: ''
													}
												>
													<GButton
														color="white"
														variant="colored"
														disabled={
															demoMode && account?.id === 'workspace-demo'
														}
														borderColor="t-border-light"
														hoverColor="gray-50"
														label="Add website"
														icon={RiAddLine}
														iconPlacement="left"
														onClick={() => open()}
													/>
												</GTooltip>
											</div>
										)}
									</AddNewSite>
								)}
								{isInSetup() &&
									account?.setup?.step !== 'subscription' &&
									account?.setup?.step !== 'done' && (
										<div className="ml-6">
											<GButton
												color="white"
												variant="colored"
												label={'Continue Setup'}
												icon={GoChevronRight}
												iconPlacement="left"
												onClick={() => navigate(`/setup/${account?.id}`)}
											/>
										</div>
									)}
								{account?.setup?.step === 'subscription' && (
									<>
										<div className="ml-6">
											<GButton
												color="white"
												variant="colored"
												label={'Activate your workspace'}
												icon={AiOutlineCheck}
												iconPlacement="left"
												onClick={() => setOpen(true)}
											/>
										</div>
										<div>
											<ActivateAccount
												open={open}
												close={() => setOpen(false)}
											/>
										</div>
									</>
								)}
							</div>
						</div>

						<div className="pl-8 flex flex-row justify-center items-center gap-x-4">
							<div className="flex flex-row gap-x-4 text-t-dark">
								{adAccounts.length > 0 ? (
									<AdAccountsList adAccounts={castedAdAccounts} />
								) : null}
								{accounts.length > 0 && (
									<AddNewPPCAccount>
										{(open) => (
											<GTooltip
												content={
													demoMode
														? 'This action is disabled when demo data is displayed'
														: hasAccess
															? 'Analysts don’t have permission for this action'
															: undefined
												}
											>
												<GButton
													color={hasAccess || demoMode ? 'neutral' : 'white'}
													className="h-[40px]"
													variant="colored"
													disabled={hasAccess || demoMode}
													label="Add PPC Account"
													borderColor="t-border-light"
													labelClassName="font-bold text-lg"
													hoverColor="gray-50"
													icon={RiAddLine}
													iconPlacement="left"
													onClick={() => open()}
												/>
											</GTooltip>
										)}
									</AddNewPPCAccount>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
			<div className="flex flex-row gap-x-2">
				<div>
					<Impersonation />
				</div>
				<div className="flex items-center space-x-3 justify-end mr-1">
					<Notifications />

					<GTooltip content={'Open knowledge base'}>
						<div
							className="w-9 h-9 flex justify-center content-center cursor-pointer p-2 border border-t-border-lighter rounded-md"
							onClick={() => window.open(AppConfig.KNOWLEDGEBASE_URL, '_blank')}
						>
							<RiBook3Line className="h-4 w-4 text-t-default" />
						</div>
					</GTooltip>
				</div>
				<div className="flex items-center justify-end mr-6">
					<div
						className="w-9 h-9 flex justify-center content-center cursor-pointer p-2 border border-t-border-lighter border-r-0 rounded-l-md"
						onClick={() => {
							changeTheme('light')
						}}
					>
						<RiSunLine
							className={`h-4 w-4  ${activeTheme === 'light' ? 'text-primary-500' : 'text-t-default'
								}`}
						/>
					</div>
					<div
						className="w-9 h-9 flex justify-center content-center cursor-pointer p-2 border border-t-border-lighter rounded-r-md"
						onClick={() => {
							changeTheme('dark')
						}}
					>
						<RiMoonLine
							className={`h-4 w-4  ${activeTheme === 'dark' ? 'text-primary-500' : 'text-t-default'
								}`}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
