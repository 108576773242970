import {
	AccountLinkDTO,
	GoogleAdsAccountBasicInfoDTO,
	GoogleOAuthAccountLinkDTO,
	MCCAccountLinkDTO
} from 'api-models'
import { GButton, GListBox, GLoading } from 'components/basic-blocks'
import { GRadio } from 'components/basic-blocks/g-radio'
import { GWindowedSelect } from 'components/basic-blocks/g-virtualized-select'
import { useStores } from 'hooks'
import { RiArrowRightSLine } from 'react-icons/ri'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { useUiStore } from 'store'
import { PlatformSelector } from '../platform-selector'
import { AddPPCConnectionViewProps } from './types'

export const AddPPCConnectionView = (props: AddPPCConnectionViewProps) => {
	const {
		connections,
		state,
		setState,
		handleAuthorize,
		handleAddMCCAccount,
		handleMultiSelectChange,
		handleAccountNumberChange
	} = props
	const { step, selectedOption } = state
	const { accountStore } = useStores()
	const { account } = accountStore
	const isLoading = useUiStore((s) => s.isLoading)

	const clients = (link: AccountLinkDTO) => {
		if (link.type === 'mcc') {
			return (link as MCCAccountLinkDTO).clients
		}
		if (link.type === 'oauth') {
			return (link as GoogleOAuthAccountLinkDTO).clients
		}
		return []
	}

	const label = (link: AccountLinkDTO) => {
		if (link.type === 'mcc') {
			return (link as MCCAccountLinkDTO).name
		}
		if (link.type === 'oauth') {
			return (link as GoogleOAuthAccountLinkDTO).name
		}
		return link.id
	}

	return (
		<>
			<PlatformSelector
				account={account}
				platform={state.platform}
				setPlatform={(e) => {
					setState({ ...state, platform: e })
				}}
			/>
			<div className="rounded-lg px-4 py-6 space-y-8">
				<div>
					<GRadio
						name="oauth"
						checked={selectedOption === 'oauth'}
						label={
							state.platform === 'GADS'
								? 'OAuth connection'
								: state.platform === 'FADS'
								? 'Facebook connection'
								: 'Bing connection'
						}
						onChange={() => setState({ ...state, selectedOption: 'oauth' })}
						value="oauth"
					/>
					<div className="ml-7 mt-2 text-sm space-y-4">
						<span className="text-t-secondary font-normal">
							{`Authorize a new ${
								state.platform === 'GADS'
									? 'Google'
									: state.platform === 'FADS'
									? 'Facebook'
									: 'Bing'
							} account we can use to access your ${
								state.platform == 'GADS'
									? 'Google'
									: state.platform === 'FADS'
									? 'Meta'
									: 'Bing'
							} Ads account.`}
						</span>
						{selectedOption === 'oauth' && (
							<div className="flex space-x-2 items-center">
								<GButton
									label={
										step === 'waiting'
											? 'Waiting for authorization...'
											: state.platform === 'GADS'
											? 'Authorize with Google'
											: state.platform === 'FADS'
											? 'Authorize with Facebook'
											: 'Authorize with Bing'
									}
									color="primary"
									size="sm"
									className="px-8"
									icon={step === 'idle' ? RiArrowRightSLine : undefined}
									iconPlacement="right"
									onClick={() => {
										handleAuthorize()
									}}
									disabled={
										step === 'waiting' || isLoading('ACCOUNT_ADD_ACCESS')
									}
									loading={isLoading('ACCOUNT_ADD_ACCESS')}
								/>
								{step === 'waiting' && (
									<span
										className="text-xs text-red-700 cursor-pointer"
										onClick={() => setState({ ...state, step: 'idle' })}
									>
										Cancel
									</span>
								)}
							</div>
						)}
					</div>
				</div>
				{state.platform === 'GADS' && (
					<div>
						<GRadio
							name="mcc-account"
							checked={selectedOption === 'mcc-account'}
							label="MCC account"
							onChange={() =>
								setState({ ...state, selectedOption: 'mcc-account' })
							}
							value="mcc-account"
						/>
						<div className="ml-7">
							<div className="mt-2 text-sm space-y-4">
								<span className="text-t-secondary font-normal">
									Select a MCC account from an existing connection to add it to
									your account.
								</span>
							</div>
							{selectedOption === 'mcc-account' && (
								<>
									<div className="mt-2">
										{connections ? (
											<GListBox<AccountLinkDTO>
												label="Connection"
												value={state?.selectedLink}
												options={connections}
												placeholder={'Select Link'}
												onChange={(val) => handleMultiSelectChange(val)}
												renderLabel={(list) => <>{label(list)}</>}
											/>
										) : (
											<GLoading />
										)}
									</div>
									<div className="my-4">
										{state?.selectedLink && (
											<GWindowedSelect
												label="MCC account number"
												options={clients(state.selectedLink) || []}
												value={state?.accountNumber || undefined}
												onChange={(newValue) =>
													handleAccountNumberChange(
														newValue as GoogleAdsAccountBasicInfoDTO
													)
												}
												isOptionSelected={(option) =>
													(option as GoogleAdsAccountBasicInfoDTO)
														.account_number ===
													state?.accountNumber?.account_number
												}
												formatOptionLabel={(option) =>
													(option as GoogleAdsAccountBasicInfoDTO)
														.account_number
												}
												filterOption={(option, query) => {
													return query
														? (
																option as FilterOptionOption<GoogleAdsAccountBasicInfoDTO>
														  ).data.account_number
																?.toLocaleLowerCase()
																.includes(query)
														: true
												}}
											/>
										)}
									</div>
									<div>
										<GButton
											label="Add MCC account"
											color="primary"
											size="sm"
											className="px-8"
											onClick={() => {
												handleAddMCCAccount()
											}}
											disabled={isLoading('ACCOUNT_ADD_ACCESS')}
											loading={isLoading('ACCOUNT_ADD_ACCESS')}
										/>
									</div>
								</>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	)
}
