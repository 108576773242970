import { formatStatsCard } from 'utils'
import { RiCheckboxBlankFill } from 'react-icons/ri'
import { color } from 'utils/colors'

export const BarTooltip = (props: any) => {
	return (
		<>
			<div className="border rounded bg-white shadow-sm p-1 flex space-x-1 items-center text-xs">
				<RiCheckboxBlankFill
					className="w-5 h-5"
					style={{ color: props.color || color('gray', 200) }}
				/>
				<span>{props.id}:</span>
				<span className="font-bold">
					{props.dollarValue
						? `$${formatStatsCard(props.value)}`
						: formatStatsCard(props.value)}
				</span>
			</div>
		</>
	)
}
