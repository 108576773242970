import { forwardRef, useEffect, useRef, useState } from 'react'
import { useTable } from 'react-table'
import { TableVirtuoso } from 'react-virtuoso'
import { twMerge } from 'tailwind-merge'

import { RiErrorWarningLine } from 'react-icons/ri'

import { GLoading } from '../g-loading'
import { GNewPagination } from './g-pagination/g-new-pagination'
import { NewTableBody } from './table-body/new-table-body'
import { NewTableHeader } from './table-header/new-table-header'
import { GTableProps, GTableState } from './types'

export function GNewTable<T>(props: GTableProps<T>) {
	const {
		columns,
		data,
		pageSize,
		totalCount,
		pageListener,
		showHeader = true,
		showPagination = true,
		canBeFiltered = false,
		title,
		containerClassName,
		paginationClassName,
		isLoading = false,
		sortListener,
		sort,
		endReached,
		overscan,
		changePageSize,
		emptyState,
		search
	} = props

	const [page, setPage] = useState(1)
	const [rowData, setRowData] = useState<Array<any>>([])
	const [columnData, setColumnData] = useState<Array<any>>([])

	const [state, setState] = useState<GTableState>({
		page: 1,
		sortPath: null
	})

	const handlePageChange = (page: number) => {
		setPage(page)
	}

	useEffect(() => {
		setPage(1)
	}, [search])

	const getTableHeader = () => {
		return columns.map((column, index) => {
			return {
				accessor: `col${index + 1}`
			}
		})
	}
	const handleData = () => {
		const columnData = []
		let singleData = {}
		for (let i = 0; i < data.length; i++) {
			for (let j = 0; j < columns.length; j++) {
				singleData = {
					...singleData,
					[`col${j + 1}`]: data[i]
				}
			}
			columnData.push(singleData)
			singleData = {}
		}
		return columnData
	}

	useEffect(() => {
		if (data.length > 0) {
			setRowData(handleData())
			setColumnData(getTableHeader())
		} else {
			setRowData([])
		}
	}, [data, columns])

	const handleSort = (key: string) => {
		setState({ ...state, page: 1 })
		sortListener && sortListener(key)
	}

	const tableInstance = useTable({ columns: columnData, data: rowData })
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		tableInstance
	const [isScrollLeft, setIsScrollLeft] = useState(false)
	const scrollDemoRef: any = useRef(null)

	const handleScroll = () => {
		if (scrollDemoRef.current) {
			setIsScrollLeft(scrollDemoRef?.current?.scrollLeft > 0)
		}
	}

	return (
		<div className={twMerge('bg-white rounded w-full', containerClassName)}>
			{title && (
				<div className="mb-4">
					<span className="text-t-default font-bold">{title}</span>
				</div>
			)}
			<div
				className="inline-block min-w-full align-middle overflow-x-auto"
				id="scrollDemo"
				ref={scrollDemoRef}
				onScroll={handleScroll}
			>
				{((!isLoading && rows?.length > 0) || canBeFiltered) && (
					<TableVirtuoso
						useWindowScroll
						totalCount={rows.length}
						endReached={() => {
							if (endReached) endReached()
						}}
						{...(overscan && { overscan })}
						components={{
							Table: ({ style, ...props }) => (
								<table
									className="min-w-full table-auto"
									{...getTableProps()}
									{...props}
									style={{ ...style, tableLayout: 'fixed' }}
								/>
							),
							TableBody: forwardRef(({ style, ...props }, ref) => (
								<tbody
									className={twMerge(
										showHeader
											? 'divide-y divide-gray-100 border-b border-gray-100'
											: ''
									)}
									{...getTableBodyProps()}
									{...props}
									ref={ref}
								/>
							)),
							TableHead: forwardRef(({ style, ...props }, ref) => (
								<thead
									className="bg-table-header-background sticky top-0 z-5 text-t-secondary"
									{...props}
									ref={ref}
								/>
							)),
							TableRow: (props) => {
								const index = props['data-index']
								const row = rows[index]
								return (
									<tr
										{...props}
										{...row.getRowProps()}
										className="text-t-default font-bold"
									/>
								)
							}
						}}
						fixedHeaderContent={() => {
							if (showHeader) {
								return headerGroups.map((headerGroup) => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column, index) => (
											<NewTableHeader
												key={index}
												column={columns[index]}
												reactColumn={column}
												sort={sort}
												onSort={handleSort}
												isScrolling={isScrollLeft}
											/>
										))}
									</tr>
								))
							}
						}}
						itemContent={(index) => {
							const row = rows[index]
							prepareRow(row)
							return row.cells.map((cell, index) => {
								return (
									<NewTableBody
										key={index}
										cell={cell}
										column={columns[index]}
										isScrolling={isScrollLeft}
									/>
								)
							})
						}}
					/>
				)}
				{isLoading ? (
					<div className="pb-12 pt-6">
						<GLoading addMargin={false} />
					</div>
				) : data.length === 0 ? (
					emptyState ? (
						emptyState
					) : (
						<div className="flex flex-col items-center justify-center w-full pb-8 pt-8 text-center">
							<RiErrorWarningLine className="w-8 h-8 text-gray-500" />
							<h3 className="mt-2 text-md font-medium text-gray-700">
								No Results
							</h3>
						</div>
					)
				) : null}
			</div>
			{!isLoading && rows?.length > 0 && pageSize && showPagination && (
				<GNewPagination
					itemsCount={totalCount ? totalCount : data?.length}
					pageSize={pageSize}
					currentPage={page}
					onPageChange={handlePageChange}
					pageListener={pageListener}
					paginationClassName={paginationClassName}
					changePageSize={changePageSize}
				/>
			)}
		</div>
	)
}
