import { AssetDTO, ProtectionInfoDetectionType } from 'api-models'
import { GSection } from 'components/basic-blocks'
import { InfoItem, InfoItemGroup } from 'components/basic-blocks/g-info-table'
import { CountriesObject } from 'config/countries'
import { RiCheckLine, RiCloseLine } from 'react-icons/ri'
import { useQuery } from 'react-query'
import { AdminRuleTags } from 'services/admin/rule-tags'

export const ProtectionSection = (props: { asset: AssetDTO }) => {
	const { asset } = props

	const protection = asset.protection

	const { data: ruleTags, isLoading } = useQuery(
		['admin-rule-tags', asset.id, asset.account_id],
		() => AdminRuleTags.findAssetTags(asset.id, asset.account_id)
	)

	return (
		<GSection loading={isLoading}>
			<InfoItemGroup
				title="Protection settings"
				className="-mt-5 border-t-0 rounded-t-lg"
			>
				<InfoItem label="Advertising region">
					<div className="flex gap-4">
						<div className="w-full">
							<div className="flex flex-wrap gap-1">
								{protection.region?.map((region) => {
									return (
										<div key={region} className="text-t-secondary">
											{CountriesObject[region]}
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</InfoItem>

				<InfoItem label="Detection type">
					<div className="flex text-t-secondary ">
						{protection.detection_type === ProtectionInfoDetectionType.AI
							? 'AI'
							: 'Custom tuning'}
					</div>
				</InfoItem>

				{protection.detection_type ===
					ProtectionInfoDetectionType.CUSTOM_TUNING && (
					<>
						<InfoItem label="Detection mode" className="overflow-clip">
							<div>
								<span
									className={
										protection.mode === 1
											? 'text-primary-500 font-semibold'
											: 'text-t-secondary'
									}
								>
									Quiet{' '}
								</span>
								-
								<span
									className={
										protection.mode === 2
											? 'text-primary-500 font-semibold'
											: 'text-t-secondary'
									}
								>
									{' '}
									Soft{' '}
								</span>
								-
								<span
									className={
										protection.mode === 3
											? 'text-primary-500 font-semibold'
											: 'text-t-secondary'
									}
								>
									{' '}
									Normal{' '}
								</span>
								-
								<span
									className={
										protection.mode === 4
											? 'text-primary-500 font-semibold'
											: 'text-t-secondary'
									}
								>
									{' '}
									Strict{' '}
								</span>
								-
								<span
									className={
										protection.mode === 5
											? 'text-primary-500 font-semibold'
											: 'text-t-secondary'
									}
								>
									{' '}
									Aggressive
								</span>
							</div>
						</InfoItem>

						<InfoItem label="Blocking mode">
							<div>
								<span
									className={
										protection.blocking_mode === 'monitoring'
											? 'text-primary-500 font-semibold'
											: 'text-t-secondary'
									}
								>
									Monitoring only{' '}
								</span>
								-
								<span
									className={
										protection.blocking_mode === 'disrupt'
											? 'text-primary-500 font-semibold'
											: 'text-t-secondary'
									}
								>
									{' '}
									Disrupt{' '}
								</span>
								-
								<span
									className={
										protection.blocking_mode === 'block'
											? 'text-primary-500 font-semibold'
											: 'text-t-secondary'
									}
								>
									{' '}
									Block
								</span>
							</div>
						</InfoItem>
					</>
				)}

				<InfoItem label="Additional options">
					<div className="space-y-2">
						<div className="flex gap-1 text-t-secondary">
							{protection.bounced ? (
								<RiCheckLine className="w-4 h-4 text-primary-500" />
							) : (
								<RiCloseLine className="w-4 h-4 " />
							)}
							<p>Bounced clicks</p>
						</div>
						<div className="flex gap-1 text-t-secondary">
							{protection.vpn ? (
								<RiCheckLine className="w-4 h-4 text-primary-500" />
							) : (
								<RiCloseLine className="w-4 h-4 " />
							)}
							<p>Traffic from proxies and VPNs</p>
						</div>
						<div className="flex gap-1 text-t-secondary">
							{protection.bots ? (
								<RiCheckLine className="w-4 h-4 text-primary-500" />
							) : (
								<RiCloseLine className="w-4 h-4 " />
							)}
							<p>Bots and other non-human traffic</p>
						</div>
						<div className="flex gap-1 text-t-secondary">
							{protection.ip_range ? (
								<RiCheckLine className="w-4 h-4 text-primary-500" />
							) : (
								<RiCloseLine className="w-4 h-4 " />
							)}
							<p>Traffic from the sam IP range</p>
						</div>
						<div className="flex gap-1 text-t-secondary">
							{protection.multi_target ? (
								<RiCheckLine className="w-4 h-4 text-primary-500" />
							) : (
								<RiCloseLine className="w-4 h-4 " />
							)}
							<p>Visitors targeting multiple campaigns</p>
						</div>
						<div className="flex gap-1 text-t-secondary">
							{protection.interaction ? (
								<RiCheckLine className="w-4 h-4 text-primary-500" />
							) : (
								<RiCloseLine className="w-4 h-4 " />
							)}
							<p>Bad website interactions</p>
						</div>
						<div className="flex gap-1 text-t-secondary">
							{protection.non_converting ? (
								<RiCheckLine className="w-4 h-4 text-primary-500" />
							) : (
								<RiCloseLine className="w-4 h-4 " />
							)}
							<p>Non-converting traffic</p>
						</div>
						<div className="flex gap-1 text-t-secondary">
							{protection.converted ? (
								<RiCheckLine className="w-4 h-4 text-primary-500" />
							) : (
								<RiCloseLine className="w-4 h-4 " />
							)}
							<p>Converted visitor</p>
						</div>
					</div>
				</InfoItem>

				{ruleTags && ruleTags.length > 0 && (
					<InfoItem label="Rule Tags">
						{ruleTags.map((tag) => (
							<div className="flex w-full text-t-secondary items-center">
								<span className="w-[100px]">{tag.tag}</span>
								<span>Current level: {tag.current_level}</span>
							</div>
						))}
					</InfoItem>
				)}
			</InfoItemGroup>
		</GSection>
	)
}
