import { AppConfig } from 'config'
import { Users } from './user'

class Service {
	init() {
		if (!zE) return
		if (!['stage', 'production'].includes(AppConfig.ENV)) {
			return
		}

		zE('messenger', 'loginUser', async (callback: (a: string) => void) => {
			const token = await Users.getSupportToken()

			await callback(token)
		})
	}

	open() {
		zE('messenger', 'open')
	}

	close() {
		zE('messenger', 'close')
	}

	hide() {
		zE('messenger', 'hide')
	}

	show() {
		zE('messenger', 'show')
	}
}

export const ZendeskService = new Service()
