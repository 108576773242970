import {
	GButton,
	GInput,
	GListBox,
	GMultiSelect,
	GTransition
} from 'components/basic-blocks'
import { OnBoarding } from 'components/onboarding/onboarding'
import { AdvertisingPlatforms, ConfigValueItem, ManagedBrands } from 'config'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StartAgencyViewProps } from './types'

export const StartAgencyView = ({
	state,
	setBrands,
	setPlatforms,
	setSpend,
	onNext
}: StartAgencyViewProps) => {
	const navigate = useNavigate()
	const [errors, setErrors] = useState<string[]>([])

	const submit = () => {
		const err = []
		if (!state.brands) err.push('brands')
		if (!state.platforms) err.push('platform')
		if (!state.spend) err.push('spend')
		setErrors(err)
		if (err.length === 0) {
			onNext()
		}
	}

	return (
		<OnBoarding
			handleBack={() => navigate('/workspaces')}
			handleBackText="Back to workspaces"
			title={
				<div className="text-3xl text-white font-[800]">
					Tell us about your <span className="text-[#71DA97]">operation</span>{' '}
				</div>
			}
			description="We’ll use this information to create your workspace and provide
								you with smooth integration."
		>
			<GListBox<ConfigValueItem>
				className="mb-4"
				label="How many brands do you manage?"
				renderLabel={(s) => s.label}
				value={state.brands}
				disabled={state.loading}
				placeholder="Select one"
				error={
					errors.includes('brands')
						? 'You need to select a number of managed brands'
						: undefined
				}
				options={ManagedBrands}
				onChange={(e) => setBrands(e)}
			/>
			<GMultiSelect<ConfigValueItem>
				className="mb-4"
				loopKey="value"
				label="What platforms do you advertise on?"
				renderLabel={(s) => s.label}
				placeholder="Select all that apply"
				disabled={state.loading}
				renderSelected={(s) =>
					s.map((i: ConfigValueItem) => i.label).join(', ')
				}
				error={
					errors.includes('platform')
						? 'You need to select at least one advertising platform'
						: undefined
				}
				value={state.platforms || []}
				options={AdvertisingPlatforms}
				onChange={(e) => setPlatforms(e)}
			/>
			<GInput
				label="What much ad spend do you manage monthly? (USD)"
				placeholder="$10,000"
				type="number"
				value={state.spend}
				disabled={state.loading}
				error={
					errors.includes('spend')
						? 'You need to set your managed spend'
						: undefined
				}
				step={1000}
				onChange={(e) => setSpend(Number(e.target.value))}
			/>
			<div className="flex justify-center">
				<GButton
					color="primary"
					iconPlacement="right"
					className="w-full my-4"
					onClick={submit}
					disabled={state.loading}
					loading={state.loading}
				>
					<span className="px-4">Continue</span>
				</GButton>
			</div>
			<GTransition show={state.loading}>
				<div className="flex justify-center text-sm">
					Preparing your workspace...
				</div>
			</GTransition>
		</OnBoarding>
	)
}
