import { AccessLogDTO } from 'api-models'
import { GDateTime } from 'components/basic-blocks/g-datetime'
import { GLoading } from 'components/basic-blocks/g-loading'
import { useStores } from 'hooks'
import { useEffect } from 'react'
import { RiLockUnlockLine } from 'react-icons/ri'
import { useUiStore } from 'store'
import { classNames } from 'utils'

export const AccessLogs = () => {
	const { userStore } = useStores()
	const { getAccessLogs, accessLogs } = userStore
	const isLoading = useUiStore((s) => s.isLoading)

	useEffect(() => {
		const getData = async () => {
			await getAccessLogs()
		}
		getData()
	}, [])

	if (isLoading('USER_GET_LOGIN_METHODS')) {
		return <GLoading />
	}
	const rows = accessLogs

	if (!accessLogs || rows.length === 0) {
		return (
			<div className="text-base mt-6 text-gray-500">
				There are no recent logins
			</div>
		)
	}

	return (
		<div className="flow-root">
			<ul role="list" className="-mb-4">
				{accessLogs.map((row, i) => renderItem(row, i, accessLogs.length))}
			</ul>
		</div>
	)
}

function renderItem(item: AccessLogDTO, i: number, l: number) {
	return (
		<li key={'' + i + item.timestamp}>
			<div className={`relative ${i !== l - 1 ? 'mt-6' : 'my-6'}`}>
				{i !== l - 1 ? (
					<span
						className="absolute top-3 left-3 -ml-px h-full w-0.5 bg-gray-200"
						aria-hidden="true"
					/>
				) : null}
				<div className="relative flex space-x-4">
					<div>
						<span
							className={classNames(
								'bg-gray-400 h-7 w-7 rounded-full flex items-center justify-center ring-8 ring-white'
							)}
						>
							<RiLockUnlockLine
								className="h-3 w-3 text-white"
								aria-hidden="true"
							/>
						</span>
					</div>

					<div className="flex space-x-4 w-full">
						<div className="flex flex-col items-start justify-between space-y-2 flex-grow">
							<div className="text-t-default text-base">{item.user_agent}</div>
							<div className="text-sm text-gray-500">
								IP:{' '}
								<code className="text-t-secondary text-sm font-normal">
									{item.ip}
								</code>
							</div>
						</div>
						<div className="text-md text-gray-500">
							<GDateTime date={item.timestamp} />
						</div>
					</div>
				</div>
			</div>
		</li>
	)
}
