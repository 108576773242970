import {
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuSubContent
} from '@radix-ui/react-dropdown-menu'

import { GCheckbox } from 'components/basic-blocks/g-checkbox'
import { OptionSubContentProps } from '../../../context'
import { getValue } from '../../utils'

const statusList = [
	{
		label: 'Active',
		value: 'ACTIVE'
	},
	{
		label: 'Setup',
		value: 'SETUP'
	},
	{
		label: 'Inactive',
		value: 'INACTIVE'
	}
]

const key = 'status'

export const WorkspaceStatusOption = {
	key,
	label: 'Status',
	subContent: ({ handleSubItemChange, mapState }: OptionSubContentProps) => (
		<DropdownMenuPortal>
			<DropdownMenuSubContent
				className="bg-white rounded border border-gray-200 p-4 max-h-[300px] overflow-y-scroll"
				sideOffset={20}
			>
				{statusList.map((o) => (
					<DropdownMenuItem key={o.value} asChild={true}>
						<GCheckbox
							name={o.label}
							label={o.label}
							checked={mapState.get(key)?.some((s: string) => s === o.value)}
							onChange={(e) => {
								handleSubItemChange({
									event: e,
									key,
									value: getValue(mapState, key, o.value)
								})
							}}
							className="p-1"
						/>
					</DropdownMenuItem>
				))}
			</DropdownMenuSubContent>
		</DropdownMenuPortal>
	)
}
