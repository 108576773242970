import { GButton, GInput } from 'components/basic-blocks'
import { GRadio } from 'components/basic-blocks/g-radio'
import { RiShieldStarLine } from 'react-icons/ri'
import { classNames, discountText } from 'utils'
import { ProtectionPlanViewProps } from '../types'
import { ProtectionPlanTier } from './tier'
import { useUiStore } from 'store'
import { EXTERNAL_APP_BASE_URL } from '../../../utils/app-links'
import { usePostHog } from 'posthog-js/react'

export const ProtectionPlanView = ({
	state,
	plans,
	buttonLabel,
	claimCoupon,
	setState,
	onConfirm
}: ProtectionPlanViewProps) => {
	const isLoading = useUiStore((s) => s.isLoading)
	const posthog = usePostHog()

	return (
		<div className="w-full flex flex-col items-center">
			<span className="text-t-title text-xl font-semibold mb-4">
				Protection plan
			</span>

			<div className="w-full px-8 rounded-lg flex flex-col space-y-6 mb-4 text-sm">
				<div className="flex flex-col items-start text-left space-y-4 text-t-dark">
					<span className="text-t-dark">
						Select a protection plan that best suits your needs.{' '}
						<a
							className="text-primary-500"
							href={`${EXTERNAL_APP_BASE_URL}/pricing`}
							target="_blank"
						>
							Compare plans
						</a>
						.
					</span>

					{plans.map((plan) => (
						<div key={plan.tier} className="w-full flex">
							<ProtectionPlanTier
								plan={plan}
								state={state}
								setState={setState}
							/>
						</div>
					))}
					<div
						onClick={() =>
							setState({ ...state, custom_plan: true, selected_tier: null })
						}
						className={classNames(
							'w-full flex items-start bg-gray-50 rounded-lg cursor-pointer border-2 p-4 transition-all',
							state.custom_plan ? 'border-purple-500' : 'border-transparent'
						)}
					>
						<div className="px-2">
							<RiShieldStarLine className="text-purple-500 w-7 h-7" />
						</div>
						<div className="flex-1 flex flex-col space-y-2 px-2">
							<span className="font-semibold text-md">Custom plan</span>

							{!state.custom_plan && (
								<span className="text-t-secondary">
									Ad spend not covered by our pricing? Request a quote here.
								</span>
							)}
							{state.custom_plan && (
								<div className="-ml-12 pt-4">
									Request a custom quote and start your trial. Our staff will
									prepare an offer for you soon.
								</div>
							)}
						</div>
						{state.custom_plan && (
							<div className="flex flex-col items-end px-4 space-y-1 h-full">
								<div className="flex flex-col h-full justify-end items-end">
									<div>
										<span className="text-t-secondary flex flex-col items-end">
											Monthly ad spend
										</span>

										<GInput
											value={state.spend}
											type="number"
											min={1000}
											step={1000}
											onChange={(e) =>
												setState({ ...state, spend: Number(e.target.value) })
											}
										/>
									</div>
								</div>
							</div>
						)}
					</div>

					<span className="">
						Your paid subscription starts after your trial period ends.
					</span>
				</div>

				<div className="w-full grid grid-cols-2">
					<div className="flex flex-col items-start text-left space-y-2 text-t-dark">
						<span className="font-medium">Preferred billing period</span>
						<div className="flex flex-col space-y-2">
							<GRadio
								name="billing-yearly"
								checked={state.billing_period === 'yearly'}
								label="Yearly (20% off)"
								onChange={(e) => {
									posthog?.capture('click-on-yearly-plan')
									setState({ ...state, billing_period: e.target.value })
								}

								}
								value="yearly"
							/>
							<GRadio
								name="billing-quarterly"
								checked={state.billing_period === 'quarterly'}
								label="Quarterly (10% off)"
								onChange={(e) => {
									posthog?.capture('click-on-quarterly-plan')
									setState({ ...state, billing_period: e.target.value })
								}
								}
								value="quarterly"
							/>
							<GRadio
								name="billing-monthly"
								checked={state.billing_period === 'monthly'}
								label="Monthly"
								onChange={(e) => {
									posthog?.capture('click-on-monthly-plan')
									setState({ ...state, billing_period: e.target.value })
								}
								}
								value="monthly"
							/>
						</div>
					</div>

					<div className="flex flex-col items-start text-left space-y-2 text-t-dark">
						<span className="font-medium">Have a coupon code?</span>
						<div className="w-full flex flex-col space-y-2">
							<div className="w-full flex items-center space-x-2">
								<GInput
									className="flex-1 text-t-default"
									onChange={(e) =>
										setState({ ...state, coupon_code: e.target.value })
									}
									placeholder="CODE"
								/>
								<GButton
									size="sm"
									onClick={() => claimCoupon(state.coupon_code)}
									loading={isLoading('ACCOUNT_GET_COUPON')}
									label="Add"
								/>
							</div>
							{state.coupon_error && (
								<div className="text-sm text-red-700">{state.coupon_error}</div>
							)}
							{state.coupon && (
								<div className="text-sm text-green-700">
									{discountText(state.coupon)}
								</div>
							)}
						</div>
					</div>
				</div>

				<div className="flex flex-col items-center text-left space-y-2 text-t-dark">
					{state.custom_plan && (
						<div className="text-center p-2">
							A custom quote will be created for you during your trial period.
							You can change your chosen protection plan at any time.
						</div>
					)}
					<GButton
						color="primary"
						size="sm"
						disabled={
							!state.custom_plan &&
							!(state.selected_tier && state.selected_price)
						}
						onClick={onConfirm}
					>
						{buttonLabel || 'Continue'}
					</GButton>
				</div>
			</div>
		</div>
	)
}
