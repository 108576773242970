import { Popover } from '@headlessui/react'
import { ReactNode, useState } from 'react'
import { Calendar, CalendarProps } from 'react-date-range'
import { RiCodeLine } from 'react-icons/ri'
import { GButton } from '../g-button'
import { GbuttonProps } from '../g-button/types'
import { GTransition } from '../g-transition'

interface GDateSelectorProps extends CalendarProps {
	onChange: (e: Date) => void
	align?: 'left' | 'right'
	label?: string | ReactNode
	variant?: GbuttonProps['variant']
	color?: GbuttonProps['color']
	className?: string
	buttonLabel?: string
	value?: Date
}

export const DateSelector = (props: GDateSelectorProps) => {
	const {
		onChange,
		label,
		buttonLabel,
		align = 'left',
		variant,
		color,
		className,
		value,
		...rest
	} = props
	const [date, setDate] = useState(value ? new Date(value) : new Date())
	const buttonTitle = date
		? date.toLocaleDateString()
		: buttonLabel || 'Select Date'

	return (
		<>
			{label && (
				<div className="block text-sm font-medium text-gray-700 mb-1">
					{label}
				</div>
			)}
			<Popover className="flex relative w-full flex-col z-[1]">
				{({ open }) => (
					<>
						<Popover.Button as="div">
							<GButton
								variant={variant || 'contained'}
								color={color || 'neutral'}
								label={buttonTitle}
								iconPlacement="right"
								icon={RiCodeLine}
								size="xxxs"
								iconClassName={
									'self-center text-t-default rotate-90 cursor-pointer'
								}
								className={'text-t-default ' + className}
							/>
						</Popover.Button>
						<GTransition show={open}>
							<Popover.Panel
								className={`absolute shadow-lg ${
									align === 'left' ? 'left-0' : 'right-0'
								}`}
							>
								<Calendar
									className="!bg-white"
									date={date}
									showMonthAndYearPickers={false}
									onChange={(item) => handleDateChange(item)}
									{...rest}
								/>
							</Popover.Panel>
						</GTransition>
					</>
				)}
			</Popover>
		</>
	)

	function handleDateChange(item: any) {
		if (onChange) {
			onChange(item)
		}
		setDate(item)
	}
}
