import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useContext } from 'react'
import { RiArrowRightSLine, RiFilter3Line } from 'react-icons/ri'

import { AccountPageFiltersContext } from '../context'
import { options } from './options'
import { SelectedFilters } from './selected-filters'

export const Filters = () => {
	const {
		handleOpenAdminUSerDetailsChange,
		handleRemoveFilter,
		handleSubItemChange,
		mapState
	} = useContext(AccountPageFiltersContext)

	return (
		<div className="flex gap-2">
			<DropdownMenu.Root
				onOpenChange={handleOpenAdminUSerDetailsChange}
				modal={false}
			>
				<DropdownMenu.Trigger className="min-w-[104px] min-h-[34px] max-h-[34px] font-bold text-t-default bg-white py-1.5 px-3 flex items-center rounded border border-gray-200 hover:bg-gray-100 focus:outline-none">
					<RiFilter3Line className="h-4 w-4 text-t-default mr-1" />
					Add filter
				</DropdownMenu.Trigger>

				<DropdownMenu.Portal>
					<DropdownMenu.Content
						sideOffset={5}
						align="start"
						className="flex flex-col bg-white rounded border border-gray-200 p-2 w-[170px] max-h-[450px] overflow-y-auto focus:outline-none"
						sticky="always"
					>
						{options.map((option) => (
							<DropdownMenu.Sub key={option.key}>
								<DropdownMenu.SubTrigger className="flex items-center hover:bg-gray-50 p-2 focus:border-none focus:outline-none">
									{option.label}
									<RiArrowRightSLine className="h-4 w-4 ml-auto" />
								</DropdownMenu.SubTrigger>
								{option.subContent({ handleSubItemChange, mapState })}
							</DropdownMenu.Sub>
						))}
					</DropdownMenu.Content>
				</DropdownMenu.Portal>
			</DropdownMenu.Root>
			<SelectedFilters mapState={mapState} removeFilter={handleRemoveFilter} />
		</div>
	)
}
