import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useContext } from 'react'
import { RiFilter3Line, RiCloseLine } from 'react-icons/ri'

import { AccountPageFiltersContext } from '../context'

const options = [
	{
		label: 'All',
		key: 'all'
	},
	{
		label: 'Active subscriptions',
		key: 'active'
	},
	{
		label: 'Active trials',
		key: 'trial'
	},
	{
		label: 'In setup',
		key: 'setup'
	},
	{
		label: 'Cancelled trials',
		key: 'cancelled trials'
	},
	{
		label: 'Cancelled subscriptions',
		key: 'cancelled'
	},
	{
		label: 'Offer requests',
		key: 'offer requests'
	},
	{
		label: 'Cancelling',
		key: 'cancelling'
	},
	{
		label: 'Breached limits',
		key: 'limit breached'
	},
	{
		label: 'Breach limits soon',
		key: 'limit breach soon'
	}
]

export const QuickViews = () => {
	const { handleApplyQuickView, handleRemoveQuickView, quickView } = useContext(
		AccountPageFiltersContext
	)

	return (
		<div className="flex gap-2 pr-2">
			<DropdownMenu.Root modal={false}>
				<DropdownMenu.Trigger className="min-w-[104px] min-h-[34px] max-h-[34px] font-bold text-t-default bg-white py-1.5 px-3 flex items-center rounded border border-gray-200 hover:bg-gray-100 focus:outline-none">
					<RiFilter3Line className="h-4 w-4 text-t-default mr-1" />
					Quick views
				</DropdownMenu.Trigger>

				<DropdownMenu.Portal>
					<DropdownMenu.Content
						sideOffset={5}
						align="start"
						className="flex flex-col bg-white rounded border border-gray-200 p-2 w-[170px] max-h-[450px] overflow-y-auto focus:outline-none"
						sticky="always"
					>
						{options.map((option) => (
							<DropdownMenu.Sub key={option.key}>
								<DropdownMenu.SubTrigger
									onClick={() => {
										handleApplyQuickView(option)
									}}
									className="flex cursor-pointer items-center hover:bg-gray-50 p-2 focus:border-none focus:outline-none"
								>
									{option.label}
								</DropdownMenu.SubTrigger>
							</DropdownMenu.Sub>
						))}
					</DropdownMenu.Content>
				</DropdownMenu.Portal>
			</DropdownMenu.Root>
			<div>
				{quickView && Object.keys(quickView).length > 0 && (
					<div className="flex items-center gap-1.5 bg-gray-50 py-1.5 px-3 text-t-default font-normal rounded-lg">
						{quickView.label}
						<RiCloseLine
							className="w-4 h-4 text-t-default cursor-pointer"
							onClick={() => handleRemoveQuickView()}
						/>
					</div>
				)}
			</div>
		</div>
	)
}
