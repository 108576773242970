import axios from 'axios'
import { ApiConfig } from 'config'
import { lazy, Suspense, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ZendeskService } from 'services/zendesk'
import { CFCState } from '../types'
import { GLazyLoading } from '../../../components/basic-blocks'

const CFCStartTrialView = lazy(() =>
	import('./view')
		.then((module) => {
			return { default: module.CFCStartTrialView }
		})
		.catch((error) => {
			console.error('Failed to load ClicksPageView:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)

const client = axios.create({
	baseURL: ApiConfig.API_CFC_URL
})

export const ClickFraudStartTrial = () => {
	const { id } = useParams()
	const navigate = useNavigate()

	const [timer, setTimer] = useState<number>(5)
	const [redirect, setRedirect] = useState(false)

	useEffect(() => {
		ZendeskService.hide()
	}, [])

	useEffect(() => {
		if (id) load(id)
	}, [id])

	useEffect(() => {
		if (!redirect) return

		const interval = setInterval(() => {
			setTimer((timer) => timer - 1)
		}, 1000)

		if (timer <= 0) {
			clearInterval(interval)
			navigate('/')
		}

		return () => clearInterval(interval)
	}, [timer, redirect])

	const [state, setState] = useState<CFCState>({
		loading: false,
		step: 1,
		lead: null,
		errors: []
	})

	const load = async (id: string) => {
		try {
			const response = await client.get(`/cfc/${id}`)
			setState({ ...state, lead: response.data.data })
		} catch (err: any) {
			console.trace(err)
			toast.error(
				'Error loading the report. Make sure you have the correct link.'
			)
		}
	}

	const startTrial = async (payload: {
		name: string
		email: string
		password: string
		timezone: string
		phone: string
	}) => {
		try {
			setState({ ...state, loading: true })

			await client.post(`/cfc/${id}/signup`, payload)

			setState({ ...state, loading: false, step: 0 })
			setRedirect(true)
		} catch (err: any) {
			if (err.response.data.message) {
				toast.error(err.response.data.message)
			} else {
				toast.error(err.message)
			}
			setState({ ...state, loading: false })
		}
	}

	return (
		<Suspense fallback={<GLazyLoading />}>
			<CFCStartTrialView
				state={state}
				setState={setState}
				startTrial={startTrial}
				timer={timer}
			/>
		</Suspense>
	)
}
