import { Switch } from '@headlessui/react'
import { useThemeStore } from 'layout/navbar/store'
import { ReactElement } from 'react'
import { classNames } from 'utils'

interface GTogglePropsType {
	label?: string
	activeColor?: string | 'success' | 'warning' | 'danger'
	description?: string | ReactElement
	checked: boolean
	disabled?: boolean
	onChange: (checked: boolean) => void
	labelClassName?: string
}

export const GToggle = (props: GTogglePropsType) => {
	const { label, description, disabled, checked, onChange, labelClassName } =
		props

	const { theme } = useThemeStore()

	const switchClassName =
		theme === 'dark'
			? `border-${checked ? 'primary-500' : 'gray-300'} bg-${
					checked ? 'primary-500' : 'gray-300'
			  } rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none items-center`
			: `border-${checked ? 'primary-500' : 't-tertiary'} bg-${
					checked ? 'primary-500' : 'gray-300'
			  } rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none items-center`

	const ringClassName =
		theme === 'dark'
			? ` bg-${
					checked ? 't-button text-primary-500' : 't-button text-t-tertiary'
			  } shadow transform ring-0 transition ease-in-out duration-200`
			: ` bg-${
					checked
						? 'card-background text-primary-500'
						: 'card-background text-t-tertiary'
			  } shadow transform ring-0 transition ease-in-out duration-200`

	return (
		<Switch.Group
			as="div"
			className="flex items-center justify-start space-x-4"
		>
			<Switch
				disabled={disabled ? disabled : false}
				checked={checked}
				onChange={onChange}
				className={`flex flex-shrink-0 h-6 w-10  ${switchClassName}`}
			>
				<span
					aria-hidden="true"
					className={classNames(
						checked ? 'translate-x-[1.2rem]' : 'translate-x-1',
						`pointer-events-none inline-block h-4 w-4 rounded-full ${ringClassName} `
					)}
				/>
			</Switch>
			{label || description ? (
				<span className="flex flex-col">
					{label && (
						<Switch.Label
							as="span"
							className={`${
								labelClassName || 'text-md font-medium text-t-dark'
							}`}
							passive
						>
							{label}
						</Switch.Label>
					)}
					{description && (
						<Switch.Description as="span" className="text-sm text-t-secondary">
							{description}
						</Switch.Description>
					)}
				</span>
			) : null}
		</Switch.Group>
	)
}
