import { PageTitle } from '../../../utils/page-title'
import {
	GButton,
	GNewTable,
	GTooltip,
	GInput
} from '../../../components/basic-blocks'
import { RiSearch2Line, RiErrorWarningLine } from 'react-icons/ri'
import { columns } from './columns'
import { Dispatch, useEffect, useMemo, useState } from 'react'
import { useAdminStore, useUiStore, useUserStore } from '../../../store'
import { IoBriefcaseOutline } from 'react-icons/io5'
import { useShallow } from 'zustand/react/shallow'
import { AdminAccessTypes, hasAdminAccess } from '../../../utils/admin'
import { AdminTeam } from '../../../services/admin/team'

export const AdminTeamList = ({
	setPage
}: {
	setPage: Dispatch<'list' | 'invite'>
}) => {
	const [invites, setInvites] = useState<{ total: number; data: any[] }>({
		total: 0,
		data: []
	})
	const isLoading = useUiStore((s) => s.isLoading)
	const { loadTeam, adminTeam } = useAdminStore(
		useShallow((s) => ({
			loadTeam: s.loadTeam,
			adminTeam: s.adminTeam
		}))
	)

	const [search, setSearch] = useState('')

	const adminTeamData = useMemo(() => {
		if (adminTeam.data.length < 10 && !search) {
			return [...adminTeam.data, ...invites.data]
		} else if (search) {
			const filterInvites = invites.data.filter((i: { user_email: string }) =>
				i?.user_email.includes(search)
			)
			return [...adminTeam.data, ...filterInvites]
		}
		return adminTeam.data
	}, [adminTeam, invites])
	const [skip, setSkip] = useState(0)
	const getInvites = async () => {
		try {
			if (skip > 0) return
			const invites = await AdminTeam.getInvites()
			setInvites(invites)
		} catch (e) {
			console.error(e)
		}
	}

	useEffect(() => {
		;(async () => {
			await loadTeam({ skip, search })
		})()
	}, [skip, search])

	useEffect(() => {
		;(async () => {
			await getInvites()
		})()
	}, [])

	const pageListener = (page: number) => {
		setSkip(page)
	}


	const emptyState = (
		<div className="flex flex-col items-center justify-center w-full pb-8 pt-8 text-center">
			<RiErrorWarningLine className="w-8 h-8 text-gray-500" />
			<h3 className="mt-2 text-md font-medium text-gray-700">No Results</h3>
		</div>
	)

	useEffect(() => {
		setSkip(0)
		;(async () => {
			await loadTeam({ skip: 0, search })
			await getInvites()
		})()
		pageListener(0)
	}, [search])


	const totalCount = useMemo(() => {
		return Number(adminTeam.total || 0) + Number(invites?.total || 0)
	}, [adminTeam, invites])


	return (
		<>
			<PageTitle pageName="Team" />
			<div className="p-8">
				<h2 className="text-t-heading text-3xl font-bold">Team</h2>
				<div className="flex items-end justify-between">
					<span className="text-lg font-medium text-t-default">
						All members have access to the ClickGUARD admin panel
					</span>
					<GTooltip
						content={
							!hasAdminAccess(
								useUserStore.getState().user,
								AdminAccessTypes.invite_cg_staff
							)
								? 'You do not have permission to invite team member'
								: ''
						}
					>
						<GButton
							label="Invite Team Member"
							icon={IoBriefcaseOutline}
							color="primary"
							disabled={
								!hasAdminAccess(
									useUserStore.getState().user,
									AdminAccessTypes.invite_cg_staff
								)
							}
							onClick={() => setPage('invite')}
						/>
					</GTooltip>
				</div>
				<GInput
					className="my-4 bg-white"
					onChange={(e) => {
						setSkip(0)
						setSearch(e.target.value)
					}}
					placeholder="Find..."
					icon={RiSearch2Line}
				/>
			</div>

			<main className="mx-6 border border-gray-100 rounded overflow-clip px-">
				<GNewTable
					isLoading={isLoading('ADMIN_GET_TEAM')}
					data={adminTeamData}
					columns={columns}
					pageSize={10}
					pageListener={pageListener}

					search={search}

					totalCount={totalCount}
					paginationClassName="mt-0 py-4 px-6"
					emptyState={emptyState}
				/>
			</main>
		</>
	)
}
