import { ProductAccountDTO } from 'api-models'
import { GButton, GTooltip, GTransition } from 'components/basic-blocks'
import { GInfo } from 'components/basic-blocks/g-info'
import { EventsList } from 'components/events-list'
import moment from 'moment'
import { AdminCreateOffer } from '../../../../../components/create-offer'
import { CancelSubscription } from './admin-subscription-actions'
import { Info } from './info'
import { OffersSection } from './offers-section'
import { WebsitesSection } from './websites-section'
import { formatSpend } from '../../../../../utils'
import { AdminAccessTypes, hasAdminAccess } from '../../../../../utils/admin'
import { useUserStore } from '../../../../../store'

export const InfoTab = (props: { account: ProductAccountDTO }) => {
	const { account } = props

	return (
		<div className="pt-2">
			<div className="flex flex-wrap space-x-6">
				<div className="flex-1">
					<Info account={account} />
				</div>
				<div className="flex-1 flex flex-col space-y-4">
					<GTransition show={!!account.offer_request}>
						<GInfo
							title="Offer request"
							content={
								<div className="flex flex-col space-y-1">
									<span>User requested an offer for this subscription:</span>
									<ul className="list-disc ml-4">
										<li>
											{account.offer_request &&
												formatSpend(account.offer_request.spend)}{' '}
											ad spend &{' '}
											{account.offer_request && account.offer_request.interval}{' '}
											billing interval
										</li>
									</ul>
								</div>
							}
							color="purple"
							actions={[
								<AdminCreateOffer
									user={{
										id: account.user_info?.user_id ?? '',
										name: account.user_info?.name || ''
									}}
									account={{
										id: account.id,
										name: account.name || '',
										spend: account?.billing?.spend || null,
										interval:
											account?.billing?.subscription?.plan?.interval || null,
										price: account?.billing?.subscription?.plan?.price || null,
										planName:
											account?.billing?.subscription?.plan?.name || null,
										interval_count:
											account?.billing?.subscription?.plan?.interval_count ||
											null
									}}
									offerRequest={account?.offer_request || null}
								>
									{(open) => (
										<GTooltip
											content={
												!hasAdminAccess(
													useUserStore.getState().user,
													AdminAccessTypes.make_offer
												)
													? 'You do not have permission to create offers'
													: ''
											}
										>
											<GButton
												disabled={
													!hasAdminAccess(
														useUserStore.getState().user,
														AdminAccessTypes.make_offer
													)
												}
												size="xs"
												color="purple"
												onClick={() => open()}
											>
												Create offer
											</GButton>
										</GTooltip>
									)}
								</AdminCreateOffer>
							]}
						/>
					</GTransition>
					<GTransition
						show={
							!!account.cancel_request &&
							account.billing?.subscription?.status !== 'CANCELLED'
						}
					>
						<GInfo
							title="Cancellation request"
							content={
								<div className="flex flex-col space-y-1">
									<span>
										User requested{' '}
										{account.billing?.subscription?.type === 'TRIAL'
											? 'trial'
											: 'subscription'}{' '}
										cancellation:
									</span>
									<ul className="list-disc ml-4">
										<li>
											{moment(account.cancel_request?.created_at).format('LL')}:{' '}
											{account.cancel_request?.note || 'No reason provided'}
										</li>
									</ul>
								</div>
							}
							color="red"
							actions={[
								<CancelSubscription account={account}>
									{(open) => (
										<GButton size="xs" color="danger" onClick={() => open()}>
											Cancel subscription
										</GButton>
									)}
								</CancelSubscription>
							]}
						/>
					</GTransition>
					<OffersSection account={account} />
					<EventsList id={account.id} type="workspace" />
					<WebsitesSection account={account} />
				</div>
			</div>
		</div>
	)
}
