import { GButton, GSlideOver } from 'components/basic-blocks'
import { eventLinkGenerator } from 'config/notificationsLinksMapping'
import { RiCheckLine, RiCloseLine } from 'react-icons/ri'
import { useAssetStore, useUiStore } from 'store'
import { GVirtuosoList } from '../../../components/basic-blocks/g-virtualized-list/virtuos-virtulized-lits'
import { NotificationItem } from './notificationItem'
import { SlideOverNotificationsProps } from './types'
import { GNewTooltip } from '../../../components/basic-blocks/g-tooltip/g-new-tooltip'

export const SlideOverNotifications = (props: SlideOverNotificationsProps) => {
	const {
		open,
		setOpen,
		state,
		onReadOneNotification,
		onReadAllNotification,
		notificationId
	} = props
	const isLoading = useUiStore((s) => s.isLoading)
	const { demoMode } = useAssetStore()

	return (
		<GSlideOver position="right" open={open} setOpen={setOpen} size="md">
			<div>
				<div className="py-4 px-4">
					<div className="flex justify-between items-center mb-4">
						<div className="text-t-dark font-medium">Notifications</div>
						<div className="rounded-full hover:bg-gray-200">
							<RiCloseLine
								className="text-t-dark w-5 h-5 cursor-pointer"
								onClick={() => setOpen(false)}
							/>
						</div>
					</div>

					{state.notifications?.length === 0 && (
						<div className="flex items-center justify-center text-t-dark mt-2 px-14">
							No notifications available
						</div>
					)}
					{state.notifications?.length > 0 && (
						<>
							<div className="overflow-y-hidden overflow-x-hidden h-[calc(100vh-168px)] min-w-[430px]">
								<GVirtuosoList
									length={state.notifications.length}
									height={'100%'}
								>
									{(index: number) => {
										const link = eventLinkGenerator(state.notifications[index])
										return (
											<NotificationItem
												solo={state.notifications.length === 1}
												key={state.notifications[index].id}
												link={link}
												data={state.notifications[index]}
												notificationId={notificationId}
												unreadIcon={
													<GButton
														className="z-10"
														type="icon"
														variant="colored"
														shape="circle"
														icon={RiCloseLine}
														size="xxs"
														onClick={() =>
															onReadOneNotification(
																state.notifications[index].id
															)
														}
													>
														Mark as read
													</GButton>
												}
											/>
										)
									}}
								</GVirtuosoList>
							</div>
							<div className="flex items-center justify-center gap-3 mt-5">
								<GNewTooltip
									content={
										demoMode
											? 'This action is disabled when demo data is displayed'
											: null
									}
								>
									<GButton
										label="Mark all as read"
										icon={RiCheckLine}
										color="primary"
										disabled={demoMode}
										variant="text"
										onClick={() => onReadAllNotification()}
										loading={isLoading('READ_NOTIFICATIONS')}
									/>
								</GNewTooltip>
							</div>
						</>
					)}
				</div>
			</div>
		</GSlideOver>
	)
}
