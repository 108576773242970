import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { VisualPreferencesItem } from 'api-models/src'
import { useStores } from 'hooks'
import { useState } from 'react'
import { RiCheckLine, RiSearchLine, RiTableLine } from 'react-icons/ri'
import { TableAvailableColumnsProps } from './types'

export const TableAvailableColumns = ({
	availableColumns,
	selectedColumns,
	setSelectedColumns,
	assetId,
	page
}: TableAvailableColumnsProps) => {
	const { userStore } = useStores()
	const { visualPreferences, updateVisualPreferences } = userStore
	const [filterInput, setFilterInput] = useState('')

	const filteredAvailableColumns = filterInput
		? availableColumns.filter(
				(c) =>
					typeof c.label === 'string' &&
					c.label.toLowerCase().includes(filterInput.toLowerCase())
		  )
		: availableColumns

	const handleClose = (isOpen: boolean) => {
		if (isOpen) return

		const columns = Array.from(selectedColumns)

		if (visualPreferences) {
			const preferences = JSON.parse(
				visualPreferences
			) as VisualPreferencesItem[]
			const index = preferences.findIndex(
				(p) => p.target_id === assetId && p.page === page
			)

			if (index === -1) {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				preferences.push({ target_id: assetId!, page: page, columns })
			} else {
				preferences[index] = { ...preferences[index], columns }
			}

			updateVisualPreferences(JSON.stringify(preferences))
		} else {
			const preferences = [{ target_id: assetId, page: page, columns }]
			updateVisualPreferences(JSON.stringify(preferences))
		}
	}

	return (
		<DropdownMenu.Root onOpenChange={handleClose} modal={false}>
			<DropdownMenu.Trigger className="h-[30px] font-bold rounded-md text-t-default bg-white py-2 px-4 flex items-center rounded border border-gray-200 hover:bg-gray-100 focus:outline-none z-9">
				<RiTableLine className="h-4 w-4 mr-1" />
				Columns
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content
					sideOffset={5}
					align="end"
					className="flex flex-col gap-1 bg-white rounded border border-gray-200 p-2 max-h-[530px] overflow-auto !z-10"
				>
					<div className="flex items-center border-b px-2 mb-2">
						<RiSearchLine className="h-3.5 w-3.5 text-t-secondary" />
						<input
							type="text"
							value={filterInput}
							placeholder="Column..."
							onChange={(e) => {
								setFilterInput(e.target.value)
							}}
							className="h-10 bg-transparent w-full outline-none text-sm focus:ring-0 border-none text-t-secondary"
						/>
					</div>
					{filteredAvailableColumns.map((column) => {
						const isSelected = selectedColumns.has(column.key)
						return (
							<DropdownMenu.CheckboxItem
								key={column.key}
								checked={isSelected}
								onClick={(e) => {
									e.preventDefault()

									if (isSelected) {
										setSelectedColumns((prevSet) => {
											const copySet = prevSet
											copySet.delete(column.key)
											return new Set(copySet)
										})
									} else {
										setSelectedColumns((set) => new Set(set.add(column.key)))
									}
								}}
								className="flex items-center px-2 py-1 cursor-pointer rounded hover:bg-gray-100 focus:outline-none"
							>
								<DropdownMenu.ItemIndicator>
									<RiCheckLine className="h-4 w-4 text-primary-500" />
								</DropdownMenu.ItemIndicator>
								<span className={isSelected ? 'ml-3' : 'ml-7'}>
									{column.label}
								</span>
							</DropdownMenu.CheckboxItem>
						)
					})}
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	)
}
