import {
	BingAccountLinkDTO,
	BingAdsAccountBasicInfoDTO,
	FacebookAccountLinkDTO,
	FacebookAdsAccountBasicInfoDTO,
	GoogleAdsAccountBasicInfoDTO,
	MCCAccountLinkDTO
} from 'api-models'
import { GoogleOAuthAccountLinkDTO } from 'api-models/src'
import {
	GButton,
	GInput,
	GListBox,
	GLoading,
	GToggle,
	GTooltip
} from 'components/basic-blocks'
import { GWindowedSelect } from 'components/basic-blocks/g-virtualized-select'
import { useStores } from 'hooks'
import { RiArrowRightSLine, RiQuestionLine } from 'react-icons/ri'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { useUiStore } from 'store'
import { removeFadPrefix } from 'utils'
import { PlatformSelector } from '../platform-selector'
import { AddNewSiteViewProps } from './types'
import { useState } from 'react'

export const AddSiteView = (props: AddNewSiteViewProps) => {
	const {
		adsConnection,
		state,
		setState,
		handleNameChange,
		handleAddSite,
		handleGoogleMultiSelectChange,
		handleFacebookMultiSelectChange,
		handleBingMultiSelectChange,
		handleAccountNumberChange,
		handleTrackingChange,
		handleCustomAudienceChange,
		handleAuthorize
	} = props
	const { accountStore } = useStores()
	const { account } = accountStore
	const isLoading = useUiStore((s) => s.isLoading)

	const castingGoogleOptions = () => {
		const castedGoogleOptions: (
			| GoogleOAuthAccountLinkDTO
			| MCCAccountLinkDTO
		)[] = []
		adsConnection.forEach((item) => {
			if (item.type === 'oauth') {
				const castedItem = item as GoogleOAuthAccountLinkDTO
				castedGoogleOptions.push(castedItem)
			}
			if (item.type === 'mcc') {
				castedGoogleOptions.push(item as MCCAccountLinkDTO)
			}
		})
		return castedGoogleOptions
	}

	const castingFacebookOptions = () => {
		const castedFacebookOptions: FacebookAccountLinkDTO[] = []
		adsConnection.forEach((item) => {
			const castedItem = item as FacebookAccountLinkDTO
			castedFacebookOptions.push(castedItem)
		})
		return castedFacebookOptions
	}

	const castingBingOptions = () => {
		const castedBingOptions: BingAccountLinkDTO[] = []
		adsConnection.forEach((item) => {
			const castedItem = item as BingAccountLinkDTO
			castedBingOptions.push(castedItem)
		})
		return castedBingOptions
	}

	return (
		<>
			<div className="my-6">
				<GInput
					value={state.siteName}
					label="Website name"
					placeholder="example.com"
					error={state.errors?.siteName}
					onChange={(e) => handleNameChange(e)}
				/>
			</div>
			<PlatformSelector
				account={account}
				platform={state.platform}
				setPlatform={(e) => {
					setState({ ...state, platform: e })
				}}
			/>
			<div className="bg-gray-50 rounded-lg px-6 py-6 space-y-4 flex flex-col justify-center">
				<div className="pb-2 text-sm space-y-4">
					<span className="text-t-secondary font-normal">
						Select how ClickGUARD will access{' '}
						{state.platform === 'GADS'
							? 'Google Ads'
							: state.platform === 'FADS'
							? 'Meta Ads'
							: 'Bing Ads'}
					</span>
				</div>
				<div className="flex items-end">
					<div className="mt-2 mr-5 w-1/2">
						{adsConnection ? (
							<>
								{state.platform === 'GADS' && (
									<GListBox<MCCAccountLinkDTO | GoogleOAuthAccountLinkDTO>
										label="Access"
										value={state?.selectedGoogleLink}
										options={castingGoogleOptions()}
										error={state.errors?.selectedLink}
										onChange={(val) => handleGoogleMultiSelectChange(val)}
										renderLabel={(list) => <>{list?.name}</>}
										disabled={adsConnection.length === 0}
										placeholder={
											adsConnection.length > 0
												? 'Select Link'
												: 'No access links for Google Ads'
										}
									/>
								)}
							</>
						) : (
							<GLoading />
						)}
						{adsConnection ? (
							<>
								{state.platform === 'FADS' && (
									<GListBox<FacebookAccountLinkDTO>
										label="Access"
										value={state?.selectedFacebookLink}
										options={castingFacebookOptions()}
										error={state.errors?.selectedLink}
										onChange={(val) => handleFacebookMultiSelectChange(val)}
										renderLabel={(list) => <>{list.email}</>}
										disabled={adsConnection.length === 0}
										placeholder={
											adsConnection.length > 0
												? 'Select Link'
												: 'No access links for Meta Ads'
										}
									/>
								)}
							</>
						) : (
							<GLoading />
						)}
						{adsConnection ? (
							<>
								{state.platform === 'BADS' && (
									<GListBox<BingAccountLinkDTO>
										label="Access"
										value={state?.selectedBingLink}
										options={castingBingOptions()}
										error={state.errors?.selectedLink}
										onChange={(val) => handleBingMultiSelectChange(val)}
										renderLabel={(list) => <>{list.email}</>}
										disabled={adsConnection.length === 0}
										placeholder={
											adsConnection.length > 0
												? 'Select Link'
												: 'No access links for Bing Ads'
										}
									/>
								)}
							</>
						) : (
							<GLoading />
						)}
					</div>
					<GButton
						className="flex-start !py-[7.5px]"
						size="sm"
						color="neutral"
						variant="contained"
						type="text"
						label="New authorization"
						loading={isLoading('ACCOUNT_ADD_ACCESS')}
						onClick={handleAuthorize}
					/>
				</div>
				<div className="my-4">
					{state.platform === 'GADS' && state?.selectedGoogleLink && (
						<div className="space-y-4">
							<div className="w-1/2">
								<GWindowedSelect
									label="Google Ads account number"
									options={[...(state?.selectedGoogleLink?.clients || [])]}
									value={state?.accountNumber || undefined}
									onChange={(newValue) =>
										handleAccountNumberChange(
											newValue as GoogleAdsAccountBasicInfoDTO
										)
									}
									placeholder="Select Google Ads account number"
									isOptionSelected={(option) =>
										(option as GoogleAdsAccountBasicInfoDTO).account_number ===
										state?.accountNumber?.account_number
									}
									formatOptionLabel={(option) =>
										(option as GoogleAdsAccountBasicInfoDTO).account_number
									}
									filterOption={(option, query) => {
										return query
											? (
													option as FilterOptionOption<GoogleAdsAccountBasicInfoDTO>
											  ).data.account_number
													?.toLocaleLowerCase()
													.includes(query)
											: true
									}}
									error={state.errors?.accountNumber}
								/>
							</div>

							<div className="flex space-x-1 items-center">
								<GToggle
									checked={state.tracking}
									onChange={(v) => handleTrackingChange(v)}
									label="Automatically configure click tracking"
								/>
								<GTooltip content="Sets ClickGUARD tracking template on your Google Ads account">
									<RiQuestionLine className="text-t-secondary w-3" />
								</GTooltip>
							</div>
						</div>
					)}
					{state.platform === 'FADS' && state?.selectedFacebookLink && (
						<div className="space-y-4">
							<div className="w-1/2">
								<GWindowedSelect
									label="Meta Ads account"
									options={[...(state?.selectedFacebookLink?.clients || [])]}
									value={state?.accountNumber || undefined}
									onChange={(newValue) =>
										handleAccountNumberChange(
											newValue as FacebookAdsAccountBasicInfoDTO
										)
									}
									placeholder="Select your Meta Ads account"
									isOptionSelected={(option) =>
										(option as FacebookAdsAccountBasicInfoDTO)
											.account_number === state?.accountNumber?.account_number
									}
									formatOptionLabel={(option) =>
										`${
											(option as FacebookAdsAccountBasicInfoDTO).name
										} - ${removeFadPrefix(
											(option as FacebookAdsAccountBasicInfoDTO).account_number
										)}`
									}
									filterOption={(option, query) => {
										return query
											? (
													option as FilterOptionOption<FacebookAdsAccountBasicInfoDTO>
											  ).data.account_number
													?.toLocaleLowerCase()
													.includes(query)
											: true
									}}
									error={state.errors?.accountNumber}
								/>
							</div>

							<div className="flex space-x-1 items-center">
								<GToggle
									checked={state.set_custom_audience}
									onChange={(c) => handleCustomAudienceChange(c)}
									label="Automatically set excluded audience in ad sets"
								/>
								<GTooltip
									content={
										"Updates targeting on ad sets to exclude ClickGUARD's audience of blocked users"
									}
								>
									<RiQuestionLine className="text-t-secondary w-3" />
								</GTooltip>
							</div>
						</div>
					)}
					{state.platform === 'BADS' && state?.selectedBingLink && (
						<div className="space-y-4">
							<div className="w-1/2">
								<GWindowedSelect
									label="Bing Ads account"
									options={[...(state?.selectedBingLink?.clients || [])]}
									value={state?.accountNumber || undefined}
									onChange={(newValue) => {
										handleAccountNumberChange(
											newValue as BingAdsAccountBasicInfoDTO
										)
									}}
									placeholder="Select your Meta Ads account"
									isOptionSelected={(option) =>
										(option as BingAdsAccountBasicInfoDTO).account_number ===
										state?.accountNumber?.account_number
									}
									formatOptionLabel={(option) =>
										`${
											(option as BingAdsAccountBasicInfoDTO).name
										} - ${removeFadPrefix(
											(option as BingAdsAccountBasicInfoDTO).account_number
										)}`
									}
									filterOption={(option, query) => {
										return query
											? (
													option as FilterOptionOption<FacebookAdsAccountBasicInfoDTO>
											  ).data.account_number
													?.toLocaleLowerCase()
													.includes(query)
											: true
									}}
									error={state.errors?.accountNumber}
								/>
							</div>

							<div className="flex space-x-1 items-center">
								<GToggle
									labelClassName="text-t-default text-base"
									checked={state.tracking}
									onChange={(value) =>
										setState({
											...state,
											tracking: value
										})
									}
									label="Automatically configure click tracking"
								/>
								<GTooltip
									content={
										'Sets ClickGUARD tracking template on your Bing Ads account'
									}
								>
									<RiQuestionLine className="text-t-secondary w-3" />
								</GTooltip>
							</div>
							<div className="flex space-x-1 items-center">
								<GToggle
									labelClassName="text-t-default text-base"
									checked={state.set_custom_audience}
									onChange={(c) =>
										setState({
											...state,
											set_custom_audience: c
										})
									}
									label="Automatically set excluded audience"
								/>
								<GTooltip
									content={
										"Updates targeting on campaigns to exclude ClickGUARD's audience of blocked users"
									}
								>
									<RiQuestionLine className="text-t-secondary w-3" />
								</GTooltip>
							</div>
						</div>
					)}
				</div>
				<div className="flex justify-center pt-2">
					<GButton
						label={`Add ${state.siteName}`}
						color="primary"
						size="sm"
						className="px-8"
						labelClassName="truncate max-w-xs"
						icon={RiArrowRightSLine}
						iconPlacement="right"
						onClick={() => handleAddSite()}
						disabled={!state.accountNumber || !state.siteName}
						loading={isLoading('ACCOUNT_ADD_ASSET')}
					/>
				</div>
			</div>
		</>
	)
}
