import { ReferenceDomainDTO } from 'api-models'
import { AccountInfo } from 'components/account-info'
import { GDateTime } from 'components/basic-blocks/g-datetime'
import { GTableColumn } from 'components/basic-blocks/g-table/types'
import moment from 'moment'

export const columns: Array<GTableColumn<ReferenceDomainDTO>> = [
	{
		label: 'domain',
		path: 'domain',
		render: (row: ReferenceDomainDTO) => <DomainColumn row={row} />,
		width: '400px'
	},
	{
		label: 'First seen',
		path: 'firstSeen',
		render: (row: ReferenceDomainDTO) => <FirstSeenColumn row={row} />,
		width: '250px'
	},
	{
		label: 'Last seen',
		path: 'lastSeen',
		render: (row: ReferenceDomainDTO) => <LastSeenColumn row={row} />,
		width: '250px'
	},
	{
		label: 'Users',
		path: 'users',
		render: (row: ReferenceDomainDTO) => <UsersColumn row={row} />,
		className: 'justify-center',
		width: '100px'
	},
	{
		label: 'Accounts',
		path: 'accounts',
		render: (row: ReferenceDomainDTO) => <AccountsColumn row={row} />,
		className: 'justify-center',
		width: '100px'
	}
]

const DomainColumn = (props: { row: ReferenceDomainDTO }) => {
	const { row } = props
	return (
		<>
			<AccountInfo data={row}>
				{(open: any) => (
					<div className="flex flex-col gap-1" onClick={() => open()}>
						<div className="font-medium text-primary-400 hover:bg-gray-100 px-2 py-1 cursor-pointer rounded-lg truncate max-w-[25rem]">
							{row.domain}
						</div>
					</div>
				)}
			</AccountInfo>
		</>
	)
}

const FirstSeenColumn = (props: { row: ReferenceDomainDTO }) => {
	const { row } = props
	return (
		<>
			<div className="flex flex-col gap-1">
				<div className="font-medium text-gray-500">
					<GDateTime date={row.first_seen} />
				</div>
				<div className="text-sm text-gray-400">
					{moment(row.first_seen).format('MMMM Do, YYYY - hh:mm:ss A')}
				</div>
			</div>
		</>
	)
}

const LastSeenColumn = (props: { row: ReferenceDomainDTO }) => {
	const { row } = props
	return (
		<>
			<div className="flex flex-col gap-1">
				<div className="font-medium text-gray-500">
					<GDateTime date={row.last_seen} />
				</div>
				<div className="text-sm text-gray-400">
					{moment(row.last_seen).format('MMMM Do, YYYY - hh:mm:ss A')}
				</div>
			</div>
		</>
	)
}

const UsersColumn = (props: { row: ReferenceDomainDTO }) => {
	const { row } = props
	return (
		<>
			<div className="flex flex-col gap-1">
				<div className="font-medium text-gray-400">{row.user_id.length}</div>
			</div>
		</>
	)
}

const AccountsColumn = (props: { row: ReferenceDomainDTO }) => {
	const { row } = props
	return (
		<>
			<div className="flex flex-col gap-1">
				<div className="font-medium text-gray-400">{row.account_id.length}</div>
			</div>
		</>
	)
}
