import { ProductAccountDTO } from 'api-models'
import { UserAvatar, WorkspaceAvatar } from 'components'
import { GButton, GTooltip } from 'components/basic-blocks'
import { GDateTime } from 'components/basic-blocks/g-datetime'
import { PlanNames } from 'config'
import { useStores } from 'hooks'
import moment from 'moment'
import { ReactElement, useEffect, useState } from 'react'

import { RiCloseLine, RiLoginCircleLine, RiStackFill } from 'react-icons/ri'
import { NavLink, useNavigate } from 'react-router-dom'
import { GBadge } from '../../../../components/basic-blocks/g-badge'

import { getAccountBadgeColorByStatus } from 'utils/user'

import { formatCurrency, formatPriceInterval, formatSpend } from 'utils'
import { SubscriptionColors } from 'utils/colors'

const commonClassName =
	'font-bold text-t-input-secondary justify-start w-max text-base'

export const columns = [
	{
		label: 'Impersonate',
		key: 'impersonate',
		render: (row: ProductAccountDTO) => <Impersonate row={row} />,
		className: commonClassName
	},
	{
		label: 'Information',
		key: 'information',
		render: (row: ProductAccountDTO) => <Information row={row} />,
		className: commonClassName
	},
	{
		label: 'Users',
		key: 'users',
		render: (row: ProductAccountDTO) => <Users account={row} />,
		className: commonClassName
	},
	{
		label: 'Status',
		key: 'status',
		render: (row: ProductAccountDTO) => <StatusColumn row={row} />,
		className: commonClassName
	},
	{
		label: 'Subscription status',
		key: 'subscription_status',
		render: (row: ProductAccountDTO) => <SubscriptionStatus row={row} />,
		className: commonClassName
	},
	{
		label: 'Subscription plan',
		key: 'subscription_plan',

		className: commonClassName,
		render: (row: ProductAccountDTO) => <SubscriptionPlan row={row} />
	},
	{
		label: 'Price',
		key: 'price',
		className: commonClassName,
		sortKey: 'price',
		render: (row: ProductAccountDTO) => <Price row={row} />
	},

	{
		label: 'Next event',
		key: 'next_event',
		sortKey: 'next_event',
		className: commonClassName,
		render: (row: ProductAccountDTO) => <NextEvent row={row} />
	},
	{
		label: 'Last reported ad spend',
		key: 'last_reported_ad_spend',
		className: commonClassName,
		render: (row: ProductAccountDTO) => <LastReport row={row} />
	},
	{
		label: 'Setup information',
		key: 'setup_information',
		className: commonClassName,
		render: (row: ProductAccountDTO) => <SetupInformation row={row} />
	},
	{
		label: 'Cancel requests',
		key: 'cancel_requests',
		className: commonClassName,
		render: (row: ProductAccountDTO) => <CancelRequests row={row} />
	},
	{
		label: 'Offer requests',
		key: 'offer_requests',
		className: commonClassName,
		render: (row: ProductAccountDTO) => <OfferRequests row={row} />
	},
	{
		label: 'Limit breached',
		key: 'limit_breached',
		className: commonClassName,
		render: (row: ProductAccountDTO) => <LimitBreached row={row} />
	},
	{
		label: 'Created at',
		key: 'created_at',
		sortKey: 'created_at',
		className: commonClassName,
		render: (row: ProductAccountDTO) => <CreatedAt row={row} />
	},
	{
		label: 'Total paid',
		key: 'total_paid',
		sortKey: 'total_paid',
		className: commonClassName,
		render: (row: ProductAccountDTO) => <TotalPaid row={row} />
	},
	{
		label: 'Total refunded amount',
		key: 'total_refunded_amount',
		sortKey: 'total_refunded_amount',
		className: commonClassName,
		render: (row: ProductAccountDTO) => <TotalRefundedAmount row={row} />
	},
	{
		label: 'Beta access',
		key: 'beta_access',
		className: commonClassName,
		render: (row: ProductAccountDTO) => <BetaAccess row={row} />
	}
]

const Impersonate = (props: { row: ProductAccountDTO }) => {
	const navigate = useNavigate()
	const { row } = props
	const { adminStore } = useStores()
	const [loading, setLoading] = useState(false)

	const impersonate = async () => {
		setLoading(true)
		try {
			await adminStore.setImpersonation(row.user_info?.user_id ?? '')
			setLoading(false)
			navigate(
				`/workspace/${row.id}/asset/${
					row?.assets && row?.assets.length > 0
						? row?.assets[0].id
						: 'website-demo'
				}`
			)
		} catch (e) {
			setLoading(false)
		}
	}

	useEffect(() => {
		return () => {
			setLoading(false)
		}
	}, [])
	return (
		<div className="flex space-x-2 items-center">
			<div className="flex items-center mr-2">
				<GButton
					shape="square"
					type="icon"
					icon={RiLoginCircleLine}
					loading={loading}
					variant="contained"
					color="primary"
					size="xs"
					onClick={impersonate}
				>
					Impersonate
				</GButton>
			</div>
		</div>
	)
}

const Information = (props: { row: ProductAccountDTO }) => {
	const link = `/admin/subscription/${props.row.id}`
	return (
		<div className="flex justify-center items-center space-x-4">
			<WorkspaceAvatar workspace={props.row} size={8} />
			<div className="flex flex-col justify-center">
				<NavLink to={link}>
					<div className="truncate max-w-xs font-medium text-primary-500 hover:text-primary-600 cursor-pointer">
						{props.row.name}
					</div>
				</NavLink>
			</div>
		</div>
	)
}

const Users = (props: { account: ProductAccountDTO }) => {
	const navigate = useNavigate()
	const { account } = props
	const { adminStore } = useStores()
	const [loading, setLoading] = useState(false)

	const link = `/admin/user/${account.user_info?.user_id}`
	const name =
		account.status === 'SETUP' ? account.user_info?.name : account.billing?.name
	const email =
		account.status === 'SETUP'
			? account.user_info?.email
			: account.billing?.email

	const impersonate = async () => {
		setLoading(true)
		try {
			await adminStore.setImpersonation(account.user_info?.user_id ?? '')
			setLoading(false)
			navigate('/')
		} catch (e) {
			setLoading(false)
		}
	}

	useEffect(() => {
		return () => {
			setLoading(false)
		}
	}, [])

	const getUserAccessInfo = (user: any) => {
		return user?.access_level === 'READ'
			? 'Analyst'
			: user?.access_level === 'WRITE'
			? 'Manager'
			: user?.access_level === 'OWNER'
			? 'Owner'
			: 'Other'
	}

	const getUserBadgeColor = (user: any) => {
		return user?.access_level === 'READ'
			? 'green'
			: user?.access_level === 'WRITE'
			? 'purple'
			: user?.access_level === 'OWNER'
			? 'primary'
			: 'primary'
	}

	return (
		<div className="flex space-x-2 items-center">
			<GTooltip
				persist
				content={
					<div className="w-80 flex flex-col">
						{account?.user_access?.map((user: any, index: number) => (
							<div
								key={index}
								className="w-full flex justify-between items-center py-2"
							>
								<div className="w-full flex justify-start items-center">
									<div className="mr-2">
										<GButton
											shape="square"
											type="icon"
											loading={loading}
											icon={RiLoginCircleLine}
											variant="contained"
											color="primary"
											size="xs"
											onClick={impersonate}
										>
											Impersonate
										</GButton>
									</div>
									<div className="flex space-x-2 items-center">
										<UserAvatar size={8} src={user?.avatar} />
										<div
											className="flex flex-col group cursor-pointer"
											onClick={() => navigate(link)}
										>
											<div className="font-semibold text-primary-500 group-hover:text-primary-600">
												{name}
											</div>
											<div className="text-gray-500 max-w-[180px] truncate">
												{email}
											</div>
										</div>
									</div>
								</div>
								<GBadge
									className="rounded-md bg-badge-selected items-center"
									text={getUserAccessInfo(user)}
									color={getUserBadgeColor(user)}
								/>
							</div>
						))}
					</div>
				}
			>
				<div className="flex -space-x-4">
					{account?.user_access?.map((user: any, index: number) => (
						<div className="flex space-x-2 items-center" key={index}>
							<UserAvatar size={8} src={user?.avatar} />
						</div>
					))}
				</div>
			</GTooltip>
		</div>
	)
}

const StatusColumn = (props: { row: ProductAccountDTO }) => {
	const { row } = props
	const status = row?.status || 'NONE'

	return (
		<GBadge
			text={status}
			color={getAccountBadgeColorByStatus(status, undefined)}
		/>
	)
}

const SubscriptionStatus = (props: { row: ProductAccountDTO }) => {
	const { row } = props
	const status = row?.billing?.subscription?.status || 'NONE'

	const accountStatus = row.status

	const isTrail = row?.billing?.subscription?.type === 'TRIAL'

	if (accountStatus === 'SETUP') {
		return <GBadge text={'SETUP'} color={SubscriptionColors.NONE} />
	}

	if (isTrail) {
		return <GBadge text={'TRIAL'} color={SubscriptionColors.TRIAL} />
	}

	return <GBadge text={status} color={SubscriptionColors[status]} />
}

const SubscriptionPlan = (props: { row: ProductAccountDTO }) => {
	const planName = PlanNames[props.row?.billing?.subscription?.plan.tier || '']
	const status = props.row.status
	const plan = props.row.billing?.subscription?.plan

	let color: string
	if (status === 'ACTIVE') {
		color = 'purple'
	} else {
		color = 'gray'
	}

	return (
		<div className="flex justify-center items-center space-x-4">
			{status !== 'SETUP' ? (
				<>
					<div className="font-medium text-primary-500">
						{planName || 'N/A'}
					</div>
					{plan?.spend !== 0 && (
						<GBadge
							text={<span>{formatSpend(plan?.spend)}</span>}
							color={color}
						/>
					)}
				</>
			) : (
				<div className="font-medium text-primary-500">N/A</div>
			)}
		</div>
	)
}

const Price = (props: { row: ProductAccountDTO }) => {
	const { row } = props

	const status = row?.billing?.subscription?.status || 'NONE'
	const type = row?.billing?.subscription?.type || 'NONE'
	const accountStatus = row.status
	const plan = row.billing?.subscription?.plan
	const nextPlan = row.billing?.subscription?.next_plan

	const discount = row.billing?.subscription?.discount

	let color: string
	if (status === 'ACTIVE') {
		color = 'green'
	} else {
		color = 'gray'
	}

	if (status === 'SWITCHING') {
		return (
			<div>
				<div className="flex flex-col justify-start gap-2">
					<GBadge
						text={
							<span>
								{formatCurrency(plan?.price)}{' '}
								{formatPriceInterval(plan?.interval, plan?.interval_count)}
							</span>
						}
						color="gray"
					/>
					<GBadge
						text={
							<span>
								{formatCurrency(nextPlan?.price)}{' '}
								{formatPriceInterval(
									nextPlan?.interval,
									nextPlan?.interval_count
								)}
							</span>
						}
						color="green"
					/>
				</div>
			</div>
		)
	}

	return (
		<div className="flex flex-col justify-start gap-2">
			{accountStatus === 'SETUP' ? (
				<div
					className={
						'text-center inline-flex rounded-full py-1 text-xs font-semibold text-t-secondary'
					}
				>
					{accountStatus === 'SETUP' && (
						<GBadge
							className={'rounded-md align-center'}
							text="No plan"
							color={'amber'}
						/>
					)}
				</div>
			) : type === 'TRIAL' && nextPlan?.price !== undefined ? (
				<GBadge
					text={
						<span>
							{formatCurrency(nextPlan?.price)}{' '}
							{formatPriceInterval(
								nextPlan?.interval,
								nextPlan?.interval_count
							)}
						</span>
					}
					color={color}
				/>
			) : type === 'TRIAL' && nextPlan?.price === undefined ? (
				<GBadge
					className={'rounded-md align-center'}
					text="No plan"
					color={'amber'}
				/>
			) : (
				<GBadge
					text={
						<span>
							{formatCurrency(plan?.price)}{' '}
							{formatPriceInterval(plan?.interval, plan?.interval_count)}
						</span>
					}
					color={color}
				/>
			)}
			{discount && (
				<GBadge
					text={<span>{discount?.percent_off}% off </span>}
					color={color}
				/>
			)}
		</div>
	)
}

const NextEvent = (props: { row: ProductAccountDTO }) => {
	const { row } = props

	const nextPlan = row.billing?.subscription?.next_plan

	const status = row?.billing?.subscription?.status
	const type = row?.billing?.subscription?.type
	const accountStatus = row?.status

	const date = row?.billing?.subscription?.next_billing_date
	const endsAt = row?.billing?.subscription?.ends_at
	const endedAt = row?.billing?.subscription?.ended_at

	let title: ReactElement | string = ''
	const subtitle = moment(date || endsAt || endedAt).format('llll')

	if (accountStatus === 'SETUP')
		return (
			<div className="flex items-center w-full">
				<RiCloseLine className="h-5 w-5 mr-2 text-t-secondary" />
				None
			</div>
		)

	if (status === 'ACTIVE') {
		if (type === 'TRIAL') {
			title = (
				<span className="text-sm">
					Ends{' '}
					<span className="font-semibold">
						<GDateTime date={endsAt} />
					</span>
				</span>
			)
		} else {
			title = (
				<span className="text-sm">
					Renews{' '}
					<span className="font-semibold">
						<GDateTime date={date} />
					</span>
				</span>
			)
		}
	}

	if (status === 'SWITCHING') {
		title = (
			<div className="flex flex-col text-sm">
				<span>
					Switching to <strong>{nextPlan?.name}</strong>
				</span>
				<span className="font-semibold">
					<GDateTime date={date} />
				</span>
			</div>
		)
	}

	if (status === 'CANCELLING') {
		title = (
			<span className="text-sm">
				Cancelling{' '}
				<span className="font-semibold">
					<GDateTime date={endsAt} />
				</span>
			</span>
		)
	}

	if (status === 'CANCELLED') {
		title = (
			<span className="text-sm">
				Cancelled{' '}
				<span className="font-semibold">
					<GDateTime date={endedAt} />
				</span>{' '}
				ago
			</span>
		)
	}

	return (
		<div className="flex flex-1 justify-start">
			<div className="flex flex-col space-y-1 text-sm">
				<div className="font-medium text-gray-700">{title}</div>
				{subtitle && <div className="text-sm text-gray-500">{subtitle}</div>}
			</div>
		</div>
	)
}

const LastReport = (props: { row: ProductAccountDTO }) => {
	const { row } = props

	const accountStatus = row.status
	const status = row?.billing?.subscription?.status || 'NONE'
	const type = row?.billing?.subscription?.type
	const plan = row.billing?.subscription?.plan
	const nextPlan = row.billing?.subscription?.next_plan

	let color: string
	if (status === 'ACTIVE') {
		color = 'purple'
	} else {
		color = 'gray'
	}

	if (status === 'SWITCHING') {
		return (
			<div>
				<div className="flex flex-col justify-start gap-2">
					<GBadge text={<span>{formatSpend(plan?.spend)}</span>} color="gray" />
					<GBadge
						text={<span>{formatSpend(nextPlan?.spend)}</span>}
						color="purple"
					/>
				</div>
			</div>
		)
	}

	if (accountStatus === 'SETUP') {
		return (
			<GBadge
				className={'rounded-md align-center'}
				text="No plan"
				color={'amber'}
			/>
		)
	}

	if (type === 'TRIAL') {
		return (
			<div className="text-xs">
				{nextPlan && nextPlan?.spend !== 0 ? (
					<GBadge
						text={<span>{formatSpend(nextPlan?.spend)}</span>}
						color={color}
					/>
				) : (
					<GBadge
						className={'rounded-md align-center'}
						text="No plan"
						color={'amber'}
					/>
				)}
			</div>
		)
	}

	return (
		<div className="text-xs">
			{plan?.spend !== 0 ? (
				<GBadge text={<span>{formatSpend(plan?.spend)}</span>} color={color} />
			) : (
				<div className="flex items-center w-full">
					<RiCloseLine className="h-5 w-5 mr-2 text-t-secondary" />
					None
				</div>
			)}
		</div>
	)
}

const SetupInformation = (props: { row: ProductAccountDTO }) => {
	const { row } = props
	const setup = row?.setup
	return (
		<GTooltip
			content={
				<div className="flex flex-col">
					<div className="">Step: {setup?.step}</div>
					<div>Domain: {setup?.domain}</div>
					<div>
						Platforms:{' '}
						{setup?.platforms?.map((platform) => (
							<span key={platform}>{platform}</span>
						))}
					</div>
				</div>
			}
		>
			<div className="flex items-center w-full">
				<RiStackFill className="text-green-500 h-5 w-5 mr-2" />{' '}
			</div>
		</GTooltip>
	)
}

const CancelRequests = (props: { row: ProductAccountDTO }) => {
	const { row } = props
	const cancelReason = row.cancel_request?.note

	return (
		<>
			{cancelReason ? (
				<GTooltip content={cancelReason}>
					<GBadge
						text={cancelReason}
						color="red"
						className="block max-w-[100px] truncate"
					/>
				</GTooltip>
			) : (
				<GBadge text="None" color="gray" />
			)}
		</>
	)
}
const OfferRequests = (props: { row: ProductAccountDTO }) => {
	const { row } = props
	const offerRequests = row?.offer_request

	return (
		<>
			{offerRequests?.spend ? (
				<div className="w-full flex flex-col justify-center items-center">
					<div className="pb-1">
						<GBadge
							text={<span>{formatSpend(offerRequests?.spend)}</span>}
							color="purple"
						/>
					</div>
					<div className="pt-1">
						<GBadge
							text={<span>Billed {offerRequests?.interval}</span>}
							color="gray"
						/>
					</div>
				</div>
			) : (
				<GBadge text={<span>None</span>} color="gray" />
			)}
		</>
	)
}

const LimitBreached = (props: { row: ProductAccountDTO }) => {
	const { row } = props
	const limitBreached = row.limit_breached
	const almostBreached = row.limit_almost_breached
	const limitBreachedAt = row.limit_breached_at
	const adSpend = row.billing?.subscription?.plan?.spend
	return (
		<div>
			{limitBreached && (
				<div>
					<GBadge text={<span>Yes</span>} color="red" className="mb-2" />
					{moment(limitBreachedAt).format('MMMM Do YYYY - h:mm a')}
				</div>
			)}
			{almostBreached && <GBadge text={<span>Soon</span>} color="orange" />}
			{!limitBreached && !almostBreached && (
				<GBadge text={<span>No</span>} color="green" />
			)}
			<GBadge
				className="mt-2"
				text={<span>{formatSpend(adSpend)}</span>}
				color="purple"
			/>
		</div>
	)
}

const CreatedAt = (props: { row: ProductAccountDTO }) => {
	const { row } = props
	return (
		<GTooltip
			content={
				<div className="flex flex-col">
					<div className="">
						{moment(row.created_at).format('MMMM Do YYYY - h:mm a')}
					</div>
				</div>
			}
		>
			<div className="flex justify-center text-sm">
				<div>{moment(row.created_at).format('MMMM Do YYYY')}</div>
			</div>
		</GTooltip>
	)
}

const TotalPaid = (props: { row: ProductAccountDTO }) => {
	const totalPaid = props.row.billing?.total_paid
	return (
		<div className="flex flex-col justify-start gap-2">
			{totalPaid !== 0 ? (
				<GBadge text={<span>{formatCurrency(totalPaid)}</span>} color="green" />
			) : (
				<GBadge
					className={'rounded-md align-center'}
					text="No plan"
					color={'amber'}
				/>
			)}
		</div>
	)
}

const TotalRefundedAmount = (props: { row: ProductAccountDTO }) => {
	const totalRefund = props.row.billing?.total_refunded
	return (
		<div className="flex flex-col justify-start gap-2">
			{totalRefund !== 0 ? (
				<GBadge text={<span>{formatCurrency(totalRefund)}</span>} color="red" />
			) : (
				<GBadge
					className={'rounded-md align-center'}
					text="No plan"
					color={'amber'}
				/>
			)}
		</div>
	)
}

const BetaAccess = (props: { row: ProductAccountDTO }) => {
	const { row } = props

	return (
		<div className="w-full flex space-x-2 items-center">
			<GBadge
				className={'rounded-md align-center'}
				text={row.beta_testing ? 'Yes' : 'No'}
				color={'amber'}
			/>
		</div>
	)
}
