import { useStores } from 'hooks'
import { lazy, Suspense, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ProductAccounts } from '../../../services/user'
import { GLazyLoading } from '../../../components/basic-blocks'

const BillingPageView = lazy(() =>
	import('./view')
		.then((module) => {
			return { default: module.BillingPageView }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)

export const BillingPage = () => {
	const { accountStore } = useStores()
	const { account, setAccountForId, wantsToSeeDemoData } = accountStore
	const tabs = [
		{
			label: 'Subscription',
			key: 'subscription',
			disabled: wantsToSeeDemoData,
			to: () => `/settings/workspace/${account?.id}/billing?tab=subscription`
		},
		{
			label: 'Payment methods',
			key: 'methods',
			to: () => `/settings/workspace/${account?.id}/billing?tab=methods`,
			disabled: !account?.billing
		},
		{
			label: 'Invoices',
			key: 'invoices',
			disabled: wantsToSeeDemoData || !account?.billing,
			to: () => `/settings/workspace/${account?.id}/billing?tab=invoices`
		}
	]
	const [search] = useSearchParams(
		wantsToSeeDemoData ? 'tab=methods' : 'tab=subscription'
	)
	const [selectedTab, setSelectedTab] = useState(
		search.get('tab') || tabs[0].key
	)

	const init = async (selectedTab: string) => {
		if (selectedTab === 'subscription' && account?.billing?.subscription) {
			account && (await accountStore.getSubscriptionByAccountId(account.id))
		}
	}

	useEffect(() => {
		if (accountStore?.subscription) {
			if (
				accountStore?.subscription.status === 'ACTIVE' &&
				accountStore?.subscription?.payment_failed_reason &&
				account
			) {
				ProductAccounts.clearSubscriptionFailedMessage(account.id)
			}
		}
	}, [accountStore?.subscription])

	useEffect(() => {
		;(async () => {
			await init(selectedTab)
			account?.billing?.subscription && (await setAccountForId(account.id))
		})()
	}, [selectedTab])

	useEffect(() => {
		if (wantsToSeeDemoData) {
			if (
				search.get('tab') !== 'subscription' &&
				search.get('tab') !== 'invoices'
			) {
				setSelectedTab(search.get('tab') || tabs[0].key)
			}
		} else {
			setSelectedTab(search.get('tab') || tabs[0].key)
		}
	}, [search])

	return (
		<Suspense fallback={<GLazyLoading />}>
			<BillingPageView selectedTab={selectedTab} tabs={tabs} />
		</Suspense>
	)
}
