import { GTransition } from '../../../components/basic-blocks'
import { useState } from 'react'
import { AdminTeamList } from './admin-team'
import { InviteAdminTeam } from './invite-admin-team'

export const AdminTeam = () => {
	const [page, setPage] = useState<'list' | 'invite'>('list')
	return (
		<div>
			<GTransition show={page === 'list'}>
				<AdminTeamList setPage={setPage} />
			</GTransition>
			<GTransition show={page === 'invite'}>
				<InviteAdminTeam setPage={setPage} />
			</GTransition>
		</div>
	)
}
