import { useStores } from 'hooks'
import { useEffect } from 'react'
import { LegacyProps } from '../types'
import { LegacyView } from './legacy-view'
import { LostView } from './lost-view'

export const Legacy = ({ lost, onConfirm, loading }: LegacyProps) => {
	const { billingStore } = useStores()

	useEffect(() => {
		billingStore.initBilling()
	}, [])

	if (lost) {
		return <LostView onConfirm={onConfirm} loading={loading} />
	}

	return <LegacyView onConfirm={onConfirm} loading={loading} />
}
