import { BsDot } from 'react-icons/bs'
import { GButton } from '../../../../components/basic-blocks'
import { MdCheck } from 'react-icons/md'
import { Dispatch } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from '../../../../store'
import { ZendeskService } from '../../../../services/zendesk'
import { EXTERNAL_APP_BASE_URL } from '../../../../utils/app-links'

export const AffiliateAbout = ({
	setAffiliateTabs
}: {
	setAffiliateTabs?: Dispatch<Array<any>>
}) => {
	const navigate = useNavigate()
	const user = useUserStore((state) => state.user)
	return (
		<div className="max-w-3xl p-6 bg-white rounded-md sm:rounded-lg mb-6 border border-t-border-light">
			<p className="text-lg font-bold">Cash and More with ClickGUARD </p>
			<p className="text-secondary text-base">
				Enjoy ClickGUARD and know someone else who could benefit from it as
				well? With your unique link, you can earn with ClickGUARD.
			</p>
			<div className="flex flex-col justify-center gap-y-4 py-6">
				<div>
					<p className="text-base font-bold">20% Lifetime Commission</p>
					<div className="flex items-baseline gap-x-4 pt-1">
						<BsDot />
						<p>
							Enjoy a generous 20% commission on sales for the entire lifetime
							of the referred account. Your influence keeps giving back,
							ensuring lasting rewards.
						</p>
					</div>
				</div>
				<div>
					<p className="text-base font-bold">60-Day Cookie Lifespan:</p>
					<div className="flex items-baseline gap-x-4 pt-1">
						<BsDot />
						<p>
							Your referrals are valuable, and we make sure you get credit for
							them. Our 60-day cookie lifespan ensures that you earn commissions
							for sales generated within two months of your referral.
						</p>
					</div>
				</div>
				<div>
					<p className="text-base font-bold">Monthly payout</p>
					<div className="flex items-baseline gap-x-4 pt-1">
						<BsDot />
						<p>
							No need to wait around! Enjoy the convenience of monthly payouts,
							putting your hard-earned commissions in your hands regularly.
						</p>
					</div>
				</div>
				<div>
					<p className="text-base font-bold">
						Special Bonus for High Performers
					</p>
					<div className="flex items-baseline gap-x-4 pt-1">
						<BsDot />
						<p>
							Go above and beyond, and we'll recognize your efforts. Affiliates
							who bring in more than $10,000 in annual revenue will receive a
							one-off $1000 bonus. For affiliates with customers whose ad spend
							exceeds $500,000, we've got an additional reward waiting for you!
						</p>
					</div>
				</div>
				<div>
					<p className="text-base font-bold">Per-Trial Sign Up Rewards</p>
					<div className="flex items-baseline gap-x-4 pt-1">
						<BsDot />
						<p>
							Earn $7 for every valid trial sign-up, even if it doesn't convert
							to a purchase. We define a 'valid' trial as one where the user
							connects any of their Ad Accounts and logs in at least once during
							the trial period.
						</p>
					</div>
				</div>
				<p className="text-base flex items-center gap-x-1">
					By participating in the ClickGUARD Affiliate program, you are
					consenting to these{' '}
					<a
						href={`${EXTERNAL_APP_BASE_URL}/affiliate-terms-and-conditions`}
						target="_blank"
						className="text-primary-500"
					>
						Terms and Conditions
					</a>
					.
				</p>
			</div>
			<div className="flex justify-center items-center">
				{!user?.affiliate_profile ? (
					<GButton
						className="w-[202px] h-[36px]"
						icon={MdCheck}
						variant="contained"
						label="Become an Affiliate"
						color="primary"
						labelClassName="text-lg font-bold"
						onClick={() => {
							setAffiliateTabs &&
								setAffiliateTabs([
									{
										label: 'Set up your Affiliate Account',
										key: 'settings',
										to: () => '/workspace/affiliate?tab=settings'
									}
								])
							navigate('/workspace/affiliate?tab=settings')
						}}
					/>
				) : (
					<GButton
						className="w-[202px] h-[36px]"
						variant="outlined"
						label="Questions? Contact Us"
						color="primary"
						labelClassName="text-lg font-bold"
						onClick={() => ZendeskService.open()}
					/>
				)}
			</div>
		</div>
	)
}
