import { GButton, GTooltip } from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'
import { useStores } from 'hooks'
import { useMemo, useState } from 'react'
import { monthlyReportFeatures } from '../../../../../config/features'
import { content } from '../constants'
import { RunReportsDialog, ScheduleReportsDialog } from '../triggers/runReports'
import { OnDemandReportViewProps } from '../types'

export const ReportView = (props: OnDemandReportViewProps) => {
	const [open, setOpen] = useState(false)
	const [openSchedule, setOpenSchedule] = useState(false)
	const { type } = props
	const { accountStore, assetStore } = useStores()
	const { demoMode } = assetStore
	const plan = accountStore?.account?.billing?.subscription?.plan?.tier || ''
	const { scheduleAccess, runAccess } = useMemo(() => {
		if (demoMode) {
			return {
				scheduleAccess: true,
				runAccess: true
			}
		}
		if (plan === 'pro' || plan === 'custom_pro') {
			return {
				scheduleAccess: true,
				runAccess: true
			}
		}
		if (accountStore?.account?.billing?.subscription?.plan?.tier) {
			return {
				scheduleAccess:
					!!monthlyReportFeatures[
						accountStore?.account?.billing?.subscription?.plan?.tier
					][type],
				runAccess:
					accountStore?.account?.billing?.subscription?.plan?.tier === 'pro'
			}
		}
		return {
			scheduleAccess: false,
			runAccess: false
		}
	}, [plan])

	const RenderActionButton = () => {
		return (
			<GTooltip
				content={
					demoMode ? 'This action is disabled when demo data is displayed' : ''
				}
			>
				<div className="flex items-center gap-3">
					{scheduleAccess ? (
						<GButton
							label="Schedule"
							onClick={() => setOpenSchedule(!openSchedule)}
							color="neutral"
							variant="text"
							size="xs"
							className="min-w-[45px]"
							disabled={demoMode}
							labelClassName="text-sm font-bold text-t-default"
						/>
					) : (
						<GBadge
							text="Standard"
							color="purple"
							tooltip="Feature available with Standard or Pro subscription"
						/>
					)}
					{runAccess ? (
						<GButton
							label="Run"
							onClick={() => setOpen(!open)}
							color="primary"
							variant="text"
							size="xs"
							className="min-w-[45px]"
							disabled={demoMode}
							labelClassName="text-sm font-bold"
						/>
					) : (
						<GBadge
							text="Pro"
							color="purple"
							tooltip="Feature available with Pro subscription"
						/>
					)}
				</div>
			</GTooltip>
		)
	}

	return (
		<div className="flex flex-row gap-6 items-center">
			<div className="flex flex-col w-[390px]">
				<div className="text-t-default text-base font-bold">
					{content[type].title}
				</div>
				<div className="text-base text-t-secondary">
					{content[type].subTitle}
				</div>
			</div>
			<div>
				{type !== 'ENGAGEMENT' ? (
					<RenderActionButton />
				) : (
					<GBadge
						text={'Coming soon'}
						className="font-bold text-sm py-1 px-2"
					/>
				)}
				<RunReportsDialog
					open={open}
					onClose={() => setOpen(false)}
					type={type}
				/>
				<ScheduleReportsDialog
					type={type}
					open={openSchedule}
					onClose={() => setOpenSchedule(false)}
				/>
			</div>
		</div>
	)
}
