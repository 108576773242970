import {
	RiArrowDownSLine,
	RiBubbleChartLine,
	RiDonutChartLine,
	RiFolderChartLine,
	RiStackLine,
	RiLoader2Line,
	RiFolderShield2Line,
	RiForbid2Line
} from 'react-icons/ri'
import { useLocation } from 'react-router-dom'
import { GTransition } from 'components/basic-blocks'
import { NavItem } from 'layout/sidebar/nav-item'
import {
	useAccountStore,
	useAdminStore,
	useAssetStore,
	useUiStore
} from 'store'

import { Disclosure } from '@headlessui/react'

export const AssetMenu = () => {
	const account = useAccountStore((s) => s.account)
	const asset = useAssetStore((s) => s.asset)
	const demoMode = useAssetStore((s) => s.demoMode)
	const desktopSidebarCollapsed = useUiStore((s) => s.desktopSidebarCollapsed)

	const impersonation = useAdminStore((s) => s.impersonation)

	const accountId = demoMode && !account?.id ? 'workspace-demo' : account?.id
	const assetId = demoMode && !impersonation ? 'website-demo' : asset?.id

	const { pathname } = useLocation()

	return (
		<GTransition show={true} loader={!asset}>
			<div>
				<div>
					<Disclosure as="div" className="pt-4 pl-2" defaultOpen={true}>
						<Disclosure.Button
							className={`group flex w-full items-center justify-between hover:bg-gray-50 ${
								pathname === `/workspace/${accountId}/asset/${assetId}` &&
								'bg-gray-100 rounded-md'
							}`}
						>
							<span className="text-sm/6 font-medium text-white group-aria-[hover]:text-white/80">
								<NavItem
									label="Dashboard"
									to={() => `/workspace/${accountId}/asset/${assetId}`}
									icon={RiDonutChartLine}
								/>
							</span>
							{!desktopSidebarCollapsed && (
								<RiArrowDownSLine className="-ml-5 text-2xl fill-white/60 group-aria-[hover]:fill-white/50 group-aria-expanded:rotate-180 w-[25%]" />
							)}
						</Disclosure.Button>
						{!desktopSidebarCollapsed && (
							<Disclosure.Panel className=" text-sm/5 text-white/50 pl-5">
								<NavItem
									label="Analytics"
									to={() =>
										`/workspace/${accountId}/asset/${assetId}/analytics`
									}
								/>
								<NavItem
									label="Clicks"
									className="clicks-tab"
									to={() => `/workspace/${accountId}/asset/${assetId}/clicks`}
								/>
							</Disclosure.Panel>
						)}
					</Disclosure>
					{desktopSidebarCollapsed && (
						<div className="pl-2">
							<NavItem
								label="Analytics"
								to={() => `/workspace/${accountId}/asset/${assetId}/analytics`}
								icon={RiStackLine}
							/>
							<NavItem
								label="Clicks"
								className="clicks-tab"
								to={() => `/workspace/${accountId}/asset/${assetId}/clicks`}
								icon={RiLoader2Line}
							/>
						</div>
					)}
					<Disclosure as="div" className="pl-2" defaultOpen={true}>
						<Disclosure.Button
							className={`group flex w-full items-center justify-between hover:bg-gray-50 ${
								pathname ===
									`/workspace/${accountId}/asset/${assetId}/threat` &&
								'bg-gray-100 rounded-md'
							}`}
						>
							<NavItem
								className="threats-tab"
								label="Threat"
								to={() => `/workspace/${accountId}/asset/${assetId}/threat`}
								icon={RiBubbleChartLine}
							/>
							{!desktopSidebarCollapsed && (
								<RiArrowDownSLine className="text-2xl fill-white/60 group-aria-[hover]:fill-white/50 group-aria-expanded:rotate-180 w-[25%]" />
							)}
						</Disclosure.Button>

						{!desktopSidebarCollapsed && (
							<Disclosure.Panel className="text-sm/5 text-white/50 pl-5">
								<NavItem
									label="Exclusions"
									to={() =>
										`/workspace/${accountId}/asset/${assetId}/exclusions`
									}
								/>
								<NavItem
									label="Blacklist"
									to={() =>
										`/workspace/${accountId}/asset/${assetId}/blacklist`
									}
								/>
							</Disclosure.Panel>
						)}
					</Disclosure>
					{desktopSidebarCollapsed && (
						<div className="pl-2">
							<NavItem
								label="Exclusions"
								to={() => `/workspace/${accountId}/asset/${assetId}/exclusions`}
								icon={RiForbid2Line}
							/>
							<NavItem
								label="Blacklist"
								to={() => `/workspace/${accountId}/asset/${assetId}/blacklist`}
								icon={RiFolderShield2Line}
							/>
						</div>
					)}
					<div className="pl-2">
						<NavItem
							className="reports-tab"
							label="Reports"
							to={() => `/workspace/${accountId}/asset/${assetId}/reports`}
							icon={RiFolderChartLine}
						/>
					</div>
				</div>
			</div>
			<nav className="pt-4 space-y-4"></nav>
		</GTransition>
	)
}
