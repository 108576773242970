import { UserDTO } from 'api-models'
import { AdminToggleUserSuspend } from 'components/admin-suspend-user'
import { GButton, GConfirm, GSection, GTooltip } from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'
import { InfoItem, InfoItemGroup } from 'components/basic-blocks/g-info-table'
import { ManageUserTags } from 'components/manage-user-tags'
import { ReferencesInfoSection } from 'components/references-info-section'
import { useAdminStore, useUiStore } from 'store'
import { useShallow } from 'zustand/react/shallow'
import moment from 'moment-timezone'
import { useState } from 'react'
import {
	RiArrowDownSLine,
	RiArrowUpSLine,
	RiCheckLine,
	RiCloseLine,
	RiFileEditLine,
	RiGhostFill,
	RiLock2Line,
	RiLockUnlockLine,
	RiLoginCircleLine
} from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { formatCurrency } from 'utils'
import { PageTitle } from 'utils/page-title'
import { truncateString } from '../../../../../utils/text'

export const Info = (props: { user: UserDTO; hasActiveWorkspace: boolean }) => {
	const { user, hasActiveWorkspace } = props
	const navigate = useNavigate()

	const { setImpersonation, anonymizeUser } = useAdminStore(
		useShallow((state) => ({
			setImpersonation: state.setImpersonation,
			anonymizeUser: state.anonymizeUser
		}))
	)
	const isLoading = useUiStore((s) => s.isLoading)

	const [openAnonymizeConfirmationModal, setOpenAnonymizeConfirmationModel] =
		useState(false)

	const impersonate = async (user: UserDTO) => {
		try {
			await setImpersonation(user.id)
			navigate('/')
		} catch (err) {
			console.log(err)
		}
	}

	const handleAnonymizeUser = async () => {
		try {
			await anonymizeUser(user.id)
			setOpenAnonymizeConfirmationModel(false)
			window.location.reload()
		} catch (err) {
			return
		}
	}

	const total_paid =
		user.billing?.total_paid != undefined ? user.billing?.total_paid : 0

	return (
		<>
			<PageTitle pageName="User" />
			<GSection>
				<InfoItemGroup
					title="Basic information"
					className="-mt-5 border-t-0 rounded-t-lg"
				>
					<InfoItem label="User ID" className="overflow-clip">
						<div className="flex justify-between items-center gap-2">
							<code className="text-red-600">{user.id}</code>
							<div className="flex space-x-2 items-center justify-center">
								<GButton
									label="Impersonate"
									icon={RiLoginCircleLine}
									loading={isLoading('ADMIN_SET_IMPERSONATION')}
									color="primary"
									size="xs"
									onClick={() => impersonate(user)}
								/>
							</div>
						</div>
					</InfoItem>
					<InfoItem label="Full name" className="overflow-clip">
						<div className="flex justify-between items-center gap-2">
							{truncateString(user.name || '', 40)}
							{!user.name?.includes('cg-anonymous') && !hasActiveWorkspace ? (
								<GConfirm
									title={`Anonymize ${user.name}`}
									type="danger"
									description={`Are you sure you want to anonymize ${user?.name}?`}
									open={openAnonymizeConfirmationModal}
									setOpen={setOpenAnonymizeConfirmationModel}
									loading={isLoading('ADMIN_ANONYMIZE_USER')}
									onConfirm={handleAnonymizeUser}
								>
									<GButton
										label="Anonymize"
										variant="contained"
										color="danger"
										size="xs"
										icon={RiGhostFill}
										onClick={() => setOpenAnonymizeConfirmationModel(true)}
									/>
								</GConfirm>
							) : null}
						</div>
					</InfoItem>
					<InfoItem label="Email">{user.email}</InfoItem>
					<InfoItem label="Phone #">
						{user.phone_number || 'No phone number'}
					</InfoItem>
					<InfoItem label="Local time">
						{moment()
							.tz(user.timezone || '')
							?.format('h:mm A z (Z)') || 'Unknown'}
					</InfoItem>
					<InfoItem label="Last login">
						{moment(user.last_logged).format('llll')}
					</InfoItem>
					<InfoItem label="Subscribed">
						{total_paid > 0 ? (
							<div className="flex space-x-1">
								<RiCheckLine className="w-4 h-4 text-green-500" />
								<span>
									{user.subscribed_at
										? moment(user.subscribed_at).format('llll')
										: 'Never'}
								</span>
							</div>
						) : (
							'N/A'
						)}
					</InfoItem>
					<InfoItem label="Stripe profile">
						<div className="overflow-hidden break-words">
							{user.billing?.stripe_customer_id ? (
								<div className="text-primary-500 hover:text-primary-400 ">
									<a
										target="_blank"
										href={`https://dashboard.stripe.com/customers/${user.billing?.stripe_customer_id}`}
									>
										{user.billing?.stripe_customer_id}
									</a>
								</div>
							) : (
								<div> N/A </div>
							)}
						</div>
					</InfoItem>

					{!user.admin && (
						<InfoItem label="Suspended" className="overflow-clip">
							{user.suspended && (
								<div className="flex items-center justify-between gap-2">
									<div>
										<GTooltip
											content={
												<div className="flex flex-col">
													<div className="">
														{moment(user.suspended_date).format('LLLL')}
													</div>
													<div>{user.suspended_reason || 'Unknown reason'}</div>
												</div>
											}
										>
											<div className="flex space-x-1">
												<RiLock2Line className="text-red-500 w-4 h-4" />
												<div>Yes</div>
											</div>
										</GTooltip>
									</div>
									<div>
										<AdminToggleUserSuspend user={user}>
											{(open) => (
												<GButton
													label="Un-suspend"
													icon={RiLockUnlockLine}
													color="primary"
													variant="contained"
													onClick={() => open()}
													size="xs"
												/>
											)}
										</AdminToggleUserSuspend>
									</div>
								</div>
							)}
							{!user.suspended && (
								<div className="flex items-center justify-between gap-2">
									<div className="flex space-x-1">
										<RiCloseLine className="w-4 h-4 " />
										<div>No</div>
									</div>
									<div>
										<AdminToggleUserSuspend user={user}>
											{(open) => (
												<GButton
													label="Suspend"
													icon={RiLock2Line}
													color="danger"
													variant="contained"
													onClick={() => open()}
													size="xs"
												/>
											)}
										</AdminToggleUserSuspend>
									</div>
								</div>
							)}
						</InfoItem>
					)}

					<InfoItem label="Revenue">
						<div className="space-y-1">
							{total_paid > 0 ? (
								<>
									<div className="flex space-x-1">
										<span>Paid:</span>
										<span className="text-red-600">
											{formatCurrency(user.billing?.total_paid)}
										</span>
									</div>
									<div className="flex space-x-1">
										<span>Refunded:</span>
										<span>
											{(user.billing?.total_refunded || 0) > 0
												? formatCurrency(user.billing?.total_refunded)
												: 'None'}
										</span>
									</div>
								</>
							) : (
								<span>$0</span>
							)}
						</div>
					</InfoItem>
					<InfoItem label="Tags" className=" overflow-clip">
						<div className="flex justify-between items-start gap-4">
							<div>
								<ExpandableList length={user.tags.length} limit={2}>
									<div className="flex gap-2 flex-wrap">
										{user.tags.map((tag, index) => (
											<div key={index}>
												<div className="flex flex-col mb-3">
													<GBadge
														text={tag.tag_name || 'Unknown'}
														color="gray"
													/>
												</div>
											</div>
										))}
									</div>
								</ExpandableList>
							</div>
							<div className="flex justify-end flex-1">
								<ManageUserTags userId={user.id} tags={user.tags}>
									{(open) => (
										<GButton
											label="Edit"
											size="xs"
											color="primary"
											icon={RiFileEditLine}
											onClick={() => open()}
										/>
									)}
								</ManageUserTags>
							</div>
						</div>
					</InfoItem>
				</InfoItemGroup>

				<ReferencesInfoSection userId={user.id} />
			</GSection>
		</>
	)
}

const ExpandableList = (props: {
	children: any
	length?: number
	limit?: number
}) => {
	const { length, limit, children } = props
	const [expanded, setExpanded] = useState(false)
	return (
		<div className="flex justify-between items-start overflow-hidden">
			<div className={expanded ? 'h-fit' : 'h-7'}>{children}</div>
			{(length === undefined || limit === undefined || length > limit) && (
				<div className="flex items-center justify-center cursor-pointer px-1 hover:bg-slate-100 rounded-full h-fit ml-2">
					<div onClick={() => setExpanded(!expanded)}>
						{expanded ? (
							<RiArrowUpSLine className="text-t-secondary w-6 h-6 " />
						) : (
							<RiArrowDownSLine className="text-t-secondary w-6 h-6" />
						)}
					</div>
				</div>
			)}
		</div>
	)
}
