import { NoteDTO } from 'api-models/src'
import { UserAvatar } from 'components'
import { GButton, GLoading } from 'components/basic-blocks'
import { GDateTime } from 'components/basic-blocks/g-datetime'
import { InfiniteLoaderList } from 'components/infinite-loader-list/infinite-loader-list'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { RiErrorWarningLine } from 'react-icons/ri'
import { useUiStore } from 'store'
import { DeleteNote } from './delete-note'
import { EditNote } from './edit-notes'
import { HTMLRenderer } from './html-render'
import './notes-editor.css'
import { AddingCommentProps, NotesListProps } from './types'

export const Notes = ({
	topicId,
	type = 'website',
	total,
	setTotal
}: NotesListProps) => {
	const { adminStore } = useStores()
	const { getNotes, createNote } = adminStore

	const PAGE_SIZE = 6

	const [notes, setNotes] = useState<NoteDTO[]>([])
	const [newNote, setNewNote] = useState('')
	const [error, setError] = useState<string>('')

	useEffect(() => {
		;(async () => {
			await fetchData(0)
		})()
	}, [])

	const fetchData = async (skip: number) => {
		const { notes, total } = await getNotes(topicId, {
			type,
			skip: skip,
			limit: PAGE_SIZE
		})

		if (skip === 0) {
			setNotes(notes)
		} else {
			setNotes((prev) => [...prev, ...notes])
		}
		setTotal(total)
	}

	const handleAddComment = async () => {
		if (!newNote) {
			setError('Note cannot be empty')
			return
		}
		setError('')
		const payload = {
			topic_id: topicId,
			text: newNote
		}
		try {
			const createdNote = await createNote(payload)
			setNewNote('')
			setNotes((prev) => [createdNote, ...prev])
		} catch (e) {
			console.log(e)
		}
	}
	return (
		<>
			<main id="notes" className="py-10">
				<div className="max-w-3xl mx-auto px-4 sm:px-6 md:px-8">
					<section aria-labelledby="notes-title">
						<div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
							<div className="divide-y divide-gray-200">
								<div className="px-4 py-5 sm:px-6">
									<h2
										id="notes-title"
										className="text-lg font-medium text-gray-700"
									>
										Notes
									</h2>
								</div>
								<NotesPreview
									notes={notes}
									total={total}
									pageSize={PAGE_SIZE}
									fetchData={fetchData}
								/>
							</div>
							<AddingComment
								value={newNote}
								onChange={setNewNote}
								postNote={handleAddComment}
								error={error}
							/>
						</div>
					</section>
				</div>
			</main>
		</>
	)
}

const NotesPreview = (props: {
	notes: NoteDTO[]
	total: number
	pageSize: number
	fetchData: (skip: number) => Promise<void>
}) => {
	const { notes, total, pageSize, fetchData } = props
	const isLoading = useUiStore((s) => s.isLoading)

	if (isLoading('ADMIN_GET_NOTES') && notes.length === 0) return <GLoading />

	if (notes.length === 0)
		return (
			<div className="flex flex-col items-center justify-center w-full p-8 text-center">
				<RiErrorWarningLine className="w-8 h-8 text-gray-500" />
				<h3 className="mt-2 text-sm font-medium text-gray-700">
					No Notes yet!
				</h3>
				<p className="mt-1 text-sm text-gray-500">
					Your Notes will appear here
				</p>
			</div>
		)

	return (
		<>
			<ul role="list" className="px-4 pb-6 pt-2 sm:px-6 divide-y max-h-96">
				<InfiniteLoaderList
					list={notes}
					pageSize={pageSize}
					total={total}
					fetchData={fetchData}
				>
					{(index) => <NoteItem note={notes[index]} fetchData={fetchData} />}
				</InfiniteLoaderList>
			</ul>
		</>
	)
}

const NoteItem = (props: { note: NoteDTO; fetchData: (s: number) => void }) => {
	useState<boolean>(false)
	const { note, fetchData } = props

	if (!note) return null

	return (
		<li key={note.id}>
			<div className="flex space-x-3 py-4">
				<div>
					<div className="text-sm">
						<span className="font-medium text-gray-700">
							{note.user_info?.name}
						</span>
					</div>
					<div className="mt-1 text-sm text-gray-700 note-preview-main">
						<HTMLRenderer htmlString={note.text} />
					</div>
					<div className="mt-1 text-sm flex items-center gap-x-2">
						<span className="font-medium text-gray-500">
							<GDateTime date={note.timestamp} />
						</span>
						<EditNote note={note} fetchData={fetchData}>
							<span className="font-medium text-green-500 cursor-pointer">
								Edit
							</span>
						</EditNote>
						<DeleteNote note={note} fetchData={fetchData} />
					</div>
				</div>
			</div>
		</li>
	)
}

const AddingComment = (props: AddingCommentProps) => {
	const { value, onChange: handleChange, postNote, error } = props
	const { userStore } = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	const { user } = userStore

	return (
		<div className="bg-gray-50 px-4 py-6 sm:px-6">
			<div className="flex space-x-3">
				<UserAvatar user={user} />
				<div className="min-w-0 flex-1">
					<form>
						<div>
							<label htmlFor="comment" className="sr-only">
								About
							</label>
							<textarea
								id="comment"
								name="comment"
								value={value}
								onChange={(e) => handleChange(e.target.value)}
								rows={5}
								cols={42}
								className="block bg-t-input-background w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
								placeholder="Add a note"
								disabled={
									isLoading('ADMIN_GET_NOTES') || isLoading('ADMIN_ADD_NOTE')
								}
							/>
							{error && <p className="mt-1 text-xs text-red-700">{error}</p>}
						</div>
						<div className="mt-3 flex items-center justify-between">
							<GButton
								label="Comment"
								color="primary"
								onClick={() => postNote()}
								disabled={isLoading('ADMIN_ADD_NOTE')}
								loading={isLoading('ADMIN_ADD_NOTE')}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}
