import { Outlet } from 'react-router-dom'

import { useUserStore } from 'store'
import { UserSuspended } from './user-suspended-page'

export const AppBase = () => {
	const user = useUserStore((state) => state.user)

	if (user?.suspended) {
		return <UserSuspended user={user} />
	}

	return <Outlet />
}
