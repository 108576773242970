import axios from 'axios'
import { ApiConfig } from 'config'
import { lazy, Suspense, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { ZendeskService } from 'services/zendesk'
import { CFCState } from '../types'
import { CFCContext } from './context'
import { GLazyLoading } from '../../../components/basic-blocks'

const CFCView = lazy(() =>
	import('./view')
		.then((module) => {
			return { default: module.CFCView }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)

const client = axios.create({
	baseURL: ApiConfig.API_CFC_URL
})

export const ClickFraudCalculator = () => {
	useEffect(() => {
		ZendeskService.hide()
	}, [])

	const [state, setState] = useState<CFCState>({
		loading: false,
		step: 1,
		lead: null,
		errors: [],
		deliveryEmail: ''
	})

	const submitInformation = async (data: any) => {
		const payload = {
			id: state.lead?.id || null,
			domain: data.domain,
			sector: data.sector.value,
			industry: data.industry.value,
			spend: data.spend,
			platforms: data.platforms.map((i: any) => i.value)
		}

		try {
			setState({ ...state, loading: true })

			const response = await client.post('/cfc', payload)
			const lead = response.data.data.lead

			setState({ ...state, lead: lead, step: 2, loading: false })
		} catch (err: any) {
			console.trace(err)
			toast.error(err.message)
			setState({ ...state, loading: false })
		}
	}

	const submitAuthorization = async (data: any) => {
		if (!state.lead) {
			console.warn('Trying to authorize without created lead resource!')
			return toast.error('Unexpected error, try again.')
		}
		try {
			setState({ ...state, loading: true })

			const response = await client.post(`/cfc/${state.lead.id}/authorize`, {
				code: data
			})
			const lead = response.data.data.lead

			setState({ ...state, lead: lead, step: 3, loading: false })
		} catch (err: any) {
			console.trace(err)
			toast.error(err.message)
			setState({ ...state, loading: false })
		}
	}

	const submitAccount = async (data: any) => {
		if (!state.lead) {
			console.warn('Trying to authorize without created lead resource!')
			toast.error('Unexpected error, try again.')
			return
		}

		try {
			setState({ ...state, loading: true })

			const response = await client.post(`/cfc/${state.lead.id}/start`, {
				account_number: data.accountNumber,
				delivery_email: data.deliveryEmail
			})

			const message = response.data.data.message
			const lead = response.data.data.lead
			if (message === 'HALT') {
				setState((state) => ({ ...state, lead: lead, loading: false }))
			} else {
				setState((state) => ({
					...state,
					lead: lead,
					step: 4,
					deliveryEmail: data.deliveryEmail,
					loading: false
				}))
			}
		} catch (err: any) {
			console.trace(err)
			toast.error(err.message)
			setState({ ...state, loading: false })
		}
	}

	return (
		<CFCContext.Provider
			value={{
				state,
				setState,
				submitInformation,
				submitAuthorization,
				submitAccount
			}}
		>
			<Suspense fallback={<GLazyLoading />}>
				<CFCView />
			</Suspense>
		</CFCContext.Provider>
	)
}
