import { twMerge } from 'tailwind-merge'
import { GRateBadged } from '../g-rate-badge'
import { GMetricLineProps } from './types'

export const GMetricLine = ({
	bulletColor,
	title,
	titleClassName = 'text-md text-t-default',
	value,
	valueClassName = 'font-bold mr-1',
	rate,
	deepLink = ''
}: GMetricLineProps) => {
	return (
		<div className="flex justify-between mb-2">
			<div className="flex items-center">
				{bulletColor ? (
					<div
						className={`h-3 w-3 rounded-full mr-1 ${bulletColor}`}
						data-testid="bullet-color"
					/>
				) : null}
				<span className={titleClassName}>{title}</span>
			</div>
			<div className="flex items-center">
				{deepLink ? (
					<a
						href={deepLink}
						className={twMerge(valueClassName, 'text-primary-500')}
					>
						{value}
					</a>
				) : (
					<span className={valueClassName}>{value}</span>
				)}
				{rate ? <GRateBadged rate={rate} /> : null}
			</div>
		</div>
	)
}
