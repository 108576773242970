export const LegacyUserStatusOptions = [
	'signed_ip',
	'special',
	'cancelled',
	'active'
]

export const getLegacyFilterErrors = (
	payload: Record<string, any>,
	filters: Record<string, any>
) => {
	const errors: any = {}
	Object.keys(filters).forEach((filterKey) => {
		const payloadKeys = Object.keys(payload).filter((payloadKey) =>
			payloadKey.startsWith(filterKey.substring(0, 5))
		)
		payloadKeys.forEach((payloadKey) => {
			if (filters[filterKey] && !payload[payloadKey]) {
				errors[payloadKey] = 'Required Field'
			}
		})
	})
	return { errors, isValid: Object.keys(errors).length === 0 }
}

export interface LegacyUserDTO {
	id: string
	access_type:
		| 'admin'
		| 'standard'
		| 'support'
		| 'affiliate'
		| 'manager'
		| 'viewer'
	first_name: string
	last_name: string
	email: string
	phone_number: string
	status: 'signed_ip' | 'special' | 'cancelled' | 'active'
	timezone: string
	currency: string
	created_at: Date
	billing_subscription_price: number | null
	billing_subscription_periods: number
}

export const getLegacyStatusColor = (
	status: 'signed_ip' | 'special' | 'cancelled' | 'active'
) => {
	switch (status) {
		case 'signed_ip':
			return 'bg-yellow-100 text-yellow-700'
		case 'special':
			return 'bg-blue-100 text-blue-700'
		case 'cancelled':
			return 'bg-red-100 text-red-700'
		case 'active':
			return 'bg-green-100 text-green-700'
		default:
			return ''
	}
}
