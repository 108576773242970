import {
	AdAccountDTO,
	FacebookAdsCampaignDTO,
	GoogleAdsCampaignDTO
} from 'api-models'
import { GDateRange, GLazyLoading } from 'components/basic-blocks'
import { Notices } from 'components/notice/notices'
import { PageHeader } from 'components/page-header'
import { useStores } from 'hooks'
import { Breadcrumbs } from 'layout/navbar/breadcrumbs/breadcrumbs'
import moment from 'moment'
import { lazy, Suspense, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getEntityName } from 'utils/get-entity-name'
import { PageTitle } from 'utils/page-title'
import { useAsset } from '../asset-base'
import { useUiStore } from 'store'

const ThreatPageView = lazy(() =>
	import('./view')
		.then((module) => {
			return { default: module.ThreatPageView }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)

export const ThreatPage = () => {
	const { accountStore, assetStore } = useStores()
	const isLoading = useUiStore((s) => s.isLoading)
	const { accountId, assetId } = useAsset()
	const { asset, adAccounts, campaigns, demoMode } = assetStore
	const { isInSetup, account } = accountStore

	const [searchParams, setSearchParams] = useSearchParams()
	const campaignId = searchParams.get('breadCampaign')
	const [selectedAdAccount, setSelectedAdAccount] =
		useState<AdAccountDTO | null>(null)
	const [selectedCampaign, setSelectedCampaign] = useState<
		GoogleAdsCampaignDTO | FacebookAdsCampaignDTO | null
	>(null)
	const [dateRange, setDateRange] = useState({
		from: moment().subtract(30, 'days').startOf('day').toDate(),
		to: moment().startOf('hour').toDate()
	})

	const [dateLoading, setDateLoadingState] = useState(false)

	useEffect(() => {
		const preSelectedAdAccountId = searchParams.get('adAccount')
		if (preSelectedAdAccountId) {
			const adAccountSelected =
				adAccounts.find((adAccount) => {
					return adAccount.id === preSelectedAdAccountId
				}) || null
			setSelectedAdAccount(adAccountSelected || null)
		}
	}, [adAccounts.length])

	useEffect(() => {
		const preSelectedCampaignId = searchParams.get('campaign')
		if (preSelectedCampaignId) {
			const campaignSelected =
				campaigns.find((campaign) => {
					return campaign.id === preSelectedCampaignId
				}) || null
			setSelectedCampaign(campaignSelected)
		}
		if (campaignId && campaignId !== preSelectedCampaignId) {
			const campaignSelected =
				campaigns.find((campaign) => {
					return campaign.id === campaignId
				}) || null
			setSelectedCampaign(campaignSelected)
		}
	}, [campaigns.length, campaignId])

	useEffect(() => {
		if (selectedAdAccount === null) {
			searchParams.delete('adAccount')
			setSearchParams(searchParams)
			return
		}
		searchParams.set('adAccount', selectedAdAccount.id)
		setSearchParams(searchParams)
	}, [selectedAdAccount?.id])

	useEffect(() => {
		if (campaigns.length === 0) return

		if (selectedCampaign === null) {
			searchParams.delete('campaign')
			setSearchParams(searchParams)
			return
		}

		searchParams.set('campaign', selectedCampaign.id)
		setSearchParams(searchParams)
	}, [campaigns.length, selectedCampaign?.id])

	const handleDateRangeChange = (e: { from: Date; to: Date }) => {
		setDateRange({ from: e.from, to: e.to })
		setDateLoadingState(true)
		setTimeout(() => setDateLoadingState(false), 1500)
	}
	const shouldTheComponentLoad =
		isLoading('ASSET_GET_ACCOUNTS') ||
		isLoading('ACCOUNT_GET_ASSET') ||
		dateLoading
	const pageActions = (
		<div className="w-full pr-8 flex items-center justify-between pb-4">
			<Breadcrumbs
				asset={asset}
				selectedAdAccount={selectedAdAccount}
				setSelectedAdAccount={setSelectedAdAccount}
				showCampaignSelector={true}
				demoMode={demoMode}
			/>
			<GDateRange
				onApply={(e) => handleDateRangeChange({ from: e.from, to: e.to })}
				align="right"
				loading={shouldTheComponentLoad}
				demoMode={demoMode}
			/>
		</div>
	)

	return (
		<>
			<Notices
				isInSetup={isInSetup()}
				setupStep={account?.setup?.step}
				isInActive={account?.status === 'INACTIVE'}
				hasDashboardPerformanceFeature={accountStore.hasFeature(
					'dashboard_performance'
				)}
				tc_installed={!!asset?.tc_installed}
				cc_installed={!!asset?.cc_installed}
				accountId={accountId}
				assetId={assetId}
				demoMode={demoMode}
				mccLinkInviteAdAccounts={adAccounts}
			/>
			<PageTitle pageName="Threat" entityName={asset?.name} />
			<PageHeader
				title={'Threat'}
				tagline="Insight and analysis about threat and fraudulent activities for"
				tags={[
					{
						label: getEntityName({
							selectedCampaign: null,
							adAccount: selectedAdAccount,
							asset,
							demoMode
						})
					}
				]}
				action={pageActions}
			/>

			<div className="max-w-full mx-auto px-6 sm:px-8">
				<Suspense fallback={<GLazyLoading />}>
					<ThreatPageView
						accountId={accountId}
						assetId={assetId}
						from={dateRange.from}
						to={dateRange.to}
						adAccountId={selectedAdAccount?.id}
						platform={selectedAdAccount?.platform}
						campaignId={selectedCampaign?.id}
					/>
				</Suspense>
			</div>
		</>
	)
}
