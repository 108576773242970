import { ProductAccountDTO } from 'api-models'
import { GTableColumn } from 'components/basic-blocks/g-table/types'
import moment from 'moment'
import { formatSpend } from 'utils'
import { GDateTime } from 'components/basic-blocks/g-datetime'
import { useNavigate } from 'react-router-dom'
import { PlanNames } from 'config'
import { planTierIconMapper, RiCloseLine, RiLoginCircleLine } from 'utils/icons'
import { useEffect, useState } from 'react'
import { GButton } from 'components/basic-blocks'
import { UserAvatar } from 'components'
import { useStores } from 'hooks'
import { GBadge } from 'components/basic-blocks/g-badge'

export const columns: Array<GTableColumn<ProductAccountDTO>> = [
	{
		label: 'Workspace',
		path: 'workspace',
		render: (row: ProductAccountDTO) => <WorkspaceColumn row={row} />,
		className: 'justify-start'
	},
	{
		label: 'Owner',
		path: 'owner',
		render: (row: ProductAccountDTO) => <OwnerColumn row={row} />,
		className: 'justify-start'
	},
	{
		label: 'Plan spend limit',
		path: 'planSpendLimit',
		render: (row: ProductAccountDTO) => <PlanSpendLimitColumn row={row} />,
		className: 'justify-start'
	},
	{
		label: 'Current Spend',
		path: 'spendPrice',
		render: (row: ProductAccountDTO) => <CurrentSpendColumn row={row} />,
		className: 'justify-start'
	},
	{
		label: 'Breached at',
		path: 'breachedAt',
		render: (row: ProductAccountDTO) => <BreachedAt row={row} />,
		sortKey: 'breached_at',
		className: 'justify-start'
	}
]

const WorkspaceColumn = (props: { row: ProductAccountDTO }) => {
	const { row } = props

	const navigate = useNavigate()

	const link = `/admin/subscription/${props.row.id}`
	const planName = PlanNames[props.row?.billing?.subscription?.plan.tier || '']
	const planIcon = planTierIconMapper(
		props.row.billing?.subscription?.plan.tier,
		6
	)

	return (
		<div className="flex justify-center items-center space-x-4">
			<>
				{planIcon}
				<div className="flex flex-col justify-center">
					<div
						className="font-medium text-primary-500 hover:text-primary-600 cursor-pointer"
						onClick={() => navigate(link)}
					>
						{row.name}
					</div>
					<div className="text-xs text-gray-500">{planName || ''}</div>
				</div>
			</>
		</div>
	)
}

const OwnerColumn = (props: { row: ProductAccountDTO }) => {
	const { row } = props
	const navigate = useNavigate()
	const { adminStore } = useStores()
	const [loading, setLoading] = useState(false)

	const link = `/admin/user/${row.user_info?.user_id}`
	const name = row.user_info?.name
	const email = row.user_info?.email

	const impersonate = async () => {
		setLoading(true)
		try {
			await adminStore.setImpersonation(row.user_info?.user_id || '')
			setLoading(false)
			navigate('/')
		} catch (e) {
			setLoading(false)
		}
	}

	useEffect(() => {
		return () => {
			setLoading(false)
		}
	}, [])

	return (
		<div className="flex space-x-2 items-center">
			<div className="flex items-center mr-2">
				<GButton
					shape="square"
					type="icon"
					loading={loading}
					icon={RiLoginCircleLine}
					variant="contained"
					color="primary"
					size="xs"
					onClick={impersonate}
				>
					Impersonate
				</GButton>
			</div>
			<div className="flex space-x-2 items-center">
				<UserAvatar size={8} src={row.user_info?.avatar} />
				<div
					className="flex flex-col group cursor-pointer"
					onClick={() => navigate(link)}
				>
					<div className="font-semibold text-primary-500 group-hover:text-primary-600">
						{name}
					</div>
					<div className="text-gray-500">{email}</div>
				</div>
			</div>
		</div>
	)
}

const PlanSpendLimitColumn = (props: { row: ProductAccountDTO }) => {
	const { row } = props
	const plan = row.billing?.subscription?.plan

	return (
		<>
			{plan?.spend ? (
				<GBadge text={<span>{formatSpend(plan?.spend)}</span>} color="purple" />
			) : (
				<RiCloseLine className="h-5 w-5 mr-2 text-t-secondary" />
			)}
		</>
	)
}

const CurrentSpendColumn = (props: { row: ProductAccountDTO }) => {
	const { row } = props
	const plan = row

	return (
		<>
			{row.limit_breached ? (
				<GBadge
					text={<span>{formatSpend(plan?.reported_spend)}</span>}
					color="red"
				/>
			) : (
				<GBadge
					text={<span>{formatSpend(plan?.reported_spend)}</span>}
					color="amber"
				/>
			)}
		</>
	)
}

const BreachedAt = (props: { row: ProductAccountDTO }) => {
	const { row } = props

	return (
		<>
			{row.limit_breached_at ? (
				<div className="flex flex-col gap-1">
					<div className="text-xs text-gray-500">
						{moment(row.limit_breached_at).format('MMMM Do, YYYY - hh:mm:ss A')}
					</div>
					<div className="font-medium text-gray-400">
						<GDateTime date={row.limit_breached_at} />
					</div>
				</div>
			) : (
				<RiCloseLine className="h-5 w-5 mr-2 text-t-secondary" />
			)}
		</>
	)
}
