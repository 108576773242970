import { GSwitcher, GTooltip } from 'components/basic-blocks/'
import { classNames } from 'utils'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useMemo, useState } from 'react'
import { ChangeAccessConfirmation } from './change-access-confirmation'
import { useUserStore } from '../../../store'
import {
	AdminRoleOptions,
	getAdminRoleByAccess,
	getAdminRoleOptions,
	IAdminRole
} from './types'
import { AdminAccessTypes, hasAdminAccess } from '../../../utils/admin'
import { DeleteAdminUser } from './delete-admin-user'

export const AccessSelector = ({ user }: { user: any }) => {
	const currentUser = useUserStore((s) => s.user)
	const [open, setOpen] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)
	const [role, setRole] = useState(
		AdminRoleOptions.find((o) => o.value === user?.admin?.access_level) ||
			AdminRoleOptions[2]
	)
	const isDisabled = useMemo(() => {
		const currentRole = AdminRoleOptions.find(
			(e) => e.value === currentUser?.admin?.access_level
		)
		const role = AdminRoleOptions.find(
			(e) => e.value === user?.admin?.access_level
		)
		if (!currentRole || !role) return true
		return currentRole.id <= role.id
	}, [currentUser, user?.admin?.access_level])

	if (!user?.admin && user?.access_level)
		return (
			<div className="flex items-center gap-2">
				<p className="text-base font-bold pt-1">
					User Invited with{' '}
					<span className="text-primary-500">
						{getAdminRoleByAccess(user?.access_level)?.name}
					</span>
				</p>
			</div>
		)

	return (
		<>
			<ChangeAccessConfirmation
				open={open}
				setOpen={setOpen}
				role={role}
				user={user}
			/>
			<DeleteAdminUser open={openDelete} setOpen={setOpenDelete} user={user} />
			<div
				className={classNames(
					'asset-switcher hidden md:flex items-center w-full min-w-[300px]',
					isDisabled ? 'opacity-50 pointer-events-none' : ''
				)}
			>
				<GSwitcher<IAdminRole>
					items={getAdminRoleOptions(currentUser)}
					selectedItem={<SelectedItem />}
					renderOption={(i, handleClose) => renderOption(i, handleClose)}
					footer={<SwitcherContentFooter />}
					placeholder="Access roles"
					disableSearch={true}
					header={<SwitcherContentHeader />}
					position="bottom-start"
					offset={[-30, 0]}
				/>
			</div>
		</>
	)

	function renderOption(option: IAdminRole, handleClose: () => void) {
		return (
			<div
				key={option.value}
				onClick={() => {
					if (option.value === user?.admin?.access_level) return
					setRole(option)
					setOpen(true)
					handleClose()
				}}
				className={classNames(
					'text-t-heading text-base font-bold w-[300px] hover:bg-gray-50  group flex items-center py-3 px-4 space-x-3 cursor-pointer',
					`${option.value === user?.admin?.access_level && 'bg-gray-100'}`
				)}
			>
				{option.name}
			</div>
		)
	}

	function SelectedItem() {
		return (
			<div className="w-full border px-3 py-2.5 rounded-lg justify-between flex items-center text-base hover:bg-gray-50 max-w-full cursor-pointer">
				{
					AdminRoleOptions.find((o) => o.value === user?.admin?.access_level)
						?.name
				}
				<div>
					<MdKeyboardArrowDown className="h-4 w-4 text-gray-400" />
				</div>
			</div>
		)
	}

	function SwitcherContentFooter() {
		return (
			<div className="flex items-center justify-center p-2 w-full ">
				<div className="w-full bg-gray-50 flex items-center justify-center">
					<GTooltip
						content={
							!hasAdminAccess(currentUser, AdminAccessTypes.delete_staff)
								? 'You dont have permission to delete staff'
								: ''
						}
					>
						<button
							className="text-red-800 text-lg font-bold py-2 rounded-md"
							color="danger"
							disabled={
								!hasAdminAccess(currentUser, AdminAccessTypes.delete_staff)
							}
							onClick={() => setOpenDelete(true)}
						>
							Delete User
						</button>
					</GTooltip>
				</div>
			</div>
		)
	}

	function SwitcherContentHeader() {
		return (
			<p className="w-full px-5 pt-4 text-sm text-t-secondary">Manage Access</p>
		)
	}
}
