import Joi from 'joi'

export const addPPCAccountSchema = Joi.object({
	selectedLink: Joi.string().min(3).required().label('link'),
	accountNumber: Joi.string().min(3).required().label('account number')
})

export const addBingPPCAccountSchema = Joi.object({
	selectedLink: Joi.string().required().label('link'),
	accountNumber: Joi.string().required().label('account number')
})
