import { GButton, GDialog, GInput } from 'components/basic-blocks'
import { useStores } from 'hooks'
import { useState } from 'react'
import { useUiStore } from 'store'
import { AdminSuspendUserProps } from './types'

export const AdminToggleUserSuspend = (props: AdminSuspendUserProps) => {
	const { children, user } = props
	const { adminStore } = useStores()
	const { suspendUser, unSuspendUser } = adminStore
	const [state, setState] = useState({
		suspendReasonValue: ''
	})
	const isLoading = useUiStore((s) => s.isLoading)

	const [dialogOpen, setDialogOpen] = useState(false)

	const handleSuspendUser = async () => {
		try {
			await suspendUser(user.id, {
				suspended_reason: state.suspendReasonValue
			})
			setDialogOpen(false)
		} catch (e) {
			return
		}
	}
	const handleUnSuspendUser = async () => {
		try {
			await unSuspendUser(user.id)
			setDialogOpen(false)
		} catch (e) {
			return
		}
	}
	return (
		<>
			{children && children(() => setDialogOpen(true))}

			{!user.suspended && (
				<GDialog
					open={dialogOpen}
					onClose={() => setDialogOpen(false)}
					title={`Suspend ${user.name}?`}
					showClose={false}
				>
					<GInput
						label="Reason for suspending"
						value={state.suspendReasonValue}
						onChange={(e) =>
							setState({ ...state, suspendReasonValue: e.target.value })
						}
						className="mt-6"
					/>

					<div className="mt-5 flex justify-end gap-4">
						<GButton onClick={() => setDialogOpen(false)}>Cancel</GButton>
						<GButton
							label="Suspend"
							color="danger"
							loading={isLoading('ADMIN_SUSPEND_USER')}
							onClick={() => handleSuspendUser()}
						/>
					</div>
				</GDialog>
			)}

			{user.suspended && (
				<GDialog
					open={dialogOpen}
					onClose={() => setDialogOpen(false)}
					title={`Un-suspend ${user.name}?`}
					subtitle={`Are you sure you want to un-suspend ${user.name}`}
					showClose={false}
				>
					<div className="mt-5 flex justify-end gap-4">
						<GButton onClick={() => setDialogOpen(false)}>Cancel</GButton>
						<GButton
							label="Un-suspend"
							color="success"
							loading={isLoading('ADMIN_SUSPEND_USER')}
							onClick={() => handleUnSuspendUser()}
						/>
					</div>
				</GDialog>
			)}
		</>
	)
}
