import {
	BingAdsAdAccountDTO,
	BingAdsLinkInfoDTO,
	FacebookAdsAdAccountDTO,
	FacebookAdsLinkInfoDTO,
	GoogleAdsAdAccountDTO,
	GoogleAdsLinkInfoDTO
} from 'api-models'
import { useMemo, useState } from 'react'
import { useAccountStore } from 'store'
import { AdPlatformTypesDTO, AdPlatforms } from 'utils/platform'
import { BingSettings } from './bing-settings'
import { FacebookSettings } from './facebook-settings'
import { GoogleSettings } from './google-settings'

interface IAdsAccountSetting {
	children: (open: () => void) => void
	adAccount: AdPlatformTypesDTO
	platform: AdPlatforms
}

export const AdsAccountSetting = (props: IAdsAccountSetting) => {
	const { children, adAccount, platform } = props

	const accountLinks = useAccountStore((s) => s.accountLinks)

	const [dialogOpen, setDialogOpen] = useState(false)

	const accountAccessLinks = useMemo(() => {
		return accountLinks.filter((link) => link.platform === platform)
	}, [accountLinks])

	return (
		<>
			{children && children(() => setDialogOpen(true))}
			{platform === 'GADS' && (
				<GoogleSettings
					adAccount={adAccount as GoogleAdsAdAccountDTO}
					links={accountAccessLinks as unknown as GoogleAdsLinkInfoDTO[]}
					dialogOpen={dialogOpen}
					setDialogOpen={setDialogOpen}
				/>
			)}
			{platform === 'FADS' && (
				<FacebookSettings
					adAccount={adAccount as FacebookAdsAdAccountDTO}
					links={accountAccessLinks as unknown as FacebookAdsLinkInfoDTO[]}
					dialogOpen={dialogOpen}
					setDialogOpen={setDialogOpen}
				/>
			)}
			{platform === 'BADS' && (
				<BingSettings
					adAccount={adAccount as BingAdsAdAccountDTO}
					links={accountAccessLinks as unknown as BingAdsLinkInfoDTO[]}
					dialogOpen={dialogOpen}
					setDialogOpen={setDialogOpen}
				/>
			)}
		</>
	)
}
