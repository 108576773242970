import { Notices } from 'components/notice/notices'
import { PageHeader } from 'components/page-header'
import { useAccountStore, useAssetStore, useUiStore } from 'store'
import { sanitizeURL } from 'utils'
import { GLazyLoading, GLoading } from '../../../../components/basic-blocks'
import { useStores } from '../../../../hooks'
import { PageTitle } from '../../../../utils/page-title'
import { useShallow } from 'zustand/react/shallow'
import { lazy, Suspense } from 'react'
import { DeleteAccount } from '../../settings/delete-account'

const PreferencesFormSection = lazy(() =>
	import('./preferences-form-section')
		.then((module) => {
			return { default: module.PreferencesFormSection }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)
const PreferencesContactSection = lazy(() =>
	import('./preferences-contact-section')
		.then((module) => {
			return { default: module.PreferencesContactSection }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)
export const PreferencesSettingsPage = () => {
	const { accountStore } = useStores()
	const { asset, adAccounts } = useAssetStore(
		useShallow((s) => ({
			asset: s.asset,
			adAccounts: s.adAccounts
		}))
	)
	const isLoading = useUiStore((s) => s.isLoading)
	const { account, isInSetup, wantsToSeeDemoData } = useAccountStore(
		useShallow((s) => {
			return {
				account: s.account,
				isInSetup: s.isInSetup,
				wantsToSeeDemoData: s.wantsToSeeDemoData
			}
		})
	)
	return (
		<div className="w-full">
			<PageTitle pageName="Workspace preferences" />
			<Notices
				isInSetup={isInSetup()}
				setupStep={account?.setup?.step}
				isInActive={account?.status === 'INACTIVE'}
				hasDashboardPerformanceFeature={accountStore.hasFeature(
					'dashboard_performance'
				)}
				tc_installed={asset ? !!asset?.tc_installed : true}
				cc_installed={asset ? !!asset?.cc_installed : true}
				accountId={account?.id}
				assetId={asset?.id}
				demoMode={wantsToSeeDemoData}
				mccLinkInviteAdAccounts={adAccounts}
			/>
			<PageHeader
				title={'Workspace preferences'}
				subtitle={
					<div className="flex items-center">
						Configure basic preferences and contact details for&nbsp;
						<span className="font-bold max-w-[200px] truncate block">
							{sanitizeURL(account?.name)}
						</span>
					</div>
				}
			/>
			{isLoading('ACCOUNT_SAVE_SETTINGS') ? (
				<div className="absolute top-0 bottom-0 left-0 right-0 bg-white opacity-90 z-100">
					<GLoading />
				</div>
			) : (
				<div className="max-w-3xl px-4 sm:px-6 md:px-8">
					<>
						<Suspense fallback={<GLazyLoading />}>
							<PreferencesFormSection />
						</Suspense>
						<Suspense fallback={<GLazyLoading />}>
							<PreferencesContactSection />
						</Suspense>
						<DeleteAccount />
					</>
				</div>
			)}
		</div>
	)
}
