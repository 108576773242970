"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProtectionInfoDetectionType = exports.RegionMode = void 0;
var RegionMode;
(function (RegionMode) {
    RegionMode["ALLOW"] = "ALLOW";
    RegionMode["RESTRICT"] = "RESTRICT";
})(RegionMode = exports.RegionMode || (exports.RegionMode = {}));
var ProtectionInfoDetectionType;
(function (ProtectionInfoDetectionType) {
    ProtectionInfoDetectionType["AI"] = "AI";
    ProtectionInfoDetectionType["CUSTOM_TUNING"] = "CUSTOM_TUNING";
})(ProtectionInfoDetectionType = exports.ProtectionInfoDetectionType || (exports.ProtectionInfoDetectionType = {}));
