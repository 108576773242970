import { create } from 'zustand'

import { Notifications } from 'services/user/notifications'
import { useUiStore } from 'store/ui-store'
import { parseError } from 'utils/errors'

interface GeneralStoreState {
	notifications: any[]
	getNotifications: (query?: Record<string, string>) => Promise<any>
	readNotifications: (id: string) => Promise<any>
	readAllNotifications: () => Promise<any>
}

export const useGeneralStore = create<GeneralStoreState>(() => ({
	notifications: [],
	getNotifications: async (query?: Record<string, string>) => {
		useUiStore.getState().setActionState('GET_NOTIFICATIONS', 'loading')
		try {
			const notifications = await Notifications.find(query)
			useUiStore.getState().setActionState('GET_NOTIFICATIONS', 'success')
			return notifications.sort((a, b) => {
				const c = new Date(a.created_at)
				const d = new Date(b.created_at)
				return d.getTime() - c.getTime()
			})
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'GET_NOTIFICATIONS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error loading notifications'
				)
		}
	},
	readNotifications: async (id: string) => {
		useUiStore.getState().setActionState('READ_NOTIFICATION', 'loading')
		try {
			const notifications = await Notifications.read(id)
			useUiStore.getState().setActionState('READ_NOTIFICATION', 'success')
			return notifications
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'READ_NOTIFICATION',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error marking notification as read'
				)
		}
	},
	readAllNotifications: async () => {
		useUiStore.getState().setActionState('READ_NOTIFICATIONS', 'loading')
		try {
			const notifications = await Notifications.readAll()
			useUiStore.getState().setActionState('READ_NOTIFICATIONS', 'success')
			return notifications
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'READ_NOTIFICATIONS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error marking all notifications as read'
				)
		}
	}
}))
