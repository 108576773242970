import { UsersPageFiltersContextProvider } from './context'
import { lazy, Suspense } from 'react'
import { GLazyLoading } from '../../../../components/basic-blocks'

const AdminUsersPageView = lazy(() =>
	import('./view')
		.then((module) => {
			return { default: module.AdminUsersPageView }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)

export const AdminUsersPage = () => {
	return (
		<UsersPageFiltersContextProvider>
			<Suspense fallback={<GLazyLoading />}>
				<AdminUsersPageView />
			</Suspense>
		</UsersPageFiltersContextProvider>
	)
}
