import {
	AccountLinkDTO,
	BillingAccountInfoDTO,
	GoogleOAuthAccountLinkDTO,
	InvoiceDTO,
	OrganizationDTO,
	ProductAccountDTO,
	SubscriptionDTO,
	UserAccessInfoDTO,
	UserAccessInviteDTO
} from 'api-models'
import { NavigateFunction } from 'react-router-dom'
import { create } from 'zustand'

import { features } from 'config/features'
import { AdAccountLinks, Invoices, ProductAccounts } from 'services/user'
import { useAssetStore } from 'store/asset-store'
import { useUiStore } from 'store/ui-store'
import { getPermissionTier } from 'utils'
import { parseError } from 'utils/errors'
import { GtmService } from '../../services/user/gtm'
import {
	AccountContactOrganizationDto,
	AccountPreferencesOrganizationDto,
	CancellationRequestInfo
} from './type'

interface AccountStoreState {
	account: ProductAccountDTO | null
	accounts: ProductAccountDTO[]
	accountUsersWithAccess: UserAccessInfoDTO[]
	accountInvitedUsers: UserAccessInviteDTO[]
	subscription: SubscriptionDTO | null
	subscriptionLoading: boolean
	gtmLinks: GoogleOAuthAccountLinkDTO[]
	invoices: Array<InvoiceDTO>
	invoice: InvoiceDTO | null
	wantsToSeeDemoData: boolean
	// Account Links Action Methods
	accountLinks: AccountLinkDTO[]

	// Account Action Methods
	setAccounts(accounts: ProductAccountDTO[]): void

	setGtmLinks(links: GoogleOAuthAccountLinkDTO[]): void

	clearAccount(): void

	clearAccounts(): void

	setAccount(account: ProductAccountDTO | null): void

	setSubscriptionLoading(loading: boolean): void

	setSubscription(subscription: SubscriptionDTO | null): void

	setWantsToSeeDemoData(value?: boolean): void

	getAllAccounts(): Promise<ProductAccountDTO[] | undefined>

	isInSetup(): boolean

	tier(): string | null

	hasFeature(feature: string): boolean

	canAddTeamMember(): boolean

	createAccount(
		payload: {
			tier: string
			price_id: string
			pm_id: string
			auto_upgrade: boolean
			offer_id: null
			org_data: {
				name: string
				billing_contact: {
					name: string
					email: string
				}
			}
		},
		onSuccess?: any
	): Promise<ProductAccountDTO | undefined>

	deleteAccount(
		accountId: string,
		onSuccess?: any
	): Promise<ProductAccountDTO[] | undefined>

	createAccountWithOffer(
		offerId: string,
		pmId: string,
		org: any
	): Promise<ProductAccountDTO | undefined>

	setAccountForId(id: string, onFail?: NavigateFunction): Promise<void>

	addCoupon(
		accountId: string,
		payload: { coupon_code: string }
	): Promise<ProductAccountDTO[]>

	// Account User Action Methods
	getAccountUsers(accountId: string): Promise<void | undefined>

	setUsersWithAccess(users: UserAccessInfoDTO[]): void

	userAccess(userId?: string): string | undefined

	userAccessLevel(userId?: string): string | undefined

	isOwnerAccess(userId?: string): boolean | undefined

	inviteAccountUser(
		accountId: string,
		payload: {
			email: string
			access_level: string
		}
	): Promise<void | undefined>

	setInvitedUsers(users: UserAccessInviteDTO[]): void

	revokeUserInvitation(accountId: string, inviteId: string): Promise<void>

	updateAccountUser(
		accountId: string,
		userId: string,
		payload: {
			access_level: string
		}
	): Promise<void>

	deleteAccountUser(accountId: string, userId: string): Promise<void>

	// Account Organization Action Methods
	updateAccountOrganization(
		accountId: string,
		payload: OrganizationDTO
	): Promise<void>

	updateAccountPreferencesOrganization(
		accountId: string,
		payload: AccountPreferencesOrganizationDto
	): Promise<void>

	updateAccountContactOrganization(
		accountId: string,
		payload: AccountContactOrganizationDto
	): Promise<void>

	updateAccountBillingInfo(
		payload: Partial<BillingAccountInfoDTO>
	): Promise<void>

	editAccountPaymentMethod(
		accountId: string,
		payload: { pm_id: string }
	): Promise<void>

	upgradeAddLimit(
		accountId: string,
		payload: { enabled: boolean }
	): Promise<void>

	setInvoices(invoices: Array<InvoiceDTO>): void

	setInvoice(invoice: InvoiceDTO): void

	loadInvoices(): Promise<void>

	downloadInvoice(account_id: string, invoice_id: string): Promise<void>

	// Account Subscription Action Methods
	startPendingSubscription(
		accountId: string,
		payload: { note: string; pm_id?: string | null }
	): Promise<void>

	revertChange(accountId: string, payload: { note: string }): Promise<void>

	newSubscription(
		accountId: string,
		payload: {
			tier: string
			price_id: string
			coupon_code: string | null
			pm_id: string | null
		}
	): Promise<void>

	getSubscriptionByAccountId(accountId: string): Promise<void>

	changeSubscription(
		accountId: string,
		payload: {
			tier: string
			price_id: string
			coupon_code: string | null
			pm_id: string | null
		}
	): Promise<any>

	cancelRequest(
		accountId: string,
		payload: CancellationRequestInfo
	): Promise<void>

	removeCancelRequest(): Promise<void>

	setAccountLinks(links: AccountLinkDTO[]): void

	refreshAccountLinks(): Promise<AccountLinkDTO[] | undefined>

	syncAccountLink(id: string): Promise<void>

	deleteAccountLink(linkId: string, deactivatePPC?: boolean): Promise<void>

	createOAuthLink(
		code: string,
		provider: 'google' | 'facebook' | 'bing',
		accountId?: string | null
	): Promise<void>

	createMCCLink(parentId: string, accNum?: string): Promise<void>

	getGtmLinks(accountId: string): Promise<void>

	createGtmAuth(accountId: string, assetId: string, code: string): Promise<void>

	deleteGtmLink(accountId: string, assetId: string): Promise<void>

	updateAccountPhoto(formData: Partial<FormData>): Promise<void>
}

export const useAccountStore = create<AccountStoreState>((set, get) => ({
	account: null,
	accounts: [],
	accountUsersWithAccess: [],
	accountInvitedUsers: [],
	subscription: null,
	subscriptionLoading: true,
	gtmLinks: [],
	invoices: [],
	invoice: null,
	wantsToSeeDemoData: false,
	// Account Action Methods
	setAccounts: (accounts: ProductAccountDTO[]) => set({ accounts }),
	setGtmLinks: (links: GoogleOAuthAccountLinkDTO[]) => set({ gtmLinks: links }),
	clearAccount: () => set({ account: null, wantsToSeeDemoData: false }),
	clearAccounts: () => set({ accounts: [] }),
	setAccount: (account: ProductAccountDTO | null) => {
		set({ account })
		if (account) {
			get().setUsersWithAccess(account.user_access)
			get().refreshAccountLinks()
		} else {
			useAssetStore.getState().setAllAssets([])
			useAssetStore.getState().setAsset(null)
		}
	},
	setSubscriptionLoading: (loading: boolean) =>
		set({ subscriptionLoading: loading }),
	setSubscription: (subscription: SubscriptionDTO | null) =>
		set({ subscription }),
	setWantsToSeeDemoData: (value = true) => set({ wantsToSeeDemoData: value }),
	getAllAccounts: async () => {
		try {
			useUiStore.getState().setActionState('USER_GET_ACCOUNTS', 'loading')
			const accounts = await ProductAccounts.find()
			const sortedAccountsByStatus = accounts.sort((a, b) => {
				if (a.status === 'ACTIVE') return -1
				if (b.setup?.step === 'subscription') return 1
				return 0
			})

			get().setAccounts(sortedAccountsByStatus)
			useUiStore.getState().setActionState('USER_GET_ACCOUNTS', 'idle')
			return get().accounts
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_GET_ACCOUNTS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to load user accounts'
				)
			console.log(err)
		}
	},
	isInSetup: () => {
		return get().account?.status === 'SETUP'
	},
	tier: () => {
		return getPermissionTier(get().account?.billing?.subscription?.plan.tier)
	},
	hasFeature: (feature: string) => {
		if (get().account?.billing?.subscription?.type === 'TRIAL') return true

		const tier = getPermissionTier(
			get().account?.billing?.subscription?.plan.tier
		)
		if (!tier) return false

		if (tier === 'legacy') return !!features[feature]['standard']

		return !!features[feature][tier]
	},
	canAddTeamMember: () => {
		if (get().account?.billing?.subscription?.type === 'TRIAL') return true

		const tier = getPermissionTier(
			get().account?.billing?.subscription?.plan.tier
		)
		if (!tier) return false

		const tierToCheck = tier === 'legacy' ? 'standard' : tier
		const value = (features.team_member_add[tierToCheck] || 0) as number
		const users = get().account?.user_access.length || 0
		return value > users
	},
	createAccount: async (
		payload: {
			tier: string
			price_id: string
			pm_id: string
			auto_upgrade: boolean
			offer_id: null
			org_data: {
				name: string
				billing_contact: {
					name: string
					email: string
				}
			}
		},
		onSuccess?: any
	) => {
		try {
			useUiStore.getState().setActionState('USER_ADD_ACCOUNT', 'loading')
			const account = await ProductAccounts.create(payload)
			useUiStore
				.getState()
				.setActionState('USER_ADD_ACCOUNT', 'success', 'Workspace created!')
			if (onSuccess && typeof onSuccess === 'function') {
				onSuccess()
			}
			return account
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_ADD_ACCOUNT',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to create workspace'
				)
		}
	},
	deleteAccount: async (accountId: string, onSuccess?: any) => {
		try {
			useUiStore.getState().setActionState('USER_REMOVE_ACCOUNT', 'loading')
			await ProductAccounts.deleteAccount(accountId)
			const refreshedAccounts = await get().getAllAccounts()
			useUiStore
				.getState()
				.setActionState('USER_REMOVE_ACCOUNT', 'success', 'Workspace deleted!')
			if (onSuccess && typeof onSuccess === 'function') {
				onSuccess()
			}
			return refreshedAccounts
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_REMOVE_ACCOUNT',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to delete workspace'
				)
		}
	},
	createAccountWithOffer: async (offerId: string, pmId: string, org: any) => {
		try {
			useUiStore.getState().setActionState('USER_ADD_ACCOUNT', 'loading')
			const account = await ProductAccounts.createWithOffer({
				pm_id: pmId,
				offer_id: offerId,
				org_data: {
					name: org.name,
					billing_contact: {
						name: org.billing_name,
						email: org.billing_email
					}
				}
			})

			useUiStore
				.getState()
				.setActionState(
					'USER_ADD_ACCOUNT',
					'success',
					'Offer accepted and new account created'
				)
			return account
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_ADD_ACCOUNT',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to create offer'
				)
		}
	},
	setAccountForId: async (id: string, onFail?: NavigateFunction) => {
		try {
			const account = await ProductAccounts.getById(id)
			get().setAccount(account)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_ACCOUNT',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to get accounts'
				)
			if (typeof onFail === 'function') onFail('/workspaces')
			console.log(err)
		}
	},
	addCoupon: async (accountId: string, payload: { coupon_code: string }) => {
		try {
			useUiStore.getState().setActionState('USER_CHANGE_ACCOUNT', 'loading')
			const offers = await ProductAccounts.addCoupon(accountId, payload)
			useUiStore
				.getState()
				.setActionState('USER_CHANGE_ACCOUNT', 'success', 'Discount applied')
			get().setAccountForId(accountId)
			return offers
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_CHANGE_ACCOUNT',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to apply discount'
				)
			throw parseError(err)
		}
	},
	//****************************
	// Account User Action Methods
	getAccountUsers: async (accountId: string) => {
		try {
			useUiStore.getState().setActionState('ACCOUNT_GET_MEMBERS', 'loading')
			const accountUsersWithAccess = await ProductAccounts.getUsersWithAccess(
				accountId
			)
			const accountInvitedUsers = await ProductAccounts.getInvitedUsers(
				accountId
			)
			get().setUsersWithAccess(accountUsersWithAccess)
			get().setInvitedUsers(accountInvitedUsers)
			useUiStore.getState().setActionState('ACCOUNT_GET_MEMBERS', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_GET_MEMBERS',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to get members'
				)
			console.log(err)
		}
	},
	setUsersWithAccess: (users: UserAccessInfoDTO[]) =>
		set({ accountUsersWithAccess: users }),
	userAccess: (userId?: string) => {
		const userAccess = get().accountUsersWithAccess.find(
			(account) => account.user_id === userId
		)
		if (
			userAccess?.access_level === 'OWNER' ||
			userAccess?.access_level === 'WRITE'
		)
			return 'WRITE'
		if (userAccess?.access_level === 'READ') return 'READ'
		return 'READ'
	},
	userAccessLevel: (userId?: string) => {
		const userAccess = get().accountUsersWithAccess.find(
			(account) => account.user_id === userId
		)
		if (userAccess?.access_level) return userAccess?.access_level
	},
	isOwnerAccess: (userId?: string) => {
		const userAccess = get().accountUsersWithAccess.find(
			(account) => account.user_id === userId
		)
		return userAccess?.access_level === 'OWNER'
	},
	inviteAccountUser: async (
		accountId: string,
		payload: {
			email: string
			access_level: string
		}
	) => {
		try {
			useUiStore.getState().setActionState('ACCOUNT_ADD_MEMBER', 'loading')
			await ProductAccounts.inviteUser(accountId, payload)
			useUiStore
				.getState()
				.setActionState('ACCOUNT_ADD_MEMBER', 'success', 'Invite sent')
			get().getAccountUsers(accountId)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_ADD_MEMBER',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to sent invite'
				)
			console.log(err)
		}
	},
	setInvitedUsers: (users: UserAccessInviteDTO[]) =>
		set({ accountInvitedUsers: users }),
	revokeUserInvitation: async (accountId: string, inviteId: string) => {
		try {
			useUiStore.getState().setActionState('ACCOUNT_SET_MEMBER', 'loading')
			await ProductAccounts.revokeUserInvitation(accountId, inviteId)
			get().getAccountUsers(accountId)
			useUiStore
				.getState()
				.setActionState('ACCOUNT_SET_MEMBER', 'success', 'Invitation revoked')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SET_MEMBER',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to revoke invitation'
				)
			console.log(err)
		}
	},

	updateAccountUser: async (
		accountId: string,
		userId: string,
		payload: {
			access_level: string
		}
	) => {
		useUiStore.getState().setActionState('ACCOUNT_SET_MEMBER', 'loading')
		try {
			await ProductAccounts.updateUser(accountId, userId, payload)
			useUiStore
				.getState()
				.setActionState('ACCOUNT_SET_MEMBER', 'success', 'Team member updated')
			get().getAccountUsers(accountId)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SET_MEMBER',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to update team member'
				)
			console.log(err)
		}
	},
	deleteAccountUser: async (accountId: string, userId: string) => {
		try {
			useUiStore.getState().setActionState('ACCOUNT_REMOVE_MEMBER', 'loading')
			await ProductAccounts.deleteUser(accountId, userId)
			await get().getAccountUsers(accountId)
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_REMOVE_MEMBER',
					'success',
					'Team member removed'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_REMOVE_MEMBER',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to remove team member'
				)
			console.log(err)
		}
	},
	// Account Organization Action Methods
	updateAccountOrganization: async (
		accountId: string,
		payload: OrganizationDTO
	) => {
		try {
			useUiStore.getState().setActionState('ACCOUNT_SAVE_SETTINGS', 'loading')
			await ProductAccounts.updateAccountOrganization(accountId, payload)
			await get().setAccountForId(accountId)
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SETTINGS',
					'success',
					'Organization updated'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SETTINGS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to update organization'
				)
			console.log(err)
		}
	},
	updateAccountPreferencesOrganization: async (
		accountId: string,
		payload: AccountPreferencesOrganizationDto
	) => {
		try {
			useUiStore.getState().setActionState('ACCOUNT_SAVE_SETTINGS', 'loading')
			await ProductAccounts.updateAccountPreferencesOrganization(
				accountId,
				payload
			)
			await get().setAccountForId(accountId)
			const accounts = await get().getAllAccounts()
			await get().setAccounts(accounts || [])

			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SETTINGS',
					'success',
					'Workspace details changed!'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SETTINGS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to update organization'
				)
			console.log(err)
		}
	},
	updateAccountContactOrganization: async (
		accountId: string,
		payload: AccountContactOrganizationDto
	) => {
		try {
			useUiStore.getState().setActionState('ACCOUNT_SAVE_SETTINGS', 'loading')
			await ProductAccounts.updateAccountContactOrganization(accountId, payload)
			await get().setAccountForId(accountId)
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SETTINGS',
					'success',
					'Workspace details changed!'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SETTINGS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to update organization'
				)
			console.log(err)
		}
	},
	updateAccountBillingInfo: async (payload: Partial<BillingAccountInfoDTO>) => {
		const account = get().account
		if (!account) {
			return
		}
		useUiStore.getState().setActionState('ACCOUNT_SAVE_SETTINGS', 'loading')
		try {
			await ProductAccounts.updateAccountBillingInfo(account.id, payload)
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SETTINGS',
					'success',
					'Billing information saved'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SETTINGS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to update billing info'
				)
			console.log(err)
		}
	},
	editAccountPaymentMethod: async (
		accountId: string,
		payload: { pm_id: string }
	) => {
		try {
			useUiStore.getState().setActionState('ACCOUNT_SAVE_SETTINGS', 'loading')
			await ProductAccounts.editAccountPaymentMethod(accountId, payload)
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SETTINGS',
					'success',
					'Payment method changed'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SETTINGS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to update payment method'
				)
			console.log(err)
		}
	},
	upgradeAddLimit: async (accountId: string, payload: { enabled: boolean }) => {
		try {
			useUiStore
				.getState()
				.setActionState('ACCOUNT_UPDATE_ADD_SPEND_LIMIT', 'loading')
			await ProductAccounts.autoUpgradeAddLimit(accountId, payload)
			await get().setAccountForId(accountId)
			const msg = payload.enabled
				? 'Auto upgrade ad spend limit enabled'
				: 'Auto upgrade ad spend limit disabled'
			useUiStore
				.getState()
				.setActionState('ACCOUNT_UPDATE_ADD_SPEND_LIMIT', 'success', msg)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_UPDATE_ADD_SPEND_LIMIT',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to update auto upgrade ad spend limit'
				)
			console.log(err)
		}
	},
	setInvoices: (invoices: Array<InvoiceDTO>) => set({ invoices }),
	setInvoice: (invoice: InvoiceDTO) => set({ invoice }),
	loadInvoices: async () => {
		const account = get().account
		if (!account) return
		try {
			useUiStore.getState().setActionState('ACCOUNT_GET_INVOICES', 'loading')
			const invoices = await Invoices.find(account.id)
			get().setInvoices(invoices)
			useUiStore.getState().setActionState('ACCOUNT_GET_INVOICES', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_GET_INVOICES',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to load invoices'
				)
			console.log(err)
		}
	},
	downloadInvoice: async (account_id: string, invoice_id: string) => {
		try {
			useUiStore
				.getState()
				.setActionState('ACCOUNT_DOWNLOAD_INVOICE', 'loading')
			await Invoices.download(account_id, invoice_id)
			useUiStore.getState().setActionState('ACCOUNT_DOWNLOAD_INVOICE', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_DOWNLOAD_INVOICE',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to download pdf'
				)
			console.log(err)
		}
	},
	// Account Subscription Action Methods
	startPendingSubscription: async (
		accountId: string,
		payload: { note: string; pm_id?: string | null }
	) => {
		try {
			useUiStore
				.getState()
				.setActionState('ACCOUNT_SAVE_SUBSCRIPTION', 'loading')
			await ProductAccounts.startPendingSubscription(accountId, payload)
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SUBSCRIPTION',
					'success',
					'Subscription updated'
				)
			get().setAccountForId(accountId)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SUBSCRIPTION',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to update subscription'
				)
			console.log(err)
		}
	},
	revertChange: async (accountId: string, payload: { note: string }) => {
		try {
			useUiStore
				.getState()
				.setActionState('ACCOUNT_SAVE_SUBSCRIPTION', 'loading')
			await ProductAccounts.revertChange(accountId, payload)
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SUBSCRIPTION',
					'success',
					'Pending changes reverted'
				)
			get().setAccountForId(accountId)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SUBSCRIPTION',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to revert pending changes'
				)
			console.log(err)
		}
	},
	newSubscription: async (
		accountId: string,
		payload: {
			tier: string
			price_id: string
			coupon_code: string | null
			pm_id: string | null
		}
	) => {
		try {
			useUiStore
				.getState()
				.setActionState('ACCOUNT_SAVE_SUBSCRIPTION', 'loading')
			await ProductAccounts.newSubscription(accountId, payload)
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SUBSCRIPTION',
					'success',
					'Subscription updated'
				)
			get().setAccountForId(accountId)
			get().getSubscriptionByAccountId(accountId)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SUBSCRIPTION',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to update subscription'
				)
			console.log(err)
		}
	},
	getSubscriptionByAccountId: async (accountId: string) => {
		try {
			useUiStore.getState().setActionState('SUBSCRIPTION_GET', 'loading')
			const subscription = await ProductAccounts.getSubscriptionByAccountId(
				accountId
			)
			get().setSubscription(subscription)
			useUiStore.getState().setActionState('SUBSCRIPTION_GET', 'idle')
			await get().getAllAccounts()
			await useAssetStore.getState().getAllAssets()
			useAssetStore.getState().setDemoMode(false)
		} catch (err: any) {
			console.log(err)
		}
	},
	changeSubscription: async (
		accountId: string,
		payload: {
			tier: string
			price_id: string
			coupon_code: string | null
			pm_id: string | null
			schedule_subscription_change?: boolean
		}
	): Promise<SubscriptionDTO | undefined> => {
		try {
			useUiStore
				.getState()
				.setActionState('ACCOUNT_SAVE_SUBSCRIPTION', 'loading')
			const subscription = await ProductAccounts.changeSubscription(
				accountId,
				payload
			)
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SUBSCRIPTION',
					'success',
					subscription?.status === 'ACTIVE' ? 'Subscription updated' : undefined
				)
			get().setAccountForId(accountId)
			return subscription
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SUBSCRIPTION',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to update subscription'
				)
			console.log(err)
		}
	},
	cancelRequest: async (
		accountId: string,
		payload: CancellationRequestInfo
	) => {
		try {
			useUiStore
				.getState()
				.setActionState('ACCOUNT_SET_CANCEL_REQUEST', 'loading')
			await ProductAccounts.cancelRequest(accountId, payload)
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SET_CANCEL_REQUEST',
					'success',
					'Cancellation request created'
				)
			get().setAccountForId(accountId)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SET_CANCEL_REQUEST',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed create cancellation request'
				)
			console.log(err)
		}
	},
	removeCancelRequest: async () => {
		const account = get().account
		if (!account) return
		try {
			useUiStore
				.getState()
				.setActionState('ACCOUNT_SAVE_SUBSCRIPTION', 'loading')
			await ProductAccounts.removeCancelRequest(account.id)
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SUBSCRIPTION',
					'success',
					'Cancel request removed'
				)
			get().setAccountForId(account.id)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SUBSCRIPTION',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to remove cancel request'
				)
			console.log(err)
		}
	},
	// Account Links Action Methods
	accountLinks: [],
	setAccountLinks: (links: AccountLinkDTO[]) => set({ accountLinks: links }),
	refreshAccountLinks: async () => {
		const account = get().account
		if (!account) return
		try {
			useUiStore.getState().setActionState('ACCOUNT_GET_ACCESS', 'loading')
			const accountLinks = (await AdAccountLinks.find(account.id)).filter(
				(a) => a !== null
			)
			get().setAccountLinks(accountLinks)
			useUiStore.getState().setActionState('ACCOUNT_GET_ACCESS', 'idle')
			return accountLinks
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_GET_ACCESS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to refresh account links'
				)
			console.log(err)
		}
	},
	syncAccountLink: async (id: string) => {
		const account = get().account
		if (!account) return
		try {
			useUiStore.getState().setActionState('ACCOUNT_SYNC_ACCESS', 'loading')
			await AdAccountLinks.syncLink(account.id, id)
			get().refreshAccountLinks()
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SYNC_ACCESS',
					'success',
					'Synchronization scheduled'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SYNC_ACCESS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to schedule synchronization'
				)
			console.log(err)
		}
	},
	deleteAccountLink: async (linkId: string, deactivatePPC: boolean) => {
		const account = get().account
		if (!account) return
		try {
			useUiStore.getState().setActionState('ACCOUNT_REMOVE_ACCESS', 'loading')
			await AdAccountLinks.deleteLink(account.id, linkId, deactivatePPC)
			useUiStore
				.getState()
				.setActionState('ACCOUNT_REMOVE_ACCESS', 'idle', 'Connection deleted')
			get().refreshAccountLinks()
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_REMOVE_ACCESS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to delete connection'
				)
			throw new Error()
		}
	},
	createOAuthLink: async (
		code: string,
		provider: 'google' | 'facebook' | 'bing',
		accountId?: string | null
	) => {
		const account = get().account
		if (!account) return
		try {
			useUiStore.getState().setActionState('ACCOUNT_ADD_ACCESS', 'loading')
			await AdAccountLinks.createOAuthLink(
				provider,
				{ auth_code: code },
				accountId || account.id
			)
			useUiStore
				.getState()
				.setActionState('ACCOUNT_ADD_ACCESS', 'success', 'Connection added')
			get().refreshAccountLinks()
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_ADD_ACCESS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to add connection'
				)
			throw new Error()
		}
	},
	createMCCLink: async (parentId: string, accNum: string) => {
		const account = get().account
		if (!account) return
		try {
			useUiStore.getState().setActionState('ACCOUNT_ADD_ACCESS', 'loading')
			await AdAccountLinks.createMccLink(account.id, {
				parent_id: parentId,
				account_number: accNum
			})
			useUiStore
				.getState()
				.setActionState('ACCOUNT_ADD_ACCESS', 'success', 'Connection added')
			get().refreshAccountLinks()
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_ADD_ACCESS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to add connection'
				)
			throw new Error()
		}
	},
	getGtmLinks: async (accountId: string) => {
		try {
			useUiStore.getState().setActionState('ACCOUNT_GET_GTM_LINKS', 'loading')
			const links = await GtmService.getGtmLinks(accountId)
			get().setGtmLinks(links)
			useUiStore.getState().setActionState('ACCOUNT_GET_GTM_LINKS', 'success')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_GET_GTM_LINKS',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to fetch links'
				)
		}
	},
	createGtmAuth: async (accountId: string, assetId: string, code: string) => {
		try {
			useUiStore.getState().setActionState('ASSET_SET_GTM_AUTH', 'loading')
			await GtmService.auth(accountId, assetId, code)
			get().getGtmLinks(accountId)
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SET_GTM_AUTH',
					'success',
					'GTM Authenticated successfully'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SET_GTM_AUTH',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error creating auth link'
				)
			console.log(err)
		}
	},
	deleteGtmLink: async (accountId: string, assetId: string) => {
		try {
			useUiStore.getState().setActionState('ACCOUNT_DELETE_GTM_LINK', 'loading')
			await GtmService.deleteGtmLink(accountId, assetId)
			get().getGtmLinks(accountId)
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_DELETE_GTM_LINK',
					'success',
					'Link deleted successfully'
				)
			get().getGtmLinks(accountId)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_DELETE_GTM_LINK',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error deleting auth link'
				)
			console.log(err)
		}
	},
	updateAccountPhoto: async (formData: Partial<FormData>) => {
		const account = get().account
		if (!account) return
		useUiStore.getState().setActionState('ACCOUNT_SAVE_SETTINGS', 'loading')
		try {
			const avatar = await ProductAccounts.updateAccountPhoto(
				formData,
				account.id
			)
			get().setAccount({ ...account, avatar })
			useUiStore.getState().setActionState('ACCOUNT_SAVE_SETTINGS', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_SAVE_SETTINGS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error updating profile photo'
				)
		}
	}
}))
