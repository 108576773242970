import { GTransition } from 'components/basic-blocks'
import { OnBoarding } from 'components/onboarding/onboarding'
import { platformNameByType } from 'utils/platform'
import { AgencyAddAccount } from './agency/agency-add-account'
import { PlatformLink } from './platform-link'
import { StandardAddAccount } from './standard/standard-add-account'
import { SetupViewProps } from './types'

const getTitle = (state: any, step: string | null) => {
	switch (step) {
		case 'link':
			return (
				<div className="text-3xl text-[#FFF] font-[800] w-[288px]">
					<span>Link your</span>
					<span className="text-green-400 ml-2">advertising platform</span>{' '}
				</div>
			)
		case 'account':
			return (
				<div className="text-3xl text-[#FFF] font-[800] w-[288px]">
					Add your <br />
					<span className="text-green-400">
						{platformNameByType[state.platform]} Ads
					</span>{' '}
					account
				</div>
			)
		default:
			return (
				<div className="text-3xl text-[#FFF] font-[800] w-[288px]">
					<span>Link your</span>
					<span className="text-green-400  ml-2">
						advertising platform
					</span>{' '}
				</div>
			)
	}
}
const getDescription = (step: string | null) => {
	switch (step) {
		case 'link':
			return (
				<span>
					Authorize ClickGUARD to connect to your primary PPC platform. You can
					add additional platforms later!
				</span>
			)
		case 'account':
			return (
				<span>
					Select one of the accounts you have access to add to your team’s
					workspace.
				</span>
			)
	}
}

export const SetupView = ({
	state,
	setState,
	setStep,
	authorizePlatform,
	resetAuth,
	addAccount,
	addMCC,
	addAccounts,
	handleBack
}: SetupViewProps) => {
	return (
		<OnBoarding
			state={state}
			setStep={setStep}
			handleBack={handleBack}
			title={
				<div className="text-3xl text-white font-[800]">
					{getTitle(state, state.step)}
				</div>
			}
			description={getDescription(state.step)}
		>
			<GTransition show={state.step === 'link'} swap>
				<PlatformLink
					state={state}
					setPlatform={(e) => {
						setState({ ...state, platform: e })
					}}
					authorizePlatform={authorizePlatform}
					reset={resetAuth}
				/>
			</GTransition>

			<div className="flex justify-center item-center">
				<GTransition
					show={state.step === 'account'}
					swap
					loader={state.loading && !state.waiting}
				>
					{state.account?.type === 'STANDARD' && (
						<StandardAddAccount
							state={state}
							addAccount={addAccount}
							setStep={setStep}
						/>
					)}
					{state.account?.type === 'AGENCY' && (
						<AgencyAddAccount
							state={state}
							addAccounts={addAccounts}
							addMCC={addMCC}
							setStep={setStep}
						/>
					)}
				</GTransition>
			</div>
		</OnBoarding>
	)
}
