import { useStores } from 'hooks'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

export const AdminBase = () => {
	const navigate = useNavigate()
	const { userStore, adminStore, billingStore } = useStores()
	const { user } = userStore

	useEffect(() => {
		billingStore.initBilling()
		;(async () => {
			if (user && !user.admin) {
				return navigate('/')
			}
		})()
	}, [user])

	useEffect(() => {
		;(async () => {
			if (adminStore.impersonation) {
				await adminStore.clearImpersonation()
			}
		})()
	}, [])

	return <Outlet />
}
