import {
	RiArrowDownSLine,
	RiArrowRightSLine,
	RiSearchLine
} from 'react-icons/ri'

import { classNames } from 'utils'

import { PlatformIcon } from 'components/platform-icon'
import { useState } from 'react'
import { AdAccountProps } from './types'

export const AdAccount = ({
	selectedAdAccount,
	isSelected,
	adAccounts,
	showAdAccounts,
	handleShowAdAccounts,
	handleSelectedAdAccount
}: AdAccountProps) => {
	const [filterInput, setFilterInput] = useState('')
	const [isInputFocused, setIsInputFocused] = useState(false)

	const showInputFilter = adAccounts.length > 5
	const filteredAdAccounts = filterInput
		? adAccounts.filter((c) =>
				c.name.toLowerCase().includes(filterInput.toLowerCase())
		  )
		: adAccounts

	return (
		<>
			<RiArrowRightSLine color="#69707D" className="ml-2 mr-3" size={16} />
			<div
				className={classNames(
					'flex items-center cursor-pointer relative py-2 px-1',
					isSelected ? 'border-b-primary-300 border-b-2' : ''
				)}
				onClick={() => {
					if (isInputFocused) return
					handleShowAdAccounts()
				}}
			>
				{selectedAdAccount ? (
					<div className="flex items-center">
						<PlatformIcon
							platform={selectedAdAccount.platform}
							className="w-4 h-4"
						/>
						<span
							className={classNames(
								'text-t-default mx-2 truncate max-w-[100px] block',
								isSelected ? 'font-bold' : ''
							)}
						>
							{selectedAdAccount.name}
						</span>
					</div>
				) : (
					<span className="italic text-t-secondary mr-2">
						Select an account
					</span>
				)}
				<RiArrowDownSLine color="#69707D" size={16} />
				{showAdAccounts && (
					<>
						<div className="flex flex-col p-2 gap-3 absolute ml-0 left-[-6px] top-[35px] z-20 border rounded-lg shadow-lg bg-white overflow-auto max-h-64">
							{showInputFilter ? (
								<div className="flex items-center border-b px-2 mb-2">
									<RiSearchLine className="h-3.5 w-3.5 text-t-secondary" />
									<input
										type="text"
										value={filterInput}
										placeholder="Name..."
										onChange={(e) => {
											setFilterInput(e.target.value)
										}}
										onFocus={() => setIsInputFocused(true)}
										onBlur={() => setIsInputFocused(false)}
										className="h-10 bg-transparent w-full outline-none text-sm focus:ring-0 border-none text-t-secondary"
									/>
								</div>
							) : null}

							{filteredAdAccounts.map((adAccount) => (
								<div
									key={adAccount.id}
									onClick={() => {
										handleSelectedAdAccount(adAccount)
										setIsInputFocused(false)
										setFilterInput('')
									}}
									className="flex items-center hover:bg-gray-100 p-2 rounded-md"
								>
									<PlatformIcon
										platform={adAccount.platform}
										className="w-4 h-4"
									/>
									<span className="ml-2 max-w-[320px] truncate block">
										{adAccount.name}
									</span>
								</div>
							))}
						</div>
					</>
				)}
			</div>
		</>
	)
}
