import { Notices } from 'components/notice/notices'
import { PlatformIcon } from 'components/platform-icon'
import { useEffect, useState } from 'react'
import { useAccountStore, useAssetStore, useUserStore } from 'store'
import { twMerge } from 'tailwind-merge'
import { sanitizeURL } from 'utils'
import { AdPlatformTypesDTO } from 'utils/platform'
import { useShallow } from 'zustand/react/shallow'
import { FacebookAdsAdAccountDTO } from '../../../../../../../api-models'
import {
	AdAccountDTO,
	BingAdsAdAccountDTO,
	GoogleAdsAdAccountDTO
} from '../../../../../../../api-models/src'
import { AddNewPPCAccount } from '../../../../../components/add-new-ppc-account'
import {
	GButton,
	GSection,
	GToggle,
	GTooltip
} from '../../../../../components/basic-blocks'
import { GBadge } from '../../../../../components/basic-blocks/g-badge'
import { PageHeader } from '../../../../../components/page-header'
import { ZendeskService } from '../../../../../services/zendesk'
import { PageTitle } from '../../../../../utils/page-title'
import { AdsAccountSetting } from './ads-account-setting'
import { capitalizeFirstLetter } from '../../../../../utils/text'

export const PpcAccountSetting = () => {
	const user = useUserStore((s) => s.user)
	const { asset, adAccounts, demoMode } = useAssetStore(
		useShallow((s) => ({
			adAccounts: s.adAccounts,
			demoMode: s.demoMode,
			asset: s.asset
		}))
	)
	const { account, userAccess, isInSetup, hasFeature } = useAccountStore(
		useShallow((s) => ({
			account: s.account,
			userAccess: s.userAccess,
			isInSetup: s.isInSetup,
			hasFeature: s.hasFeature
		}))
	)

	const [fadsAccounts, setFadsAccounts] = useState<AdAccountDTO[]>([])
	const [gadsAccounts, setGadsAccounts] = useState<AdAccountDTO[]>([])
	const [badsAccounts, setBadsAccounts] = useState<AdAccountDTO[]>([])
	const [currentAsset, setCurrentAsset] = useState<string | undefined>(
		sanitizeURL(asset?.name)
	)

	useEffect(() => {
		if (adAccounts?.length > 0) {
			const fads = adAccounts.filter((acc) => acc.platform === 'FADS')
			const gads = adAccounts.filter((acc) => acc.platform === 'GADS')
			const bads = adAccounts.filter((acc) => acc.platform === 'BADS')

			setFadsAccounts(fads)
			setGadsAccounts(gads)
			setBadsAccounts(bads)
		}
	}, [adAccounts])

	useEffect(() => {
		setCurrentAsset(asset?.name)
	}, [asset])
	return (
		<div className="w-full">
			<PageTitle pageName="PPC accounts" />
			<Notices
				isInSetup={isInSetup()}
				setupStep={account?.setup?.step}
				isInActive={account?.status === 'INACTIVE'}
				hasDashboardPerformanceFeature={hasFeature('dashboard_performance')}
				tc_installed={!!asset?.tc_installed}
				cc_installed={!!asset?.cc_installed}
				accountId={account?.id}
				assetId={asset?.id}
				demoMode={demoMode}
				mccLinkInviteAdAccounts={adAccounts}
			/>
			<PageHeader
				title={'PPC accounts'}
				tagline="Manage PPC accounts and their settings on"
				tags={[
					{
						label: demoMode ? 'Demo PPC account' : currentAsset
					}
				]}
			/>
			<div className="max-w-3xl px-4 sm:px-6 md:px-8">
				<GSection
					titleClass={gadsAccounts?.length > 0 ? 'mb-6' : 'mb-0'}
					lineBreak={gadsAccounts?.length > 0}
					lineBreakColor="gray-100"
					title={
						<div className="flex items-center">
							<PlatformIcon platform="GADS" className="h-6 w-6" />
							<p className="font-bold text-xl text-t-default ">
								&nbsp; &nbsp;Google Ads
							</p>
						</div>
					}
					actions={
						<AddNewPPCAccount>
							{(open) => (
								<GTooltip
									content={
										demoMode
											? 'This action is disabled when demo data is displayed'
											: userAccess(user!.id) === 'READ'
											? "Analysts don't have permission for this action"
											: undefined
									}
								>
									<GButton
										variant="contained"
										label="Add account"
										disabled={demoMode || userAccess(user!.id) === 'READ'}
										color="primary"
										className="h-[30px] min-w-[116px] border-t-input-border"
										labelClassName="font-bold text-base"
										onClick={() => open()}
									/>
								</GTooltip>
							)}
						</AddNewPPCAccount>
					}
				>
					{gadsAccounts?.map((account) => (
						<PPCAccountList
							key={account?.id}
							adAccount={account as GoogleAdsAdAccountDTO}
							platform={'GADS'}
						/>
					))}
				</GSection>

				{account?.billing?.subscription?.plan?.tier !== 'legacy' && (
					<>
						<div className="mt-8">
							<GSection
								titleClass={fadsAccounts?.length > 0 ? 'mb-6' : 'mb-0'}
								lineBreak={fadsAccounts?.length > 0}
								lineBreakColor="gray-100"
								title={
									<div className="flex items-center">
										<PlatformIcon platform="FADS" className="h-6 w-6" />
										<p className="font-bold text-xl text-t-default">
											&nbsp; &nbsp;Meta Ads
										</p>
									</div>
								}
								actions={
									<AddNewPPCAccount platform={'FADS'}>
										{(open) => (
											<GTooltip
												content={
													demoMode
														? 'This action is disabled when demo data is displayed'
														: userAccess(user!.id) === 'READ'
														? "Analysts don't have permission for this action"
														: undefined
												}
											>
												<GButton
													variant="contained"
													label="Add account"
													color="primary"
													disabled={demoMode || userAccess(user!.id) === 'READ'}
													className="h-[30px] minw-[141px] border-t-input-border"
													labelClassName="font-bold text-base"
													onClick={() => open()}
												/>
											</GTooltip>
										)}
									</AddNewPPCAccount>
								}
							>
								{fadsAccounts?.map((account) => (
									<PPCAccountList
										key={account?.id}
										adAccount={account as FacebookAdsAdAccountDTO}
										platform={'FADS'}
									/>
								))}
							</GSection>
						</div>

						<div className="mt-8">
							<GSection
								titleClass={badsAccounts?.length > 0 ? 'mb-6' : 'mb-0'}
								lineBreak={badsAccounts?.length > 0}
								lineBreakColor="gray-100"
								title={
									<div className="flex items-center">
										<PlatformIcon platform="BADS" className="h-6 w-6" />
										<p className="font-bold text-xl text-t-default">
											&nbsp; &nbsp;Bing Ads
										</p>
									</div>
								}
								actions={
									<AddNewPPCAccount platform={'BADS'}>
										{(open) => (
											<GTooltip
												content={
													demoMode
														? 'This action is disabled when demo data is displayed'
														: userAccess(user!.id) === 'READ'
														? "Analysts don't have permission for this action"
														: undefined
												}
											>
												<GButton
													variant="contained"
													label="Add account"
													color="primary"
													disabled={demoMode || userAccess(user!.id) === 'READ'}
													className="h-[30px] minw-[141px] border-t-input-border"
													labelClassName="font-bold text-base"
													onClick={() => open()}
												/>
											</GTooltip>
										)}
									</AddNewPPCAccount>
								}
							>
								{badsAccounts?.map((account) => (
									<PPCAccountList
										key={account?.id}
										adAccount={account as BingAdsAdAccountDTO}
										platform={'BADS'}
									/>
								))}
							</GSection>
						</div>
					</>
				)}
			</div>
		</div>
	)
}

export const PPCAccountList = (props: {
	adAccount: AdPlatformTypesDTO
	platform: 'GADS' | 'FADS' | 'BADS'
}) => {
	const { adAccount, platform } = props
	const user = useUserStore((s) => s.user)
	const { updateSettingsAccountProtection, demoMode } = useAssetStore(
		useShallow((s) => ({
			updateSettingsAccountProtection: s.updateSettingsAccountProtection,
			demoMode: s.demoMode
		}))
	)
	const { userAccess } = useAccountStore(
		useShallow((s) => ({
			userAccess: s.userAccess,
			accountLinks: s.accountLinks
		}))
	)
	const [isActive, setIsActive] = useState(() => adAccount.status === 'ACTIVE')

	const handleToggleAccountProtection = () => {
		setIsActive((prevState) => !prevState)
		updateSettingsAccountProtection(adAccount?.account_id, adAccount?.id, {
			active: !isActive
		})
	}

	return (
		<div className="flex justify-between mt-6">
			<div className="flex gap-4">
				<GTooltip
					content={
						demoMode
							? 'This action is disabled when demo data is displayed'
							: userAccess(user!.id) === 'READ'
							? "Analysts don't have permission for this action"
							: undefined
					}
				>
					<GToggle
						checked={isActive}
						disabled={demoMode || userAccess(user!.id) === 'READ'}
						activeColor={'success'}
						onChange={handleToggleAccountProtection}
					/>
				</GTooltip>
				<div>
					<p className="text-t-default text-base truncate max-w-[200px]">
						{adAccount?.name}
					</p>
					<p className="text-t-secondary text-sm">
						{adAccount?.details?.account_number?.replace('act_', '')}
					</p>
				</div>
			</div>
			<div className="flex items-center gap-4">
				<GBadge
					className={twMerge('font-bold px-3 py-1 rounded-md align-center')}
					text={capitalizeFirstLetter(
						adAccount?.details.status || 'OFF'
					).replaceAll('_', ' ')}
					color={
						adAccount?.details.status === 'OK'
							? 'green'
							: adAccount?.details.status === 'NO_ACCESS'
							? 'red'
							: 'amber'
					}
				/>
				<AdsAccountSetting adAccount={adAccount} platform={platform}>
					{(open) => (
						<GTooltip
							content={
								userAccess(user!.id) === 'READ'
									? "Analysts don't have permission for this action"
									: undefined
							}
						>
							<GButton
								variant="text"
								label="Configure"
								size="lg"
								color="buttonPrimary"
								className="h-[28px] w-[84px] disabled:bg-gray-200 disabled:text-t-secondary border-t-input-border"
								labelClassName="font-bold text-sm"
								disabled={userAccess(user!.id) === 'READ'}
								onClick={() => {
									open()
									ZendeskService.hide()
								}}
							/>
						</GTooltip>
					)}
				</AdsAccountSetting>
			</div>
		</div>
	)
}
