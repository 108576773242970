import { AdminRevenueStatsDTO, AdminRevenueTimelineDTO } from 'api-models'

import { formatCurrency } from 'utils'

import moment from 'moment'
import { AdminTimelineDataType } from '../types'
import {
	FormattedAdminRevenueStatsDTO,
	FormattedAdminTimelineDTO
} from './types'

export const formatData = (
	data: AdminRevenueStatsDTO[]
): FormattedAdminRevenueStatsDTO[] => {
	return data.map((item: AdminRevenueStatsDTO) => {
		return {
			...item,
			tier_type_capitalized:
				item.tier_type.charAt(0).toUpperCase() + item.tier_type.slice(1),
			net_change_in_currency: formatCurrency(Number(item.net_change), 'cent'),
			arpu_in_currency: formatCurrency(Number(item.arpu), 'cent'),
			average_mrr_in_currency: formatCurrency(Number(item.average_mrr), 'cent'),
			average_arr_in_currency: formatCurrency(Number(item.average_arr), 'cent'),
			start_mrr_in_currency: formatCurrency(Number(item.start_mrr), 'cent'),
			end_mrr_in_currency: formatCurrency(Number(item.end_mrr), 'cent'),
			avg_per_user_in_currency: formatCurrency(
				Number(item.avg_per_user),
				'cent'
			),
			life_time_value_in_currency: formatCurrency(
				Number(item.life_time_value),
				'cent'
			),
			customer_acquisition_cost_in_currency: formatCurrency(
				Number(item.customer_acquisition_cost),
				'cent'
			),
			gained_mrr_in_currency: formatCurrency(Number(item.gained_mrr), 'cent'),
			returning_mrr_in_currency: formatCurrency(
				Number(item.returning_mrr),
				'cent'
			),
			upgrade_mrr_in_currency: formatCurrency(Number(item.upgrade_mrr), 'cent'),
			new_mrr_in_currency: formatCurrency(Number(item.new_mrr), 'cent'),
			churned_mrr_in_currency: formatCurrency(Number(item.churned_mrr), 'cent'),
			downgrade_mrr_in_currency: formatCurrency(
				Number(item.downgrade_mrr),
				'cent'
			),
			cancelled_mrr_in_currency: formatCurrency(
				Number(item.cancelled_mrr),
				'cent'
			)
		}
	})
}

export const formatTimelineData = (
	data: AdminRevenueTimelineDTO[]
): FormattedAdminTimelineDTO[] => {
	const netChange = [] as AdminTimelineDataType[]
	const churnedMrr = [] as AdminTimelineDataType[]
	const gainedMrr = [] as AdminTimelineDataType[]
	const averageMrr = [] as AdminTimelineDataType[]

	data.forEach((item) => {
		netChange.push({ value: item.net_change, date: item.date.value })
		churnedMrr.push({ value: item.churned_mrr, date: item.date.value })
		gainedMrr.push({ value: item.gained_mrr, date: item.date.value })
		averageMrr.push({ value: item.average_mrr, date: item.date.value })
	})

	return [
		{
			id: 'Average MRR',
			data: averageMrr.map((item) => ({
				x: moment(item.date).format('YYYY-MM-DD'),
				y: Number(item.value)
			}))
		},
		{
			id: 'Gained MRR',
			data: gainedMrr.map((item) => ({
				x: moment(item.date).format('YYYY-MM-DD'),
				y: Number(item.value)
			}))
		},
		{
			id: 'Churned MRR',
			data: churnedMrr.map((item) => ({
				x: moment(item.date).format('YYYY-MM-DD'),
				y: Number(item.value)
			}))
		},
		{
			id: 'Net Change',
			data: netChange.map((item) => ({
				x: moment(item.date).format('YYYY-MM-DD'),
				y: Number(item.value)
			}))
		}
	]
}

export const timelineDataFormatter = (value: number) =>
	formatCurrency(value, 'cent')
