import { GTooltip } from 'components/basic-blocks'
import { GButton } from 'components/basic-blocks/g-button'
import { GInput } from 'components/basic-blocks/g-input'
import { ModalTypes } from 'components/basic-blocks/g-modal/types'
import { GRadio } from 'components/basic-blocks/g-radio'
import { useStores } from 'hooks'
import { ChangeEvent, FormEvent, useState } from 'react'
import { RiQuestionLine } from 'react-icons/ri'
import { useUiStore } from 'store'
import { addMemberSchema } from './validations'

export const AddMember = () => {
	const { accountStore, userStore } = useStores()
	const { user } = userStore
	const setModalContent = useUiStore((s) => s.setModalContent)
	const { userAccessLevel, wantsToSeeDemoData } = accountStore

	const renderModal = () => {
		setModalContent({
			title: 'Add Member',
			type: ModalTypes.INFO,
			actionsEnabled: false,
			showIcon: false,
			body: <ModalBody />
		})
	}

	return (
		<GTooltip
			content={
				wantsToSeeDemoData
					? 'This action is disabled when demo data is displayed'
					: userAccessLevel(user!.id) === 'READ'
					? 'Analysts don’t have permission for this action'
					: userAccessLevel(user!.id) === 'WRITE'
					? 'Manager don’t have permission for this action'
					: !accountStore.canAddTeamMember()
					? 'Upgrade your subscription to add more team members'
					: undefined
			}
		>
			<GButton
				variant="contained"
				labelClassName="font-bold"
				disabled={
					wantsToSeeDemoData ||
					!accountStore.canAddTeamMember() ||
					userAccessLevel(user!.id) === 'READ' ||
					userAccessLevel(user!.id) === 'WRITE'
				}
				color="primary"
				size="sm"
				label="Add Member"
				className="text-base py-1.5 px-5"
				onClick={() => renderModal()}
			/>
		</GTooltip>
	)
}

const ModalBody = () => {
	const {
		accountStore,
		userStore: { user }
	} = useStores()
	const { inviteAccountUser, account, isOwnerAccess } = accountStore
	const setModalState = useUiStore((s) => s.setModalState)

	const [addMemberForm, setAddMemberForm] = useState<Record<string, string>>({
		email: '',
		role: 'WRITE'
	})
	const [formErrors, setFormErrors] = useState<null | Partial<{
		email: string
		role: string
	}>>()

	const handleChange = ({ currentTarget: input }: any) => {
		const form = { ...addMemberForm }
		form[input.name] = input.value
		setAddMemberForm(form)
	}

	const validate = () => {
		const options = { abortEarly: false }
		const { error } = addMemberSchema.validate(
			{ email: addMemberForm.email, role: addMemberForm.role },
			options
		)
		if (!error) return null
		const errors: Record<string, string> = {}

		error.details.forEach((item) => {
			errors[item.path[0]] = item.message
		})
		return errors
	}

	const confirmSendInvite = () => {
		const payload = {
			email: addMemberForm.email,
			access_level: addMemberForm.role
		}
		const errors = validate()
		setFormErrors(errors)
		if (errors) return
		inviteAccountUser(account!.id, payload)
		setModalState(false)
	}

	return (
		<>
			<form onSubmit={(e: FormEvent<HTMLFormElement>) => e.preventDefault()}>
				<div className="my-4 w-full">
					<GInput
						error={formErrors?.email}
						name="email"
						label="Email Address"
						placeholder="john.doe@company.com"
						type="email"
						value={addMemberForm.email}
						onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
					/>
				</div>
				<div className="flex flex-col gap-1 w-full">
					<div className="text-sm font-medium text-gray-700">Team role</div>
					<div className="flex items-center">
						<GRadio
							checked={addMemberForm.role === 'WRITE'}
							label="Manager"
							name="role"
							value={'WRITE'}
							onChange={(e) => handleChange(e)}
						/>
						<GTooltip content="Has read and write access.">
							<RiQuestionLine className="text-gray-500 h-3 w-3" />
						</GTooltip>
					</div>
					<div className="flex items-center">
						<GRadio
							checked={addMemberForm.role === 'READ'}
							label="Analyst"
							name="role"
							value={'READ'}
							onChange={(e) => handleChange(e)}
						/>
						<GTooltip content="Has read-only access.">
							<RiQuestionLine className="text-gray-500 h-3 w-3" />
						</GTooltip>
					</div>
					{isOwnerAccess(user!.id) && (
						<>
							<div className="text-sm font-medium text-gray-700 flex items-center gap-1">
								Transfer ownership
								<GTooltip content="Transferring ownership to another member will make them a sole workspace owner, and your role will be reduced to manager.">
									<RiQuestionLine className="text-gray-500 h-3 w-3" />
								</GTooltip>
							</div>
							<GRadio
								checked={addMemberForm.role === 'OWNER'}
								label="Owner"
								name="role"
								value={'OWNER'}
								onChange={(e) => handleChange(e)}
							/>
						</>
					)}
				</div>
			</form>
			<div className="flex justify-end gap-4 mt-5 ">
				<GButton
					label="Cancel"
					className="text-t-default"
					onClick={() => setModalState(false)}
				/>
				<GButton
					variant="contained"
					label="Invite"
					color="primary"
					onClick={() => confirmSendInvite()}
				/>
			</div>
		</>
	)
}
