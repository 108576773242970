import moment from 'moment-timezone'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { RiCloseLine, RiDeleteBin2Line } from 'react-icons/ri'
import { useUiStore } from 'store'
import { ProductAccountDTO } from '../../../../api-models'
import { useStores } from '../../hooks'
import { classNames } from '../../utils'
import { truncateString } from '../../utils/text'
import {
	GButton,
	GInput,
	GListBox,
	GTooltip,
	GTransition
} from '../basic-blocks'
import { GCheckbox } from '../basic-blocks/g-checkbox'
import { GCollapse } from '../basic-blocks/g-collapse/g-collapse'
import Drawer from '../basic-blocks/g-drawer/g-drawer'
import {
	getCreateOfferPayload,
	getOfferUpdatePayload,
	validateCurrentItem
} from './helper'
import {
	PriceBadge,
	PriceReference,
	RenderWorkspaceName,
	SpendBadge
} from './price-reference'
import {
	Format,
	IAdminCreateOffer,
	ICreateOffer,
	ICreateOfferPayload,
	IOfferForm,
	IToggleProps,
	IUpdateOfferPayload,
	getPlanNameByTier,
	offerData,
	toggleButtonsName
} from './types'

export const AdminCreateOffer = ({
	children,
	user,
	account,
	offerRequest,
	updateOffer
}: IAdminCreateOffer) => {
	const {
		adminStore: { createOffer, updateOfferById, loadAccounts }
	} = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	const [dialogOpen, setDialogOpen] = useState(false)
	const [offerList, setOfferList] = useState<ICreateOffer[]>([offerData])
	const [userAccount, setUserAccounts] = useState<ProductAccountDTO[]>([])
	const [userAccountOption, setUserAccountsOption] = useState<Array<string>>([])
	const [selectedAccountId, setSelectedAccountId] = useState<string>(
		account?.id || 'new'
	)
	const [selectedAccount, setSelectedAccount] =
		useState<ProductAccountDTO | null>(null)

	const getUserAccounts = useCallback(async () => {
		const accountData = user.id && (await loadAccounts({ user_id: user.id }))
		if (accountData) {
			setUserAccounts(accountData.data)
			const option = accountData.data.map(
				(account: ProductAccountDTO) => account.id
			)
			option.unshift('new')
			setUserAccountsOption(option)
		}
	}, [user.id])

	const handleToggle = (name: string, value: boolean, index: number) => {
		const updatedOfferList = [...offerList]
		const updatedOffer: ICreateOffer = { ...updatedOfferList[index] }
		updatedOffer['toggleButton'] = {
			...updatedOffer.toggleButton,
			[name]: value
		}
		updatedOfferList[index] = updatedOffer
		setOfferList(updatedOfferList)
	}

	const handleDialogClose = () => {
		setDialogOpen(false)
	}
	const handleDeleteOffer = (index: number) => {
		setOfferList((prevState) => prevState.filter((_, i) => i !== index))
	}

	const handleChange = (
		e: ChangeEvent<HTMLInputElement>,
		index: number,
		discountToggle = false
	) => {
		if (discountToggle) {
			handleDiscountToggle(index)
		} else {
			const name = e.target.name
			const value = e.target.value
			const updatedOfferList = [...offerList] // Create a copy of the array
			const updatedOffer: ICreateOffer = { ...updatedOfferList[index] }
			if (name === 'amount_off') {
				updatedOffer[name] = Number(value)
				updatedOffer['percent_off'] = 0
			} else if (name === 'percent_off') {
				updatedOffer[name] = Number(value)
				updatedOffer['amount_off'] = 0
			} else {
				updatedOffer[name] = value
			}
			updatedOfferList[index] = updatedOffer
			setOfferList(updatedOfferList)
		}
	}
	const handleDiscountToggle = (index: number) => {
		const updatedOfferList = [...offerList]
		const updatedOffer: ICreateOffer = { ...updatedOfferList[index] }
		updatedOffer['percent_off'] = 0
		updatedOffer['amount_off'] = 0
		updatedOfferList[index] = updatedOffer
		setOfferList(updatedOfferList)
	}
	const handleSelectionChange = (
		value: string,
		name: string,
		index: number
	) => {
		const updatedOfferList = [...offerList]
		const updatedOffer: ICreateOffer = { ...updatedOfferList[index] }
		updatedOffer[name] = value
		updatedOfferList[index] = updatedOffer
		setOfferList(updatedOfferList)
	}

	const handleSubmit = async () => {
		let isValid = true
		const updatedOfferList = offerList.map((offer, index) => {
			const error = validateCurrentItem(index, offerList)
			return {
				...offer,
				error: error
			}
		})

		updatedOfferList.forEach((list) => {
			if (list?.error !== null) isValid = false
			if (list?.error) {
				isValid = Object.values(list.error).every((value) => value === '')
			}
		})

		setOfferList(updatedOfferList)
		if (isValid) {
			const payload: ICreateOfferPayload | IUpdateOfferPayload = updateOffer
				? getOfferUpdatePayload(
						updatedOfferList[0],
						selectedAccountId !== 'new' ? selectedAccountId : null
				  )
				: getCreateOfferPayload(
						updatedOfferList,
						user.id,
						selectedAccountId !== 'new' ? selectedAccountId : null,
						offerRequest?.id || null
				  )

			try {
				updateOffer
					? await updateOfferById(
							payload as IUpdateOfferPayload,
							updateOffer.id
					  )
					: await createOffer(payload as ICreateOfferPayload)
				setOfferList([offerData])
				handleDialogClose()
			} catch (e) {
				return
			}
		}
	}

	useEffect(() => {
		if (offerRequest && dialogOpen) {
			setOfferList([
				{
					...offerData,
					spend: offerRequest?.spend || 0,
					price: 0,
					toggleButton: {
						discount:
							offerRequest?.interval !== 'monthly' &&
							Number(offerRequest?.spend) > 99999,
						exp: false,
						trial: false
					},
					percent_off:
						Number(offerRequest?.spend) < 99999
							? 0
							: offerRequest?.interval === 'monthly'
							? 0
							: offerRequest?.interval === 'quarterly'
							? 10
							: 20,
					interval_count: offerRequest?.interval === 'quarterly' ? 3 : 1,
					interval: offerRequest?.interval === 'yearly' ? 'year' : 'month'
				}
			])
		}
	}, [offerRequest, dialogOpen])

	useEffect(() => {
		if (updateOffer) {
			setSelectedAccountId(
				updateOffer.account_id !== null ? updateOffer.account_id : 'new'
			)
			setOfferList([
				{
					...offerData,
					spend: updateOffer?.spend || 0,
					publish: updateOffer?.status === 'READY',
					price: updateOffer.price / 100 || 0,
					interval_count: updateOffer?.interval_count,
					interval: (updateOffer?.interval as any) || 'month',
					days: updateOffer?.trial?.days || 0,
					percent_off: updateOffer?.coupon?.percent_off || 0,
					duration: updateOffer?.coupon?.duration || 'once',
					amount_off: updateOffer?.coupon?.amount_off
						? updateOffer?.coupon?.amount_off / 100
						: 0,
					months: updateOffer?.coupon?.months || 0,
					expired_at: updateOffer?.expired_at
						? moment(moment(updateOffer?.expired_at)).diff(moment(), 'days')
						: 0,
					toggleButton: {
						exp: Boolean(updateOffer?.expired_at),
						discount: Boolean(
							updateOffer?.coupon?.amount_off ||
								updateOffer?.coupon?.percent_off
						),
						trial: Boolean(updateOffer?.trial?.days)
					}
				}
			])
		}
	}, [updateOffer, dialogOpen])

	useEffect(() => {
		const filterAccount = userAccount?.filter(
			(acc) => acc.id === selectedAccountId
		)
		setSelectedAccount(filterAccount[0] || null)
	}, [selectedAccountId, userAccount])

	useEffect(() => {
		dialogOpen && getUserAccounts()
	}, [dialogOpen])

	return (
		<>
			{children && children(() => setDialogOpen(true))}
			<Drawer
				label={`Offer for ${truncateString(user?.name || '', 20)}`}
				isOpen={dialogOpen}
				handleClose={handleDialogClose}
			>
				<GTransition show={true}>
					<div className="w-[685px] mx-auto bg-white p-4">
						<div>
							<div className="bg-gray-100 px-4 py-2">
								<span className="text-t-default font-bold text-base">
									Workspace{' '}
								</span>{' '}
								<br />
								<span className="text-t-secondary text-sm">
									{account?.name || 'New workspace'}
								</span>
							</div>
							<div className="mt-4">
								<div className="flex items-center gap-6 px-4">
									<GListBox<string>
										options={userAccountOption}
										className="w-[300px]"
										placeholder={`${selectedAccountId || 'New workspace'}`}
										value={selectedAccountId}
										onChange={(e) => {
											setSelectedAccountId(e)
										}}
										renderLabel={(list) => (
											<RenderWorkspaceName
												account_id={list}
												account={userAccount}
											/>
										)}
									/>
									{selectedAccount ? (
										<div className="flex gap-6">
											<span>
												{getPlanNameByTier(
													selectedAccount?.billing?.subscription?.plan?.name ||
														''
												)}
											</span>
											<PriceBadge account={selectedAccount} />
											<SpendBadge account={selectedAccount} />
										</div>
									) : (
										<p>Accepting the offer will create a new workspace</p>
									)}
								</div>
							</div>
							<GCollapse
								title="Pricing reference"
								titleClass="text-t-default text-base"
								subtitle={
									<span className="text-t-secondary text-sm">
										Table of standard pricing for Pro plans
									</span>
								}
								className="mt-6"
							>
								<PriceReference />
							</GCollapse>

							<div className="flex justify-between py-2 rounded-lg px-4 bg-gray-50 mt-6">
								<div>
									<span className="font-bold text-t-default">
										{updateOffer ? 'Offer details' : 'Offers'}{' '}
									</span>
									<br />
									<span className="text-t-secondary text-sm">
										{updateOffer
											? 'Update the details on existing offer'
											: 'Create one or multiple offers'}
									</span>
								</div>
								{!updateOffer && (
									<GButton
										size="sm"
										className="h-[25px] w-[90px]"
										labelClassName="font-bold"
										color="primary"
										variant="text"
										onClick={() =>
											setOfferList((prevState) => [...prevState, offerData])
										}
									>
										Add offer
									</GButton>
								)}
							</div>
						</div>
						<div className="flex flex-col justify-between h-[calc(100vh-350px)] mt-4">
							<div>
								{offerList.map((offer, index) => (
									<OfferForm
										key={index}
										offerData={offer}
										index={index}
										handleDeleteOffer={handleDeleteOffer}
										handleSelectionChange={handleSelectionChange}
										handleChange={handleChange}
										handleToggle={handleToggle}
										isUpdatingOffer={Boolean(updateOffer)}
										offerList={offerList}
										selectedAccount={selectedAccount}
									/>
								))}
							</div>
							<div className="flex items-center justify-between min-h-[70px] px-4 border-t border-gray-100">
								<GButton
									className="w-[74px] h-[33px]"
									variant="text"
									label="Close"
									size="md"
									color="neutral"
									labelClassName="font-bold text-t-default"
									onClick={handleDialogClose}
								/>
								<GButton
									className="min-w-[119px] h-[33px]"
									onClick={handleSubmit}
									labelClassName="font-bold"
									label={updateOffer ? 'Update Offer' : 'Create offers'}
									variant="contained"
									size="md"
									color="primary"
									loading={isLoading('ADMIN_ADD_OFFER' || 'ADMIN_UPDATE_OFFER')}
								/>
							</div>
						</div>
					</div>
				</GTransition>
			</Drawer>
		</>
	)
}

const OfferForm = ({
	handleDeleteOffer,
	offerData,
	index,
	handleChange,
	handleSelectionChange,
	handleToggle,
	isUpdatingOffer,
	offerList,
	selectedAccount
}: IOfferForm) => {
	const [minimumPrice, setMinimumPrice] = useState<number | undefined>()
	const [format, setFormat] = useState<Format>('dollar')
	const [isInactive, setIsInactive] = useState<boolean>(
		Boolean(selectedAccount?.status === 'INACTIVE' || !selectedAccount)
	)

	useEffect(() => {
		if (Number(offerList[index].spend) > 99999) {
			setMinimumPrice(Math.trunc(Number(offerList[index].spend) * 0.007 * 0.6))
			handleChange(
				{
					target: {
						value: Math.trunc(Number(offerList[index].spend) * 0.007),
						name: 'price'
					}
				},
				index
			)
		} else {
			setMinimumPrice(undefined)
		}
	}, [offerList[index].spend])

	useEffect(() => {
		if (offerList[index].percent_off) {
			setFormat('percentage')
		}
	}, [])

	useEffect(() => {
		setIsInactive(selectedAccount?.status === 'INACTIVE' || !selectedAccount)
	}, [selectedAccount])

	useEffect(() => {
		if (!isInactive) {
			handleToggle('trial', false, index)
		}
	}, [isInactive])

	return (
		<div
			className={
				'w-full flex pt-4 pb-8 pl-4 border-b border-gray-100 last:border-b-0'
			}
		>
			<div className="w-[48px] flex flex-col items-center gap-2.5">
				<p className="bg-gray-100 px-6 py-4 rounded mt-2 w-[50px] h-[50px]">
					{index + 1}
				</p>
				{!isUpdatingOffer && offerList.length > 1 && (
					<GButton
						size="xxs"
						className="w-8 h-8"
						color="primary"
						variant="text"
						disabled={offerList.length === 1}
						onClick={() => handleDeleteOffer(index)}
					>
						<RiDeleteBin2Line className="ml-0 mr-0 w-4 h-4" color="red" />
					</GButton>
				)}
			</div>
			<div className="w-[580px] pl-6">
				<div className="flex items-center gap-4 mb-4 relative">
					<GInput
						labelClassName="font-bold pb-1"
						type="number"
						label="Monthly ad spend"
						inputClassName="text-base font-medium max-w-[172px] "
						value={offerData.spend}
						name="spend"
						errorPosition="absolute"
						onChange={(e) => handleChange(e, index)}
						error={offerData?.error?.spend}
					/>
					<div className="max-w-[172px]">
						<p className="font-bold pb-1">Billing Period</p>
						<div className="flex items-center gap-[20px] ">
							<GInput
								errorPosition="absolute"
								errorClassName="w-[200px] bottom-[-15px] text-xs text-red-700"
								name="interval_count"
								inputClassName="text-base font-medium max-w-[50px]"
								value={offerData.interval_count}
								onChange={(e) => handleChange(e, index)}
								error={offerData?.error?.interval_count}
							/>
							<GListBox<string>
								className="w-[115px]"
								labelClass="text-base font-medium"
								options={['month', 'year']}
								placeholder="Select interval"
								onChange={(e) => handleSelectionChange(e, 'interval', index)}
								value={offerData.interval}
								renderLabel={(item) => item}
							/>
						</div>
					</div>
					<GInput
						labelClassName="font-bold pb-1"
						type="number"
						label="Price ($)"
						inputClassName="text-base font-medium max-w-[172px]"
						name="price"
						errorPosition="absolute"
						value={offerData.price}
						onChange={(e) => handleChange(e, index)}
						error={offerData?.error?.price}
					/>
					<p
						className={`font-bold text-t-secondary text-base absolute right-[40px] ${
							offerData?.error?.price ? 'top-[75px]' : 'top-[66px]'
						}`}
					>
						{minimumPrice ? `Minimum Price: $ ${minimumPrice}` : ''}
					</p>
				</div>
				{offerData.toggleButton['discount'] && (
					<div className="flex items-center gap-4 mb-4">
						<div className="w-20">
							<p className="font-bold">Discount</p>
							<div className="grid grid-cols-2 text-center divide-x border border-gray-200 rounded-md font-bold cursor-pointer w-[80px] text-sm">
								<span
									className={classNames(
										'py-2',
										format === 'dollar'
											? 'text-primary-500'
											: 'text-t-secondary bg-gray-100'
									)}
									onClick={() => {
										handleChange(null, index, true)
										setFormat('dollar')
									}}
								>
									$
								</span>
								<span
									className={classNames(
										'py-2',
										format === 'percentage'
											? 'text-primary-500'
											: 'text-t-secondary bg-gray-100'
									)}
									onClick={() => {
										handleChange(null, index, true)
										setFormat('percentage')
									}}
								>
									%
								</span>
							</div>
						</div>
						<GInput
							labelClassName="font-bold"
							errorPosition="absolute"
							errorClassName="w-[150px] bottom-[-15px] text-xs text-red-700"
							label={format === 'dollar' ? 'Amount off' : 'Discount off'}
							inputClassName="text-base font-medium w-[100px]"
							value={
								format === 'dollar'
									? offerData.amount_off
									: offerData.percent_off
							}
							name={format === 'dollar' ? 'amount_off' : 'percent_off'}
							onChange={(e) => handleChange(e, index)}
							error={offerData?.error?.amount_off}
						/>
						<div className="w-[297px]">
							<p className="font-bold">Duration</p>
							<div className="flex items-center gap-4">
								<div className="max-w-[239px]">
									<GListBox<string>
										options={['forever', 'once', 'repeating']}
										labelClass="text-base font-medium pb-1"
										className="w-[239px]"
										value={offerData.duration}
										onChange={(e) => {
											handleSelectionChange(e, 'duration', index)
										}}
										renderLabel={(item) =>
											item === 'repeating'
												? 'Multiple Months'
												: item.charAt(0).toUpperCase() + item.slice(1)
										}
									/>
								</div>
								{offerData?.duration === 'repeating' && (
									<GInput
										type="number"
										errorPosition="absolute"
										className="max-w-[50px]"
										inputClassName="text-base font-medium w-[50px]"
										value={offerData.months}
										name="months"
										onChange={(e) => handleChange(e, index)}
										error={offerData?.error?.months}
									/>
								)}
							</div>
						</div>
						<GButton
							size="xxs"
							className="h-7 w-7 mt-4"
							color="primary"
							variant="text"
							onClick={() => handleToggle('discount', false, index)}
						>
							<RiCloseLine className="ml-0 mr-0 w-4 h-4" color="red" />
						</GButton>
					</div>
				)}
				{offerData.toggleButton['trial'] && (
					<div className="flex items-center gap-4 mb-4">
						<GInput
							className="w-full"
							labelClassName="font-bold pb-1"
							errorPosition="absolute"
							inputClassName="text-base font-medium w-full"
							label="Trial duration (days)"
							value={offerData.days}
							name="days"
							onChange={(e) => handleChange(e, index)}
							error={offerData?.error?.days}
						/>
						<GButton
							size="xxs"
							className="h-7 w-7 mt-4"
							color="primary"
							variant="text"
							onClick={() => handleToggle('trial', false, index)}
						>
							<RiCloseLine className="ml-0 mr-0 w-4 h-4" color="red" />
						</GButton>
					</div>
				)}
				{offerData.toggleButton['exp'] && (
					<div className="flex items-center gap-4">
						<GInput
							className="w-full"
							type="number"
							labelClassName="font-bold pb-1"
							errorPosition="absolute"
							inputClassName="text-base font-medium w-full"
							label="Expires in (days)"
							value={offerData.expired_at}
							name="expired_at"
							onChange={(e) => handleChange(e, index)}
							error={offerData?.error?.expired_at}
						/>
						<GButton
							size="xxs"
							className="h-7 w-7 mt-4"
							color="primary"
							variant="text"
							onClick={() => handleToggle('exp', false, index)}
						>
							<RiCloseLine className="ml-0 mr-0 w-4 h-4" color="red" />
						</GButton>
					</div>
				)}
				<div className="flex gap-6 pt-1">
					{Object.keys(offerData.toggleButton).map(
						(e) =>
							!offerData.toggleButton[e as keyof IToggleProps] && (
								<GTooltip
									content={
										e === 'trial' && !isInactive
											? 'This option is available only for New & Inactive workspaces'
											: ''
									}
								>
									<GButton
										key={`${e}-${index}`}
										size="sm"
										className="h-[30px] font-bold disabled:opacity-70"
										color="primary"
										variant="text"
										disabled={e === 'trial' && !isInactive}
										onClick={() => handleToggle(e, true, index)}
									>
										{toggleButtonsName[e]}
									</GButton>
								</GTooltip>
							)
					)}
				</div>
				<div className="pt-4">
					<GCheckbox
						name="publish"
						checked={offerData.publish}
						labelClassName="font-bold text-t-secondary text-base"
						label="Publish Offer to User"
						onChange={(e) =>
							handleChange(
								{
									...e,
									target: { name: 'publish', value: e.target.checked }
								},
								index
							)
						}
					/>
				</div>
			</div>
		</div>
	)
}
