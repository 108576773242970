import { GLoading, GTransition } from 'components/basic-blocks'
import { PageHeader } from 'components/page-header'
import { TabItem } from 'components/tabs/types'

import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { PageTitle } from 'utils/page-title'
import { InfoTab } from './info-tab/info-tab'
import { NotesTab } from './notes-tab'

export const AdminWebsitePage = () => {
	const { assetId } = useParams()
	const navigate = useNavigate()
	const { adminStore } = useStores()
	const { getWebsiteById, asset, getNotes } = adminStore

	const [search] = useSearchParams('tab=info')

	const [total, setTotal] = useState(0)
	const fetchData = async (skip: number) => {
		if (!assetId) return
		const { total } = await getNotes(assetId, {
			type: 'website',
			skip: skip,
			limit: 6
		})
		setTotal(total)
	}

	useEffect(() => {
		;(async () => {
			await fetchData(0)
		})()
	}, [])

	const tabs: Array<TabItem> = [
		{
			key: 'info',
			label: 'Information',
			to: () => `/admin/website/${assetId}/?tab=info`
		},
		{
			key: 'notes',
			label: (
				<div>
					Notes{' '}
					<span
						className={`${
							search.get('tab') === 'notes'
								? 'bg-badge-selected text-badge-text-selected'
								: 'bg-badge-default text-badge-text-t-default'
						} text-sm font-medium mr-2 mx-2.5 px-1.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300`}
					>
						{total || 0}
					</span>
				</div>
			),
			to: () => `/admin/website/${assetId}/?tab=notes`
		}
	]

	const [selectedTab, setSelectedTab] = useState(
		search.get('tab') || tabs[0].key
	)

	const init = async () => {
		if (assetId) {
			try {
				await getWebsiteById(assetId)
				return
			} catch (e) {
				navigate('/admin/websites')
			}
		}
		navigate('/admin/websites')
	}

	useEffect(() => {
		init()
	}, [assetId])

	useEffect(() => {
		const tab = search.get('tab') || tabs[0].key
		setSelectedTab(tab)
	}, [search, assetId])

	if (!asset) {
		return <GLoading />
	}

	return (
		<div className="">
			<PageTitle pageName="Website" />
			<PageHeader
				title={asset.name || 'N/A'}
				tabs={tabs}
				currentTab={selectedTab}
			/>
			<div className="w-full px-4 sm:px-6">
				<GTransition swap show={selectedTab === 'info'}>
					<InfoTab asset={asset} />
				</GTransition>
				<GTransition swap show={selectedTab === 'notes'}>
					<NotesTab topicId={asset?.id} total={total} setTotal={setTotal} />
				</GTransition>
			</div>
		</div>
	)
}
