import { Dialog, Transition } from '@headlessui/react'
import { GSection, GTransition } from 'components/basic-blocks'
import { Fragment } from 'react'
import { Legacy } from './legacy/legacy'
import { ProtectionPlan } from './protection-plan/protection-plan'
import { ActivateAccountSteps } from './steps'
import { TrialStart } from './trial-start/trial-start'
import { ActivateAccountViewProps } from './types'

export const ActivateAccountView = ({
	state,
	open,
	close,
	setStep,
	setProtectionPlan,
	confirmTrial,
	confirmLegacy,
	handleChangePlan
}: ActivateAccountViewProps) => {
	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog as="div" className="relative z-20" onClose={close}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-2xl transform overflow-hidden transition-all">
								<GSection
									subtitle="ClickGUARD Workspace Activation"
									actions={
										<ActivateAccountSteps state={state} setStep={setStep} />
									}
								>
									<GTransition swap show={state.step === 'plan'}>
										{state.legacy || state.legacy_lost ? (
											<Legacy
												lost={state.legacy_lost}
												onConfirm={confirmLegacy}
												loading={state.loading}
											/>
										) : (
											<ProtectionPlan onConfirm={setProtectionPlan} />
										)}
									</GTransition>
									<GTransition swap show={state.step === 'subscription'}>
										<TrialStart
											plan={state.protection_plan!}
											loading={state.loading}
											qualifiedTrial={state.qualified_lead}
											onConfirm={confirmTrial}
											isPlanChange={state.isPlanChange}
											handleChangePlan={handleChangePlan}
											back={() => setStep('plan')}
										/>
									</GTransition>
								</GSection>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
