import { ResponsiveBar } from '@nivo/bar'
import { linearGradientDef } from '@nivo/core'
import { ResponsivePie } from '@nivo/pie'
import { GLoading } from 'components/basic-blocks'
import { PlatformIcon } from 'components/platform-icon'
import {
	RiArrowRightDoubleLine,
	RiEarthLine,
	RiMouseLine
} from 'react-icons/ri'
import { formatCurrency, formatNumber } from 'utils'
import { CFCLead } from '../types'
import Josip from './assets/josip.png'
import Reviews from './assets/reviews.png'
import Quotes from './assets/start-quote-orange.png'

interface CFCReportViewProps {
	lead: CFCLead
	download: () => void
	startTrial: () => void
}

export const CFCReportView = ({ lead, startTrial }: CFCReportViewProps) => {
	const { accountStats, campaignsStats, keywordsStats } = lead.report

	const elevatedRiskPercentage = Number(accountStats.estimated_fraud_rate) * 100
	const wastedSpend = accountStats.estimated_fraud_spend
	const legitSpend =
		accountStats.total_cost - accountStats.estimated_fraud_spend
	const wastedSpendPercentage = formatNumber(
		Number((wastedSpend / accountStats.total_cost) * 100)
	)
	const invalidClicks = Number(
		accountStats.invalid_click_rate < accountStats.estimated_fraud_rate
			? Math.floor(accountStats.total_clicks * accountStats.invalid_click_rate)
			: Math.floor(
					accountStats.total_clicks *
						(accountStats.invalid_click_rate -
							accountStats.estimated_fraud_rate)
			  )
	)
	const estimatedFraudClicks = Number(
		Math.floor(accountStats.total_clicks * accountStats.estimated_fraud_rate)
	)
	const legitClicks = Number(
		accountStats.total_clicks -
			(accountStats.invalid_click_rate < accountStats.estimated_fraud_rate
				? Math.floor(
						accountStats.total_clicks * accountStats.invalid_click_rate
				  )
				: Math.floor(
						accountStats.total_clicks *
							(accountStats.invalid_click_rate -
								accountStats.estimated_fraud_rate)
				  )) -
			Math.floor(accountStats.total_clicks * accountStats.estimated_fraud_rate)
	)
	const unwantedClicksPercentage = formatNumber(
		Number((invalidClicks + estimatedFraudClicks) / accountStats.total_clicks) *
			100
	)

	const pieData = [
		{
			id: 'legit-spend',
			label: 'Legitimate ad spend',
			value: legitSpend
		},
		{
			id: 'wasted-spend',
			label: 'Estimated wasted spend',
			value: wastedSpend
		}
	]

	const barData = [
		{
			id: 'clicks',
			'Estimated fraudulent clicks': estimatedFraudClicks,
			'Invalid clicks': invalidClicks,
			'Legitimate clicks': legitClicks
		}
	]

	if (!lead || !lead.report) {
		return <GLoading />
	}

	return (
		<div id="report-container">
			<section
				className="dark py-16"
				style={{
					backgroundImage:
						'linear-gradient(106deg, #240736 0.39%, #072536 100%)'
				}}
			>
				<div className="flex flex-row gap-4 items-center justify-center mb-24">
					<div className="flex gap-4 py-4 px-8 bg-gray-100 rounded-md text-t-default">
						<RiEarthLine className="h-6 w-6" />
						<span className="text-lg font-medium">{lead.domain}</span>
					</div>

					<div className="flex gap-4 py-4 px-8 bg-gray-100 rounded-md text-t-default">
						<PlatformIcon platform="GADS" />
						<span className="text-lg font-medium">{lead.ads?.name}</span>
					</div>
				</div>

				<div className="flex flex-col items-center justify-center gap-6">
					<strong className="text-5xl font-bold text-t-heading">
						Your estimated <span className="text-red-500">waste</span> in
						advertising budget
					</strong>
					<strong className="text-8xl text-t-heading font-extrabold">
						{formatCurrency(accountStats.estimated_fraud_spend, 'dollar')}
					</strong>
				</div>

				<div className="flex flex-row items-center justify-center divide-x-2 border-gray-500 mt-16 mb-8">
					<div className="flex flex-col items-center justify-center gap-6 w-80 pr-8">
						<span className="text-t-default text-2xl">Elevated risk</span>
						<span className="text-t-heading font-extrabold text-6xl">
							{elevatedRiskPercentage > 0 ? '+' : ''}
							{formatNumber(elevatedRiskPercentage)}%
						</span>
					</div>

					<div className="flex flex-col items-center justify-center gap-6 px-20">
						<span className="text-t-default text-2xl">
							Industry fraud rate risk
						</span>
						<span className="text-t-heading font-extrabold text-6xl">
							{Math.floor(accountStats.industry_risk * 100)}%
						</span>
					</div>

					<div className="flex flex-col items-center justify-center gap-6 w-80 pl-8">
						<span className="text-t-default text-2xl">Global fraud rate</span>
						<span className="text-t-heading font-extrabold text-6xl">
							{Math.floor(accountStats.global_risk * 100) +
								(accountStats.global_risk === accountStats.industry_risk
									? 2
									: 0)}
							%
						</span>
					</div>
				</div>

				<div className="flex items-center justify-center my-16">
					<button
						className="cursor-pointer py-4 px-8 flex items-center gap-2 rounded-md text-base border border-amber-400 bg-[#FBC251]"
						onClick={startTrial}
					>
						<RiArrowRightDoubleLine className="w-6 h-6 text-[#080736]" />
						<span className="text-2xl text-[#080736] font-bold">
							Save your budget
						</span>
					</button>
				</div>

				<div className="flex flex-col items-center justify-center text-t-default gap-4">
					<RiMouseLine className="h-8 w-8" />
					<span className="text-lg">Scroll to learn more</span>
				</div>
			</section>

			<section
				className="flex flex-col items-center justify-center screen:py-32 print:py-12"
				style={{
					backgroundImage: 'linear-gradient(151deg, #FCFCFD 0%, #F7F7F8 100%);'
				}}
			>
				<div className="max-w-[1140px]">
					<div className="flex flex-col items-center justify-center">
						<strong className="text-t-heading text-3xl font-bold">
							Your Ad Spend vulnerability to Click Fraud
						</strong>
						<span className="text-t-default text-xl block text-center max-w-5xl mt-2">
							The metrics below offer deeper insights into the non-human and
							fraudulent traffic affecting your ads to help you identify sources
							of vulnerability in your PPC campaigns.
						</span>
					</div>

					<div className="flex flex-col items-center justify-center my-24">
						<div className="flex flex-col items-center justify-center">
							<strong className="text-7xl font-bold text-t-heading">
								<span className="text-red-500">{wastedSpendPercentage}%</span>{' '}
								Wasted Spend
							</strong>
							<span className="text-t-default text-xl mt-2">
								This is the percentage of your ad budget that is lost to click
								fraud and invalid clicks.
							</span>
						</div>

						<div className="h-80 w-96 my-6">
							<ResponsivePie
								data={pieData}
								padAngle={4}
								enableArcLabels={false}
								enableArcLinkLabels={false}
								defs={[
									linearGradientDef('legitGradient', [
										{ offset: 0, color: 'rgba(34, 197, 94, 0.90)' },
										{ offset: 100, color: 'rgba(19, 110, 53, 0.90)' }
									]),
									linearGradientDef('wastedGradient', [
										{ offset: 0, color: 'rgba(218, 11, 56, 0.90)' },
										{ offset: 100, color: 'rgba(139, 14, 41, 0.90)' }
									])
								]}
								fill={[
									{ match: { id: 'wasted-spend' }, id: 'wastedGradient' },
									{ match: { id: 'legit-spend' }, id: 'legitGradient' }
								]}
								tooltip={() => <></>}
								cornerRadius={4}
							/>
						</div>

						<div className="text-t-default flex items-center justify-center gap-8">
							<div className="flex flex-col items-center justify-center">
								<div className="flex items-center gap-1">
									<div className={'h-3 w-3 rounded-full bg-red-500'} />
									<span className="text-lg">Estimated wasted spend</span>
								</div>
								<span className="text-xl font-bold">
									{formatCurrency(wastedSpend, 'dollar')}
								</span>
							</div>

							<div className="flex flex-col items-center justify-center">
								<div className="flex items-center gap-1">
									<div className={'h-3 w-3 rounded-full bg-green-500'} />
									<span className="text-lg">Legitimate ad spend</span>
								</div>
								<span className="text-xl font-bold">
									{formatCurrency(legitSpend, 'dollar')}
								</span>
							</div>
						</div>
					</div>

					<div className="flex flex-col items-center print:pt-20">
						<div className="flex flex-col items-center justify-center">
							<strong className="text-6xl font-bold text-t-heading">
								<span className="text-red-500">
									{unwantedClicksPercentage}%
								</span>{' '}
								Unwanted Clicks
							</strong>
							<span className="text-t-default text-xl mt-2">
								Unwanted clicks include fraudulent clicks and invalid clicks
								from users outside your target market.
							</span>
						</div>
						<div className="h-10 w-[512px] my-8">
							<ResponsiveBar
								data={barData}
								keys={[
									'Estimated fraudulent clicks',
									'Invalid clicks',
									'Legitimate clicks'
								]}
								indexBy="id"
								groupMode="stacked"
								layout="horizontal"
								enableGridX={false}
								enableGridY={false}
								enableLabel={false}
								borderWidth={0}
								innerPadding={2}
								defs={[
									linearGradientDef('legitGradient', [
										{ offset: 0, color: 'rgba(34, 197, 94, 0.90)' },
										{ offset: 100, color: 'rgba(19, 110, 53, 0.90)' }
									]),
									linearGradientDef('fraudGradient', [
										{ offset: 0, color: 'rgba(218, 11, 56, 0.90)' },
										{ offset: 100, color: 'rgba(139, 14, 41, 0.90)' }
									]),
									linearGradientDef('invalidGradient', [
										{ offset: 0, color: 'rgba(107, 114, 128, 0.90)' },
										{ offset: 100, color: 'rgba(52, 55, 65, 0.90)' }
									])
								]}
								fill={[
									{
										match: { id: 'Estimated fraudulent clicks' },
										id: 'fraudGradient'
									},
									{ match: { id: 'Invalid clicks' }, id: 'invalidGradient' },
									{ match: { id: 'Legitimate clicks' }, id: 'legitGradient' }
								]}
								tooltip={() => <></>}
							/>
						</div>

						<div className="text-t-default flex items-center justify-center gap-8">
							<div className="flex flex-col items-center justify-center">
								<div className="flex items-center gap-1">
									<div className={'h-3 w-3 rounded-full bg-red-500'} />
									<span className="text-lg">Estimated fraudulent clicks</span>
								</div>
								<span className="text-xl font-bold">
									{estimatedFraudClicks}
								</span>
							</div>

							<div className="flex flex-col items-center justify-center">
								<div className="flex items-center gap-1">
									<div className={'h-3 w-3 rounded-full bg-[#69707d]'} />
									<span className="text-lg">Invalid clicks</span>
								</div>
								<span className="text-xl font-bold">{invalidClicks}</span>
							</div>

							<div className="flex flex-col items-center justify-center">
								<div className="flex items-center gap-1">
									<div className={'h-3 w-3 rounded-full bg-green-500'} />
									<span className="text-lg">Legitimate clicks</span>
								</div>
								<span className="text-xl font-bold">{legitClicks}</span>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-col items-center justify-center gap-6 screen:my-24 print:mb-24 print:mt-8">
					<div className="flex flex-col items-center justify-center">
						<strong className="text-6xl font-bold text-t-heading">
							Vulnerable campaigns
						</strong>
						<span className="text-t-default text-xl mt-2">
							These are your top campaigns that are susceptible to click fraud
							attacks based on the amount of targeted fraudulent activity.
						</span>
					</div>
					<div className="flex items-center justify-center divide-x-2 border-gray-500 text-t-default mt-6">
						{campaignsStats.map((campaign: any) => (
							<div className="px-10 w-[250px]">
								<strong className="font-bold text-xl">
									{campaign.campaign_name}
								</strong>

								<div className="mt-3">
									<div className="flex justify-between">
										<span>Fraud rate</span>
										<span>
											{formatNumber(
												Number(campaign.estimated_fraud_rate * 100)
											)}
											%
										</span>
									</div>
									<div className="flex justify-between">
										<span>Traffic share</span>
										<span>
											{formatNumber(
												Number(
													(campaign.total_clicks / accountStats.total_clicks) *
														100
												) + 1
											)}
											%
										</span>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>

				<div className="flex flex-col items-center justify-center gap-6 print:mb-8">
					<div className="flex flex-col items-center justify-center">
						<strong className="text-6xl font-bold text-t-heading">
							Vulnerable keywords
						</strong>
						<span className="text-t-default text-xl mt-2">
							These are your top keywords that are susceptible to click fraud
							attacks based on the amount of targeted fraudulent activity.
						</span>
					</div>
					<div className="flex items-center justify-center divide-x-2 border-gray-500 text-t-default mt-6">
						{keywordsStats.map((keyword: any) => (
							<div className="px-10 w-[250px]">
								<strong className="font-bold text-xl">{keyword.name}</strong>

								<div className="mt-3">
									<div className="flex justify-between">
										<span>Fraud rate</span>
										<span>
											{formatNumber(Number(keyword.estimated_fraud_rate * 100))}
											%
										</span>
									</div>
									<div className="flex justify-between">
										<span>Traffic share</span>
										<span>
											{Math.round(
												Number(
													(keyword.total_clicks / accountStats.total_clicks) *
														100
												)
											) === 0
												? Number(
														(
															(keyword.total_clicks /
																accountStats.total_clicks) *
																100 +
															Math.random() * (2 - 0.2) +
															0.2
														).toFixed(1)
												  )
												: Math.round(
														Number(
															(keyword.total_clicks /
																accountStats.total_clicks) *
																100
														)
												  )}
											%
										</span>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>

			<section className="flex flex-col items-center justify-center bg-[#E0E7FF] text-t-heading pt-20 pb-12">
				<div className="max-w-[1080px]">
					<strong className="text-6xl font-bold block text-center">
						The click fraud prevention tool that thousands of businesses trust
					</strong>
					<div className="flex flex-row items-center justify-center mt-10">
						<div className="flex flex-col border-r-[1px] border-solid border-r-t-tertiary py-8 pr-32">
							<div className="flex flex-col gap-2 pb-4 border-b-t-tertiary border-b-[1px] border-solid">
								<strong className="text-5xl font-bold block">
									$ 5,680,554
								</strong>
								<span className="text-xl">
									Ad spend saved over the last year
								</span>
							</div>
							<div className="flex flex-col gap-2 pt-6 pb-4 border-b-t-tertiary border-b-[1px] border-solid">
								<strong className="text-5xl font-bold">27,555,254,733</strong>
								<span className="text-xl">Bad Clicks successfully blocked</span>
							</div>
							<div className="flex flex-col gap-2 pt-6">
								<strong className="text-5xl font-bold">#1</strong>
								<span className="text-xl">Click fraud protection</span>
							</div>
						</div>

						<div className="flex flex-col flex-1 pl-6">
							<div className="flex flex-col w-[576px]">
								<img alt="quotes" src={Quotes} width={75} height={50} />
								<span className="max-w-[507px] block text-2xl pl-20 pt-4">
									Their customer support is top-notch, helping me navigate
									through the system features... The system itself is very
									useful, saving me a monthly budget that would usually go to
									waste due to fraudulent traffic, my ROI increased at least 7x
									from the moment I started using ClickGUARD for one of my
									clients.
								</span>
								<img
									alt="quotes"
									src={Quotes}
									width={75}
									height={50}
									className="rotate-180 ml-auto"
								/>
							</div>

							<div className="flex items-center gap-4 pl-20">
								<img alt="name" src={Josip} width={107} height={107} />
								<div className="flex flex-col gap-2 text-t-default">
									<strong className="font-bold text-2xl">Josip Mešković</strong>
									<a
										href="https://uk.trustpilot.com/reviews/658d71e267f765ceb4b5a41d"
										className="text-xl underline italic"
									>
										Trust Pilot Review
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section
				className="flex items-center justify-center py-24"
				style={{
					backgroundImage: 'linear-gradient(151deg, #FCFCFD 0%, #F7F7F8 100%);'
				}}
			>
				<div className=" flex flex-col items-center justify-center max-w-[1230px]">
					<strong className="text-t-heading font-bold text-5xl">
						And don't just take our word for it
					</strong>
					<span className="text-t-secondary text-5xl mt-4 font-medium">
						Here's what 100s of independent reviews say
					</span>
					<img alt="review" src={Reviews} className="mt-10 mb-14" />
					<strong className="text-t-heading font-extrabold text-6xl block text-center">
						Fake clicks drain your budget. Proactively block non-human and
						fraudulent traffic on Google and Meta.
					</strong>
					<div className="flex items-center justify-center mt-14">
						<button
							className="cursor-pointer py-4 px-8 flex items-center gap-2 rounded-md text-base border border-amber-400 bg-[#FBC251]"
							onClick={startTrial}
						>
							<RiArrowRightDoubleLine className="w-6 h-6 text-[#080736]" />
							<span className="text-2xl text-[#080736] font-bold">
								Save your budget
							</span>
						</button>
					</div>
				</div>
			</section>
		</div>
	)
}
