import { ProductAccountDTO } from 'api-models'
import { GButton } from 'components/basic-blocks/g-button'
import { GDialog } from 'components/basic-blocks/g-dialog'
import { GInput } from 'components/basic-blocks/g-input'
import { GListBox } from 'components/basic-blocks/g-listbox'
import { PlanNames } from 'config'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { useAssetStore, useUiStore } from 'store'
import { GRadio } from '../basic-blocks/g-radio'
import { RequestOfferProps, RequestOfferState } from './types'

export const RequestOffer = ({
	open,
	account,
	onClose,
	onFinish,
	offerRequest,
	disabledFor
}: RequestOfferProps) => {
	const { billingStore, accountStore } = useStores()
	const isLoading = useUiStore((s) => s.isLoading)
	const { demoMode } = useAssetStore()

	const [initialAccount, setInitialAccount] =
		useState<ProductAccountDTO | null>(account || null)
	const [data, setData] = useState<RequestOfferState>({
		account: account || null,
		spend: offerRequest?.spend || null,
		interval: (offerRequest?.interval as any) || 'yearly',
		notes: offerRequest?.note || null
	})

	const [errors, setErrors] = useState({
		clicks: false
	})

	const updateSubscription = (account: ProductAccountDTO | null) => {
		setData({ ...data, account })
	}

	const updateClicks = (val: string) => {
		const spend = Number(val)
		setData({ ...data, spend })
		setErrors({ ...errors, clicks: false })
	}

	const updateNotes = (notes: string) => {
		setData({ ...data, notes })
	}

	const updateInterval = (interval: string) => {
		if (interval === 'monthly') {
			setData({ ...data, interval: 'monthly' })
		}
		if (interval === 'yearly') {
			setData({ ...data, interval: 'yearly' })
		}
		if (interval === 'quarterly') {
			setData({ ...data, interval: 'quarterly' })
		}
	}

	const submit = async () => {
		if (Number(data.spend) <= 0) {
			setErrors({ ...errors, clicks: true })
			return
		}

		const req = await billingStore.createOfferRequest({
			account_id: data.account ? data.account.id : null,
			spend: Number(data.spend),
			interval: data.interval,
			notes: data.notes
		})

		if (req) {
			if (onFinish) onFinish(req)
			onClose()
		}
	}

	useEffect(() => {
		if (offerRequest) {
			const acc = accountStore.accounts.find(
				(acc) => acc.id === offerRequest?.account_id
			)
			acc && setInitialAccount(acc)
			setData({
				account: acc || null,
				spend: offerRequest?.spend || 0,
				interval: (offerRequest?.interval as any) || 'yearly',
				notes: offerRequest?.note || null
			})
		}
	}, [offerRequest])

	useEffect(() => {
		if (open) {
			billingStore.initBilling()
		}
	}, [open])

	useEffect(() => {
		if (account) {
			setInitialAccount(account)
			setData({
				...data,
				account
			})
		}
	}, [account])

	return (
		<GDialog
			open={open}
			onClose={onClose}
			showClose={false}
			lineBreak={true}
			showFullSubTitle={true}
			className={'min-w-[595px] border border-card-border'}
			title={
				<p className="text-t-default font-bold text-xl -mb-1">
					{offerRequest ? 'Update request' : 'Request a quote'}
				</p>
			}
			subtitle={
				!offerRequest && (
					<div className="text-[14px] mb-2 text-t-secondary">
						Need a custom quote? Submit a request and our staff will prepare an
						offer for you shortly.
					</div>
				)
			}
		>
			<fieldset className="mt-6">
				<div className="space-y-4">
					{disabledFor !== 'EXISTING' && !demoMode && (
						<>
							<div className="flex items-center">
								<GRadio
									className={
										offerRequest?.account_id
											? 'opacity-50'
											: 'border-primary-300'
									}
									label={'I want to upgrade existing workspace'}
									labelClass="font-bold text-t-default text-base"
									value="existing"
									checked={data.account !== null}
									disabled={!!disabledFor}
									onChange={() => updateSubscription(accountStore.accounts[0])}
								/>
							</div>
							<GListBox<ProductAccountDTO>
								options={accountStore.accounts}
								className="ml-6"
								onChange={(a) => updateSubscription(a)}
								placeholder="Select a workspace"
								value={data.account || (initialAccount as any)}
								disabled={!accountStore.accounts || !!disabledFor}
								renderLabel={(acc) => (
									<div className="flex items-center space-x-2 text-base  font-medium">
										<span className="text-t-secondary truncate max-w-[350px]">
											{acc.name || 'N/A'}
										</span>
										<span className="text-t-secondary">
											(
											{acc.status === 'INACTIVE'
												? 'Inactive'
												: PlanNames[
														acc.billing?.subscription?.plan?.tier || ''
												  ] || 'N/A'}
											)
										</span>
									</div>
								)}
							/>
						</>
					)}
					{disabledFor !== 'NEW' && (
						<div className="flex items-center">
							<GRadio
								className={
									offerRequest && !offerRequest?.account_id ? 'opacity-50' : ''
								}
								label={'I want to create a new workspace'}
								labelClass="font-bold text-t-default text-base"
								value="new"
								disabled={!!disabledFor}
								checked={data.account === null}
								onChange={() => updateSubscription(null)}
							/>
						</div>
					)}
				</div>
			</fieldset>
			<fieldset className="mt-6">
				<div className="w-full">
					<GInput
						type="text"
						value={data.spend || ''}
						inputClassName="text-base w-full font-medium"
						label="What monthly budget would you like to protect?"
						placeholder="25,000"
						prefix="$"
						prefixClassName="text-base top-[8px] left-[19px] font-medium"
						labelClassName="font-bold pb-2"
						onChange={(e) => updateClicks(e.target.value)}
						error={errors.clicks ? 'Set your protected budget' : undefined}
					/>
				</div>
			</fieldset>
			<fieldset className="mt-6">
				<p className="font-bold text-t-default text-base">
					How often would you like to be billed?
				</p>
				<div className="flex mt-4">
					<div className="flex flex-1 items-center">
						<GRadio
							label={'Monthly'}
							labelClass="font-bold text-t-default text-base"
							value="monthly"
							checked={data.interval === 'monthly'}
							onChange={() => updateInterval('monthly')}
						/>
					</div>
					<div className="flex flex-1 items-center">
						<GRadio
							label={'Quarterly'}
							labelClass="font-bold text-t-default text-base"
							value="quarterly"
							checked={data.interval === 'quarterly'}
							onChange={() => updateInterval('quarterly')}
						/>
					</div>
					<div className="flex flex-1 items-center">
						<GRadio
							label={'Yearly'}
							labelClass="font-bold text-t-default text-base"
							value="yearly"
							checked={data.interval === 'yearly'}
							onChange={() => updateInterval('yearly')}
						/>
					</div>
				</div>
			</fieldset>
			<fieldset className="mt-6">
				<div>
					<GInput
						type="text"
						value={data.notes || ''}
						labelClassName="font-bold text-t-default text-base mb-2"
						inputClassName="text-t-default w-full font-medium  placeholder:text-t-default "
						label="Something else worth noting?"
						placeholder="Additional comments"
						onChange={(e) => updateNotes(e.target.value)}
					/>
				</div>
			</fieldset>
			<div className="mt-5 sm:mt-6 flex justify-between">
				<GButton
					labelClassName="font-bold text-t-default"
					className="h-[36px] w-[100px]"
					onClick={onClose}
				>
					Cancel
				</GButton>
				<GButton
					label="Send request"
					labelClassName="font-bold"
					className="h-[36px] w-[138px]"
					color="primary"
					onClick={submit}
					loading={isLoading('USER_ADD_OFFER_REQUEST')}
				/>
			</div>
		</GDialog>
	)
}
