import { useAssetStore } from '../../../../../store'
import { GSection, GTooltip } from '../../../../../components/basic-blocks'
import { RiQuestionLine } from 'react-icons/ri'
import { generateReportTitle } from '../types'
import { capitalizeFirstLetter } from '../../../../../utils/text'
import moment from 'moment'
import { useShallow } from 'zustand/react/shallow'
import { NavLink } from 'react-router-dom'

export const UpcomingReports = () => {
	const { asset, reportRequests } = useAssetStore(
		useShallow((s) => ({
			asset: s.asset,
			reportRequests: s.reportRequests
		}))
	)
	return (
		<GSection>
			<div className="h-[175px]">
				<div className="flex items-center justify-between w-[334px] pb-4">
					<div className="flex gap-x-2 items-center text-xl font-bold">
						Upcoming reports
						<GTooltip
							className="font-normal"
							content="This shows the upcoming reports that you have scheduled."
						>
							<RiQuestionLine />
						</GTooltip>
					</div>
					<NavLink
						to={`/workspace/${asset?.account_id}/asset/${asset?.id}/reports?tab=scheduled`}
						className="text-primary-600 text-lg"
					>
						Configure
						<hr className="border-primary-600 relative bottom-[6px]" />
					</NavLink>
				</div>
				{reportRequests.length === 0 ? (
					<div className="flex items-center justify-center pt-8">
						<p className="text-t-secondary">No upcoming reports available</p>
					</div>
				) : (
					<div className="flex flex-col gap-3">
						{reportRequests
							.filter((f) => f.status === 'ACTIVE')
							.map((s) => {
								return (
									<div
										key={s.id}
										className="text-lg text-t-secondary flex items-center justify-between"
									>
										<p>{generateReportTitle(s)}</p>
										<p>
											{capitalizeFirstLetter(
												'In ' + moment(s.scheduled_for).fromNow(true)
											)}
										</p>
									</div>
								)
							})}
					</div>
				)}
			</div>
		</GSection>
	)
}
