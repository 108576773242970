import {
	DropdownMenuPortal,
	DropdownMenuSubContent
} from '@radix-ui/react-dropdown-menu'

import { GInput } from 'components/basic-blocks'
import { OptionSubContentProps } from '../../../context'

const key = 'name'

export const NameOption = {
	key,
	label: 'Name',
	subContent: ({ handleSubItemChange, mapState }: OptionSubContentProps) => (
		<DropdownMenuPortal>
			<DropdownMenuSubContent
				className="bg-white rounded border border-gray-200 p-4"
				sideOffset={20}
			>
				<GInput
					name={key}
					value={mapState.get(key)}
					onClick={(e) => e.stopPropagation()}
					onChange={(e) =>
						handleSubItemChange({ event: e, key, value: e.target.value })
					}
					placeholder="John Smith"
				/>
			</DropdownMenuSubContent>
		</DropdownMenuPortal>
	)
}
