import { ChevronDownIcon } from '@heroicons/react/solid'
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'
import Select from 'react-select'
import { twMerge } from 'tailwind-merge'
import { GButton } from '../../g-button'
import { useThemeStore } from '../../../../layout/navbar/store'
import { Dispatch, SetStateAction } from 'react'

interface GNewPaginationProps {
	itemsCount?: number
	pageSize: number
	currentPage: number
	pageListener?: (page: number) => void
	onPageChange?: (page: number) => void
	paginationClassName?: string
	changePageSize?: Dispatch<SetStateAction<number>>
}

const pageSizeOptions = [
	{ value: 10, label: '10' },
	{ value: 20, label: '20' },
	{ value: 50, label: '50' },
	{ value: 100, label: '100' },
	{ value: 250, label: '250' }
]

export const GNewPagination = ({
	itemsCount = 0,
	pageSize,
	currentPage,
	pageListener,
	onPageChange,
	paginationClassName,
	changePageSize
}: GNewPaginationProps) => {
	const { theme } = useThemeStore()
	const pagesCount = Math.ceil(itemsCount / pageSize)
	const firstItem = (currentPage - 1) * pageSize + 1
	const lastItem =
		firstItem + pageSize - 1 >= itemsCount
			? itemsCount
			: firstItem + pageSize - 1

	if (pagesCount === 1) return null

	return (
		<nav
			className={twMerge(
				'mt-6 -mb-1 flex items-center justify-between',
				paginationClassName
			)}
			aria-label="Pagination"
		>
			<div className="flex items-center flex-row gap-4">
				{changePageSize && (
					<div className="flex items-center gap-1 text-t-default">
						<span className="text-sm text-t-default">Items per page</span>

						<Select
							defaultValue={pageSizeOptions[0]}
							value={pageSizeOptions.find((o) => o.value === pageSize)}
							options={pageSizeOptions}
							menuPlacement="top"
							isSearchable={false}
							isClearable={false}
							onChange={(o) => changePageSize(o?.value || 10)}
							components={{
								IndicatorSeparator: () => null,
								DropdownIndicator: () => <ChevronDownIcon className="h-3 w-3" />
							}}
							styles={{
								control: (base) => ({
									...base,
									minHeight: 24,
									maxHeight: 24,
									backgroundColor: theme === 'dark' ? '#1c1f2b' : 'white',
									color: theme === 'dark' ? 'white' : 'black',
									borderColor: '#32384d',
									padding: 0
								}),
								valueContainer: (base) => ({
									...base,
									padding: '0 2px',
									fontSize: 12
								}),
								indicatorsContainer: (base) => ({
									...base,
									padding: '0 2px'
								}),
								dropdownIndicator: (base) => ({
									...base,
									padding: '0 2px',
									fontSize: 12
								}),
								clearIndicator: (base) => ({
									...base,
									paddingTop: 0,
									paddingBottom: 0
								}),
								option: (styles, { isDisabled, isSelected }) => {
									return {
										...styles,
										cursor: isDisabled ? 'not-allowed' : 'default',
										fontSize: 12,
										backgroundColor:
											theme === 'dark'
												? isSelected
													? '#32384d'
													: '#1c1f2b'
												: isSelected
												? '#5a8dfd'
												: 'white',
										padding: 2,
										color: isSelected
											? 'white'
											: theme === 'dark'
											? 'white'
											: 'black',
										display: 'flex',
										':hover': {
											backgroundColor: isSelected
												? '#5a8dfd'
												: theme === 'dark'
												? '#2d3748'
												: '#f3f4f6'
										},
										justifyContent: 'center',
										alignItems: 'center'
									}
								}
							}}
						/>
					</div>
				)}
				<p className="text-sm text-t-default">
					Showing <span className="font-medium">({firstItem}</span> to{' '}
					<span className="font-medium">{lastItem})</span> of
					<span className="font-medium"> {itemsCount} </span> results
				</p>
			</div>
			<div className="flex gap-4">
				<GButton
					onClick={() => {
						if (onPageChange) onPageChange(currentPage - 1)
						if (pageListener)
							pageListener(
								(currentPage - 1 === 1 ? 0 : currentPage - 2) * pageSize
							)
					}}
					size="xs"
					disabled={currentPage === 1}
					className="py-1 px-4 text-t-default text-base font-bold border-gray-200 disabled:text-t-default disabled:bg-gray-100"
					icon={RiArrowLeftSLine}
					iconPlacement="left"
				>
					Previous
				</GButton>
				<GButton
					onClick={() => {
						if (onPageChange) onPageChange(currentPage + 1)
						if (pageListener) pageListener(currentPage * pageSize)
					}}
					size="xs"
					disabled={currentPage === pagesCount}
					className="py-1 px-4 text-t-default text-base font-bold border-gray-200 disabled:text-t-default disabled:bg-gray-100"
					icon={RiArrowRightSLine}
					iconPlacement="right"
				>
					Next
				</GButton>
			</div>
		</nav>
	)
}
