import {
	AdAccountDTO,
	FacebookAdsCampaignDTO,
	GoogleAdsCampaignDTO
} from 'api-models'

import { GDateRange, GLazyLoading } from 'components/basic-blocks'
import { Notices } from 'components/notice/notices'
import { PageHeader } from 'components/page-header'
import { Breadcrumbs } from 'layout/navbar/breadcrumbs/breadcrumbs'
import moment from 'moment'
import { lazy, Suspense, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
	useAccountStore,
	useAdminStore,
	useAssetStore,
	useUiStore
} from 'store'
import { getEntityName } from 'utils/get-entity-name'
import { PageTitle } from 'utils/page-title'
import { useShallow } from 'zustand/react/shallow'
import { useAsset } from '../asset-base'
import { AnalyticsTableFilterProvider } from './context'

const Analytics = lazy(() =>
	import('./view')
		.then((module) => {
			return { default: module.Analytics }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)

export const AnalyticsPage = () => {
	const { accountId, assetId } = useAsset()
	const isLoading = useUiStore((s) => s.isLoading)
	const impersonation = useAdminStore((s) => s.impersonation)
	const { asset, adAccounts, refreshAdAccounts, demoMode, campaigns } =
		useAssetStore(
			useShallow((s) => ({
				asset: s.asset,
				adAccounts: s.adAccounts,
				refreshAdAccounts: s.refreshAdAccounts,
				demoMode: s.demoMode,
				campaigns: s.campaigns
			}))
		)
	const { account, isInSetup, hasFeature } = useAccountStore(
		useShallow((s) => ({
			account: s.account,
			isInSetup: s.isInSetup,
			hasFeature: s.hasFeature
		}))
	)
	const [searchParams, setSearchParams] = useSearchParams()
	const campaignId = searchParams.get('breadCampaign')
	const [adAccount, setAdAccount] = useState<AdAccountDTO | null>(null)
	const [selectedCampaign, setSelectedCampaign] = useState<
		GoogleAdsCampaignDTO | FacebookAdsCampaignDTO | null
	>(null)
	const [dateRange, setDateRange] = useState({
		from: moment().subtract(30, 'days').startOf('day').toDate(),
		to: moment().startOf('hour').toDate()
	})

	const [dateLoading, setDateLoadingState] = useState(false)

	useEffect(() => {
		refreshAdAccounts()
	}, [asset, impersonation])

	useEffect(() => {
		const preSelectedAccountId = searchParams.get('adAccount')
		const matchedAccount =
			adAccounts.find((acc) => acc.id === preSelectedAccountId) || null
		setAdAccount(matchedAccount)

		if (!preSelectedAccountId) {
			setSelectedCampaign(null)
		}
	}, [adAccounts])

	useEffect(() => {
		const preSelectedCampaignId = searchParams.get('campaign')

		let matchedCampaign: GoogleAdsCampaignDTO | FacebookAdsCampaignDTO | null =
			null

		if (campaignId && campaignId !== preSelectedCampaignId) {
			matchedCampaign = campaigns.find((c) => c.id === campaignId) || null
		} else if (preSelectedCampaignId) {
			matchedCampaign =
				campaigns.find((c) => c.id === preSelectedCampaignId) || null
		}

		setSelectedCampaign(matchedCampaign)
	}, [campaigns, campaignId])

	useEffect(() => {
		const newParams = new URLSearchParams(searchParams.toString())

		if (adAccount === null) {
			newParams.delete('adAccount')
		} else {
			newParams.set('adAccount', adAccount.id)
		}
		setSearchParams(newParams)
	}, [adAccount])

	const handleDateRangeChange = (e: { from: Date; to: Date }) => {
		setDateRange({ from: e.from, to: e.to })
		setDateLoadingState(true)
		setTimeout(() => setDateLoadingState(false), 1500)
	}
	const shouldTheComponentLoad =
		isLoading('ASSET_GET_ACCOUNTS') ||
		isLoading('ACCOUNT_GET_ASSET') ||
		dateLoading
	const isReady = assetId && dateRange.from && dateRange.to

	const pageActions = (
		<div className="w-full pr-4 flex items-center justify-between pb-4">
			<Breadcrumbs
				asset={asset}
				selectedAdAccount={adAccount}
				setSelectedAdAccount={setAdAccount}
				showCampaignSelector={true}
				demoMode={demoMode}
			/>
			<GDateRange
				onApply={(e) => handleDateRangeChange({ from: e.from, to: e.to })}
				align="right"
				loading={shouldTheComponentLoad}
				demoMode={demoMode}
			/>
		</div>
	)

	return (
		<>
			<Notices
				isInSetup={isInSetup()}
				setupStep={account?.setup?.step}
				hasDashboardPerformanceFeature={hasFeature('dashboard_performance')}
				tc_installed={!!asset?.tc_installed}
				cc_installed={!!asset?.cc_installed}
				accountId={accountId}
				assetId={assetId}
				demoMode={demoMode}
				isInActive={account?.status === 'INACTIVE'}
				mccLinkInviteAdAccounts={adAccounts}
			/>
			<PageTitle pageName="Analytics" entityName={asset?.name} />
			<PageHeader
				title={'Analytics'}
				tagline="Detailed analytics, traffic and exclusion logs for"
				tags={[
					{
						label: getEntityName({
							demoMode,
							selectedCampaign: null,
							adAccount,
							asset
						})
					}
				]}
				action={pageActions}
			/>

			<div className="max-w-full mx-auto px-6 sm:px-8">
				<AnalyticsTableFilterProvider>
					<Suspense fallback={<GLazyLoading />}>
						<Analytics
							asset={asset}
							accountId={accountId}
							assetId={assetId}
							from={dateRange.from}
							to={dateRange.to}
							adAccountId={adAccount?.id}
							isReady={!!isReady}
							selectedCampaign={selectedCampaign}
							selectedAdAccount={adAccount}
							campaignId={selectedCampaign?.id}
							platform={adAccount?.platform}
						/>
					</Suspense>
				</AnalyticsTableFilterProvider>
			</div>
		</>
	)
}
