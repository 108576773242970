import { useState } from 'react'
import { RiCloseLine } from 'react-icons/ri'
import AsyncSelect from 'react-select/async'
import { GroupedOption, SearchWebsiteInputProps } from './types'

import { components } from 'react-select'

import { color } from 'utils/colors'

export const SearchWebsiteInput = (props: SearchWebsiteInputProps) => {
	const { state, setState, loadOptions, formatGroupLabel, formateOptionLabel } =
		props
	const [selectedValue, setSelectedValue] = useState<any>()

	const handleSearchInput = (e: any, { action }: any) => {
		if (
			action === 'menu-close' ||
			action === 'input-blur' ||
			action === 'set-value'
		) {
			return
		} else {
			setState((prevState) => ({ ...prevState, searchQuery: e }))
		}
	}

	const DropdownIndicator = (props: any) => {
		return (
			<components.DropdownIndicator {...props}>
				<div
					onClick={() =>
						setState((prevState) => ({ ...prevState, searchQuery: '' }))
					}
				>
					{props.children}
					{state.searchQuery ? (
						<RiCloseLine className="w-4 h-4 cursor-pointer" />
					) : null}
				</div>
			</components.DropdownIndicator>
		)
	}

	const colorStyles = {
		placeholder: (styles: any, { isFocused }: any) => ({
			...styles,
			fontSize: '0.875rem',
			color: isFocused
				? 'var(--color-text-default)'
				: 'var(--color-text-default)'
		}),
		control: (styles: any, { isFocused }: any) => ({
			...styles,
			fontSize: '0.875rem',
			backgroundColor: isFocused ? 'var(--background)' : 'var(--background)',
			border: isFocused
				? '1px solid var(--card-border)'
				: '1px solid var(--input-border)',
			color: isFocused ? 'gray' : 'gray',
			outline: 'none',
			'&:hover': {
				border: '1px solid ' + color('gray', 800)
			}
		}),
		option: (styles: any, { isSelected, isFocused }: any) => ({
			...styles,
			backgroundColor:
				isSelected || isFocused ? 'var(--color-gray-100)' : 'default',
			color:
				isSelected || isFocused ? 'var(--color-text-dark);' : 'rgb(55 65 81)',
			fontSize: '0.875rem',
			cursor: 'pointer'
		}),
		menu: (provided: any) => ({
			...provided,
			backgroundColor: 'var(--background)'
		}),
		input: (styles: any, { isFocused }: any) => ({
			...styles,
			color: 'var(--color-text-dark);',
			border: isFocused ? 'none' : 'none',
			overflow: 'hidden'
		}),
		valueContainer: (styles: any) => ({
			...styles,
			color: 'gray',
			'&:hover': {
				cursor: 'text'
			}
		})
	}

	const handleChange = (e: any) => {
		setSelectedValue(e as any)
		window.location.href = e.navLink
	}

	return (
		<div className="text-white max-w-lg">
			<AsyncSelect<any, false, GroupedOption>
				classNamePrefix="select2-selection"
				value={selectedValue}
				onChange={(e) => handleChange(e as any)}
				inputValue={state.searchQuery}
				onInputChange={(e, action) => handleSearchInput(e, action)}
				controlShouldRenderValue={false}
				isOptionSelected={(option) => option?.id === selectedValue?.id}
				placeholder={<span className="flex">{'Find anything...'}</span>}
				isSearchable
				noOptionsMessage={() => 'No results'}
				loadOptions={loadOptions}
				defaultOptions={state.options}
				formatGroupLabel={formatGroupLabel}
				formatOptionLabel={formateOptionLabel}
				styles={colorStyles}
				components={{
					DropdownIndicator,
					ValueContainer,
					IndicatorSeparator: () => null
				}}
				menuPosition="fixed"
				menuPlacement="auto"
			/>
		</div>
	)
}

const ValueContainer = ({ children, ...props }: any) => {
	return (
		<components.ValueContainer {...props}>{children}</components.ValueContainer>
	)
}
