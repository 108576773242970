import {
	RiArrowDownSLine,
	RiArrowRightSLine,
	RiSearchLine
} from 'react-icons/ri'

import { classNames } from 'utils'

import { useEffect, useState } from 'react'
import { CampaignProps } from './types'
import { GVirtuosoList } from '../../../../../components/basic-blocks/g-virtualized-list/virtuos-virtulized-lits'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FacebookAdsCampaignDTO, GoogleAdsCampaignDTO } from 'api-models'

export const Campaign = ({
	handleShowCampaigns,
	showCampaigns,
	campaigns
}: CampaignProps) => {
	const [searchParams] = useSearchParams()
	const breadCampaign = searchParams.get('breadCampaign')
	const adAccount = searchParams.get('adAccount')
	const navigate = useNavigate()
	const [selectedCampaign, setSelectedCampaign] = useState<any>(null)
	const [filterInput, setFilterInput] = useState('')
	const [isInputFocused, setIsInputFocused] = useState(false)

	const updateCampaignQueryParam = (
		campaign?: FacebookAdsCampaignDTO | GoogleAdsCampaignDTO | null
	) => {
		const newParams = new URLSearchParams(searchParams)
		if (campaign) {
			setSelectedCampaign(campaign)
			newParams.set('breadCampaign', campaign.id.toString())
		} else {
			newParams.delete('breadCampaign')
			setSelectedCampaign(null)
		}
		navigate({
			pathname: window.location.pathname,
			search: newParams.toString()
		})
	}

	useEffect(() => {
		if (!breadCampaign) {
			updateCampaignQueryParam()
		}
	}, [breadCampaign, adAccount])

	useEffect(() => {
		const campaign = campaigns.find((c) => c.id === breadCampaign)
		if (campaign) {
			updateCampaignQueryParam(campaign)
		}
	}, [])

	const showInputFilter = campaigns.length > 5
	const filteredCampaigns = filterInput
		? campaigns.filter((c) =>
				c.name.toLowerCase().includes(filterInput.toLowerCase())
		  )
		: campaigns

	return (
		<>
			<RiArrowRightSLine color="#69707D" className="ml-2 mr-3" size={16} />
			<div
				className={classNames(
					'flex items-center cursor-pointer relative py-2 px-1',
					selectedCampaign ? 'border-b-primary-300 border-b-2' : ''
				)}
				onClick={() => {
					if (isInputFocused) return
					handleShowCampaigns()
				}}
			>
				{selectedCampaign ? (
					<div className="flex items-center">
						<span className="text-t-default mx-2 font-bold">
							{selectedCampaign.name}
						</span>
					</div>
				) : (
					<span className="italic text-t-secondary mr-2">
						Select a campaign
					</span>
				)}
				<RiArrowDownSLine color="#69707D" size={16} />
				{showCampaigns && (
					<>
						<div className="flex flex-col p-2 gap-1 w-auto overflow-y-scroll absolute ml-0 left-[-6px] top-[35px] z-20 border rounded-lg shadow-lg bg-white overflow-auto max-h-64">
							{showInputFilter ? (
								<div className="flex items-center border-b px-2 mb-2">
									<RiSearchLine className="h-3.5 w-3.5 text-t-secondary" />
									<input
										type="text"
										value={filterInput}
										placeholder="Name..."
										onChange={(e) => {
											setFilterInput(e.target.value)
										}}
										onFocus={() => setIsInputFocused(true)}
										onBlur={() => setIsInputFocused(false)}
										className="h-10 bg-transparent w-full outline-none text-sm focus:ring-0 border-none text-t-secondary"
									/>
								</div>
							) : null}
							<div className="overflow-y-hidden overflow-x-hidden min-w-[250px]">
								<GVirtuosoList length={filteredCampaigns.length} height={180}>
									{(index: number) => {
										return (
											<div
												onClick={() => {
													updateCampaignQueryParam(filteredCampaigns[index])
													setIsInputFocused(false)
													setFilterInput('')
												}}
												className="flex items-center hover:bg-gray-100 p-2 rounded-md"
											>
												<span className="ml-2 max-w-[320px] truncate block">
													{filteredCampaigns[index].name}
												</span>
											</div>
										)
									}}
								</GVirtuosoList>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	)
}
