import { GButton, GDialog } from '../../../components/basic-blocks'
import { Dispatch } from 'react'
import { useAdminStore, useUiStore } from '../../../store'
import { UserDTO } from '../../../../../api-models'
import { toast } from 'react-toastify'
import { useShallow } from 'zustand/react/shallow'
import { AdminRoleOptions } from './types'

export const ChangeAccessConfirmation = ({
	open,
	setOpen,
	role,
	user
}: {
	open: boolean
	setOpen: Dispatch<boolean>
	user: UserDTO
	role: { value: 'BASIC' | 'FULL' | 'SUPER'; name: string }
}) => {
	const { updateAdminRole } = useAdminStore(
		useShallow((s) => ({
			updateAdminRole: s.updateAdminRole
		}))
	)
	const isLoading = useUiStore((e) => e.isLoading)
	const handleAdminRoleChange = async () => {
		if (role?.value === user?.admin?.access_level) {
			toast.error('User already has this role')
			return
		}
		await updateAdminRole(user.id, role.value)
		setOpen(false)
	}
	return (
		<GDialog
			open={open}
			title="Change access level?"
			maxWidth="2xl"
			showClose={false}
			lineBreak={true}
			onClose={() => setOpen(false)}
		>
			<div>
				<p className="text-base py-6">
					You’re changing the access level of this user from&nbsp;
					{
						AdminRoleOptions.find((e) => e?.value === user?.admin?.access_level)
							?.name
					}
					&nbsp;to {role?.name}
				</p>
				<div className="flex items-center justify-between">
					<GButton
						loading={isLoading('ADMIN_UPDATE_ROLE')}
						onClick={() => setOpen(false)}
						variant="text"
					>
						Cancel
					</GButton>
					<GButton
						loading={isLoading('ADMIN_UPDATE_ROLE')}
						color="primary"
						onClick={handleAdminRoleChange}
					>
						Confirm
					</GButton>
				</div>
			</div>
		</GDialog>
	)
}
