import { AdAccountDTO, GoogleAdsAdAccountDTO } from 'api-models'
import { UserAvatar } from 'components'
import { GButton } from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'
import { GTableColumn } from 'components/basic-blocks/g-table/types'
import { PlanNames } from 'config'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { RiCloseLine, RiEarthLine, RiLoginCircleLine } from 'react-icons/ri'
import { NavLink, useNavigate } from 'react-router-dom'
import { removeFadPrefix } from 'utils'
import { PlatformIcon } from '../../../../components/platform-icon'

export const columns: Array<GTableColumn<AdAccountDTO>> = [
	{
		label: 'PPC account',
		render: (row: AdAccountDTO) => <PPCAccountColumn ppcAccount={row} />
	},
	{
		label: 'Owner',
		render: (row: AdAccountDTO) => <User ppcAccount={row} />,
		expand: true
	},
	{
		label: 'Workspace',
		render: (row: AdAccountDTO) => <WorkspaceColumn ppcAccount={row} />
	},
	{
		label: 'Website',
		render: (row: AdAccountDTO) => <WebsiteColumn ppcAccount={row} />,
		width: '300px'
	},
	{
		label: 'Status',
		render: (row: AdAccountDTO) => <StatusColumn ppcAccount={row} />
	},
	{
		label: 'Notice',
		render: (row: AdAccountDTO) => <Notices ppcAccount={row} />,
		className: 'justify-center'
	}
]

const PPCAccountColumn = (props: { ppcAccount: AdAccountDTO }) => {
	const { ppcAccount } = props
	const googleAdAccount = ppcAccount as GoogleAdsAdAccountDTO

	return (
		<div className="flex justify-start items-center space-x-4 py-2">
			<div
			// className={'bg-primary-500 rounded-full p-2'}
			// style={{
			// 	backgroundColor: googleAdAccount.color ? googleAdAccount.color : ''
			// }}
			>
				<PlatformIcon platform={googleAdAccount.platform} />
				{/*{googleAdAccount.platform === 'GADS' ? (*/}
				{/*	<RiGoogleLine className="text-white w-4 h-4" />*/}
				{/*) : (*/}
				{/*	<BsMeta className="text-white w-4 h-4" />*/}
				{/*)}*/}
			</div>
			<div className="flex flex-col justify-start items-start">
				<NavLink to={`/admin/ppc-account/${googleAdAccount.id}`}>
					<div className=" font-medium text-md text-primary-500">
						{googleAdAccount.name || 'Name: N/A'}
					</div>
				</NavLink>
				<div className="text-sm text-t-secondary">
					{removeFadPrefix(googleAdAccount.details?.account_number) ||
						'Account Number: N/A'}
				</div>
			</div>
		</div>
	)
}

const User = (props: { ppcAccount: AdAccountDTO }) => {
	const navigate = useNavigate()
	const { ppcAccount } = props
	const { adminStore } = useStores()

	const [loading, setLoading] = useState(false)

	const link = `/admin/user/${ppcAccount.user_info?.user_id}`
	const name = ppcAccount.user_info?.name
	const email = ppcAccount.user_info?.email

	const impersonate = async () => {
		setLoading(true)
		try {
			await adminStore.setImpersonation(ppcAccount.user_info?.user_id ?? '')
			setLoading(false)
			navigate('/')
		} catch (e) {
			setLoading(false)
		}
	}

	useEffect(() => {
		return () => {
			setLoading(false)
		}
	}, [])

	return (
		<div className="flex space-x-2 items-center">
			<div className="flex items-center mr-2">
				<GButton
					shape="square"
					type="icon"
					loading={loading}
					icon={RiLoginCircleLine}
					variant="contained"
					color="primary"
					size="xs"
					onClick={impersonate}
				>
					Impersonate
				</GButton>
			</div>
			<div className="flex space-x-2 items-center">
				<UserAvatar size={8} src={ppcAccount.user_info?.avatar} />
				<NavLink to={link}>
					<div className="flex flex-col group cursor-pointer">
						<div className="font-semibold text-primary-500 group-hover:text-primary-600">
							{name}
						</div>
						<div className="text-gray-500">{email}</div>
					</div>
				</NavLink>
			</div>
		</div>
	)
}

const WorkspaceColumn = (props: { ppcAccount: AdAccountDTO }) => {
	const { ppcAccount } = props
	const link = `/admin/subscription/${ppcAccount.account_id}`
	const planName = PlanNames[ppcAccount?.account_info?.tier || '']

	return (
		<div className="flex justify-center items-center space-x-4">
			<UserAvatar size={8} name={ppcAccount.user_info?.name} />
			<div className="flex flex-col justify-center">
				<NavLink to={link}>
					<div className="font-medium text-primary-500 hover:text-primary-600 cursor-pointer">
						{ppcAccount.user_info?.name}
					</div>
				</NavLink>
				<div className="text-sm text-gray-500">{planName || ''}</div>
			</div>
		</div>
	)
}

const WebsiteColumn = (props: { ppcAccount: AdAccountDTO }) => {
	const { ppcAccount } = props
	const link = `/admin/website/${ppcAccount.asset_id}`

	return (
		<div className="flex justify-center items-center space-x-4">
			<RiEarthLine
				className={'w-6 h-6 '}
				style={{ color: ppcAccount.color || '#9c73d2' }}
			/>
			<div className="flex flex-col justify-center">
				<NavLink to={link}>
					<div className="font-medium text-primary-500 hover:text-primary-600 cursor-pointer truncate max-w-[230px]">
						{ppcAccount.asset_info?.name}
					</div>
				</NavLink>
			</div>
		</div>
	)
}

const StatusColumn = (props: { ppcAccount: AdAccountDTO }) => {
	const { ppcAccount } = props
	return (
		<div>
			{ppcAccount.status === 'ACTIVE' ? (
				<GBadge text={ppcAccount.status} color="green" />
			) : (
				<GBadge text="INACTIVE" color="red" />
			)}
		</div>
	)
}
const Notices = (props: { ppcAccount: AdAccountDTO }) => {
	const { ppcAccount } = props
	const GooglePPCAccount = ppcAccount as GoogleAdsAdAccountDTO
	const hasProtectionOverride = GooglePPCAccount.protection
	const unlined = GooglePPCAccount.details.status === 'NO_ACCESS'
	const hasNotice = unlined || hasProtectionOverride

	return (
		<div className="flex flex-col gap-2 justify-center items-center">
			{hasProtectionOverride && (
				<div className="w-fit">
					<GBadge text={'Protection override'} color="primary" />
				</div>
			)}
			{unlined && (
				<div className="w-fit">
					<GBadge text="Unlined" color="red" />
				</div>
			)}
			{!hasNotice && <RiCloseLine className="h-5 w-5 mr-2 text-t-secondary" />}
		</div>
	)
}
