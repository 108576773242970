import { AppConfig } from 'config'

export const CompletedNotice = () => {
	return (
		<div className="flex items-center">
			Congratulations! You’re all set up on ClickGUARD. Need help?{' '}
			<a
				onClick={() => window.open(AppConfig.KNOWLEDGEBASE_URL, '_blank')}
				className="px-1 underline text-lg text-gray-500 cursor-pointer"
			>
				Our Knowledge Base
			</a>{' '}
			has what you need. Or contact support from the chat icon on your bottom
			right.
		</div>
	)
}
