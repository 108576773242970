"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleTagOwnerType = exports.RuleTagType = void 0;
var RuleTagType;
(function (RuleTagType) {
    RuleTagType["IP"] = "IP";
    RuleTagType["DEVICE"] = "DEVICE";
    RuleTagType["TARGET_CAMPAIGN"] = "TARGET_CAMPAIGN";
    RuleTagType["VPN"] = "VPN";
})(RuleTagType = exports.RuleTagType || (exports.RuleTagType = {}));
var RuleTagOwnerType;
(function (RuleTagOwnerType) {
    RuleTagOwnerType["WEBSITE"] = "WEBSITE";
    RuleTagOwnerType["AD_ACCOUNT"] = "AD_ACCOUNT";
    RuleTagOwnerType["CAMPAIGN"] = "CAMPAIGN";
})(RuleTagOwnerType = exports.RuleTagOwnerType || (exports.RuleTagOwnerType = {}));
