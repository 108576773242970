import { GButton } from 'components/basic-blocks'
import { useNavigate } from 'react-router-dom'
import { useUiStore } from 'store'
import { OnBoarding } from '../../../components/onboarding/onboarding'
import { useStores } from '../../../hooks'
import { getUserWorkspaceCount } from '../../../utils/user'
import { ExistingUserProps } from './types'

export const ExistingUserView = ({
	state,
	user,
	accept
}: ExistingUserProps) => {
	const navigate = useNavigate()
	const { accountStore, assetStore } = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	const getDashboardUrl = async (): Promise<string> => {
		await accountStore.getAllAccounts()
		await assetStore.getAllAssets()
		const account = accountStore.accounts[0]
		const accountWithAsset = account
			? assetStore.accountsWithAssets.find((a) => a.id === account.id)
			: null
		if (account) {
			const assetId = accountWithAsset?.assets?.length
				? accountWithAsset.assets[0]?.id
				: 'website-demo'
			accountStore.setAccount(account)
			return `/workspace/${account.id}/asset/${assetId}`
		} else {
			return '/workspaces'
		}
	}

	const roleLabel = () => {
		if (state?.invite?.access_level === 'OWNER') return 'owner'
		if (state?.invite?.access_level === 'READ') return 'analyst'
		if (state?.invite?.access_level === 'WRITE') return 'manager'
		return state?.invite?.access_level?.toLowerCase()
	}

	return (
		<OnBoarding
			title={
				<div className="text-3xl max-w-[20rem] break-words text-[#fff] font-[800]">
					You've been invited to join&nbsp;
					<span className="text-[#71DA97]">
						{state?.invite?.info?.owner_organization_name || ''}
					</span>
				</div>
			}
			description={
				<p>
					invited by <strong>{state?.invite?.info?.owner_name}</strong>
				</p>
			}
		>
			<div className="flex justify-center items-center flex-col h-[22.5rem]">
				<div className="flex items-center justify-center flex-col px-4 gap-4 w-72 border-b border-gray-100 pb-8">
					<p className="text-center text-t-default">
						By joining, you will be added to the existing workspace with the
						role of <strong>{roleLabel()}</strong>.
					</p>
					<p className="font-bold text-t-default break-words max-w-[16rem]">
						Accept invitation to join&nbsp;
						{state?.invite?.info?.owner_organization_name || ''}&nbsp;?
					</p>
					<GButton
						color="primary"
						iconPlacement="right"
						className="w-full"
						loading={isLoading('USER_ACCEPT_INVITE')}
						onClick={accept}
					>
						<span className="font-bold">Accept</span>
					</GButton>
				</div>
				<p
					onClick={async () => {
						if (getUserWorkspaceCount(user) > 0) {
							const dashboardUrl = await getDashboardUrl()
							navigate(dashboardUrl)
						} else {
							navigate('/workspaces')
						}
					}}
					className="text-sm text-primary-500 cursor-pointer pt-8"
				>
					{getUserWorkspaceCount(user) > 0
						? 'Back to dashboard'
						: 'I want to create my own workspace'}
				</p>
			</div>
		</OnBoarding>
	)
}
