import { UserAccessInviteDTO } from 'api-models'
import { GButton } from 'components/basic-blocks/g-button'
import { ModalTypes } from 'components/basic-blocks/g-modal/types'
import { useStores } from 'hooks'
import { useUiStore } from 'store'

export const RevokeInvite = (props: { row: UserAccessInviteDTO }) => {
	const { row } = props
	const { user_email, id } = row
	const { accountStore } = useStores()
	const { revokeUserInvitation, account } = accountStore
	const setModalState = useUiStore((s) => s.setModalState)
	const setModalContent = useUiStore((s) => s.setModalContent)

	const confirmRevokeInvitation = () => {
		revokeUserInvitation(account!.id, id)
		setModalState(false)
	}

	const renderModal = () => {
		setModalContent({
			title: 'Revoke Invitation',
			description: `Are you sure you want to Revoke ${
				user_email ? user_email : 'this user'
			} invitation?`,
			type: ModalTypes.DANGER,
			confirmLabel: 'Revoke',
			confirm: () => confirmRevokeInvitation()
		})
	}

	return (
		<GButton
			label="Revoke invite"
			labelClassName="font-bold"
			variant="text"
			color="danger"
			size="xs"
			onClick={() => renderModal()}
		/>
	)
}
