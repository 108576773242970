import {
	GButton,
	GDialog,
	GInput,
	GListBox,
	GTransition
} from 'components/basic-blocks'
import { useStores } from 'hooks'
import { useState } from 'react'
import { useUiStore } from 'store'
import { RevertSubscriptionProps, RevertSubscriptionState } from './types'

export const RevertSubscription = (props: RevertSubscriptionProps) => {
	const { children, account } = props
	const { adminStore } = useStores()
	const isLoading = useUiStore((s) => s.isLoading)
	const { revertSubscription } = adminStore

	const [dialogOpen, setDialogOpen] = useState(false)
	const [state, setState] = useState<RevertSubscriptionState>({
		note: '',
		reason: 'User requested to revert'
	})

	const handleChange = (e: any, name: string) => {
		setState({ ...state, [name]: e })
	}

	const handleDialogClose = () => {
		setDialogOpen(false)
		setState({
			note: '',
			reason: 'User requested to revert'
		})
	}

	const handleSumbit = async () => {
		const payload = {
			note: state.reason === 'Other' ? state.note : state.reason
		}
		if (!account?.billing?.subscription?.id) {
			return
		}
		try {
			await revertSubscription(payload, account?.billing?.subscription?.id)
			handleDialogClose()
		} catch (e) {
			console.log('error', e)
		}
	}

	return (
		<>
			{children && children(() => setDialogOpen(true))}
			<GDialog
				title="Revert subscription"
				subtitle="By performing this action you will revert the subscription to it's previous state."
				open={dialogOpen}
				onClose={() => handleDialogClose()}
				maxWidth="3xl"
				className="sm:max-w-3xl overflow-clip"
			>
				<div className="my-8 space-y-4">
					<GListBox<string>
						label="Reason"
						options={['User requested to revert', 'Other']}
						placeholder="Select Reason"
						onChange={(e) => {
							handleChange(e, 'reason')
						}}
						value={state.reason}
						renderLabel={(item) => item}
						// error={selectedItem?.errors?.tier}
					/>
					<GTransition show={state.reason === 'Other'}>
						<GInput
							placeholder="Type the reason here"
							onChange={(e) => {
								handleChange(e.target.value, 'note')
							}}
							value={state.note}
							className="ml-6"
						/>
					</GTransition>
				</div>
				<div className="flex justify-end gap-2">
					<GButton label="Cancel" onClick={() => handleDialogClose()} />
					<GButton
						label="Confirm"
						onClick={() => handleSumbit()}
						color="primary"
						loading={isLoading('ADMIN_CHANGE_ACCOUNT')}
					/>
				</div>
			</GDialog>
		</>
	)
}
