import { RiArrowDownSLine } from 'react-icons/ri'
import WindowedSelect, { components } from 'react-windowed-select'
import { classNames } from 'utils'
import { color } from 'utils/colors'
import { useThemeStore } from '../../../layout/navbar/store'
import { GWindowedSelectProps } from './types'

export const GWindowedSelect = <T,>(props: GWindowedSelectProps<T>) => {
	const {
		label,
		subtitle,
		error,
		placeholder = 'Select',
		options,
		value,
		getOptionValue,
		getOptionLabel,
		labelClassName,
		...rest
	} = props
	const { theme } = useThemeStore()

	const colourStyles = {
		container: (styles: any) => {
			return {
				...styles
			}
		},
		placeholder: (styles: any) => ({
			...styles,
			backgroundColor: 'transparent',
			fontSize: '0.875rem'
		}),
		control: (styles: any, { isHovered, isFocused }: any) => ({
			...styles,
			borderRadius: '0.375rem',
			color: 'var(--text-default)',

			fontSize: '0.875rem',
			'--tw-shadow': '0 1px 2px 0 rgb(0 0 0 / 0.05)',
			'--tw-shadow-colored': '0 1px 2px 0 var(--tw-shadow-color)',
			...(isHovered
				? {
						backgroundColor: color('gray', 50),
						borderColor: color('gray', 100)
				  }
				: {
						backgroundColor: 'var(--background)'
				  }),
			...(isFocused
				? {
						outline: '2px solid transparent',
						outlineOffset: '2px',
						'--tw-ring-inset': 'var(--tw-empty,/*!*/ /*!*/)',
						'--tw-ring-offset-width': '0px',
						'--tw-ring-offset-color': '#fff',
						'--tw-ring-color': color('primary', 500),
						'--tw-ring-offset-shadow':
							'var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)',
						'--tw-ring-shadow':
							'var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)',
						boxShadow:
							'var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow)',
						borderColor: color('primary', 500),
						backgroundColor: 'var(--background)'
				  }
				: {
						boxShadow:
							'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)',
						borderColor: color('gray', 100)
				  })
		}),
		menu: (provided: any) => ({
			...provided,
			backgroundColor: 'var(--background)'
		}),
		indicatorSeparator: (styles: any) => ({
			...styles,
			backgroundColor: 'var(--background)'
		}),
		input: (styles: any) => ({
			...styles,
			color: 'var(--text-default)'
		}),
		option: (styles: any, { isSelected, isFocused }: any) => ({
			...styles,
			backgroundColor: isSelected
				? 'var(--color-gray-200)'
				: isFocused
				? 'var(--color-gray-100)'
				: 'default',
			color: theme === 'dark' ? 'white' : 'black',
			fontSize: '0.875rem'
		})
	}

	const DropdownIndicator = (props: any) => {
		return (
			<components.DropdownIndicator {...props}>
				<RiArrowDownSLine
					className="h-5 w-5 text-gray-400"
					aria-hidden="true"
				/>
			</components.DropdownIndicator>
		)
	}

	return (
		<div>
			{label && (
				<div
					className={`${labelClassName} block text-sm font-medium text-t-dark `}
				>
					{label}
				</div>
			)}
			{subtitle && <p className="text-sm mt-1 text-t-secondary">{subtitle}</p>}
			<div
				className={classNames(
					'relative windowed-select',
					label || subtitle ? 'mt-2' : ''
				)}
			>
				<WindowedSelect
					placeholder={<span className="w-full truncate">{placeholder}</span>}
					components={{ DropdownIndicator }}
					isSearchable={true}
					value={value}
					options={options?.map((opt: any) => {
						return {
							...opt,
							value: getOptionValue ? getOptionValue(opt) : opt,
							label: getOptionLabel ? getOptionLabel(opt) : opt
						}
					})}
					windowThreshold={0}
					styles={colourStyles}
					{...(rest || {})}
				/>
			</div>
			{error && (
				<p className="mt-1 text-sm text-red-700" id="email-description">
					{error}
				</p>
			)}
		</div>
	)
}
