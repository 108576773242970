import { ReferenceDomainDTO } from 'api-models'
import { GButton, GInput, GSection, GTable } from 'components/basic-blocks'
import { PageHeader } from 'components/page-header'
import { useStores } from 'hooks'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { RiRefreshLine } from 'react-icons/ri'
import { useUiStore } from 'store'
import { PageTitle } from 'utils/page-title'
import { columns } from './columns'
import { DomainReference, State } from './types'

export const AdminDomainsPage = () => {
	const { adminStore } = useStores()
	const { getDomainsReferences } = adminStore
	const isLoading = useUiStore((s) => s.isLoading)

	const [state, setState] = useState<State>({
		query: '',
		skip: 0,
		pageSize: 20
	})

	const [domainsReferences, setDomainsReferences] = useState<DomainReference>({
		total: 0,
		data: []
	})

	const pageListener = (page: number) => {
		setState({ ...state, skip: page })
	}

	const currentQuery = state.query
		? { search: state.query, skip: state.skip, limit: state.pageSize }
		: { skip: state.skip, limit: state.pageSize }

	useEffect(() => {
		;(async () => {
			try {
				const domainsReferences = await getDomainsReferences(currentQuery)
				setDomainsReferences(domainsReferences || { total: 0, data: [] })
			} catch (err) {
				return
			}
		})()
	}, [state.skip])

	const debounceQuery = useCallback(
		debounce(async (query) => {
			const domainsReferences = await getDomainsReferences({
				search: query,
				skip: state.skip,
				limit: state.pageSize
			})
			setDomainsReferences(domainsReferences || { total: 0, data: [] })
		}, 500),
		[getDomainsReferences]
	)

	return (
		<>
			<PageTitle pageName="Domains" />
			<PageHeader
				title="Domains references"
				actionPlacement="top"
				action={
					<div className="w-full flex pr-3.5 space-x-4 items-center justify-between">
						<GButton
							icon={RiRefreshLine}
							size="sm"
							onClick={() => getDomainsReferences(currentQuery)}
						>
							Refresh
						</GButton>
						<GInput
							placeholder="Find domains..."
							onChange={(e) => {
								setState({ ...state, query: e.target.value })
								debounceQuery(e.target.value)
							}}
						/>
					</div>
				}
			/>
			<div className="w-full pt-6 px-4 sm:px-6">
				<GSection loading={isLoading('ADMIN_GET_REFERENCES')} className="">
					<GTable<ReferenceDomainDTO>
						data={domainsReferences.data}
						columns={columns}
						pageListener={(e: number) => pageListener(e)}
						pageSize={state.pageSize}
						totalCount={domainsReferences.total}
					/>
				</GSection>
			</div>
		</>
	)
}
