import { formatNumber } from 'utils'
import { AcquisitionPanel } from './components/acquisition'
import { ActiveWorkspacesPanel } from './components/active-workspaces'
import { CancelledWorkspacesPanel } from './components/cancelled-workspaces'
import { ChurnPanel } from './components/churn'
import { SetupWorkspacesPanel } from './components/setup-workspaces'
import { Timeline } from './components/timeline/timeline'
import { MetricsViewProps } from './types'

export const MetricsView = ({
	data,
	percentages,
	timelineData
}: MetricsViewProps) => {
	return (
		<div className="flex w-full">
			<div className="mr-8 min-w-[320px]">
				<AcquisitionPanel data={data} percentages={percentages} />
				<ChurnPanel data={data} percentages={percentages} />
			</div>
			<div className="flex flex-col w-full">
				<div>
					<Timeline data={timelineData} formatter={formatNumber} />
				</div>
				<div className="flex gap-6 justify-evenly">
					<SetupWorkspacesPanel data={data} percentages={percentages} />
					<ActiveWorkspacesPanel data={data} percentages={percentages} />
					<CancelledWorkspacesPanel data={data} percentages={percentages} />
				</div>
			</div>
		</div>
	)
}
