import { useAuth0 } from '@auth0/auth0-react'
import { UserAvatar } from 'components'
import { GButton } from 'components/basic-blocks/'
import { GBadge } from 'components/basic-blocks/g-badge'
import { GDropdown } from 'components/basic-blocks/g-dropdown-menu'
import { useStores } from 'hooks'
import { AccountSwitcher } from 'layout/sidebar/menus/account-switcher'
import { AiOutlinePlus } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useUiStore } from 'store'
import { MenuLink } from '../menu-link'

export const UserHeaderDropdown = (props: {
	removeImpersonationId: () => void
}) => {
	const { removeImpersonationId } = props

	return (
		<GDropdown position="top" menuButton={<MenuButton />}>
			{(close?: () => void) => (
				<UserMenuContent
					close={close}
					removeImpersonationId={removeImpersonationId}
				/>
			)}
		</GDropdown>
	)
}

const UserMenuContent = (props: {
	close?: () => void
	removeImpersonationId: () => void
}) => {
	const { close, removeImpersonationId } = props
	const { logout } = useAuth0()
	const { userStore, accountStore, billingStore } = useStores()
	const { account, accounts, userAccess } = accountStore
	const { user } = userStore
	const navigate = useNavigate()

	const showNewWorkspaceOffers = billingStore.offers?.length > 0

	return (
		<div
			className={
				'ml-6 -mb-8  border w-60 px-2 whitespace-nowrap text-ellipsis rounded-md shadow-lg bg-card-background ring-opacity-5 divide-y divide-gray-100 '
			}
		>
			{account ? (
				<div className="py-1">
					<div className="pt-3 pb-2">
						<AccountSwitcher />
					</div>
					<MenuLink
						label="Preferences"
						to={() => `/settings/workspace/${account?.id}/preferences`}
						onClickWithNavigation={close}
					/>
					<MenuLink
						label="Connections"
						to={() => `/settings/workspace/${account?.id}/connections`}
						onClickWithNavigation={close}
					/>
					<MenuLink
						label="Team access"
						to={() => `/settings/workspace/${account?.id}/team-access`}
						onClickWithNavigation={close}
					/>
					{userAccess(user?.id) !== 'READ' && (
						<MenuLink
							label={
								<div className="flex gap-2 items-center">
									<>
										<span>Billing</span>
									</>
								</div>
							}
							to={() => `/settings/workspace/${account?.id}/billing`}
							onClickWithNavigation={close}
						/>
					)}
				</div>
			) : accounts?.length > 0 ? (
				<div className="py-1">
					<AccountSwitcher />
				</div>
			) : (
				<div className="py-1">
					<div className="pt-3 pb-2">
						<GButton
							className="w-full"
							color="white"
							variant="contained"
							size="md"
							icon={AiOutlinePlus}
							iconPlacement="left"
							onClick={() => navigate('/start/standard')}
						>
							Create new workspace
						</GButton>
					</div>
				</div>
			)}
			<div className="py-1">
				<div className="flex items-center space-x-2 px-2 pt-4 pb-2">
					<UserAvatar user={user} size={8} />
					<div className="text-left flex-col flex-1 hidden md:block">
						<p className="text-sm font-bold text-t-heading w-32 truncate">
							{user?.name}
						</p>
						<p className="text-sm text-t-secondary w-32 truncate">
							{user?.email}
						</p>
					</div>
				</div>
				<>
					<MenuLink
						label={
							<div className="flex gap-2 items-center">
								<>
									<span>Offers</span>
									{showNewWorkspaceOffers && (
										<span className="bg-red-100 px-2.5 h-[23px] rounded-md text-red-700">
											!
										</span>
									)}
								</>
							</div>
						}
						to={() => 'settings/user/offers'}
						onClickWithNavigation={close}
					/>

					<MenuLink
						label="Profile"
						to={() => '/settings/user/profile'}
						onClickWithNavigation={close}
					/>
					<MenuLink
						label="Notifications"
						to={() => '/settings/user/notification'}
						onClickWithNavigation={close}
					/>
					<MenuLink
						label="Security"
						to={() => '/settings/user/security'}
						onClickWithNavigation={close}
					/>
				</>
			</div>
			{/* user section end */}
			{user?.admin && (
				<div className="py-1">
					<MenuLink
						label="Admin Panel"
						to={() => '/admin'}
						// icon={RiShieldKeyholeFill}
						onClickWithNavigation={() => {
							accountStore.clearAccount()
							close && close()
						}}
					/>
				</div>
			)}

			<div className="py-1">
				<MenuLink
					label="Logout"
					to={() => '#'}
					onClickWithoutNavigation={() => {
						logout()
						removeImpersonationId()
					}}
				/>
			</div>
		</div>
	)
}

const MenuButton = () => {
	const { userStore, accountStore } = useStores()
	const { user } = userStore

	const { account } = accountStore
	const userAccessInfo =
		account?.user_access &&
		account.user_access.find((userAccess) => userAccess.user_id === user?.id)
	const text =
		userAccessInfo?.access_level === 'READ'
			? 'Analyst'
			: userAccessInfo?.access_level === 'WRITE'
			? 'Manager'
			: userAccessInfo?.access_level === 'OWNER'
			? 'Owner'
			: 'Other'
	const color =
		userAccessInfo?.access_level === 'READ'
			? 'green'
			: userAccessInfo?.access_level === 'WRITE'
			? 'purple'
			: userAccessInfo?.access_level === 'OWNER'
			? 'primary'
			: 'primary'
	const desktopSidebarCollapsed = useUiStore((s) => s.desktopSidebarCollapsed)
	const context = useUiStore((s) => s.context)

	return (
		<div className="flex max-w-full items-center cursor-pointer px-2 py-2 rounded-md  space-x-2 hover:bg-gray-50">
			<div className="w-8 h-8 flex items-center justify-center rounded-md ">
				<UserAvatar user={user} size={6} />
			</div>
			{!desktopSidebarCollapsed && (
				<div className="text-left flex-1 hidden md:block w-[110px]">
					<p className="font-bold truncate">
						{context !== 'admin' && account?.name
							? account?.name.replace(
									/http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g,
									''
							  )
							: user?.name}
					</p>
					{context !== 'admin' && account && (
						<GBadge color={color} text={text} />
					)}
				</div>
			)}
		</div>
	)
}
