export const ThemeFix = () => {
	return (
		<div className="theme-fix hidden">
			<span className="text-primary-100 bg-primary-100 hover:bg-primary-100 hover:text-primary-100 disabled:bg-primary-100 border-primary-100"></span>
			<span className="text-primary-200 bg-primary-200 hover:bg-primary-200 hover:text-primary-200 disabled:bg-primary-200 border-primary-200"></span>
			<span className="text-primary-300 bg-primary-300 hover:bg-primary-300 hover:text-primary-300 disabled:bg-primary-300 border-primary-300"></span>
			<span className="text-primary-400 bg-primary-400 hover:bg-primary-400 hover:text-primary-400 disabled:bg-primary-400 border-primary-400"></span>
			<span className="text-primary-500 bg-primary-500 hover:bg-primary-500 hover:text-primary-500 disabled:bg-primary-500 border-primary-500"></span>
			<span className="text-primary-600 bg-primary-600 hover:bg-primary-600 hover:text-primary-600 disabled:bg-primary-600 border-primary-600"></span>
			<span className="text-primary-700 bg-primary-700 hover:bg-primary-700 hover:text-primary-700 disabled:bg-primary-700 border-primary-700"></span>
			<span className="text-primary-800 bg-primary-800 hover:bg-primary-800 hover:text-primary-800 disabled:bg-primary-800 border-primary-800"></span>
			<span className="text-primary-900 bg-primary-900 hover:bg-primary-900 hover:text-primary-900 disabled:bg-primary-900 border-primary-900"></span>
			<span className="text-violet-100 bg-violet-100 hover:bg-violet-100 hover:text-violet-100 border-violet-100 disabled:bg-violet-300 disabled:text-gray-50"></span>
			<span className="text-violet-200 bg-violet-200 hover:bg-violet-200 hover:text-violet-200 border-violet-200 disabled:bg-violet-300 disabled:text-gray-50"></span>
			<span className="text-violet-300 bg-violet-300 hover:bg-violet-300 hover:text-violet-300 border-violet-300 disabled:bg-violet-300 disabled:text-gray-50"></span>
			<span className="text-violet-400 bg-violet-400 hover:bg-violet-400 hover:text-violet-400 border-violet-400 disabled:bg-violet-300 disabled:text-gray-50"></span>
			<span className="text-violet-500 bg-violet-500 hover:bg-violet-500 hover:text-violet-500 border-violet-500 disabled:bg-violet-300 disabled:text-gray-50"></span>
			<span className="text-violet-600 bg-violet-600 hover:bg-violet-600 hover:text-violet-600 border-violet-600 disabled:bg-violet-300 disabled:text-gray-50"></span>
			<span className="text-violet-700 bg-violet-700 hover:bg-violet-700 hover:text-violet-700 border-violet-700 disabled:bg-violet-300 disabled:text-gray-50"></span>
			<span className="text-violet-800 bg-violet-800 hover:bg-violet-800 hover:text-violet-800 border-violet-800 disabled:bg-violet-300 disabled:text-gray-50"></span>
			<span className="text-violet-900 bg-violet-900 hover:bg-violet-900 hover:text-violet-900 border-violet-900 disabled:bg-violet-300 disabled:text-gray-50"></span>
			<span className="text-green-100 bg-green-100 hover:bg-green-100 hover:text-green-100 border-green-100 disabled:bg-green-300 disabled:text-gray-50"></span>
			<span className="text-green-200 bg-green-200 hover:bg-green-200 hover:text-green-200 border-green-200 disabled:bg-green-300 disabled:text-gray-50"></span>
			<span className="text-green-300 bg-green-300 hover:bg-green-300 hover:text-green-300 border-green-300 disabled:bg-green-300 disabled:text-gray-50"></span>
			<span className="text-green-400 bg-green-400 hover:bg-green-400 hover:text-green-400 border-green-400 disabled:bg-green-300 disabled:text-gray-50"></span>
			<span className="text-green-500 bg-green-500 hover:bg-green-500 hover:text-green-500 border-green-500 disabled:bg-green-300 disabled:text-gray-50"></span>
			<span className="text-green-600 bg-green-600 hover:bg-green-600 hover:text-green-600 border-green-600 disabled:bg-green-300 disabled:text-gray-50"></span>
			<span className="text-green-700 bg-green-700 hover:bg-green-700 hover:text-green-700 border-green-700 disabled:bg-green-300 disabled:text-gray-50"></span>
			<span className="text-green-800 bg-green-800 hover:bg-green-800 hover:text-green-800 border-green-800 disabled:bg-green-300 disabled:text-gray-50"></span>
			<span className="text-green-900 bg-green-900 hover:bg-green-900 hover:text-green-900 border-green-900 disabled:bg-green-300 disabled:text-gray-50"></span>
			<span className="text-amber-100 bg-amber-100 hover:bg-amber-100 hover:text-amber-100 border-amber-100 disabled:bg-amber-300 disabled:text-gray-50"></span>
			<span className="text-amber-200 bg-amber-200 hover:bg-amber-200 hover:text-amber-200 border-amber-200 disabled:bg-amber-300 disabled:text-gray-50"></span>
			<span className="text-amber-300 bg-amber-300 hover:bg-amber-300 hover:text-amber-300 border-amber-300 disabled:bg-amber-300 disabled:text-gray-50"></span>
			<span className="text-amber-400 bg-amber-400 hover:bg-amber-400 hover:text-amber-400 border-amber-400 disabled:bg-amber-300 disabled:text-gray-50"></span>
			<span className="text-amber-500 bg-amber-500 hover:bg-amber-500 hover:text-amber-500 border-amber-500 disabled:bg-amber-300 disabled:text-gray-50"></span>
			<span className="text-amber-600 bg-amber-600 hover:bg-amber-600 hover:text-amber-600 border-amber-600 disabled:bg-amber-300 disabled:text-gray-50"></span>
			<span className="text-amber-700 bg-amber-700 hover:bg-amber-700 hover:text-amber-700 border-amber-700 disabled:bg-amber-300 disabled:text-gray-50"></span>
			<span className="text-amber-800 bg-amber-800 hover:bg-amber-800 hover:text-amber-800 border-amber-800 disabled:bg-amber-300 disabled:text-gray-50"></span>
			<span className="text-amber-900 bg-amber-900 hover:bg-amber-900 hover:text-amber-900 border-amber-900 disabled:bg-amber-300 disabled:text-gray-50"></span>
			<span className="text-red-100 bg-red-100 hover:bg-red-100 hover:text-red-100 border-red-100 disabled:bg-red-300 disabled:text-gray-50"></span>
			<span className="text-red-200 bg-red-200 hover:bg-red-200 hover:text-red-200 border-red-200 disabled:bg-red-300 disabled:text-gray-50"></span>
			<span className="text-red-300 bg-red-300 hover:bg-red-300 hover:text-red-300 border-red-300 disabled:bg-red-300 disabled:text-gray-50"></span>
			<span className="text-red-400 bg-red-400 hover:bg-red-400 hover:text-red-400 border-red-400 disabled:bg-red-300 disabled:text-gray-50"></span>
			<span className="text-red-500 bg-red-500 hover:bg-red-500 hover:text-red-500 border-red-500 disabled:bg-red-300 disabled:text-gray-50"></span>
			<span className="text-red-600 bg-red-600 hover:bg-red-600 hover:text-red-600 border-red-600 disabled:bg-red-300 disabled:text-gray-50"></span>
			<span className="text-red-700 bg-red-700 hover:bg-red-700 hover:text-red-700 border-red-700 disabled:bg-red-300 disabled:text-gray-50"></span>
			<span className="text-red-800 bg-red-800 hover:bg-red-800 hover:text-red-800 border-red-800 disabled:bg-red-300 disabled:text-gray-50"></span>
			<span className="text-red-900 bg-red-900 hover:bg-red-900 hover:text-red-900 border-red-900 disabled:bg-red-300 disabled:text-gray-50"></span>

			<span className="text-purple-100 bg-purple-100 hover:bg-purple-100 hover:text-purple-100 border-purple-100 disabled:bg-purple-300 disabled:text-gray-50"></span>
			<span className="text-purple-200 bg-purple-200 hover:bg-purple-200 hover:text-purple-200 border-purple-200 disabled:bg-purple-300 disabled:text-gray-50"></span>
			<span className="text-purple-300 bg-purple-300 hover:bg-purple-300 hover:text-purple-300 border-purple-300 disabled:bg-purple-300 disabled:text-gray-50"></span>
			<span className="text-purple-400 bg-purple-400 hover:bg-purple-400 hover:text-purple-400 border-purple-400 disabled:bg-purple-300 disabled:text-gray-50"></span>
			<span className="text-purple-500 bg-purple-500 hover:bg-purple-500 hover:text-purple-500 border-purple-500 disabled:bg-purple-300 disabled:text-gray-50"></span>
			<span className="text-purple-600 bg-purple-600 hover:bg-purple-600 hover:text-purple-600 border-purple-600 disabled:bg-purple-300 disabled:text-gray-50"></span>
			<span className="text-purple-700 bg-purple-700 hover:bg-purple-700 hover:text-purple-700 border-purple-700 disabled:bg-purple-300 disabled:text-gray-50"></span>
			<span className="text-purple-800 bg-purple-800 hover:bg-purple-800 hover:text-purple-800 border-purple-800 disabled:bg-purple-300 disabled:text-gray-50"></span>
			<span className="text-purple-900 bg-purple-900 hover:bg-purple-900 hover:text-purple-900 border-purple-900 disabled:bg-purple-300 disabled:text-gray-50"></span>
			<span className="border-t-tertiary bg-t-tertiary"></span>
			<span className="bg-t-button"></span>
			<span className="bg-gray-300"></span>
			<span className="bg-gray-500 ring-gray-500 text-gray-500 border-gray-100"></span>
		</div>
	)
}
