import { GButton } from 'components/basic-blocks'
import { LegacyViewProps } from '../types'

export const LostView = ({ loading, onConfirm }: LegacyViewProps) => {
	return (
		<div className="w-full flex flex-col items-center">
			<span className="text-t-title text-xl font-semibold mb-4">
				Welcome to ClickGUARD 2.0 🚀
			</span>

			<div className="w-full px-2 rounded-lg flex flex-col space-y-6 mb-4 text-sm">
				<div className="flex flex-col text-center space-y-4 text-t-dark">
					<p>
						We're so excited to share our new & improved platform for you to
						test out with a <strong>FREE TRIAL</strong>.
					</p>
					<p>
						You get access to <strong>all our features</strong> during the
						trial, so give it a go, and tell us what you think!
					</p>
					<p>
						Whenever you're ready to lock in your protection, simply choose your
						protection plan and start your subscription.
					</p>
					<p className="font-semibold text-md">Let's get started!</p>
				</div>
			</div>
			<div className="mt-2">
				<GButton color="primary" onClick={onConfirm} loading={loading}>
					Start FREE TRIAL
				</GButton>
			</div>
		</div>
	)
}
