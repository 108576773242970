export const PlanNames: Record<string, string> = {
	lite: 'ClickGUARD Lite',
	standard: 'ClickGUARD Standard',
	legacy: 'ClickGUARD Standard',
	pro: 'ClickGUARD Pro',
	pro_plus: 'ClickGUARD Pro+',
	standard_basic: 'ClickGUARD Standard',
	standard_pro: 'ClickGUARD Pro',
	standard_pro_plus: 'ClickGUARD Pro+',
	custom_pro: 'Custom Pro'
}
