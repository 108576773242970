import { GButton } from 'components/basic-blocks'
import { GDateTime } from 'components/basic-blocks/g-datetime'
import {
	RiBriefcaseLine,
	RiEarthLine,
	RiGoogleLine,
	RiRobotLine,
	RiUser3Line
} from 'react-icons/ri'
import { NavLink } from 'react-router-dom'
import { NotificationItemProps } from './types'
import { useAssetStore } from '../../../store'
import { GNewTooltip } from '../../../components/basic-blocks/g-tooltip/g-new-tooltip'
import { MdCampaign } from 'react-icons/md'

export const NotificationItem = (props: NotificationItemProps) => {
	const { demoMode } = useAssetStore()
	const { data, unreadIcon, link, notificationId, solo } = props

	const icon = {
		USER: RiUser3Line,
		ACCOUNT: RiBriefcaseLine,
		CAMPAIGN: MdCampaign,
		WEBSITE: RiEarthLine,
		PPC_ACCOUNT: {
			GADS: RiGoogleLine
		},
		SYSTEM: RiRobotLine
	}

	const chooseIcon = () => {
		if (!(data.context_type === 'PPC_ACCOUNT')) return icon[data.context_type]
		if (data.context_type === 'PPC_ACCOUNT') {
			if (data.platform === 'GADS') return icon['PPC_ACCOUNT']['GADS']
			return icon['PPC_ACCOUNT']['GADS']
		}
	}

	const Icon = chooseIcon()

	return (
		<div
			className={`flex justify-around items-center hover:bg-gray-50 hover:cursor-pointer w-full ${
				solo && 'h-20'
			}`}
		>
			<NavLink to={demoMode ? '#' : link}>
				<div className="flex items-center justify-between gap-4 py-2 mr-6">
					<div className="flex items-center justify-start gap-3 w-64">
						<div>
							{Icon && (
								<Icon
									className="w-4 h-4"
									style={{ color: data.color || '#9c73d2' }}
								/>
							)}
						</div>
						<div className="flex flex-col max-w-[225px]">
							<div className="text-sm font-medium text-t-default break-words">
								{data.message}
							</div>
							{data.context_name && (
								<div className="text-sm text-t-secondary truncate max-w-[200px]">
									{data.context_name}
								</div>
							)}
						</div>
					</div>
					<div className="flex items-center justify-end gap-3">
						<div className="text-t-default text-sm">
							<GDateTime date={data.created_at} />
						</div>
					</div>
				</div>
			</NavLink>
			<div>
				{data.id === notificationId ? (
					<GNewTooltip
						content={
							demoMode
								? 'This action is disabled when demo data is displayed'
								: null
						}
					>
						<GButton
							className="z-10"
							type="icon"
							variant="colored"
							disabled={demoMode}
							shape="circle"
							size="xxs"
							loading={data.id === notificationId}
						/>
					</GNewTooltip>
				) : (
					!data.read && unreadIcon
				)}
			</div>
		</div>
	)
}
