import { useAssetStore } from '../../../../../store'
import { GSection, GTooltip } from '../../../../../components/basic-blocks'
import { RiQuestionLine } from 'react-icons/ri'
import { generateReportTitle } from '../types'
import { GoArrowDown } from 'react-icons/go'

export const RecentReports = () => {
	const recentReports = useAssetStore((s) => s.recentReports)

	return (
		<div className="w-full">
			<GSection>
				<div className="h-[175px]">
					<div className="flex items-center justify-between pb-4">
						<div className="flex gap-x-2 items-center text-xl font-bold">
							Recent reports
							<GTooltip
								className="font-normal"
								content="This shows the recent generated reports"
							>
								<RiQuestionLine />
							</GTooltip>
						</div>
					</div>
					{recentReports.length === 0 ? (
						<div className="flex items-center justify-center pt-8">
							<p className="text-t-secondary">
								No recent reports available you can schedule or download them
							</p>
						</div>
					) : (
						<div className="flex items-center justify-between">
							<div className="flex flex-col gap-3 w-full pr-3">
								{recentReports.slice(0, 3).map((r) => (
									<div
										key={r.id}
										className="text-lg w-full text-t-secondary flex items-center justify-between"
									>
										<p>{generateReportTitle(r)}</p>
										<a
											href={r.download_link}
											target="_blank"
											className="flex justify-center gap-x-1 items-center h-[24px] py-3 w-[64px] bg-white rounded-lg hover:bg-gray-50 cursor-pointer border"
										>
											<GoArrowDown className="h-3.5 w-3.5 text-primary-500" />
											<span className="text-sm font-bold text-t-default">
												{r?.format}
											</span>
										</a>
									</div>
								))}
							</div>
							<div className="flex flex-col gap-3 w-full pl-3 border-t-border border-l">
								{recentReports.slice(3, 6).map((r) => (
									<div
										key={r.id}
										className="text-lg w-full text-t-secondary flex items-center justify-between"
									>
										<p>{generateReportTitle(r)}</p>
										<a
											href={r.download_link}
											target="_blank"
											className="flex justify-center gap-x-1 items-center h-[24px] py-3 w-[64px] bg-white rounded-lg hover:bg-gray-50 cursor-pointer border"
										>
											<GoArrowDown className="h-3.5 w-3.5 text-primary-500" />
											<span className="text-sm font-bold text-t-default">
												{r?.format}
											</span>
										</a>
									</div>
								))}
							</div>
						</div>
					)}
				</div>
			</GSection>
		</div>
	)
}
