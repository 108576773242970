import { useAdminStore, useUiStore } from '../../../store'
import { GBadge } from '../../../components/basic-blocks/g-badge'
import moment from 'moment'
import { GButton } from '../../../components/basic-blocks'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { useShallow } from 'zustand/react/shallow'
import { useEffect, useState } from 'react'
import { InvoiceDTO } from '../../../../../api-models'
import { GNewPagination } from '../../../components/basic-blocks/g-table/g-pagination/g-new-pagination'

const typeStyles: { [key: string]: string } = {
	trial: 'primary',
	subscription: 'green',
	prorate: 'amber'
}

export const LegacyInvoices = ({ email }: { email: string }) => {
	const [id, setId] = useState('')
	const [skip, setSkip] = useState(0)
	const [data, setData] = useState<{ total: number; data: InvoiceDTO[] }>({
		total: 0,
		data: []
	})
	const downloadLoading = useUiStore((s) =>
		s.isLoading('ADMIN_DOWNLOAD_LEGACY_INVOICE')
	)
	const { getLegacyInvoices, downloadLegacyInvoice } = useAdminStore(
		useShallow((state) => ({
			getLegacyInvoices: state.getLegacyInvoices,
			downloadLegacyInvoice: state.downloadLegacyInvoice
		}))
	)
	const handleGetInvoices = async () => {
		if (email) {
			const invoices = await getLegacyInvoices({ email, limit: 5, skip })
			setData(invoices)
		}
	}

	const pageListener = (skip: number) => {
		setSkip(skip)
	}

	useEffect(() => {
		handleGetInvoices()
	}, [skip])

	return (
		<div>
			{data?.data?.length === 0 && (
				<div className="flex w-full justify-center text-t-secondary my-5">
					This user doesn't have any paid invoices.
				</div>
			)}
			<ul role="list" className="flex flex-col gap-6">
				{data?.data?.map((invoice) => (
					<li className="text-t-dark text-sm flex justify-between items-center">
						<div className="flex gap-4">
							<div className="w-[90px] flex justify-center items-center">
								<GBadge
									color={typeStyles[invoice.type]}
									text={invoice.type}
									className={'py-1 px-3 h-[20px] font-bold'}
								/>
							</div>
							<div className="text-t-default font-bold text-base min-w-[64px] text-right">
								${(invoice.total / 100).toFixed(2).toLocaleString()}
							</div>
							<div className="text-t-dark text-base">
								{moment(invoice.created_at).format('LL')}
							</div>
						</div>
						<GButton
							size="sm"
							color={'neutral'}
							variant={'text'}
							labelClassName="font-bold text-t-default"
							loading={downloadLoading && id === invoice.id}
							onClick={() => {
								setId(invoice.id)
								downloadLegacyInvoice(invoice.id)
							}}
							iconClassName={'text-primary-500'}
							className={'rounded-md font-bold w-[92px] h-[25px]'}
						>
							<div className="flex items-center gap-2">
								<AiOutlineCloudDownload className="h-3 w-3" /> Download
							</div>
						</GButton>
					</li>
				))}
			</ul>
			{data?.data?.length ? (
				<div className="border-t mt-12">
					<GNewPagination
						itemsCount={data.total}
						pageSize={5}
						currentPage={skip / 5 + 1}
						pageListener={pageListener}
					/>
				</div>
			) : null}
		</div>
	)
}
