import { ClicksPageFiltersContextProvider } from './context'
import { lazy, Suspense } from 'react'
import { GLazyLoading } from '../../../../components/basic-blocks'

const ClicksPageView = lazy(() =>
	import('./view')
		.then((module) => {
			return { default: module.ClicksPageView }
		})
		.catch((error) => {
			console.error('Failed to load ClicksPageView:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)

export const ClicksPage = () => {
	return (
		<ClicksPageFiltersContextProvider>
			<Suspense fallback={<GLazyLoading />}>
				<ClicksPageView />
			</Suspense>
		</ClicksPageFiltersContextProvider>
	)
}
