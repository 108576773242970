export const getValue = (
	mapState: Map<any, any>,
	key: string,
	value: string
) => {
	const currentValue = mapState.get(key)
	const valueAlreadyExist = currentValue?.some((v: string) => v === value)

	if (valueAlreadyExist) {
		return currentValue?.filter((v: string) => v !== value)
	}

	return [...(mapState.get(key) || []), value]
}
