import { AssetDTO, ProductAccountDTO } from 'api-models'
import { UserAvatar, WorkspaceAvatar } from 'components'
import { GButton, GLoading, GSection } from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'
import { InfoItem, InfoItemGroup } from 'components/basic-blocks/g-info-table'

import { useAdminStore, useUiStore } from 'store'
import { useShallow } from 'zustand/react/shallow'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { RiLoginCircleLine } from 'react-icons/ri'
import { NavLink, useNavigate } from 'react-router-dom'
import { AdminProductAccounts } from 'services/admin'

export const InfoSection = (props: { asset: AssetDTO }) => {
	const { asset } = props
	const navigate = useNavigate()

	const { setImpersonation } = useAdminStore(
		useShallow((state) => ({
			setImpersonation: state.setImpersonation
		}))
	)
	const isLoading = useUiStore((s) => s.isLoading)

	const [loadingAccount, setLoadingAccount] = useState(true)
	const [account, setAccount] = useState<ProductAccountDTO | null>(null)

	useEffect(() => {
		async function getWorkspace() {
			try {
				const account = await AdminProductAccounts.getById(asset.account_id)
				setAccount(account)
			} catch (e) {
				console.log(e)
			} finally {
				setLoadingAccount(false)
			}
		}

		getWorkspace()
	}, [])

	const impersonate = async () => {
		try {
			await setImpersonation(asset.user_info?.user_id ?? '')
			navigate('/')
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<GSection>
			<InfoItemGroup
				title="Basic information"
				className="-mt-5 border-t-0 rounded-t-lg"
			>
				<InfoItem label="Website ID" className="overflow-clip">
					<div className="flex justify-between items-center gap-2">
						<code className="text-red-600">{asset.id}</code>
					</div>
				</InfoItem>
				<InfoItem label="Name">
					<div className="truncate max-w-[15rem]">{asset.name}</div>
				</InfoItem>

				<InfoItem label="Owner">
					<div className="flex space-x-2 items-center">
						<div className="flex items-center mr-2">
							<GButton
								shape="square"
								type="icon"
								icon={RiLoginCircleLine}
								loading={isLoading('ADMIN_SET_IMPERSONATION')}
								variant="contained"
								color="primary"
								size="xs"
								onClick={impersonate}
							>
								Impersonate
							</GButton>
						</div>
						<div className="flex space-x-2 items-center">
							<UserAvatar size={8} src={asset.user_info?.avatar} />
							<NavLink to={`/admin/user/${asset.user_info?.user_id}`}>
								<div className="flex flex-col group cursor-pointer">
									<div className="font-semibold text-primary-500 group-hover:text-primary-600 truncate max-w-[200px]">
										{asset.user_info?.name}
									</div>
									<div className="text-gray-500">{asset.user_info?.email}</div>
								</div>
							</NavLink>
							{/* <div className='flex flex-col group'>
									<div className='font-semibold text-gray-500'>
										{asset.user_info?.name}
									</div>
									<div className='text-t-secondary'>
										{asset.user_info?.email}
									</div>
								</div> */}
						</div>
					</div>
				</InfoItem>
				<InfoItem label="Workspace">
					{loadingAccount ? (
						<GLoading addMargin={false} size={4} />
					) : (
						<div className="flex space-x-2">
							<WorkspaceAvatar workspace={account} size={8} />
							<div className="flex flex-col justify-center">
								<NavLink to={`/admin/subscription/${asset.account_id}`}>
									<div className="font-medium text-primary-500 hover:text-primary-600 cursor-pointer truncate max-w-[200px]">
										{account?.name}
									</div>
								</NavLink>
							</div>
						</div>
					)}
				</InfoItem>
				<InfoItem label="Created">
					<span className="text-gray-500">
						{asset.created_at
							? moment(asset.created_at).format('MMMM Do YYYY - h:mm a')
							: 'N/A'}
					</span>
				</InfoItem>
				<InfoItem label="Status">
					<div className="flex">
						{asset.status === 'ACTIVE' ? (
							<GBadge text={asset.status} color="green" />
						) : (
							<GBadge text="INACTIVE" color="red" />
						)}
					</div>
				</InfoItem>
				<InfoItem label="Tracking code">
					<div className="flex">
						{asset.tc_installed ? (
							<GBadge text="Installed" color="green" />
						) : (
							<GBadge text="Not installed" color="red" />
						)}
					</div>
				</InfoItem>
				<InfoItem label="Conversion code">
					<div className="flex">
						{asset.cc_installed ? (
							<GBadge text="Installed" color="green" />
						) : (
							<GBadge text="Not installed" color="amber" />
						)}
					</div>
				</InfoItem>
			</InfoItemGroup>
		</GSection>
	)
}
