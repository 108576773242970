import { ProductAccountDTO } from 'api-models'
import {
	GAdminDashboardTable,
	GLoading,
	GSection
} from 'components/basic-blocks'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { RiErrorWarningLine } from 'react-icons/ri'
import { useUiStore } from 'store'
import { columns } from './columns'
import { potentialMRR, MRRBreakDownByPrice } from 'utils'

export const TrialTab = () => {
	const { adminStore } = useStores()
	const { loadAccounts, downloadAccounts, getPipeline } = adminStore
	const isLoading = useUiStore((s) => s.isLoading)

	const [state, setState] = useState<any>({})
	const [skip, setSkip] = useState(0)
	const [query, setQuery] = useState({
		subscription_status: 'ACTIVE',
		subscription_type: 'TRIAL',
		sort: ''
	})

	useEffect(() => {
		;(async () => {
			const accounts = await loadAccounts({ ...query, skip })
			const pipeline = await getPipeline({ ...query })
			setState({ accounts, pipeline })
		})()
	}, [query.sort, skip])

	const handleSort = (key: string) => {
		setQuery({ ...query, sort: key })
	}

	if (!state.accounts || !state.pipeline) return <GLoading />
	return (
		<>
			<GSection className="pt-1" loading={isLoading('ADMIN_GET_ACCOUNTS')}>
				<GAdminDashboardTable<ProductAccountDTO>
					refreshAction={async () => {
						const accounts = await loadAccounts(query)
						setState({ accounts })
					}}
					downloadAction={async () => {
						await downloadAccounts(query, 'trials')
					}}
					potentialMRR={
						<>
							<div className="w-full flex items-center justify-between">
								<div className="font-bold text-xl text-t-default mx-1">
									Potential MRR in pipeline:
								</div>
								<div className="font-bold text-xl text-primary-500 mx-1">
									{potentialMRR(state.pipeline.trial)}
								</div>
							</div>
						</>
					}
					MRRBreakdownByPlan={MRRBreakDownByPrice(state.pipeline.trial)}
					isDownloadable={true}
					actionButtonsPlacement="bottom"
					title="Trials"
					columns={columns}
					data={state.accounts.data}
					pageSize={state.pageSize}
					sortListener={handleSort}
					sort={query.sort}
					skip={skip}
					setSkip={setSkip}
					totalCount={state.accounts.total}
					emptyState={
						<div className="flex flex-col items-center justify-center w-full p-8 text-center">
							<RiErrorWarningLine className="w-8 h-8 text-gray-500" />
							<h3 className="mt-2 text-md font-medium text-gray-700">
								No data
							</h3>
						</div>
					}
				/>
			</GSection>
		</>
	)
}
