import { useCallback, useEffect, useMemo, useState } from 'react'
import { RiCheckLine } from 'react-icons/ri'
import { useUiStore } from 'store'
import { GoogleOAuthAccountLinkDTO } from '../../../../../../api-models/src'
import {
	GtmAccount,
	GtmContainer,
	GtmWorkspace
} from '../../../../../../api-models/src/advertiser/gtm'
import {
	GButton,
	GListBox,
	GTooltip,
	GTransition
} from '../../../../components/basic-blocks'
import { GoogleTagManagerAuthConfig } from '../../../../config'
import { useStores } from '../../../../hooks'

interface ICreateTag {
	gtmAccount: GtmAccount | null
	gtmContainer: GtmContainer | null
	gtmWorkspace: GtmWorkspace | null
	oauth: 'PENDING' | 'WAITING' | 'COMPLETED'
	link: GoogleOAuthAccountLinkDTO | null
}

const getAuthCode = () => {
	return window.localStorage.getItem('google_oauth_code')
}

const clearAuthCode = () => {
	window.localStorage.removeItem('google_oauth_code')
}

interface IStateError {
	link: string | undefined
	gtmAccount: string | undefined
	gtmContainer: string | undefined
	gtmWorkspace: string | undefined
}

export const GtmAccountSelector = () => {
	const {
		assetStore: {
			getGtmAccounts,
			asset,
			gtmTag,
			getGtmContainers,
			getGtmWorkspaces,
			demoMode,
			createGtmTag
		},
		accountStore: { createGtmAuth, account, userAccess, gtmLinks },
		userStore: { user }
	} = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	const [gtmAccounts, setGtmAccounts] = useState<GtmAccount[]>([])
	const [gtmContainer, setContainer] = useState<GtmContainer[]>([])
	const [gtmWorkspaces, setWorkspaces] = useState<GtmWorkspace[]>([])
	const [isGTMUpdated, setIsGTMUpdated] = useState(!!gtmTag)
	const [state, setState] = useState<ICreateTag>({
		gtmAccount: null,
		gtmContainer: null,
		link: null,
		gtmWorkspace: null,
		oauth: 'PENDING'
	})

	const [error, setError] = useState<IStateError>({
		link: undefined,
		gtmAccount: undefined,
		gtmContainer: undefined,
		gtmWorkspace: undefined
	})

	const isGTMDisabled = useMemo(() => {
		return userAccess(user!.id) === 'READ' || demoMode || isGTMUpdated
	}, [user, demoMode, isGTMUpdated])

	const reset = useCallback(() => {
		clearAuthCode()
		setState((prevState) => {
			return { ...prevState, oauth: 'PENDING' }
		})
	}, [])

	const fetchGtmAccounts = async (id: string) => {
		if (!account || !asset || !id) return
		const data = (await getGtmAccounts(account.id, asset.id, id)) ?? []
		setGtmAccounts(data)
	}

	const fetchContainer = async () => {
		if (
			!account ||
			!asset ||
			!state.link ||
			!state.gtmAccount?.id ||
			isGTMDisabled
		)
			return
		const data =
			(await getGtmContainers(
				account!.id,
				asset!.id,
				state.gtmAccount.id,
				state.link.id
			)) ?? []
		setContainer(data)
	}

	const fetchWorkspace = async () => {
		if (!state.gtmContainer || isGTMDisabled) return
		const data =
			(await getGtmWorkspaces(
				account!.id,
				asset!.id,
				state.gtmAccount!.id,
				state.gtmContainer!.id,
				state.link!.id
			)) ?? []
		setWorkspaces(data)
	}

	const handleAccountChange = async (val: GtmAccount) => {
		if (!state.link) return
		setState({
			...state,
			gtmAccount: val,
			gtmContainer: null,
			gtmWorkspace: null
		})
		setError((prevState) => ({ ...prevState, gtmAccount: undefined }))
		setWorkspaces([])
	}

	const handleAccess = async (val: GoogleOAuthAccountLinkDTO) => {
		setState({
			...state,
			gtmAccount: null,
			gtmContainer: null,
			gtmWorkspace: null,
			link: val
		})
		fetchGtmAccounts(val.id)
		setIsGTMUpdated(false)
		setContainer([])
		setWorkspaces([])
	}

	const handleContainerChange = async (val: GtmContainer) => {
		if (!state.link) return
		setState({ ...state, gtmContainer: val, gtmWorkspace: null })
		setError((prevState) => ({ ...prevState, gtmContainer: undefined }))
	}

	const handleCreateTag = async () => {
		setError({
			link: !state.link ? 'Please select an Auth account' : undefined,
			gtmAccount: !state.gtmAccount ? 'Please select an account' : undefined,
			gtmContainer: !state.gtmContainer
				? 'Please select a container'
				: undefined,
			gtmWorkspace: !state.gtmWorkspace
				? 'Please select a workspace'
				: undefined
		})
		if (!state.gtmWorkspace || !state.link || !account?.id || !asset?.id) return
		await createGtmTag(
			account.id,
			asset.id,
			state.gtmWorkspace.accountId,
			state.gtmWorkspace.containerId,
			state.gtmWorkspace.workspaceId,
			state.link.id
		)
	}

	useEffect(() => {
		if (state.oauth === 'WAITING') {
			const interval = setInterval(async () => {
				const code = getAuthCode()
				if (code && asset && account) {
					clearInterval(interval)
					clearAuthCode()
					await createGtmAuth(account.id, asset.id, code)
					setState({ ...state, oauth: 'COMPLETED' })
				}
			}, 1500)
		}
	}, [state.oauth])

	useEffect(() => {
		if (state?.gtmAccount?.id) {
			fetchContainer()
		}
	}, [state.gtmAccount])

	useEffect(() => {
		if (state?.gtmContainer?.id) {
			fetchWorkspace()
		}
	}, [state.gtmContainer])

	useEffect(() => {
		if (gtmTag && gtmLinks) {
			let link = gtmLinks.find((l) => l.id === gtmTag.link_id)
			if (!link) {
				link = { name: 'Google Auth' } as GoogleOAuthAccountLinkDTO
			}
			setState({
				...state,
				gtmAccount: gtmTag.gtmAccount,
				gtmContainer: gtmTag.gtmContainer,
				gtmWorkspace: gtmTag.gtmWorkspace,
				link
			})
		}
	}, [gtmTag, gtmLinks])

	useEffect(() => {
		if (gtmTag) {
			setIsGTMUpdated(true)
		}
	}, [gtmTag])

	return (
		<div>
			<p className="text-t-secondary text-base mb-6">
				You can install the tracking code automatically by adding it to your GTM
				setup
			</p>
			<div className="flex flex-col gap-3">
				<div className={'flex items-center justify-between'}>
					<p className="text-t-secondary font-bold text-base">Authorization</p>
					<div className="flex items-center gap-2 w-[360px]">
						<div className="flex-1 relative">
							<GListBox<GoogleOAuthAccountLinkDTO>
								options={gtmLinks}
								value={state.link as any}
								placeholder="Select account"
								onChange={(val) => handleAccess(val)}
								renderLabel={(val) => <span>{val?.name}</span>}
								disabled={isLoading('ASSET_SET_GTM_AUTH')}
								error={error.link}
							/>
						</div>

						<GButton
							variant="text"
							label="New authorization"
							disabled={demoMode || userAccess(user!.id) === 'READ'}
							color="buttonPrimary"
							labelClassName="text-sm font-bold text-t-default"
							className="h-[33px] border-t-input-border"
							loading={
								isLoading('ASSET_SET_GTM_AUTH') || state.oauth === 'WAITING'
							}
							onClick={() => {
								clearAuthCode()
								setState({ ...state, oauth: 'WAITING' })
								window.open(GoogleTagManagerAuthConfig.AUTH_URL, '_blank')
							}}
						/>
					</div>
				</div>
				<div className="flex justify-between items-center">
					<p className="text-t-secondary font-bold text-base">GTM Account</p>
					<GListBox<GtmAccount>
						className="w-[360px]"
						options={gtmAccounts}
						value={state.gtmAccount as any}
						placeholder="Select account"
						onChange={(val) => handleAccountChange(val)}
						renderLabel={(val) => <span>{val?.name}</span>}
						disabled={
							isLoading('ASSET_GET_GTM_ACCOUNTS' || 'ASSET_SET_GTM_AUTH') ||
							isGTMDisabled
						}
						error={error.gtmAccount}
					/>
				</div>
				<div className="flex justify-between items-center">
					<p className="text-t-secondary font-bold text-base">Container</p>
					<GListBox<GtmContainer>
						className="w-[360px]"
						options={gtmContainer}
						value={state.gtmContainer as any}
						placeholder="Select container"
						onChange={(val) => handleContainerChange(val)}
						renderLabel={(val) => <span>{val?.name}</span>}
						disabled={
							isLoading('ASSET_GET_GTM_CONTAINERS' || 'ASSET_SET_GTM_AUTH') ||
							isGTMDisabled
						}
						error={error.gtmContainer}
					/>
				</div>
				<div className="flex justify-between items-center">
					<p className="text-t-secondary font-bold text-base">Workspace</p>
					<GListBox<GtmWorkspace>
						className="w-[360px]"
						options={gtmWorkspaces}
						value={state.gtmWorkspace as any}
						placeholder="Select container"
						onChange={(val) => setState({ ...state, gtmWorkspace: val })}
						renderLabel={(val) => <span>{val?.name}</span>}
						disabled={
							isLoading('ASSET_GET_GTM_WORKSPACES' || 'ASSET_SET_GTM_AUTH') ||
							isGTMDisabled
						}
						error={error.gtmWorkspace}
					/>
				</div>
			</div>
			<div>
				{isGTMUpdated ? (
					<div className="flex items-center gap-2 pt-6">
						<RiCheckLine className="w-6 h-6 text-green-500" />
						<p className="text-t-secondary text-base">
							Tracking code installed.
						</p>
					</div>
				) : (
					<div className="py-6">
						<p>
							New tag will be created with ClickGUARD tracking code and we will
							publish a new versions for the selected workspace.
						</p>
						<div className="flex justify-end items-center">
							<GTooltip
								content={
									userAccess(user!.id) === 'READ'
										? "Analysts don't have permission for this action"
										: undefined
								}
							>
								<GButton
									label="Add tracking code"
									className="min-w-[134px] h-[33px]"
									labelClassName="text-sm font-bold"
									color="primary"
									variant="contained"
									disabled={
										userAccess(user!.id) === 'READ' ||
										demoMode ||
										isLoading('ASSET_SET_GTM_TAGS')
									}
									onClick={handleCreateTag}
									loading={isLoading('ASSET_SET_GTM_TAGS')}
									size="md"
								/>
							</GTooltip>
						</div>
						<GTransition show={state.oauth === 'WAITING'}>
							<div className="flex flex-col items-center text-md">
								<span>Waiting for authorization...</span>
								<span
									className="text-red-700 cursor-pointer"
									onClick={() => reset()}
								>
									Cancel
								</span>
							</div>
						</GTransition>
					</div>
				)}
			</div>
		</div>
	)
}
