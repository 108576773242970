import { ActivateAccount } from 'components/activate-account/activate-account'
import { useState } from 'react'

export const ActivateAccountNotice = () => {
	const [open, setOpen] = useState(false)

	return (
		<div className="flex items-center">
			<span>
				Page is showing demo data. Activate your workspace to start using
				ClickGUARD!
			</span>
			<button
				onClick={() => setOpen(true)}
				className="ml-1 text-primary-500 font-bold"
			>
				Activate
			</button>
			<div>
				<ActivateAccount open={open} close={() => setOpen(false)} />
			</div>
		</div>
	)
}
