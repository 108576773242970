import { PageHeader } from '../../../../components/page-header'
import { lazy, Suspense, useState } from 'react'
import moment from 'moment'
import { GDateRange, GLazyLoading } from '../../../../components/basic-blocks'

const AffiliateDashboard = lazy(() =>
	import('./dashboard')
		.then((module) => {
			return { default: module.AffiliateDashboard }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)

export const NonClickGuardAffiliateDashboard = () => {
	const [dateRange, setDateRange] = useState({
		from: moment().subtract(30, 'days').startOf('day').toDate(),
		to: moment().startOf('hour').toDate()
	})
	const handleDateRangeChange = (e: { from: Date; to: Date }) => {
		setDateRange({ from: e.from, to: e.to })
	}
	return (
		<div>
			<div className="flex items-center justify-between">
				<PageHeader
					title={'Analytics'}
					subtitle={'Explore the performance of your affiliate link'}
				/>
				<GDateRange
					onApply={(e) => handleDateRangeChange({ from: e.from, to: e.to })}
					align="right"
				/>
			</div>
			<Suspense fallback={<GLazyLoading />}>
				<AffiliateDashboard dateRange={dateRange} />
			</Suspense>
		</div>
	)
}
