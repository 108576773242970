import { GButton, GConfirm, GSection, GTooltip } from 'components/basic-blocks'
import { useStores } from 'hooks'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUiStore } from 'store'

export const DeleteAccount = () => {
	const {
		accountStore,
		assetStore,
		userStore: { user }
	} = useStores()
	const isLoading = useUiStore((s) => s.isLoading)
	const {
		account,
		deleteAccount,
		userAccessLevel,
		setAccount,
		wantsToSeeDemoData
	} = accountStore
	const { setAsset, setDemoMode, getAllAssets } = assetStore
	const navigate = useNavigate()
	const [open, setOpen] = useState<boolean>(false)

	const handleAccountDelete = async (id: string) => {
		try {
			const onSuccess = () => {
				setOpen(false)
			}
			const refreshedAccountsAfterDelete = await deleteAccount(id, onSuccess)
			const refreshedAccountsWithAssets = await getAllAssets()

			if (
				refreshedAccountsAfterDelete &&
				refreshedAccountsAfterDelete.length > 0
			) {
				const account = refreshedAccountsAfterDelete[0]
				setAccount(account)

				const selectedAccount = refreshedAccountsWithAssets?.find(
					(accountWithAsset) => accountWithAsset.id === account.id
				)
				let assetId = 'website-demo'

				if (selectedAccount) {
					const asset =
						selectedAccount.assets && selectedAccount.assets.length > 0
							? selectedAccount.assets[0]
							: null
					if (asset) {
						assetId = asset.id
						setAsset(asset)
					} else {
						setDemoMode(true)
					}
				} else {
					setAsset(null)
					setDemoMode(true)
				}

				navigate(`/workspace/${account.id}/asset/${assetId}`)
			} else {
				setAccount(null)
				setAsset(null)
				setDemoMode(true)
				navigate('/workspace/workspace-demo/asset/website-demo')
			}
		} catch (e) {
			return
		}
	}

	const isDeleteButtonDisabled =
		user && user.admin
			? false
			: account?.status === 'ACTIVE' || userAccessLevel(user!.id) !== 'OWNER'

	return (
		<GSection title="Danger zone" lineBreak={true} lineBreakColor="gray-100">
			<div className="flex justify-between items-center mt-6">
				<p>Remove workspace and all it’s data</p>
				<GConfirm
					title={`Remove ${account?.name || 'Workspace'}`}
					description="Are you sure you want to remove this workspace and all it’s data? All websites and PPC accounts in this workspace will be removed as well. This action cannot be undone. Proceed?"
					type="danger"
					open={open}
					setOpen={(e) => !isDeleteButtonDisabled && setOpen(e)}
					onConfirm={() => handleAccountDelete(account!.id)}
					loading={isLoading('USER_REMOVE_ACCOUNT')}
				>
					<GTooltip
						content={
							wantsToSeeDemoData
								? 'This action is disabled when demo data is displayed'
								: isDeleteButtonDisabled &&
								  userAccessLevel(user?.id) !== 'OWNER'
								? 'Only the Owner of the workspace have permission for this action'
								: undefined
						}
					>
						<GButton
							label="Delete"
							color="danger"
							size="lg"
							labelClassName="font-bold"
							className="h-[30px] w-[80px]"
							disabled={isDeleteButtonDisabled}
						/>
					</GTooltip>
				</GConfirm>
			</div>{' '}
		</GSection>
	)
}
