import { GSection } from 'components/basic-blocks'
import { GtmIcon } from 'components/icons'
import { useEffect } from 'react'
import { GoogleOAuthAccountLinkDTO } from '../../../../../api-models'
import { useStores } from '../../../hooks'

export const GoogleTagManagerConnection = () => {
	const {
		accountStore: { gtmLinks, getGtmLinks, account }
	} = useStores()
	useEffect(() => {
		if (account?.id) {
			getGtmLinks(account.id)
		}
	}, [account?.id])
	return (
		<>
			<GSection
				title={'Google Tag Manager'}
				titleClass={gtmLinks?.length > 0 ? 'mb-6' : 'mb-0'}
				lineBreak={gtmLinks.length > 0}
				lineBreakColor="gray-100"
			>
				{gtmLinks.map((v) => {
					return <GTMList link={v} key={v.id} />
				})}
			</GSection>
		</>
	)
}

const GTMList = ({ link }: { link: GoogleOAuthAccountLinkDTO }) => {
	return (
		<div className="flex justify-between items-center mt-6">
			<div className="flex justify-start items-center space-x-4">
				<GtmIcon className="text-red-500 w-6 h-6" />
				<div className="flex flex-col justify-start items-start ">
					<div className="text-sm leading-5 text-t-secondary">Google OAuth</div>
					<div className="text-md text-t-default">{link?.name}</div>
				</div>
			</div>
		</div>
	)
}
