import { GButton, GDropdown } from 'components/basic-blocks'
import { eventLinkGenerator } from 'config/notificationsLinksMapping'
import {
	RiCheckLine,
	RiCloseLine,
	RiFilter3Line,
	RiNotification3Line
} from 'react-icons/ri'
import { useAssetStore, useUiStore } from 'store'
import { GVirtuosoList } from '../../../components/basic-blocks/g-virtualized-list/virtuos-virtulized-lits'
import { NotificationItem } from './notificationItem'
import { DropdownNotificationsProps } from './types'
import { GNewTooltip } from '../../../components/basic-blocks/g-tooltip/g-new-tooltip'

export const DropdownNotifications = (props: DropdownNotificationsProps) => {
	const {
		setOpen,
		state,
		onReadOneNotification,
		onReadAllNotification,
		notificationId
	} = props
	const isLoading = useUiStore((s) => s.isLoading)
	const { demoMode } = useAssetStore()

	const showNotificationAlert = state.notifications.length > 0

	const MenuButton = () => {
		return (
			<div className="w-9 h-9 flex justify-center content-center cursor-pointer p-2 border border-t-border-lighter rounded-md relative">
				<RiNotification3Line className="h-4 w-4 text-t-default" />
				{showNotificationAlert && (
					<div className="bg-red-500 text-white absolute bottom-5 left-5 rounded-full flex items-center justify-center w-5 h-5 text-[9px]">
						{state.notifications.length > 99
							? '99+'
							: state.notifications.length}
					</div>
				)}
			</div>
		)
	}

	return (
		<GDropdown anchor="right" menuButton={<MenuButton />}>
			{(close?: () => void) => {
				return (
					<div className="origin-top-left absolute border right-2 mt-2 w-[28rem] rounded-md shadow-lg bg-white ring-opacity-5 divide-y divide-gray-100 z-50">
						<div className="py-4 px-4 max-w-full">
							<div className="text-t-default font-medium text-lg mb-4">
								Notifications
							</div>
							<div className="overflow-y-hidden overflow-x-hidden max-w-full max-h-60">
								{state.notifications?.length === 0 && (
									<div className="flex items-center justify-center text-t-default mt-2">
										No new notifications
									</div>
								)}
								{state.notifications?.length > 0 && (
									<div className="max-h-60">
										<GVirtuosoList length={state.notifications.length}>
											{(index: number) => {
												const link = eventLinkGenerator(
													state.notifications[index]
												)
												return (
													<NotificationItem
														solo={state.notifications.length === 1}
														key={state.notifications[index].id}
														link={link}
														data={state.notifications[index]}
														notificationId={notificationId}
														unreadIcon={
															<GButton
																className="z-10"
																type="icon"
																variant="colored"
																disabled={demoMode}
																shape="circle"
																icon={RiCloseLine}
																size="xxs"
																onClick={() =>
																	onReadOneNotification(
																		state.notifications[index].id,
																		{ read: 'false' }
																	)
																}
															>
																{demoMode
																	? 'This action is disabled when demo data is displayed'
																	: 'Mark as read'}
															</GButton>
														}
													/>
												)
											}}
										</GVirtuosoList>
									</div>
								)}
							</div>
							<div className="flex items-center justify-center gap-3 mt-5">
								<GButton
									className="text-t-default"
									label="View all"
									icon={RiFilter3Line}
									onClick={() => {
										close && close()
										setOpen(true)
									}}
								/>
								<GNewTooltip
									content={
										demoMode
											? 'This action is disabled when demo data is displayed'
											: null
									}
								>
									<GButton
										label="Mark all as read"
										icon={RiCheckLine}
										color="primary"
										disabled={demoMode}
										variant="text"
										onClick={() => onReadAllNotification({ read: 'false' })}
										loading={isLoading('READ_NOTIFICATIONS')}
									/>
								</GNewTooltip>
							</div>
						</div>
					</div>
				)
			}}
		</GDropdown>
	)
}
