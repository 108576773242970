import { GButton, GTooltip } from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'
import moment from 'moment'
import { useState } from 'react'
import { RiDeleteBin2Line, RiFileEditLine, RiRefreshLine } from 'react-icons/ri'
import {
	formatCurrency,
	formatCurrencyDiscount,
	formatPriceInterval,
	formatSpend
} from 'utils'
import { OfferViewProp } from '../../pages/admin/entites/user/info-tab/types'
import { AdminCreateOffer } from '../create-offer'

export const OfferView = (props: OfferViewProp) => {
	const { offer, onDeleteOffer, singleOffer, onSendEmail } = props
	const [loading, setLoading] = useState(false)
	const offersStatusMapper = {
		REJECTED: { name: 'Rejected', color: 'red' },
		ACCEPTED: { name: 'Accepted', color: 'green' },
		READY: { name: 'Pending', color: 'neutral' },
		DRAFT: { name: 'Draft', color: 'primary' },
		PAYMENT_IN_PROGRESS: { name: 'Payment in progress', color: 'amber' },
		SCHEDULED_UPGRADE: { name: 'Upgrade scheduled', color: 'blue' }
	}

	const handleOnSendEmail = async (id: string) => {
		try {
			setLoading(true)
			await onSendEmail(id)
			setLoading(false)
		} catch (e) {
			setLoading(false)
		}
	}
	return (
		<div className="flex justify-between space-x-4 px-6 mt-4">
			<div className="flex items-center min-w-[150px]">
				<div className="flex-col px-4 text-xs space-y-2">
					<div className="text-gray-500 break-words">Status</div>
					<GTooltip
						content={
							offersStatusMapper[offer.status].name == 'Accepted'
								? moment(offer.accepted_at).format('llll')
								: undefined
						}
					>
						<GBadge
							color={offersStatusMapper[offer.status].color}
							text={offersStatusMapper[offer.status].name}
						/>
					</GTooltip>
				</div>
			</div>
			<div className=" flex-1 flex justify-between items-center space-x-2">
				<div className="flex justify-start">
					<div className="flex-col px-4 text-xs space-y-2">
						<div className="text-gray-500 break-words">Monthly spend</div>
						<div className="font-medium text-gray-700 break-words w-fit">
							<GBadge color="purple" text={`${formatSpend(offer.spend)}`} />
						</div>
					</div>
					<div className="flex-col-2 px-4 text-xs space-y-2 min-w-[150px]">
						<div className="text-gray-500 break-words">Price</div>
						<div className="font-medium text-gray-700 break-words w-fit">
							<GBadge
								color="green"
								text={`${!offer?.coupon ? formatCurrency(offer.price) : ''} ${
									offer?.coupon
										? formatCurrencyDiscount(offer.price, {
												amountOff: offer?.coupon?.amount_off,
												percentOff: offer?.coupon?.percent_off
										  })
										: ''
								}


								 ${formatPriceInterval(offer.interval, offer.interval_count)}`}
							/>
						</div>
					</div>
					{offer?.coupon && (
						<div className="flex-col px-4 text-xs space-y-2">
							<div className="text-gray-500 break-words">Discount</div>
							<div className="font-medium text-gray-700 break-words w-fit">
								<GTooltip
									content={
										offer.coupon
											? `${
													offer?.coupon?.amount_off
														? formatCurrency(offer?.coupon?.amount_off)
														: `${offer?.coupon?.percent_off}%`
											  } off for ${
													offer?.coupon?.duration === 'repeating'
														? `${offer?.coupon?.months} months`
														: offer?.coupon?.duration
											  }. Full price plan: ${formatCurrency(
													offer.price
											  )}  ${formatPriceInterval(
													offer.interval,
													offer.interval_count
											  )}`
											: undefined
									}
								>
									<GBadge color="amber" text={'%'} />
								</GTooltip>
							</div>
						</div>
					)}
				</div>
				<div className="flex justify-end items-center">
					<div className="flex text-xs items-center space-y-2">
						<div className="flex gap-1">
							{['READY', 'DRAFT'].includes(offer.status) && (
								<>
									<GButton
										label={'Resend email'}
										type="icon"
										icon={RiRefreshLine}
										color="neutral"
										size="xs"
										onClick={() => handleOnSendEmail(offer.id)}
										tooltipPosition={singleOffer ? 'top' : undefined}
										loading={loading}
									/>
									<AdminCreateOffer
										user={{ id: offer.user_id || '', name: '' }}
										updateOffer={offer}
									>
										{(open) => (
											<GButton
												label={'Update offer'}
												type="icon"
												icon={RiFileEditLine}
												color="primary"
												size="xs"
												onClick={() => open()}
											/>
										)}
									</AdminCreateOffer>
								</>
							)}
							{offer.status !== 'ACCEPTED' && (
								<GButton
									label={'Delete offer'}
									type="icon"
									icon={RiDeleteBin2Line}
									color="danger"
									size="xs"
									onClick={() => onDeleteOffer(offer.id)}
									tooltipPosition={singleOffer ? 'top' : undefined}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
