import { GDialog, GTransition } from 'components/basic-blocks'
import { PlatformSelector } from 'components/platform-selector'
import { useState } from 'react'
import { AddPPCConnectionProps, AddPPCConnectionState } from './types'

import {
	BingAccountLinkDTO,
	FacebookAccountLinkDTO,
	GoogleAdsAdAccountDTO
} from 'api-models'
import { useAccountStore, useAssetStore } from 'store'
import { useShallow } from 'zustand/react/shallow'
import { BadsForm } from './forms/bads'
import { FadsForm } from './forms/fads'
import { GadsForm } from './forms/gads'
import { MccLinkConnection } from '../mcc-link-connection'

export const AddNewPPCAccount = (props: AddPPCConnectionProps) => {
	const { children } = props
	const { accountLinks, account } = useAccountStore(
		useShallow((s) => ({
			accountLinks: s.accountLinks,
			account: s.account
		}))
	)

	const { asset } = useAssetStore(
		useShallow((s) => ({
			skipMccSupport: s.skipMccSupport,
			asset: s.asset
		}))
	)
	const [step, setStep] = useState<{
		step: 'ACCOUNT' | 'LINK'
		adAccount: GoogleAdsAdAccountDTO | null
	}>({ step: 'ACCOUNT', adAccount: null })
	const [dialogOpen, setDialogOpen] = useState(false)
	const [state, setState] = useState<AddPPCConnectionState>({
		platform: props?.platform || 'GADS'
	})

	const googleAdsConnections = accountLinks.filter(
		(link) => link.platform === 'GADS'
	)
	const facebookAdsConnections = accountLinks.filter(
		(link) => link.platform === 'FADS'
	)
	const bingAdsConnections = accountLinks.filter(
		(link) => link.platform === 'BADS'
	)

	const closeDialog = () => {
		setDialogOpen(false)
	}
	const closeLinkDialog = async () => {
		setStep({ step: 'ACCOUNT', adAccount: null })
		setDialogOpen(false)
	}

	return (
		<>
			<GTransition show={step.step === 'LINK'} swap>
				<MccLinkConnection
					open={step.step === 'LINK'}
					onClose={closeLinkDialog}
					heading="New PPC account"
					adAccount={step.adAccount}
					state="CONNECTION"
				/>
			</GTransition>
			<GTransition show={step.step === 'ACCOUNT'} swap>
				{children && children(() => setDialogOpen(true))}
				<GDialog
					title="New PPC account"
					subtitle={`Add a PPC account to ${asset?.name}`}
					open={dialogOpen && step.step === 'ACCOUNT'}
					onClose={() => setDialogOpen(false)}
					maxWidth="2xl"
					className="sm:max-w-2xl"
				>
					<GTransition show={true} swap>
						<PlatformSelector
							account={account}
							platform={state.platform}
							setPlatform={(e) => {
								setState({ ...state, platform: e })
							}}
						/>
						{state.platform === 'GADS' && (
							<GadsForm
								closeDialog={closeDialog}
								gadsConnections={googleAdsConnections}
								setStep={setStep}
							/>
						)}
						{account?.billing?.subscription?.plan?.tier === 'legacy' ? null : (
							<>
								{state.platform === 'FADS' && (
									<FadsForm
										closeDialog={closeDialog}
										fadsConnections={
											facebookAdsConnections as FacebookAccountLinkDTO[]
										}
									/>
								)}
								{state.platform === 'BADS' && (
									<BadsForm
										closeDialog={closeDialog}
										badsConnections={bingAdsConnections as BingAccountLinkDTO[]}
									/>
								)}
							</>
						)}
					</GTransition>
				</GDialog>
			</GTransition>
		</>
	)
}
