import { GButton, GLoading, GSwitcher } from 'components/basic-blocks/'
import { useStores } from 'hooks'
import { NavLink, useLocation } from 'react-router-dom'

import { AssetDTO } from 'api-models'
import { WebsiteAvatar } from 'components'
import { AddNewSite } from 'components/add-new-site'
import { BsBriefcase } from 'react-icons/bs'
import { RiAddLine, RiArrowDownSLine, RiCheckLine } from 'react-icons/ri'
import { AllAccountsAssets } from 'services/user/types'
import { classNames, sanitizeURL, setItemsToLocalStorage } from 'utils'
import { useAssetStore } from 'store'
import { truncateString } from '../../../utils/text'
import { useShallow } from 'zustand/react/shallow'

export const AssetSwitcher = () => {
	const { accountStore } = useStores()
	const {
		asset: selectedAsset,
		accountsWithAssets: assets,
		setAsset,
		demoMode,
		setDemoMode
	} = useAssetStore(
		useShallow((s) => {
			return {
				asset: s.asset,
				accountsWithAssets: s.accountsWithAssets,
				setAsset: s.setAsset,
				demoMode: s.demoMode,
				setDemoMode: s.setDemoMode
			}
		})
	)

	const { setAccount, accounts } = accountStore
	if (!assets) return <GLoading />

	return (
		<>
			<div className="asset-switcher hidden md:flex items-center"></div>
			<GSwitcher<AllAccountsAssets>
				selectedItem={<SelectedItem />}
				items={assets}
				footer={<SwitcherContentFooter />}
				renderOption={(i, handleClose) => renderOption(i, handleClose)}
				// filter={(item, query) => item.name.toLowerCase().includes(query.toLowerCase())}
				placeholder="Filter assets..."
				disableSearch
				width="w-[260px]"
			/>
		</>
	)

	function SelectedItem() {
		return (
			<div className="w-[260px] h-9 flex items-center px-4 py-2.5 text-md text-t-dark bg-gray-100 space-x-4 rounded-md cursor-pointer ml-6">
				<div className="flex flex-row items-center w-full">
					<div className="flex items-center gap-2">
						<div>
							<WebsiteAvatar website={selectedAsset} size={6} />
						</div>
						<span className="flex flex-col truncate max-w-[8rem]">
							{demoMode
								? 'No asset selected'
								: sanitizeURL(selectedAsset?.name)}
						</span>
					</div>
				</div>
				<div>
					<RiArrowDownSLine className="h-4 w-4 text-t-dark" />
				</div>
			</div>
		)
	}

	function SwitcherContentFooter() {
		return (
			<div className="flex flex-grow p-2">
				<AddNewSite>
					{(open) => (
						<GButton
							className="w-full text-primary-500"
							color="primary"
							variant="text"
							icon={RiAddLine}
							label={'Add New Website'}
							onClick={() => open()}
						/>
					)}
				</AddNewSite>
			</div>
		)
	}

	function renderOption(option: AllAccountsAssets, handleClose: () => void) {
		const { pathname } = useLocation()
		const handleSelectingAsset = (asset: AssetDTO) => {
			setDemoMode(false)
			setAsset(asset)
			const selectedAccount = accounts.find(
				(account) => account.id === asset.account_id
			)
			if (selectedAccount) setAccount(selectedAccount)
			setItemsToLocalStorage([
				{ key: 'selected_asset', value: asset.id },
				{ key: 'selected_account', value: selectedAccount!.id }
			])

			handleClose()
		}

		const createNavLink = (asset: AssetDTO) => {
			let path = pathname
			path = path.replace(/(\/acc)[^/]+/, `/${asset.account_id}`)
			path = path.replace(/(\/ast)[^/]+/, `/${asset.id}`)
			return path
		}

		return (
			<div className="flex flex-col space-y-2 w-full">
				{option?.assets && option?.assets?.length > 0 && (
					<div className="flex w-full justify-start align-center bg-gray-50 px-2 py-2 rounded-md mt-2">
						<BsBriefcase className="mx-2" />
						<span className="truncate text-sm block max-w-[100px]">
							{sanitizeURL(option.name)}
						</span>
					</div>
				)}
				{option?.assets?.map((asset) => {
					return (
						<NavLink
							key={asset.id}
							to={createNavLink(asset)}
							onClick={() => handleSelectingAsset(asset)}
							className={classNames(
								`text-t-heading font-semibold ${
									asset.id === selectedAsset?.id && 'bg-gray-100'
								} hover:bg-gray-100 group flex items-center py-2 px-4 text-md space-x-3 cursor-pointer rounded-md`
							)}
						>
							<div className="flex flex-row items-center justify-between space-y-0 w-full">
								<div className="flex items-center gap-2">
									<span className="self-baseline">
										<WebsiteAvatar website={asset} size={6} />
									</span>
									<div className="space-x-2">
										<div>
											<span className="flex flex-col truncate max-w-[120px]">
												{sanitizeURL(asset.name)}
											</span>
											{asset?.description && (
												<span className="text-sm">
													{truncateString(asset?.description || '', 20)}
												</span>
											)}
										</div>
									</div>
								</div>

								{asset.id === selectedAsset?.id && (
									<div>
										<RiCheckLine className="text-primary-500 w-4 h-4" />
									</div>
								)}
							</div>
						</NavLink>
					)
				})}
			</div>
		)
	}
}
