import moment from 'moment'
import { GTooltip } from '../g-tooltip'

export const GDateTime = (props: {
	date?: Date | null
	time?: boolean
	className?: string
}) => {
	const { date, time = true, className } = props

	const format = `MMMM Do, YYYY${time ? ' - hh:mm:ss A' : ''}`
	if (!date) return <span>N/A</span>

	return (
		<GTooltip
			content={<div className="text-t-dark">{moment(date).format(format)}</div>}
		>
			<span className={className || ''}>{moment(date).fromNow()}</span>
		</GTooltip>
	)
}
