import { GButton, GDialog } from '../../../components/basic-blocks'
import { Dispatch, useEffect } from 'react'
import { useAdminStore, useUiStore } from '../../../store'
import { UserDTO } from '../../../../../api-models'
import { useShallow } from 'zustand/react/shallow'

export const DeleteAdminUser = ({
	open,
	setOpen,
	user
}: {
	open: boolean
	setOpen: Dispatch<boolean>
	user: UserDTO
}) => {
	const { deleteAdmin } = useAdminStore(
		useShallow((s) => ({
			deleteAdmin: s.deleteAdmin
		}))
	)
	const isLoading = useUiStore((e) => e.isLoading)
	const handleDeleteAdminUser = async () => {
		await deleteAdmin(user.id)
		setOpen(false)
	}

	// on click enter key trigger delete
	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === 'Enter') {
				handleDeleteAdminUser()
			}
		}
		if (open) {
			document.addEventListener('keydown', handleKeyDown)
		}
		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [open])

	return (
		<GDialog
			open={open}
			title="Delete user?"
			maxWidth="2xl"
			showClose={false}
			lineBreak={true}
			onClose={() => setOpen(false)}
		>
			<div>
				<p className="text-base py-6">
					By deleting this user, they will no longer be able to access the
					ClickGUARD admin platform. Are you sure you want to delete this user?
				</p>
				<div className="flex items-center justify-between">
					<GButton
						loading={isLoading('ADMIN_UPDATE_ROLE')}
						onClick={() => setOpen(false)}
						variant="text"
					>
						Cancel
					</GButton>

					<GButton
						loading={isLoading('ADMIN_UPDATE_ROLE')}
						color="danger"
						labelClassName="font-bold"
						onClick={handleDeleteAdminUser}
					>
						Delete
					</GButton>
				</div>
			</div>
		</GDialog>
	)
}
