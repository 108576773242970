import { GTooltip } from 'components/basic-blocks'
import { useStores } from 'hooks'
import { RiCheckboxBlankCircleFill } from 'react-icons/ri'
import { useNavigate, useParams } from 'react-router-dom'
import { classNames } from 'utils'
import { SetupStepsProps } from './types'

export const SetupSteps = ({ setStep, step, account }: SetupStepsProps) => {
	const navigate = useNavigate()
	const { accountId } = useParams()
	const { f } = useParams()
	const { userStore } = useStores()
	const { user } = userStore

	const classes = (s: string) => {
		return classNames(
			step === s ? 'text-primary-500' : 'text-gray-100',
			'h-4 w-4 cursor-pointer'
		)
	}

	const onClick = (s: string) => {
		if (s === step) return
		setStep(s)
	}

	const getLink = (link: string) => {
		if (account) {
			return `${link}/${account.id}`
		}
		if (accountId) {
			return `${link}/${accountId}`
		}
		return link
	}

	const path = window.location.pathname.split('/')

	return (
		<div className="flex items-center space-x-2">
			{path[1] === 'confirmation' && (
				<GTooltip content="Step 1: Profile confirmation" className="min-w-max">
					<RiCheckboxBlankCircleFill
						className={classes('confirmation')}
						onClick={() => {
							onClick('confirmation')
							navigate('/confirmation')
						}}
					/>
				</GTooltip>
			)}
			<GTooltip content="Step 2: Workspace information" className="min-w-max">
				<RiCheckboxBlankCircleFill
					className={classes('workspace')}
					onClick={() => {
						if (user?.profile_confirmed) {
							onClick('workspace')
							if (f === 'agency') {
								navigate(getLink('/start/agency'))
							} else {
								navigate(getLink('/start/standard'))
							}
						}
					}}
				/>
			</GTooltip>
			<GTooltip
				content="Step 3: Select advertising platform"
				className="min-w-max"
			>
				<RiCheckboxBlankCircleFill
					className={classes('link')}
					onClick={() => {
						if (user?.profile_confirmed) {
							if (account || accountId) {
								onClick('link')
								navigate(getLink('/setup'))
							}
						}
					}}
				/>
			</GTooltip>
			<GTooltip content="Step 4: Add PPC account" className="min-w-max">
				<RiCheckboxBlankCircleFill
					className={classes('account')}
					onClick={() => {
						if (user?.profile_confirmed) {
							if (account || accountId) {
								onClick('account')
								navigate(getLink('/setup'))
							}
						}
					}}
				/>
			</GTooltip>
		</div>
	)
}
