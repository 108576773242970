import { WebsitesPageFiltersContextProvider } from './context'
import { lazy, Suspense } from 'react'
import { GLazyLoading } from '../../../../components/basic-blocks'

const AdminWebsitesPageView = lazy(() =>
	import('./view')
		.then((module) => {
			return { default: module.AdminWebsitesPageView }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)

export const AdminWebsitesPage = () => {
	return (
		<WebsitesPageFiltersContextProvider>
			<Suspense fallback={<GLazyLoading />}>
				<AdminWebsitesPageView />
			</Suspense>
		</WebsitesPageFiltersContextProvider>
	)
}
