import { useAccountStore, useAssetStore } from '../../../../store'
import { useShallow } from 'zustand/react/shallow'
import { GoogleAdsAdAccountDTO } from 'api-models'

export const MccLinkRequestNotice = ({
	mccLinkInviteAdAccounts,
	state
}: {
	mccLinkInviteAdAccounts: GoogleAdsAdAccountDTO[]
	state:
		| 'PENDING'
		| 'SKIPPED'
		| 'SENDING_LINK_REQUEST'
		| 'LINK_REQUEST_PENDING_ACCEPTANCE'
		| 'SUPPRESSED_LINK_REQUEST_ALERT'
}) => {
	const account = useAccountStore((a) => a.account)
	const { sendMccLinkRequest, sendMccLinkRequests } = useAssetStore(
		useShallow((s) => ({
			sendMccLinkRequest: s.sendMccLinkRequest,
			sendMccLinkRequests: s.sendMccLinkRequests
		}))
	)

	const handleSendMccLinkRequest = async () => {
		if (!account || state !== 'PENDING') return
		if (mccLinkInviteAdAccounts.length > 1) {
			await sendMccLinkRequests(account.id)
		} else if (mccLinkInviteAdAccounts[0]) {
			await sendMccLinkRequest(account.id, mccLinkInviteAdAccounts[0].id)
		}
	}

	if (state === 'PENDING') {
		return (
			<div className="flex items-center">
				To unlock advanced support from ClickGUARD, you can connect your Google
				Ads {mccLinkInviteAdAccounts.length > 1 ? 'accounts' : 'account'} to
				ClickGUARD's Manager Account (MCC).
				<div
					onClick={handleSendMccLinkRequest}
					className="px-1 text-lg text-primary-500 cursor-pointer"
				>
					{mccLinkInviteAdAccounts.length > 1
						? 'Send Link Requests'
						: 'Send Link Request'}
				</div>{' '}
			</div>
		)
	}

	if (state === 'LINK_REQUEST_PENDING_ACCEPTANCE') {
		return (
			<div className="flex items-center">
				{mccLinkInviteAdAccounts.length > 1
					? "Requests to link the ClickGUARD's Manager account to your Google Ads accounts have been sent to the email addresses associated with them."
					: "A request to link the ClickGUARD's Manager account to your Google Ads account has been sent to the email address associated with it."}
			</div>
		)
	}
	return <></>
}
