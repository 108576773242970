import {
	GButton,
	GDialog,
	GInput,
	GListBox,
	GTransition
} from 'components/basic-blocks'
import { useStores } from 'hooks'
import { useState } from 'react'
import { useUiStore } from 'store'
import { CancelSubscriptionProps, CancelSubscriptionState } from './types'

export const CancelSubscription = (props: CancelSubscriptionProps) => {
	const { children, account, immediately = false } = props
	const { adminStore } = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	const { cancelSubscription } = adminStore

	const [dialogOpen, setDialogOpen] = useState(false)
	const [state, setState] = useState<CancelSubscriptionState>({
		note: '',
		reason: 'User requested cancellation',
		grace:
			account?.billing?.subscription?.status === 'CANCELLING'
				? 'Cancel immediately'
				: 'Cancel at the end of billing period'
	})

	const handleChange = (e: string, name: 'reason' | 'note' | 'grace') => {
		setState({ ...state, [name]: e })
	}

	const handleDialogClose = () => {
		setDialogOpen(false)
		setState({
			note: '',
			reason: 'User requested cancellation',
			grace:
				account?.billing?.subscription?.status === 'CANCELLING'
					? 'Cancel immediately'
					: 'Cancel at the end of billing period'
		})
	}

	const handleSumbit = async () => {
		const payload = {
			note: state.reason === 'Other' ? state.note : state.reason,
			immediately: immediately ? true : state.grace === 'Cancel immediately'
		}
		if (!account?.billing?.subscription?.id) return
		try {
			await cancelSubscription(payload, account?.billing?.subscription.id)
			handleDialogClose()
		} catch (e) {
			console.log('error', e)
		}
	}

	return (
		<>
			{children && children(() => setDialogOpen(true))}
			<GDialog
				title="Cancel subscription"
				subtitle="By performing this action you will end user's ongoing subscription."
				open={dialogOpen}
				onClose={() => handleDialogClose()}
				maxWidth="3xl"
				className="sm:max-w-3xl overflow-clip"
			>
				<div className="my-8 space-y-4">
					<GListBox<string>
						label="Reason"
						options={['User requested cancellation', 'Other']}
						placeholder="Select Reason"
						onChange={(e) => {
							handleChange(e, 'reason')
						}}
						value={state.reason}
						renderLabel={(item) => item}
						// error={selectedItem?.errors?.tier}
					/>
					<GTransition show={state.reason === 'Other'}>
						<GInput
							placeholder="Type the reason here"
							onChange={(e) => {
								handleChange(e.target.value, 'note')
							}}
							value={state.note}
							className="ml-6"
						/>
					</GTransition>
					<GTransition show={!immediately}>
						<GListBox<string>
							label="Grace Period"
							options={
								account?.billing?.subscription?.status === 'CANCELLING'
									? ['Cancel immediately']
									: [
											'Cancel at the end of billing period',
											'Cancel immediately'
									  ]
							}
							placeholder="Choose grace period"
							onChange={(e) => {
								handleChange(e, 'grace')
							}}
							value={state.grace}
							renderLabel={(item) => item}
						/>
					</GTransition>
				</div>
				<div className="flex justify-end gap-2">
					<GButton label="Cancel" onClick={() => handleDialogClose()} />
					<GButton
						label="Confirm"
						onClick={() => handleSumbit()}
						color="primary"
						loading={isLoading('ADMIN_CHANGE_ACCOUNT')}
					/>
				</div>
			</GDialog>
		</>
	)
}
