import { ReportView } from './report-view'
import { reportType } from '../types'

const reportTypes: reportType[] = [
	'DOMAIN',
	'CAMPAIGN',
	'KEYWORD',
	'PLACEMENT',
	'ENGAGEMENT'
]

export const AvailableReports = () => {
	return (
		<>
			<div className="space-y-6">
				{reportTypes.map((type) => (
					<ReportView type={type} key={type} />
				))}
			</div>
		</>
	)
}
