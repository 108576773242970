import { PageTitle } from '../../../utils/page-title'
import { PageHeader } from '../../../components/page-header'
import { GButton, GInput, GTransition } from '../../../components/basic-blocks'
import { useAdminStore, useUiStore } from '../../../store'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { validateEmail } from '../../../utils/validation'
import { LegacyUserDTO } from './types'
import { toast } from 'react-toastify'
import { LegacyUserData } from './user-details'
import { LegacyInvoices } from './legacy-invoice'
import { BulkDownload } from './bulk-download'

export const LegacyUser = () => {
	const getLegacyUserWithEmail = useAdminStore((s) => s.getLegacyUserWithEmail)
	const [userDetails, setUserDetails] = useState<null | LegacyUserDTO>(null)
	const [showBulkDownload, setShowBulkDownload] = useState(false)
	const [search] = useSearchParams('tab=info')
	const [tabs, setTabs] = useState<string>('users')
	const userLoading = useUiStore((s) => s.isLoading('ADMIN_GET_LEGACY_USER'))
	useEffect(() => {
		setTabs(search.get('tab') || 'users')
	}, [search])

	const [email, setEmail] = useState('deancheong@gmail.com')
	const [error, setError] = useState('')

	const getLegacyUserData = async () => {
		if (!email) return setError('Email is required')
		const error = validateEmail(email)
		if (error) return setError(error)
		setUserDetails(null)
		const data = (await getLegacyUserWithEmail(email)) || null
		if (!data) toast.info('No user exist with this email')
		setUserDetails(data)
	}

	const handleClear = () => {
		setEmail('')
		setError('')
		setUserDetails(null)
	}
	return (
		<>
			<PageTitle pageName="Users" />
			<PageHeader
				title="Legacy User Data"
				subtitle="Database includes legacy users who successfully migrated and are active on the new platform"
			/>
			<div className="max-w-3xl px-4 sm:px-6 md:px-8">
				{!showBulkDownload && (
					<div className="relative px-6 pt-4 pb-6 bg-white rounded-md sm:rounded-lg mb-6 border border-t-border-light">
						<div className="flex py-8 gap-2">
							<GInput
								className="w-full"
								name="email"
								placeholder="Email address"
								value={email}
								error={error}
								onChange={(e) => {
									setEmail(e.target.value)
									setError('')
								}}
							/>
							<GButton
								className="w-[131px] h-[36px]"
								onClick={getLegacyUserData}
								color="primary"
								loading={userLoading}
								label="Search"
							/>
							<GButton
								className="w-[131px] h-[36px]"
								onClick={handleClear}
								color="neutral"
								label="Clear"
							/>
						</div>
						{userDetails && (
							<div className="border rounded-md p-6">
								<GTransition show={tabs === 'users'}>
									<LegacyUserData user={userDetails} />
								</GTransition>
								<GTransition show={tabs === 'invoices'}>
									<LegacyInvoices email={email} />
								</GTransition>
							</div>
						)}
					</div>
				)}
				{!userDetails && tabs === 'users' && (
					<div>
						<BulkDownload
							setShow={setShowBulkDownload}
							show={showBulkDownload}
						/>
					</div>
				)}
			</div>
		</>
	)
}
