import Joi from 'joi'
import { useState } from 'react'
import { MdLockReset } from 'react-icons/md'
import { useUiStore } from 'store'
import { GButton, GInput } from '../../../components/basic-blocks'
import { OnBoarding } from '../../../components/onboarding/onboarding'
import { useStores } from '../../../hooks'

const emailSchema = Joi.object({
	email: Joi.string()
		.email({ tlds: { allow: false } })
		.required()
})
const validateEmail = (email: string) => {
	const { error } = emailSchema.validate({ email })
	return error ? error.details[0].message : null
}
export const PasswordReset = () => {
	const {
		userStore: { passwordReset }
	} = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	const [email, setEmail] = useState('')
	const [error, setError] = useState<string | undefined>()

	const handleSubmit = async () => {
		const validationError = validateEmail(email)
		if (validationError) {
			setError(validationError)
		} else {
			setError(undefined)
			await passwordReset(email)
		}
	}

	return (
		<>
			<OnBoarding
				backToLogin={true}
				title={
					<div className="text-3xl text-[#FFFFFF] font-[800]">
						<p className="text-4xl">
							<MdLockReset className="w-14 h-14 text-red-400 mb-4" />
						</p>
						Make sure you enter the correct email.
					</div>
				}
			>
				<div className="w-full min-h-full py-16">
					<GInput
						type="email"
						autoComplete="email"
						label="Your email"
						className="mb-4"
						value={email}
						error={error}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<div className="flex justify-center">
						<GButton
							color="primary"
							loading={isLoading('USER_PASSWORD_RESET')}
							iconPlacement="right"
							className="w-full mt-6"
							onClick={handleSubmit}
						>
							<span className="px-4">Send email</span>
						</GButton>
					</div>
				</div>
			</OnBoarding>
		</>
	)
}
