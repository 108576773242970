import { AccessLogs } from '../security/access-logs-section'
import { LoginMethod } from '../security/login-method-section'

import { GSection } from 'components/basic-blocks/g-section'
import { PageHeader } from 'components/page-header'

import { Notices } from 'components/notice/notices'
import { useStores } from 'hooks'
import { useEffect } from 'react'
import { PageTitle } from 'utils/page-title'

export const SecuritySetting = () => {
	const { userStore, accountStore, assetStore } = useStores()
	const { user, getUsersEnrollementDetails } = userStore

	useEffect(() => {
		getUsersEnrollementDetails()
	}, [])
	const { account, isInSetup } = accountStore
	const { asset, adAccounts } = assetStore
	if (!user) return <></>
	return (
		<div className="w-full">
			<PageTitle pageName="Security" />
			<Notices
				isInSetup={isInSetup()}
				setupStep={account?.setup?.step}
				isInActive={account?.status === 'INACTIVE'}
				hasDashboardPerformanceFeature={accountStore.hasFeature(
					'dashboard_performance'
				)}
				tc_installed={asset ? !!asset?.tc_installed : true}
				cc_installed={asset ? !!asset?.cc_installed : true}
				accountId={account?.id}
				assetId={asset?.id}
				demoMode={false}
				hideDemoDataNotice={true}
				mccLinkInviteAdAccounts={adAccounts}
			/>
			<PageHeader
				title="Security"
				subtitle="Manage your login options and history"
			/>
			<div className="max-w-3xl px-4 sm:px-6 md:px-8">
				<GSection
					lineBreak={true}
					title="Login methods"
					lineBreakColor="gray-100"
					className="px-6 py-6"
				>
					<LoginMethod />
				</GSection>

				<GSection
					lineBreak={true}
					title="Login history"
					lineBreakColor="gray-100"
					className="px-6 py-6"
				>
					<AccessLogs />
				</GSection>
			</div>
		</div>
	)
}
