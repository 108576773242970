import {
	AccountLinkDTO,
	BingAccountLinkDTO,
	BingAdsAccountBasicInfoDTO,
	FacebookAccountLinkDTO,
	FacebookAdsAccountBasicInfoDTO,
	GoogleAdsAccountBasicInfoDTO,
	GoogleOAuthAccountLinkDTO,
	MCCAccountLinkDTO
} from 'api-models'
import { ModalTypes } from 'components/basic-blocks/g-modal/types'
import { BingAuthConfig, GoogleAuthConfig } from 'config'
import { useStores } from 'hooks'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUiStore } from 'store'
import { AddNewSiteProps, Payload, State } from './types'
import { addSiteSchema } from './validations'
import { AddSiteView } from './view'

export const AddNewSite = (props: AddNewSiteProps) => {
	const { children } = props
	const setModalContent = useUiStore((s) => s.setModalContent)

	if (children) {
		return children(renderModal)
	}

	function renderModal() {
		setModalContent({
			title: 'New website',
			subtitle: 'Add a new website to your account',
			type: ModalTypes.INFO,
			actionsEnabled: false,
			showIcon: false,
			body: <ModalBody />
		})
	}
}

export const ModalBody = () => {
	const navigate = useNavigate()
	const { accountStore, assetStore } = useStores()
	const { account, accountLinks, createOAuthLink } = accountStore
	const { createAsset, setAsset } = assetStore

	const setModalState = useUiStore((s) => s.setModalState)
	const setModalContent = useUiStore((s) => s.setModalContent)

	const [adsConnection, setAdsConnection] = useState<AccountLinkDTO[]>([])
	const [state, setState] = useState<State>({
		platform: 'GADS',
		siteName: '',
		errors: {},
		tracking: true,
		set_custom_audience: true,
		step: 'idle'
	})

	useEffect(() => {
		if (state.platform === 'GADS') {
			const googleAdsConnections = accountLinks.filter(
				(link) => link.platform === 'GADS'
			)
			setAdsConnection(googleAdsConnections)
		}
		if (state.platform === 'FADS') {
			const facebookAdsConnections = accountLinks.filter(
				(link) => link.platform === 'FADS'
			)
			setAdsConnection(facebookAdsConnections)
		}
		if (state.platform === 'BADS') {
			const bingAdsConnections = accountLinks.filter(
				(link) => link.platform === 'BADS'
			)
			setAdsConnection(bingAdsConnections)
		}
	}, [state.platform, accountLinks])

	const handleAddSite = async () => {
		const errors = validate()
		setState({ ...state, errors: errors })
		if (errors) return

		let payload = {} as Payload

		if (state.selectedGoogleLink && state.accountNumber) {
			payload = {
				name: state.siteName,
				link_id: state.selectedGoogleLink?.id, // account link ID
				account_number: state.accountNumber?.account_number,
				set_tracking_template: state.tracking
			}
		}

		if (state.selectedFacebookLink && state.accountNumber) {
			payload = {
				name: state.siteName,
				link_id: state.selectedFacebookLink?.id, // account link ID
				account_number: state.accountNumber?.account_number,
				set_custom_audience: state.set_custom_audience
			}
		}

		if (
			state.selectedBingLink &&
			state.accountNumber &&
			'id' in state.accountNumber
		) {
			payload = {
				name: state.siteName,
				link_id: state.selectedBingLink?.id, // account link ID
				account_number: state.accountNumber?.account_number,
				ad_account_id: String(state.accountNumber?.id),
				set_custom_audience: state.set_custom_audience,
				set_tracking_template: state.tracking,
				customer_id: state.selectedBingLink.external_id
			}
		}

		try {
			const createdAsset = await createAsset(account!.id, payload)
			setAsset(createdAsset)
			setModalContent({})
			setModalState(false)
			createdAsset &&
				navigate(
					`/workspace/${createdAsset.account_id}/asset/${createdAsset.id}`
				)
		} catch (e: any) {
			return
		}
	}

	function validate() {
		const options = { abortEarly: false }
		const { error } = addSiteSchema.validate(
			{
				siteName: state.siteName,
				selectedLink:
					state.platform === 'GADS'
						? state.selectedGoogleLink?.id
						: state.platform === 'FADS'
						? state.selectedFacebookLink?.id
						: state.selectedBingLink?.id,
				accountNumber: state.accountNumber?.account_number
			},
			options
		)

		if (!error) return null

		const errors: Record<string, string> = {}

		error.details.forEach((item) => {
			errors[item.path[0]] = item.message
		})
		return errors
	}

	const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setState({ ...state, siteName: e.target.value })
	}

	const handleGoogleMultiSelectChange = (
		val: MCCAccountLinkDTO | GoogleOAuthAccountLinkDTO
	) => {
		setState({ ...state, selectedGoogleLink: val, accountNumber: undefined })
	}

	const handleFacebookMultiSelectChange = (val: FacebookAccountLinkDTO) => {
		setState({ ...state, selectedFacebookLink: val, accountNumber: undefined })
	}

	const handleBingMultiSelectChange = (val: BingAccountLinkDTO) => {
		setState({ ...state, selectedBingLink: val, accountNumber: undefined })
	}

	const handleAccountNumberChange = (
		val:
			| GoogleAdsAccountBasicInfoDTO
			| FacebookAdsAccountBasicInfoDTO
			| BingAdsAccountBasicInfoDTO
	) => {
		setState({ ...state, accountNumber: val })
	}

	useEffect(() => {
		if (state.step === 'waiting') {
			const interval = setInterval(async () => {
				const code = getAuthCode()
				if (code) {
					clearInterval(interval)
					clearAuthCode()
					handleNewConnection(code)
				}
			}, 1000)
		}
	}, [state.step])

	const getAuthCode = () => {
		let code
		if (state.platform === 'GADS') {
			code = window.localStorage.getItem('google_oauth_code')
		} else if (state.platform === 'FADS') {
			code = window.localStorage.getItem('facebook_oauth_long_lived_token')
		} else {
			code = window.localStorage.getItem('bing_oauth_code')
		}
		return code
	}

	const clearAuthCode = () => {
		if (state.platform === 'GADS') {
			window.localStorage.removeItem('google_oauth_code')
		} else if (state.platform === 'FADS') {
			window.localStorage.removeItem('facebook_oauth_long_lived_token')
		} else {
			window.localStorage.removeItem('bing_oauth_code')
		}
		setState({ ...state, step: 'idle' })
	}

	const handleNewConnection = async (code: string) => {
		try {
			await createOAuthLink(
				code,
				state.platform === 'GADS'
					? 'google'
					: state.platform === 'FADS'
					? 'facebook'
					: 'bing'
			)
		} catch (e) {
			console.log(e)
		}
	}

	const handleAuthorize = () => {
		if (state.platform === 'GADS') {
			window.open(GoogleAuthConfig.AUTH_URL, '_blank')
		} else if (state.platform === 'FADS') {
			window.open(GoogleAuthConfig.AUTH_URL, '_blank')
		} else {
			window.open(BingAuthConfig.AUTH_URL, '_blank')
		}
		setState({ ...state, step: 'waiting' })
	}

	return (
		<AddSiteView
			state={state}
			setState={setState}
			adsConnection={adsConnection}
			handleAddSite={handleAddSite}
			handleNameChange={handleNameChange}
			handleGoogleMultiSelectChange={handleGoogleMultiSelectChange}
			handleFacebookMultiSelectChange={handleFacebookMultiSelectChange}
			handleBingMultiSelectChange={handleBingMultiSelectChange}
			handleAccountNumberChange={handleAccountNumberChange}
			handleTrackingChange={(v) => setState({ ...state, tracking: v })}
			handleCustomAudienceChange={(c) =>
				setState({ ...state, set_custom_audience: c })
			}
			handleAuthorize={handleAuthorize}
		/>
	)
}
