import { GNewTable } from 'components/basic-blocks'
import { AverageMrrPanel } from './components/average-mrr'
import { columns } from './components/columns'
import { NetChangePanel } from './components/net-change'
import { Timeline } from './components/timeline/timeline'

import { RevenueViewProps } from './types'
import { timelineDataFormatter } from './utils'

export const RevenueView = ({ data, timelineData }: RevenueViewProps) => {
	const averageMrrData = data.find((stats) => stats.tier_type === 'average_mrr')
	const netChangeData = data.find((stats) => stats.tier_type === 'net_change')
	const tableData =
		data?.filter(
			(stats) =>
				stats.tier_type !== 'net_change' && stats.tier_type !== 'average_mrr'
		) || []

	return (
		<div className="flex">
			<div className="mr-8 min-w-[320px]">
				<AverageMrrPanel data={averageMrrData} />
				<NetChangePanel data={netChangeData} />
			</div>
			<div className="w-full">
				<Timeline data={timelineData} formatter={timelineDataFormatter} />
				<GNewTable
					data={tableData}
					columns={columns}
					title="Subscription plans"
					containerClassName="py-4 px-6"
				/>
			</div>
		</div>
	)
}
