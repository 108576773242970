import { useAuth0 } from '@auth0/auth0-react'
import { GLoading } from 'components/basic-blocks'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Users } from 'services/user'
import { parseError } from 'utils/errors'
import { ConfirmUserState } from './types'
import { ConfirmUserView } from './view'

const Rewardful = (window as any).Rewardful

export const ConfirmUserPage = () => {
	const { isAuthenticated, loginWithRedirect } = useAuth0()
	const { userStore, accountStore } = useStores()
	const { accounts } = accountStore
	const { user } = userStore

	const navigate = useNavigate()
	const { f } = useParams()

	const [state, setState] = useState<ConfirmUserState>({
		name: user?.name,
		phone_number: user?.phone_number?.replace(/\+/g, '') || '',
		timezone: user?.timezone,
		country: user?.country,
		loading: false,
		error: null,
		step: 'confirmation'
	})

	useEffect(() => {
		setState({
			name: user?.name,
			phone_number: user?.phone_number,
			timezone: user?.timezone,
			loading: false,
			error: null,
			step: 'confirmation'
		})
	}, [user])

	if (!isAuthenticated) {
		loginWithRedirect({
			appState: {
				returnTo: window.location.pathname + window.location.search
			}
		})
		return <></>
	}

	const onNext = async () => {
		if (!state.name) {
			return
		}

		setState({ ...state, loading: true })

		try {
			await Users.updateProfile({
				name: state.name,
				phone_number: state.phone_number?.startsWith('+')
					? state.phone_number
					: `+${state.phone_number}`,
				timezone: state.timezone || null,
				country: state.country || null,
				confirm_user: true
			})

			if (Rewardful?.referral) {
				await Users.updateReferral({
					ref_id: Rewardful.referral,
					aff_id: Rewardful.affiliate.id,
					aff_token: Rewardful.affiliate.token
				})
			}

			await userStore.initUser()
			const userInvite = await Users.getUserAccessInviteCode()
			if (userInvite?.code) {
				return navigate(`/join-workspace?code=${userInvite.code}`)
			}
			if (accounts.length === 0) {
				if (f === 'agency') {
					navigate('/start/agency')
				} else {
					navigate('/start/standard')
				}
			}
			if (
				accounts[0]?.status === 'SETUP' &&
				accounts[0].setup?.step !== 'subscription'
			) {
				navigate(`/setup/${accounts[0].id}`)
			}

			setState({ ...state, loading: false })
		} catch (err: any) {
			setState({ ...state, error: parseError(err) })
		}
	}

	if (!user) {
		return <GLoading />
	}

	return (
		<ConfirmUserView
			user={user}
			state={state}
			onNext={onNext}
			setName={(s) => setState({ ...state, name: s })}
			setPhone={(s) => setState({ ...state, phone_number: s })}
			setTimezone={(s) => setState({ ...state, timezone: s })}
		/>
	)
}
