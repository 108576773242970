import { GButton, GDialog, GInput } from 'components/basic-blocks'
import { useStores } from 'hooks'
import { useState } from 'react'
import { useUiStore } from 'store'
import { AddDiscountProps, AddDiscountState } from './types'

export const AddDiscount = (props: AddDiscountProps) => {
	const { children, account } = props
	const { adminStore } = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	const { addDiscount } = adminStore

	const [dialogOpen, setDialogOpen] = useState(false)
	const [state, setState] = useState<AddDiscountState>({
		couponId: ''
	})

	const handleChange = (e: string, name: string) => {
		setState({ ...state, [name]: e })
	}

	const handleDialogClose = () => {
		setDialogOpen(false)
		setState({
			couponId: ''
		})
	}

	const handleSumbit = async () => {
		const payload = {
			coupon_id: state.couponId
		}
		if (!account?.billing?.subscription?.id) {
			return
		}
		try {
			await addDiscount(payload, account?.billing?.subscription?.id)
			handleDialogClose()
		} catch (e) {
			console.log('error', e)
		}
	}

	return (
		<div>
			{children && children(() => setDialogOpen(true))}
			<GDialog
				title="Add discount"
				subtitle="Use the “Coupon ID” from Stripe to add a discount to this subscription."
				open={dialogOpen}
				onClose={() => handleDialogClose()}
				maxWidth="3xl"
				className="sm:max-w-3xl overflow-clip"
			>
				<div className="my-8 space-y-4">
					<GInput
						label="Coupon ID"
						placeholder="Type your coupon here"
						onChange={(e) => {
							handleChange(e.target.value, 'couponId')
						}}
						value={state.couponId}
					/>
				</div>
				<div className="flex justify-end gap-2">
					<GButton label="Cancel" onClick={() => handleDialogClose()} />
					<GButton
						label="Confirm"
						onClick={() => handleSumbit()}
						color="primary"
						loading={isLoading('ADMIN_CHANGE_ACCOUNT')}
					/>
				</div>
			</GDialog>
		</div>
	)
}
