import { UserDTO } from 'api-models'
import { GDateTime } from 'components/basic-blocks/g-datetime'
import { RiLockFill } from 'react-icons/ri'

export const UserSuspended = (props: { user?: UserDTO }) => {
	const { user } = props
	return (
		<>
			<div className="min-h-full h-[calc(100vh-70px)] px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 ">
				<div className="max-w-max mx-auto">
					<main className="sm:flex items-center">
						<p className="text-4xl">
							<RiLockFill className="w-14 h-14 text-red-500" />
						</p>
						<div className="sm:border-l ml-6 sm:border-gray-200 sm:pl-6 flex flex-col justify-center items-center">
							<div>
								<h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
									Suspended
								</h1>
							</div>

							{user?.suspended_date && (
								<div>
									<p className="mt-1 text-base text-t-secondary">
										<GDateTime date={user?.suspended_date} time={true} />
									</p>
								</div>
							)}
							<div>
								<p className="mt-1 text-base text-t-dark">
									Contact support{' '}
									<a
										href="mailto:support@clickguard.com?Subject=support"
										className="text-primary-500"
									>
										support@clickguard.com{' '}
									</a>
								</p>
							</div>
						</div>
						<div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6"></div>
					</main>
				</div>
			</div>
		</>
	)
}
