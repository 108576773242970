import { ProductAccountDTO } from 'api-models'
import { UserAvatar } from 'components'
import { GButton, GTooltip } from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'
import { GDateTime } from 'components/basic-blocks/g-datetime'
import { GTableColumn } from 'components/basic-blocks/g-table/types'
import { PlanNames } from 'config'
import { useStores } from 'hooks'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { RiCloseLine, RiLoginCircleLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import {
	formatCurrency,
	formatPriceInterval,
	formatSpend,
	sanitizeURL
} from 'utils'
import { planTierColorMapper } from 'utils/icons'

export const columns: Array<GTableColumn<ProductAccountDTO>> = [
	{
		label: '',
		path: 'workspace',
		render: (row: ProductAccountDTO) => <WorkspaceColumn row={row} />,
		expand: true,
		className: 'justify-start'
	},
	{
		label: 'User',
		path: 'owner',
		render: (row: ProductAccountDTO) => <OwnerColumn account={row} />,
		className: 'justify-center'
	},
	{
		label: 'Plan tier',
		path: 'planTier',
		render: (row: ProductAccountDTO) => <PlanTierColumn row={row} />,
		className: 'justify-center'
	},
	{
		label: 'Plan price',
		path: 'planPrice',
		render: (row: ProductAccountDTO) => <PlanPriceColumn row={row} />,
		className: 'justify-center'
	},
	{
		label: 'Current ad spend',
		path: 'planAdSpend',
		render: (row: ProductAccountDTO) => <PlanAdSpend row={row} />,
		className: 'justify-center'
	},
	{
		label: 'Next Plan',
		path: 'nextPlan',
		render: (row: ProductAccountDTO) => <NextPlanAdSpend row={row} />,
		className: 'justify-center'
	},
	{
		label: 'Reason',
		path: 'reason',
		render: (row: ProductAccountDTO) => <ReasonsColumn row={row} />,
		className: 'justify-center',
		expand: true
	},
	{
		label: 'Cancel date',
		path: 'cancelDate',
		render: (row: ProductAccountDTO) => <CancelDate row={row} />,
		className: 'justify-center',
		sortKey: 'cancelled_at'
	}
]

const WorkspaceColumn = (props: { row: ProductAccountDTO }) => {
	const navigate = useNavigate()
	const link = `/admin/subscription/${props.row.id}`
	const planName = PlanNames[props.row?.billing?.subscription?.plan.tier || '']
	const planIcon = planTierColorMapper(
		props.row.billing?.subscription?.plan.tier,
		3
	)
	const trialPeriod = props.row?.billing?.subscription?.type === 'TRIAL'

	return (
		<div className="flex justify-center items-center space-x-2">
			<>
				{planIcon}
				<div className="flex flex-col justify-center">
					<div
						className="font-bold text-lg text-primary-500 hover:text-primary-600 cursor-pointer truncate max-w-[300px]"
						onClick={() => navigate(link)}
					>
						{sanitizeURL(props.row.name)}
					</div>
					<div className="text-md text-gray-500">{planName || ''}</div>
				</div>
				{trialPeriod && (
					<GBadge
						className="rounded-md bg-badge-selected align-center"
						text={
							<div className="w-full px-2 text-badge-text-t-default text-center">
								Trial
							</div>
						}
						color="[#e0e7ff]"
					/>
				)}
			</>
		</div>
	)
}
const OwnerColumn = (props: { account: ProductAccountDTO }) => {
	const navigate = useNavigate()
	const { account } = props
	const { adminStore } = useStores()
	const [loading, setLoading] = useState(false)

	const link = `/admin/user/${account.user_info?.user_id}`
	const name =
		account.status === 'SETUP' ? account.user_info?.name : account.billing?.name
	const email =
		account.status === 'SETUP'
			? account.user_info?.email
			: account.billing?.email

	const impersonate = async () => {
		setLoading(true)
		try {
			adminStore.setImpersonation(account.user_info?.user_id ?? '')
			setLoading(false)
			navigate('/')
		} catch (e) {
			setLoading(false)
		}
	}

	useEffect(() => {
		return () => {
			setLoading(false)
		}
	}, [])

	const getUserAccessInfo = (user: any) => {
		return user?.access_level === 'READ'
			? 'Analyst'
			: user?.access_level === 'WRITE'
			? 'Manager'
			: user?.access_level === 'OWNER'
			? 'Owner'
			: 'Other'
	}

	const getUserBadgeColor = (user: any) => {
		return user?.access_level === 'READ'
			? 'green'
			: user?.access_level === 'WRITE'
			? 'purple'
			: user?.access_level === 'OWNER'
			? 'primary'
			: 'primary'
	}

	return (
		<div className="flex space-x-2 items-center">
			<GTooltip
				persist
				content={
					<div className="w-72 flex flex-col">
						{account?.user_access?.map((user: any, index: number) => (
							<div
								key={index}
								className="w-full flex justify-between items-center py-2"
							>
								<div className="w-full flex justify-start items-center">
									<div className="mr-2">
										<GButton
											shape="square"
											type="icon"
											loading={loading}
											icon={RiLoginCircleLine}
											variant="contained"
											color="primary"
											size="xs"
											onClick={impersonate}
										>
											Impersonate
										</GButton>
									</div>
									<div className="flex space-x-2 items-center">
										<UserAvatar size={8} src={user?.avatar} />
										<div
											className="flex flex-col group cursor-pointer"
											onClick={() => navigate(link)}
										>
											<div className="font-semibold text-primary-500 group-hover:text-primary-600">
												{name}
											</div>
											<div className="text-gray-500">{email}</div>
										</div>
									</div>
								</div>
								<GBadge
									className="rounded-md bg-badge-selected items-center"
									text={getUserAccessInfo(user)}
									color={getUserBadgeColor(user)}
								/>
							</div>
						))}
					</div>
				}
			>
				<div className="flex -space-x-4">
					{account?.user_access?.map((user: any, index: number) => (
						<div className="flex space-x-2 items-center" key={index}>
							<UserAvatar size={8} src={user?.avatar} />
						</div>
					))}
				</div>
			</GTooltip>
		</div>
	)
}

const PlanTierColumn = (props: { row: ProductAccountDTO }) => {
	const planName = PlanNames[props.row?.billing?.subscription?.plan.tier || '']
	const status = props.row.status

	return (
		<div className="flex justify-center items-center space-x-4">
			{status !== 'SETUP' ? (
				<>
					<div className="font-medium text-primary-500">
						{planName || 'N/A'}
					</div>
				</>
			) : (
				<div className="font-medium text-primary-500">N/A</div>
			)}
		</div>
	)
}

const PlanPriceColumn = (props: { row: ProductAccountDTO }) => {
	const { row } = props

	const status = row?.billing?.subscription?.status || 'NONE'
	const type = row?.billing?.subscription?.type || 'NONE'
	const accountStatus = row.status
	const plan = row.billing?.subscription?.plan
	const nextPlan = row.billing?.subscription?.next_plan

	const discount = row.billing?.subscription?.discount

	let color: string
	if (status === 'ACTIVE') {
		color = 'green'
	} else {
		color = 'gray'
	}

	if (status === 'SWITCHING') {
		return (
			<div>
				<div className="flex flex-col justify-start gap-2">
					<GBadge
						text={
							<span>
								{formatCurrency(plan?.price)}{' '}
								{formatPriceInterval(plan?.interval, plan?.interval_count)}
							</span>
						}
						color="gray"
					/>
					<GBadge
						text={
							<span>
								{formatCurrency(nextPlan?.price)}{' '}
								{formatPriceInterval(
									nextPlan?.interval,
									nextPlan?.interval_count
								)}
							</span>
						}
						color="green"
					/>
				</div>
			</div>
		)
	}

	return (
		<div className="flex flex-col justify-start gap-2">
			{accountStatus === 'SETUP' ? (
				<div
					className={
						'text-center inline-flex rounded-full py-1 text-xs font-semibold text-t-secondary'
					}
				>
					{accountStatus === 'SETUP' && (
						<GBadge
							className={'rounded-md align-center'}
							text="No plan"
							color={'amber'}
						/>
					)}
				</div>
			) : type === 'TRIAL' && nextPlan?.price !== undefined ? (
				<GBadge
					text={
						<span>
							{formatCurrency(nextPlan?.price)}{' '}
							{formatPriceInterval(
								nextPlan?.interval,
								nextPlan?.interval_count
							)}
						</span>
					}
					color={color}
				/>
			) : type === 'TRIAL' && nextPlan?.price === undefined ? (
				<GBadge
					className={'rounded-md align-center'}
					text="No plan"
					color={'amber'}
				/>
			) : (
				<GBadge
					text={
						<span>
							{formatCurrency(plan?.price)}{' '}
							{formatPriceInterval(plan?.interval, plan?.interval_count)}
						</span>
					}
					color={color}
				/>
			)}
			{discount && (
				<GBadge
					text={<span>{discount?.percent_off}% off </span>}
					color={color}
				/>
			)}
		</div>
	)
}

const PlanAdSpend = (props: { row: ProductAccountDTO }) => {
	const { row } = props

	const adSpend = row.billing?.subscription?.plan?.spend

	return (
		<>
			{adSpend ? (
				<GBadge
					className="mt-2"
					text={<span>{formatSpend(adSpend)}</span>}
					color="purple"
				/>
			) : (
				<RiCloseLine className="h-5 w-5 mr-2 text-t-secondary" />
			)}
		</>
	)
}

const NextPlanAdSpend = (props: { row: ProductAccountDTO }) => {
	const { row } = props

	const adSpend = row.billing?.subscription?.next_plan?.spend
	const planName = row.billing?.subscription?.next_plan?.tier

	return (
		<>
			<div className="flex justify-center items-center space-x-2">
				<div className="font-medium text-primary-500">{planName || 'N/A'}</div>
				{adSpend ? (
					<GBadge text={<span>{formatSpend(adSpend)}</span>} color="purple" />
				) : (
					<RiCloseLine className="h-5 w-5 mr-2 text-t-secondary" />
				)}
			</div>
		</>
	)
}

const CancelDate = (props: { row: ProductAccountDTO }) => {
	const { row } = props

	const date = row?.billing?.subscription?.ends_at
	const subtitle = moment(date).format('llll')

	return (
		<div className="flex flex-1 justify-center">
			<div className="flex flex-col space-y-1 text-sm">
				<div className="font-medium">
					<span>
						Cancelling{' '}
						<span className="font-bold">
							<GDateTime date={date} />
						</span>
					</span>
				</div>
				{subtitle && <div className="text-sm text-gray-500">{subtitle}</div>}
			</div>
		</div>
	)
}

const ReasonsColumn = (props: { row: ProductAccountDTO }) => {
	const { row } = props
	const cancelReason = row.cancel_request?.note

	return (
		<>
			{cancelReason ? (
				<GTooltip content={cancelReason}>
					<GBadge
						text={cancelReason}
						color="red"
						className="block max-w-[100px] truncate"
					/>
				</GTooltip>
			) : (
				<GBadge text="None" color="gray" />
			)}
		</>
	)
}
