import { BiLogoBing } from 'react-icons/bi'
import { BsMeta } from 'react-icons/bs'
import { RiGoogleLine } from 'react-icons/ri'

import { BingIcon, GoogleIcon, MetaIcon } from 'components/icons'
import { useThemeStore } from 'layout/navbar/store'
import { twMerge } from 'tailwind-merge'
import { PlatformIconProps, PlatformIconsRIProps } from './types'

export const PlatformIcon = ({ platform, ...props }: PlatformIconProps) => {
	if (platform === 'GADS') {
		return (
			<>
				<GoogleIcon {...props} />
			</>
		)
	}

	if (platform === 'FADS') {
		return (
			<>
				<MetaIcon {...props} />
			</>
		)
	}

	if (platform === 'BADS') {
		return (
			<>
				<BingIcon {...props} />
			</>
		)
	}

	return null
}

export const PlatformIconRI = ({
	platform,
	className,
	...props
}: PlatformIconsRIProps) => {
	if (platform === 'GADS') {
		return (
			<>
				<RiGoogleLine
					className={twMerge('text-red-500', className)}
					{...props}
				/>
			</>
		)
	}

	if (platform === 'FADS') {
		return (
			<>
				<BsMeta className={twMerge('text-blue-500', className)} {...props} />
			</>
		)
	}

	if (platform === 'BADS') {
		return (
			<>
				<BiLogoBing
					className={twMerge('text-green-500', className)}
					{...props}
				/>
			</>
		)
	}

	return null
}

export const FolderOpenLineIcon = () => {
	const { theme } = useThemeStore()
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="65"
			height="64"
			viewBox="0 0 65 64"
			fill="none"
			className="text-t-menu"
		>
			<path
				d="M8.50065 56C7.0279 56 5.83398 54.8061 5.83398 53.3333V10.6667C5.83398 9.19392 7.0279 8 8.50065 8H28.2719L33.6052 13.3333H53.834C55.3068 13.3333 56.5007 14.5273 56.5007 16V24H51.1673V18.6667H31.3961L26.0628 13.3333H11.1673V45.328L15.1673 29.3333H60.5007L54.3391 53.98C54.0423 55.1672 52.9756 56 51.7519 56H8.50065ZM53.6697 34.6667H19.3315L15.3315 50.6667H49.6697L53.6697 34.6667Z"
				fill={theme === 'dark' ? '#A8AEB9' : '#5E5E7C'}
			/>
		</svg>
	)
}
