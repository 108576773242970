import { RiCloseLine } from 'react-icons/ri'
import { SelectedFiltersProps } from './types'

const workspaceOptions = [
	{
		label: 'Active',
		value: 'ACTIVE'
	},
	{
		label: 'Inactive',
		value: 'INACTIVE'
	},
	{
		label: 'Setup',
		value: 'SETUP'
	}
]

const planOptions = [
	{
		label: 'Custom Pro',
		value: 'custom_pro'
	},
	{
		label: 'Pro',
		value: 'pro'
	},
	{
		label: 'Standard',
		value: 'standard'
	},
	{
		label: 'lite',
		value: 'lite'
	},
	{
		label: 'Legacy',
		value: 'legacy'
	}
]

const subscriptionStatusList = [
	{
		label: 'Active',
		value: 'ACTIVE'
	},
	{
		label: 'Setup',
		value: 'SETUP'
	},
	{
		label: 'Inactive',
		value: 'INACTIVE'
	},
	{
		label: 'Trialing',
		value: 'TRIAL'
	},
	{
		label: 'Cancelling',
		value: 'CANCELLING'
	}
]

const LimitBreachedOption = [
	{ value: 'true', label: 'Yes' },
	{ value: 'false', label: 'No' },
	{ value: 'soon', label: 'Soon' }
]

const formatSelectedFilters = (key: string, value: any) => {
	switch (key) {
		case 'name': {
			return `Name: ${value}`
		}
		case 'status': {
			const status = value.map(
				(s: string) =>
					workspaceOptions.filter((st) => st.value === s)[0].label || null
			)

			return `Workspace status: ${status}`
		}
		case 'subscription_status': {
			const status = value.map(
				(s: string) =>
					subscriptionStatusList.filter((st) => st.value === s)[0].label || null
			)

			return `Subscription status: ${status.join(', ')}`
		}
		case 'subscription_tier': {
			const status = value.map(
				(s: string) =>
					planOptions.filter((st) => st.value === s)[0].label || null
			)

			return `Subscription plan: ${status.join(', ')}`
		}
		case 'cancel_request': {
			return `Cancel request: ${value === 'true' ? 'Yes' : 'No'}`
		}

		case 'offer_request': {
			return `Offer request: ${value === 'true' ? 'Yes' : 'No'}`
		}

		case 'used_trial': {
			return `Trialed: ${value === 'true' ? 'Yes' : 'No'}`
		}
		case 'limit_breached': {
			const status = value.map(
				(s: string) =>
					LimitBreachedOption.filter((st) => st.value === s)[0].label || null
			)

			return `Limit breached: ${status.join(', ')}`
		}
		case 'discounted': {
			return `Discounted: ${value === 'true' ? 'Yes' : 'No'}`
		}

		default:
			return null
	}
}

export const SelectedFilters = ({
	mapState,
	removeFilter
}: SelectedFiltersProps) => {
	return (
		<div className="flex flex-wrap gap-2">
			{Array.from(mapState.entries())
				.filter((e) => !['from', 'to'].includes(e[0]))
				.map(([key, value]) => (
					<div
						key={key}
						className="flex items-center gap-1.5 bg-gray-50 py-1.5 px-3 text-t-default font-normal rounded-lg"
					>
						{formatSelectedFilters(key, value)}
						<RiCloseLine
							className="w-4 h-4 text-t-default cursor-pointer"
							onClick={() => removeFilter(key)}
						/>
					</div>
				))}
		</div>
	)
}
