import { Notices } from 'components/notice/notices'
import { PageHeader } from 'components/page-header'
import { useEffect, useState } from 'react'
import { useAccountStore, useAssetStore, useUserStore } from 'store'
import { sanitizeURL } from 'utils'
import { PageTitle } from 'utils/page-title'
import { AdPlatforms } from 'utils/platform'
import { useShallow } from 'zustand/react/shallow'
import { FacebookAdTabs } from './facebook-ads-tab'
import { BingAdTabs } from './bing-ads-tab'
import { GoogleTagManagerConnection } from './google-tag-manager'
import { AddPPCConnection } from '../../../components/add-ppc-connection'
import { GoogleAdsTab } from './google-ads-tab'

export const PPCAccessPage = () => {
	const user = useUserStore((s) => s.user)
	const {
		account,
		refreshAccountLinks,
		accountLinks,
		isInSetup,
		wantsToSeeDemoData,
		hasFeature
	} = useAccountStore(
		useShallow((s) => ({
			account: s.account,
			refreshAccountLinks: s.refreshAccountLinks,
			accountLinks: s.accountLinks,
			isInSetup: s.isInSetup,
			wantsToSeeDemoData: s.wantsToSeeDemoData,
			hasFeature: s.hasFeature
		}))
	)

	const { asset, adAccounts } = useAssetStore(
		useShallow((s) => ({
			asset: s.asset,
			adAccounts: s.adAccounts
		}))
	)

	const googleAdsConnections = accountLinks.filter(
		(link) => link.platform === 'GADS'
	)
	const facebookAdsConnections = accountLinks.filter(
		(link) => link.platform === 'FADS'
	)
	const bingAdsConnections = accountLinks.filter(
		(link) => link.platform === 'BADS'
	)

	const [modalNewConnection, setModalNewConnection] = useState<{
		platform: AdPlatforms
		MCC: 'oauth' | 'mcc-account'
		active: boolean
	}>({
		platform: 'GADS',
		MCC: 'oauth',
		active: false
	})
	const [isAnalyst, setIsAnalyst] = useState(false)

	useEffect(() => {
		;(async () => {
			await refreshAccountLinks()
		})()
	}, [])

	useEffect(() => {
		if (user?.id) {
			const userAccessInfo =
				account?.user_access &&
				account.user_access.find(
					(userAccess) => userAccess.user_id === user?.id
				)
			setIsAnalyst(userAccessInfo?.access_level === 'READ')
		}
	}, [account?.id])

	return (
		<div className="w-full">
			<PageTitle pageName="Connections" entityName={account?.name} />
			<Notices
				isInSetup={isInSetup()}
				setupStep={account?.setup?.step}
				isInActive={account?.status === 'INACTIVE'}
				hasDashboardPerformanceFeature={hasFeature('dashboard_performance')}
				tc_installed={asset ? !!asset?.tc_installed : true}
				cc_installed={asset ? !!asset?.cc_installed : true}
				accountId={account?.id}
				assetId={asset?.id}
				demoMode={wantsToSeeDemoData}
				mccLinkInviteAdAccounts={adAccounts}
			/>
			<PageHeader
				title={'Connections'}
				subtitle={
					<p className="flex items-center">
						Manage connections and authorizations to PPC platforms and other
						integrations for&nbsp;
						<span className="font-bold max-w-[200px] truncate block">
							{sanitizeURL(account?.name) || 'Demo'}
						</span>
						&nbsp;Workspace
					</p>
				}
			/>

			<div className="max-w-3xl px-4 sm:px-6 md:px-8">
				<GoogleAdsTab
					links={googleAdsConnections}
					isAnalyst={isAnalyst}
					setModalNewConnection={setModalNewConnection}
				/>
				{account?.billing?.subscription?.plan?.tier !== 'legacy' && (
					<>
						<FacebookAdTabs
							links={facebookAdsConnections}
							isAnalyst={isAnalyst}
							setModalNewConnection={setModalNewConnection}
						/>
						<BingAdTabs
							links={bingAdsConnections}
							isAnalyst={isAnalyst}
							setModalNewConnection={setModalNewConnection}
						/>
					</>
				)}
				<GoogleTagManagerConnection />
			</div>
			<AddPPCConnection
				open={modalNewConnection.active}
				onClose={() =>
					setModalNewConnection({ ...modalNewConnection, active: false })
				}
				selectedTab={modalNewConnection.platform}
				initialTab={modalNewConnection.MCC}
			/>
		</div>
	)
}
