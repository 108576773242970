import { GButton, GInput, GSection, GTable } from 'components/basic-blocks'
import { PageHeader } from 'components/page-header'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { RiRefreshLine } from 'react-icons/ri'
import { useUiStore } from 'store'
import { PageTitle } from 'utils/page-title'
import { columns } from './columns'
import { AdAccountTableData } from './types'

export const AdminPPCAccountsPage = () => {
	const { adminStore } = useStores()
	const isLoading = useUiStore((s) => s.isLoading)
	const { getAdAccounts } = adminStore

	const [query, setQuery] = useState<Record<string, any>>({
		limit: 10,
		skip: 0,
		pageSize: 10
	})
	const [adAccounts, setAdAccounts] = useState<AdAccountTableData>({
		total: 0,
		data: []
	})

	const pageListener = (page: number) => {
		setQuery({ ...query, skip: page })
	}

	useEffect(() => {
		;(async () => {
			try {
				const data = await getAdAccounts(query)
				data ? setAdAccounts(data) : setAdAccounts({ total: 0, data: [] })
			} catch (err) {
				return
			}
		})()
	}, [query])

	return (
		<div className="">
			<PageTitle pageName="PPC accounts" />
			<PageHeader
				title="PPC accounts"
				subtitle="PPC accounts added and managed in users websites"
				actionPlacement="top"
				action={
					<div className="w-full flex pr-3.5 space-x-4 items-center justify-between">
						<GButton
							icon={RiRefreshLine}
							size="sm"
							onClick={() => getAdAccounts(query)}
						>
							Refresh
						</GButton>
						<GInput
							placeholder="Find PPC account..."
							value={query.search || ''}
							onChange={(e) => setQuery({ ...query, search: e.target.value })}
						/>
					</div>
				}
			/>
			<div className="w-full pt-6 px-4 sm:px-6">
				<GSection className="-mt-3" loading={isLoading('ADMIN_GET_ADACCOUNTS')}>
					<GTable
						data={adAccounts.data}
						columns={columns}
						pageSize={query.pageSize}
						totalCount={adAccounts.total}
						pageListener={(e: number) => pageListener(e)}
					/>
				</GSection>
			</div>
		</div>
	)
}
