import { GLoading } from 'components/basic-blocks'
import { useUrlQuery } from 'hooks'
import { useEffect } from 'react'

export function BingOAuthPage() {
	const query = useUrlQuery()

	useEffect(() => {
		window.localStorage.setItem('bing_oauth_code', query.code)
		window.close()
	}, [])

	return (
		<div className="w-full h-full flex items-center justify-center">
			<GLoading />
		</div>
	)
}
