import { useStores } from 'hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Onboarding } from 'services/user'

import {
	AdvertisingPlatforms,
	IndustryTypes,
	SectorTypes
} from '../../../config'
import { StartStandardState } from './types'
import { StartStandardView } from './view'
import { setItemsToLocalStorage } from '../../../utils'

export const StartStandard = () => {
	const navigate = useNavigate()
	const { accountId } = useParams()
	const { accountStore, assetStore } = useStores()
	const { setWantsToSeeDemoData, setAccount, accounts, getAllAccounts } =
		accountStore
	const { setDemoMode, setAssetForId } = assetStore
	const [state, setState] = useState<StartStandardState>({
		loading: false,
		step: 'workspace',
		error: ''
	})

	const accountData = useMemo(
		() => accounts.find((a) => a.id === accountId),
		[accountId, accounts]
	)

	const onNext = async () => {
		setState({ ...state, loading: true })
		let account
		try {
			account = await Onboarding.createProductAccount({
				type: 'standard',
				domain: state.domain,
				sector: state.sector?.value,
				industry: state.industry?.value,
				platforms: state.platforms?.map((p) => p.value),
				spend: state.spend,
				workspace: state.workspace,
				step: 'workspace',
				account_id: accountId || null
			})
			navigate(`/setup/${account.id}`)

			setState({ ...state, loading: false })
		} catch (error: any) {
			setState({ ...state, loading: false })
			console.error(error.response.data.message)
			const workspaceId =
				error.response.data.message.split(' ')[
					error.response.data.message.split(' ').length - 1
				]
			setState({
				...state,
				error: {
					message: error.response.data.message,
					domain: workspaceId
				}
			})
		}
	}

	const handleBack = useCallback(async () => {
		setWantsToSeeDemoData()
		setAccount(null)
		setDemoMode(true)
		setItemsToLocalStorage([
			{ key: 'selected_asset', value: 'website-demo' },
			{ key: 'selected_account', value: 'workspace-demo' }
		])
		await setAssetForId('workspace-demo', 'website-demo')
		navigate('/workspace/workspace-demo/asset/website-demo')
	}, [])

	const setStep = (step: string) => {
		setState({ ...state, step: step })
	}

	useEffect(() => {
		const platforms = AdvertisingPlatforms.filter((p) =>
			accountData?.setup?.platforms.map((f) => f).includes(p.value)
		)
		const sector = SectorTypes.find(
			(s) => s.value === accountData?.setup?.sector
		)
		const industry = IndustryTypes.find(
			(i) => i.value === accountData?.setup?.industry
		)
		setState({
			...state,
			platforms,
			sector,
			industry,
			domain: accountData?.setup?.domain || '',
			spend: accountData?.setup?.spend || 0
		})
	}, [accountData])

	useEffect(() => {
		getAllAccounts()
	}, [])

	return (
		<StartStandardView
			state={state}
			setStep={setStep}
			setDomain={(d) => setState({ ...state, domain: d })}
			setSector={(s) => setState({ ...state, sector: s })}
			setIndustry={(i) => setState({ ...state, industry: i })}
			setPlatforms={(p) => setState({ ...state, platforms: p })}
			setSpend={(s) => setState({ ...state, spend: s })}
			onNext={onNext}
			handleBack={handleBack}
		/>
	)
}
