import { twMerge } from 'tailwind-merge'
import { GInputProps } from './types'

export const GInput = (props: GInputProps) => {
	const {
		onChange: handleChange,
		label,
		helpText,
		error,
		type = 'text',
		icon: Icon,
		errorClassName,
		errorPosition,
		inputClassName,
		labelClassName,
		className,
		ref,
		id,
		value,
		checked,
		tagLabel,
		prefix,
		prefixClassName,
		borderError,
		...rest
	} = props

	return (
		<div className={`${className} relative`}>
			{label && (
				<label
					htmlFor={label}
					className={`text-left block ${
						labelClassName || 'text-t-default mb-1 text-md font-bold'
					}`}
				>
					{label}
				</label>
			)}
			<div className="rounded-md relative">
				<input
					ref={ref}
					type={type}
					className={twMerge(
						'bg-transparent disabled:bg-gray-100 focus:ring-primary-500 focus:border-primary-500 block rounded-md',
						'font-medium placeholder:text-t-secondary w-full',
						inputClassName,
						Icon ? 'pl-10' : '',
						prefix ? 'pl-7 relative' : '',
						borderError && error ? 'border-red-800 ' : 'border-t-input-border'
					)}
					onChange={(e) => handleChange(e)}
					checked={checked}
					{...(rest || {})}
					value={value}
					id={id}
				/>
				{prefix && (
					<span className={`absolute ${prefixClassName || ''}`}>{prefix}</span>
				)}

				{tagLabel && (
					<span className="absolute bg-gray-100 h-[33px] inset-y-0 px-4 py-2 right-0">
						{tagLabel}
					</span>
				)}

				{Icon && (
					<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
						<Icon className="h-4 w-4 text-gray-400" aria-hidden="true" />
					</div>
				)}
				{errorPosition === 'flex' && error && (
					<p
						className={twMerge(
							'mt-1 text-xs text-red-700 bottom-[-15px]',
							errorClassName
						)}
						id="email-description"
					>
						{error}
					</p>
				)}
			</div>
			{!errorPosition && error && (
				<p
					className={twMerge('mt-1 text-xs text-red-700', errorClassName)}
					id="email-description"
				>
					{error}
				</p>
			)}
			{errorPosition === 'absolute' && error && (
				<p
					className={twMerge('absolute text-xs text-red-700', errorClassName)}
					id="email-description"
				>
					{error}
				</p>
			)}
			{helpText && (
				<p className="mt-2 text-sm text-t-secondary" id="email-description">
					{helpText}
				</p>
			)}
		</div>
	)
}
