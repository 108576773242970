import { AdAccountDTO } from 'api-models'
import { EventsList } from 'components/events-list'
import { CampaignsSection } from './campiagns-section'
import { InfoSection } from './info-section'
import { ProtectionSection } from './protection-section'

export const InfoTab = (props: { ppcAccount: AdAccountDTO }) => {
	const { ppcAccount } = props
	return (
		<div className="pt-2">
			<div className="flex flex-wrap gap-6">
				<div className="flex-1">
					<InfoSection ppcAccount={ppcAccount} />
					<ProtectionSection ppcAccount={ppcAccount} />
				</div>
				<div className="flex flex-col flex-1">
					<EventsList id={ppcAccount.id} type="ppc-account" />
					<CampaignsSection ppcAccount={ppcAccount} />
				</div>
			</div>
		</div>
	)
}
