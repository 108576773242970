import { AccountLinkDTO, FacebookAccountLinkDTO } from 'api-models'
import { GButton, GSection, GTooltip } from 'components/basic-blocks'
import { Dispatch } from 'react'
import { BsMeta } from 'react-icons/bs'
import { GBadge } from '../../../components/basic-blocks/g-badge'
import { useStores } from '../../../hooks'
import { ZendeskService } from '../../../services/zendesk'
import { ConfigurePpcAccess } from './configure-ppc-access'

export const FacebookAdTabs = (props: {
	links: AccountLinkDTO[]
	isAnalyst: boolean
	setModalNewConnection: Dispatch<{
		platform: 'GADS' | 'FADS'
		MCC: 'oauth' | 'mcc-account'
		active: boolean
	}>
}) => {
	const { links, isAnalyst, setModalNewConnection } = props
	const { accountStore } = useStores()
	const { wantsToSeeDemoData } = accountStore

	const facebookConnections = links.filter(
		(link) => link.type === 'oauth'
	) as FacebookAccountLinkDTO[]

	return (
		<div className="mt-8">
			<GSection
				title={'Meta Ads'}
				titleClass={facebookConnections?.length > 0 ? 'mb-6' : 'mb-0'}
				lineBreak={facebookConnections.length > 0}
				lineBreakColor="gray-100"
				actions={
					<GTooltip
						content={
							wantsToSeeDemoData
								? 'This action is disabled when demo data is displayed'
								: isAnalyst
								? "Analysts don't have permission for this action"
								: undefined
						}
					>
						<GButton
							variant="text"
							labelClassName="font-bold"
							color="primary"
							size="md"
							className="h-[30px] border-t-input-border"
							disabled={wantsToSeeDemoData || isAnalyst}
							label="New OAuth link"
							onClick={() =>
								setModalNewConnection({
									platform: 'FADS',
									active: true,
									MCC: 'oauth'
								})
							}
						/>
					</GTooltip>
				}
			>
				{facebookConnections.length > 0 &&
					facebookConnections.map((connection, index) => {
						return (
							<FacebookConnectingTemplate
								link={connection}
								key={index}
								isAnalyst={isAnalyst}
							/>
						)
					})}
			</GSection>
		</div>
	)
}

const FacebookConnectingTemplate = (props: {
	link: FacebookAccountLinkDTO
	isAnalyst: boolean
}) => {
	const { link, isAnalyst } = props

	const color = link.status === 'UNLINKED' ? 'amber' : 'green'

	return (
		<div className="flex justify-between items-center mt-6">
			<div className="flex justify-start items-center space-x-4">
				<BsMeta className="text-primary-500 w-6 h-6" />
				<div className="flex flex-col justify-start items-start ">
					<div className="text-sm leading-5 text-t-secondary">
						Facebook OAuth
					</div>
					<div className="text-md text-t-default">{link.email}</div>
				</div>
			</div>
			<div className="flex justify-end items-end gap-4">
				<GBadge
					className="h-[28px] py-0.5 px-3"
					color={color}
					text={<span className="font-bold px-1 font-sm">{link.status}</span>}
				/>
				<ConfigurePpcAccess link={link} platform={'FADS'} isAnalyst={isAnalyst}>
					{(open) => (
						<GTooltip
							content={
								isAnalyst
									? "Analysts don't have permission for this action"
									: undefined
							}
						>
							<GButton
								variant="text"
								label="Configure"
								size="lg"
								color="buttonPrimary"
								className="h-[28px] w-[84px] disabled:bg-gray-200 disabled:text-t-secondary border-t-input-border"
								labelClassName="font-bold text-sm"
								disabled={isAnalyst}
								onClick={() => {
									open()
									ZendeskService.hide()
								}}
							/>
						</GTooltip>
					)}
				</ConfigurePpcAccess>
			</div>
		</div>
	)
}
