import { FacebookAdsAdAccountDTO, GoogleAdsAdAccountDTO } from 'api-models'
import { GLoading, GTransition } from 'components/basic-blocks'
import { PageHeader } from 'components/page-header'
import { TabItem } from 'components/tabs/types'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { removeFadPrefix } from 'utils'
import { PageTitle } from 'utils/page-title'
import { InfoTab } from './info-tab/info-tab'
import { NotesTab } from './notes-tab'

export const AdminPPCAccountPage = () => {
	const { ppcAccountId } = useParams()
	const navigate = useNavigate()
	const { adminStore } = useStores()
	const { ppcAccount, getAdAccountsById, getNotes } = adminStore

	const [search] = useSearchParams('tab=info')

	const [total, setTotal] = useState(0)

	const fetchData = async (skip: number) => {
		if (!ppcAccountId) return
		const { total } = await getNotes(ppcAccountId, {
			type: 'website',
			skip: skip,
			limit: 6
		})
		setTotal(total)
	}

	useEffect(() => {
		;(async () => {
			await fetchData(0)
		})()
	}, [])

	const tabs: Array<TabItem> = [
		{
			key: 'info',
			label: 'Information',
			to: () => `/admin/ppc-account/${ppcAccountId}/?tab=info`
		},
		{
			key: 'notes',
			label: (
				<div>
					Notes{' '}
					<span
						className={`${
							search.get('tab') === 'notes'
								? 'bg-badge-selected text-badge-text-selected'
								: 'bg-badge-default text-badge-text-t-default'
						} text-sm font-medium mr-2 mx-2.5 px-1.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300`}
					>
						{total || 0}
					</span>
				</div>
			),
			to: () => `/admin/ppc-account/${ppcAccountId}/?tab=notes`
		}
	]

	const [selectedTab, setSelectedTab] = useState(
		search.get('tab') || tabs[0].key
	)

	const init = async () => {
		if (ppcAccountId) {
			try {
				await getAdAccountsById(ppcAccountId)
				return
			} catch (e) {
				navigate('/admin/ppc-accounts')
			}
		}
		navigate('/admin/ppc-accounts')
	}

	useEffect(() => {
		init()
	}, [ppcAccountId])

	useEffect(() => {
		const tab = search.get('tab') || tabs[0].key
		setSelectedTab(tab)
	}, [search, ppcAccountId])

	if (!ppcAccount) {
		return <GLoading />
	}

	const AdAccountPlatform = () => {
		let adAccount
		if (ppcAccount.platform === 'GADS') {
			adAccount = ppcAccount as GoogleAdsAdAccountDTO
		} else {
			adAccount = ppcAccount as FacebookAdsAdAccountDTO
		}
		return adAccount
	}

	return (
		<div className="">
			<PageTitle pageName="PPC account" />
			<PageHeader
				title={ppcAccount.name || '[No name]'}
				subtitle={`${
					ppcAccount.platform === 'GADS' ? 'Google Ads' : 'Meta Ads'
				} - ${removeFadPrefix(AdAccountPlatform().details.account_number)}`}
				tabs={tabs}
				currentTab={selectedTab}
			/>
			<div className="w-full px-4 sm:px-6">
				<GTransition swap show={selectedTab === 'info'}>
					<InfoTab ppcAccount={ppcAccount} />
				</GTransition>
				<GTransition swap show={selectedTab === 'notes'}>
					<NotesTab
						topicId={ppcAccount?.id}
						total={total}
						setTotal={setTotal}
					/>
				</GTransition>
			</div>
		</div>
	)
}
