import { useState } from 'react'

import { SimpleBarChart } from 'components/charts/simple-bar-chart'
import { classNames } from 'utils'
import { color } from 'utils/colors'

import { GMetricLine } from 'components/basic-blocks/g-metric-line/g-metric-line'
import { GRateBadged } from 'components/basic-blocks/g-rate-badge'
import { FormattedAdminRevenueStatsDTO } from '../../types'
import { NetChangePanelProps } from './types'
import { BarTooltip } from '../../../../../components/basic-blocks/g-bartooltip'

type Format = 'dollar' | 'percentage'

const getValueBasedOnFormat = (
	format: Format,
	data: FormattedAdminRevenueStatsDTO,
	key: string
) => {
	const fullKey =
		format === 'dollar' ? `${key}_in_currency` : `${key}_percentage`
	return `${data[fullKey]}${format === 'percentage' ? '%' : ''}`
}
const getChartValueBasedOnFormat = (
	format: Format,
	data: FormattedAdminRevenueStatsDTO,
	key: string
) => {
	const fullKey = format === 'dollar' ? `${key}` : `${key}_percentage`
	return data[fullKey]
}

export const NetChangePanel = ({ data }: NetChangePanelProps) => {
	const [format, setFormat] = useState<Format>('dollar')

	if (!data) return null

	return (
		<section className="bg-white px-4 py-4 text-t-default divide-y divide-gray-100 rounded">
			<div className="pb-3">
				<div className="flex justify-between mb-2">
					<span className="font-bold text-md">Net change</span>
					<div className="grid grid-cols-2 text-center divide-x-2 border-2 border-gray-200 rounded-md font-bold cursor-pointer w-20 text-sm">
						<span
							className={classNames(
								'py-0.5',
								format === 'dollar'
									? 'text-t-default'
									: 'text-t-secondary bg-gray-100'
							)}
							onClick={() => setFormat('dollar')}
						>
							$
						</span>
						<span
							className={classNames(
								'py-0.5',
								format === 'percentage'
									? 'text-t-default'
									: 'text-t-secondary bg-gray-100'
							)}
							onClick={() => setFormat('percentage')}
						>
							%
						</span>
					</div>
				</div>

				<div className="flex items-center">
					<span className="font-bold mr-2 text-2xl">
						{data.net_change_in_currency}
					</span>
					{data.net_change_rate ? (
						<GRateBadged rate={data.net_change_rate} />
					) : null}
				</div>
			</div>
			<div className="py-3">
				<div className="flex justify-between mb-2">
					<div className="flex items-center">
						<div className="bg-green-400 h-3 w-3 rounded-full mr-1" />
						<span className="font-bold text-sm">Gained MRR</span>
					</div>
					<div className="flex items-center">
						<span className="font-bold mr-1">
							{data.gained_mrr_in_currency}
						</span>
						{data.gained_mrr_rate ? (
							<GRateBadged rate={data.gained_mrr_rate} />
						) : null}
					</div>
				</div>

				<SimpleBarChart
					data={[
						{
							id: 'mrr',
							'Upgrade MRR': getChartValueBasedOnFormat(
								format,
								data,
								'upgrade_mrr'
							),
							'Returning MRR': getChartValueBasedOnFormat(
								format,
								data,
								'returning_mrr'
							),
							'New MRR': getChartValueBasedOnFormat(format, data, 'new_mrr')
						}
					]}
					keys={['Upgrade MRR', 'Returning MRR', 'New MRR']}
					colors={[
						color('green', 300),
						color('green', 400),
						color('green', 600)
					]}
					format={format}
					tooltip={BarTooltip}
				>
					<div className="flex flex-col h-full mt-2">
						<GMetricLine
							title="Upgrade MRR"
							titleClassName="text-sm"
							bulletColor="bg-green-300"
							value={getValueBasedOnFormat(format, data, 'upgrade_mrr')}
							rate={data.upgrade_mrr_rate}
						/>
						<GMetricLine
							title="Returning MRR"
							titleClassName="text-sm"
							bulletColor="bg-green-400"
							value={getValueBasedOnFormat(format, data, 'returning_mrr')}
							rate={data.returning_mrr_rate}
						/>
						<GMetricLine
							title="New MRR"
							titleClassName="text-sm"
							bulletColor="bg-green-600"
							value={getValueBasedOnFormat(format, data, 'new_mrr')}
							rate={data.new_mrr_rate}
						/>
					</div>
				</SimpleBarChart>
			</div>
			<div className="pt-3">
				<div className="flex justify-between mb-2">
					<div className="flex items-center">
						<div className="bg-red-400 h-3 w-3 rounded-full mr-1" />
						<span className="font-bold text-sm">Churned MRR</span>
					</div>
					<div className="flex items-center">
						<span className="font-bold mr-1">
							{data.churned_mrr_in_currency}
						</span>
						{data.churned_mrr_rate ? (
							<GRateBadged rate={data.churned_mrr_rate} />
						) : null}
					</div>
				</div>

				<SimpleBarChart
					data={[
						{
							id: 'mrr',
							'Downgrade MRR': getChartValueBasedOnFormat(
								format,
								data,
								'downgrade_mrr'
							),
							'Cancelled MRR': getChartValueBasedOnFormat(
								format,
								data,
								'cancelled_mrr'
							)
						}
					]}
					keys={['Downgrade MRR', 'Cancelled MRR']}
					colors={[color('red', 300), color('red', 600)]}
					format={format}
					tooltip={BarTooltip}
				>
					<div className="flex flex-col h-full mt-2">
						<GMetricLine
							title="Downgrade MRR"
							titleClassName="text-sm"
							bulletColor="bg-red-300"
							value={getValueBasedOnFormat(format, data, 'downgrade_mrr')}
							rate={data.downgrade_mrr_rate}
						/>
						<GMetricLine
							title="Cancelled MRR"
							titleClassName="text-sm"
							bulletColor="bg-red-600"
							value={getValueBasedOnFormat(format, data, 'cancelled_mrr')}
							rate={data.cancelled_mrr_rate}
						/>
					</div>
				</SimpleBarChart>
			</div>
		</section>
	)
}
