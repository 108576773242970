import Joi from 'joi'
import { useEffect, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { RiLockPasswordFill } from 'react-icons/ri'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useUiStore } from 'store'
import { GButton, GInput, GLoading } from '../../../components/basic-blocks'
import { OnBoarding } from '../../../components/onboarding/onboarding'
import { useStores } from '../../../hooks'

const passwordSchema = Joi.object({
	password: Joi.string()
		.pattern(new RegExp('^(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$'))
		.required()
		.messages({
			'string.pattern.base':
				'Password must be at least 8 characters long and contain at least one special character.'
		})
})

const validatePassword = (password: string) => {
	const { error } = passwordSchema.validate({ password })
	return error ? error.details[0].message : null
}

export const ChangePassword = () => {
	const navigate = useNavigate()
	const [isValidCode, setIsValidCode] = useState(false)
	const [search] = useSearchParams()
	const code = search.get('code')
	const {
		userStore: { changePassword, getPasswordRequest }
	} = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	const [data, setData] = useState({
		password: '',
		confirmPassword: '',
		showPassword: false,
		showConfirmPassword: false
	})
	const [error, setError] = useState<{
		password: undefined | string
		confirmPassword: undefined | string
	}>({
		password: undefined,
		confirmPassword: undefined
	})

	const handleSubmit = async () => {
		const validationError = validatePassword(data.password)
		if (validationError) {
			setError({ ...error, password: validationError })
		} else if (data.password !== data.confirmPassword) {
			setError({
				password: undefined,
				confirmPassword: 'Passwords do not match.'
			})
		} else {
			setError({ password: undefined, confirmPassword: undefined })
			const isSuccess = await changePassword(
				data.password,
				data.confirmPassword,
				code || ''
			)
			if (isSuccess) {
				navigate('/')
			}
		}
	}

	const handleGetPasswordRequest = async () => {
		const data = await getPasswordRequest(code || '')
		setIsValidCode(!!data)
	}

	useEffect(() => {
		setIsValidCode(!!code)
		handleGetPasswordRequest()
	}, [])

	return (
		<>
			<OnBoarding
				backToLogin={true}
				title={
					<div className="text-3xl text-[#FFFFFF] font-[800]">
						<p className="text-4xl">
							<RiLockPasswordFill className="w-14 h-14 text-red-400 mb-4" />
						</p>
						Make sure you set a strong password.
					</div>
				}
			>
				{isValidCode ? (
					<div className="w-full min-h-full py-16">
						<div className="relative">
							<GInput
								label="New password"
								className="mb-4"
								value={data.password}
								error={error.password}
								onChange={(e) => setData({ ...data, password: e.target.value })}
								type={data.showPassword ? 'text' : 'password'}
								inputClassName="pr-12"
							/>
							<div
								className="absolute top-[20px] right-[0px] p-4 cursor-pointer"
								onClick={() =>
									setData({
										...data,
										showPassword: !data.showPassword
									})
								}
							>
								{data.showPassword ? (
									<FaEyeSlash className="w-4 h-4" />
								) : (
									<FaEye className="w-4 h-4" />
								)}
							</div>
						</div>

						<div className="relative">
							<GInput
								type={data.showConfirmPassword ? 'text' : 'password'}
								label="Confirm new password"
								className="mb-4"
								inputClassName="pr-12"
								value={data.confirmPassword}
								error={error.confirmPassword}
								onChange={(e) =>
									setData({ ...data, confirmPassword: e.target.value })
								}
							/>
							<div
								className="absolute top-[20px] right-[0px] p-4 cursor-pointer"
								onClick={() =>
									setData({
										...data,
										showConfirmPassword: !data.showConfirmPassword
									})
								}
							>
								{data.showConfirmPassword ? (
									<FaEyeSlash className="w-4 h-4" />
								) : (
									<FaEye className="w-4 h-4" />
								)}
							</div>
						</div>
						<div className="flex justify-center">
							<GButton
								color="primary"
								loading={isLoading('USER_PASSWORD_CHANGE')}
								iconPlacement="right"
								className="w-full mt-6"
								onClick={handleSubmit}
							>
								<span className="px-4">Change password</span>
							</GButton>
						</div>
					</div>
				) : (
					<div>
						<GLoading />
						<p>Code expire or invalid request please resend mail</p>
						<GButton
							label={'Resend mail'}
							color="primary"
							className="w-full mt-6"
							onClick={() => navigate('/reset-password')}
						/>
					</div>
				)}
			</OnBoarding>
		</>
	)
}
