import { ReactNode, createContext } from 'react'
import { useAnalyticsReducer } from './reducer'

interface AnalyticsTableFilterType {
	dispatch: any
	state: any
}
export const AnalyticsTableFilter = createContext(
	{} as AnalyticsTableFilterType
)

interface AnalyticsTableFilterProviderProps {
	children: ReactNode
}
export function AnalyticsTableFilterProvider({
	children
}: AnalyticsTableFilterProviderProps) {
	const { dispatch, state } = useAnalyticsReducer()

	return (
		<AnalyticsTableFilter.Provider
			value={{
				dispatch,
				state
			}}
		>
			{children}
		</AnalyticsTableFilter.Provider>
	)
}
