"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleType = exports.OwnershipType = exports.CriteriaModifierType = exports.CriteriaModifierMode = exports.CriteriaType = exports.ScopeType = exports.TargetType = exports.SourceType = exports.ResolveStatus = exports.RuleStatus = void 0;
var RuleStatus;
(function (RuleStatus) {
    RuleStatus["ACTIVE"] = "ACTIVE";
    RuleStatus["PAUSED"] = "PAUSED";
})(RuleStatus = exports.RuleStatus || (exports.RuleStatus = {}));
var ResolveStatus;
(function (ResolveStatus) {
    ResolveStatus["SUSPICIOUS"] = "SUSPICIOUS";
    ResolveStatus["BAD"] = "BAD";
})(ResolveStatus = exports.ResolveStatus || (exports.ResolveStatus = {}));
var SourceType;
(function (SourceType) {
    SourceType["IP"] = "IP";
    SourceType["RANGE"] = "RANGE";
    SourceType["DEVICE"] = "DEVICE";
    SourceType["PLACEMENT"] = "PLACEMENT";
    SourceType["VISITOR"] = "VISITOR";
})(SourceType = exports.SourceType || (exports.SourceType = {}));
var TargetType;
(function (TargetType) {
    TargetType["CAMPAIGN"] = "CAMPAIGN";
    TargetType["AD_GROUP"] = "AD_GROUP";
    TargetType["KEYWORD"] = "KEYWORD";
})(TargetType = exports.TargetType || (exports.TargetType = {}));
var ScopeType;
(function (ScopeType) {
    ScopeType["WEBSITE"] = "WEBSITE";
    ScopeType["ACCOUNT"] = "ACCOUNT";
    ScopeType["CAMPAIGNS"] = "CAMPAIGNS";
    ScopeType["AD_GROUPS"] = "AD_GROUPS";
    ScopeType["KEYWORDS"] = "KEYWORDS";
})(ScopeType = exports.ScopeType || (exports.ScopeType = {}));
var CriteriaType;
(function (CriteriaType) {
    CriteriaType["SOURCE_FREQ"] = "SOURCE_FREQ";
    CriteriaType["TARGET_FREQ"] = "TARGET_FREQ";
    CriteriaType["SHARE"] = "SHARE";
})(CriteriaType = exports.CriteriaType || (exports.CriteriaType = {}));
var CriteriaModifierMode;
(function (CriteriaModifierMode) {
    CriteriaModifierMode["REALTIME"] = "REALTIME";
    CriteriaModifierMode["POST"] = "POST";
})(CriteriaModifierMode = exports.CriteriaModifierMode || (exports.CriteriaModifierMode = {}));
var CriteriaModifierType;
(function (CriteriaModifierType) {
    CriteriaModifierType["THREAT_LEVEL"] = "THREAT_LEVEL";
    CriteriaModifierType["IP_USAGE"] = "IP_USAGE";
    CriteriaModifierType["PROXY"] = "PROXY";
    CriteriaModifierType["DEVICE_TYPE"] = "DEVICE_TYPE";
    CriteriaModifierType["COUNTRY"] = "COUNTRY";
    CriteriaModifierType["BOT_PROBABILITY"] = "BOT_PROBABILITY";
    CriteriaModifierType["INTERACTION"] = "INTERACTION";
    CriteriaModifierType["TIME"] = "TIME";
    CriteriaModifierType["PAGE_VIEWS"] = "PAGE_VIEWS";
    CriteriaModifierType["NO_DEVICE"] = "NO_DEVICE";
    CriteriaModifierType["CONVERSION"] = "CONVERSION";
    CriteriaModifierType["NOT_CONVERSION"] = "NOT_CONVERSION";
    CriteriaModifierType["BAD_CLICKS"] = "BAD_CLICKS";
    CriteriaModifierType["SEARCH_KEYWORD"] = "SEARCH_KEYWORD";
})(CriteriaModifierType = exports.CriteriaModifierType || (exports.CriteriaModifierType = {}));
var OwnershipType;
(function (OwnershipType) {
    OwnershipType["WEBSITE"] = "WEBSITE";
    OwnershipType["AD_ACCOUNT"] = "AD_ACCOUNT";
    OwnershipType["CAMPAIGN"] = "CAMPAIGN";
})(OwnershipType = exports.OwnershipType || (exports.OwnershipType = {}));
var RuleType;
(function (RuleType) {
    RuleType["SCOUT"] = "SCOUT";
    RuleType["HUNTER"] = "HUNTER";
    RuleType["CUSTOM"] = "CUSTOM";
})(RuleType = exports.RuleType || (exports.RuleType = {}));
