"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleAdsCampaignStatus = exports.GoogleAdsAdGroupStatus = exports.GoogleAdsCampaignSubtype = exports.GoogleAdsCampaignType = void 0;
var GoogleAdsCampaignType;
(function (GoogleAdsCampaignType) {
    GoogleAdsCampaignType["UNSPECIFIED"] = "UNSPECIFIED";
    GoogleAdsCampaignType["UNKNOWN"] = "UNKNOWN";
    GoogleAdsCampaignType["SHOPPING"] = "SHOPPING";
    GoogleAdsCampaignType["SEARCH"] = "SEARCH";
    GoogleAdsCampaignType["DISPLAY"] = "DISPLAY";
    GoogleAdsCampaignType["HOTEL"] = "HOTEL";
    GoogleAdsCampaignType["VIDEO"] = "VIDEO";
    GoogleAdsCampaignType["MULTI_CHANNEL"] = "MULTI_CHANNEL";
    GoogleAdsCampaignType["LOCAL"] = "LOCAL";
    GoogleAdsCampaignType["SMART"] = "SMART";
    GoogleAdsCampaignType["PERFORMANCE_MAX"] = "PERFORMANCE_MAX";
    GoogleAdsCampaignType["DISCOVERY"] = "DISCOVERY";
    GoogleAdsCampaignType["LOCAL_SERVICES"] = "LOCAL_SERVICES";
    GoogleAdsCampaignType["TRAVEL"] = "TRAVEL";
    GoogleAdsCampaignType["DEMAND_GEN"] = "DEMAND_GEN";
})(GoogleAdsCampaignType = exports.GoogleAdsCampaignType || (exports.GoogleAdsCampaignType = {}));
var GoogleAdsCampaignSubtype;
(function (GoogleAdsCampaignSubtype) {
    GoogleAdsCampaignSubtype["UNSPECIFIED"] = "UNSPECIFIED";
    GoogleAdsCampaignSubtype["UNKNOWN"] = "UNKNOWN";
    GoogleAdsCampaignSubtype["SEARCH_MOBILE_APP"] = "SEARCH_MOBILE_APP";
    GoogleAdsCampaignSubtype["DISPLAY_MOBILE_APP"] = "DISPLAY_MOBILE_APP";
    GoogleAdsCampaignSubtype["SEARCH_EXPRESS"] = "SEARCH_EXPRESS";
    GoogleAdsCampaignSubtype["DISPLAY_EXPRESS"] = "DISPLAY_EXPRESS";
    GoogleAdsCampaignSubtype["SHOPPING_SMART_ADS"] = "SHOPPING_SMART_ADS";
    GoogleAdsCampaignSubtype["DISPLAY_GMAIL_AD"] = "DISPLAY_GMAIL_AD";
    GoogleAdsCampaignSubtype["DISPLAY_SMART_CAMPAIGN"] = "DISPLAY_SMART_CAMPAIGN";
    GoogleAdsCampaignSubtype["VIDEO_OUTSTREAM"] = "VIDEO_OUTSTREAM";
    GoogleAdsCampaignSubtype["VIDEO_ACTION"] = "VIDEO_ACTION";
    GoogleAdsCampaignSubtype["VIDEO_NON_SKIPPABLE"] = "VIDEO_NON_SKIPPABLE";
    GoogleAdsCampaignSubtype["APP_CAMPAIGN"] = "APP_CAMPAIGN";
    GoogleAdsCampaignSubtype["APP_CAMPAIGN_FOR_ENGAGEMENT"] = "APP_CAMPAIGN_FOR_ENGAGEMENT";
    GoogleAdsCampaignSubtype["LOCAL_CAMPAIGN"] = "LOCAL_CAMPAIGN";
    GoogleAdsCampaignSubtype["SHOPPING_COMPARISON_LISTING_ADS"] = "SHOPPING_COMPARISON_LISTING_ADS";
    GoogleAdsCampaignSubtype["SMART_CAMPAIGN"] = "SMART_CAMPAIGN";
    GoogleAdsCampaignSubtype["VIDEO_SEQUENCE"] = "VIDEO_SEQUENCE";
    GoogleAdsCampaignSubtype["APP_CAMPAIGN_FOR_PRE_REGISTRATION"] = "APP_CAMPAIGN_FOR_PRE_REGISTRATION";
    GoogleAdsCampaignSubtype["VIDEO_REACH_TARGET_FREQUENCY"] = "VIDEO_REACH_TARGET_FREQUENCY";
    GoogleAdsCampaignSubtype["TRAVEL_ACTIVITIES"] = "TRAVEL_ACTIVITIES";
})(GoogleAdsCampaignSubtype = exports.GoogleAdsCampaignSubtype || (exports.GoogleAdsCampaignSubtype = {}));
var GoogleAdsAdGroupStatus;
(function (GoogleAdsAdGroupStatus) {
    GoogleAdsAdGroupStatus["ENABLED"] = "ENABLED";
    GoogleAdsAdGroupStatus["PAUSED"] = "PAUSED";
    GoogleAdsAdGroupStatus["UNSPECIFIED"] = "UNSPECIFIED";
    GoogleAdsAdGroupStatus["REMOVED"] = "REMOVED";
    GoogleAdsAdGroupStatus["UNKNOWN"] = "UNKNOWN";
})(GoogleAdsAdGroupStatus = exports.GoogleAdsAdGroupStatus || (exports.GoogleAdsAdGroupStatus = {}));
var GoogleAdsCampaignStatus;
(function (GoogleAdsCampaignStatus) {
    GoogleAdsCampaignStatus["ENABLED"] = "ENABLED";
    GoogleAdsCampaignStatus["PAUSED"] = "PAUSED";
    GoogleAdsCampaignStatus["UNSPECIFIED"] = "UNSPECIFIED";
    GoogleAdsCampaignStatus["REMOVED"] = "REMOVED";
    GoogleAdsCampaignStatus["UNKNOWN"] = "UNKNOWN";
})(GoogleAdsCampaignStatus = exports.GoogleAdsCampaignStatus || (exports.GoogleAdsCampaignStatus = {}));
