import { GButton, GDialog, GInput } from 'components/basic-blocks'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { useUiStore } from 'store'
import { TrailProps, TrialState } from './types'

export const Trial = (props: TrailProps) => {
	const { children, account, type = 'new' } = props
	const { adminStore } = useStores()
	const isLoading = useUiStore((s) => s.isLoading)
	const { startTrial, updateTrial, createTrialForInactive } = adminStore

	const [dialogOpen, setDialogOpen] = useState(false)
	const [state, setState] = useState<TrialState>({
		days: 0,
		clicks: 0
	})

	useEffect(() => {
		if (type === 'update')
			setState({
				days: account.billing?.subscription?.plan.interval_count || 0,
				clicks: account.billing?.subscription?.plan.clicks || 0
			})
	}, [dialogOpen])

	const handleChange = (e: string, name: string) => {
		setState({ ...state, [name]: e })
	}

	const handleDialogClose = () => {
		setDialogOpen(false)
		setState({
			days: 0,
			clicks: 0
		})
	}

	const handleSubmit = async () => {
		const payload = {
			days: state.days,
			clicks: state.clicks
		}
		if (type === 'new') {
			try {
				await startTrial(payload, account.id)
				handleDialogClose()
			} catch (e) {
				console.log('error', e)
			}
		}
		if (!account?.billing?.subscription?.id) {
			return
		}
		if (type === 'update') {
			try {
				await updateTrial(payload, account?.billing?.subscription.id)
				handleDialogClose()
			} catch (e) {
				console.log('error', e)
			}
		}
		if (type === 'inactive') {
			try {
				await createTrialForInactive(state.days, account.id)
				handleDialogClose()
			} catch (e) {
				console.log('error', e)
			}
		}
	}

	return (
		<>
			{children && children(() => setDialogOpen(true))}
			<GDialog
				title={type === 'new' ? 'New Trial' : 'Update trial'}
				subtitle={
					type === 'new'
						? 'By performing this action you start a new trial period.'
						: 'By performing this action you will update current trial'
				}
				open={dialogOpen}
				onClose={() => handleDialogClose()}
				maxWidth="3xl"
				className="sm:max-w-3xl overflow-clip"
			>
				<div className="my-8 space-y-4">
					<GInput
						label="Durations (days)"
						type="number"
						value={state.days}
						onChange={(e) => {
							handleChange(e.target.value, 'days')
						}}
					/>
					{type !== 'inactive' && (
						<GInput
							label="Trial clicks"
							type="number"
							value={state.clicks}
							onChange={(e) => {
								handleChange(e.target.value, 'clicks')
							}}
						/>
					)}
				</div>
				<div className="flex justify-end gap-2">
					<GButton label="Cancel" onClick={() => handleDialogClose()} />
					<GButton
						label="Confirm"
						onClick={() => handleSubmit()}
						color="primary"
						loading={isLoading('ADMIN_CHANGE_ACCOUNT')}
					/>
				</div>
			</GDialog>
		</>
	)
}
