import { GConfirm } from 'components/basic-blocks'
import { useStores } from 'hooks'
import { useState } from 'react'
import { useUiStore } from 'store'
import { NoteDTO } from '../../../../api-models/src'

export const DeleteNote = ({
	note,
	fetchData
}: {
	note: NoteDTO
	fetchData: (s: number) => void
}) => {
	const {
		adminStore: { deleteNoteById }
	} = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	const [open, setOpen] = useState<boolean>(false)
	const handleDelete = async () => {
		await deleteNoteById(note.id)
		fetchData(0)
		setOpen(false)
	}
	return (
		<GConfirm
			title="Delete Note"
			description="Are you sure you want to remove this note. Proceed?"
			type="danger"
			open={open}
			setOpen={(e) => setOpen(e)}
			onConfirm={handleDelete}
			loading={isLoading('ADMIN_DELETE_NOTE')}
		>
			<span className="font-medium text-red-900 cursor-pointer">Delete</span>
		</GConfirm>
	)
}
