import { ExclusionsPageFiltersContextProvider } from './context'
import { lazy, Suspense } from 'react'
import { GLazyLoading } from '../../../../components/basic-blocks'

const ExclusionsPageView = lazy(() =>
	import('./view')
		.then((module) => {
			return { default: module.ExclusionsPageView }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)

export const ExclusionsPage = () => {
	return (
		<ExclusionsPageFiltersContextProvider>
			<Suspense fallback={<GLazyLoading />}>
				<ExclusionsPageView />
			</Suspense>
		</ExclusionsPageFiltersContextProvider>
	)
}
