export const publicRoutes = [
	'/reset-password',
	'/sign-up',
	'/login',
	'/change-password',
	'/emails/verify',
	'/start/affiliate',
	'/click-fraud-calculator',
	'/click-fraud-report'
]
