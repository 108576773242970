import PropTypes from 'prop-types'
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'
import { twMerge } from 'tailwind-merge'
import { GButton } from '../../g-button'

export const GPagination = (props: any) => {
	const {
		itemsCount,
		pageSize,
		currentPage,
		pageListener,
		onPageChange,
		paginationClassName
	} = props
	const pagesCount = Math.ceil(itemsCount / pageSize)
	const firstItem = (currentPage - 1) * pageSize + 1
	const lastItem =
		firstItem + pageSize - 1 >= itemsCount
			? itemsCount
			: firstItem + pageSize - 1

	if (pagesCount === 1) return null

	return (
		<nav
			className={twMerge(
				'mt-6 -mb-1 flex items-center justify-between',
				paginationClassName
			)}
			aria-label="Pagination"
		>
			<div className="hidden sm:block">
				<p className="text-sm text-t-default">
					Showing <span className="font-medium">({firstItem}</span> to{' '}
					<span className="font-medium">{lastItem})</span> of
					<span className="font-medium"> {itemsCount} </span> results
				</p>
			</div>
			<div className="flex-1 flex justify-between sm:justify-end">
				<GButton
					onClick={() => {
						onPageChange(currentPage - 1)
						pageListener(
							(currentPage - 1 === 1 ? 0 : currentPage - 2) * pageSize
						)
					}}
					size="xs"
					disabled={currentPage === 1}
					className="relative inline-flex items-center px-4 border border-gray-300 text-sm font-medium rounded-md text-t-default bg-white hover:bg-gray-50"
					icon={RiArrowLeftSLine}
					iconPlacement="left"
				>
					Previous
				</GButton>
				<GButton
					onClick={() => {
						onPageChange(currentPage + 1)
						pageListener(currentPage * pageSize)
					}}
					size="xs"
					disabled={currentPage === pagesCount}
					className="ml-3 relative inline-flex items-center px-4 border border-gray-300 text-sm font-medium rounded-md text-t-default bg-white hover:bg-gray-50"
					icon={RiArrowRightSLine}
					iconPlacement="right"
				>
					Next
				</GButton>
			</div>
		</nav>
	)
}
GPagination.propTypes = {
	itemsCount: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	pageListener: PropTypes.func,
	paginationClassName: PropTypes.string
}
