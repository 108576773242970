interface HeaderProps {
	website?: any | null
	size?: number
	src?: string | null
	name?: string | null
	demoMode?: boolean
}

export const WebsiteAvatar = ({
	website,
	size,
	src,
	name,
	demoMode
}: HeaderProps) => {
	const getAvatar = () => {
		if (website?.name?.length > 0) {
			return `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${website?.name.replace(
				/http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g,
				''
			)}&size=32`
		}
	}

	if (demoMode) {
		return (
			<img
				className={`inline-block h-${size || 10} w-${size || 10} rounded-full`}
				src={'https://eu.ui-avatars.com/api/?name=Demo PPC account'}
				alt="website icon"
				onError={(e) =>
					(e.currentTarget.src =
						'https://eu.ui-avatars.com/api/?name=	Demo PPC account')
				}
			/>
		)
	}

	return (
		<img
			className={`inline-block h-${size || 10} w-${size || 10} rounded-full`}
			src={
				src ||
				website?.avatar ||
				getAvatar() ||
				`https://eu.ui-avatars.com/api/?name=${website?.name || name}`
			}
			alt="website icon"
			onError={(e) =>
				(e.currentTarget.src = `https://eu.ui-avatars.com/api/?name=${
					website?.name || name
				}`)
			}
		/>
	)
}
