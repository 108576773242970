import { OfferRequestDTO, OfferRequestInfoDTO } from 'api-models'
import { OfferDTO, ProductAccountDTO } from '../../../../api-models'

export const getPlanNameByTier = (tier: string): string => {
	const planName: Record<string, string> = {
		pro: 'ClickGUARD Pro',
		lite: 'ClickGUARD Lite',
		standard: 'ClickGUARD Standard',
		legacy: 'ClickGUARD Standard'
	}

	if (Object.keys(planName).includes(tier)) {
		return planName[tier]
	}

	return tier
}

export interface ICreateOfferErrors {
	price: string
	spend: string
	interval_count: string
	amount_off: string
	days: string
	expired_at: string
	months: string
}

export const offerData: ICreateOffer = {
	price: 0,
	period: 0,
	spend: 0,
	interval_count: 1,
	publish: true,
	toggleButton: { discount: false, trial: false, exp: false },
	tier: 'pro',
	interval: 'month',
	percent_off: 0,
	amount_off: 0,
	months: 0,
	duration: 'once',
	days: 0,
	expired_at: 0,
	error: null
}

export interface ICreateOffer {
	[key: string]: any

	price: number
	spend: number
	interval: 'day' | 'week' | 'month' | 'year'
	interval_count: number
	publish: boolean
	tier: string
	period: number
	percent_off: number
	amount_off: number
	duration: 'forever' | 'once' | 'repeating'
	months: number
	days: number
	expired_at: number
	error: ICreateOfferErrors | null
	toggleButton: IToggleProps
}

export interface IOfferForm {
	offerData: ICreateOffer
	handleDeleteOffer: (index: number) => void
	index: number
	handleChange: (e: any, index: number, discountToggle?: boolean) => void
	handleSelectionChange: (e: any, name: string, index: number) => void
	handleToggle: (name: string, value: boolean, index: number) => void
	isUpdatingOffer: boolean
	offerList: ICreateOffer[]
	selectedAccount: ProductAccountDTO | null
}

export interface IToggleProps {
	discount: boolean
	trial: boolean
	exp: boolean
}

export const toggleButtonsName: any = {
	discount: 'Add discount',
	trial: 'Add trial',
	exp: 'Add expiration'
}

export interface IAdminCreateOffer {
	user: {
		id: string
		name: string
	}
	account?:
		| {
				id: string
				name: string
				spend: number | null
				interval: string | null
				interval_count: number | null
				price: number | null
				planName: string | null
		  }
		| undefined
	children: (open: () => void) => void
	offerRequest?: OfferRequestDTO | OfferRequestInfoDTO | null
	updateOffer?: OfferDTO | null
}

export type Format = 'dollar' | 'percentage'

export type State = {
	offersList: OfferFormState[]
	selectedOfferIndex: number
	couponId: string
}

export interface Offer {
	tier: string
	spend: number
	interval: 'month' | 'year' | 'quarterly'
	interval_count: number
	price: number
}

export interface OfferFormState extends Offer {
	errors?: Record<string, string> | null
}

interface IOfferPayload {
	tier: string
	spend: number
	interval: string
	interval_count: number
	publish: boolean
	price: number
	days: number | null
	expired_at: number | null
	coupon: {
		months: number
		amount_off: number
		duration: string
		percent_off: number
	} | null
}

export interface ICreateOfferPayload {
	user_id: string
	account_id: string | null
	offer_request_id: string | null
	offers: IOfferPayload[]
}

export interface IUpdateOfferPayload {
	spend: number
	price: number
	interval: string
	interval_count: number
	publish: boolean
	days: number | null
	expired_at: number | null
	coupon: {
		months: number
		amount_off: number
		duration: string
		percent_off: number
	} | null
	account_id: string | null
}
