import { GButton, GInput, GLoading } from 'components/basic-blocks'
import { OnBoarding } from 'components/onboarding/onboarding'
import { useStores } from 'hooks'
import { RiMailSendLine } from 'react-icons/ri'
import { toast } from 'react-toastify'
import { Users } from 'services/user'

/* This example requires Tailwind CSS v2.0+ */
export const NotVerifiedPage = () => {
	const { userStore } = useStores()
	const { user } = userStore

	toast.clearWaitingQueue()

	if (!user) {
		return <GLoading />
	}

	const resend = async () => {
		user.email_verified && toast.error('Email already verified')
		try {
			await Users.resendVerificationEmail({ email: user.email })
			toast.success('Verification link sent')
		} catch (e) {
			toast.error("Couldn't send verification link")
		}
	}

	return (
		<>
			<OnBoarding
				title={
					<div className="text-3xl text-[#fff] font-[800]">
						<p className="text-4xl">
							<RiMailSendLine className="w-14 h-14 text-primary-400" />
						</p>
						Your email is not verified
					</div>
				}
				backToLogin
			>
				<div className="w-full min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 ">
					<div className="w-full mx-auto">
						<main className="w-full flex flex-col space-y-2 items-center">
							<div className="w-full flex flex-col justify-center items-center pb-4">
								<div className="w-full">
									<GInput
										inputClassName="sm:text-sm placeholder:text-sm placeholder:text-t-default text-t-default w-full"
										label="Your email"
										className="mb-4"
										value={user.email}
										disabled
										onChange={() => null}
									/>
									<p className="mt-1 text-base text-t-secondary w-full">
										Check your inbox for verification link.
									</p>
								</div>
								<div className="flex justify-center w-full">
									<GButton
										color="primary"
										iconPlacement="right"
										className="w-full mt-6"
										onClick={resend}
									>
										<span className="px-4">Resend verification</span>
									</GButton>
								</div>
							</div>
						</main>
					</div>
				</div>
			</OnBoarding>
		</>
	)
}
