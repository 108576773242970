import { AnalyticsItem } from '../../../../components/analytics-item'
import { AffiliateStatsData, IAffiliateStats } from './types'

const getValue = (
	data: IAffiliateStats | undefined,
	key: keyof IAffiliateStats
) => {
	if (!data) {
		return '0'
	}
	return data[key] || '0'
}

export const AffiliateStats = ({
	data
}: {
	data: IAffiliateStats | undefined
}) => {
	return (
		<div className="flex py-2 mb-4 items-stretch bg-white rounded-lg divide-x overflow-x-auto border border-card-border">
			{AffiliateStatsData.map((metric, index) => (
				<AnalyticsItem
					assetId={''}
					metrics={AffiliateStatsData}
					key={index}
					label={metric.label}
					showMetricSelector={false}
					value={getValue(data, metric.key as any)}
					infoTooltip={<div className="flex flex-col">{metric.tooltip}</div>}
				/>
			))}
		</div>
	)
}
