import { GLoading } from 'components/basic-blocks/g-loading'
import { useStores } from 'hooks'
import { useEffect } from 'react'
import { useUiStore } from 'store'
import { LoginMethodRows } from './login-method-card'

export const LoginMethod = () => {
	const { userStore } = useStores()
	const { getLoginMethods, loginMethods } = userStore
	const isLoading = useUiStore((s) => s.isLoading)

	useEffect(() => {
		const getData = async () => {
			await getLoginMethods()
		}
		getData()
	}, [])

	// TODO
	// remove if user email management is without bugs!!!
	// const method = loginMethods.filter(item => !item.is_social) // to test

	if (isLoading('USER_GET_LOGIN_METHODS')) {
		return <GLoading />
	}

	return (
		<div className="flex flex-col gap-y-5 justify-between overflow-x-auto pb-2">
			{loginMethods?.map((item) => (
				<LoginMethodRows
					key={item.id}
					item={item}
					showDelete={loginMethods.length > 1}
					loading={isLoading('USER_CHANGE_MAIN_EMAIL')}
				/>
			))}
		</div>
	)
}
