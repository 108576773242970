import {
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuSubContent
} from '@radix-ui/react-dropdown-menu'
import { GRadio } from 'components/basic-blocks/g-radio'

import { OptionSubContentProps } from '../../../context'

const options = [
	{ value: 'true', label: 'Yes' },
	{ value: 'false', label: 'No' }
]
const key = 'cancel_request'

export const CancelOption = {
	key,
	label: 'Cancel request',
	subContent: ({ handleSubItemChange, mapState }: OptionSubContentProps) => (
		<DropdownMenuPortal>
			<DropdownMenuSubContent
				className="bg-white rounded border border-gray-200 p-2"
				sideOffset={20}
				alignOffset={-9}
			>
				{options.map((o) => (
					<DropdownMenuItem key={o.value} asChild={true}>
						<GRadio
							name={o.label}
							value={o.value}
							label={o.label}
							checked={mapState.get(key) === o.value}
							onChange={(e) =>
								handleSubItemChange({ event: e, key, value: o.value })
							}
							className="p-1"
						/>
					</DropdownMenuItem>
				))}
			</DropdownMenuSubContent>
		</DropdownMenuPortal>
	)
}
