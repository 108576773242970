import { AdAccountDTO } from 'api-models'
import { GTooltip } from 'components/basic-blocks/g-tooltip'
import { PlatformIcon } from 'components/platform-icon'
import { forEach } from 'lodash'
import { HiMinus } from 'react-icons/hi'
import { color } from 'utils/colors'
import {
	AdPlatformTypesDTO,
	castAdPlatforms,
	platformNameByType
} from 'utils/platform'

type BuildTooltipContentProps = {
	platform: AdAccountDTO['platform']
	adsAccounts: AdPlatformTypesDTO[]
}

const buildTooltipContent = ({
	platform,
	adsAccounts
}: BuildTooltipContentProps) => {
	const adPlatformName = platformNameByType[platform]
	const detailsMessage = buildDetailsMessage({
		adsAccounts
	})

	return (
		<div className="flex flex-col text-md space-y-1">
			<span className="font-bold">{`${adPlatformName} account`}</span>
			{detailsMessage ? <span>{detailsMessage}</span> : null}
		</div>
	)
}
type BuildDetailsMessageProps = {
	adsAccounts: AdPlatformTypesDTO[]
}

const buildDetailsMessage = ({ adsAccounts }: BuildDetailsMessageProps) => {
	let message = ''
	forEach(adsAccounts, (adAccount: AdPlatformTypesDTO) => {
		if (adAccount.status === 'ACTIVE') {
			if (
				adAccount?.details?.status === 'INITIALIZING' ||
				adAccount?.details?.status === 'PENDING'
			)
				message = 'Ad Account initializing.'

			if (
				adAccount?.details?.status === 'OK' ||
				adAccount?.details?.status === 'SYNCHRONIZING'
			)
				message = 'All systems operational.'
			if (adAccount?.details?.status === 'NO_ACCESS')
				message = `Access to Ad Account ${adAccount.name} was lost. Visit Settings to reconnect.`
			if (!adAccount.ad_clicks_logged)
				message = `Your Ad Account ${adAccount.name} is not logging traffic. Visit Settings to review tracking configuration.`

			if (adAccount?.details.status === 'INITIALIZING_FAILED') {
				message = 'An error occurred. Visit Settings to review.'
			}
		}
		if (adAccount.status === 'INACTIVE') {
			message = 'Protection turned off. Visit Settings to turn on.'
		}
	})
	return message
}
type StatusLineProps = {
	adsAccounts: AdPlatformTypesDTO[]
}
const statusLineColor = ({ adsAccounts }: StatusLineProps) => {
	let lineColor = color('gray', 500)
	forEach(adsAccounts, (adAccount: any) => {
		if (adAccount.status === 'ACTIVE') {
			if (
				adAccount?.details?.status === 'INITIALIZING' ||
				adAccount?.details?.status === 'PENDING'
			)
				lineColor = color('primary', 500)
			if (!adAccount.ad_clicks_logged) lineColor = color('red', 500)

			if (
				adAccount.ad_clicks_logged &&
				(adAccount?.details?.status === 'OK' ||
					adAccount?.details?.status === 'SYNCHRONIZING')
			)
				lineColor = color('green', 500)
			if (
				adAccount.ad_clicks_logged &&
				adAccount?.details?.status === 'NO_ACCESS'
			)
				lineColor = color('orange', 500)

			if (adAccount?.details.status === 'INITIALIZING_FAILED') {
				lineColor = color('red', 500)
			}
		} else {
			lineColor = color('gray', 500)
		}
	})
	return lineColor
}

interface AdAccountsListProps {
	adAccounts: AdPlatformTypesDTO[]
}

export const AdAccountsList = ({ adAccounts }: AdAccountsListProps) => {
	const googleAccounts = castAdPlatforms(
		'GADS',
		adAccounts.filter((aa) => aa.platform === 'GADS')
	)
	const metaAccounts = castAdPlatforms(
		'FADS',
		adAccounts.filter((aa) => aa.platform === 'FADS')
	)
	const bingAccounts = castAdPlatforms(
		'BADS',
		adAccounts.filter((aa) => aa.platform === 'BADS')
	)

	return (
		<>
			{googleAccounts.length > 0 && (
				<div className="bg-gray-100 rounded-md px-2 py-0.5">
					<GTooltip
						content={buildTooltipContent({
							platform: 'GADS',
							adsAccounts: googleAccounts
						})}
					>
						<div
							className="flex flex-col justify-center items-center mt-[3px]"
							data-testid={`ad-account-container-${googleAccounts[0].name}`}
						>
							<PlatformIcon platform="GADS" className="h-6 w-6" />

							<HiMinus
								className="h-7 w-7 -mt-[9px] -mb-[10px]"
								fill={statusLineColor({
									adsAccounts: googleAccounts
								})}
								data-testid="status-line"
							/>
						</div>
					</GTooltip>
				</div>
			)}
			{metaAccounts.length > 0 && (
				<div className="bg-gray-100 rounded-md px-2 py-0.5">
					<GTooltip
						content={buildTooltipContent({
							platform: 'FADS',
							adsAccounts: metaAccounts
						})}
					>
						<div
							className="flex flex-col justify-center items-center mt-[3px]"
							data-testid={`ad-account-container-${metaAccounts[0].name}`}
						>
							<PlatformIcon platform="FADS" className="h-6 w-6" />
							<HiMinus
								className="h-7 w-7 -mt-[9px] -mb-[10px]"
								fill={statusLineColor({
									adsAccounts: metaAccounts
								})}
								data-testid="status-line"
							/>
						</div>
					</GTooltip>
				</div>
			)}
			{bingAccounts.length > 0 && (
				<div className="bg-gray-100 rounded-md px-2 py-0.5">
					<GTooltip
						content={buildTooltipContent({
							platform: 'BADS',
							adsAccounts: bingAccounts
						})}
					>
						<div
							className="flex flex-col justify-center items-center mt-[3px]"
							data-testid={`ad-account-container-${bingAccounts[0].name}`}
						>
							<PlatformIcon platform="BADS" className="h-6 w-6" />
							<HiMinus
								className="h-7 w-7 -mt-[9px] -mb-[10px]"
								fill={statusLineColor({
									adsAccounts: bingAccounts
								})}
								data-testid="status-line"
							/>
						</div>
					</GTooltip>
				</div>
			)}
		</>
	)
}
