import { Dialog, Transition } from '@headlessui/react'
import { GButton } from 'components/basic-blocks'
import { Fragment } from 'react'
import { RiCheckFill, RiCloseLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import WarningIcon from '../../assets/img/warning.svg'

import { ForceInstallTCProps } from './types'

export const ForceInstallTC = ({
	open,
	close,
	account,
	asset
}: ForceInstallTCProps) => {
	const navigate = useNavigate()
	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog as="div" className="relative z-20" onClose={() => null}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Dialog.Overlay className="fixed inset-0 bg-modal-background transition-opacity backdrop-blur-sm" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-2xl transform overflow-hidden transition-all">
								<div className="relative bg-white rounded-md sm:rounded-lg mb-6 border border-t-border-light p-8">
									<div
										className="absolute top-3 right-3 hover:cursor-pointer"
										onClick={close}
									>
										<RiCloseLine className="h-6 w-6" aria-hidden="true" />
									</div>
									<div className="flex justify-center items-center mb-4">
										<img src={WarningIcon} alt="Google" className="w-18 h-18" />
									</div>
									<div className="flex flex-col justify-center align-center mt-4 mb-4 lg:mb-6">
										<h1 className="text-xl font-bold text-t-default">
											Install the ClickGUARD Tracking Code
										</h1>
										<p className="text-lg text-t-secondary">
											The tracking code enables us to properly monitor the
											clicks, behaviour and threats on your ads.
										</p>
										<p className="text-lg text-t-secondary pt-2">
											Get the most out of ClickGUARD’s powerful protection by
											installing our tracking code on your website.
										</p>
									</div>
									<div className="py-4 flex justify-center">
										<GButton
											color="primary"
											iconPlacement="left"
											className="max-h-[30px] mr-6"
											icon={RiCheckFill}
											onClick={() => {
												navigate(
													`/settings/workspace/${account?.id}/asset/${asset?.id}/tracking-code`
												)
												close()
											}}
										>
											<span>Install now</span>
										</GButton>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
