import { OnBoarding } from '../../../components/onboarding/onboarding'
import { GButton, GLoading } from '../../../components/basic-blocks'
import { useUrlQuery } from '../../../hooks'
import { useUserStore } from '../../../store'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { AdminTeam } from '../../../services/admin/team'
import { AdminTeamInvites } from '../../../../../api-models/src/admin/team'
import { AdminRoleOptions } from './types'
import { parseError } from '../../../utils/errors'

export const JoinAdminTeam = () => {
	const navigate = useNavigate()
	const initUser = useUserStore((s) => s.initUser)
	const [loading, setLoading] = useState(false)
	const [state, setState] = useState<AdminTeamInvites | undefined>()
	const [accepted, setAccepted] = useState(false)
	const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
	const { code } = useUrlQuery()
	const user = useUserStore((s) => s.user)
	const goToLogin = async () => {
		await loginWithRedirect({
			appState: {
				returnTo: window.location.pathname + window.location.search
			}
		})
	}

	const load = async () => {
		if (code) {
			try {
				setLoading(true)
				const invite = await AdminTeam.getInviteByCode(code)
				setState(invite)
				setLoading(false)
			} catch (err) {
				toast.error('Your invitation is invalid!')
				navigate('/')
			}
		}
	}
	const acceptInvite = async () => {
		if (code) {
			try {
				setAccepted(true)
				setLoading(true)
				await AdminTeam.acceptTeamInvite(code)
				await initUser()
				setLoading(false)
				toast.success('You have successfully joined the admin team!')
				setTimeout(() => {
					navigate('/admin')
				}, 2000)
			} catch (err) {
				setLoading(false)
				toast.error(parseError(err) || 'Failed to accept invitation')
			}
		}
	}

	useEffect(() => {
		if (user && !accepted) {
			load()
		}
	}, [user, code, accepted])

	useEffect(() => {
		!isAuthenticated && goToLogin()
	}, [isAuthenticated])

	if (isLoading) {
		return <GLoading />
	}

	if (!user) {
		return <GLoading />
	}
	return (
		<OnBoarding
			title={
				<div className="text-3xl max-w-[20rem] break-words text-[#fff] font-[800]">
					You've been invited to join&nbsp;
					<span className="text-[#71DA97]">Admin Team</span>
				</div>
			}
			description={
				<p>
					invited by <strong>{state?.invited_by}</strong>
				</p>
			}
		>
			<div className="flex justify-center items-center flex-col h-[22.5rem]">
				<div className="flex items-center justify-center flex-col px-4 gap-4 w-72 border-b border-gray-100 pb-8">
					<p className="text-center text-t-default">
						By accepting, you will be added to the admin team with the role
						of&nbsp;
						{
							AdminRoleOptions.find((e) => e.value === state?.access_level)
								?.name
						}
						.
					</p>
					<p className="font-bold text-t-default break-words max-w-[16rem]">
						Accept invitation to join&nbsp;Admin Team
					</p>
					<GButton
						loading={loading}
						color="primary"
						iconPlacement="right"
						className="w-full"
						onClick={acceptInvite}
					>
						<span className="font-bold">Accept</span>
					</GButton>
				</div>
			</div>
		</OnBoarding>
	)
}
