import { NavItem } from 'layout/sidebar/nav-item'
import {
	RiBriefcaseLine,
	RiEarthLine,
	RiFileList2Line,
	RiFundsLine,
	RiGroupLine,
	RiHome3Line,
	RiLineChartLine,
	RiStackLine
} from 'react-icons/ri'
import { useUiStore, useUserStore } from 'store'
import { AdminAccessTypes, hasAdminAccess } from '../../../utils/admin'

export const AdminMenu = () => {
	const desktopSidebarCollapsed = useUiStore((s) => s.desktopSidebarCollapsed)
	const user = useUserStore((s) => s.user)

	return (
		<nav className="flex-1 pt-4 space-y-4 divide-y divide-card-border">
			<div className="pt-2 space-y-2">
				<NavItem label="Home" to={() => '/admin'} icon={RiHome3Line} />
				{hasAdminAccess(user, AdminAccessTypes.view_metrics) ? (
					<>
						<NavItem
							label="Revenue"
							to={() => '/admin/revenue'}
							icon={RiFundsLine}
						/>
						<NavItem
							label="Metrics"
							to={() => '/admin/metrics'}
							icon={RiLineChartLine}
						/>
					</>
				) : null}
			</div>
			<div className="pt-4 space-y-2">
				{!desktopSidebarCollapsed && (
					<p className="text-t-secondary pl-4 pb-1 font-medium">Entites</p>
				)}
				<NavItem label="Users" to={() => '/admin/users'} icon={RiGroupLine} />
				<NavItem
					label="Workspaces"
					to={() => '/admin/subscriptions'}
					icon={RiBriefcaseLine}
				/>
				<NavItem
					label="Websites"
					to={() => '/admin/websites'}
					icon={RiEarthLine}
				/>
				<NavItem
					label="PPC Accounts"
					to={() => '/admin/ppc-accounts'}
					icon={RiStackLine}
				/>
				<NavItem
					activeText={'/admin/legacy'}
					label="Legacy Users"
					to={() => '/admin/legacy?tab=users'}
					icon={RiFileList2Line}
				/>
			</div>
			<div className="pt-4 space-y-2">
				{!desktopSidebarCollapsed && (
					<p className="text-t-secondary pl-4 pb-1 font-medium">References</p>
				)}
				<NavItem
					label="Domains"
					to={() => '/admin/references/domains'}
					icon={RiEarthLine}
				/>
				<NavItem
					label="PPC Accounts"
					to={() => '/admin/references/ppc-accounts'}
					icon={RiStackLine}
				/>
			</div>
		</nav>
	)
}
