import _ from 'lodash'
import { twMerge } from 'tailwind-merge'
import { GTableBodyProps, GTableColumn } from '../types'

export const NewTableBody = <T,>(props: GTableBodyProps<T>) => {
	const { column, cell, isScrolling } = props

	if (!column) return null

	const renderCell = (item: T, column: GTableColumn<T>) => {
		if (column.render) return column.render(item)
		if (column.path) return _.get(item, column.path)
		return <span className="text-xs text-t-default">N/A</span>
	}

	return (
		<td
			style={
				column?.width
					? {
							width: column.width,
							maxWidth: column.width,
							minWidth: column.width,
							overflow: 'hidden'
					  }
					: {}
			}
			className={twMerge(
				'whitespace-nowrap px-4 py-4 text-sm text-t-default text-semibold',
				column.hidden ? 'hidden' : '',
				column.mobileHide ? 'hidden md:table-cell' : '',
				column.tabletHide ? 'hidden lg:table-cell' : '',
				column.freeze ? 'sticky left-0' : '',
				isScrolling ? 'bg-white' : ''
			)}
			{...cell.getCellProps()}
		>
			<div
				className={twMerge(
					'flex w-full h-full px-2',
					column.className || 'justify-end'
				)}
			>
				{renderCell(cell.value, column)}
			</div>
		</td>
	)
}
