import {
	AdAccountDTO,
	FacebookAdsCampaignDTO,
	GoogleAdsCampaignDTO
} from 'api-models'
import {
	ChangeEvent,
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useEffect,
	useState
} from 'react'
import { useSearchParams } from 'react-router-dom'

export type HandleSubItemChangeProps = {
	event: ChangeEvent<HTMLInputElement>
	key: string
	value: string | string[]
}

export type OptionSubContentProps = {
	handleSubItemChange: ({ event, key, value }: HandleSubItemChangeProps) => void
	mapState: Map<any, any>
}

export type FiltersType = {
	[key: string]: string | string[] | undefined
	ppc?: string
	status?: string[]
	converted?: string
	ip_address?: string
	device_id?: string
	campaign_type?: string[]
	country?: string[]
	bounced?: string
	vpn?: string
	threat_level?: string[]
	bot_probability?: string[]
	ip_usage?: string
	identifier?: string
	session_id?: string
	labels?: string[]
}

export type QuickViewType = {
	key: string
	label: string
}

interface UsersPageFiltersContextType {
	filters: FiltersType
	quickView: QuickViewType
	mapState: Map<any, any>
	selectedAdAccount: AdAccountDTO | null
	setSelectedAdAccount: Dispatch<SetStateAction<AdAccountDTO | null>>
	selectedCampaign: GoogleAdsCampaignDTO | FacebookAdsCampaignDTO | null
	setSelectedCampaign: Dispatch<
		SetStateAction<GoogleAdsCampaignDTO | FacebookAdsCampaignDTO | null>
	>
	handleApplyFilters: (newFilters: FiltersType) => void
	handleApplyDetailsItemFilter: (newFilters: FiltersType) => void
	handleSubItemChange: ({ event, key, value }: HandleSubItemChangeProps) => void
	handleRemoveFilter: (filterKeyToRemove: string) => void
	handleOpenAdminUSerDetailsChange: (open: boolean) => void
	handleApplyQuickView: (newQuickView: QuickViewType) => void
	handleRemoveQuickView: () => void
}
export const UsersPageFiltersContext = createContext(
	{} as UsersPageFiltersContextType
)

const FILTER_KEYS_TYPE_ARRAY = [
	'status',
	'campaign_type',
	'country',
	'threat_level',
	'bot_probability'
]

interface UsersPageFiltersContextProviderProps {
	children: ReactNode
}
export function UsersPageFiltersContextProvider({
	children
}: UsersPageFiltersContextProviderProps) {
	const [searchParams, setSearchParams] = useSearchParams()

	const [filters, setFilters] = useState({} as FiltersType)
	const [quickView, setQuickView] = useState({} as QuickViewType)
	const [mapState, setMapState] = useState(new Map())
	const [selectedAdAccount, setSelectedAdAccount] =
		useState<AdAccountDTO | null>(null)
	const [selectedCampaign, setSelectedCampaign] = useState<
		GoogleAdsCampaignDTO | FacebookAdsCampaignDTO | null
	>(null)

	useEffect(() => {
		const filters = {}
		const map = new Map()

		searchParams.forEach((value, key) => {
			const formattedValue = FILTER_KEYS_TYPE_ARRAY.includes(key)
				? value.split(',')
				: value

			Object.assign(filters, { [key]: formattedValue })
			map.set(key, formattedValue)
		})

		setFilters(filters)
		setMapState(map)
	}, [])

	const handleApplyFilters = (newFilters: FiltersType) => {
		setFilters(newFilters)
		Object.keys(newFilters).map((key) => {
			const value = newFilters[key]
			if (value)
				searchParams.set(key, Array.isArray(value) ? value.join(',') : value)
		})
		setSearchParams(searchParams)
	}

	const handleApplyQuickView = (newQuickView: QuickViewType) => {
		setQuickView(newQuickView)
	}
	const handleRemoveQuickView = () => {
		setQuickView({} as QuickViewType)
	}

	const handleApplyDetailsItemFilter = (newFilters: FiltersType) => {
		for (const key of searchParams.keys()) {
			searchParams.delete(key)
		}
		const map = new Map()
		Object.keys(newFilters).forEach((key) => {
			const value = newFilters[key] as string
			if (value) {
				map.set(key, value)
				searchParams.set(key, value)
			}
		})

		setSearchParams(searchParams)
		setMapState(map)
		setFilters(newFilters)
	}

	const handleSubItemChange = ({
		event,
		key,
		value
	}: HandleSubItemChangeProps) => {
		event.stopPropagation()
		if (value.length === 0) {
			handleRemoveFilter(key)
			return
		}
		setMapState((map) => new Map(map.set(key, value)))
	}

	const handleRemoveFilter = (filterKeyToRemove: string) => {
		const copy = mapState
		copy.delete(filterKeyToRemove)
		setMapState(new Map(copy))

		const filters = mapFilters(copy)
		setFilters(filters)
		searchParams.delete(filterKeyToRemove)
		setSearchParams(searchParams)
	}

	const handleOpenAdminUSerDetailsChange = (open: boolean) => {
		if (!open) {
			const filters = mapFilters(mapState)
			handleApplyFilters(filters)
		}
	}

	const mapFilters = (map: Map<any, any>) => {
		const filters = {}
		map.forEach((value, key) => Object.assign(filters, { [key]: value }))
		return filters as FiltersType
	}

	return (
		<UsersPageFiltersContext.Provider
			value={{
				filters,
				quickView,
				mapState,
				selectedAdAccount,
				setSelectedAdAccount,
				selectedCampaign,
				setSelectedCampaign,
				handleApplyFilters,
				handleRemoveFilter,
				handleApplyDetailsItemFilter,
				handleOpenAdminUSerDetailsChange,
				handleSubItemChange,
				handleApplyQuickView,
				handleRemoveQuickView
			}}
		>
			{children}
		</UsersPageFiltersContext.Provider>
	)
}
