import { GInput } from '../../../components/basic-blocks'
import { useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter } from '../../../utils/text'
import moment from 'moment-timezone'
import { getLegacyStatusColor, LegacyUserDTO } from './types'
import { classNames } from '../../../utils'

export const LegacyUserData = ({ user }: { user: LegacyUserDTO | null }) => {
	const handleChange = () => {
		return
	}
	const navigate = useNavigate()
	return (
		<dl className="flex gap-6 flex-col">
			<div className="flex items-center justify-between border-b pb-4">
				<p className="text-xl font-bold">Current Status</p>
				<p
					className={classNames(
						'px-4 rounded-md py-2 font-bold w-max',
						user?.status ? getLegacyStatusColor(user.status) : ''
					)}
				>
					{capitalizeFirstLetter(user?.status || '').replace('_', ' ')}
				</p>
			</div>
			<div className="sm:grid sm:grid-cols-3 sm:gap-4 items-center">
				<dt className="text-t-secondary text-base font-bold">Access Type</dt>
				<dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
					<GInput
						name="fullName"
						inputClassName="text-t-default w-full font-medium"
						value={capitalizeFirstLetter(user?.access_type || '')}
						onChange={handleChange}
					/>
				</dd>
			</div>
			<div className="sm:grid sm:grid-cols-3 sm:gap-4 items-center">
				<dt className="text-t-secondary text-base font-bold">Names</dt>
				<dd className="flex gap-3.5 mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
					<GInput
						name="fullName"
						inputClassName="text-t-default w-full font-medium"
						value={user?.first_name || ''}
						onChange={handleChange}
					/>
					<GInput
						name="fullName"
						inputClassName="text-t-default w-full font-medium"
						value={user?.last_name || ''}
						onChange={handleChange}
					/>
				</dd>
			</div>
			<div className="sm:grid sm:grid-cols-3 sm:gap-4 items-center">
				<dt className="text-t-secondary text-base font-bold">Phone Number</dt>
				<dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
					<GInput
						name="fullName"
						inputClassName="text-t-default w-full font-medium"
						value={user?.phone_number || ''}
						onChange={handleChange}
					/>
				</dd>
			</div>
			<div className="sm:grid sm:grid-cols-3 sm:gap-4 items-center">
				<dt className="text-t-secondary text-base font-bold">Timezone</dt>
				<dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
					<GInput
						name="fullName"
						inputClassName="text-t-default w-full font-medium"
						value={user?.timezone || ''}
						onChange={handleChange}
					/>
				</dd>
			</div>
			<div className="sm:grid sm:grid-cols-3 sm:gap-4 items-center">
				<dt className="text-t-secondary text-base font-bold">
					Subscription Price
				</dt>
				<dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
					<GInput
						name="fullName"
						inputClassName="text-t-default w-full font-medium"
						value={user?.billing_subscription_price || ''}
						onChange={handleChange}
					/>
				</dd>
			</div>
			<div className="sm:grid sm:grid-cols-3 sm:gap-4 items-center">
				<dt className="text-t-secondary text-base font-bold">
					Subscription Period (Months)
				</dt>
				<dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
					<GInput
						name="fullName"
						inputClassName="text-t-default w-full font-medium"
						value={user?.billing_subscription_periods || ''}
						onChange={handleChange}
					/>
				</dd>
			</div>
			<div className="sm:grid sm:grid-cols-3 sm:gap-4 items-center">
				<dt className="text-t-secondary text-base font-bold">
					Account Creation Date
				</dt>
				<dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
					<GInput
						name="fullName"
						inputClassName="text-t-default w-full font-medium"
						value={
							user?.created_at ? moment(user.created_at).format('llll') : ''
						}
						onChange={handleChange}
					/>
				</dd>
			</div>
			<button
				onClick={() => navigate('/admin/legacy?tab=invoices')}
				className="w-[150px] text-center rounded-md h-[36px] border-2 font-bold border-primary-400 bg-transparent focus:ring-primary-500"
			>
				View Legacy Invoices
			</button>
		</dl>
	)
}
