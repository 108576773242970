import { SearchIcon } from '@heroicons/react/solid'
import { EventLogDTO } from 'api-models'
import { GDialog, GInput, GSection } from 'components/basic-blocks'
import { GDateTime } from 'components/basic-blocks/g-datetime'
import { InfiniteLoaderList } from 'components/infinite-loader-list/infinite-loader-list'
import { useStores } from 'hooks'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
	RiArticleLine,
	RiBriefcaseLine,
	RiEarthLine,
	RiUserLine
} from 'react-icons/ri'
import { useUiStore } from 'store'
import { MdCampaign } from 'react-icons/md'

interface EventsListProps {
	id: string
	type: 'workspace' | 'website' | 'ppc-account' | 'user'
}

export const EventsList = ({ id, type }: EventsListProps) => {
	const { adminStore } = useStores()
	const { getEvents } = adminStore
	const isLoading = useUiStore((s) => s.isLoading)

	const PAGE_SIZE = 20

	const [events, setEvents] = useState<EventLogDTO[]>([])
	const [total, setTotal] = useState(0)
	const [searchTerm, setSearchTerm] = useState('')

	const fetchData = async (skip: number, search_term?: string) => {
		const { events, total } = await getEvents({
			id,
			type,
			search_term: search_term ?? searchTerm,
			skip: skip,
			limit: PAGE_SIZE
		})

		if (skip === 0) {
			setEvents(events)
		} else {
			setEvents((prev) => [...prev, ...events])
		}

		setTotal(total)
	}

	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

	const handleSearchTermChange = (value: string) => {
		setSearchTerm(value)

		if (timeoutId) {
			clearTimeout(timeoutId)
		}

		setTimeoutId(
			setTimeout(() => {
				fetchData(0, value)
			}, 500)
		)
	}

	useEffect(() => {
		;(async () => {
			await fetchData(0)
		})()
	}, [])

	return (
		<GSection loading={isLoading('ADMIN_GET_EVENTS')}>
			<div className="flex justify-between items-center -mt-5 -mx-6 px-5 py-2 border-b rounded-t-lg border-card-border bg-gray-50 text-t-dark text-sm font-medium">
				<div>Events</div>
				<div>
					<GInput
						icon={SearchIcon}
						placeholder="Find event..."
						value={searchTerm}
						onChange={(e) => handleSearchTermChange(e.target.value)}
					/>
				</div>
			</div>
			<div className="-mx-6">
				<EventList
					events={events}
					total={total}
					pageSize={PAGE_SIZE}
					fetchData={fetchData}
					empty={events?.length <= 0}
				/>
			</div>
		</GSection>
	)
}

const EventList = (props: {
	events: EventLogDTO[]
	total: number
	pageSize: number
	fetchData: (skip: number) => Promise<void>
	empty?: boolean
}) => {
	const { events, total, pageSize, fetchData, empty } = props
	if (empty || events === undefined) {
		return (
			<div className="py-4 flex w-full items-center justify-center -mb-4 text-t-secondary text-xs">
				No events
			</div>
		)
	}

	if (events?.length <= 0) {
		return (
			<div className="py-4 flex w-full items-center justify-center -mb-4 text-t-secondary text-xs">
				No event matches your search
			</div>
		)
	}

	return (
		<div className="max-h-96">
			{events && events.length > 0 && (
				<InfiniteLoaderList
					list={events}
					pageSize={pageSize}
					total={total}
					fetchData={fetchData}
				>
					{(index) => (
						<EventItem key={events[index]?.id} event={events[index]} />
					)}
				</InfiniteLoaderList>
			)}
		</div>
	)
}

const EventItem = (props: { event: EventLogDTO }) => {
	const { event } = props
	const [dialogOpen, setDialogOpen] = useState(false)

	const handleDialogClose = () => {
		setDialogOpen(false)
	}

	if (!event) return null

	const severityToColorMapper = {
		LOW: 'gray',
		NORMAL: 'primary',
		HIGH: 'green',
		CRITICAL: 'red',
		WARNING: 'amber'
	}
	const contextToIconMapper = {
		USER: RiUserLine,
		ACCOUNT: RiBriefcaseLine,
		CAMPAIGN: MdCampaign,
		WEBSITE: RiEarthLine,
		PPC_ACCOUNT: RiArticleLine,
		SYSTEM: RiArticleLine
	}

	const Icon = contextToIconMapper[event.context_type]
	return (
		<>
			<div className="flex justify-between px-5 py-4 border-b gap-8">
				<div className="flex gap-4 items-start">
					<div>
						<Icon
							className={`mt-1 h-7 w-7 text-${
								severityToColorMapper[event.severity]
							}-500`}
						/>
					</div>
					<div className="flex flex-col justify-start">
						<div className="text-t-title text-sm">{event.message}</div>
						<div
							className={`text-t-dark text-xs ${
								event.name === 'PPC_ACCOUNT_TRACKING_TEMPLATE_CHANGED' &&
								'truncate max-w-[15rem]'
							} `}
						>
							{event.name === 'PPC_ACCOUNT_TRACKING_TEMPLATE_CHANGED' ? (
								<div
									dangerouslySetInnerHTML={{ __html: event.description }}
								></div>
							) : (
								event.description
							)}
							{event.name === 'PPC_ACCOUNT_TRACKING_TEMPLATE_CHANGED' && (
								<div
									className="text-sm text-primary-500 cursor-pointer"
									onClick={() => setDialogOpen(true)}
								>
									more info...
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="flex flex-col justify-start items-end text-xs min-w-fit gap-1">
					<div className="text-t-dark">
						{moment(event.timestamp).format('MMMM Do, YYYY - hh:mm:ss A')}
					</div>
					<div className="flex justify-end text-t-secondary">
						<GDateTime date={event.timestamp} time />
					</div>
				</div>
			</div>
			<GDialog
				open={dialogOpen}
				maxWidth="2xl"
				onClose={() => handleDialogClose()}
				title={event.message}
				subtitle={''}
			>
				<div
					className="whitespace-pre-line"
					dangerouslySetInnerHTML={{ __html: event.description }}
				></div>
			</GDialog>
		</>
	)
}
