import { PageHeader } from '../../../components/page-header'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
	GButton,
	GTooltip,
	GTransition
} from '../../../components/basic-blocks'
import moment from 'moment/moment'
import { Tabs } from '../../../components/tabs'
import { useUserStore } from '../../../store'
import { EXTERNAL_APP_BASE_URL } from '../../../utils/app-links'
import { affiliatePageHeaderData } from './affiliate-index'
import { AffiliateDashboard } from './dashboard/dashboard'
import { AffiliateResources } from './resources/resources'
import { AffiliateSetting } from './setting/setting'
import { AffiliateAbout } from './about/about'

const tabs = [
	{
		label: 'Dashboard',
		key: 'dashboard',
		to: () => '/workspace/affiliate?tab=dashboard'
	},
	{
		label: 'Settings',
		key: 'settings',
		to: () => '/workspace/affiliate?tab=settings'
	},
	{
		label: 'About',
		key: 'about',
		to: () => '/workspace/affiliate?tab=about'
	},
	{
		label: 'Resources',
		key: 'resources',
		to: () => '/workspace/affiliate?tab=resources'
	}
]

const setupTab = [
	{
		label: 'About',
		key: 'about',
		to: () => '/workspace/affiliate?tab=about'
	}
]
export const Affiliate = () => {
	const [copyStatus, setCopyStatus] = useState(false)
	const user = useUserStore((state) => state.user)
	const [affiliateTabs, setAffiliateTabs] = useState(
		user?.affiliate_profile ? tabs : setupTab
	)
	const [search] = useSearchParams()
	const [selectedTab, setSelectedTab] = useState(
		search.get('tab') || affiliateTabs[0].key
	)
	const [dateRange, setDateRange] = useState({
		from: moment().subtract(30, 'days').startOf('day').toDate(),
		to: moment().startOf('hour').toDate()
	})
	const getPageHeaderData = useMemo(() => {
		return (
			affiliatePageHeaderData.find((item) => item.key === selectedTab) ||
			affiliatePageHeaderData[5]
		)
	}, [selectedTab])

	async function copy(text: string) {
		await navigator.clipboard.writeText(text)
		setCopyStatus(true)
		setTimeout(() => {
			setCopyStatus(false)
		}, 2000)
	}

	useEffect(() => {
		if (search.get('tab') === 'settings' && !user?.affiliate_profile) {
			setAffiliateTabs([
				{
					label: 'Set up your Affiliate Account',
					key: 'settings',
					to: () => '/workspace/affiliate?tab=settings'
				}
			])
		}
		const tab = search.get('tab') || 'settings'
		setSelectedTab(tab)
	}, [search])
	const handleDateRangeChange = (e: { from: Date; to: Date }) => {
		setDateRange({ from: e.from, to: e.to })
	}

	useEffect(() => {
		if (user?.affiliate_profile) {
			setAffiliateTabs(tabs)
		}
	}, [user?.affiliate_profile])

	return (
		<div>
			<div className="flex items-center justify-between w-full pr-6 sm:pr-8">
				{user?.affiliate_profile?.affiliate_id && (
					<PageHeader
						title={getPageHeaderData?.title || ''}
						subtitle={getPageHeaderData?.subtitle || ''}
					/>
				)}
				{!user?.affiliate_profile?.affiliate_id && (
					<PageHeader
						title="Affiliate"
						subtitle="Refer new customers to ClickGUARD to earn commission and other benefits"
					/>
				)}

				{user?.affiliate_profile?.affiliate_id && (
					<div className="flex items-center gap-x-2">
						<p className="text-primary-500 font-bold text-lg">
							{(
								user?.affiliate_profile?.link ||
								`${EXTERNAL_APP_BASE_URL}?/via=first_name_last_name`
							).substring(8)}
						</p>
						<GTooltip content={copyStatus ? 'Copied!' : 'Copy'} position="top">
							<GButton
								label="Copy"
								labelClassName="font-bold text-sm"
								type="text"
								size="xs"
								onClick={() =>
									copy(
										user?.affiliate_profile?.link ||
											`${EXTERNAL_APP_BASE_URL}?/via=first_name_last_name`
									)
								}
							/>
						</GTooltip>
					</div>
				)}
			</div>
			<div className="max-w-full mx-auto px-6 sm:px-8">
				<div className="flex justify-between items-center gap-x-6">
					<div className="w-full">
						<Tabs current={selectedTab} tabs={affiliateTabs} />
					</div>
				</div>

				<div className="mt-6">
					{user?.affiliate_profile && (
						<>
							<GTransition swap show={selectedTab === 'dashboard'}>
								<AffiliateDashboard dateRange={dateRange} />
							</GTransition>
							<GTransition swap show={selectedTab === 'resources'}>
								<AffiliateResources />
							</GTransition>
						</>
					)}
					<GTransition swap show={selectedTab === 'settings'}>
						<AffiliateSetting />
					</GTransition>
					<GTransition swap show={selectedTab === 'about'}>
						<AffiliateAbout setAffiliateTabs={setAffiliateTabs} />
					</GTransition>
				</div>
			</div>
		</div>
	)
}
