import { useAuth0 } from '@auth0/auth0-react'
import { SetupSteps } from 'pages/setup/steps'
import Logo from '../../assets/img/cg-logo-white.svg'
import { EXTERNAL_APP_BASE_URL } from '../../utils/app-links'
import { ReactNode } from 'react'
import { Impersonation } from '../../layout/navbar/impersonation/impersonation'
import { useStores } from '../../hooks'

interface OnBoardingProps {
	children?: ReactNode
	handleBack?: () => void
	title: ReactNode
	description?: ReactNode
	state?: any
	setStep?: (step: string) => void
	handleBackText?: string
	backToLogin?: boolean
	disableTopAction?: boolean
	isAffiliate?: boolean
	contentClassName?: string
	showLogo?: boolean
}

export const OnBoarding = ({
	children,
	handleBack,
	title,
	description,
	state,
	setStep,
	handleBackText,
	backToLogin,
	disableTopAction,
	isAffiliate = false,
	showLogo = true,
	contentClassName
}: OnBoardingProps) => {
	const { adminStore } = useStores()
	const { logout } = useAuth0()

	return (
		<div className="relative flex items-center h-[100vh] max-w-[960px] min-h-[600px] mx-auto px-4 sm:px-6 lg:px-8 text-t-dark">
			<div className="absolute top-0 right-0">
				<Impersonation />
			</div>
			<div className="py-6">
				<div className="flex bg-card-background rounded-lg shadow-lg overflow-hidden mx-auto min-w-[960px] min-h-[600px]">
					<div className="hidden lg:block lg:w-1/2 bg-onboarding-panel relative">
						{showLogo && (
							<div className="absolute pt-8 ml-8">
								<img className="h-[28px]" src={Logo} alt="" />
							</div>
						)}
						<div className="flex flex-col justify-center items-center h-full mx-24 ">
							{title}
							<div className="text-sm text-[#FFF] leading-5 mt-6 max-w-[288px] w-full text-left">
								{description}
							</div>
						</div>
					</div>

					<div
						className={`w-full lg:w-1/2 flex flex-col space-y-4 relative ${
							contentClassName || 'py-28 px-20'
						}`}
					>
						{!disableTopAction && (
							<div
								className="mt-4 mr-8 absolute right-0 top-0 text-sm text-primary-500 cursor-pointer"
								onClick={() => {
									adminStore.clearImpersonation()
									logout()
								}}
							>
								{backToLogin ? (
									<>
										<span className="text-t-default">Back to</span> Login
									</>
								) : (
									'Logout'
								)}
							</div>
						)}
						{isAffiliate && (
							<div className="mt-4 mr-8 absolute right-0 top-0 cursor-pointer">
								<>
									<span className="text-t-default">Learn more on</span>{' '}
									<a
										target="_blank"
										className="text-sm text-primary-500 "
										href={EXTERNAL_APP_BASE_URL}
									>
										clickguard.com
									</a>
								</>
							</div>
						)}
						<div className="m-auto w-full relative">{children}</div>
						{state && setStep && (
							<div className="absolute bottom-3 right-[45%]">
								<SetupSteps
									step={state.step}
									setStep={setStep}
									account={state.account}
								/>
							</div>
						)}
					</div>
				</div>
				{handleBack && (
					<div
						className="w-full text-center text-sm text-primary-500 cursor-pointer mt-8"
						onClick={handleBack}
					>
						{handleBackText ?? 'View demo account dashboard'}
					</div>
				)}
			</div>
		</div>
	)
}
