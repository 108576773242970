import { ReactElement, useEffect, useReducer } from 'react'
import JoyRide, { ACTIONS, EVENTS, STATUS } from 'react-joyride'

const TOUR_STEPS = [
	{
		target: '.settings-bar',
		content:
			"This is where you'll add your tracking code, invite team members and reset your account and billing details",
		disableBeacon: true, // This makes the tour to start automatically without clicking,
		hideBackButton: true,
		spotlightClicks: true
	},
	{
		target: '.clicks-tab',
		content:
			"You can view the list of clicks on your ads, and their threat classification. You can also 'Add to Blacklist'",
		spotlightClicks: true
	},
	{
		target: '.threats-tab',
		content:
			"This is where you'll find detailed analysis of the threats and risks to your ads",
		spotlightClicks: true
	},
	{
		target: '.reports-tab',
		content:
			'You can set up the reports you want to download or auto-sent to your email',
		spotlightClicks: true
	},
	{
		target: '.tour-link',
		content: 'This is where you can start the tour again in future.'
	}
]

const INITIAL_STATE = {
	key: new Date(), // This field makes the tour to re-render when we restart the tour
	run: false,
	continuous: true,
	loading: false,
	stepIndex: 0,
	steps: TOUR_STEPS
}

// Reducer will manage updating the local state
const reducer = (
	state = INITIAL_STATE,
	action: { type: any; payload: any }
) => {
	switch (action.type) {
		case 'START':
			return { ...state, run: true }
		case 'RESET':
			return { ...state, stepIndex: 0 }
		case 'STOP':
			return { ...state, run: false }
		case 'NEXT_OR_PREV':
			return { ...state, ...action.payload }
		case 'RESTART':
			return {
				...state,
				stepIndex: 0,
				run: true,
				loading: false,
				key: new Date()
			}
		default:
			return state
	}
}

// Tour component
const Tour = (): ReactElement => {
	// Tour state is the state which control the JoyRide component
	const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE)

	useEffect(() => {
		// Auto start the tour if the tour is not viewed before
		if (
			!localStorage.getItem('tourViewed') &&
			window.location.pathname.includes('/workspace')
		) {
			dispatch({
				type: 'START',
				payload: undefined
			})
		}
	}, [])

	// Set once tour is viewed, skipped or closed
	const setTourViewed = () => {
		window.localStorage.setItem('tourViewed', 'true')
	}

	const callback = (data: {
		action: any
		index: any
		type: any
		status: any
	}) => {
		const { action, index, type, status } = data

		if (window.localStorage.getItem('tourViewed') === 'true') {
			dispatch({
				type: 'STOP',
				payload: undefined
			})
		}
		if (
			// If close button clicked, then close the tour
			action === ACTIONS.CLOSE ||
			// If skipped or end tour, then close the tour
			(status === STATUS.SKIPPED && tourState.run) ||
			status === STATUS.FINISHED
		) {
			setTourViewed()
			dispatch({
				type: 'STOP',
				payload: undefined
			})
		} else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
			// Check whether next or back button click and update the step.
			dispatch({
				type: 'NEXT_OR_PREV',
				payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }
			})
		}
	}

	return (
			<JoyRide
				{...tourState}
				callback={callback}
				showSkipButton={true}
				styles={{
					tooltipContainer: {
						textAlign: 'left'
					},
					buttonBack: {
						marginRight: 10
					}
				}}
			/>
	)
}

export default Tour
