import { OfferRequestDTO } from 'api-models'
import {
	GAdminDashboardTable,
	GLoading,
	GSection
} from 'components/basic-blocks'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { RiErrorWarningLine } from 'react-icons/ri'
import { useUiStore } from 'store'
import { columns } from './columns'

export const OfferRequestsTab = () => {
	const { adminStore } = useStores()
	const { getOffers, offerRequest, handleOfferRequest } = adminStore
	const isLoading = useUiStore((s) => s.isLoading)

	const [skip, setSkip] = useState(0)
	const [query, setQuery] = useState({ sort: '' })

	const loading = isLoading('ADMIN_DELETE_OFFER_REQUEST')

	useEffect(() => {
		;(async () => {
			const offers: any = await getOffers({ ...query, skip })
			offers && handleOfferRequest(offers)
		})()
	}, [loading, query.sort, skip])

	const handleSort = (key: string) => {
		setQuery({ sort: key })
	}

	if (!offerRequest?.data) return <GLoading />
	return (
		<>
			<GSection className="" loading={isLoading('ADMIN_GET_OFFER_REQUESTS')}>
				<GAdminDashboardTable<OfferRequestDTO>
					refreshAction={async () => {
						const offers: any = await getOffers(query)
						offers && handleOfferRequest(offers)
					}}
					title="Offer requests"
					columns={columns}
					data={offerRequest?.data as any} //TODO Need to fix Types
					pageSize={offerRequest.total}
					sortListener={handleSort}
					sort={query.sort}
					skip={skip}
					setSkip={setSkip}
					totalCount={offerRequest.total}
					emptyState={
						<div className="flex flex-col items-center justify-center w-full p-8 text-center">
							<RiErrorWarningLine className="w-8 h-8 text-gray-500" />
							<h3 className="mt-2 text-sm font-medium text-gray-700">
								No data
							</h3>
						</div>
					}
				/>
			</GSection>
		</>
	)
}
