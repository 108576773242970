import { useAuth0 } from '@auth0/auth0-react'
import { VisualPreferencesItem } from 'api-models'
import React, { useEffect, useState } from 'react'
import {
	Route,
	Routes,
	useLocation,
	useNavigate,
	useSearchParams
} from 'react-router-dom'
import { ApiClient } from 'services/api-client'
import { useShallow } from 'zustand/react/shallow'
import { Auth0Config } from './config'
import { GModal } from 'components/basic-blocks'
import { useThemeStore } from 'layout/navbar/store'
import { CgSpinner } from 'react-icons/cg'
import { RiComputerLine } from 'react-icons/ri'
import { toast } from 'react-toastify'
import {
	useAccountStore,
	useAdminStore,
	useAssetStore,
	useBillingStore,
	useUiStore,
	useUserStore
} from 'store'
import { ThemeFix } from 'theme-fix'
import Tour from 'tour'
import { ZendeskService } from './services/zendesk'
import { publicRoutes } from './utils/public-routes'
import { isNoClickGuardAffiliate } from './utils/user'
import { MainPage } from './pages'
import { FacebookOAuthPage } from 'pages/oauth/facebook'
import { Setup } from 'pages/setup/setup'
import { BingOAuthPage } from './pages/oauth/bing'
import { GoogleOAuthPage } from './pages/oauth/google'
import { ErrorPage } from 'pages/error'
import { AffiliateSetupWizard } from 'pages/user/affiliate/account-setup/affiliate-setup-wizard'
import { PasswordReset } from 'pages/public-pages/password-reset/password-reset'
import { JoinWorkspacePage } from 'pages/user/join-workspace/join-workspace'
import { StartAgency } from 'pages/start/agency/start-agency'
import { StartStandard } from './pages/start/standard/start-standard'
import { VerifyEmailPage } from './pages/public-pages'
import { ConfirmUserPage } from './pages/user/confirm/confirm'
import { NotVerifiedPage } from './pages/public-pages/not-verified-page'
import { ChangePassword } from './pages/public-pages/password-reset/change-password'
import { ClickFraudCalculator } from './pages/click-fraud/calculator/click-fraud-calculator'
import { JoinAdminTeam } from './pages/admin/team/accept-team-invite'
import { CFCGoogleOAuthPage } from './pages/click-fraud/auth/google'
import { ClickFraudReport } from './pages/click-fraud/report/click-fraud-report'
import { ClickFraudStartTrial } from './pages/click-fraud/start-trial/click-fraud-start-trial'

const SignUpPage = () => {
	const { loginWithRedirect } = useAuth0()
	useEffect(() => {
		loginWithRedirect({
			screen_hint: 'signup'
		})
	}, [])
	return <></>
}

const LoginPage = () => {
	const { loginWithRedirect } = useAuth0()
	useEffect(() => {
		loginWithRedirect({
			screen_hint: 'login'
		})
	}, [])
	return <></>
}

export const App = () => {
	const {
		isLoading: auth0Loading,
		error: auth0Error,
		isAuthenticated,
		loginWithRedirect,
		getAccessTokenSilently,
		logout
	} = useAuth0()

	const navigate = useNavigate()
	const currentLocation = useLocation()
	const [search] = useSearchParams()
	const { switchTheme } = useThemeStore()

	const { modalContent, setActivePage, context } = useUiStore(
		useShallow((state) => ({
			modalContent: state.modalContent,
			setActivePage: state.setActivePage,
			context: state.context
		}))
	)
	const { setImpersonation, imperId } = useAdminStore(
		useShallow((state) => ({
			setImpersonation: state.setImpersonation,
			imperId: state.imperId
		}))
	)
	const { initUser, visualPreferences, updateVisualPreferences } = useUserStore(
		(state) => ({
			initUser: state.initUser,
			visualPreferences: state.visualPreferences,
			updateVisualPreferences: state.updateVisualPreferences
		})
	)
	const { getAllAccounts, wantsToSeeDemoData, setAccount } = useAccountStore(
		useShallow((state) => ({
			getAllAccounts: state.getAllAccounts,
			setAccount: state.setAccount,
			wantsToSeeDemoData: state.wantsToSeeDemoData
		}))
	)
	const { getAllAssets, setAsset } = useAssetStore(
		useShallow((state) => ({
			getAllAssets: state.getAllAssets,
			setAsset: state.setAsset
		}))
	)
	const { loadOfferRequests, loadOffers } = useBillingStore(
		useShallow((state) => ({
			loadOfferRequests: state.loadOfferRequests,
			loadOffers: state.loadOffers
		}))
	)

	const [loading, setLoading] = useState(true)
	const isPublicRoute = publicRoutes.find((route) =>
		currentLocation.pathname.startsWith(route)
	)
	async function init() {
		try {
			setLoading(true)

			if (isPublicRoute) {
				setLoading(false)
				return
			}

			const token = await getAccessTokenSilently({
				audience: Auth0Config.audience,
				scope: Auth0Config.scope
			})
			ApiClient.setAuthToken(token)
			ApiClient.setLogoutListener(logout)
			ApiClient.setUnverifiedEmailError(() => navigate('/not-verified'))
			if (!isAuthenticated) {
				return
			}

			if (imperId) {
				setImpersonation(imperId)
				setAccount(null)
				setAsset(null)
			}

			const initializedUser = await initUser(search.get('ref'))

			if (!initializedUser.email_verified) {
				navigate('/not-verified')
				setLoading(false)
				return
			}
			if (isNoClickGuardAffiliate(initializedUser)) {
				navigate(
					!initializedUser?.affiliate_profile?.affiliate_id
						? '/affiliate/dashboard'
						: currentLocation.pathname.includes('/affiliate/')
							? currentLocation.pathname
							: '/affiliate/dashboard'
				)
				setLoading(false)
				return
			}
			if (!initializedUser.profile_confirmed) {
				navigate('/confirmation')
				setLoading(false)
				return
			}
			if (initializedUser.admin && currentLocation.pathname === '/') {
				navigate('/admin')
			}

			if (wantsToSeeDemoData) {
				setLoading(false)
				return
			}

			await Promise.all([
				getAllAccounts(),
				getAllAssets(),
				loadOfferRequests(),
				loadOffers()
			])

			setLoading(false)
		} catch (e: any) {
			if (e?.message === 'External interaction required') {
				toast.success('Success please Login again')
				setTimeout(() => {
					logout()
				}, 1000)
			} else if (e.message !== 'Login required')
				toast.error('Error while initializing data')
			setLoading(false)
		}
	}

	useEffect(() => {
		init()
		return () => {
			setLoading(false)
		}
	}, [isAuthenticated, imperId])

	useEffect(() => {
		if (!isAuthenticated || isPublicRoute) return

		if (
			!visualPreferences ||
			JSON.parse(visualPreferences).find((v: VisualPreferencesItem) =>
				Object.prototype.hasOwnProperty.call(v, 'theme')
			) === undefined
		) {
			if (
				localStorage.theme === 'dark' ||
				(!('theme' in localStorage) &&
					window.matchMedia('(prefers-color-scheme: dark)').matches)
			) {
				switchTheme('dark')

				updateVisualPreferences(JSON.stringify([{ theme: 'dark' }]))
			} else {
				switchTheme('light')
				updateVisualPreferences(JSON.stringify([{ theme: 'light' }]))
			}
		} else {
			const visualPreferencesObj = JSON.parse(visualPreferences)
			const { theme } =
				visualPreferencesObj.find((v: VisualPreferencesItem) =>
					Object.prototype.hasOwnProperty.call(v, 'theme')
				) || {}
			switchTheme(theme)
		}
	}, [isAuthenticated, isPublicRoute])

	useEffect(() => {
		setActivePage(currentLocation.pathname)

		if (isPublicRoute) return

		currentLocation.pathname.includes('admin')
			? ZendeskService.hide()
			: ZendeskService.show()
	}, [currentLocation.pathname])

	if (auth0Loading || loading) {
		return (
			<div className="h-96 flex flex-col justify-end items-center text-center space-y-4">
				<CgSpinner className="w-16 h-16 animate-spin text-primary-500" />
				<span className="w-full text-center text-t-secondary">Loading...</span>
			</div>
		)
	}

	if (auth0Error) {
		return <ErrorPage error={auth0Error.message} />
	}

	if (!isAuthenticated && !isPublicRoute) {
		loginWithRedirect({
			appState: {
				returnTo: window.location.pathname + window.location.search
			}
		})
		return null
	}

	return (
		<div className="min-h-full">
			{/* {context !== 'admin' && (
				<div className="md:hidden flex flex-col w-full h-full items-center justify-center py-12">
					<div className="text-primary-500 p-8">
						<RiComputerLine className="w-12 h-12" />
					</div>
					<div className="text-t-title text-xl font-semibold">
						ClickGUARD is optimized for desktop environment
					</div>
					<div className="text-t-secondary text-md">
						Open the app from a desktop browser to continue
					</div>
				</div>
			)} */}
			<div>
				<Tour />
				<GModal {...modalContent} />
				<Routes>
					<Route path="/emails/verify" element={<VerifyEmailPage />} />
					<Route path="/start/affiliate" element={<AffiliateSetupWizard />} />
					<Route path="/change-password" element={<ChangePassword />} />
					<Route path="/reset-password" element={<PasswordReset />} />
					<Route path="/confirmation" element={<ConfirmUserPage />} />
					<Route path="/join-workspace" element={<JoinWorkspacePage />} />
					<Route path="admin/join-team" element={<JoinAdminTeam />} />

					<Route path="/start/standard" element={<StartStandard />} />
					<Route
						path="/start/standard/:accountId"
						element={<StartStandard />}
					/>
					<Route path="/start/agency" element={<StartAgency />} />
					<Route path="/setup/:accountId" element={<Setup />} />
					<Route path="/sign-up" element={<SignUpPage />} />
					<Route path="/login" element={<LoginPage />} />

					<Route path="/oauth/google" element={<GoogleOAuthPage />} />
					<Route path="/oauth/facebook" element={<FacebookOAuthPage />} />
					<Route path="/oauth/bing" element={<BingOAuthPage />} />

					<Route path="/not-verified" element={<NotVerifiedPage />} />

					<Route
						path="/click-fraud-calculator"
						element={<ClickFraudCalculator />}
					/>
					<Route
						path="/click-fraud-calculator/auth"
						element={<CFCGoogleOAuthPage />}
					/>
					<Route
						path="/click-fraud-report/:id"
						element={<ClickFraudReport />}
					/>
					<Route
						path="/click-fraud-report/:id/start-trial"
						element={<ClickFraudStartTrial />}
					/>

					<Route path="*" element={<MainPage />} />
				</Routes>
			</div>
			<ThemeFix />
		</div>
	)
}
