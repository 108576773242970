import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import { Fragment, useEffect, useState } from 'react'
import { GMultiSelectPropsType } from './types'

import { RiCodeLine } from 'react-icons/ri'
import { twMerge } from 'tailwind-merge'
import { classNames } from 'utils'

export function GMultiSelect<T>(props: GMultiSelectPropsType<T>) {
	const {
		value,
		options,
		onChange,
		renderSelected,
		renderLabel,
		label,
		subtitle,
		className,
		containerClassName,
		buttonClassName,
		error,
		placeholder,
		disabled,
		labelClassName,
		name
	} = props

	const [openState, setOpenState] = useState<boolean>(false)
	const [selectedItems, setSelectedItems] = useState<Array<T>>(
		value === null ? [] : value
	)

	useEffect(() => {
		if (value === null || value === undefined) {
			setSelectedItems([])
		} else {
			setSelectedItems(value)
		}
	}, [value])

	return (
		<div
			className={classNames(
				openState ? 'z-10 relative' : 'z-0',
				containerClassName || ''
			)}
		>
			<Listbox
				value={selectedItems}
				onChange={(e) => {
					setSelectedItems(e)
					onChange(e)
				}}
				disabled={disabled}
				multiple
			>
				{({ open }) => {
					useEffect(() => {
						setOpenState(open)
					}, [open])

					return (
						<>
							<div className={twMerge('mt-1', className)}>
								<div className="relative">
									<Listbox.Label
										className={`block ${
											labelClassName || 'text-md font-medium text-t-default'
										}`}
									>
										{label}
									</Listbox.Label>
									{subtitle && (
										<p className="mt-1 text-t-secondary">{subtitle}</p>
									)}
									<Listbox.Button
										name={name}
										className={twMerge(
											'"disabled:bg-gray-100 font-medium bg-white mt-2 hover:bg-gray-50 relative overflow-clip border border-t-input-border rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500  focus:border-primary-500 w-full',
											buttonClassName
										)}
									>
										<span className="block">
											{selectedItems &&
											selectedItems.length > 0 &&
											options.length > 0 ? (
												renderSelected(selectedItems)
											) : (
												<p className="text-t-secondary">{placeholder}</p>
											)}
										</span>
										<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
											<RiCodeLine className="self-center w-3 h-3 text-t-default rotate-90 cursor-pointer" />
										</span>
									</Listbox.Button>
									<Transition
										show={open}
										as={Fragment}
										leave="transition ease-in duration-100"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<Listbox.Options className="absolute font-medium mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
											{options.map((option, i) => (
												<Listbox.Option
													key={i}
													value={option}
													className={({ active }) =>
														classNames(
															active
																? 'text-t-default bg-gray-100'
																: 'text-t-default',
															'cursor-default select-none relative py-2 pl-3 pr-9'
														)
													}
												>
													{({ selected }) => (
														<>
															<div
																className={classNames(
																	selected ? 'font-semibold' : 'font-normal',
																	'block truncate'
																)}
															>
																{renderLabel(option)}
															</div>
															{selected ? (
																<span
																	className={classNames(
																		'text-primary-600 absolute inset-y-0 right-0 flex items-center pr-4'
																	)}
																>
																	<CheckIcon
																		className="h-5 w-5"
																		aria-hidden="true"
																	/>
																</span>
															) : null}
														</>
													)}
												</Listbox.Option>
											))}
										</Listbox.Options>
									</Transition>
								</div>
							</div>
						</>
					)
				}}
			</Listbox>

			{error && <p className="mt-1 text-xs text-red-700">{error}</p>}
		</div>
	)
}
