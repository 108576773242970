import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { ProtectionPlanProps, ProtectionPlanState } from '../types'
import { ProtectionPlanView } from './view'

export const ProtectionPlan = ({
	onConfirm,
	buttonLabel
}: ProtectionPlanProps) => {
	const { billingStore, accountStore } = useStores()
	const { plans } = billingStore
	const { account } = accountStore

	const [state, setState] = useState<ProtectionPlanState>({
		auto_upgade: false,
		billing_period: 'yearly',
		selected_price: null,
		selected_tier: null,
		coupon: null,
		coupon_code: null,
		coupon_error: null,
		detected_spend: account?.setup?.detected_spend || 0,
		custom_plan: false,
		recommended_spend: Math.max(
			account?.setup?.spend || 0,
			account?.setup?.detected_spend || 0,
			5000
		),
		spend: Math.max(
			account?.setup?.spend || 0,
			account?.setup?.detected_spend || 0,
			25000
		)
	})

	useEffect(() => {
		billingStore.initBilling()
	}, [])

	const claimCoupon = async (code: string | null) => {
		if (!code) return

		try {
			const discount = await billingStore.fetchCoupon(code)
			setState({
				...state,
				coupon: discount,
				coupon_code: code,
				coupon_error: null
			})
		} catch (error: any) {
			setState({ ...state, coupon_error: error })
		}
	}

	const confirm = () => {
		if (state.selected_tier && state.selected_price) {
			onConfirm(state)
		}
		if (state.custom_plan) {
			onConfirm(state)
		}
	}

	return (
		<ProtectionPlanView
			state={state}
			plans={plans}
			buttonLabel={buttonLabel}
			setState={(s) => {
				setState(s)
			}}
			claimCoupon={claimCoupon}
			onConfirm={confirm}
		/>
	)
}
