import { useChecklistStore } from 'layout/sidebar/store'

export const FinishChecklistNotice = () => {
	const { openChecklist } = useChecklistStore()

	return (
		<div>
			Get the most out of ClickGUARD by{' '}
			<button
				onClick={() => openChecklist(true)}
				className="ml-1 text-primary-500 font-bold"
			>
				completing your setup checklist.
			</button>
		</div>
	)
}
