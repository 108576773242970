import {
	GoogleAdsAdAccountDTO,
	GoogleAdsCampaignDTO,
	GoogleAdsLinkInfoDTO
} from 'api-models'
import {
	ChangeEvent,
	Dispatch,
	SetStateAction,
	useEffect,
	useState
} from 'react'
import { RiGoogleLine, RiQuestionLine } from 'react-icons/ri'
import { useQuery } from 'react-query'
import timezones from 'timezones-list'
import { useShallow } from 'zustand/react/shallow'

import {
	GButton,
	GComboBox,
	GConfirm,
	GInput,
	GLoading,
	GMultiSelect,
	GToggle,
	GTransition
} from 'components/basic-blocks'
import { GCheckbox } from 'components/basic-blocks/g-checkbox'
import Drawer from 'components/basic-blocks/g-drawer/g-drawer'
import { GRadio } from 'components/basic-blocks/g-radio'
import { GNewTooltip } from 'components/basic-blocks/g-tooltip/g-new-tooltip'
import { AppConfig, GoogleAuthConfig } from 'config'
import { TrackerCodeTemplate } from 'pages/workspace/asset/settings-page/tracking-section'
import { AdAccounts } from 'services/user'
import { ZendeskService } from 'services/zendesk'
import { useAccountStore, useAssetStore, useUiStore, useUserStore } from 'store'
import { currenciesOptions } from '../../constants'
import { adAccountSettingsFormSchema } from '../validations'
import { AccessLinkUpdated, ClickTracking, SettingsForm } from './types'

const tzList = timezones.map((item) => item.tzCode)

export const GoogleSettings = (props: {
	adAccount: GoogleAdsAdAccountDTO
	links: GoogleAdsLinkInfoDTO[]
	dialogOpen: boolean
	setDialogOpen: Dispatch<SetStateAction<boolean>>
}) => {
	const { adAccount, links, dialogOpen, setDialogOpen } = props

	const isLoading = useUiStore((s) => s.isLoading)
	const user = useUserStore((s) => s.user)
	const { account, userAccess, refreshAccountLinks } = useAccountStore(
		useShallow((s) => ({
			account: s.account,
			userAccess: s.userAccess,
			refreshAccountLinks: s.refreshAccountLinks
		}))
	)
	const {
		asset,
		updateGadsSettingsAccessLink,
		updateGadsAdAccountSettings,
		updateSettingsAccountProtection,
		synchronizeAdAccount,
		deleteAdAccountAccount,
		demoMode
	} = useAssetStore(
		useShallow((s) => ({
			asset: s.asset,
			demoMode: s.demoMode,
			updateGadsSettingsAccessLink: s.updateGadsSettingsAccessLink,
			updateGadsAdAccountSettings: s.updateGadsAdAccountSettings,
			updateSettingsAccountProtection: s.updateSettingsAccountProtection,
			synchronizeAdAccount: s.synchronizeAdAccount,
			deleteAdAccountAccount: s.deleteAdAccountAccount
		}))
	)

	const [errors, setErrors] = useState<Record<string, string> | null>()
	const [checked, setChecked] = useState(adAccount.status === 'ACTIVE')
	const [modalSync, setModalSync] = useState(false)
	const [modalDelete, setModalDelete] = useState(false)
	const [accessLinkUpdated, setAccessLinkUpdated] = useState<AccessLinkUpdated>(
		{}
	)

	const campaigns = useQuery(
		['campaigns', adAccount?.id],
		() => AdAccounts.getGoogleCampaigns(account!.id, adAccount?.id),
		{ enabled: !!account && !!adAccount }
	)

	const mapIdToAccount = (): GoogleAdsCampaignDTO[] => {
		return (
			adAccount?.preferences?.ignored_campaigns &&
			adAccount?.preferences?.ignored_campaigns
				.map((id: string) => campaigns?.data?.find((dataId) => dataId.id == id))
				.filter((id: string) => id !== undefined)
		)
	}

	const handleClickTracking = (): ClickTracking => {
		if (
			adAccount?.preferences?.automated_tracking &&
			adAccount?.preferences?.set_tracking_template
		) {
			return 'BOTH'
		}
		if (
			adAccount?.preferences?.automated_tracking &&
			!adAccount?.preferences?.set_tracking_template
		) {
			return 'AUTOMATIC'
		}
		return 'MANUAL'
	}

	const [settingsForm, setSettingsForm] = useState<SettingsForm>({
		name: adAccount?.name || '',
		color: adAccount?.color || '#3be380',
		currency: adAccount?.currency || '',
		timezone: adAccount?.timezone || '',
		access: undefined,
		ip_exclusions: adAccount.preferences.ip_exclusions,
		audience_exclusions: adAccount.preferences.audience_exclusions,
		ignored: mapIdToAccount() || [],
		clickTracking: handleClickTracking()
	})

	const setSelectedLink = () => {
		if (adAccount.details.link.type === 'direct')
			return { name: 'ClickGUARD MCC' }

		return links.find((link) => link.id === adAccount.details?.link?.id) as any
	}

	useEffect(() => {
		if (dialogOpen) {
			setChecked(adAccount.status === 'ACTIVE')
			setSettingsForm({
				name: adAccount?.name || '',
				color: adAccount?.color || '#2461FD',
				currency: adAccount?.currency || '',
				timezone: adAccount?.timezone || '',
				ip_exclusions: adAccount.preferences.ip_exclusions,
				audience_exclusions: adAccount.preferences.audience_exclusions,
				access: setSelectedLink(),
				ignored: mapIdToAccount() || [],
				clickTracking: handleClickTracking()
			})

			setAccessLinkUpdated({
				...accessLinkUpdated,
				originalValue: setSelectedLink()
			})
		}
	}, [adAccount, links, dialogOpen])
	const handleDialogClose = () => {
		setDialogOpen(false)
		ZendeskService.show()
	}

	const RenderIgnoredLabels = (Props: { option?: GoogleAdsCampaignDTO }) => {
		const { option } = Props

		return <div>{campaigns.data?.find((c) => c.id === option?.id)?.name}</div>
	}

	const RenderIgnoredSelected = (Props: {
		options: GoogleAdsCampaignDTO[]
	}) => {
		const { options } = Props
		const selectedItems: string[] = []
		options.forEach((option) => {
			const foundCampaignName = campaigns.data?.find(
				(c) => c.id === option?.id
			)?.name
			if (foundCampaignName) {
				selectedItems.push(foundCampaignName)
			}
		})

		return <div className="text-sm">{selectedItems.join(', ')}</div>
	}

	const onSynchronizeAccount = async () => {
		if (!account) return
		await synchronizeAdAccount(
			account?.id,
			adAccount?.id,
			adAccount.platform.toLowerCase()
		)
		campaigns.refetch()
		setModalSync(false)
	}

	const onDeletePPCAccount = async () => {
		if (!adAccount) return
		try {
			await deleteAdAccountAccount(adAccount?.account_id, adAccount?.id)
			setModalDelete(false)
		} catch (e) {
			return
		}
	}

	const handleChange = (e: any, key: keyof SettingsForm) => {
		const input = e.currentTarget
		const form = { ...settingsForm }

		if (key === 'ip_exclusions' || key === 'audience_exclusions') {
			form[key] = Boolean(e)
			setSettingsForm(form)
			return
		}
		if (!input) {
			form[key] = e
			setSettingsForm(form)
			return
		}
		form[key] = input.value
		setSettingsForm(form)
	}

	const confirmCreate = async () => {
		const payload = {
			name: settingsForm.name,
			color: settingsForm.color,
			currency: settingsForm.currency,
			timezone: settingsForm.timezone,
			ignored_campaigns: settingsForm.ignored?.map((c) => c?.id) || [],
			click_tracking: settingsForm.clickTracking,
			ip_exclusions: settingsForm.ip_exclusions,
			audience_exclusions: settingsForm.audience_exclusions
		}
		const protectionPayload = { active: checked }

		const errors = validate()
		setErrors(errors)
		if (errors) return

		handleDialogClose()
		updateGadsAdAccountSettings(account!.id, adAccount!.id, payload as any)

		if (checked !== (adAccount.status === 'ACTIVE')) {
			updateSettingsAccountProtection(
				account!.id,
				adAccount!.id,
				protectionPayload
			)
		}

		if (!settingsForm?.access?.id) return
		if (settingsForm?.access?.id === accessLinkUpdated.originalValue?.id) return

		const linkPayload = { link_id: settingsForm.access?.id }
		try {
			await updateGadsSettingsAccessLink(
				account!.id,
				adAccount!.id,
				linkPayload
			)
			setAccessLinkUpdated({
				...accessLinkUpdated,
				originalValue: settingsForm.access as any
			})
		} catch (e) {
			return
		}
	}

	const validate = () => {
		const options = { abortEarly: false }
		const { error } = adAccountSettingsFormSchema.validate(
			{
				active: checked,
				name: settingsForm.name,
				color: settingsForm.color,
				currency: settingsForm.currency,
				timeZone: settingsForm.timezone,
				access: settingsForm.access,
				clickTracking: settingsForm.clickTracking
			},
			options
		)

		if (!error) return null

		const errors: Record<string, string> = {}

		error.details.forEach((item) => {
			errors[item.path[0]] = item.message
		})
		return errors
	}

	const handleNewAuthorization = () => {
		let newTab: Window | null = null

		newTab = window.open(GoogleAuthConfig.AUTH_URL, '_blank')

		window.addEventListener('beforeunload', async () => {
			const refreshedAccountLinks = await refreshAccountLinks()
			if (refreshedAccountLinks)
				handleChange(refreshedAccountLinks.slice(-1), 'access')

			if (newTab) newTab.close()
		})
	}

	return (
		<Drawer
			isOpen={dialogOpen}
			handleClose={handleDialogClose}
			label="Account settings"
			headerClassName="pt-6"
			labelClassName="text-xl"
		>
			<GTransition show={true} swap>
				<div className="w-[750px] mx-auto bg-white px-6 mt-6 py-6">
					<div className="bg-gray-50 px-3 py-3 flex justify-between">
						<div className="flex items-center">
							<RiGoogleLine className="text-red-500 w-6 h-6" />
							<p className="text-[#343741] font-bold text-lg flex items-center">
								&nbsp; &nbsp;
								<span className="block max-w-[200px] truncate text-t-default">
									{adAccount.name}
								</span>
								<span className="text-md font-normal text-t-secondary px-2">
									&nbsp;
									{adAccount.details.account_number}
								</span>
							</p>
						</div>
						<GConfirm
							title="Synchronize PPC account"
							description="This action will pull fresh account and campaign information. Proceed?"
							open={
								demoMode || userAccess(user!.id) === 'READ' ? false : modalSync
							}
							setOpen={setModalSync}
							loading={isLoading('ASSET_SYNC_ACCOUNT')}
							onConfirm={() => onSynchronizeAccount()}
						>
							<GNewTooltip
								content={
									demoMode
										? 'This action is disabled when demo data is displayed'
										: ''
								}
							>
								<GButton
									disabled={demoMode || userAccess(user!.id) === 'READ'}
									variant="text"
									label="Synchronize"
									color="neutral"
									labelClassName="font-bold text-primary-500 text-base"
									className="h-[32px] min-w-[112px]"
									onClick={() => setModalSync(true)}
									loading={isLoading('ASSET_SYNC_ACCOUNT')}
								/>
							</GNewTooltip>
						</GConfirm>
					</div>
					<div className="flex flex-col justify-between h-[calc(100vh-100px)] py-3">
						<div>
							<div className="flex items-center py-3">
								<p className="text-t-secondary text-lg font-bold min-w-[300px]">
									Protection enabled
								</p>
								<GToggle
									checked={checked}
									activeColor={'success'}
									onChange={() => {
										setChecked((prevState) => !prevState)
									}}
								/>
							</div>
							<div className="flex items-center py-3">
								<p className="text-t-secondary text-lg font-bold min-w-[300px]">
									Account name
								</p>
								<div className="w-full">
									<GInput
										inputClassName="text-base w-full font-medium"
										placeholder="acmecorp.com"
										errorPosition="flex"
										value={settingsForm.name}
										onChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleChange(e, 'name')
										}
										error={errors?.name}
									/>
								</div>
							</div>
							<div className="flex items-center py-3">
								<p className="text-t-secondary text-lg font-bold min-w-[300px]">
									Currency
								</p>
								<div className="w-full">
									<GComboBox
										options={currenciesOptions}
										inputClass="text-base font-medium"
										value={settingsForm.currency}
										onChange={(e) => handleChange(e, 'currency')}
										filter={(item, query) =>
											item.toLowerCase().includes(query.toLocaleLowerCase())
										}
										renderOption={(i) => i}
										renderValue={(i) => i}
									/>
								</div>
							</div>
							<div className="flex items-center py-3">
								<p className="text-t-secondary text-lg font-bold min-w-[300px]">
									Timezone
								</p>
								<div className="w-full">
									<GComboBox
										options={tzList}
										value={settingsForm.timezone}
										inputClass="text-base font-medium"
										onChange={(e) => handleChange(e, 'timezone')}
										filter={(item, query) =>
											item.toLowerCase().includes(query.toLocaleLowerCase())
										}
										renderOption={(i) => i}
										renderValue={(i) => i}
									/>
								</div>
							</div>
							<div
								className={`flex items-center ${
									!settingsForm.access ? 'my-8' : 'py-3'
								}`}
							>
								<div className="text-t-secondary text-lg font-bold min-w-[300px] flex items-center">
									Authorization access &nbsp;
									<GNewTooltip content="Select a connection to Google Ads used to access this account">
										<RiQuestionLine className="text-gray-500 h-3 w-3" />
									</GNewTooltip>
								</div>
								<div className="flex justify-between items-center w-full gap-2">
									<div className="w-full">
										<div className="flex-1 relative">
											<GComboBox<any>
												options={links as []}
												inputClass="text-base font-medium"
												placeholder={
													links.length === 0
														? 'No access links for Google'
														: 'Select a connection'
												}
												value={settingsForm.access}
												onChange={(e) => handleChange(e, 'access')}
												disabled={links.length === 0}
												filter={(item, query) =>
													item?.name ||
													item?.email
														.toLowerCase()
														.includes(query.toLocaleLowerCase())
												}
												renderOption={(i) =>
													i?.name || i?.email || i?.account_number
												}
												renderValue={(i) =>
													i?.name || i?.email || i?.account_number
												}
												error={errors?.access}
											/>
											{!settingsForm.access && (
												<p className="text-base text-red-500 absolute top-[45px] left-[5px] font-medium w-[20rem]">
													Selected authorization has lost access to this account
												</p>
											)}
										</div>
									</div>
									<GNewTooltip
										content={
											demoMode
												? 'This action is disabled when demo data is displayed'
												: userAccess(user!.id) === 'READ'
												? "Analysts don't have permission for this action"
												: undefined
										}
									>
										<GButton
											variant="text"
											label="New authorization"
											disabled={demoMode || userAccess(user!.id) === 'READ'}
											color="buttonPrimary"
											className="font-bold h-[40px] w-[160px] border-t-input-border"
											size="lg"
											onClick={handleNewAuthorization}
										/>
									</GNewTooltip>
								</div>
							</div>
							<div className="flex items-center py-3">
								<div className="text-t-secondary text-lg font-bold min-w-[300px] flex items-center">
									Ignored campaigns &nbsp;
									<GNewTooltip content="Select campaigns that you want to be ignored by ClickGUARD">
										<RiQuestionLine className="text-gray-500 h-3 w-3" />
									</GNewTooltip>
								</div>
								<div className="w-full">
									{campaigns ? (
										<GMultiSelect<GoogleAdsCampaignDTO>
											labelClassName="text-base font-medium"
											placeholder="None"
											options={campaigns.data || []}
											value={settingsForm?.ignored || []}
											renderLabel={(list?: GoogleAdsCampaignDTO) => (
												<RenderIgnoredLabels option={list} />
											)}
											renderSelected={(list: GoogleAdsCampaignDTO[]) => (
												<RenderIgnoredSelected options={list} />
											)}
											onChange={(val) => handleChange(val, 'ignored')}
										/>
									) : (
										<GLoading />
									)}
								</div>
							</div>
							<div className="flex items-start py-3">
								<p className="text-t-secondary text-lg font-bold min-w-[300px]">
									Exclusion management
								</p>
								<div className="flex flex-col gap-1 w-full">
									<GCheckbox
										name="ip_exclusions"
										label="Enable IP address exclusions"
										checked={!!settingsForm.ip_exclusions}
										className="cursor-pointer"
										onChange={() =>
											handleChange(!settingsForm.ip_exclusions, 'ip_exclusions')
										}
										helpText="Enables excluding IP addresses and IP ranges on supported campaigns."
									/>
									<GCheckbox
										name="audience_exclusions"
										label="Enable audience exclusions"
										checked={!!settingsForm.audience_exclusions}
										className="cursor-pointer"
										onChange={() =>
											handleChange(
												!settingsForm.audience_exclusions,
												'audience_exclusions'
											)
										}
										helpText="Enables creating and maintaining the user list that we’ll exclude on all your campaigns. Adding visitors to the user list will automatically exclude them from seeing your ads."
									/>
								</div>
							</div>
							<div className="flex items-start py-3">
								<p className="text-t-secondary text-lg font-bold min-w-[300px]">
									Click tracking
								</p>
								<div className="flex flex-col gap-1 w-full">
									<GRadio
										checked={settingsForm.clickTracking === 'BOTH'}
										className="py-1.5"
										labelClass="text-t-secondary font-bold"
										label="Use tracking template with final URL suffix"
										name="role"
										value={'BOTH'}
										onChange={(e) =>
											handleChange(e.target.value, 'clickTracking')
										}
										helpToolTip="Automatically set both tracking template and final URL suffix on account level, enables tracking clicks as they happen. This ensures most accurate data collection."
									/>
									<GRadio
										checked={settingsForm.clickTracking === 'AUTOMATIC'}
										className="py-1.5"
										labelClass="text-t-secondary font-bold"
										label="Use final URL suffix only"
										name="role"
										value={'AUTOMATIC'}
										onChange={(e) =>
											handleChange(e.target.value, 'clickTracking')
										}
										helpToolTip="Automatically set final URL suffix on account level, enables tracking clicks from the landing page. Less accurate than using tracking template."
									/>
									<GRadio
										checked={settingsForm.clickTracking === 'MANUAL'}
										className="py-1.5"
										labelClass="text-t-secondary font-bold"
										label="Manual setup"
										name="role"
										value={'MANUAL'}
										onChange={(e) =>
											handleChange(e.target.value, 'clickTracking')
										}
										helpToolTip="Manually set required tracking parameters in Google Ads account settings. Good option if you’re already using tracking template and final URL suffix for other services."
									/>
								</div>
							</div>

							{settingsForm.clickTracking === 'MANUAL' && (
								<AccountSettingManualTracking adAccount={adAccount} />
							)}
							<div className="flex items-center my-8 border-t border-gray-100 pt-6">
								<p className="text-t-secondary text-base font-bold min-w-[300px]">
									Delete account
								</p>
								<GConfirm
									title={`Remove ${adAccount.name}`}
									type="danger"
									description={`Are you sure you want to remove this PPC account from ${asset?.name}`}
									open={
										demoMode || userAccess(user!.id) === 'READ'
											? false
											: modalDelete
									}
									setOpen={setModalDelete}
									loading={isLoading('ASSET_DELETE_AD_ACCOUNT')}
									onConfirm={() => onDeletePPCAccount()}
								>
									<GNewTooltip
										content={
											demoMode
												? 'This action is disabled when demo data is displayed'
												: userAccess(user!.id) === 'READ'
												? "Analysts don't have permission for this action"
												: undefined
										}
									>
										<GButton
											label="Delete"
											variant="contained"
											labelClassName="font-bold"
											className="text-white h-[25px] w-[70px]"
											color="danger"
											size="lg"
											onClick={() => setModalDelete(true)}
											disabled={userAccess(user!.id) === 'READ' || demoMode}
										/>
									</GNewTooltip>
								</GConfirm>
							</div>
						</div>
						<div className="flex items-center justify-between min-h-[70px] px-4 border-t border-green-100]">
							<GButton
								variant="text"
								label="Close"
								size="lg"
								color="neutral"
								labelClassName="font-bold text-t-default"
								className="h-[30px] w-[68px] border-t-input-border"
								onClick={handleDialogClose}
							/>
							<GButton
								disabled={
									userAccess(user!.id) === 'READ' ||
									account?.billing?.subscription?.status === 'CANCELLED' ||
									demoMode
								}
								label="Save"
								variant="contained"
								color="primary"
								labelClassName="font-bold text-base"
								className="h-[30px] w-[68px]"
								onClick={() => confirmCreate()}
								loading={isLoading('ASSET_SAVE_ACCOUNT_SETTINGS')}
							/>
						</div>
					</div>
				</div>
			</GTransition>
		</Drawer>
	)
}

export const AccountSettingManualTracking = ({
	adAccount
}: {
	adAccount: GoogleAdsAdAccountDTO
}) => {
	const [copyStatus, setCopyStatus] = useState(false)
	return (
		<div>
			<p className="text-t-default font-bold text-xl">Manual tracking setup</p>
			<p className="text-t-secondary text-base mt-1">
				You will need to manually set tracking template and final URL suffix on
				account level in Google Ads.
			</p>
			<p className="text-t-default font-bold text-base mt-6">
				With tracking template and URL suffix (recommended)
			</p>
			<p className="text-t-secondary text-base mt-1">
				By using both tracking template and URL suffix we can track clicks as
				they happen, and keep tracking them on the landing page.
			</p>
			<div className="mt-6">
				<div className="flex justify-between items-center">
					<p className="text-t-default font-bold text-sm">Tracking template</p>
					<GNewTooltip content={copyStatus ? 'Copied!' : 'Copy'} position="top">
						<GButton
							variant="text"
							label="Copy to clipboard"
							color="buttonPrimary"
							size="lg"
							className="h-[28px] w-max"
							labelClassName="font-bold"
							onClick={() => copy(adAccount?.preferences?.tracking_template)}
						/>
					</GNewTooltip>
				</div>
				<TrackerCodeTemplate
					isCopy={false}
					content={{
						subtitle: 'Tracking script',
						code: adAccount?.preferences
							? adAccount?.preferences?.tracking_template
							: '< code sample >'
					}}
				/>
			</div>
			<div className="mt-6">
				<div className="flex justify-between items-center">
					<p className="text-t-default font-bold text-sm">Final URL suffix</p>
					<GNewTooltip content={copyStatus ? 'Copied!' : 'Copy'} position="top">
						<GButton
							variant="text"
							label="Copy to clipboard"
							color="buttonPrimary"
							size="lg"
							className="h-[28px] w-max"
							labelClassName="font-bold"
							onClick={() => copy(adAccount?.preferences?.url_suffix)}
						/>
					</GNewTooltip>
				</div>
				<TrackerCodeTemplate
					isCopy={false}
					height={1}
					font="font-normal"
					content={{
						subtitle: 'Tracking script',
						code: adAccount?.preferences
							? adAccount?.preferences?.url_suffix
							: '< code sample >'
					}}
				/>
			</div>
			<div>
				<p className="text-t-default font-bold text-base mt-6">
					With final URL suffix only
				</p>
				<p className="text-t-secondary text-base">
					By using only URL suffix, clicks will be tracked only when the visitor
					reaches the landing page. Requires installed tracking code on the
					website.
				</p>
				<div className="mt-6">
					<div className="flex justify-between items-center">
						<p className="text-t-default font-bold text-sm">Final URL suffix</p>
						<GNewTooltip
							content={copyStatus ? 'Copied!' : 'Copy'}
							position="top"
						>
							<GButton
								variant="text"
								label="Copy to clipboard"
								color="buttonPrimary"
								size="lg"
								className="h-[28px] w-max"
								labelClassName="font-bold"
								onClick={() => copy(adAccount?.preferences?.url_suffix_only)}
							/>
						</GNewTooltip>
					</div>
					<TrackerCodeTemplate
						isCopy={false}
						font={'font-normal'}
						height={2}
						content={{
							subtitle: 'Tracking script',
							code: adAccount?.preferences
								? adAccount?.preferences?.url_suffix_only
								: '< code sample >'
						}}
					/>
				</div>
				<div className="my-6">
					Visit the{' '}
					<a
						className="mb-2 text-md text-primary-500 hover:text-primary-300 underline font-bold"
						href={AppConfig.KNOWLEDGEBASE_URL}
						target="_blank"
					>
						Knowledge base
					</a>{' '}
					for additional help.
				</div>
			</div>
		</div>
	)
	async function copy(text: string) {
		await navigator.clipboard.writeText(text)
		setCopyStatus(true)
		setTimeout(() => {
			setCopyStatus(false)
		}, 2000)
	}
}
