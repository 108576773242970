import { Elements } from '@stripe/react-stripe-js'
import { Notices } from 'components/notice/notices'
import { useThemeStore } from 'layout/navbar/store'
import moment from 'moment/moment'
import { Dispatch, useEffect, useMemo, useState } from 'react'
import { RiMore2Line } from 'react-icons/ri'
import {
	useAccountStore,
	useAssetStore,
	useBillingStore,
	useUiStore
} from 'store'
import { useShallow } from 'zustand/react/shallow'
import { OfferDTO, OfferRequestDTO } from '../../../../../api-models'
import {
	GButton,
	GDropdown,
	GIconButton,
	GInput,
	GSection
} from '../../../components/basic-blocks'
import { GBadge } from '../../../components/basic-blocks/g-badge'
import { GDateTime } from '../../../components/basic-blocks/g-datetime'
import { NewPaymentMethod } from '../../../components/new-payment-method'
import { PageHeader } from '../../../components/page-header'
import { RequestOffer } from '../../../components/request-offer'
import { PlanNames } from '../../../config'
import { useStores } from '../../../hooks'
import { useSubscriptionManagement } from '../../../hooks/usePendingSubscription'
import { Offers } from '../../../services/user'
import {
	formatCurrency,
	formatCurrencyDiscount,
	formatPriceInterval,
	formatSpend
} from '../../../utils'
import { PageTitle } from '../../../utils/page-title'

export const OffersSetting = () => {
	const isLoading = useUiStore((s) => s.isLoading)
	const { account, isInSetup, hasFeature } = useAccountStore(
		useShallow((s) => ({
			account: s.account,
			isInSetup: s.isInSetup,
			hasFeature: s.hasFeature
		}))
	)
	const { offers, offersRequests, stripe } = useBillingStore(
		useShallow((s) => ({
			offers: s.offers,
			offersRequests: s.offersRequests,
			stripe: s.stripe
		}))
	)
	const { asset, adAccounts } = useAssetStore(
		useShallow((s) => ({
			asset: s.asset,
			adAccounts: s.adAccounts
		}))
	)

	const [modalRequestOffer, setModalRequestOffer] = useState(false)

	const { theme } = useThemeStore()

	return (
		<div className="w-full">
			<PageTitle pageName="Offers" />
			<Notices
				isInSetup={isInSetup()}
				setupStep={account?.setup?.step}
				isInActive={account?.status === 'INACTIVE'}
				hasDashboardPerformanceFeature={hasFeature('dashboard_performance')}
				tc_installed={asset ? !!asset?.tc_installed : true}
				cc_installed={asset ? !!asset?.cc_installed : true}
				accountId={account?.id}
				assetId={asset?.id}
				demoMode={false}
				hideDemoDataNotice={true}
				mccLinkInviteAdAccounts={adAccounts}
			/>
			<PageHeader
				title={'Offers'}
				subtitle={'Request and review custom quotes for your workspaces'}
			/>
			<div className="max-w-3xl px-4 sm:px-6 md:px-8">
				{offers?.length > 0 && (
					<GSection
						title="Prepared offers"
						subtitle="Our staff has prepared the following offers"
						lineBreak={true}
						lineBreakColor="gray-100"
					>
						{offers?.map((offer) => (
							<Elements
								key={offer.id}
								stripe={stripe}
								options={{
									appearance: { theme: theme === 'dark' ? 'night' : 'none' }
								}}
							>
								<OfferList offer={offer} />
							</Elements>
						))}
					</GSection>
				)}
				<RequestOffer
					open={modalRequestOffer}
					account={account}
					onClose={() => setModalRequestOffer(false)}
				/>
				<div className={offers.length === 0 ? '' : 'mt-8'}>
					<GSection
						title="Requests"
						subtitle="If you need a custom plan, our staff can prepare you a quote"
						loading={isLoading('USER_GET_OFFER_REQUEST')}
						lineBreak={offersRequests?.length > 0}
						titleClass={offersRequests?.length > 0 ? 'mb-4' : 'mb-0'}
						lineBreakColor="gray-100"
						actions={
							<GButton
								variant="contained"
								size="lg"
								color="primary"
								className="h-[30px]"
								label={'Request a quote'}
								labelClassName="font-bold text-base"
								onClick={() => setModalRequestOffer(true)}
							/>
						}
					>
						{offersRequests?.map((request) => (
							<OfferRequestList key={request.id} offerRequest={request} />
						))}
					</GSection>
				</div>
			</div>
		</div>
	)
}

const OfferRequestList = ({
	offerRequest
}: {
	offerRequest: OfferRequestDTO
}) => {
	const [modalRequestOffer, setModalRequestOffer] = useState(false)
	const {
		accountStore: { accounts },
		billingStore
	} = useStores()
	const deleteOfferRequest = async () => {
		await billingStore.removeOfferRequest(offerRequest.id)
	}

	const account = accounts.find((a) => a.id === offerRequest.account_id)

	return (
		<div className="flex justify-between items-center mt-6">
			<RequestOffer
				open={modalRequestOffer}
				onClose={() => setModalRequestOffer(false)}
				offerRequest={offerRequest}
				disabledFor={offerRequest.account_id ? 'NEW' : 'EXISTING'}
			/>
			<div>
				<p className="text-t-default font-bold text-base">
					{`${account ? account.name : 'For a new'} Workspace`}
				</p>
				<p className="text-t-secondary text-base">
					{`${formatSpend(offerRequest.spend)} monthly ad spend, ${
						offerRequest.interval
					} billing period`}
				</p>
			</div>
			<div className="flex gap-4 items-center">
				<GDateTime
					className="text-t-secondary w-max block"
					date={offerRequest.created_at}
				/>
				<GDropdown
					anchor="left"
					menuButton={
						<GIconButton
							icon={RiMore2Line}
							size="xs"
							variant="text"
							type="square"
						/>
					}
				>
					{() => {
						return (
							<div className="bg-white p-4 font-bold">
								<p className="py-1 cursor-pointer" onClick={deleteOfferRequest}>
									Remove
								</p>
								<p
									onClick={() => setModalRequestOffer(true)}
									className="py-1 cursor-pointer"
								>
									Update
								</p>
							</div>
						)
					}}
				</GDropdown>
			</div>
		</div>
	)
}

const OfferList = (props: { offer: OfferDTO }) => {
	const { offer } = props
	const {
		accountStore: { accounts }
	} = useStores()
	const [close, setClose] = useState(true)

	const account = accounts.find((a) => a.id === offer.account_id)

	return (
		<div className="mt-6 border border-gray-100">
			<div
				className={`flex justify-between items-center px-4 py-${
					!close ? '6' : '2'
				}`}
			>
				<div className="flex items-center gap-4">
					<p className="text-t-default text-base">
						For{' '}
						<span className="font-bold">
							{`${account ? account.name : 'a new'} workspace`}
						</span>
					</p>
					<PriceBadge offer={offer} />
					<SpendBadge offer={offer} />
				</div>
				{close && (
					<GButton
						variant="text"
						size="sm"
						className="h-[25px] px-4"
						labelClassName="font-bold text-base"
						color="neutral"
						label={'View'}
						onClick={() => setClose(false)}
					/>
				)}
			</div>
			{!close && (
				<ViewOfferDetails key={offer.id} offer={offer} setClose={setClose} />
			)}
		</div>
	)
}

const PriceBadge = (props: { offer: OfferDTO }) => {
	const { offer } = props
	return (
		<GBadge
			className="rounded-md"
			text={
				<p className="flex items-center gap-2">
					<span className="px-1 py-0.5 font-bold">
						{!offer?.coupon && formatCurrency(offer.price)}
						{offer?.coupon && (
							<>
								{formatCurrencyDiscount(offer.price, {
									amountOff: offer?.coupon?.amount_off,
									percentOff: offer?.coupon?.percent_off
								})}
							</>
						)}
						{formatPriceInterval(offer.interval, offer.interval_count)}
					</span>
				</p>
			}
			color="green"
		/>
	)
}

const SpendBadge = (props: { offer: OfferDTO }) => {
	const { offer } = props
	return (
		<GBadge
			className="rounded-md px-2 py-1"
			text={
				<span className="px-1 py-0.5 font-bold">
					{formatSpend(offer.spend, true, false)} spend
				</span>
			}
			color="purple"
		/>
	)
}

const ViewOfferDetails = ({
	setClose,
	offer
}: {
	setClose: Dispatch<boolean>
	offer: OfferDTO
}) => {
	const { billingStore, accountStore } = useStores()
	const { isInSetup, getAllAccounts } = accountStore
	const isLoading = useUiStore((s) => s.isLoading)
	const [showNewPayment, setShowNewPayment] = useState(false)
	const [paymentMethodId, setPaymentMethodId] = useState<string>('')
	const [loading, setLoading] = useState(false)
	const [name, setName] = useState('')
	const [error, setErrors] = useState({ name: '' })

	const offerAccount = offer.account
	const subscriptionType = offerAccount?.billing?.subscription?.type
	const billingDate = moment(
		offerAccount?.billing?.subscription?.next_billing_date ||
			offerAccount?.billing?.subscription?.ends_at ||
			offerAccount?.billing?.subscription?.ended_at
	).format('LL')

	const fetchOffer = async () => {
		const offers = await Offers.find()
		billingStore.setOffers(offers)
	}

	const isDiscount = useMemo(() => {
		if (offer.coupon?.amount_off) {
			return offer.coupon.amount_off > 0
		}
		if (offer.coupon?.percent_off) {
			return offer.coupon.percent_off > 0
		}
		return false
	}, [offer?.coupon])

	useSubscriptionManagement({
		subscription: accountStore.subscription,
		shouldLoad: loading,
		onSuccess: async () => {
			getAllAccounts()
			fetchOffer()
			setLoading(false)
			setClose(true)
		}
	})
	const reject = async () => {
		await billingStore.rejectOffer(offer.id)
		setClose(true)
	}

	const submitForNewAccount = async (isSchedule = false) => {
		setLoading(true)
		const subscription = await billingStore.acceptOffer(
			offer.id,
			isSchedule,
			paymentMethodId,
			offer?.account_id ? undefined : name,
			isInSetup()
		)
		if (subscription?.status === 'ACTIVE') {
			getAllAccounts()
			setLoading(false)
			setClose(true)
		}
	}

	const submitForCurrent = async (isSchedule = false) => {
		if (offer?.account_id) {
			await submitForNewAccount(isSchedule)
		} else {
			const error = handleErrors()
			if (error) {
				setErrors({ name: error })
				return
			}
			setShowNewPayment(true)
		}
	}

	useEffect(() => {
		if (paymentMethodId && !offer?.account_id) {
			submitForNewAccount()
		}
	}, [paymentMethodId])

	useEffect(() => {
		accountStore.setSubscription(null)
	}, [])

	const handleErrors = () => {
		if (!offer.account_id && name === '') {
			return 'You need to add a workspace name and select a payment method'
		} else {
			return ''
		}
	}

	return (
		<div className="pt-2 px-6">
			{!offer?.account_id && (
				<div className="pb-6">
					<NewPaymentMethod
						newWorkspace={true}
						show={showNewPayment}
						onClose={() => setShowNewPayment(false)}
						onSelect={(pm) => typeof pm === 'string' && setPaymentMethodId(pm)}
					/>
				</div>
			)}
			<div className="flex justify-between pt-3">
				<span className="text-t-secondary font-medium">Plan</span>
				<span className="text-t-default font-bold">
					{PlanNames[offer.tier]}
				</span>
			</div>
			<div className="flex justify-between pt-3">
				<span className="text-t-secondary font-medium">Price</span>
				<div className="flex items-center gap-2">
					{isDiscount && (
						<del className="text-t-secondary font-bold">
							{formatCurrency(offer.price)}
						</del>
					)}
					<span className="text-t-default font-bold">
						{formatCurrencyDiscount(offer.price, {
							amountOff: offer?.coupon?.amount_off,
							percentOff: offer?.coupon?.percent_off
						})}
						{formatPriceInterval(offer.interval, offer.interval_count)}
					</span>
				</div>
			</div>
			{isDiscount && (
				<div className="flex justify-between pt-3">
					<span className="text-t-secondary font-medium">Discount</span>
					<span className="text-t-default font-bold">
						{(offer?.coupon?.amount_off &&
							formatCurrency(offer?.coupon?.amount_off)) ||
							`${offer?.coupon?.percent_off}%`}{' '}
						{offer?.coupon?.duration}
					</span>
				</div>
			)}
			<div className="flex justify-between pt-3">
				<span className="text-t-secondary font-medium">Monthly ad spend</span>
				<span className="text-t-default font-bold">
					{formatSpend(offer.spend)}
				</span>
			</div>
			{offer?.trial?.days && (
				<p className="flex justify-between pt-3">
					<span className="text-t-secondary font-medium">Free trial</span>
					<span className="text-t-default font-bold">
						{offer?.trial?.days} days
					</span>
				</p>
			)}
			{offer?.expired_at && (
				<div className="flex justify-between pt-3">
					<span className="text-t-secondary font-medium">Valid until</span>
					<span className="text-t-default font-bold">
						{moment(offer.expired_at).format('MMMM Do')}
					</span>
				</div>
			)}
			{!offer.account_id && (
				<div className="text-left py-4 pt-3">
					<GInput
						labelClassName="text-t-secondary font-medium pb-1"
						label="New workspace name"
						onChange={(e) => setName(e.target.value)}
						error={error.name}
					/>
				</div>
			)}
			<div className="flex justify-between pt-6 pb-4">
				<GButton
					variant="text"
					color="neutral"
					label={'Close'}
					className="h-[25px] px-4"
					labelClassName="font-bold test-base"
					disabled={isLoading('USER_ACCEPT_OFFER')}
					loading={isLoading('USER_ACCEPT_OFFER') || loading}
					onClick={() => setClose(true)}
				/>
				<div className="flex gap-2">
					<GButton
						variant="text"
						size="sm"
						color="danger"
						label={'Reject'}
						className="h-[25px] min-w-[65px]"
						disabled={isLoading('USER_REMOVE_OFFER')}
						loading={isLoading('USER_REMOVE_OFFER') || loading}
						labelClassName="font-bold text-base"
						onClick={reject}
					/>
					{subscriptionType !== 'TRIAL' && (
						<div className="flex flex-col">
							<GButton
								variant="contained"
								color="primary"
								label={'Activate Now'}
								className="h-[25px] min-w-[69px]"
								loading={isLoading('USER_ACCEPT_OFFER') || loading}
								labelClassName="font-bold text-base"
								onClick={() => submitForCurrent()}
							/>
							<div className="text-t-secondary text-center text-xs mt-2">
								New subscription will start on {moment().format('LL')}
							</div>
						</div>
					)}

					{offerAccount && (
						<div className="flex flex-col">
							<GButton
								variant="outlined"
								color="primary"
								label={'Activate on next billing date'}
								className="h-[25px] min-w-[69px]"
								loading={isLoading('USER_ACCEPT_OFFER') || loading}
								labelClassName="font-bold text-base"
								onClick={() => submitForCurrent(subscriptionType !== 'TRIAL')}
							/>
							<div className="text-t-secondary text-center text-xs mt-2">
								New subscription will start on {billingDate}
							</div>{' '}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
