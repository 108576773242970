import {
	AdAccountDTO,
	GoogleAdsAdAccountDTO,
	ProductAccountDTO
} from 'api-models'
import { useState } from 'react'
import {
	RiBriefcaseLine,
	RiEarthLine,
	RiGoogleLine,
	RiUserLine
} from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { adminSearch } from 'services/admin/search'
import { sanitizeURL } from 'utils'
import { SearchWebsiteInput } from './search-website'
import { GroupedOption, Option, OptionExtention, SearchState } from './types'

export const AdminSearch = () => {
	const [state, setState] = useState<SearchState>({
		searchQuery: ''
	})

	const loadOptions = async (inputValue: string) => {
		if (inputValue.length < 3)
			return [
				{ label: 'Workspaces', options: [] },
				{ label: 'Sites', options: [] },
				{ label: 'PPC Accounts', options: [] },
				{ label: 'Users', options: [] }
			]

		const searchResults = await adminSearch.getSearchResults(inputValue)

		const accounts = searchResults.accounts.map((account) => {
			return {
				...account,
				icon: RiBriefcaseLine,
				navLink: `/admin/subscription/${account.id}`,
				group: 'account'
			}
		})
		const assets = searchResults.asset.map((assets) => {
			return {
				...assets,
				icon: RiEarthLine,
				navLink: `/admin/website/${assets.id}`,
				group: 'asset'
			}
		})
		const ad_accounts = searchResults.ad_accounts.map((adAccount) => {
			//We can change icon based on platform here
			return {
				...adAccount,
				icon: RiGoogleLine,
				navLink: `/admin/ppc-account/${adAccount.id}`,
				group: 'PPCAccounts'
			}
		})
		const users = searchResults.users.map((user) => {
			return {
				...user,
				icon: RiUserLine,
				navLink: `/admin/user/${user.id}`,
				group: 'user'
			}
		})

		const groupedOptions: GroupedOption[] = [
			{ label: 'Workspaces', options: accounts || [] },
			{ label: 'Sites', options: assets || [] },
			{ label: 'PPC Accounts', options: ad_accounts || [] },
			{ label: 'Users', options: users || [] }
		]
		setState((prevState) => ({ ...prevState, options: groupedOptions }))
		return groupedOptions
	}

	const formatGroupLabel = (option: GroupedOption) => {
		return <div className="font-semibold text-t-secondary">{option.label}</div>
	}

	const formateOptionLabel = (option: Option) => {
		const Icon = option.icon
		const title = option.name
		let subtitle
		if (option.group === 'accounts') {
			const castedOption = option as ProductAccountDTO & OptionExtention
			subtitle = castedOption.billing?.organization.name
		}
		if (option.group === 'PPCAccounts') {
			const castedOption = option as AdAccountDTO & OptionExtention
			if (castedOption.platform === 'GADS') {
				const castedToPlatform = castedOption as GoogleAdsAdAccountDTO &
					OptionExtention
				subtitle = castedToPlatform.details.account_number
			}
		}
		return (
			<Link
				to={option.navLink}
				onClick={() => {
					setState((prevState) => ({ ...prevState, searchQuery: '' }))
					const groupedOptions: GroupedOption[] = [
						{ label: 'Workspaces', options: [] },
						{ label: 'Sites', options: [] },
						{ label: 'PPC Accounts', options: [] },
						{ label: 'Users', options: [] }
					]
					setState((prevState) => ({ ...prevState, options: groupedOptions }))
				}}
			>
				<div className="flex items-center gap-2 pl-4">
					<div>
						{Icon && (
							<Icon
								className="w-4 h-4"
								style={{ color: option?.color || '#9c73d2' }}
							/>
						)}
					</div>
					<div className="text-sm font-medium text-t-dark">
						{sanitizeURL(title)}
					</div>
					{subtitle && (
						<div className="text-sm text-t-secondary">{subtitle}</div>
					)}
				</div>
			</Link>
		)
	}

	return (
		<div className="text-white max-w-xs ml-6">
			<SearchWebsiteInput
				state={state}
				setState={setState}
				loadOptions={loadOptions}
				formatGroupLabel={formatGroupLabel}
				formateOptionLabel={formateOptionLabel}
			/>
		</div>
	)
}
