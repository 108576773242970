import { useAccountStore } from 'store/account-store'
import { useAdminStore } from 'store/admin-store'
import { useAssetStore } from 'store/asset-store'
import { useBillingStore } from 'store/billing-store'
import { useGeneralStore } from 'store/general-store'
import { useUserStore } from 'store/user-store'

export const useStores = () => ({
	accountStore: useAccountStore(),
	assetStore: useAssetStore(),
	userStore: useUserStore(),
	billingStore: useBillingStore(),
	adminStore: useAdminStore(),
	generalStore: useGeneralStore()
})
