import { GTooltip } from 'components/basic-blocks'
import { GMetricLine } from 'components/basic-blocks/g-metric-line/g-metric-line'
import { SimpleBarChart } from 'components/charts/simple-bar-chart'
import { RiQuestionLine } from 'react-icons/ri'
import { color } from 'utils/colors'
import { ChurnPanelProps } from './types'

export const ChurnPanel = ({ data, percentages }: ChurnPanelProps) => {
	return (
		<section className="bg-white mt-6 px-4 py-4 text-t-default divide-y divide-gray-100 rounded">
			<div className="pb-2">
				<SimpleBarChart
					data={[
						{
							id: 'acquisition',
							'Cancelled setups': data.cancelled_setups,
							'Cancelled trials': data.cancelled_trials,
							'Cancelled subscriptions': data.cancelled_subscriptions
						}
					]}
					keys={[
						'Cancelled setups',
						'Cancelled trials',
						'Cancelled subscriptions'
					]}
					colors={[color('red', 300), color('red', 400), color('red', 600)]}
					format="number"
					title={
						<div className="text-t-default text-md flex flex-row items-center gap-x-1">
							<span className="font-bold">Churn</span>
							<GTooltip
								position="right"
								content={
									<div className="flex flex-col">
										<span>
											Statistics about lost and cancelled accounts during the
											selected period
										</span>
									</div>
								}
							>
								<RiQuestionLine className="w-4 h-4 text-t-dark" />
							</GTooltip>
						</div>
					}
				>
					<div className="flex flex-col h-full mt-4">
						<GMetricLine
							title="Cancelled setups"
							bulletColor="bg-red-300"
							value={data.cancelled_setups_formatted}
							rate={percentages.cancelled_setups}
						/>
						<GMetricLine
							title="Cancelled trials"
							bulletColor="bg-red-400"
							value={data.cancelled_trial_formatted}
							rate={percentages.cancelled_trials}
						/>
						<GMetricLine
							title="Cancelled subscriptions"
							bulletColor="bg-red-600"
							value={data.cancelled_subscription_formatted}
							rate={percentages.cancelled_subscriptions}
						/>
					</div>
				</SimpleBarChart>
			</div>
			<div className="pt-4 flex flex-col gap-2">
				<GMetricLine
					title="Trial churn rate"
					value={`${data.trial_churn_rate}%`}
					rate={percentages.trial_churn_rate}
				/>
				<GMetricLine
					title="Subscription churn rate"
					value={`${data.subscription_churn_rate}%`}
					rate={percentages.subscription_churn_rate}
				/>
			</div>
		</section>
	)
}
