import { ProductAccountDTO } from 'api-models'
import {
	GAdminDashboardTable,
	GLoading,
	GSection
} from 'components/basic-blocks'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { RiErrorWarningLine } from 'react-icons/ri'
import { useUiStore } from 'store'
import { columns } from './columns'

export const OverduePaymentsTab = () => {
	const { adminStore } = useStores()
	const { loadAccounts } = adminStore
	const isLoading = useUiStore((s) => s.isLoading)

	const [state, setState] = useState<any>({})
	const [skip, setSkip] = useState(0)
	const [query, setQuery] = useState({
		payment_overdue: true,
		account_status: 'ACTIVE',
		sort: ''
	})

	useEffect(() => {
		;(async () => {
			const accounts = await loadAccounts({ ...query, skip })
			setState({ accounts })
		})()
	}, [query.sort, skip])

	const handleSort = (key: string) => {
		setQuery({ ...query, sort: key })
	}

	if (!state.accounts) return <GLoading />

	return (
		<>
			<GSection className="" loading={isLoading('ADMIN_GET_ACCOUNTS')}>
				<GAdminDashboardTable<ProductAccountDTO>
					refreshAction={async () => {
						const accounts = await loadAccounts(query)
						setState({ accounts })
					}}
					title="Overdue payments"
					columns={columns}
					data={state.accounts.data}
					pageSize={state.pageSize}
					sortListener={handleSort}
					sort={query.sort}
					skip={skip}
					setSkip={setSkip}
					totalCount={state.accounts.total}
					emptyState={
						<div className="flex flex-col items-center justify-center w-full p-8 text-center">
							<RiErrorWarningLine className="w-8 h-8 text-gray-500" />
							<h3 className="mt-2 text-md font-medium text-gray-700">
								No data
							</h3>
							{/* <p className="mt-1 text-sm text-gray-500">Select a different time period</p> */}
						</div>
					}
				/>
			</GSection>
		</>
	)
}
