import { marked } from 'marked'
import { Dispatch } from 'react'

export interface NotesListProps {
	topicId: string
	type: 'workspace' | 'website' | 'ppc-account' | 'user'
	total: number
	setTotal: (total: number) => void
}

export type AddingCommentProps = {
	value: string
	onChange: Dispatch<string>
	postNote: () => void
	error: string
}

export const createMarkUp = (val: string) => {
	return { __html: marked(val, { breaks: true }) }
}
