import { forwardRef, useEffect, useState } from 'react'
import { useTable } from 'react-table'
import { TableVirtuoso } from 'react-virtuoso'
import { classNames } from '../../../utils'
import { GPagination } from './g-pagination'
import { TableBody } from './table-body'
import TableHeader from './table-header/table-header'
import { GTableProps, GTableState } from './types'

export function GTable<T>(props: GTableProps<T>) {
	const {
		columns,
		data,
		pageSize,
		showHeader = true,
		rowClassName,
		emptyState,
		totalCount,
		pageListener,
		sortListener,
		sort
	} = props

	const [state, setState] = useState<GTableState>({
		page: 1,
		sortPath: null
	})
	const [rowData, setRowData] = useState<Array<any>>([])
	const [columnData, setColumnData] = useState<Array<any>>([])

	useEffect(() => {
		handlePageChange(state.page)
	}, [data])

	const handlePageChange = (page: number) => {
		setState({ ...state, page })
	}

	const handleSort = (key: string) => {
		setState({ ...state, page: 1 })
		sortListener && sortListener(key)
	}

	const getTableHeader = () => {
		return columns.map((column, index) => {
			return {
				accessor: `col${index + 1}`
			}
		})
	}
	const handleData = () => {
		const columnData = []
		let singleData = {}
		for (let i = 0; i < data.length; i++) {
			for (let j = 0; j < columns.length; j++) {
				singleData = {
					...singleData,
					[`col${j + 1}`]: data[i]
				}
			}
			columnData.push(singleData)
			singleData = {}
		}
		return columnData
	}

	useEffect(() => {
		if (data.length > 0) {
			setRowData(handleData())
			setColumnData(getTableHeader())
		} else {
			setRowData([])
		}
	}, [data, columns])

	const tableInstance = useTable({ columns: columnData, data: rowData })
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		tableInstance

	return (
		<div className="mb-1 flex flex-col">
			<div className="-mt-6 overflow-x-auto rounded-lg -mx-4 sm:-mx-5 lg:-mx-6">
				<div className="inline-block min-w-full align-middle">
					{rows?.length > 0 && (
						<TableVirtuoso
							useWindowScroll
							totalCount={rows.length}
							components={{
								Table: ({ style, ...props }) => (
									<table
										className="min-w-full table-auto divide-y divide-gray-200"
										{...getTableProps()}
										{...props}
										style={{ ...style, tableLayout: 'fixed' }}
									/>
								),
								TableBody: forwardRef(({ style, ...props }, ref) => (
									<tbody
										className={classNames(
											showHeader ? 'divide-y divide-gray-200' : ''
										)}
										{...getTableBodyProps()}
										{...props}
										ref={ref}
									/>
								)),
								TableHead: forwardRef(({ style, ...props }, ref) => (
									<thead
										className="bg-gray-50 sticky top-0 z-5 text-t-secondary"
										{...props}
										ref={ref}
									/>
								)),
								TableRow: (props) => {
									const index = props['data-index']
									const row = rows[index]
									return (
										<tr
											{...props}
											{...row.getRowProps()}
											className={
												rowClassName
													? rowClassName(data[index])
													: 'divide-x divide-gray-200'
											}
										/>
									)
								}
							}}
							fixedHeaderContent={() => {
								if (showHeader) {
									return headerGroups.map((headerGroup) => (
										<tr
											{...headerGroup.getHeaderGroupProps()}
											className="divide-x divide-gray-200"
										>
											{headerGroup.headers.map((column, index) => (
												<TableHeader
													key={index}
													column={columns[index]}
													reactColumn={column}
													sort={sort}
													onSort={handleSort}
												/>
											))}
										</tr>
									))
								}
							}}
							itemContent={(index) => {
								const row = rows[index]
								prepareRow(row)
								return row.cells.map((cell, index) => {
									return (
										<TableBody
											key={index}
											cell={cell}
											column={columns[index]}
										/>
									)
								})
							}}
						/>
					)}
					{data?.length === 0 &&
						(emptyState || (
							<div className="text-center py-4">
								<span className="text-md text-gray-500">
									Nothing to show here
								</span>
							</div>
						))}
				</div>
			</div>
			{rows?.length > 0 && pageSize && (
				<GPagination
					itemsCount={totalCount ? totalCount : data?.length}
					pageSize={pageSize}
					currentPage={state.page}
					onPageChange={handlePageChange}
					pageListener={pageListener}
				/>
			)}
		</div>
	)
}
