import {
	GButton,
	GInput,
	GListBox,
	GMultiSelect,
	GTransition
} from 'components/basic-blocks'
import { OnBoarding } from 'components/onboarding/onboarding'
import { usePostHog } from 'posthog-js/react'
import {
	AdvertisingPlatforms,
	ConfigSectorItem,
	ConfigValueItem,
	IndustryTypes,
	SectorTypes
} from 'config'
import { useState } from 'react'
import { StartStandardViewProps } from './types'

export const StartStandardView = ({
	state,
	setDomain,
	setSector,
	setIndustry,
	setPlatforms,
	setSpend,
	onNext,
	setStep,
	handleBack
}: StartStandardViewProps) => {
	const [errors, setErrors] = useState<string[]>([])
	const posthog = usePostHog()


	const submit = () => {
		const err = []
		if (!state.domain) err.push('domain')
		if (state.spend && state.spend < 1000) err.push('spend')
		setErrors(err)
		if (err.length === 0) {
			posthog?.capture('click-on-cta-onboarding-screen-2')
			onNext()
		}
	}

	return (
		<OnBoarding
			state={state}
			setStep={setStep}
			handleBack={handleBack}
			title={
				<div className="text-3xl text-[#FFF] font-[800]">
					Tell us about your <span className="text-green-400">business</span>{' '}
				</div>
			}
			description="We’ll use this information to create your workspace and provide
								you with smooth integration."
		>
			<GTransition show={state.step === 'workspace'} swap>
				<GInput
					className="py-3"
					labelClassName="text-t-default mb-1 text-md font-bold"
					label="What is your main business domain?"
					inputClassName="sm:text-sm placeholder:text-sm placeholder:text-t-default text-t-default w-full"
					placeholder="example.com"
					value={state.domain}
					disabled={state.loading}
					error={
						errors.includes('domain')
							? 'You need to input a domain name'
							: undefined
					}
					onChange={(e) => setDomain(e.target.value)}
				/>
				{state.error.domain && (
					<p className=" text-xs text-red-700">
						A workspace with this name{' '}
						<a
							className="underline"
							href={`/workspace/${state.error.domain}/asset/website-demo/`}
						>
							{' '}
							already exists.
						</a>
					</p>
				)}
				<div className="py-3">
					<span className="block text-t-default mb-1 text-md font-bold">
						What is your primary industry?
					</span>
					<div className="flex flex-col">
						<div className="flex items-center gap-x-3 w-full">
							<div className="w-[14rem]">
								<GListBox<ConfigSectorItem>
									className="text-t-default"
									renderLabel={(s) => s.label}
									value={state.sector}
									disabled={state.loading}
									placeholder="Sector"
									options={SectorTypes}
									onChange={(e) => setSector(e)}
								/>
							</div>
							<div className="w-[14rem]">
								<GListBox<ConfigSectorItem>
									className="text-t-default"
									renderLabel={(s) => s.label}
									value={
										state.industry?.sectorId !== state.sector?.sectorId
											? undefined
											: state.industry
									}
									disabled={state.loading || !state.sector}
									placeholder="Industry"
									options={IndustryTypes.filter(
										(item) => item.sectorId === state.sector?.sectorId
									)}
									onChange={(e) => setIndustry(e)}
								/>
							</div>
						</div>
						{(errors.includes('sector') || errors.includes('industry')) && (
							<p className="mt-1 text-xs text-red-700">
								You need to select a sector and industry
							</p>
						)}
					</div>
				</div>
				<GMultiSelect<ConfigValueItem>
					loopKey="value"
					className="py-3 text-t-default"
					labelClassName="text-t-default mb-1 text-md font-bold"
					label="What platforms do you advertise on?"
					renderLabel={(s) => s.label}
					placeholder="Google Ads, Meta"
					disabled={state.loading}
					renderSelected={(s) =>
						s.map((i: ConfigValueItem) => i.label).join(', ')
					}
					error={
						errors.includes('platform')
							? 'You need to select at least one advertising platform'
							: undefined
					}
					value={state.platforms || []}
					options={AdvertisingPlatforms}
					onChange={(e) => setPlatforms(e)}
				/>
				<GInput
					label="What is your total monthly advertising spend? (USD)"
					labelClassName="text-t-default mb-1 text-md font-bold"
					inputClassName="sm:text-sm placeholder:text-sm placeholder:text-t-default text-t-default w-full"
					placeholder="$10,000"
					className="py-3"
					type="number"
					value={state.spend || ''}
					disabled={state.loading}
					error={
						errors.includes('spend')
							? 'You need to set your advertising spend (minimum $1000)'
							: undefined
					}
					step={1000}
					onChange={(e) =>
						setSpend(Number(e.target.value) <= 0 ? 0 : Number(e.target.value))
					}
				/>
				<div>
					<GButton
						color="primary"
						iconPlacement="right"
						className="mt-8 w-full"
						onClick={submit}
						disabled={state.loading}
						loading={state.loading}
					>
						<span className="px-4">Continue</span>
					</GButton>
				</div>
				<GTransition show={state.loading}>
					<div className="flex justify-center text-sm">
						Preparing your workspace...
					</div>
				</GTransition>
			</GTransition>
		</OnBoarding>
	)
}
