import { GTooltip } from 'components/basic-blocks'
import { GMetricLine } from 'components/basic-blocks/g-metric-line/g-metric-line'
import { SimpleBarChart } from 'components/charts/simple-bar-chart'
import { RiQuestionLine } from 'react-icons/ri'
import { color } from 'utils/colors'
import { AcquisitionPanelProps } from './types'

export const AcquisitionPanel = ({
	data,
	percentages
}: AcquisitionPanelProps) => {
	return (
		<section className="bg-white px-4 py-4 text-t-default divide-y divide-gray-100 rounded">
			<div>
				<SimpleBarChart
					data={[
						{
							id: 'acquisition',
							'Total signups': data.total_signups,
							'Confirmed signups': data.confirmed_signups
						}
					]}
					keys={['Total signups', 'Confirmed signups']}
					colors={['#7ba7fe', '#5a8dfd']}
					format="number"
					title={
						<div className="text-t-default text-md flex flex-row items-center gap-x-1">
							<span className="font-bold">Acquisition</span>
							<GTooltip
								position="right"
								content={
									<div className="flex flex-col">
										<span>Acquisition statistics for the selected period,</span>
										<span>
											signed up users distribution and workspace status funnel.
										</span>
									</div>
								}
							>
								<RiQuestionLine className="w-4 h-4 text-t-dark" />
							</GTooltip>
						</div>
					}
				>
					<div className="flex flex-col h-full mt-4">
						<GMetricLine
							title="Total signups"
							bulletColor="bg-primary-300"
							value={data.total_signups_formatted}
							rate={percentages.total_signups}
						/>
						<GMetricLine
							title="Confirmed signups"
							bulletColor="bg-primary-400"
							value={data.confirmed_signups_formatted}
							rate={percentages.confirmed_signups}
						/>
						<GMetricLine
							title="New signups"
							bulletColor="bg-white"
							value={data.new_signups_formatted}
							rate={percentages.new_signups}
						/>
						<GMetricLine
							title="Team additions"
							bulletColor="bg-white"
							value={data.team_addition_formatted}
							rate={percentages.team_additions}
						/>
					</div>
				</SimpleBarChart>
			</div>
			<div className="py-2">
				<SimpleBarChart
					data={[
						{
							id: 'workspaces',
							'New setups': data.new_setups,
							'New trials': data.new_trials,
							'New subscriptions': data.new_subscriptions
						}
					]}
					keys={['New setups', 'New trials', 'New subscriptions']}
					colors={[
						color('green', 300),
						color('green', 400),
						color('green', 600)
					]}
					format="number"
				>
					<div className="flex flex-col h-full mt-4">
						<GMetricLine
							title="New setups"
							bulletColor="bg-green-300"
							value={data.new_setups_formatted}
							rate={percentages.new_setups}
						/>
						<GMetricLine
							title="First time setups"
							bulletColor="bg-white"
							value={data.first_time_setups_formatted}
							rate={percentages.first_time_setups}
						/>
						<GMetricLine
							title="New trials"
							bulletColor="bg-green-400"
							value={data.new_trials_formatted}
							rate={percentages.new_trials}
						/>
						<GMetricLine
							title="First time trials"
							bulletColor="bg-white"
							value={data.first_time_trials_formatted}
							rate={percentages.first_time_trials}
						/>
						<GMetricLine
							title="New subscriptions"
							bulletColor="bg-green-600"
							value={data.new_subscriptions_formatted}
							rate={percentages.new_subscriptions}
						/>
						<GMetricLine
							title="First time subscriptions"
							bulletColor="bg-white"
							value={data.first_time_subscriptions_formatted}
							rate={percentages.first_time_subscriptions}
						/>
					</div>
				</SimpleBarChart>
			</div>
			<div className="pt-4 flex flex-col gap-2">
				<GMetricLine
					title="Signup confirmation ratio"
					value={`${data.singup_confirmation_ratio}%`}
					rate={percentages.singup_confirmation_ratio}
				/>
				<GMetricLine
					title="Signup to setup ratio"
					value={`${data.signup_setup_ratio}%`}
					rate={percentages.signup_setup_ratio}
				/>
				<GMetricLine
					title="Setup to trial ratio"
					value={`${data.setup_trial_ratio}%`}
					rate={percentages.setup_trial_ratio}
				/>
				<GMetricLine
					title="Trial to subscription ratio"
					value={`${data.trial_subscription_ratio}%`}
					rate={percentages.trial_subscription_ratio}
				/>
			</div>
		</section>
	)
}
