import { loadStripe } from '@stripe/stripe-js'
import {
	DiscountInfoDTO,
	OfferDTO,
	OfferRequestDTO,
	PaymentMethodDTO,
	PricingTierInfoDTO,
	SubscriptionDTO
} from 'api-models'
import { create } from 'zustand'

import { StripeConfig } from 'config'
import { Offers, PaymentMethods, ProductAccounts, System } from 'services/user'
import { useUiStore } from 'store/ui-store'
import { parseError } from 'utils/errors'

interface BillingStoreState {
	stripe: any
	plans: Array<PricingTierInfoDTO>
	paymentMethods: Array<PaymentMethodDTO>
	offers: Array<OfferDTO>
	offersRequests: Array<OfferRequestDTO>
	intent: any
	setPlans: (plans: Array<PricingTierInfoDTO>) => void
	setIntent: (intent: any) => void
	setPaymentMethods: (methods: Array<PaymentMethodDTO>) => void
	setOffers: (offers: Array<OfferDTO>) => void
	setOfferRequests: (reqs: Array<OfferRequestDTO>) => void

	initBilling: () => Promise<void>
	getPaymentIntent: (accountId: string, piid: string) => Promise<any>
	fetchCoupon: (code: string) => Promise<DiscountInfoDTO>
	getPaymentMethods: () => Promise<void>
	addPaymentMethod: (
		pmData: string,
		account_id: string | null
	) => Promise<PaymentMethodDTO | undefined>
	removePaymentMethod: (id: string) => Promise<void>
	loadOffers: () => Promise<void>
	acceptOffer: (
		id: string,
		schedule_subscription_change: boolean,
		pmId?: string,
		name?: string,
		isInSetup?: boolean
	) => Promise<SubscriptionDTO | null | undefined>
	rejectOffer: (id: string) => Promise<void>
	loadOfferRequests: () => Promise<void>
	createOfferRequest: (payload: {
		account_id: string | null
		spend: number
		interval: 'monthly' | 'yearly' | 'quarterly'
		notes: string | null
	}) => Promise<OfferRequestDTO | undefined>
	removeOfferRequest: (id: string) => Promise<void>
}

export const useBillingStore = create<BillingStoreState>((set, get) => ({
	stripe: loadStripe(StripeConfig.publicKey),
	intent: null,
	plans: [],
	paymentMethods: [],
	offers: [],
	offersRequests: [],
	setPlans: (plans: Array<PricingTierInfoDTO>) => set({ plans }),
	setIntent: (intent: any) => set({ intent }),
	setPaymentMethods: (methods: Array<PaymentMethodDTO>) =>
		set({ paymentMethods: methods }),
	setOffers: (offers: Array<OfferDTO>) => set({ offers }),
	setOfferRequests: (reqs: Array<OfferRequestDTO>) =>
		set({ offersRequests: reqs }),

	initBilling: async () => {
		useUiStore.getState().setActionState('ACCOUNT_SET_BILLING', 'loading')
		const plans = await System.getPricingPlans()
		const intent = await PaymentMethods.intent()
		set({ plans, intent })
		useUiStore.getState().setActionState('ACCOUNT_SET_BILLING', 'idle')
	},

	getPaymentIntent: async (accountId: string, piid: string) => {
		return await ProductAccounts.getPaymentIntent(accountId, piid)
	},

	fetchCoupon: async (code: string) => {
		useUiStore.getState().setActionState('ACCOUNT_GET_COUPON', 'loading')
		try {
			const coupon = await System.getCoupon(code)
			useUiStore.getState().setActionState('ACCOUNT_GET_COUPON', 'idle')
			return coupon
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_GET_COUPON',
					'error',
					err.response.data.message ? parseError(err) : 'Error fetching coupon'
				)
			throw parseError(err)
		}
	},

	getPaymentMethods: async () => {
		try {
			useUiStore
				.getState()
				.setActionState('USER_GET_PAYMENT_METHODS', 'loading')
			const methods = await PaymentMethods.find()
			useUiStore.getState().setActionState('USER_GET_PAYMENT_METHODS', 'idle')
			set({ paymentMethods: methods })
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_GET_PAYMENT_METHODS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error loading payment methods'
				)
		}
	},

	addPaymentMethod: async (
		pmData: string,
		account_id: string | null
	): Promise<PaymentMethodDTO | undefined> => {
		try {
			useUiStore.getState().setActionState('USER_ADD_PAYMENT_METHOD', 'loading')
			const pm = await PaymentMethods.create({
				pm_data: pmData,
				account_id: account_id
			})
			get().getPaymentMethods()
			useUiStore
				.getState()
				.setActionState(
					'USER_ADD_PAYMENT_METHOD',
					'success',
					'Payment method successfully added'
				)
			return pm
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_ADD_PAYMENT_METHOD',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error adding payment methods'
				)
		}
	},

	removePaymentMethod: async (id: string) => {
		try {
			useUiStore
				.getState()
				.setActionState('USER_REMOVE_PAYMENT_METHOD', 'loading')
			await PaymentMethods.remove(id)
			await get().getPaymentMethods()
			useUiStore
				.getState()
				.setActionState(
					'USER_REMOVE_PAYMENT_METHOD',
					'success',
					'Payment method successfully removed'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_REMOVE_PAYMENT_METHOD',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error removing payment methods'
				)
		}
	},

	loadOffers: async () => {
		try {
			useUiStore.getState().setActionState('USER_GET_OFFERS', 'loading')
			const offers = await Offers.find()
			set({ offers })
			useUiStore.getState().setActionState('USER_GET_OFFERS', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_GET_OFFERS',
					'error',
					err.response.data.message ? parseError(err) : 'Error loading offers'
				)
		}
	},

	acceptOffer: async (
		id: string,
		schedule_subscription_change: boolean,
		pmId?: string,
		name?: string,
		isInSetup?: boolean
	): Promise<SubscriptionDTO | null | undefined> => {
		try {
			useUiStore.getState().setActionState('USER_ACCEPT_OFFER', 'loading')
			const offer = await Offers.acceptOffer(id, {
				pm_id: pmId,
				org_name: name,
				is_in_setup: isInSetup,
				schedule_subscription_change
			})
			const subscription = offer?.account_id
				? await ProductAccounts.getSubscriptionByAccountId(offer?.account_id)
				: null
			if (subscription?.status === 'ACTIVE') {
				const offers = await Offers.find()
				set({ offers })
			}
			useUiStore
				.getState()
				.setActionState('USER_ACCEPT_OFFER', 'success', 'Offer accepted!')
			return subscription
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_ACCEPT_OFFER',
					'error',
					err.response.data.message ? parseError(err) : 'Error accepting offers'
				)
		}
	},

	rejectOffer: async (id: string) => {
		try {
			useUiStore.getState().setActionState('USER_REMOVE_OFFER', 'loading')
			await Offers.rejectOffer(id)

			const offers = await Offers.find()
			set({ offers })

			useUiStore
				.getState()
				.setActionState('USER_REMOVE_OFFER', 'success', 'Offer rejected!')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_REMOVE_OFFER',
					'error',
					err.response.data.message ? parseError(err) : 'Error rejecting offers'
				)
		}
	},

	loadOfferRequests: async () => {
		useUiStore.getState().setActionState('USER_GET_OFFER_REQUEST', 'loading')
		try {
			const reqs = await Offers.findRequests()
			set({ offersRequests: reqs })
			useUiStore.getState().setActionState('USER_GET_OFFER_REQUEST', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_GET_OFFER_REQUEST',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error loading offer request'
				)
		}
	},

	createOfferRequest: async (payload: {
		account_id: string | null
		spend: number
		interval: 'monthly' | 'yearly' | 'quarterly'
		notes: string | null
	}) => {
		try {
			useUiStore.getState().setActionState('USER_ADD_OFFER_REQUEST', 'loading')
			const req = await Offers.createOfferRequest({ ...payload, tier: 'pro' })
			useUiStore
				.getState()
				.setActionState('USER_ADD_OFFER_REQUEST', 'success', 'Request sent!')
			await get().loadOfferRequests()
			return req
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_ADD_OFFER_REQUEST',
					'error',
					err.response.data.message
						? err.response.data.message === 'The minimum spend is $25000'
							? 'Requested ad spend is covered by our standard pricing. Open the Billing page and select the plan with the required ad spend.'
							: parseError(err)
						: 'Error creating offer request'
				)
		}
	},

	removeOfferRequest: async (id: string) => {
		try {
			useUiStore
				.getState()
				.setActionState('USER_REMOVE_OFFER_REQUEST', 'loading')
			await Offers.removeOfferRequest(id)

			const reqs = await Offers.findRequests()
			set({ offersRequests: reqs })
			useUiStore
				.getState()
				.setActionState(
					'USER_REMOVE_OFFER_REQUEST',
					'success',
					'Offer request removed'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_REMOVE_OFFER_REQUEST',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error removing offer request'
				)
		}
	}
}))
