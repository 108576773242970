import { classNames } from 'utils'
import { FormattedAdminRevenueStatsDTO } from '../../types'

export const columns = [
	{
		label: '',
		className: 'justify-start',
		render: (row: FormattedAdminRevenueStatsDTO) => (
			<div className="flex items-center">
				<div
					className={classNames(
						getTierTypeColor(row.tier_type),
						'h-3 w-3 rounded-full mr-1'
					)}
				/>
				<span className="font-semibold text-sm">
					{row.tier_type_capitalized}
				</span>
			</div>
		)
	},
	{
		label: 'Average MRR',
		render: (row: FormattedAdminRevenueStatsDTO) =>
			rowItem(row.average_mrr_in_currency)
	},
	{
		label: 'ARPU',
		render: (row: FormattedAdminRevenueStatsDTO) =>
			rowItem(row.arpu_in_currency)
	},
	{
		label: 'Gained MRR',
		render: (row: FormattedAdminRevenueStatsDTO) =>
			rowItem(row.gained_mrr_in_currency)
	},
	{
		label: 'Upgrade',
		render: (row: FormattedAdminRevenueStatsDTO) =>
			rowItem(row.upgrade_mrr_in_currency)
	},
	{
		label: 'Returning',
		render: (row: FormattedAdminRevenueStatsDTO) =>
			rowItem(row.returning_mrr_in_currency)
	},
	{
		label: 'New',
		render: (row: FormattedAdminRevenueStatsDTO) =>
			rowItem(row.new_mrr_in_currency)
	},
	{
		label: 'Churned MRR',
		render: (row: FormattedAdminRevenueStatsDTO) =>
			rowItem(row.churned_mrr_in_currency)
	},
	{
		label: 'Downgrade',
		render: (row: FormattedAdminRevenueStatsDTO) =>
			rowItem(row.downgrade_mrr_in_currency)
	},
	{
		label: 'Cancelled',
		render: (row: FormattedAdminRevenueStatsDTO) =>
			rowItem(row.cancelled_mrr_in_currency)
	}
]

const rowItem = (value: string) => <span>{value}</span>

const getTierTypeColor = (tier_type: string) => {
	switch (tier_type) {
		case 'lite':
			return 'bg-red-400'
		case 'standard':
			return 'bg-green-400'
		case 'pro':
			return 'bg-blue-400'
		case 'legacy':
			return 'bg-amber-400'
		default:
			return 'bg-purple-400'
	}
}
