"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetectionTag = exports.ClickStatus = void 0;
var ClickStatus;
(function (ClickStatus) {
    ClickStatus["ORGANIC"] = "ORGANIC";
    ClickStatus["GOOD"] = "GOOD";
    ClickStatus["SUSPICIOUS"] = "SUSPICIOUS";
    ClickStatus["BAD"] = "BAD";
    ClickStatus["IGNORED"] = "IGNORED";
    ClickStatus["WHITELSITED"] = "WHITELSITED";
})(ClickStatus = exports.ClickStatus || (exports.ClickStatus = {}));
var DetectionTag;
(function (DetectionTag) {
    // malicious
    DetectionTag["CLICKFARM"] = "CLICKFARM";
    DetectionTag["COMPETITOR"] = "COMPETITOR";
    DetectionTag["ABUSIVE"] = "ABUSIVE";
    DetectionTag["BOTS"] = "BOTS";
    DetectionTag["THREAT"] = "THREAT";
    // waste
    DetectionTag["BOUNCED"] = "BOUNCED";
    DetectionTag["OUTSIDE_GEO"] = "OUTSIDE_GEO";
    DetectionTag["DISRUPTIVE"] = "DISRUPTIVE";
    DetectionTag["UNWANTED"] = "UNWANTED";
})(DetectionTag = exports.DetectionTag || (exports.DetectionTag = {}));
