import { GButton, GSection } from '../../../../components/basic-blocks'
import { AFFILIATE_BRANDING_RESOURCES_URL } from '../../../../utils/app-links'

export const AffiliateResources = () => {
	return (
		<div className="max-w-6xl">
			<GSection
				titleClass="pb-4 pt-2"
				lineBreak={true}
				title="Access Branding Resources to promote ClickGUARD"
			>
				<p className="text-base py-4">
					You’ll find a record of our branding guidelines, insights on how to
					thrive as an affiliate marketer, and resources that will help you
					create content that will get prospects clicking on your affiliate
					link.
				</p>
				<div className="flex justify-start items-center pb-1">
					<GButton
						variant="contained"
						label="Access Google Drive"
						color="primary"
						labelClassName="text-sm font-bold"
						onClick={() => {
							window.open(AFFILIATE_BRANDING_RESOURCES_URL, '_blank')
						}}
					/>
				</div>
			</GSection>
		</div>
	)
}
