import {
	AccountSetupInfoDTO,
	AdAccountDTO,
	GoogleAdsAdAccountDTO
} from 'api-models'

import { ActivateAccountNotice } from './notices/activate-account'
import { DemoDataNotice } from './notices/demo-data'

import { FinishChecklistNotice } from './notices/finish-checklish'
import { GNoticeType } from './types'
import { InactiveAccountNotice } from './notices/inactive-account'
import { CompletedNotice } from './notices/completed-notice'
import { MccLinkRequestNotice } from './notices/mcc-link-request-notice'
import { AdAccounts } from '../../../services/user'
import { toast } from 'react-toastify'

export const buildDemoDataNotice = (demoMode: boolean): GNoticeType[] | [] => {
	if (demoMode) {
		if (!sessionStorage.getItem('finish-checklist')) {
			return [
				{
					key: 'in-setup',
					type: 'critical',
					notice: <FinishChecklistNotice />,
					onDismiss: () => {
						sessionStorage.setItem('finish-checklist', 'true')
					}
				}
			]
		}
		return [
			{
				notice: <DemoDataNotice />,
				key: 'demo-data',
				type: 'critical'
			}
		]
	}

	return []
}

interface BuildInSetupDemoDataNoticeProps {
	step?: AccountSetupInfoDTO['step']
	accountId?: string
}

export const buildInSetupDemoDataNotice = ({
	step
}: BuildInSetupDemoDataNoticeProps): any => {
	if (!step) return []

	if (step === 'subscription') {
		return [
			{
				key: 'in-setup-last-step',
				type: 'critical',
				hideDismiss: true,
				notice: <ActivateAccountNotice />
			}
		]
	}

	if (!sessionStorage?.getItem('finish-checklist')) {
		return [
			{
				key: 'in-setup',
				type: 'critical',
				notice: <FinishChecklistNotice />,
				onDismiss: () => {
					sessionStorage.setItem('finish-checklist', 'true')
				}
			}
		]
	}
	return []
}

export const buildInActiveNotice = ({
	accountId
}: {
	accountId: any
}): GNoticeType[] => {
	return [
		{
			key: 'in-active',
			type: 'critical',
			hideDismiss: true,
			notice: <InactiveAccountNotice accountId={accountId} />
		}
	]
}

export const buildCompletedNotice = (): GNoticeType[] => {
	return [
		{
			key: 'done',
			type: 'warning',
			notice: <CompletedNotice />
		}
	]
}
export const buildMccLinkRequestNotice = (
	allAdAccounts: AdAccountDTO[],
	accountId?: string
): GNoticeType[] => {
	const mccLinkInviteAdAccounts = allAdAccounts.filter(
		(a: any) =>
			a?.platform === 'GADS' && a?.details?.mcc_support_connection !== 'LINKED'
	) as GoogleAdsAdAccountDTO[]

	if (!mccLinkInviteAdAccounts.length) return []
	let state:
		| 'PENDING'
		| 'SKIPPED'
		| 'SENDING_LINK_REQUEST'
		| 'LINK_REQUEST_PENDING_ACCEPTANCE'
		| 'SUPPRESSED_LINK_REQUEST_ALERT' = 'PENDING'
	let adAccounts: GoogleAdsAdAccountDTO[] = []

	const pendingAcceptance = mccLinkInviteAdAccounts.filter(
		(a) =>
			a.details.mcc_support_connection === 'LINK_REQUEST_PENDING_ACCEPTANCE'
	)
	const pending = mccLinkInviteAdAccounts.filter(
		(a) => a.details.mcc_support_connection === 'PENDING'
	)

	const skipped = mccLinkInviteAdAccounts.filter(
		(a) => a.details.mcc_support_connection === 'SKIPPED'
	)

	if (pendingAcceptance.length) {
		state = 'LINK_REQUEST_PENDING_ACCEPTANCE'
		adAccounts = pendingAcceptance
	}

	if (pending.length) {
		state = 'PENDING'
		adAccounts = [...pending, ...skipped]
	}

	if (!adAccounts?.length) {
		return []
	}
	const handleSkip = async () => {
		try {
			if (!accountId) return
			if (state === 'LINK_REQUEST_PENDING_ACCEPTANCE') {
				await AdAccounts.suppressMccSupportAll(accountId)
			}
			if (mccLinkInviteAdAccounts.length > 1) {
				await AdAccounts.skipMccSupportAll(accountId)
			} else if (mccLinkInviteAdAccounts[0]) {
				await AdAccounts.skipMccSupport(
					accountId,
					mccLinkInviteAdAccounts[0].id
				)
			}
		} catch (e) {
			toast.error('Something went wrong')
			console.error(e)
		}
	}

	return [
		{
			key: 'mcc_link_request',
			type: 'warning',
			notice: (
				<MccLinkRequestNotice
					mccLinkInviteAdAccounts={adAccounts}
					state={state}
				/>
			),
			onDismiss: handleSkip
		}
	]
}
