import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/solid'
import { useThemeStore } from 'layout/navbar/store'
import { ReactElement, useState } from 'react'
import { BsMeta } from 'react-icons/bs'
import { RiArrowDownSLine, RiGoogleLine } from 'react-icons/ri'
import { classNames } from 'utils'
import { defaultPlatformColor } from 'utils/colors'

interface GComboBoxProps<T> {
	options: T[]
	value?: T | ReactElement
	label?: string
	subtitle?: string
	name?: string
	onChange: (e: T) => void
	onInputChange?: (e: string) => void
	filter: (i: T, s: string) => boolean
	renderValue: (i: T) => string
	renderOption: (i: T) => ReactElement | Element | string
	disabled?: boolean
	error?: string
	placeholder?: string
	platform?: 'GADS' | 'FADS'
	className?: string
	inputClass?: string
}

export const GComboBox = <T,>(props: GComboBoxProps<T>) => {
	const {
		options,
		value,
		onChange,
		onInputChange,
		label,
		subtitle,
		disabled,
		filter,
		error,
		renderValue,
		renderOption,
		platform,
		inputClass,
		placeholder = 'Select',
		className
	} = props
	const [query, setQuery] = useState<string>('')
	const [open, setOpen] = useState<boolean>(false)

	const filteredOptions =
		query === '' ? options : options.filter((i) => filter(i, query))

	const { theme } = useThemeStore()

	return (
		<Combobox
			as="div"
			value={value}
			onChange={(e: T) => onChange(e)}
			disabled={disabled}
			className={`${open ? 'z-10 relative' : ''} ${className || ''}`}
		>
			{({ open }) => {
				setOpen(open)
				return (
					<>
						{label && (
							<Combobox.Label className="block text-md font-bold text-t-default">
								{label}
							</Combobox.Label>
						)}
						{subtitle && (
							<p className="text-sm mt-1 text-t-secondary">{subtitle}</p>
						)}
						<div
							className={classNames(
								'relative',
								label || subtitle ? 'mt-1' : ''
							)}
						>
							<div className="relative">
								{platform && (
									<Combobox.Button className="disabled:bg-gray-100 absolute inset-y-0 left-0 flex items-center rounded-r-md pl-3 focus:outline-none border-r-5">
										<div
											className={
												'flex items-center justify-center w-6 h-6 rounded-full '
											}
											style={{ background: defaultPlatformColor(platform) }}
										>
											{platform === 'GADS' ? (
												<RiGoogleLine className="w-4 h-4 text-white" />
											) : (
												<BsMeta className="w-4 h-4 text-white" />
											)}
										</div>
									</Combobox.Button>
								)}
								<Combobox.Input
									as="input"
									className={`disabled:bg-gray-100 w-full rounded-md border border-t-input-border bg-white py-2 ${
										platform ? 'pl-12' : 'pl:3'
									} ${
										platform ? 'pr-3' : 'pr-10'
									} shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 ${
										inputClass || 'sm:text-sm'
									}`}
									onChange={(event) => {
										setQuery(event.target.value)
										onInputChange && onInputChange(event.target.value)
									}}
									placeholder={placeholder}
									displayValue={value ? renderValue : () => query}
								/>
								<Combobox.Button className="disabled:bg-gray-100 absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
									<RiArrowDownSLine
										className="h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
								</Combobox.Button>
							</div>

							<Transition
								enter="transition ease-out duration-200"
								enterFrom="opacity-0 translate-y-1"
								enterTo="opacity-100 translate-y-0"
								leave={'transition ease-in duration-0'}
								leaveFrom={'opacity-100 translate-y-0'}
								leaveTo={'opacity-0 translate-y-1'}
							>
								{filteredOptions.length > 0 && (
									<Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
										{filteredOptions.map((option: T, i: number) => (
											<Combobox.Option
												key={i}
												value={option}
												className={({ active }) =>
													classNames(
														'relative cursor-default select-none py-2 pl-3 pr-9',
														active
															? `bg-primary-500 ${
																	theme === 'light'
																		? 'text-t-light'
																		: 'text-t-default'
															  }`
															: 'text-t-dark'
													)
												}
											>
												{({ active, selected }) => {
													return (
														<RenderActiveSelected
															active={active}
															selected={selected}
															renderOption={renderOption}
															option={option}
														/>
													)
												}}
											</Combobox.Option>
										))}
									</Combobox.Options>
								)}
							</Transition>
						</div>
						{error && (
							<p className="mt-1 text-sm text-red-500" id="email-description">
								{error}
							</p>
						)}
					</>
				)
			}}
		</Combobox>
	)
}

interface RenderActiveSelectedProps<T> {
	active: boolean
	selected: boolean
	renderOption: (i: T) => ReactElement | Element | string
	option: any
}

function RenderActiveSelected<T>(props: RenderActiveSelectedProps<T>) {
	const { active, selected, renderOption, option } = props

	return (
		<>
			<div className="flex items-center">
				<span
					className={classNames('truncate', `${selected && 'font-semibold'}`)}
				>
					{renderOption(option)}
				</span>
			</div>

			{selected && (
				<span
					className={classNames(
						'absolute inset-y-0 right-0 flex items-center pr-4',
						active ? 'text-white' : 'text-primary-600'
					)}
				>
					<CheckIcon className="h-5 w-5" aria-hidden="true" />
				</span>
			)}
		</>
	)
}
