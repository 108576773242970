import { ReactNode, useState } from 'react'
import { useUiStore } from 'store'
import { NoteDTO } from '../../../../api-models'
import { useStores } from '../../hooks'
import { GButton, GDialog } from '../basic-blocks'

export const EditNote = ({
	note,
	children,
	fetchData
}: {
	note: NoteDTO
	children: ReactNode
	fetchData: (s: number) => void
}) => {
	const {
		adminStore: { updateNoteById }
	} = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	const [value, setValue] = useState<string>(note.text)
	const [open, setOpen] = useState<boolean>(false)

	const handleSubmit = async () => {
		if (value && note.id) {
			await updateNoteById(note.id, value)
			fetchData(0)
			setOpen(false)
		}
	}

	return (
		<>
			<div onClick={() => setOpen(true)}>{children}</div>
			<GDialog
				title="Edit Note"
				open={open}
				onClose={() => setOpen(false)}
				maxWidth={'2xl'}
			>
				<div className="mt-6">
					<form>
						<div>
							<label htmlFor="comment" className="sr-only">
								About
							</label>
							<textarea
								id="comment"
								name="comment"
								value={value}
								onChange={(e) => setValue(e.target.value)}
								rows={15}
								cols={42}
								className="block bg-t-input-background w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
								placeholder="Add a note"
								disabled={
									isLoading('ADMIN_GET_NOTES') || isLoading('ADMIN_ADD_NOTE')
								}
							/>
						</div>
						<div className="mt-3 flex items-center justify-between">
							<GButton
								label="Comment"
								color="primary"
								loading={
									isLoading('ADMIN_UPDATE_NOTE') || isLoading('ADMIN_GET_NOTES')
								}
								onClick={handleSubmit}
							/>
						</div>
					</form>
				</div>
			</GDialog>
		</>
	)
}
