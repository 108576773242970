import { Notes } from 'components/notes'

export const NotesTab = (props: {
	topicId: string
	total: number
	setTotal: (total: number) => void
}) => {
	const { topicId, total, setTotal } = props
	return (
		<main className="py-4">
			<div className="max-w-3xl mx-auto px-4 sm:px-6 md:px-8">
				<Notes
					topicId={topicId}
					type="website"
					total={total}
					setTotal={setTotal}
				/>
			</div>
		</main>
	)
}
