import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { AffiliateService } from '../../../../services/user/affiliate'
import { AffiliateStats } from './affiliate-stats'
import { AffiliateTimeline } from './affiliate-timeline'
import { AffiliateUsers } from './affiliate-users'
import { AffiliatesPageFiltersContextProvider } from './context'
import { transformTimelineData } from './types'

export const AffiliateDashboard = ({
	dateRange
}: {
	dateRange: { from: Date; to: Date }
}) => {
	const stats = useQuery('affiliate-dashboard-stats', () =>
		AffiliateService.dashboardStats({})
	)

	const timeline = useQuery('affiliate-dashboard-timeline', () =>
		AffiliateService.dashboardTimeline({})
	)

	const hasData = useMemo(() => !!timeline.data?.total, [timeline.data?.total])

	return (
		<>
			<AffiliateStats data={stats.data} />
			<AffiliateTimeline
				hasData={hasData}
				data={transformTimelineData(timeline.data?.array)}
				loading={timeline.isLoading || timeline.isFetching}
			/>
			<AffiliatesPageFiltersContextProvider>
				<AffiliateUsers hasData={true} dateRange={dateRange} />
			</AffiliatesPageFiltersContextProvider>
		</>
	)
}
