import { UserDTO } from 'api-models'
import { GLoading } from 'components/basic-blocks'
import { EventsList } from 'components/events-list'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { Accounts } from './accounts-section'
import { Info } from './info'
import { OffersSection } from './offers-section'
import { AccountsReference, State } from './types'

export const InfoTab = (props: { user: UserDTO }) => {
	const { user } = props
	const { adminStore } = useStores()
	const { loadAccounts } = adminStore

	const [accounts, setAccounts] = useState<AccountsReference>({
		total: 0,
		data: []
	})
	const [allAccounts, setAllAccounts] = useState<AccountsReference>({
		total: 0,
		data: []
	})
	const [loading, setLoading] = useState(true)
	const [state, setState] = useState<State>({ skip: 0, pageSize: 5 })

	const pageListener = (page: number) => {
		setState({ ...state, skip: page })
	}

	useEffect(() => {
		;(async () => {
			try {
				const currentQuery = {
					skip: state.skip,
					limit: state.pageSize,
					user_id: user.id
				}
				const data = await loadAccounts(currentQuery)
				data && setAccounts(data || { total: 0, data: [] })
			} catch (err) {
				return
			} finally {
				setLoading(false)
			}
		})()
	}, [user, state.skip])

	useEffect(() => {
		;(async () => {
			try {
				const currentQuery = {
					user_id: user.id
				}
				const data = await loadAccounts(currentQuery)
				data && setAllAccounts(data || { total: 0, data: [] })
			} catch (err) {
				return
			}
		})()
	}, [])

	if (loading) return <GLoading />

	const hasActiveWorkspace = allAccounts.data.some(
		(account) => account.status === 'ACTIVE'
	)

	return (
		<div className="pt-2">
			<div className="flex flex-wrap gap-6">
				<div className="flex-1">
					<Info user={user} hasActiveWorkspace={hasActiveWorkspace} />
				</div>
				<div className="flex flex-1 flex-col">
					<OffersSection user={user} />
					<EventsList id={user.id} type="user" />
				</div>
			</div>
			<div className="w-full">
				<Accounts
					accounts={accounts.data}
					totalAccounts={accounts.total}
					pageListener={pageListener}
					pageSize={state.pageSize}
				/>
			</div>
		</div>
	)
}
