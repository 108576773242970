export const currencyList = [
	'AFN',
	'ALL',
	'DZD',
	'ARS',
	'AMD',
	'AUD',
	'AZN',
	'BHD',
	'BDT',
	'BYN',
	'BZD',
	'BOB',
	'BAM',
	'BWP',
	'BRL',
	'GBP',
	'BND',
	'BGN',
	'BIF',
	'KHR',
	'CAD',
	'CVE',
	'XAF',
	'CLP',
	'CNY',
	'COP',
	'KMF',
	'CDF',
	'CRC',
	'HRK',
	'CZK',
	'DKK',
	'DJF',
	'DOP',
	'EGP',
	'ERN',
	'EEK',
	'ETB',
	'EUR',
	'GEL',
	'GHS',
	'GTQ',
	'GNF',
	'HNL',
	'HKD',
	'HUF',
	'ISK',
	'INR',
	'IDR',
	'IRR',
	'IQD',
	'ILS',
	'JMD',
	'JPY',
	'JOD',
	'KZT',
	'KES',
	'KWD',
	'LVL',
	'LBP',
	'LYD',
	'LTL',
	'MOP',
	'MKD',
	'MGA',
	'MYR',
	'MUR',
	'MXN',
	'MDL',
	'MAD',
	'MZN',
	'MMK',
	'NAD',
	'NPR',
	'TWD',
	'NZD',
	'NIO',
	'NGN',
	'NOK',
	'OMR',
	'PKR',
	'PAB',
	'PYG',
	'PEN',
	'PHP',
	'PLN',
	'QAR',
	'RON',
	'RUB',
	'RWF',
	'SAR',
	'RSD',
	'SGD',
	'SOS',
	'ZAR',
	'KRW',
	'LKR',
	'SDG',
	'SEK',
	'CHF',
	'SYP',
	'TZS',
	'THB',
	'TOP',
	'TTD',
	'TND',
	'TRY',
	'USD',
	'UGX',
	'UAH',
	'AED',
	'UYU',
	'UZS',
	'VEF',
	'VND',
	'XOF',
	'YER',
	'ZMK',
	'ZWL'
]

export const currencySymbols: { [key: string]: string } = {
	AFN: 'Af',
	ALL: 'ALL',
	DZD: 'DA',
	ARS: 'AR$',
	AMD: 'AMD',
	AUD: 'AU$',
	AZN: 'man.',
	BHD: 'BD',
	BDT: 'Tk',
	BYN: 'Br',
	BZD: 'BZ$',
	BOB: 'Bs',
	BAM: 'KM',
	BWP: 'BWP',
	BRL: 'R$',
	GBP: '£',
	BND: 'BN$',
	BGN: 'BGN',
	BIF: 'FBu',
	KHR: 'KHR',
	CAD: 'CA$',
	CVE: 'CV$',
	XAF: 'FCFA',
	CLP: 'CL$',
	CNY: 'CN¥',
	COP: 'CO$',
	KMF: 'CF',
	CDF: 'CDF',
	CRC: '₡',
	HRK: 'kn',
	CZK: 'Kč',
	DKK: 'Dkr',
	DJF: 'Fdj',
	DOP: 'RD$',
	EGP: 'EGP',
	ERN: 'Nfk',
	EEK: 'Ekr',
	ETB: 'Br',
	EUR: '€',
	GEL: 'GEL',
	GHS: 'GH₵',
	GTQ: 'GTQ',
	GNF: 'FG',
	HNL: 'HNL',
	HKD: 'HK$',
	HUF: 'Ft',
	ISK: 'Ikr',
	INR: 'Rs',
	IDR: 'Rp',
	IRR: 'IRR',
	IQD: 'IQD',
	ILS: '₪',
	JMD: 'J$',
	JPY: '¥',
	JOD: 'JD',
	KZT: 'KZT',
	KES: 'Ksh',
	KWD: 'KD',
	LVL: 'Ls',
	LBP: 'LB£',
	LYD: 'LD',
	LTL: 'Lt',
	MOP: 'MOP$',
	MKD: 'MKD',
	MGA: 'MGA',
	MYR: 'RM',
	MUR: 'MURs',
	MXN: 'MX$',
	MDL: 'MDL',
	MAD: 'MAD',
	MZN: 'MTn',
	MMK: 'MMK',
	NAD: 'N$',
	NPR: 'NPRs',
	TWD: 'NT$',
	NZD: 'NZ$',
	NIO: 'C$',
	NGN: '₦',
	NOK: 'Nkr',
	OMR: 'OMR',
	PKR: 'PKRs',
	PAB: 'B/.',
	PYG: '₲',
	PEN: 'S/.',
	PHP: '₱',
	PLN: 'zł',
	QAR: 'QR',
	RON: 'RON',
	RUB: 'RUB',
	RWF: 'RWF',
	SAR: 'SR',
	RSD: 'din.',
	SGD: 'S$',
	SOS: 'Ssh',
	ZAR: 'R',
	KRW: '₩',
	LKR: 'SLRs',
	SDG: 'SDG',
	SEK: 'Skr',
	CHF: 'CHF',
	SYP: 'SY£',
	TZS: 'TSh',
	THB: '฿',
	TOP: 'T$',
	TTD: 'TT$',
	TND: 'DT',
	TRY: 'TL',
	USD: '$',
	UGX: 'USh',
	UAH: '₴',
	AED: 'AED',
	UYU: '$U',
	UZS: 'UZS',
	VEF: 'Bs.F.',
	VND: '₫',
	XOF: 'CFA',
	YER: 'YR',
	ZMK: 'ZK',
	ZWL: 'ZWL$'
}
