import { useState } from 'react'
import { TrialStartProps, TrialStartState } from '../types'
import { usePostHog } from 'posthog-js/react'

import { TrialStartView } from './view'

export const TrialStart = ({
	plan,
	loading,
	onConfirm,
	qualifiedTrial,
	back,
	isPlanChange,
	handleChangePlan
}: TrialStartProps) => {
	const [state, setState] = useState<TrialStartState>({
		pm_token: null,
		pm_id: null,
		coupon_code: plan.coupon_code,
		discount: plan.coupon
	})
	const posthog = usePostHog()

	const confirm = (token: string) => {
		posthog?.capture('click-on-start-free-trial')
		if (isPlanChange) {
			if (!token) return
			handleChangePlan(token)
		} else {
			if (!token && !qualifiedTrial) return
			onConfirm(
				{ ...state, pm_token: token || null },
				Boolean(qualifiedTrial && token)
			)
		}
	}

	const confirmAlternate = () => {
		if (!qualifiedTrial) return

		onConfirm({ ...state, pm_id: null }, false)
	}

	return (
		<TrialStartView
			state={state}
			setState={setState}
			loading={loading}
			plan={plan}
			qualifiedTrial={qualifiedTrial}
			back={back}
			onConfirm={confirm}
			onConfirmAlternate={confirmAlternate}
			isPlanChange={isPlanChange}
		/>
	)
}
