import { BasicTooltip } from '@nivo/tooltip'
import { SimpleBarChart } from 'components/charts/simple-bar-chart'
import { useStores } from 'hooks'
import { Fragment, useEffect, useState } from 'react'
import { formatCurrency, formatStatsCard } from 'utils'
import { StatsCardProps } from './types'

export const StatsCard = (props: StatsCardProps) => {
	const { dollarValue, data, link } = props

	const { adminStore, userStore } = useStores()
	const { user } = userStore

	const { getAdminStats } = adminStore
	const [state, setState] = useState<any>({})

	useEffect(() => {
		;(async () => {
			const adminStats = user?.admin && (await getAdminStats())
			setState(adminStats)
		})()
	}, [])

	const barData = [
		{
			id: 'AcquisitionParent',
			title: 'Acquisition',
			'Total signups': state?.total_signups || 0,
			'Confirmed signups': state?.confirmed_signups || 0,
			colors: ['#9fb3f9', '#4269f5']
		},
		{
			id: 'Acquisition',
			title: '',
			'In setup': state?.in_setup || 0,
			'In trial': state?.in_trial || 0,
			Subscriptions: state?.subscriptions || 0,
			Cancelling: state?.cancelling || 0,
			Inactive: state?.inactive || 0,
			colors: ['#b7ecca', '#71da98', '#4ba550', '#f5c150', '#ec607e']
		},
		{
			id: 'SubscriptionByPlans',
			title: 'Subscription by plans',
			Lite: state?.sub_lite || 0,
			Standard: state?.sub_standard || 0,
			Pro: state?.sub_pro || 0,
			Legacy: state?.sub_legacy || 0,
			Custom: state?.sub_custom || 0,
			colors: ['#da2f3a', '#5cc660', '#183cf2', '#f5c150', '#9749df']
		},
		{
			id: 'MRR by billing period',
			title: 'MRR by billing period',
			'Monthly plans': state?.mrr_monthly || 0,
			'Yearly plans': state?.mrr_yearly || 0,
			'Other plans': state?.mrr_other || 0,
			colors: ['#9fb3f9', '#4269f5', '#183cf2']
		},
		{
			id: 'Revenue',
			title: 'Revenue',
			'Monthly recurring': state?.monthly_recurring / 100 || 0,
			'Annual recurring': state?.annual_recurring || 0,
			colors: ['#9fb3f9', '#4269f5']
		},
		{
			id: 'MRR by subscription plan',
			title: 'MRR by subscription plan',
			Lite: state?.mrr_lite || 0,
			Standard: state?.mrr_standard || 0,
			Pro: state?.mrr_pro || 0,
			Custom: state?.mrr_custom || 0,
			colors: ['#da2f3a', '#5cc660', '#183cf2', '#9749df']
		},
		{
			id: 'MRR by subscription plan',
			title: 'MRR by subscription plan',
			colors: ['#da2f3a', '#5cc660', '#183cf2', '#9749df']
		}
	]

	const chartKeys = (id: string) => {
		const keys: any = []
		barData.map((item: any) => {
			if (item.id === id) {
				Object.keys(item).map((key: any) => {
					if (key !== 'title' && key !== 'colors' && key !== 'id') {
						keys.push(key)
					}
				})
			}
		})
		return keys
	}

	const chartData = (id: string) => {
		const data: any = []
		barData.map((item: any) => {
			if (item.id === id) {
				data.push(item)
			}
		})
		return data
	}

	const chartColors = (id: string) => {
		const colors: any = []
		barData.map((item: any) => {
			if (item.id === id) {
				Object.keys(item).map((key: any) => {
					if (key === 'colors') {
						colors.push(...item[key])
					}
				})
			}
		})
		return colors
	}

	const BarTooltip = (props: any) => {
		return (
			<div className="mx-4 absolute start-0 text-gray-500">
				<BasicTooltip
					id={props.id}
					value={
						dollarValue
							? formatCurrency(props.value)
							: formatStatsCard(props.value)
					}
					color={props.color}
					enableChip
				/>
			</div>
		)
	}

	return (
		<div className="flex flex-col space-y-3 bg-white p-4 text-sm rounded-md">
			{data.map((item: any, index: number) => (
				<Fragment key={index}>
					<div>
						<div className="flex justify-between">
							<div className="font-medium">{item.title}</div>
							{item.viewMore && (
								<div>
									<a
										href="#"
										className="text-blue-500 hover:text-blue-700 underline"
									>
										View more
									</a>
								</div>
							)}
						</div>
					</div>
					{item.bar && (
						<div className="w-full">
							<SimpleBarChart
								tooltip={BarTooltip}
								format={dollarValue ? 'dollar' : 'number'}
								data={chartData(item.id)}
								keys={chartKeys(item.id)}
								colors={chartColors(item.id)}
							/>
						</div>
					)}
					{item.subtitle.map((sub: any, index: number) => (
						<Fragment key={index}>
							<div className="flex justify-between">
								<div className="flex flex-row justify-center items-center space-x-1">
									<div
										className="w-3 h-3 rounded-full"
										style={{ background: chartColors(item.id)[index] }}
									></div>
									{link ? (
										<a
											href="#"
											className="text-primary-500 hover:text-primary-700 underline"
										>
											{sub.name}
										</a>
									) : (
										<p className="text-gray-500">{sub.name}</p>
									)}
								</div>
								{state && (
									<div className="font-bold">
										{dollarValue
											? formatCurrency(state[sub.label])
											: state[sub.label]}
									</div>
								)}
							</div>
						</Fragment>
					))}
					{item.bottomBorder && <div className="flex border-b-[1px]"></div>}
				</Fragment>
			))}
		</div>
	)
}
