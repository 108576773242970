import { PricingTierInfoDTO } from 'api-models'
import { GButton, GTransition } from 'components/basic-blocks'
import { useStores } from 'hooks'
import { useThemeStore } from 'layout/navbar/store'
import { usePostHog } from 'posthog-js/react'
import {
	RiArrowLeftLine,
	RiShieldCheckLine,
	RiShieldFlashLine,
	RiShieldLine,
	RiShieldStarLine
} from 'react-icons/ri'

import { TrialStartViewProps } from '../types'

import { Elements } from '@stripe/react-stripe-js'
import { PaymentMethodForm } from 'components/new-payment-method'
import moment from 'moment'

export const TrialStartView = ({
	loading,
	back,
	plan,
	qualifiedTrial,
	onConfirm,
	onConfirmAlternate,
	isPlanChange
}: TrialStartViewProps) => {
	const {
		billingStore,
		accountStore: { account }
	} = useStores()
	const { theme } = useThemeStore()
	const posthog = usePostHog()

	return (
		<div className="w-full flex flex-col items-center">
			<div className="w-full px-12">
				<div className="w-full flex items-center space-x-4 mb-4 p-4 rounded-lg bg-gray-50 text-sm">
					<RiArrowLeftLine
						className="w-6 h-6 text-t-dark cursor-pointer"
						onClick={back}
					/>
					{iconMapper(plan.selected_tier)}
					<div className="flex-1 flex flex-col text-left">
						{plan.selected_tier && (
							<span className="font-semibold">{plan.selected_tier?.name}</span>
						)}
						{plan.custom_plan && (
							<div className="flex justify-between">
								<span className="font-semibold">ClickGUARD Pro</span>
								<span className="text-t-secondary">
									Requesting custom quote
								</span>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="text-t-title text-xl font-semibold my-1">
				Start your FREE TRIAL
			</div>
			{qualifiedTrial && (
				<div className="text-t-secondary text-sm">
					Authorize payment method for an extended trial period
				</div>
			)}

			<div className="w-full px-12 rounded-lg flex flex-col space-y-4 text-sm divide-y">
				<GTransition show={!!billingStore.intent}>
					{billingStore.intent && (
						<Elements
							stripe={billingStore.stripe}
							options={{
								clientSecret: billingStore.intent.client_secret,
								appearance: { theme: theme === 'dark' ? 'night' : 'none' }
							}}
						>
							<PaymentMethodForm
								buttonLabel={
									isPlanChange
										? 'Authorize your payment '
										: `Start ${qualifiedTrial ? 14 : 7} day FREE Trial`
								}
								buttonIcon={RiShieldCheckLine}
								loading={loading}
								onSelect={(token) => { posthog?.capture('click-on-start-free-trial'); onConfirm(token) }}
							/>
						</Elements>
					)}
					<div className="text-t-secondary mt-2">
						{isPlanChange ? (
							<>
								By authorising your payment method, you are only changing your
								after-trial plan. You will be charged after your trial ends{' '}
								{moment(account?.billing?.subscription?.ends_at).format(
									'MMMM Do YYYY'
								)}
							</>
						) : (
							<>
								Billing starts on{' '}
								{moment()
									.add(qualifiedTrial ? 14 : 7, 'days')
									.format('MMMM Do YYYY')}
							</>
						)}
					</div>
				</GTransition>
				{!isPlanChange && (
					<GTransition show={qualifiedTrial}>
						<div className="py-4">
							<GButton
								disabled={loading}
								loading={loading}
								onClick={() => onConfirmAlternate()}
							>
								Start Limited Trial
							</GButton>
							<div className="text-t-secondary mt-2">
								Trial ends on {moment().add(7, 'days').format('MMMM Do YYYY')}
							</div>
						</div>
					</GTransition>
				)}
			</div>
		</div>
	)
}

const iconMapper = (plan?: PricingTierInfoDTO | null) => {
	if (plan?.tier === 'lite')
		return <RiShieldLine className="text-red-500 w-6 h-6" />
	if (plan?.tier === 'standard')
		return <RiShieldFlashLine className="text-green-500 w-6 h-6" />
	if (plan?.tier === 'pro')
		return <RiShieldStarLine className="text-primary-500 w-6 h-6" />
	return <RiShieldStarLine className="text-purple-500 w-6 h-6" />
}
