import { GTooltip } from 'components/basic-blocks'
import { GMetricLine } from 'components/basic-blocks/g-metric-line/g-metric-line'
import { SimpleBarChart } from 'components/charts/simple-bar-chart'
import { RiQuestionLine } from 'react-icons/ri'
import { color } from 'utils/colors'
import { SetupWorkspacesPanelProps } from './types'

export const SetupWorkspacesPanel = ({
	data,
	percentages
}: SetupWorkspacesPanelProps) => {
	return (
		<section className="bg-white px-4 py-4 text-t-default rounded min-w-[320px] h-fit">
			<div className="text-t-default text-md flex flex-row items-center gap-x-1 mb-4">
				<span className="font-bold">Workspaces in setup</span>
				<GTooltip
					content={
						<div className="flex flex-col">
							<span>
								Statistics about workspaces that were in setup during the
								selected period
							</span>
						</div>
					}
				>
					<RiQuestionLine className="w-4 h-4 text-t-dark" />
				</GTooltip>
			</div>
			<div className="flex flex-col gap-2">
				<GMetricLine
					title="Avg completion time"
					value={data.avg_completion_time_formatted}
					rate={percentages.avg_completion_time}
				/>
				<GMetricLine
					title="Median completion time"
					value={data.median_completion_time_formatted}
					rate={percentages.median_completion_time}
				/>
			</div>
			<div className="mt-4">
				<SimpleBarChart
					data={[
						{
							id: 'acquisition',
							'Link PPC platform': data.abandonment_link,
							'Add PPC platform': data.abandonment_account,
							'Select plan': data.abandonment_plan
						}
					]}
					keys={['Link PPC platform', 'Add PPC platform', 'Select plan']}
					colors={[
						color('amber', 300),
						color('amber', 400),
						color('amber', 600)
					]}
					format="number"
					title={
						<div className="text-t-default text-md flex flex-row items-center gap-x-1">
							<span className="font-bold">Abandonment step</span>
						</div>
					}
				>
					<div className="flex flex-col h-full mt-4">
						<GMetricLine
							title="Link PPC platform"
							bulletColor="bg-amber-300"
							rate={percentages.abandonment_link}
							value={data.link_ppc_platform_formatted}
						/>
						<GMetricLine
							title="Add PPC account"
							bulletColor="bg-amber-400"
							rate={percentages.abandonment_account}
							value={data.add_ppc_account_formatted}
						/>
						<GMetricLine
							title="Select plan"
							bulletColor="bg-amber-600"
							value={data.selected_plan_formatted}
							rate={percentages.abandonment_plan}
						/>
					</div>
				</SimpleBarChart>
			</div>
		</section>
	)
}
