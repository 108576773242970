import { useNavigate } from 'react-router-dom'

export const InactiveAccountNotice = (props: { accountId?: string }) => {
	const { accountId } = props
	const navigate = useNavigate()

	return (
		<div className="flex items-center">
			Your workspace is currently inactive. Visit the
			<button
				onClick={() => navigate(`/settings/workspace/${accountId}/billing`)}
				className="mx-1 text-primary-500 font-bold"
			>
				Billing Page
			</button>
			to start a subscription.
		</div>
	)
}
