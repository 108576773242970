/* This example requires Tailwind CSS v2.0+ */
import { AdminDashboardStatsDTO } from 'api-models'
import { GTooltip } from 'components/basic-blocks'
import { Tabs } from 'components/tabs'
import { TabItem } from 'components/tabs/types'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { useSearchParams } from 'react-router-dom'
import { PageTitle } from 'utils/page-title'
import { CancelRequestsTab } from './cancel-requests-tab'
import { LimitBreachesTab } from './limit-breaches-tab'
import { NewSetupsTab } from './new-setups-tab'
import { OfferRequestsTab } from './offer-requests-tab'
import { OverduePaymentsTab } from './overdue-payments-tab'
import { StatsCard } from './stats-card'
import { TrialTab } from './trials-tab'

const data1 = [
	{
		id: 'AcquisitionParent',
		title: (
			<div className="flex items-center">
				Acquisition{' '}
				<GTooltip
					className="ml-20"
					content="Current statistics for signed up users and workspaces"
				>
					<AiOutlineQuestionCircle className="h-4 w-4 pl-1 cursor-pointer" />
				</GTooltip>
			</div>
		),
		subtitle: [
			{ name: 'Total signups', label: 'total_signups' },
			{ name: 'Confirmed signups', label: 'confirmed_signups' }
		],
		bar: true,
		viewMore: true,
		bottomBorder: true
	},
	{
		id: 'Acquisition',
		title: '',
		subtitle: [
			{ name: 'In setup', label: 'in_setup' },
			{ name: 'In trial', label: 'in_trial' },
			{ name: 'Subscriptions', label: 'subscriptions' },
			{ name: 'Cancelling', label: 'cancelling' },
			{ name: 'Inactive', label: 'inactive' }
		],
		bar: true,
		viewMore: false,
		bottomBorder: true
	},
	{
		id: 'SubscriptionByPlans',
		title: 'Subscription by plans',
		subtitle: [
			{ name: 'Lite', label: 'sub_lite' },
			{ name: 'Standard', label: 'sub_standard' },
			{ name: 'Pro', label: 'sub_pro' },
			{ name: 'Legacy', label: 'sub_legacy' },
			{ name: 'Custom', label: 'sub_custom' }
		],
		bar: true,
		viewMore: false,
		bottomBorder: false
	}
]

const data2 = [
	{
		id: 'Revenue',
		title: (
			<div className="flex items-center">
				Revenue{' '}
				<GTooltip
					className="ml-20"
					content="Current statistics for revenue based on currently active subscriptions"
				>
					<AiOutlineQuestionCircle className="h-4 w-4 pl-1 cursor-pointer" />
				</GTooltip>
			</div>
		),
		subtitle: [
			{ name: 'Monthly recurring', label: 'monthly_recurring' },
			{ name: 'Annual recurring', label: 'annual_recurring' }
		],
		bar: false,
		viewMore: true,
		bottomBorder: true
	},
	{
		id: 'MRR by billing period',
		title: 'MRR by billing period',
		subtitle: [
			{ name: 'Monthly plans', label: 'mrr_monthly' },
			{ name: 'Yearly plans', label: 'mrr_yearly' },
			{ name: 'Other plans', label: 'mrr_other' }
		],
		bar: true,
		viewMore: false,
		bottomBorder: true
	},
	{
		id: 'MRR by subscription plan',
		title: 'MRR by subscription plan',
		subtitle: [
			{ name: 'Lite', label: 'mrr_lite' },
			{ name: 'Standard', label: 'mrr_standard' },
			{ name: 'Pro', label: 'mrr_pro' },
			{ name: 'Legacy', label: 'mrr_legacy' },
			{ name: 'Custom', label: 'mrr_custom' }
		],
		bar: true,
		viewMore: false,
		bottomBorder: false
	}
]

export const AdminHome = () => {
	const { adminStore } = useStores()
	const { getAdminStats } = adminStore
	const [stats, setStats] = useState<AdminDashboardStatsDTO | undefined>(
		{} as AdminDashboardStatsDTO
	)
	const [search] = useSearchParams('tab=trials')
	useEffect(() => {
		;(async () => {
			const adminStats = await getAdminStats()
			setStats(adminStats)
		})()
	}, [])

	const tabs: Array<TabItem> = [
		{
			key: 'trials',
			label: (
				<div>
					Trials{' '}
					<span
						className={`${
							search.get('tab') === 'trials'
								? 'bg-badge-selected text-badge-text-selected'
								: 'bg-badge-default text-badge-text-t-default'
						} text-sm font-medium mr-2 mx-2.5 px-1.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300`}
					>
						{stats?.trial || 0}
					</span>
				</div>
			),
			to: () => '/admin?tab=trials'
		},
		{
			key: 'new-setups',
			label: (
				<div>
					New setups{' '}
					<span
						className={`${
							search.get('tab') === 'new-setups'
								? 'bg-badge-selected text-badge-text-selected'
								: 'bg-badge-default text-badge-text-t-default'
						} text-sm font-medium mr-2 mx-2.5 px-1.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300`}
					>
						{stats?.in_setup || 0}
					</span>
				</div>
			),
			to: () => '/admin?tab=new-setups'
		},
		{
			key: 'offer-requests',
			label: (
				<div>
					Offer requests{' '}
					<span
						className={`${
							search.get('tab') === 'offer-requests'
								? 'bg-badge-selected text-badge-text-selected'
								: 'bg-badge-default text-badge-text-t-default'
						} text-sm font-medium mr-2 mx-2.5 px-1.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300`}
					>
						{stats?.offer_requests || 0}
					</span>
				</div>
			),
			to: () => '/admin?tab=offer-requests'
		},
		{
			key: 'cancelling',
			label: (
				<div>
					Cancel requests{' '}
					<span
						className={`${
							search.get('tab') === 'cancelling'
								? 'bg-badge-selected text-badge-text-selected'
								: 'bg-badge-default text-badge-text-t-default'
						} text-sm font-medium mr-2 mx-2.5 px-1.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300`}
					>
						{stats?.cancel_requests || 0}
					</span>
				</div>
			),
			to: () => '/admin?tab=cancelling'
		},
		{
			key: 'limit-breaches',
			label: (
				<div>
					Limit Breaches{' '}
					<span
						className={`${
							search.get('tab') === 'limit-breaches'
								? 'bg-badge-selected text-badge-text-selected'
								: 'bg-badge-default text-badge-text-t-default'
						} text-sm font-medium mr-2 mx-2.5 px-1.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300`}
					>
						{stats?.limit_breaches || 0}
					</span>
				</div>
			),
			to: () => '/admin?tab=limit-breaches'
		},
		{
			key: 'overdue-payments',
			label: (
				<div>
					Overdue payments{' '}
					<span
						className={`${
							search.get('tab') === 'overdue-payments'
								? 'bg-badge-selected text-badge-text-selected'
								: 'bg-badge-default text-badge-text-t-default'
						} text-sm font-medium mr-2 mx-2.5 px-1.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300`}
					>
						{stats?.overdue_payments || 0}
					</span>
				</div>
			),
			to: () => '/admin?tab=overdue-payments'
		}
	]

	const [selectedTab, setSelectedTab] = useState(
		search.get('tab') || tabs[0].key
	)

	useEffect(() => {
		setSelectedTab(search.get('tab') || tabs[0].key)
	}, [search])

	return (
		<>
			<PageTitle pageName="Home" />
			<div className="py-2">
				<div className="flex mx-auto px-4 sm:px-6 md:px-8">
					{/* <StatsSection /> */}
					<div className="w-2/12 mr-4">
						<div className="flex flex-col space-y-4">
							<StatsCard dollarValue={false} link={true} data={data1} />
							<StatsCard dollarValue={true} link={false} data={data2} />
						</div>
					</div>
					<div className="w-10/12">
						<Tabs tabs={tabs} current={selectedTab} />
						<div className="border-b border-b-t-tab-border" />
						<div className="my-4" />
						{selectedTab === 'trials' && <TrialTab />}
						{selectedTab === 'new-setups' && <NewSetupsTab />}
						{selectedTab === 'offer-requests' && <OfferRequestsTab />}
						{selectedTab === 'cancelling' && <CancelRequestsTab />}
						{selectedTab === 'limit-breaches' && <LimitBreachesTab />}
						{selectedTab === 'overdue-payments' && <OverduePaymentsTab />}
					</div>
				</div>
			</div>
		</>
	)
}
