import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { publicRoutes } from 'utils/public-routes'
import { DropdownNotifications } from './dropdown-notifications'
import { SlideOverNotifications } from './slideover-notifications'
import { NotificationsState } from './types'

export const Notifications = () => {
	const pathname = useLocation().pathname
	const { generalStore, adminStore } = useStores()
	const { impersonation } = adminStore
	const { getNotifications, readAllNotifications, readNotifications } =
		generalStore
	const [open, setOpen] = useState(false)
	const [notificationId, setNotificationId] = useState<string>('')
	const [state, setState] = useState<NotificationsState>({
		notifications: []
	})
	const [clearId, setClearId] = useState<NodeJS.Timeout | null>(null)
	const getNotificationsHandler = async (query?: Record<string, string>) => {
		const notifications = await generalStore.getNotifications(query)
		if (notifications) {
			setState((prevState) => ({
				...prevState,
				notifications: notifications
			}))
		}
	}

	const isPublicRoute = publicRoutes.find((route) =>
		window.location.pathname.startsWith(route)
	)

	useEffect(() => {
		if (clearId) clearTimeout(clearId)
		if (pathname.includes('admin')) {
			getNotificationsHandler()
			return
		}
		if (!open) {
			;(async function init() {
				if (isPublicRoute) return

				const notifications = await getNotifications({ read: 'false' })
				if (notifications)
					setState((prevState) => ({
						...prevState,
						notifications: notifications
					}))
				if (open) return
				const timeoutId = setTimeout(init, 15000)
				setClearId(timeoutId)
			})()
		}
		if (open) {
			;(async function init() {
				if (isPublicRoute) return

				const notifications = await getNotifications()
				if (notifications)
					setState((prevState) => ({
						...prevState,
						notifications: notifications
					}))
				if (!open) return
				const timeoutId = setTimeout(init, 15000)
				setClearId(timeoutId)
			})()
		}
		return () => {
			clearId && clearTimeout(clearId)
		}
	}, [open, impersonation, pathname])

	const handleMarkOneAsRead = async (
		id: string,
		query?: Record<string, string>
	) => {
		setNotificationId(id)
		await readNotifications(id)
		const notifications = await getNotifications(query)
		if (notifications)
			setState((prevState) => ({ ...prevState, notifications: notifications }))
		setNotificationId('')
	}

	const handleMarkAllAsRead = async (query?: Record<string, string>) => {
		await readAllNotifications()
		const notifications = await getNotifications(query)
		if (notifications)
			setState((prevState) => ({ ...prevState, notifications: notifications }))
	}

	return (
		<>
			{/* Unread notifications */}
			<DropdownNotifications
				state={state}
				setState={setState}
				setOpen={setOpen}
				onReadOneNotification={handleMarkOneAsRead}
				onReadAllNotification={handleMarkAllAsRead}
				notificationId={notificationId}
			/>
			{/* All Notifications */}
			<SlideOverNotifications
				state={state}
				setState={setState}
				open={open}
				setOpen={setOpen}
				onReadOneNotification={handleMarkOneAsRead}
				onReadAllNotification={handleMarkAllAsRead}
				notificationId={notificationId}
			/>
		</>
	)
}
