import { useState } from 'react'
import { useQuery } from 'react-query'

import { GDateRange, GLoading } from 'components/basic-blocks'
import { AdminStats } from 'services/admin/stats'
import { PageTitle } from 'utils/page-title'
import { AdminDateFilterRangeType } from '../types'
import { formatData, formatTimelineData } from './utils'
import { RevenueView } from './revenue-view'

export const AdminRevenuePage = () => {
	const [dateFilterRange, setDateFilterRange] =
		useState<AdminDateFilterRangeType>({})

	const cacheKey = (key: string) => [
		key,
		dateFilterRange.dateFrom,
		dateFilterRange.dateTo
	]
	const isReady = dateFilterRange.dateFrom && dateFilterRange.dateTo

	const { data, isFetching } = useQuery(
		cacheKey('admin-revenue-stats'),
		() =>
			AdminStats.getAdminRevenueStats({
				...dateFilterRange
			}),
		{ enabled: !!isReady }
	)
	const { data: timelineData, isFetching: timelineIsFetching } = useQuery(
		cacheKey('admin-revenue-timeline'),
		() =>
			AdminStats.getAdminRevenueTimeline({
				...dateFilterRange
			}),
		{ enabled: !!isReady }
	)

	const isLoading = isFetching || !data || timelineIsFetching || !timelineData

	return (
		<>
			<PageTitle pageName="Revenue" />
			<div className="w-full h-full p-8">
				<div className="mb-6">
					<h2 className="text-t-heading text-3xl font-bold">Revenue</h2>
					<div className="flex items-end justify-between">
						<span className="text-lg font-medium text-t-default">
							Metrics and statistics regarding revenue and subscriptions
						</span>

						<GDateRange
							align="right"
							onApply={(e) =>
								setDateFilterRange({
									dateFrom: e.from.getTime(),
									dateTo: e.to.getTime()
								})
							}
							loading={false}
						/>
					</div>
				</div>

				{isLoading || !data || !timelineData ? (
					<GLoading />
				) : (
					<RevenueView
						data={formatData(data)}
						timelineData={formatTimelineData(timelineData)}
					/>
				)}
			</div>
		</>
	)
}
