import { LevelType } from './types'

export const getLevel = (props: {
	isAdAccountSelected: boolean
	isCampaignSelected: boolean
}): LevelType => {
	const { isAdAccountSelected, isCampaignSelected } = props

	if (isAdAccountSelected && isCampaignSelected) return 'campaign'
	if (isAdAccountSelected) return 'ad-account'
	return 'website'
}
