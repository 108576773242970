import { GLoading } from 'components/basic-blocks'
import { useUrlQuery } from 'hooks'
import { useEffect } from 'react'

export function CFCGoogleOAuthPage() {
	const query = useUrlQuery()

	useEffect(() => {
		const token = query.code
		window.localStorage.setItem('google_oauth_code', token)
		window.close()
	}, [])

	return (
		<div className="w-full h-full flex items-center justify-center">
			<GLoading />
		</div>
	)
}
