import { UserAccessInfoDTO, UserAccessInviteDTO } from 'api-models'
import { UserAvatar } from 'components'
import { GLoading, GSection } from 'components/basic-blocks/'
import { Notices } from 'components/notice/notices'
import { PageHeader } from 'components/page-header'
import { useEffect, useState } from 'react'
import { RiUserAddLine, RiUserSmileLine } from 'react-icons/ri'
import { sanitizeURL } from 'utils'
import { GBadge } from '../../../components/basic-blocks/g-badge'
import { PageTitle } from '../../../utils/page-title'
import { AddMember } from './add-member'
import { ManagerMember } from './manage-memeber'
import { RevokeInvite } from './revoke-invite'
import { useAccountStore, useAssetStore } from 'store'
import { useShallow } from 'zustand/react/shallow'

export const TeamAccessPage = () => {
	const [loading, setLoading] = useState(true)

	const { asset, adAccounts } = useAssetStore(
		useShallow((s) => ({
			asset: s.asset,
			adAccounts: s.adAccounts
		}))
	)
	const {
		account,
		getAccountUsers: getAccountUsers,
		accountInvitedUsers,
		accountUsersWithAccess,
		wantsToSeeDemoData,
		isInSetup,
		hasFeature
	} = useAccountStore(
		useShallow((state) => ({
			account: state.account,
			getAccountUsers: state.getAccountUsers,
			accountInvitedUsers: state.accountInvitedUsers,
			accountUsersWithAccess: state.accountUsersWithAccess,
			wantsToSeeDemoData: state.wantsToSeeDemoData,
			isInSetup: state.isInSetup,
			hasFeature: state.hasFeature
		}))
	)

	useEffect(() => {
		async function init() {
			if (account) {
				await getAccountUsers(account.id)
				setLoading(false)
			}
		}

		init()
	}, [account])

	if (!wantsToSeeDemoData && loading) return <GLoading />

	const filteredAccountUserWithAccessData = accountUsersWithAccess
	const filteredAccountInvitedUsers = accountInvitedUsers
	const isThereItemToDisplay =
		filteredAccountUserWithAccessData.length > 0 ||
		filteredAccountInvitedUsers.length > 0

	return (
		<div className="w-full">
			<PageTitle pageName="Team access" />
			<Notices
				isInSetup={isInSetup()}
				setupStep={account?.setup?.step}
				isInActive={account?.status === 'INACTIVE'}
				hasDashboardPerformanceFeature={hasFeature('dashboard_performance')}
				tc_installed={asset ? !!asset?.tc_installed : true}
				cc_installed={asset ? !!asset?.cc_installed : true}
				accountId={account?.id}
				assetId={asset?.id}
				demoMode={wantsToSeeDemoData}
				mccLinkInviteAdAccounts={adAccounts}
			/>
			<PageHeader
				title={'Team access'}
				subtitle={
					<p className="flex items-center">
						Manage team members and their permissions for&nbsp;
						<span className="font-bold max-w-[200px] truncate block">
							{sanitizeURL(account?.name) || 'Demo'} Workspace
						</span>
					</p>
				}
			/>
			<div className="max-w-3xl px-4 sm:px-6 md:px-8">
				<GSection
					lineBreak={true}
					title={'Team members'}
					actions={<AddMember />}
					lineBreakColor="gray-100"
				>
					{isThereItemToDisplay && (
						<>
							{filteredAccountUserWithAccessData.length > 0 && (
								<div className="mb-4">
									{filteredAccountUserWithAccessData.map((item, index) => (
										<AccountUserFilteredByAccessTemplate
											key={`${item.user_email}-${index}`}
											row={item}
										/>
									))}
								</div>
							)}
							{filteredAccountInvitedUsers.length > 0 && (
								<div>
									{filteredAccountInvitedUsers.map((item) => (
										<FilteredAccountInvitedUsersTemplate
											key={item.user_email}
											row={item}
										/>
									))}
								</div>
							)}
						</>
					)}
					{filteredAccountUserWithAccessData.length === 0 &&
						filteredAccountInvitedUsers.length === 0 && (
							<div className="text-center flex flex-col items-center mt-6">
								<RiUserAddLine className="h-10 w-10 text-t-secondary" />
								<h3 className="mt-2 text-sm font-medium text-t-dark">
									No team members
								</h3>
								<p className="mt-1 text-sm text-t-secondary">
									You are the only user with access to this workspace
								</p>
							</div>
						)}
				</GSection>
			</div>
		</div>
	)
}

const AccountUserFilteredByAccessTemplate = (props: {
	row: UserAccessInfoDTO
}) => {
	const { row } = props
	const userName = row?.user_name
	const email = row?.user_email
	const accessLevel = row?.access_level
	const status =
		accessLevel === 'READ'
			? 'Analyst'
			: accessLevel === 'WRITE'
			? 'Manager'
			: accessLevel === 'OWNER'
			? 'Owner'
			: 'Other'
	const color =
		accessLevel === 'READ'
			? 'green'
			: accessLevel === 'WRITE'
			? 'purple'
			: accessLevel === 'OWNER'
			? 'primary'
			: 'primary'
	return (
		<div className="flex justify-between items-center mt-6">
			<div className="flex justify-start items-center space-x-2">
				<div className="flex flex-col justify-center mr-4">
					<UserAvatar size={8} src={row?.avatar} name={row.user_name} />
				</div>
				<div className="flex flex-col justify-start items-start ">
					<div className="text-base text-t-default truncate max-w-[200px]">
						{userName ? userName : email}
					</div>
					{userName && (
						<div className="text-sm text-t-secondary">{row?.user_email}</div>
					)}
				</div>
			</div>

			<div className="flex justify-end gap-2 items-center">
				<div className="flex justify-start items-center">
					<GBadge
						color={color}
						text={<span className="font-bold px-1 font-sm">{status}</span>}
					/>
				</div>
				{accessLevel !== 'OWNER' && (
					<div className="flex justify-end pr-0">
						<ManagerMember row={row} />
					</div>
				)}
			</div>
		</div>
	)
}

const FilteredAccountInvitedUsersTemplate = (props: {
	row: UserAccessInviteDTO
}) => {
	const { row } = props
	const email = row?.user_email
	const accessLevel = row?.access_level
	const status =
		accessLevel === 'READ'
			? 'Analyst'
			: accessLevel === 'WRITE'
			? 'Manager'
			: accessLevel === 'OWNER'
			? 'Owner'
			: 'Other'
	const color =
		accessLevel === 'READ'
			? 'green'
			: accessLevel === 'WRITE'
			? 'purple'
			: accessLevel === 'OWNER'
			? 'primary'
			: 'primary'
	const invitedColor = 'amber'
	return (
		<div className="flex justify-between items-center mt-6">
			<div className="flex justify-start items-center space-x-4">
				<div className="flex flex-col justify-center mr-1 ">
					<span
						className={
							'rounded-full border-2 border-gray-300 p-2 bg-violet-500'
						}
					>
						<RiUserSmileLine className="text-t-light w-5 h-5" />
					</span>
				</div>
				<div className="flex flex-col justify-center">
					<div className="text-base text-t-default">{email}</div>
				</div>
			</div>

			<div className="flex justify-end items-end gap-2">
				<div className="flex justify-start items-center">
					<GBadge
						color={color}
						text={<span className="font-bold px-1 font-sm">{status}</span>}
					/>
					{row.code && (
						<GBadge
							color={invitedColor}
							text={<span className="font-bold px-1 font-sm">Invited</span>}
						/>
					)}
				</div>
				<div className="flex justify-end pr-0">
					<RevokeInvite row={row} />
				</div>
			</div>
		</div>
	)
}
