import {
	AdAccountDTO,
	AssetDTO,
	NoteDTO,
	OfferDTO,
	PaginationDTO,
	ProductAccountDTO,
	UserDTO
} from 'api-models'
import { create } from 'zustand'

import {
	CancelSubscriptionPayload,
	TrialPayload
} from 'pages/admin/entites/account/info-tab/admin-subscription-actions/types'
import { AdminProductAccounts } from 'services/admin'
import { AdminAdAccounts } from 'services/admin/ad-accounts'
import { AdminAssets } from 'services/admin/assets'
import { AdminCampigns } from 'services/admin/campaigns'
import { Events } from 'services/admin/events'
import { Notes } from 'services/admin/notes'
import { AdminOffers } from 'services/admin/offers'
import { References } from 'services/admin/references'
import { AdminStats } from 'services/admin/stats'
import { Tags } from 'services/admin/tags'
import { AdminUsers } from 'services/admin/users'
import { ApiClient } from 'services/api-client'
import { useAccountStore } from 'store/account-store'
import { useAssetStore } from 'store/asset-store'
import { useUiStore } from 'store/ui-store'
import { parseError } from 'utils/errors'
import {
	ICreateOfferPayload,
	IUpdateOfferPayload
} from '../../components/create-offer/types'
import { LegacyUserService } from '../../services/admin/legacy-user'
import { LegacyUserDTO } from '../../pages/admin/legacy-user/types'
import { AdminTeam } from '../../services/admin/team'
import { AdminAccessTypes, hasAdminAccess } from '../../utils/admin'
import { useUserStore } from '../user-store'
import { removeFromLocalStorage } from '../../utils'

interface IOfferRequestDTO {
	data: OfferDTO[]
	total: number
}

interface IAdminStoreState {
	impersonation: boolean
	adminTeam: PaginationDTO
	users: UserDTO[] | PaginationDTO
	user: UserDTO | null
	accounts: ProductAccountDTO[] | PaginationDTO
	account: ProductAccountDTO | null
	asset: AssetDTO | null
	ppcAccount: AdAccountDTO | null
	offers: OfferDTO[]
	totalOffersData: number
	offerRequest: IOfferRequestDTO
	imperId: string | null

	setUsers: (users: UserDTO[]) => void
	setAccounts: (accounts: ProductAccountDTO[]) => void
	setAccount: (account: ProductAccountDTO) => void
	setAsset: (asset: AssetDTO) => void
	setPPCAccount: (ppcAccount: AdAccountDTO) => void
	setOfferRequest: (offerRequest: IOfferRequestDTO) => void
	setImpersonation: (user_id: string) => void
	clearImpersonation: () => Promise<void>
	handleOfferRequest: (offer: IOfferRequestDTO) => void
	loadUsers: (query?: Record<string, any>) => Promise<any>
	loadTeam: (query?: Record<string, any>) => Promise<any>
	deleteTeamInvite: (id: string) => Promise<void>
	updateAdminRole: (
		id: string,
		role: 'BASIC' | 'FULL' | 'SUPER'
	) => Promise<void>
	deleteAdmin: (id: string) => Promise<void>
	sendAdminTeamInvite: (
		data: {
			email: string
			access: 'SUPER' | 'FULL' | 'BASIC'
		}[]
	) => Promise<any>
	getUserById: (userId: string) => Promise<any>
	clearAccount: () => void
	clearUser: () => void
	loadAccounts: (query?: Record<string, any>) => Promise<any>
	getAdminInviteByCode: (code: string) => Promise<any>
	setAccountById: (accountId: string) => Promise<any>
	getDomainsReferences: (query?: Record<string, any>) => Promise<any>
	getGadsReferences: (query?: Record<string, any>) => Promise<any>
	updateGadsReference: (
		accountNumber: string,
		body: Record<string, any>
	) => Promise<any>
	suspendUser: (
		id: string,
		payload: { suspended_reason: string }
	) => Promise<any>
	unSuspendUser: (id: string) => Promise<any>
	anonymizeUser: (id: string) => Promise<any>
	getUsersStats: () => Promise<any>
	getAccountsStats: () => Promise<any>
	getAdminStats: () => Promise<any>
	getSubscription: (subscriptionId: string) => Promise<any>
	getWebsites: (query: Record<string, any>) => Promise<any>
	getWebsiteById: (websiteId: string) => Promise<any>
	getAdAccounts: (query?: Record<string, any>) => Promise<any>
	getAdAccountsById: (adAccountId: string) => Promise<any>
	getCampaignsById: (adAccountId: string) => Promise<any>
	createOffer: (
		payload: ICreateOfferPayload,
		query?: Record<string, any>
	) => Promise<any>
	getOffers: (query?: Record<string, any>) => Promise<any>
	findOffers: (query?: Record<string, any>) => Promise<any>
	sendOfferEmail: (id: string) => Promise<any>
	setBlocksData: (offerData: Array<OfferDTO>, total: number) => void
	updateOfferById: (
		payload: IUpdateOfferPayload,
		offer_id: string,
		query?: Record<string, any>
	) => Promise<any>
	deleteOffersById: (id: string, query?: Record<string, any>) => Promise<any>
	deleteOfferRequest: (id: string) => Promise<any>
	createNote: (payload: { topic_id: string; text: string }) => Promise<any>
	deleteNoteById: (id: string) => Promise<NoteDTO>
	updateNoteById: (id: string, value: string) => Promise<NoteDTO>
	getNotes: (topicId: string, query?: Record<string, any>) => Promise<any>
	startTrial: (
		payload: TrialPayload,
		accountId: string
	) => Promise<ProductAccountDTO[] | undefined>
	updateTrial: (
		payload: TrialPayload,
		subscriptionId: string
	) => Promise<ProductAccountDTO[] | undefined>
	createTrialForInactive: (
		days: number,
		accountId: string
	) => Promise<ProductAccountDTO[] | undefined>
	cancelSubscription: (
		payload: CancelSubscriptionPayload,
		subscriptionId: string
	) => Promise<ProductAccountDTO[] | undefined>
	startSubscription: (
		payload: { note: string },
		subscriptionId: string
	) => Promise<ProductAccountDTO[] | undefined>
	revertSubscription: (
		payload: { note: string },
		subscriptionId: string
	) => Promise<ProductAccountDTO[] | undefined>
	addDiscount: (
		payload: { coupon_id: string },
		subscriptionId: string
	) => Promise<ProductAccountDTO[] | undefined>
	getEvents: (query?: Record<string, any>) => Promise<any>
	getSystemTags: () => Promise<any>
	cleanExclusions: (adAccountID: string, imported: boolean) => Promise<any>
	syncExclusions: (adAccountID: string) => Promise<any>
	createTag: (userId: string, tag: string) => Promise<any>
	removeTag: (userId: string, tag: string) => Promise<any>
	downloadAccounts: (query: Record<string, any>, tab?: string) => Promise<any>
	downloadUsers: (query: Record<string, any>, tab?: string) => Promise<any>
	getPipeline: (query?: Record<string, any>) => Promise<any>
	getLegacyUserWithEmail: (
		email: string
	) => Promise<LegacyUserDTO | null | undefined>
	getLegacyInvoices: (query: Record<string, any>) => Promise<any>
	downloadLegacyInvoice: (invoiceId: string) => Promise<any>
}

export const useAdminStore = create<IAdminStoreState>((set, get) => ({
	imperId: JSON.parse(localStorage.getItem('imper_userId') as string),
	impersonation: false,
	users: [],
	adminTeam: {
		data: [],
		total: 0
	},
	user: null,
	accounts: [],
	account: null,
	asset: null,
	ppcAccount: null,
	offers: [],
	totalOffersData: 0,
	offerRequest: {} as IOfferRequestDTO,

	setUsers: (users: UserDTO[]) => set({ users }),
	setAccounts: (accounts: ProductAccountDTO[]) => set({ accounts }),
	setAccount: (account: ProductAccountDTO) => set({ account }),
	setAsset: (asset: AssetDTO) => set({ asset }),
	setPPCAccount: (ppcAccount: AdAccountDTO) => set({ ppcAccount }),
	setOfferRequest: (offerRequest: IOfferRequestDTO) => set({ offerRequest }),

	setImpersonation: async (user_id: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_SET_IMPERSONATION', 'loading')
			removeFromLocalStorage(['selected_asset', 'selected_account'])
			localStorage.setItem('imper_userId', JSON.stringify(user_id))
			ApiClient.setImpersonation(user_id)
			set({ impersonation: true, imperId: user_id })
			useUiStore.getState().setActionState('ADMIN_SET_IMPERSONATION', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_SET_IMPERSONATION',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to impersonate user'
				)
			console.log(err)
		}
	},
	clearImpersonation: async () => {
		removeFromLocalStorage([
			'selected_asset',
			'selected_account',
			'imper_userId'
		])
		useAccountStore.getState().clearAccounts()
		useAccountStore.getState().clearAccount()
		useAssetStore.getState().clearAssets()
		useAssetStore.getState().clearAsset()
		set({ impersonation: false, imperId: null })
		ApiClient.setImpersonation(null)
	},

	handleOfferRequest: (offer: IOfferRequestDTO) => set({ offerRequest: offer }),

	loadUsers: async (query?: Record<string, any>) => {
		try {
			// useUiStore.getState().setActionState('ADMIN_GET_USERS', 'loading')
			// set({ users: users.data })
			// useUiStore.getState().setActionState('ADMIN_GET_USERS', 'idle')
			return await AdminUsers.find(query)
		} catch (err: any) {
			// useUiStore
			// 	.getState()
			// 	.setActionState(
			// 		'ADMIN_GET_USERS',
			// 		'error',
			// 		err.response.data.message ? parseError(err) : 'Failed to load users'
			// 	)
			console.log(err)
		}
	},

	loadTeam: async (query?: Record<string, any>) => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_TEAM', 'loading')
			const team = await AdminTeam.find(query)
			useUiStore.getState().setActionState('ADMIN_GET_TEAM', 'idle')
			set({ adminTeam: team })
			return team
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_TEAM',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to load team'
				)
			console.log(err)
		}
	},

	cleanExclusions: async (adAccountID: string, imported: boolean) => {
		try {
			useUiStore.getState().setActionState('ADMIN_CLEAN_EXCLUSIONS', 'loading')
			const team = await AdminAdAccounts.cleanExclusions(adAccountID, imported)
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_CLEAN_EXCLUSIONS',
					'success',
					'Exclusions cleanup started successfully'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_CLEAN_EXCLUSIONS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to clean exclusion'
				)
			console.log(err)
		}
	},

	syncExclusions: async (adAccountID: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_SYNC_EXCLUSIONS', 'loading')
			const team = await AdminAdAccounts.syncExclusions(adAccountID)
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_SYNC_EXCLUSIONS',
					'success',
					'Exclusions syncing started successfully'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_SYNC_EXCLUSIONS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to sync exclusion'
				)
			console.log(err)
		}
	},

	updateAdminRole: async (id: string, role: 'BASIC' | 'FULL' | 'SUPER') => {
		try {
			useUiStore.getState().setActionState('ADMIN_UPDATE_ROLE', 'loading')
			await AdminTeam.patchRole(id, role)
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_UPDATE_ROLE',
					'success',
					'Role updated successfully'
				)
			get().loadTeam()
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_UPDATE_ROLE',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to load team'
				)
			console.log(err)
		}
	},
	getAdminInviteByCode: async (code: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_INVITE_CODE', 'loading')
			await AdminTeam.getInviteByCode(code)
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_INVITE_CODE',
					'success',
					'Role updated successfully'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_INVITE_CODE',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to load team'
				)
			console.log(err)
		}
	},
	deleteAdmin: async (id: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_DELETE_TEAM', 'loading')
			await AdminTeam.deleteAdmin(id)
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_DELETE_TEAM',
					'success',
					'Role deleted successfully'
				)
			get().loadTeam()
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_DELETE_TEAM',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to load team'
				)
			console.log(err)
		}
	},
	sendAdminTeamInvite: async (
		data: {
			email: string
			access: 'SUPER' | 'FULL' | 'BASIC'
		}[]
	) => {
		try {
			useUiStore.getState().setActionState('ADMIN_INVITE_TEAM', 'loading')
			const invite = await AdminTeam.sendInvites(data)
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_INVITE_TEAM',
					'success',
					'Team invites sent successfully'
				)
			return invite
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_INVITE_TEAM',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to load team'
				)
			console.log(err)
		}
	},
	deleteTeamInvite: async (id: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_DELETE_INVITE', 'loading')
			await AdminTeam.deleteTeamInvite(id)
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_DELETE_INVITE',
					'success',
					'Team invites deleted successfully'
				)
			get().loadTeam()
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_DELETE_INVITE',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to load team'
				)
			console.log(err)
		}
	},

	getUserById: async (userId: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_USER', 'loading')
			const user = await AdminUsers.getById(userId)
			if (!user) {
				useUiStore
					.getState()
					.setActionState(
						'ADMIN_GET_USER',
						'error',
						parseError('User Not Found')
					)
				return null
			}
			set({ user })
			useUiStore.getState().setActionState('ADMIN_GET_USER', 'idle')
			return user
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_USER',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to get user'
				)
			console.log(err)
		}
	},

	clearAccount: () => set({ account: null }),
	clearUser: () => set({ user: null }),

	loadAccounts: async (query?: Record<string, any>) => {
		try {
			// useUiStore.getState().setActionState('ADMIN_GET_ACCOUNTS', 'loading')
			// set({ accounts: accounts?.data })
			// useUiStore.getState().setActionState('ADMIN_GET_ACCOUNTS', 'idle')
			return await AdminProductAccounts.find(query)
		} catch (err: any) {
			// useUiStore
			// 	.getState()
			// 	.setActionState(
			// 		'ADMIN_GET_ACCOUNTS',
			// 		'error',
			// 		err.response.data.message ? parseError(err) : 'Failed to load account'
			// 	)
			console.log(err)
		}
	},
	setAccountById: async (accountId: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_REFERENCES', 'loading')
			const _acc = await AdminProductAccounts.getById(accountId)
			useUiStore.getState().setActionState('ADMIN_GET_REFERENCES', 'success')
			if (!_acc) {
				useUiStore
					.getState()
					.setActionState(
						'ADMIN_GET_REFERENCES',
						'error',
						'Subscription not found'
					)
				return null
			}
			set({ account: _acc })
			return _acc
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_REFERENCES',
					'error',
					err.response.data.message ? parseError(err) : 'Error with account id'
				)
			throw new Error()
		}
	},
	getDomainsReferences: async (query?: Record<string, any>) => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_REFERENCES', 'loading')
			const domainsReferences = await References.getDomainsReferences(query)
			useUiStore.getState().setActionState('ADMIN_GET_REFERENCES', 'idle')
			return domainsReferences
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_REFERENCES',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to load domain references'
				)
			console.log(err)
		}
	},
	getGadsReferences: async (query?: Record<string, any>) => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_REFERENCES', 'loading')
			const gadsReferences = await References.getGadsReferences(query)
			useUiStore.getState().setActionState('ADMIN_GET_REFERENCES', 'idle')
			return gadsReferences
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_REFERENCES',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error loading PPC account references'
				)
			console.log(err)
		}
	},
	updateGadsReference: async (
		accountNumber: string,
		body: Record<string, any>
	) => {
		try {
			useUiStore.getState().setActionState('ADMIN_UPDATE_REFERENCE', 'loading')
			const gadsReference = await References.updateGadsReferences(
				accountNumber,
				body
			)
			useUiStore.getState().setActionState('ADMIN_UPDATE_REFERENCE', 'idle')
			return gadsReference
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_UPDATE_REFERENCE',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error updating reference'
				)
			console.log(err)
		}
	},
	suspendUser: async (id: string, payload: { suspended_reason: string }) => {
		try {
			useUiStore.getState().setActionState('ADMIN_SUSPEND_USER', 'loading')
			const user = await AdminUsers.suspendUser(id, payload)
			useUiStore
				.getState()
				.setActionState('ADMIN_SUSPEND_USER', 'success', 'User suspended')
			await get().getUserById(id)
			return user
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_SUSPEND_USER',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to suspend user'
				)
			console.log(err)
		}
	},
	unSuspendUser: async (id: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_SUSPEND_USER', 'loading')
			const user = await AdminUsers.unSuspendUser(id)
			useUiStore
				.getState()
				.setActionState('ADMIN_SUSPEND_USER', 'success', 'Suspension removed')
			await get().getUserById(id)
			return user
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_SUSPEND_USER',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to unsuspend user'
				)
			throw new Error()
		}
	},
	anonymizeUser: async (id: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_ANONYMIZE_USER', 'loading')
			await AdminUsers.anonymizeUser(id)
			useUiStore
				.getState()
				.setActionState('ADMIN_ANONYMIZE_USER', 'success', 'User anonymized')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_ANONYMIZE_USER',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to anonymize user'
				)
			throw new Error()
		}
	},
	getUsersStats: async () => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_STATS', 'loading')
			const usersStats = await AdminUsers.getStats()
			useUiStore.getState().setActionState('ADMIN_GET_STATS', 'idle')
			return usersStats
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_STATS',
					'error',
					err.response.data.message ? parseError(err) : 'Error loading stats'
				)
			console.log(err)
		}
	},
	getAccountsStats: async () => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_STATS', 'loading')
			const usersStats = await AdminProductAccounts.getStats()
			useUiStore.getState().setActionState('ADMIN_GET_STATS', 'idle')
			return usersStats
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_STATS',
					'error',
					err.response.data.message ? parseError(err) : 'Error loading stats'
				)
			console.log(err)
		}
	},
	getAdminStats: async () => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_STATS', 'loading')
			const adminStats = await AdminStats.getAdminStats()
			useUiStore.getState().setActionState('ADMIN_GET_STATS', 'idle')
			return adminStats
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_STATS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error loading admin stats'
				)
			console.log(err)
		}
	},
	getSubscription: async (subscriptionId: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_SUBSCRIPTION', 'loading')
			const subscription = await AdminProductAccounts.getSubscriptions(
				subscriptionId
			)
			useUiStore.getState().setActionState('ADMIN_GET_SUBSCRIPTION', 'idle')
			return subscription
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_SUBSCRIPTION',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error loading subscriptions'
				)
			console.log(err)
		}
	},
	getWebsites: async (query: Record<string, any>) => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_WEBSITES', 'loading')
			const assets = await AdminAssets.find(query)
			useUiStore.getState().setActionState('ADMIN_GET_WEBSITES', 'idle')
			return assets
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_WEBSITES',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to add connection'
				)
			console.log(err)
		}
	},

	getWebsiteById: async (websiteId: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_WEBSITE', 'loading')
			const asset = await AdminAssets.findById(websiteId)
			useUiStore.getState().setActionState('ADMIN_GET_WEBSITE', 'idle')
			set({ asset })
			return asset
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_WEBSITE',
					'error',
					err.response.data.message ? parseError(err) : 'Error loading website'
				)
			throw new Error()
		}
	},
	getAdAccounts: async (query?: Record<string, any>) => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_ADACCOUNTS', 'loading')
			const assets = await AdminAdAccounts.find(query)
			useUiStore.getState().setActionState('ADMIN_GET_ADACCOUNTS', 'idle')
			return assets
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_ADACCOUNTS',
					'error',
					err.response.data.message ? parseError(err) : 'Error loading website'
				)
			console.log(err)
		}
	},
	getAdAccountsById: async (adAccountId: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_ADACCOUNT', 'loading')
			const ppcAccount = await AdminAdAccounts.findById(adAccountId)
			useUiStore.getState().setActionState('ADMIN_GET_ADACCOUNT', 'idle')
			set({ ppcAccount })
			return ppcAccount
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_ADACCOUNT',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error loading PPC account'
				)
			throw new Error()
		}
	},
	getCampaignsById: async (adAccountId: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_CAMPAIGNS', 'loading')
			const campaigns = await AdminCampigns.findById(adAccountId)
			useUiStore.getState().setActionState('ADMIN_GET_CAMPAIGNS', 'idle')
			return campaigns
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_CAMPAIGNS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error loading campaigns'
				)
			throw new Error()
		}
	},
	createOffer: async (payload: ICreateOfferPayload) => {
		try {
			useUiStore.getState().setActionState('ADMIN_ADD_OFFER', 'loading')
			const offers = await AdminOffers.createOffer(payload)
			useUiStore
				.getState()
				.setActionState('ADMIN_ADD_OFFER', 'success', 'Offer created!')
			if (payload.account_id) {
				get().findOffers({ accountId: payload.account_id })
				return offers
			}
			if (payload.user_id) {
				get().findOffers({ userId: payload.user_id })
				return offers
			}
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_ADD_OFFER',
					'error',
					err.response.data.message ? parseError(err) : 'Error creating offers'
				)
			console.log(err)
		}
	},
	getOffers: async (query?: Record<string, any>) => {
		try {
			useUiStore
				.getState()
				.setActionState('ADMIN_GET_OFFER_REQUESTS', 'loading')
			const offers = await AdminOffers.getOffersRequests(query)
			useUiStore.getState().setActionState('ADMIN_GET_OFFER_REQUESTS', 'idle')
			return offers
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_OFFER_REQUESTS',
					'error',
					err.response.data.message ? parseError(err) : 'Error loading offers'
				)
			console.log(err)
		}
	},
	findOffers: async (query?: Record<string, any>) => {
		try {
			if (
				!hasAdminAccess(
					useUserStore.getState().user,
					AdminAccessTypes.view_offers
				)
			) {
				return []
			}
			useUiStore.getState().setActionState('ADMIN_GET_OFFERS', 'loading')
			const { data, total } = await AdminOffers.find(query)
			useUiStore.getState().setActionState('ADMIN_GET_OFFERS', 'idle')
			get().setBlocksData(data, total)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_OFFERS',
					'error',
					err.response.data.message ? parseError(err) : 'Error loading offers'
				)
			console.log(err)
		}
	},
	sendOfferEmail: async (id: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_SEND_OFFER_EMAIL', 'loading')
			await AdminOffers.sendOfferEmail(id)
			useUiStore
				.getState()
				.setActionState('ADMIN_SEND_OFFER_EMAIL', 'success', 'Email sent')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_SEND_OFFER_EMAIL',
					'error',
					err.response.data.message ? parseError(err) : 'Error sending email'
				)
			throw new Error()
		}
	},
	setBlocksData: (offerData: Array<OfferDTO>, total: number) =>
		set({ offers: offerData, totalOffersData: total }),

	updateOfferById: async (
		payload: IUpdateOfferPayload,
		offer_id: string,
		query?: Record<string, any>
	) => {
		try {
			useUiStore.getState().setActionState('ADMIN_UPDATE_OFFER', 'loading')
			await AdminOffers.updateOfferById(payload, offer_id)
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_UPDATE_OFFER',
					'success',
					'successfully updated offer'
				)
			return await get().findOffers(query)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_UPDATE_OFFER',
					'error',
					err.response.data.message ? parseError(err) : 'Error deleting offer'
				)
			console.log(err)
			throw new Error()
		}
	},
	deleteOffersById: async (id: string, query?: Record<string, any>) => {
		try {
			useUiStore.getState().setActionState('ADMIN_DELETE_OFFER', 'loading')
			await AdminOffers.deleteOfferByid(id)
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_DELETE_OFFER',
					'success',
					'Successfully deleted offer'
				)
			return await get().findOffers(query)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_DELETE_OFFER',
					'error',
					err.response.data.message ? parseError(err) : 'Error deleting offer'
				)
			console.log(err)
		}
	},
	deleteOfferRequest: async (id: string) => {
		try {
			useUiStore
				.getState()
				.setActionState('ADMIN_DELETE_OFFER_REQUEST', 'loading')
			await AdminOffers.deleteOfferRequest(id)
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_DELETE_OFFER_REQUEST',
					'success',
					'Successfully deleted offer request'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_DELETE_OFFER_REQUEST',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error deleting offer request'
				)
			console.log(err)
		}
	},
	createNote: async (payload: {
		topic_id: string
		text: string
	}): Promise<NoteDTO> => {
		try {
			useUiStore.getState().setActionState('ADMIN_ADD_NOTE', 'loading')
			const note = await Notes.create(payload)
			useUiStore.getState().setActionState('ADMIN_ADD_NOTE', 'idle')
			return note
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_ADD_NOTE',
					'error',
					err.response.data.message ? parseError(err) : 'Error adding note'
				)
			throw new Error()
		}
	},
	deleteNoteById: async (id: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_DELETE_NOTE', 'loading')
			const note = await Notes.deleteById(id)
			useUiStore.getState().setActionState('ADMIN_DELETE_NOTE', 'idle')
			return note
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_DELETE_NOTE',
					'error',
					err.response.data.message ? parseError(err) : 'Error deleting note'
				)
			throw new Error()
		}
	},
	updateNoteById: async (id: string, value: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_UPDATE_NOTE', 'loading')
			const note = await Notes.updateById(id, value)
			useUiStore.getState().setActionState('ADMIN_UPDATE_NOTE', 'idle')
			return note
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_UPDATE_NOTE',
					'error',
					err.response.data.message ? parseError(err) : 'Error updating note'
				)
			throw new Error()
		}
	},
	getNotes: async (topicId: string, query?: Record<string, any>) => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_NOTES', 'loading')
			const data = await Notes.get(topicId, query)
			useUiStore.getState().setActionState('ADMIN_GET_NOTES', 'success')
			return { notes: data.data, total: data.total }
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_NOTES',
					'error',
					err.response.data.message ? parseError(err) : 'Error loading notes'
				)
			console.log(err)
			throw new Error()
		}
	},
	startTrial: async (
		payload: TrialPayload,
		accountId: string
	): Promise<ProductAccountDTO[] | undefined> => {
		try {
			useUiStore.getState().setActionState('ADMIN_CHANGE_ACCOUNT', 'loading')
			const account = await AdminProductAccounts.startTrial(payload, accountId)
			const trial = await AdminProductAccounts.startTrial(payload, accountId)
			useUiStore
				.getState()
				.setActionState('ADMIN_CHANGE_ACCOUNT', 'success', 'Trial started!')
			if (account) {
				await get().setAccountById(accountId)
			}
			return trial
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_CHANGE_ACCOUNT',
					'error',
					err.response.data.message ? parseError(err) : 'Error starting trial'
				)
			console.log(err)
			throw new Error()
		}
	},
	updateTrial: async (
		payload: TrialPayload,
		subscriptionId: string
	): Promise<ProductAccountDTO[] | undefined> => {
		const account = get().account
		try {
			useUiStore.getState().setActionState('ADMIN_CHANGE_ACCOUNT', 'loading')
			const trial = await AdminProductAccounts.updateTrial(
				payload,
				subscriptionId
			)
			useUiStore
				.getState()
				.setActionState('ADMIN_CHANGE_ACCOUNT', 'success', 'Trial updated!')
			if (account) {
				await get().setAccountById(account.id)
			}
			return trial
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_CHANGE_ACCOUNT',
					'error',
					err.response.data.message ? parseError(err) : 'Error updating trial'
				)
		}
	},
	createTrialForInactive: async (
		days: number,
		accountId: string
	): Promise<ProductAccountDTO[] | undefined> => {
		const account = get().account
		try {
			useUiStore.getState().setActionState('ADMIN_CHANGE_ACCOUNT', 'loading')
			const trial = await AdminProductAccounts.newTrialForInactive(
				days,
				accountId
			)
			useUiStore
				.getState()
				.setActionState('ADMIN_CHANGE_ACCOUNT', 'success', 'Trial updated!')
			if (account) {
				await get().setAccountById(account.id)
			}
			return trial
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_CHANGE_ACCOUNT',
					'error',
					err.response.data.message ? parseError(err) : 'Error starting trial'
				)
			console.log(err)
			throw new Error()
		}
	},
	cancelSubscription: async (
		payload: CancelSubscriptionPayload,
		subscriptionId: string
	): Promise<ProductAccountDTO[] | undefined> => {
		const account = get().account
		try {
			useUiStore.getState().setActionState('ADMIN_CHANGE_ACCOUNT', 'loading')
			const subscription = await AdminProductAccounts.cancelSubscription(
				payload,
				subscriptionId
			)
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_CHANGE_ACCOUNT',
					'success',
					'Subscription cancelled'
				)
			if (account) {
				await get().setAccountById(account.id)
			}
			return subscription
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_CHANGE_ACCOUNT',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error cancelling subscription'
				)
			console.log(err)
			throw new Error()
		}
	},
	startSubscription: async (
		payload: { note: string },
		subscriptionId: string
	): Promise<ProductAccountDTO[] | undefined> => {
		const account = get().account
		try {
			useUiStore.getState().setActionState('ADMIN_CHANGE_ACCOUNT', 'loading')
			const subscription = await AdminProductAccounts.startSubscription(
				payload,
				subscriptionId
			)
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_CHANGE_ACCOUNT',
					'success',
					'Subscription started'
				)
			if (account) {
				await get().setAccountById(account.id)
			}
			return subscription
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_CHANGE_ACCOUNT',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error starting subscription'
				)
		}
	},
	revertSubscription: async (
		payload: { note: string },
		subscriptionId: string
	): Promise<ProductAccountDTO[] | undefined> => {
		const account = get().account
		try {
			useUiStore.getState().setActionState('ADMIN_CHANGE_ACCOUNT', 'loading')
			const subscription = await AdminProductAccounts.revertSubscription(
				payload,
				subscriptionId
			)
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_CHANGE_ACCOUNT',
					'success',
					'Subscription reverted'
				)
			if (account) {
				await get().setAccountById(account.id)
			}
			return subscription
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_CHANGE_ACCOUNT',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error reverting subscription'
				)
		}
	},
	addDiscount: async (
		payload: { coupon_id: string },
		subscriptionId: string
	): Promise<ProductAccountDTO[] | undefined> => {
		const account = get().account
		try {
			useUiStore.getState().setActionState('ADMIN_CHANGE_ACCOUNT', 'loading')
			const discount = await AdminProductAccounts.addDiscount(
				payload,
				subscriptionId
			)
			useUiStore
				.getState()
				.setActionState('ADMIN_CHANGE_ACCOUNT', 'success', 'Discount activated')
			if (account) {
				await get().setAccountById(account.id)
			}
			return discount
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_CHANGE_ACCOUNT',
					'error',
					err.response.data.message ? parseError(err) : 'Error adding discount.'
				)
		}
	},
	getEvents: async (
		query?: Record<string, any>
	): Promise<{ events: any; total: number } | undefined> => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_EVENTS', 'loading')
			const data = await Events.get(query)
			useUiStore.getState().setActionState('ADMIN_GET_EVENTS', 'success')
			return { events: data.data, total: data.total }
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_EVENTS',
					'error',
					err.response.data.message ? parseError(err) : 'Error loading events'
				)
			console.log(err)
			throw new Error()
		}
	},
	getSystemTags: async () => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_TAGS', 'loading')
			const tags = await Tags.getSystemTags()
			useUiStore.getState().setActionState('ADMIN_GET_TAGS', 'success')
			return tags
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_TAGS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error loading system tags'
				)
			console.log(err)
			throw new Error()
		}
	},
	createTag: async (userId: string, tag: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_UPDATE_TAGS', 'loading')
			await Tags.create(userId, tag)
			useUiStore.getState().setActionState('ADMIN_UPDATE_TAGS', 'success')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_UPDATE_TAGS',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to add tag'
				)
			console.log(err)
			throw new Error()
		}
	},
	removeTag: async (userId: string, tag: string) => {
		try {
			useUiStore.getState().setActionState('ADMIN_UPDATE_TAGS', 'loading')
			const tags = await Tags.remove(userId, tag)
			useUiStore.getState().setActionState('ADMIN_UPDATE_TAGS', 'success')
			get().getUserById(userId)
			return tags
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_UPDATE_TAGS',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to remove tag'
				)
			console.log(err)
			throw new Error()
		}
	},
	downloadAccounts: async (query: any, tab?: string) => {
		try {
			useUiStore.getState().setActionState('DOWNLOAD_ACCOUNTS', 'loading')
			await AdminAdAccounts.downloadAccounts(query, tab)
			useUiStore.getState().setActionState('DOWNLOAD_ACCOUNTS', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'DOWNLOAD_ACCOUNTS',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to download pdf'
				)
			console.log(err)
		}
	},
	downloadUsers: async (query: any) => {
		try {
			useUiStore.getState().setActionState('DOWNLOAD_USERS', 'loading')
			await AdminUsers.exportUsers(query)
			useUiStore.getState().setActionState('DOWNLOAD_USERS', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'DOWNLOAD_USERS',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to download pdf'
				)
			console.log(err)
		}
	},
	getLegacyUserWithEmail: async (
		email: string
	): Promise<LegacyUserDTO | null | undefined> => {
		try {
			useUiStore.getState().setActionState('ADMIN_GET_LEGACY_USER', 'loading')
			const user = await LegacyUserService.searchByEmail(email)
			useUiStore.getState().setActionState('ADMIN_GET_LEGACY_USER', 'idle')
			return user
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_LEGACY_USER',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to get user'
				)
			console.log(err)
		}
	},
	getLegacyInvoices: async (query: Record<string, string>) => {
		try {
			useUiStore
				.getState()
				.setActionState('ADMIN_GET_LEGACY_INVOICES', 'loading')
			const user = await LegacyUserService.getInvoices(query)
			useUiStore.getState().setActionState('ADMIN_GET_LEGACY_INVOICES', 'idle')
			return user
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_GET_LEGACY_INVOICES',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to get invoices'
				)
			console.log(err)
		}
	},
	downloadLegacyInvoice: async (id: string) => {
		try {
			useUiStore
				.getState()
				.setActionState('ADMIN_DOWNLOAD_LEGACY_INVOICE', 'loading')
			const user = await LegacyUserService.downloadInvoice(id)
			useUiStore
				.getState()
				.setActionState('ADMIN_DOWNLOAD_LEGACY_INVOICE', 'idle')
			return user
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ADMIN_DOWNLOAD_LEGACY_INVOICE',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to download invoice'
				)
			console.log(err)
		}
	},
	getPipeline: async (query?: Record<string, any>) => {
		try {
			const pipeline = await AdminProductAccounts.getPipeline(query)
			return pipeline
		} catch (err: any) {
			console.log(err)
		}
	}
}))
