import { twMerge } from 'tailwind-merge'
import { RiArrowDownSLine, RiArrowUpSLine, RiCodeLine } from 'react-icons/ri'
import { GTableColumn, GTableHeaderProps } from '../types'

export const NewTableHeader = <T,>(props: GTableHeaderProps<T>) => {
	const { column, reactColumn, sort, onSort, isScrolling } = props

	const raiseSort = (column: GTableColumn<T>) => {
		if (onSort && column.sortKey) {
			if (column.sortKey === sort) return onSort(`-${column.sortKey}`)
			onSort(column.sortKey)
		}
	}
	const renderSortIcon = (column: GTableColumn<T>) => {
		if (`-${column.sortKey}` !== sort && column.sortKey !== sort)
			return <RiCodeLine className="h-3 w-3 rotate-90" />
		if (sort === column.sortKey) return <RiArrowUpSLine className="h-3 w-3" />
		if (sort === `-${column.sortKey}`)
			return <RiArrowDownSLine className="h-3 w-3" />
		return <RiCodeLine className="h-3 w-3 rotate-90" />
	}

	if (!column) return null

	return (
		<th
			{...reactColumn.getHeaderProps()}
			className={twMerge(
				'px-4 py-3 text-right text-sm font-semibold text-t-secondary',
				column.hidden ? 'hidden' : '',
				column.mobileHide ? 'hidden md:table-cell' : '',
				column.tabletHide ? 'hidden lg:table-cell' : '',
				column.parentClassName || '',
				column.freeze ? 'sticky left-0' : '',
				isScrolling ? 'bg-white' : ''
			)}
		>
			<div
				className={twMerge(
					'flex w-full h-full px-2 justify-end items-center',
					column.className || ''
				)}
			>
				{column.header ? column.header() : column.label}
				{column.sortKey && (
					<span
						className="mx-2 cursor-pointer"
						onClick={() => raiseSort(column)}
					>
						{renderSortIcon(column)}
					</span>
				)}
			</div>
		</th>
	)
}
