import {
	AccountLinkDTO,
	GoogleOAuthAccountLinkDTO,
	MCCAccountLinkDTO
} from 'api-models'
import { GButton, GSection, GTooltip } from 'components/basic-blocks'
import { Dispatch } from 'react'
import { SiGoogleads } from 'react-icons/si'
import { GBadge } from '../../../components/basic-blocks/g-badge'
import { useStores } from '../../../hooks'
import { ZendeskService } from '../../../services/zendesk'
import { ConfigurePpcAccess } from './configure-ppc-access'

import { RiGoogleLine } from 'react-icons/ri'

export const GoogleAdsTab = (props: {
	links: AccountLinkDTO[]
	isAnalyst: boolean
	setModalNewConnection: Dispatch<{
		platform: 'GADS' | 'FADS'
		MCC: 'oauth' | 'mcc-account'
		active: boolean
	}>
}) => {
	const {
		accountStore,
		userStore: { user }
	} = useStores()
	const { wantsToSeeDemoData, userAccess } = accountStore
	const { links, isAnalyst, setModalNewConnection } = props

	const oAuthConnections = links.filter(
		(link) => link.type === 'oauth'
	) as GoogleOAuthAccountLinkDTO[]
	const mccConnections = links.filter(
		(link) => link.type === 'mcc'
	) as MCCAccountLinkDTO[]

	return (
		<>
			<GSection
				title={'Google Ads'}
				titleClass={links?.length > 0 ? 'mb-6' : 'mb-0'}
				lineBreak={links.length > 0}
				lineBreakColor="gray-100"
				actions={
					<div className="flex items-center gap-2">
						<GTooltip
							content={
								wantsToSeeDemoData
									? 'This action is disabled when demo data is displayed'
									: userAccess(user!.id) === 'READ'
									? "Analysts don't have permission for this action"
									: undefined
							}
						>
							<GButton
								variant="text"
								labelClassName="font-bold"
								color="primary"
								size="md"
								className="h-[30px] border-t-input-border"
								disabled={wantsToSeeDemoData || isAnalyst}
								label="New OAuth link"
								onClick={() =>
									setModalNewConnection({
										platform: 'GADS',
										active: true,
										MCC: 'oauth'
									})
								}
							/>
						</GTooltip>
						<GTooltip
							content={
								wantsToSeeDemoData
									? 'This action is disabled when demo data is displayed'
									: userAccess(user!.id) === 'READ'
									? "Analysts don't have permission for this action"
									: undefined
							}
						>
							<GButton
								variant="text"
								labelClassName="font-bold"
								color="primary"
								size="md"
								className="h-[30px] border-t-input-border"
								disabled={wantsToSeeDemoData || isAnalyst}
								label="Add MCC account"
								onClick={() =>
									setModalNewConnection({
										platform: 'GADS',
										active: true,
										MCC: 'mcc-account'
									})
								}
							/>
						</GTooltip>
					</div>
				}
			>
				{oAuthConnections.length > 0 &&
					oAuthConnections.map((connection, index) => {
						return (
							<OAuthConnectionTemplate
								link={connection}
								key={index}
								isAnalyst={isAnalyst}
							/>
						)
					})}
				{mccConnections.length > 0 &&
					mccConnections.map((connection, index) => {
						return (
							<MccConnectionsTemplate
								link={connection}
								key={index}
								isAnalyst={isAnalyst}
							/>
						)
					})}
			</GSection>
		</>
	)
}

const OAuthConnectionTemplate = (props: {
	link: GoogleOAuthAccountLinkDTO
	isAnalyst: boolean
}) => {
	const {
		accountStore: { wantsToSeeDemoData }
	} = useStores()
	const { link, isAnalyst } = props

	const color = link.status === 'UNLINKED' ? 'amber' : 'green'

	return (
		<div className="flex justify-between items-center mt-6">
			<div className="flex justify-start items-center space-x-4">
				<RiGoogleLine className="text-red-500 w-6 h-6" />
				<div className="flex flex-col justify-start items-start ">
					<div className="text-sm leading-5 text-t-secondary">Google OAuth</div>
					<div className="text-md text-t-default">{link.name}</div>
				</div>
			</div>
			<div className="flex justify-end items-end gap-4">
				<GBadge
					className="h-[28px] py-0.5 px-3"
					color={color}
					text={<span className="font-bold px-1 font-sm">{link.status}</span>}
				/>

				<ConfigurePpcAccess link={link} platform={'GADS'} isAnalyst={isAnalyst}>
					{(open) => (
						<GTooltip
							content={
								wantsToSeeDemoData
									? 'This action is disabled when demo data is displayed'
									: isAnalyst
									? "Analysts don't have permission for this action"
									: undefined
							}
						>
							<GButton
								variant="text"
								label="Configure"
								size="lg"
								color="buttonPrimary"
								className="h-[28px] w-[84px] disabled:bg-gray-200 disabled:text-t-secondary border-t-input-border"
								labelClassName="font-bold text-sm"
								disabled={wantsToSeeDemoData || isAnalyst}
								onClick={() => {
									open()
									ZendeskService.hide()
								}}
							/>
						</GTooltip>
					)}
				</ConfigurePpcAccess>
			</div>
		</div>
	)
}

const MccConnectionsTemplate = (props: {
	link: MCCAccountLinkDTO
	isAnalyst: boolean
}) => {
	const { link, isAnalyst } = props
	const color = link.status === 'UNLINKED' ? 'amber' : 'green'

	return (
		<div className="flex justify-between items-center mt-6">
			<div className="flex justify-start items-center space-x-4">
				<SiGoogleads className="text-green-500 w-6 h-6" />
				<div className="flex flex-col justify-start items-start ">
					<div className="text-sm leading-5 text-t-secondary">MCC Account</div>
					<div className="text-md text-t-dark">{link.name}</div>
				</div>
			</div>
			<div className="flex justify-end items-end gap-4">
				<GBadge
					color={color}
					className="h-[28px] py-0.5 px-3"
					text={<span className="font-bold px-1 font-sm">{link.status}</span>}
				/>
				<ConfigurePpcAccess link={link} platform={'MCC'} isAnalyst={isAnalyst}>
					{(open) => (
						<GTooltip
							content={
								isAnalyst
									? "Analysts don't have permission for this action"
									: undefined
							}
						>
							<GButton
								variant="text"
								label="Configure"
								size="lg"
								color="buttonPrimary"
								className="h-[28px] w-[84px] disabled:bg-gray-200 disabled:text-t-secondary"
								labelClassName="font-bold text-sm"
								disabled={isAnalyst}
								onClick={() => {
									open()
									ZendeskService.hide()
								}}
							/>
						</GTooltip>
					)}
				</ConfigurePpcAccess>
			</div>
		</div>
	)
}
