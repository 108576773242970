import { GButton } from 'components/basic-blocks'
import { LegacyViewProps } from '../types'

export const LegacyView = ({ loading, onConfirm }: LegacyViewProps) => {
	return (
		<div className="w-full flex flex-col items-center">
			<span className="text-t-title text-xl font-semibold mb-4">
				Welcome to ClickGUARD 2.0 🚀
			</span>

			<div className="w-full px-2 rounded-lg flex flex-col space-y-6 mb-4 text-sm">
				<div className="flex flex-col text-center space-y-4 text-t-dark">
					<p>
						We're so excited to share our new & improved platform for you to
						test out with a <strong>FREE TRIAL</strong>.
					</p>
					<p>
						You get access to <strong>all our features</strong> during the
						trial, so give it a go, and tell us what you think!
					</p>
					<p>
						If you still have an active ClickGUARD subscription, checkout this{' '}
						<a
							className="text-primary-500"
							href="https://help.clickguard.com/hc/en-us/articles/11735098846621-ClickGUARD-2-0-migration-guide"
							target="_blank"
						>
							migration guide
						</a>
						, or contact support via chat for help.
					</p>
					<p>
						We want you to have an effortless experience, so while you're
						testing things out, our team will create a custom quote just for you
						to <strong>match the price</strong> of your current subscription.
					</p>
					<p className="font-semibold text-md">Let's get started!</p>
				</div>
			</div>
			<div className="mt-2">
				<GButton color="primary" onClick={onConfirm} loading={loading}>
					Start FREE TRIAL
				</GButton>
			</div>
		</div>
	)
}
