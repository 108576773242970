import { useAuth0 } from '@auth0/auth0-react'
import { UserIdentityDTO } from 'api-models/src'
import {
	GButton,
	GConfirm,
	GDialog,
	GListBox,
	GTooltip
} from 'components/basic-blocks'
import { ModalTypes } from 'components/basic-blocks/g-modal/types'
import { ConfigValueItem } from 'config'
import { useStores } from 'hooks'
import { ReactElement, useEffect, useState } from 'react'
import {
	RiDeleteBin2Line,
	RiFacebookLine,
	RiGoogleLine,
	RiLinkedinLine,
	RiLockPasswordLine,
	RiMailLine,
	RiRefreshLine
} from 'react-icons/ri'
import { toast } from 'react-toastify'
import { useUiStore } from 'store'

interface loginMethodCRowsProps {
	item: UserIdentityDTO
	showDelete: boolean
	loading: boolean
}

export const LoginMethodRows = (props: loginMethodCRowsProps) => {
	const { item, showDelete } = props
	const { userStore } = useStores()
	const { deleteLoginMethod, passwordReset, changeMainEmail, user } = userStore
	const setModalContent = useUiStore((s) => s.setModalContent)
	const setModalState = useUiStore((s) => s.setModalState)
	const { logout } = useAuth0()

	const [emailValue, setEmailValue] = useState<ConfigValueItem>({
		value: item.email as string,
		label: item.email as string
	})
	const [verifiedEmails, setVerifiedEmails] = useState<ConfigValueItem[]>([
		{ value: '', label: '' }
	])
	const [openModal, setOpenModal] = useState({
		changeEmailModal: false,
		changingEmailModal: false
	})

	const deleteIcon = () => {
		return <RiDeleteBin2Line className="ml-0 mr-0 h-3.5 w-3.5" />
	}

	useEffect(() => {
		const configEmailsArray: ConfigValueItem[] = []
		const verifiedEmail = user?.email_addresses.filter(
			(email) => email.email_verified
		)
		verifiedEmail &&
			verifiedEmail.forEach((item) =>
				configEmailsArray.push({ label: item.email, value: item.email })
			)
		setVerifiedEmails([...configEmailsArray])
	}, [user?.email_addresses])

	const renderUpdatePasswordModal = () => {
		setModalContent({
			title: 'Reset password',
			description:
				'This action will start a password reset process. You will receive instructions for setting new password in your inbox. Proceed?',
			type: ModalTypes.WARNING,
			confirm: () => handlePasswordChange()
		})
	}

	const handlePasswordChange = async () => {
		try {
			await passwordReset(user!.email)
			toast.success('Password reset email sent')
			setTimeout(() => {
				logout()
			}, 500)
		} catch (err) {
			toast.error('Failed to send password reset email')
		}
		setModalState(false)
	}

	const renderDeleteModal = () => {
		setModalContent({
			title: 'Remove login method',
			description:
				'Are you sure you want to remove this login method? You will be logged out and prompted to log in again. Proceed?',
			type: ModalTypes.DANGER,
			confirm: () => handleDelete(item.id, item.provider)
		})
	}

	const handleDelete = async (id: string, provider: string) => {
		try {
			await deleteLoginMethod(id, provider)
			toast.success('Login method removed')
		} catch (err) {
			toast.error('Failed to remove login method')
		}
		setModalState(false)
	}

	const handleConfirmEmailChange = async () => {
		try {
			await changeMainEmail({ email: emailValue.value })
			handleUserLogOut()
		} catch (error) {
			setOpenModal({ changeEmailModal: false, changingEmailModal: false })
		}
	}

	const handleEmailChange = (e: ConfigValueItem) => {
		setEmailValue(e)
	}

	const handleUserLogOut = () => {
		setOpenModal({ ...openModal, changingEmailModal: false })
		logout()
	}

	const renderLoginTypeName = (connection: string): string | undefined => {
		if (connection === 'google-oauth2') return 'Google Account'
		if (connection === 'facebook') return 'Facebook Account'
		if (connection === 'linkedin') return 'LinkedIn Account'
		if (
			connection === 'Username-Password-Authentication' ||
			connection === 'stage-database'
		)
			return 'Email and Password'
	}

	const renderSocialIcons = (
		isSocial: boolean,
		connection: string
	): ReactElement => {
		if (isSocial) {
			if (connection === 'google-oauth2')
				return (
					<div>
						<RiGoogleLine className="text-500 w-6 h-6" />
					</div>
				)
			if (connection === 'facebook')
				return (
					<div>
						<RiFacebookLine className="text-500 w-6 h-6" />
					</div>
				)
			if (connection === 'linkedin')
				return (
					<div>
						<RiLinkedinLine className="text-500 w-6 h-6" />
					</div>
				)
		}
		return (
			<div>
				<RiLockPasswordLine className="text-500 w-6 h-6" />
			</div>
		)
	}

	return (
		<>
			<div className="flex w-full items-center justify-between overflow-hidden space-x-4 mt-6">
				{renderSocialIcons(item.is_social, item.connection)}
				<div className="flex-grow">
					<dl>
						<dd>
							<div className="text-t-secondary text-sm">
								<span className="mr-2">
									{renderLoginTypeName(item.connection)}
								</span>
							</div>
						</dd>
						<dt className="text-base text-t-default truncate max-w-[180px]">
							{item.email}
						</dt>
					</dl>
				</div>
				<div className="flex gap-3">
					{!item.is_social && !showDelete && (
						<GButton
							size="sm"
							variant="text"
							label="Change email"
							icon={RiMailLine}
							color="primary"
							onClick={() =>
								setOpenModal({ ...openModal, changeEmailModal: true })
							}
						/>
					)}
					{!item.is_social && (
						<GButton
							size="sm"
							variant="text"
							label="Reset password"
							icon={RiRefreshLine}
							// color="primary"
							onClick={() => renderUpdatePasswordModal()}
						/>
					)}
					{showDelete && (
						<GTooltip content={'Remove login method'}>
							<GButton
								variant="text"
								size="sm"
								color="danger"
								type="icon"
								icon={deleteIcon}
								onClick={() => renderDeleteModal()}
							/>
						</GTooltip>
					)}
				</div>
				<GDialog
					showFullSubTitle
					title="Change email address"
					subtitle={`Select a verified email address to use for password login.
						After changing the login email address, you will be logged out and prompted to log in again`}
					maxWidth="xl"
					onClose={() =>
						setOpenModal({ ...openModal, changeEmailModal: false })
					}
					open={openModal.changeEmailModal}
					showClose={false}
				>
					<div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 items-center">
						<dt className="text-sm font-medium text-gray-500">Email address</dt>
						<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 -mr-4">
							<div className="">
								<GListBox
									value={emailValue}
									onChange={(e) => handleEmailChange(e)}
									renderLabel={(s) => s.label}
									options={verifiedEmails}
								/>
							</div>
						</dd>
						<div className="flex justify-between sm:col-span-4 mt-3">
							<GButton
								size="sm"
								label="Cancel"
								onClick={() =>
									setOpenModal({ ...openModal, changeEmailModal: false })
								}
							/>
							<GButton
								size="sm"
								label="Confirm"
								color="primary"
								onClick={() =>
									setOpenModal({
										changeEmailModal: false,
										changingEmailModal: true
									})
								}
							/>
						</div>
					</div>
				</GDialog>
				<GConfirm
					title="Changing email address"
					description={`You are about to change your login email address to ${emailValue.value}. You will be logged out and you’ll need to log in again. Proceed?`}
					open={openModal.changingEmailModal}
					setOpen={() =>
						setOpenModal({ ...openModal, changingEmailModal: false })
					}
					onConfirm={handleConfirmEmailChange}
					loading={props.loading}
				>
					<></>
				</GConfirm>
			</div>
		</>
	)
}
