import {
	BingAdsAdAccountDTO,
	FacebookAdsAdAccountDTO,
	GoogleAdsAdAccountDTO
} from 'api-models'
import { AdAccountDTO } from 'api-models/src'
import { UserAvatar } from 'components'
import {
	GButton,
	GDialog,
	GInput,
	GSection,
	GTooltip
} from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'
import { InfoItem, InfoItemGroup } from 'components/basic-blocks/g-info-table'
import { PlanNames } from 'config'
import { useAdminStore, useUiStore } from 'store'
import { useShallow } from 'zustand/react/shallow'
import moment from 'moment-timezone'
import {
	RiEarthLine,
	RiFacebookLine,
	RiGoogleLine,
	RiLoginCircleLine
} from 'react-icons/ri'
import { SiGoogleads } from 'react-icons/si'
import { NavLink, useNavigate } from 'react-router-dom'

import { removeFadPrefix } from 'utils'
import { PlatformIcon } from '../../../../../../components/platform-icon'
import { useState } from 'react'

export const InfoSection = (props: { ppcAccount: AdAccountDTO }) => {
	const [openDelete, setOpenDelete] = useState(false)
	const [cleanImported, setCleanImported] = useState(true)
	const { ppcAccount } = props
	const navigate = useNavigate()

	const { setImpersonation, cleanExclusions, syncExclusions } = useAdminStore(
		useShallow((state) => ({
			setImpersonation: state.setImpersonation,
			cleanExclusions: state.cleanExclusions,
			syncExclusions: state.syncExclusions
		}))
	)
	const isLoading = useUiStore((s) => s.isLoading)

	const impersonate = async () => {
		try {
			await setImpersonation(ppcAccount.user_info?.user_id ?? '')
			navigate('/')
		} catch (err) {
			console.log(err)
		}
	}

	const handleCleanExclusions = async () => {
		await cleanExclusions(ppcAccount.id, true)
		setOpenDelete(false)
	}

	const handleSyncExclusions = async () => {
		await syncExclusions(ppcAccount.id)
	}

	const GoogleAdAccount = ppcAccount as GoogleAdsAdAccountDTO
	const googleLinkType = GoogleAdAccount.details.link.type

	const FacebookAdAccount = ppcAccount as FacebookAdsAdAccountDTO
	const BingAdAccount = ppcAccount as BingAdsAdAccountDTO

	const googleAccessLink = {
		oauth: {
			label: GoogleAdAccount.details.link.email,
			icon: (
				<div className={'bg-red-500 rounded-full p-1'}>
					<RiGoogleLine className="text-white w-5 h-5" />
				</div>
			)
		},
		mcc: {
			label: GoogleAdAccount.details.link.mcc_account_number,
			icon: (
				<div className={'bg-primary-500 rounded-full p-1'}>
					<SiGoogleads className="text-white w-5 h-5" />
				</div>
			)
		},
		direct: { label: 'NONE', icon: null }
	}

	return (
		<>
			<GSection>
				<InfoItemGroup
					title="Basic information"
					className="-mt-5 border-t-0 rounded-t-lg"
				>
					<InfoItem label="PPC account ID" className="overflow-clip">
						<div className="flex justify-between items-center gap-2">
							<code className="text-red-600">{ppcAccount.id}</code>
						</div>
					</InfoItem>
					<InfoItem label="Name" className="flex items-center">
						<div>{ppcAccount.name}</div>
						<div className="text-t-secondary">
							{removeFadPrefix(GoogleAdAccount.details.account_number)}
						</div>
					</InfoItem>
					<InfoItem label="Platform" className="flex items-center">
						{ppcAccount.platform === 'GADS' && (
							<div className="flex gap-2 items-center">
								<PlatformIcon platform={ppcAccount.platform} />
								<div>Google Ads</div>
							</div>
						)}
						{ppcAccount.platform === 'FADS' && (
							<div className="flex gap-2 items-center">
								<PlatformIcon platform={ppcAccount.platform} />
								<div>Meta Ads</div>
							</div>
						)}
						{ppcAccount.platform === 'BADS' && (
							<div className="flex gap-2 items-center">
								<PlatformIcon platform={ppcAccount.platform} />
								<div>Meta Ads</div>
							</div>
						)}
					</InfoItem>
					<InfoItem label="Access link" className="flex items-center">
						{ppcAccount.platform === 'GADS' && (
							<div className="flex gap-2 items-center">
								{googleLinkType !== 'direct' ? (
									<>
										<div>{googleAccessLink[googleLinkType].icon}</div>
										<div>{googleAccessLink[googleLinkType].label}</div>
									</>
								) : (
									'None'
								)}
							</div>
						)}
						{ppcAccount.platform === 'FADS' && (
							<div className="flex gap-2 items-center">
								<>
									<div>
										<div className={'bg-primary-500 rounded-full p-1'}>
											<RiFacebookLine className="text-white w-5 h-5" />
										</div>
									</div>
									<div>{FacebookAdAccount.details.link.email}</div>
								</>
							</div>
						)}
						{ppcAccount.platform === 'BADS' && (
							<div className="flex gap-2 items-center">
								<PlatformIcon platform={ppcAccount.platform} />
								<div>{BingAdAccount.details.link.email}</div>
							</div>
						)}
					</InfoItem>
					<InfoItem label="Owner" className="flex items-center">
						<div className="flex space-x-2 items-center">
							<div className="flex items-center">
								<GButton
									shape="square"
									type="icon"
									icon={RiLoginCircleLine}
									loading={isLoading('ADMIN_SET_IMPERSONATION')}
									variant="contained"
									color="primary"
									size="xs"
									onClick={impersonate}
								>
									Impersonate
								</GButton>
							</div>
							<div className="flex space-x-2 items-center">
								<UserAvatar size={7} src={ppcAccount.user_info?.avatar} />
								<NavLink to={`/admin/user/${ppcAccount.user_info?.user_id}`}>
									<div className="flex flex-col group cursor-pointer">
										<div className="font-semibold text-primary-500 group-hover:text-primary-600">
											{ppcAccount.user_info?.name}
										</div>
										<div className="text-gray-500">
											{ppcAccount.user_info?.email}
										</div>
									</div>
								</NavLink>
							</div>
						</div>
					</InfoItem>
					{ppcAccount.platform === 'GADS' && (
						<>
							<InfoItem label="Sync Exclusion" className="flex items-center">
								<div>
									<GTooltip content="This will fetch & sync exclusion from user google Ad account">
										<GButton
											size="md"
											color="primary"
											loading={isLoading('ADMIN_SYNC_EXCLUSIONS')}
											labelClassName="font-bold"
											label="Sync Exclusion"
											onClick={handleSyncExclusions}
										/>
									</GTooltip>
								</div>
							</InfoItem>
							<InfoItem
								label="Clean Imported Exclusion"
								className="flex items-center"
							>
								<div>
									<GButton
										size="md"
										color="danger"
										labelClassName="font-bold"
										loading={isLoading('ADMIN_CLEAN_EXCLUSIONS')}
										label="Clean Exclusion"
										onClick={() => setOpenDelete(true)}
									/>
									<GDialog
										open={openDelete}
										onClose={() => setOpenDelete(false)}
										showClose={false}
										maxWidth="2xl"
									>
										<div>
											<p className="font-bold text-xl border-b pb-4">
												Clean Imported Exclusions
											</p>
											<p className="py-4">
												Imported exclusion are those which we sync & import from
												user google ad account these imported exclusions will
												expired after 15 days.
											</p>
											{/*<div className="flex items-center py-2">*/}
											{/*	<GCheckbox*/}
											{/*		label="Delete only imported Exclusions"*/}
											{/*		name={'deactivate'}*/}
											{/*		checked={cleanImported}*/}
											{/*		onChange={(v) => setCleanImported(v.target.checked)}*/}
											{/*	/>*/}
											{/*</div>*/}
											<div className="flex justify-between items-center pt-6">
												<GButton
													disabled={!ppcAccount.id}
													label={'Cancel'}
													color={'neutral'}
													labelClassName="font-bold"
													variant="text"
													size="md"
													onClick={() => setOpenDelete(false)}
												/>
												<GButton
													disabled={!ppcAccount.id}
													label={'Delete'}
													color={'danger'}
													variant="contained"
													size="md"
													loading={isLoading('ADMIN_CLEAN_EXCLUSIONS')}
													onClick={handleCleanExclusions}
												/>
											</div>
										</div>
									</GDialog>
								</div>
							</InfoItem>
						</>
					)}
					<InfoItem label="Workspace" className="flex items-center">
						<div className="flex space-x-4">
							<UserAvatar size={7} name={ppcAccount.user_info?.name} />
							<div className="flex flex-col justify-center">
								<NavLink to={`/admin/subscription/${ppcAccount.account_id}`}>
									<div className="font-medium text-primary-500 hover:text-primary-600 cursor-pointer">
										{ppcAccount.user_info?.name}
									</div>
								</NavLink>
								<div className="text-xs text-gray-500">
									{PlanNames[ppcAccount?.account_info?.tier || ''] || ''}
								</div>
							</div>
						</div>
					</InfoItem>
					<InfoItem label="Website" className="flex items-center">
						<div className="flex gap-2 items-center">
							<div>
								<RiEarthLine
									className={'w-7 h-7'}
									style={{ color: ppcAccount.color || '#9c73d2' }}
								/>
							</div>
							<div className="flex flex-col justify-center">
								<NavLink to={`/admin/website/${ppcAccount.asset_id}`}>
									<div className="font-medium text-primary-500 hover:text-primary-600 cursor-pointer truncate w-40">
										{ppcAccount.asset_info?.name}
									</div>
								</NavLink>
							</div>
						</div>
					</InfoItem>
					<InfoItem label="Created" className="flex items-center">
						<span className="text-gray-500">
							{ppcAccount.created_at
								? moment(ppcAccount.created_at).format(
										'MMMM Do, YYYY - hh:mm:ss A'
								  )
								: 'N/A'}
						</span>
					</InfoItem>
					<InfoItem label="Status" className="flex items-center">
						<div className="flex">
							{ppcAccount.status === 'ACTIVE' ? (
								<GBadge text={ppcAccount.status} color="green" />
							) : (
								<GBadge text="INACTIVE" color="red" />
							)}
						</div>
					</InfoItem>
					{ppcAccount.platform === 'GADS' && (
						<>
							<InfoItem
								label="Original tracking template"
								className="flex items-center"
							>
								<GInput
									value={GoogleAdAccount.details.tracking_template || 'N/A'}
									disabled
									onChange={() => {
										return
									}}
									className="text-red-600"
								/>
							</InfoItem>
							<InfoItem label="Tracking template" className="flex items-center">
								<GInput
									value={GoogleAdAccount.preferences.tracking_template || 'N/A'}
									disabled
									onChange={() => {
										return
									}}
									className="text-red-600"
								/>
							</InfoItem>

							<InfoItem
								label="Automated tracking"
								className="flex items-center"
							>
								<div className="flex gap-2">
									{ppcAccount.preferences?.automated_tracking && (
										<GBadge text="On" color="green" />
									)}
									{ppcAccount.preferences?.set_tracking_template === false && (
										<GBadge text="Suffix only" color="amber" />
									)}
								</div>
							</InfoItem>

							<InfoItem
								label="Original URL suffix"
								className="flex items-center"
							>
								<GInput
									value={GoogleAdAccount.details.final_url_suffix || 'N/A'}
									disabled
									onChange={() => {
										return
									}}
									className="text-red-600"
								/>
							</InfoItem>
							<InfoItem label="Final URL suffix" className="flex items-center">
								<GInput
									value={GoogleAdAccount.preferences.url_suffix || 'N/A'}
									disabled
									onChange={() => {
										return
									}}
									className="text-red-600"
								/>
							</InfoItem>
						</>
					)}
					{ppcAccount.platform === 'FADS' && (
						<>
							<InfoItem label="Custom audience" className="flex items-center">
								{FacebookAdAccount.details.custom_audience_id ? (
									<div className="flex text-gray-500">
										<span>{FacebookAdAccount.details.custom_audience_id}</span>
										<span className="mx-2">-</span>
										<span>
											{FacebookAdAccount.details.custom_audience_name}
										</span>
									</div>
								) : (
									<span className="text-gray-500">N/A</span>
								)}
							</InfoItem>
							<InfoItem
								label="Automatic exclusion settings"
								className="flex items-center"
							>
								<GInput
									value={
										FacebookAdAccount.preferences.set_custom_audience
											? 'Enabled'
											: 'Disabled'
									}
									disabled
									onChange={() => {
										return
									}}
									className="text-red-600"
								/>
							</InfoItem>
						</>
					)}
					{ppcAccount.platform === 'BADS' && (
						<>
							<InfoItem label="Tracking template" className="flex items-center">
								<GInput
									value={BingAdAccount?.preferences?.tracking_template || 'N/A'}
									disabled
									onChange={() => {
										return
									}}
									className="text-red-600"
								/>
							</InfoItem>

							<InfoItem
								label="Automated tracking"
								className="flex items-center"
							>
								<div className="flex gap-2">
									{BingAdAccount?.preferences?.automated_tracking && (
										<GBadge text="On" color="green" />
									)}
									{BingAdAccount?.preferences?.set_tracking_template ===
										false && <GBadge text="Suffix only" color="amber" />}
								</div>
							</InfoItem>
							<InfoItem
								label="Automatic exclusion settings"
								className="flex items-center"
							>
								<GInput
									value={
										BingAdAccount?.preferences?.set_custom_audience
											? 'Enabled'
											: 'Disabled'
									}
									disabled
									onChange={() => {
										return
									}}
									className="text-red-600"
								/>
							</InfoItem>
							<InfoItem label="Final URL suffix" className="flex items-center">
								<GInput
									value={BingAdAccount?.preferences?.url_suffix || 'N/A'}
									disabled
									onChange={() => {
										return
									}}
									className="text-red-600"
								/>
							</InfoItem>
						</>
					)}
				</InfoItemGroup>
			</GSection>
		</>
	)
}
