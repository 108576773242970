import { BiRadioCircleMarked } from 'react-icons/bi'
import { RiErrorWarningLine, RiQuestionLine } from 'react-icons/ri'
import { GSection, GTooltip } from '../../../../components/basic-blocks'
import { LineChart } from '../../../../components/charts/line-chart'
import { affiliateTimelineColors, IAffiliateTimelineDTO } from './types'

export const AffiliateTimeline = ({
	data,
	hasData,
	loading
}: {
	hasData: boolean
	data: IAffiliateTimelineDTO[]
	loading: boolean
}) => {
	return (
		<GSection
			loading={loading}
			className="bg-white px-4 py-4 text-t-default divide-gray-100 rounded mb-6"
		>
			<div className="text-t-default text-md flex flex-row items-center gap-x-1">
				<span className="font-bold">Last 30-Day Performance</span>
				<GTooltip
					content={
						<div className="flex flex-col">
							<span>
								Timeline showing daily traffic, includes total number of
							</span>
							<span>clicks, trials and conversion numbers for each day.</span>
						</div>
					}
				>
					<RiQuestionLine className="w-4 h-4 text-t-dark" />
				</GTooltip>
			</div>
			{hasData ? (
				<>
					<LineChart
						data={data}
						colors={affiliateTimelineColors}
						formatter={(v) => v.toLocaleString()}
						abbreviate={false}
						noBottomAxis
						enablePoints={false}
						lineWidth={4}
					/>
					<div className="w-[20%] m-auto">
						<div className="flex flex-row items-center gap-x-1">
							{data.map((item, index) => (
								<div key={index} className="flex items-center mr-4">
									<BiRadioCircleMarked
										className={`w-4 h-4 text-${item.color}-500 mr-1`}
									/>
									<span className="text-t-secondary text-base font-bold">
										{item.id}
									</span>
								</div>
							))}
						</div>
					</div>
				</>
			) : (
				<div className="flex flex-col items-center justify-center w-full p-8 text-center">
					<RiErrorWarningLine className="w-8 h-8 text-gray-500" />
					<h3 className="mt-2 text-md font-medium text-gray-700">
						No traffic data
					</h3>
				</div>
			)}
		</GSection>
	)
}
