import { useState } from 'react'

import { DemoBreadcrumbs } from 'components/demo-breadcrumbs'
import { AdAccount } from './components/ad-account'
import { Campaign } from './components/campaign/campaign'
import { Website } from './components/website'

import { AdAccountDTO } from 'api-models'
import { useAssetStore } from 'store'
import { useShallow } from 'zustand/react/shallow'
import { BreadcrumbsProps } from './types'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const Breadcrumbs = ({
	asset,
	handleWebsiteClickSideEffect,
	selectedAdAccount,
	setSelectedAdAccount,
	setSelectedAdAccountSideEffect,
	showCampaignSelector,
	demoMode
}: BreadcrumbsProps) => {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const breadCampaign = searchParams.get('breadCampaign')
	const updateCampaignQueryParam = () => {
		const newParams = new URLSearchParams(searchParams)
		newParams.delete('breadCampaign')
		navigate({
			pathname: window.location.pathname,
			search: newParams.toString()
		})
	}
	const { adAccounts, campaigns } = useAssetStore(
		useShallow((s) => ({
			adAccounts: s.adAccounts,
			campaigns: s.campaigns
		}))
	)

	const filteredCampaigns = campaigns.filter(
		(c) => c.ad_account_id === selectedAdAccount?.id
	)

	const [showAdAccounts, setShowAdAccounts] = useState(false)
	const [showCampaigns, setShowCampaigns] = useState(false)

	const handleWebsiteClick = () => {
		setSelectedAdAccount(null)

		if (breadCampaign) updateCampaignQueryParam()
		if (handleWebsiteClickSideEffect) handleWebsiteClickSideEffect()
		setShowAdAccounts(false)
		setShowCampaigns(false)
	}
	const handleSelectedAdAccount = (adAccount: AdAccountDTO) => {
		setSelectedAdAccount(adAccount)
		if (breadCampaign) updateCampaignQueryParam()
		if (setSelectedAdAccountSideEffect)
			setSelectedAdAccountSideEffect(adAccount)
	}

	const handleShowAdAccounts = () => setShowAdAccounts((prev) => !prev)
	const handleShowCampaigns = () => setShowCampaigns((prev) => !prev)

	if (demoMode) {
		return <DemoBreadcrumbs />
	}

	return (
		<div className="flex items-center px-4">
			<Website
				asset={asset}
				handleWebsiteClick={handleWebsiteClick}
				isSelected={!selectedAdAccount}
			/>
			{adAccounts.length > 0 && (
				<>
					<AdAccount
						selectedAdAccount={selectedAdAccount}
						adAccounts={adAccounts}
						isSelected={!!(selectedAdAccount && !breadCampaign)}
						handleSelectedAdAccount={handleSelectedAdAccount}
						handleShowAdAccounts={handleShowAdAccounts}
						showAdAccounts={showAdAccounts}
					/>
					{showCampaignSelector &&
						selectedAdAccount &&
						campaigns.length > 0 && (
							<>
								<Campaign
									campaigns={filteredCampaigns}
									handleShowCampaigns={handleShowCampaigns}
									showCampaigns={showCampaigns}
								/>
							</>
						)}
				</>
			)}
		</div>
	)
}
