import moment from 'moment'

export const AffiliateStatsData: {
	label: string
	key: string
	tooltip: string
	value: number | string
}[] = [
	{
		label: 'Clicks',
		key: 'clicks',
		tooltip: 'Number of clicks the affiliate link got',
		value: 0
	},
	{
		label: 'Trials',
		key: 'trials',
		tooltip: 'Number of referees signed up for trial',
		value: 0
	},
	{
		label: 'Conversions',
		key: 'conversions',
		tooltip: 'Number of referees became paying subscribers',
		value: 0
	},
	{
		label: 'Commission',
		key: 'commission',
		tooltip: 'Total commission earned from the trials and paid conversions',
		value: 0
	},
	{
		label: 'Cancellation',
		key: 'cancellations',
		tooltip: 'Number of referees who cancelled their subscription',
		value: 0
	}
]
export interface IAffiliateStats {
	cancellations: number | null
	clicks: number | null
	commission: number | null
	conversions: number | null
	trials: number | null
}
const affiliateTimelineData: IAffiliateTimelineDTO[] = [
	{
		id: 'Clicks',
		color: 'primary',
		data: []
	},
	{
		id: 'Trials',
		color: 'purple',
		data: []
	},
	{
		id: 'Conversions',
		color: 'green',
		data: []
	}
]

export interface IAffiliateTimelineDTO {
	id: string
	color: string
	data: { x: string; y: number }[]
}

export const transformTimelineData = (
	data: Array<{
		date: string
		count: {
			clicks: number
			trials: number
			conversions: number
		}
	}>
): IAffiliateTimelineDTO[] => {
	if (!data) return affiliateTimelineData
	const sortedData = data.sort((a, b) =>
		moment(a.date).isAfter(b.date) ? 1 : -1
	)
	const mapData = (key: 'clicks' | 'trials' | 'conversions') =>
		sortedData.map((item) => ({
			x: moment(item.date).format('YYYY-MM-DD'),
			y: item.count[key]
		}))

	const totalClicksData = mapData('clicks')
	const trialsData = mapData('trials')
	const conversionsData = mapData('conversions')

	return [
		{ id: 'Clicks', color: 'primary', data: totalClicksData },
		{ id: 'Trials', color: 'purple', data: trialsData },
		{ id: 'Conversions', color: 'green', data: conversionsData }
	]
}

export const affiliateTimelineColors = [
	{ color: 'primary', shade: 500 },
	{ color: 'purple', shade: 500 },
	{ color: 'green', shade: 500 }
]

export interface IAffiliateDashboardUsers {
	name: string
	status: 'CANCELLED' | 'CANCELLING' | 'SIGNUP' | 'TRIAL' | 'ACTIVE'
	tier?: string
	sale?: {
		amount: string
		interval: string
		interval_count: number
	}
	trial_date?: string
	conversion_date?: string
	commission?: string
}
