import { useAuth0 } from '@auth0/auth0-react'
import { GButton, GLoading } from 'components/basic-blocks'
import { OnBoarding } from 'components/onboarding/onboarding'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { GoVerified } from 'react-icons/go'
import { ImCross } from 'react-icons/im'
import { RiMailCheckLine, RiMailCloseLine } from 'react-icons/ri'
import { useSearchParams } from 'react-router-dom'

export const VerifyEmailPage = () => {
	const [search] = useSearchParams()
	const code = search.get('code')

	return (
		<>
			{code && <EmailVerifiedView code={code} />}
			{!code && <BrokenVerificationLink />}
		</>
	)
}

const EmailVerifiedView = (props: { code: string }) => {
	const { code } = props

	const { userStore } = useStores()
	const [status, setStatus] = useState<'loading' | 'error' | 'success'>(
		'loading'
	)

	const { logout } = useAuth0()

	const VerifyEmail = async () => {
		try {
			await userStore.verifyEmailByCode(code)
			setStatus('success')
			logout()
		} catch (e) {
			setStatus('error')
		}
	}
	useEffect(() => {
		VerifyEmail()
	}, [])

	if (status === 'loading') {
		return <GLoading />
	}

	const content = {
		success: {
			icon: <GoVerified className="text-green-500 h-16 w-16" />,
			title: 'Verification successful',
			description: 'Your email address is now verified.'
		},
		error: {
			icon: <ImCross className="text-red-500 h-16 w-16" />,
			title: 'Invalid verification code',
			description: 'The verification code has expired or is invalid.'
		}
	}
	return (
		<>
			<OnBoarding
				title={
					<div className="text-3xl text-[#fff] font-[800]">
						<p className="text-4xl">
							{status === 'success' ? (
								<RiMailCheckLine className="w-14 h-14 text-green-400 mb-4" />
							) : (
								<RiMailCloseLine className="w-14 h-14 text-red-400 mb-4" />
							)}
						</p>
						{content[status].title}
					</div>
				}
				description={status === 'success' && content.success.description}
				disableTopAction={status !== 'success'}
				backToLogin={status === 'success'}
			>
				<div className="w-full min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 ">
					<div className="max-w-max mx-auto">
						<main className="sm:flex flex flex-col items-center justify-start">
							{status !== 'success' && (
								<h1 className="text-md text-t-default tracking-tight">
									{content[status].description}
								</h1>
							)}
							{status === 'success' && <GLoading />}
							{status !== 'success' && (
								<div className="flex justify-center w-full">
									<GButton
										color="primary"
										iconPlacement="right"
										className="w-full mt-6"
										onClick={logout}
									>
										<span className="px-4">Back to login</span>
									</GButton>
								</div>
							)}
							{status === 'success' && (
								<div className="flex justify-center w-full">
									Redirecting you to login page...
								</div>
							)}
						</main>
					</div>
				</div>
			</OnBoarding>
		</>
	)
}

const BrokenVerificationLink = () => {
	return (
		<>
			<OnBoarding
				title={
					<div className="text-3xl text-white font-[800]">
						<p className="text-4xl">
							<RiMailCloseLine className="w-14 h-14 text-red-400 mb-4" />
						</p>
						Failed to Verify Email.
					</div>
				}
			>
				<div className="w-full min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 ">
					<div className="max-w-max mx-auto">
						<main className="sm:flex flex flex-col items-center justify-start">
							<h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
								{' '}
								Make sure you are using the right link and then try again.
							</h1>
							<GLoading />
						</main>
					</div>
				</div>
			</OnBoarding>
		</>
	)
}
