import { validateEmail } from '../../../utils/validation'
import { UserDTO } from '../../../../../api-models'
import { AdminAccessLevel } from '../../../../../api-models/dist/account/admin'

export const validateTeamInvites = (
	invite: { email: string; access: 'BASIC' | 'FULL' | 'SUPER' | '' }[],
	currentUserEmail: string
) => {
	const errors: { email: undefined | string; access: undefined | string }[] = []
	invite.map((e, i) => {
		errors.push({
			email: undefined,
			access: undefined
		})
		if (!e.email && !e.access) {
			errors[i].email = undefined
			errors[i].access = undefined
			return
		}

		if (e.email) {
			if (e.email === currentUserEmail) {
				errors[i].email = 'You cannot invite yourself'
			} else {
				errors[i].email = validateEmail(e.email)
			}
		} else {
			errors[i].email = ' '
		}
		if (e.access) {
			errors[i].access = undefined
		} else {
			errors[i].access = ' '
		}
	})
	const isValid = errors.every(
		(e) => e.email === undefined && e.access === undefined
	)
	return { errors, isValid }
}
export interface IAdminRole {
	name: string
	value: 'BASIC' | 'FULL' | 'SUPER'
	id: number
}

export const AdminRoleOptions: IAdminRole[] = [
	{ name: 'Super Admin', value: 'SUPER', id: 2 },
	{ name: 'Admin', value: 'FULL', id: 1 },
	{ name: 'Member', value: 'BASIC', id: 0 }
]

export const getAdminRoleByAccess = (
	access: AdminAccessLevel
): IAdminRole | null => {
	return AdminRoleOptions.find((role) => role.value === access) || null
}

export const getAdminRoleOptions = (user: UserDTO | null): IAdminRole[] => {
	const access = user?.admin?.access_level
	if (access === 'SUPER') {
		return AdminRoleOptions
	}
	if (access === 'FULL') {
		return AdminRoleOptions.filter((role) => role.id === 0)
	}
	return []
}

export interface AdminTeamInvites {
	id: string
	invited_by: string
	code: string
	user_email: string
	expires_at: Date
	access_level: AdminAccessLevel
}

export interface AdminTeamUser extends UserDTO {
	invite: AdminTeamInvites | null
}
