import { AccountChecklistView } from 'components/account-checklist/view'
import { GTransition } from 'components/basic-blocks'
import { GSlideOver } from 'components/basic-blocks/g-slide-over'
import { ForceInstallTC } from 'components/install-tc/view'
import { UserHeader } from 'layout/navbar/user-header'
import { useState } from 'react'
import { RiCheckFill, RiListSettingsLine } from 'react-icons/ri'
import { TbHeartbeat } from 'react-icons/tb'
import {
	useAccountStore,
	useAdminStore,
	useAssetStore,
	useUiStore,
	useUserStore
} from 'store'
import { useShallow } from 'zustand/react/shallow'
import DarkLogoIcon from '../../assets/img/apple-touch-icon-dark.svg'
import LogoIcon from '../../assets/img/apple-touch-icon.svg'
import Logo from '../../assets/img/cg-logo-light.svg'
import DarkLogo from '../../assets/img/cg-logo-white.svg'
import { AdminMenu } from './menus'
import { AssetMenu } from './menus/asset-menu'
import { NavButtonItem, NavItem } from './nav-item'
import { useChecklistStore } from './store'
import { LuUser } from 'react-icons/lu'
import { AdminAccessTypes, hasAdminAccess } from '../../utils/admin'

export const Sidebar = () => {
	const {
		context,
		mobileSidebarOpen,
		setMobileSidebarOpen,
		desktopSidebarCollapsed
	} = useUiStore(
		useShallow((state) => ({
			context: state.context,
			mobileSidebarOpen: state.mobileSidebarOpen,
			setMobileSidebarOpen: state.setMobileSidebarOpen,
			desktopSidebarCollapsed: state.desktopSidebarCollapsed
		}))
	)
	const { asset, demoMode } = useAssetStore(
		useShallow((state) => ({
			asset: state.asset,
			demoMode: state.demoMode
		}))
	)
	const user = useUserStore((state) => state.user)
	const account = useAccountStore((state) => state.account)
	const impersonation = useAdminStore((state) => state.impersonation)

	const accountId = demoMode && !account?.id ? 'workspace-demo' : account?.id
	const assetId = demoMode && !asset?.id ? 'website-demo' : asset?.id

	const tc_installed = !!asset?.tc_installed
	const cc_installed = !!asset?.cc_installed

	const disableChecklist = demoMode
		? false
		: accountId && !!assetId && tc_installed && cc_installed

	const menuContent = (
		<>
			<GTransition show={context === 'admin'} swap>
				<AdminMenu />
			</GTransition>
			<GTransition show={context !== 'admin'} swap>
				<AssetMenu />
			</GTransition>
		</>
	)

	const { checklistOpened, openChecklist } = useChecklistStore()

	// useEffect(() => {
	// 	if (account) {
	// 		if (detected_spend < 5000 && !has_credit_card) {
	// 			setOpen(true)
	// 		} else {
	// 			setOpen(false)
	// 		}
	// 	}
	// }, [detected_spend, has_credit_card])
	const [openForceTCInstall, setOpenForceTCInstall] = useState(false)

	// useEffect(() => {
	// 	if (account) {
	// 		if (
	// 			allAssets &&
	// 			allAssets.length < 2 &&
	// 			!tc_installed &&
	// 			!demoMode &&
	// 			context !== 'admin' &&
	// 			!impersonation &&
	// 			!window.location.pathname.includes('tracking-code')
	// 		) {
	// 			console.log('open force tc install', allAssets, tc_installed)
	// 			setOpenForceTCInstall(true)
	// 		} else {
	// 			setOpenForceTCInstall(false)
	// 		}
	// 	}
	// }, [account, tc_installed])

	return (
		<>
			<div
				className={
					'hidden md:flex md:flex-col md:fixed  md:inset-y-0 bg-white border-r border-r-gray-100 relative z-20'
				}
			>
				<div className="flex items-center justify-center h-[28px] my-[22px]">
					{!desktopSidebarCollapsed ? (
						<picture>
							<source
								srcSet={DarkLogo}
								className="h-[28px]"
								media="(prefers-color-scheme: dark)"
							/>
							<img className="h-[28px]" src={Logo} alt="logo" />
						</picture>
					) : (
						<picture>
							<source
								srcSet={DarkLogoIcon}
								className="h-[28px]"
								media="(prefers-color-scheme: dark)"
							/>
							<img className="h-[28px]" src={LogoIcon} alt="logo" />
						</picture>
					)}
				</div>
				<div className="border-b border-b-gray-50 mx-4"></div>
				<div
					className={`px-2 pb-4 z-40 overflow-auto overflow-x-hidden transition-all ${
						!desktopSidebarCollapsed ? 'w-[200px]' : 'w-auto'
					}`}
				>
					{menuContent}
				</div>
				<div
					className={`divide-y-2 px-2 z-50 flex flex-col flex-grow flex-1 justify-end ${
						!desktopSidebarCollapsed ? 'w-[200px]' : 'w-auto'
					}`}
				>
					{context !== 'admin' && !disableChecklist && (
						<>
							<div>
								<NavButtonItem
									className="!text-red-500"
									label="Set-up checklist"
									icon={RiCheckFill}
									onClick={() => openChecklist(true)}
								/>
							</div>
							<AccountChecklistView
								open={checklistOpened}
								close={() => openChecklist(false)}
							/>
						</>
					)}
					{!demoMode &&
						context !== 'admin' &&
						!impersonation &&
						!window.location.pathname.includes('tracking-code') && (
							<>
								<ForceInstallTC
									account={account}
									asset={asset}
									open={openForceTCInstall}
									close={() => setOpenForceTCInstall(false)}
								/>
							</>
						)}
				</div>
				{/* {context !== 'admin' && !demoMode && !impersonation && account && (
					<>
						<AccountSpendMinimumView
							account={account}
							open={open}
							detected_spend={detected_spend}
							has_credit_card={!!has_credit_card}
							close={() => openChecklist(false)}
						/>
					</>
				)} */}
				<div
					className={`divide-y-2 px-2 gap-4 divide-card-border mb-[24px] z-50 flex flex-col flex-grow flex-1 justify-end ${
						!desktopSidebarCollapsed ? 'w-[200px]' : 'w-auto'
					}`}
				>
					{context !== 'admin' && (
						<>
							<div className="space-y-2">
								<NavItem
									label="Share the Love"
									activeText={'/affiliate'}
									activeIconClass="text-red-500"
									className="relative"
									badge={{
										className:
											'absolute top-[-6px] right-[-5px] px-3 py-1 rounded-md',
										text: 'New',
										color: 'green'
									}}
									to={() =>
										`/workspace/affiliate${
											!user?.affiliate_profile?.affiliate_id
												? '?tab=about'
												: '?tab=dashboard'
										}`
									}
									icon={TbHeartbeat}
								/>
								<NavItem
									className="settings-bar"
									label="Settings"
									activeText={'/settings'}
									to={() =>
										accountId === 'workspace-demo'
											? '/settings/user/profile'
											: assetId === 'website-demo' ||
											  account?.setup?.step !== 'done'
											? `/settings/workspace/${accountId}/preferences`
											: `/settings/workspace/${accountId}/asset/${assetId}/site-preference`
									}
									icon={RiListSettingsLine}
								/>
							</div>
						</>
					)}
					<div className="pt-4">
						{hasAdminAccess(user, AdminAccessTypes.view_cg_staff) &&
							context === 'admin' && (
								<NavItem label="Team" to={() => '/admin/team'} icon={LuUser} />
							)}
						<UserHeader />
					</div>
				</div>
			</div>
			{/* mobile sidebar start*/}
			<div className="flex md:hidden">
				<GSlideOver open={mobileSidebarOpen} setOpen={setMobileSidebarOpen}>
					<div className="w-72 px-4 -z-50 overflow-auto overflow-x-hidden">
						{menuContent}
					</div>
				</GSlideOver>
			</div>
			{/* mobile sidebar end*/}
		</>
	)
}
