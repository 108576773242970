import { ReportRequestPeriodDTO } from 'api-models'
import moment from 'moment'

export const automatedIntervalOptions: {
	label: string
	value: ReportRequestPeriodDTO
}[] = [
	{ label: 'Weekly', value: { unit: 'week', value: 1 } },
	{ label: 'Every 2 weeks', value: { unit: 'week', value: 2 } },
	{ label: 'Monthly', value: { unit: 'month', value: 1 } },
	{ label: 'Every 2 months', value: { unit: 'month', value: 2 } },
	{ label: 'Quarterly', value: { unit: 'month', value: 3 } }
]

export const onDemandIntervalOptions: {
	label: string
	value: { from: Date; to: Date }
}[] = [
	{
		label: 'Last 7 days',
		value: {
			from: moment().subtract(7, 'days').startOf('day').toDate(),
			to: moment().endOf('day').toDate()
		}
	},
	{
		label: 'Last 30 days',
		value: {
			from: moment().subtract(30, 'days').startOf('day').toDate(),
			to: moment().endOf('day').toDate()
		}
	},
	{
		label: 'Last week',
		value: {
			from: moment().subtract(1, 'week').startOf('week').toDate(),
			to: moment().subtract(1, 'week').endOf('week').toDate()
		}
	},
	{
		label: 'Last month',
		value: {
			from: moment().subtract(1, 'month').startOf('month').toDate(),
			to: moment().subtract(1, 'month').endOf('month').toDate()
		}
	},
	{
		label: 'Last 2 months',
		value: {
			from: moment().subtract(2, 'month').startOf('month').toDate(),
			to: moment().subtract(1, 'month').endOf('month').toDate()
		}
	},
	{
		label: 'Last 3 months',
		value: {
			from: moment().subtract(3, 'month').startOf('month').toDate(),
			to: moment().subtract(1, 'month').endOf('month').toDate()
		}
	}
]

export const content = {
	DOMAIN: {
		title: 'Summary report',
		subTitle:
			'An overview of traffic quality, fraud activity and account performance'
	},
	CAMPAIGN: {
		title: 'Campaign report',
		subTitle: 'Threat and performance analysis for campaigns'
	},
	PLACEMENT: {
		title: 'Placements report',
		subTitle:
			'Threat and performance analysis for placements on Google Ads accounts'
	},
	KEYWORD: {
		title: 'Keywords report',
		subTitle:
			'Threat and performance analysis for keywords on Google Ads accounts'
	},
	ENGAGEMENT: {
		title: 'Engagement report',
		subTitle: 'Full analysis of visitor behavior and engagement on your website'
	},
	TRAFFIC: {
		title: 'Engagement report',
		subTitle: 'Full analysis of visitor behavior and engagement on your website'
	}
}
