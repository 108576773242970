import { GNotice } from 'components/basic-blocks/g-notice'
import { GNoticeType } from 'components/basic-blocks/g-notice/types'
import {
	buildCompletedNotice,
	buildDemoDataNotice,
	buildInActiveNotice,
	buildInSetupDemoDataNotice,
	buildMccLinkRequestNotice
} from 'components/basic-blocks/g-notice/utils'
import { BuildDashboardNoticesProps } from './types'
import { buildWarningNotices } from './utils'

export const DashboardNotices = ({
	isInSetup,
	setupStep,
	tc_installed,
	cc_installed,
	hasDashboardPerformanceFeature,
	accountId,
	assetId,
	demoMode,
	isInActive,
	mccLinkInviteAdAccounts = []
}: BuildDashboardNoticesProps) => {
	let notices: GNoticeType[]

	if (isInSetup) {
		notices = [...buildInSetupDemoDataNotice({ step: setupStep, accountId })]
	} else {
		const contextKey = `${assetId}-dashboard`
		const criticalNotices = [...buildDemoDataNotice(demoMode)]
		const warningNotices: GNoticeType[] = demoMode
			? []
			: buildWarningNotices({
				tc_installed,
				cc_installed,
				hasDashboardPerformanceFeature,
				accountId,
				assetId,
				contextKey
			})

		notices = [...criticalNotices, ...warningNotices]
	}

	if (!tc_installed && !demoMode) {
		notices = [...buildInSetupDemoDataNotice({ step: setupStep, accountId })]
	}
	if (setupStep === 'done' && tc_installed && !demoMode) {
		notices = [...buildCompletedNotice()]
	}
	if (isInActive) {
		notices = [...buildInActiveNotice({ accountId })]
	}

	if (setupStep === 'done' && mccLinkInviteAdAccounts?.length) {
		notices = [...buildMccLinkRequestNotice(mccLinkInviteAdAccounts, accountId)]
	}

	if (notices.length === 0) return null

	return (
		<div className="pl-2 pr-6">
			<GNotice notices={notices} />
		</div>
	)
}
