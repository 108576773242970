import { WebsiteAvatar } from 'components'
import { classNames } from 'utils'

import { WebsiteProps } from './types'

export const Website = ({
	asset,
	isSelected,
	handleWebsiteClick
}: WebsiteProps) => {
	return (
		<div
			className={classNames(
				'py-2 px-1 cursor-pointer',
				isSelected ? 'border-b-primary-300 border-b-2' : ''
			)}
			onClick={handleWebsiteClick}
		>
			<div className="flex items-center">
				<WebsiteAvatar website={asset} size={5} />
				<p
					className={classNames(
						'mx-2 text-t-default max-w-[100px] truncate',
						isSelected ? 'font-bold' : ''
					)}
				>
					{asset?.name}
				</p>
			</div>
		</div>
	)
}
