import { GListBox, GTooltip } from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'
import { RiAlarmWarningLine } from 'react-icons/ri'
import { NavLink, useNavigate } from 'react-router-dom'
import { classNames } from 'utils'
import { TabItem, TabsProps } from './types'

export const Tabs = (props: TabsProps) => {
	const { current, tabs, actions } = props
	const navigate = useNavigate()

	const handleMultiSelectChange = (val: TabItem) => {
		navigate(val.disabled ? '#' : val.to())
	}

	return (
		<div className="pt-2">
			<div className="sm:hidden mb-4">
				<GListBox<TabItem>
					value={tabs.find((tab) => tab.key === current)}
					options={tabs}
					placeholder={'Select Link'}
					onChange={(val) => handleMultiSelectChange(val)}
					renderLabel={(tab) => (
						<div
							className={tab.disabled ? 'text-gray-300' : 'hover:text-t-dark'}
						>
							{tab.label}
						</div>
					)}
				/>
			</div>
			<div className="hidden sm:block border-b-t-tab-border border-b">
				<div className="flex items-center space-x-6">
					<nav className="flex-1 -mb-px flex space-x-10" aria-label="Tabs">
						{tabs.map((tab: TabItem) => (
							<div key={tab.key}>
								{tab.show !== false && (
									<div
										className={classNames(
											tab.key === current
												? 'text-t-color-primary-tab border-b-t-color-tab-border border-b-2'
												: 'text-t-secondary',
											tab.disabled ? 'text-gray-300' : '',
											'pb-3 -mb-px'
										)}
									>
										<div className="flex space-x-2 items-center">
											{tab.disabled ? (
												<div key={tab.key}>
													<div className="flex items-center space-x-1">
														<span className="text-lg font-bold">
															{tab.label}
														</span>
														{tab?.infoTooltip}
													</div>
												</div>
											) : (
												<NavLink key={tab.key} to={tab.to()}>
													<div className="flex items-center space-x-1">
														<span className="text-lg font-bold">
															{tab.label}
														</span>
														{tab?.infoTooltip}
													</div>
												</NavLink>
											)}
											<GTooltip content={tab.tooltip}>
												{tab.notification ? (
													<GBadge
														color={tab.notification?.color}
														text={
															tab.notification.value?.toString() || (
																<RiAlarmWarningLine />
															)
														}
													/>
												) : (
													<></>
												)}
											</GTooltip>
										</div>
									</div>
								)}
							</div>
						))}
						<div className="flex-1"></div>
					</nav>
					<div className="self-end">{actions}</div>
				</div>
			</div>
		</div>
	)
}
