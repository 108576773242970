import { ProductAccountDTO } from 'api-models'
import { GTableColumn } from 'components/basic-blocks/g-table/types'
import moment from 'moment'
import {
	formatCurrency,
	formatPriceInterval,
	formatSpend,
	sanitizeURL
} from 'utils'
import { GDateTime } from 'components/basic-blocks/g-datetime'
import { GButton, GTooltip } from 'components/basic-blocks'
import { useNavigate } from 'react-router-dom'
import { PlanNames } from 'config'
import {
	planTierColorMapper,
	RiCloseLine,
	RiLoginCircleLine
} from 'utils/icons'
import { useStores } from 'hooks'
import { ReactElement, useEffect, useState } from 'react'
import { UserAvatar } from 'components'
import { GBadge } from 'components/basic-blocks/g-badge'

export const columns: Array<GTableColumn<ProductAccountDTO>> = [
	{
		label: '',
		path: 'triallingWorkspace',
		render: (row: ProductAccountDTO) => <WorkspaceColumn row={row} />,
		className: 'justify-start'
	},
	{
		label: 'Users',
		path: 'owner',
		render: (row: ProductAccountDTO) => <OwnerColumn account={row} />,
		className: 'justify-center'
	},
	{
		label: 'Plan price',
		path: 'planPrice',
		render: (row: ProductAccountDTO) => PriceColumn(row)
	},
	{
		label: 'Plan ad spend',
		path: 'planAdSpend',
		render: (row: ProductAccountDTO) => PlanAdSpendColumn(row),
		className: 'justify-center'
	},
	{
		label: 'Next event',
		path: 'nextEvent',
		render: (row: ProductAccountDTO) => EventColumn(row),
		sortKey: 'next_event',
		className: 'justify-center'
	},
	{
		label: 'Notice',
		path: 'Notices',
		render: (row: ProductAccountDTO) => <Notices account={row} />,
		className: 'justify-center'
	}
]

const WorkspaceColumn = (props: { row: ProductAccountDTO }) => {
	const navigate = useNavigate()
	const link = `/admin/subscription/${props.row.id}`
	const planName = PlanNames[props.row?.billing?.subscription?.plan.tier || '']
	const planIcon = planTierColorMapper(
		props.row.billing?.subscription?.plan.tier,
		3
	)
	const trialPeriod = props.row?.billing?.subscription?.type === 'TRIAL'

	return (
		<div className="flex justify-center items-center space-x-2">
			<>
				{planIcon}
				<div className="flex flex-col justify-center">
					<div
						className="font-bold text-lg text-t-color-primary-tab hover:text-primary-600 cursor-pointer truncate max-w-[300px]"
						onClick={() => navigate(link)}
					>
						{sanitizeURL(props.row.name)}
					</div>
					<div className="text-md text-t-default">{planName || ''}</div>
				</div>
				{trialPeriod && (
					<GBadge
						className="rounded-md bg-badge-selected align-center"
						text={
							<div className="w-full px-2 text-badge-text-t-default text-center">
								Trial
							</div>
						}
						color="primary"
					/>
				)}
			</>
		</div>
	)
}

const OwnerColumn = (props: { account: ProductAccountDTO }) => {
	const navigate = useNavigate()
	const { account } = props
	const { adminStore } = useStores()
	const [loading, setLoading] = useState(false)

	const link = `/admin/user/${account.user_info?.user_id}`
	const name =
		account.status === 'SETUP' ? account.user_info?.name : account.billing?.name
	const email =
		account.status === 'SETUP'
			? account.user_info?.email
			: account.billing?.email

	const impersonate = async () => {
		setLoading(true)
		try {
			await adminStore.setImpersonation(account.user_info?.user_id ?? '')
			setLoading(false)
			navigate('/')
		} catch (e) {
			setLoading(false)
		}
	}

	useEffect(() => {
		return () => {
			setLoading(false)
		}
	}, [])

	const getUserAccessInfo = (user: any) => {
		return user?.access_level === 'READ'
			? 'Analyst'
			: user?.access_level === 'WRITE'
			? 'Manager'
			: user?.access_level === 'OWNER'
			? 'Owner'
			: 'Other'
	}

	const getUserBadgeColor = (user: any) => {
		return user?.access_level === 'READ'
			? 'green'
			: user?.access_level === 'WRITE'
			? 'purple'
			: user?.access_level === 'OWNER'
			? 'primary'
			: 'primary'
	}

	return (
		<div className="flex space-x-2 items-center">
			<GTooltip
				persist
				content={
					<div className="w-80 flex flex-col">
						{account?.user_access?.map((user: any, index: number) => (
							<div
								key={index}
								className="w-full flex justify-between items-center py-2"
							>
								<div className="w-full flex justify-start items-center">
									<div className="mr-2">
										<GButton
											shape="square"
											type="icon"
											loading={loading}
											icon={RiLoginCircleLine}
											variant="contained"
											color="primary"
											size="xs"
											onClick={impersonate}
										>
											Impersonate
										</GButton>
									</div>
									<div className="flex space-x-2 items-center">
										<UserAvatar size={8} src={user?.avatar} />
										<div
											className="flex flex-col group cursor-pointer"
											onClick={() => navigate(link)}
										>
											<div className="font-semibold text-primary-500 group-hover:text-primary-600">
												{name}
											</div>
											<div className="text-gray-500 max-w-[180px] truncate">
												{email}
											</div>
										</div>
									</div>
								</div>
								<GBadge
									className="rounded-md bg-badge-selected items-center"
									text={getUserAccessInfo(user)}
									color={getUserBadgeColor(user)}
								/>
							</div>
						))}
					</div>
				}
			>
				<div className="flex -space-x-4">
					{account?.user_access?.map((user: any, index: number) => (
						<div className="flex space-x-2 items-center" key={index}>
							<UserAvatar size={8} src={user?.avatar} />
						</div>
					))}
				</div>
			</GTooltip>
		</div>
	)
}

const PriceColumn = (row: ProductAccountDTO) => {
	const status = row?.billing?.subscription?.status || 'NONE'
	const type = row?.billing?.subscription?.type || 'NONE'
	const accountStatus = row.status
	const plan = row.billing?.subscription?.plan
	const nextPlan = row.billing?.subscription?.next_plan

	let color: string
	if (status === 'ACTIVE') {
		color = 'green'
	} else {
		color = 'gray'
	}

	if (status === 'SWITCHING') {
		return (
			<div>
				<div className="flex flex-col justify-start gap-2">
					<GBadge
						text={
							<span>
								{formatCurrency(plan?.price)}{' '}
								{formatPriceInterval(plan?.interval, plan?.interval_count)}
							</span>
						}
						color="gray"
					/>
					<GBadge
						text={
							<span>
								{formatCurrency(nextPlan?.price)}{' '}
								{formatPriceInterval(
									nextPlan?.interval,
									nextPlan?.interval_count
								)}
							</span>
						}
						color="green"
					/>
				</div>
			</div>
		)
	}

	return (
		<div className="flex flex-col justify-start gap-2">
			{accountStatus === 'SETUP' ? (
				<div
					className={
						'text-center inline-flex rounded-full py-1 text-sm font-semibold text-t-secondary'
					}
				>
					{accountStatus === 'SETUP' && (
						<RiCloseLine className="h-5 w-5 mr-2 text-t-secondary" />
					)}
				</div>
			) : type === 'TRIAL' && nextPlan?.price !== undefined ? (
				<GBadge
					className={'rounded-md align-center'}
					text={
						<span>
							{formatCurrency(nextPlan?.price)}{' '}
							{formatPriceInterval(
								nextPlan?.interval,
								nextPlan?.interval_count
							)}
						</span>
					}
					color={color}
				/>
			) : type === 'TRIAL' && nextPlan?.price === undefined ? (
				<GBadge
					className={'rounded-md align-center'}
					text="No plan"
					color={'amber'}
				/>
			) : (
				<GBadge
					text={
						<span>
							{formatCurrency(plan?.price)}{' '}
							{formatPriceInterval(plan?.interval, plan?.interval_count)}
						</span>
					}
					color={color}
				/>
			)}
		</div>
	)
}

const PlanAdSpendColumn = (row: ProductAccountDTO) => {
	const type = row?.billing?.subscription?.type
	const status = row?.billing?.subscription?.status || 'NONE'
	const plan = row.billing?.subscription?.plan
	const nextPlan = row.billing?.subscription?.next_plan

	let color: string
	if (status === 'ACTIVE') {
		color = 'purple'
	} else {
		color = 'gray'
	}

	if (status === 'SWITCHING') {
		return (
			<div>
				<div className="flex flex-col justify-start gap-2 text-md">
					<GBadge
						className={'rounded-md align-center'}
						text={<span>{formatSpend(plan?.spend)}</span>}
						color="gray"
					/>
					<GBadge
						className={'rounded-md align-center'}
						text={<span>{formatSpend(nextPlan?.spend)}</span>}
						color="purple"
					/>
				</div>
			</div>
		)
	}

	if (type === 'TRIAL') {
		return (
			<div className="text-md">
				{nextPlan && nextPlan?.spend !== 0 ? (
					<GBadge
						className={'rounded-md align-center'}
						text={<span>{formatSpend(nextPlan?.spend)}</span>}
						color={color}
					/>
				) : (
					<GBadge
						className={'rounded-md align-center'}
						text="No plan"
						color={'amber'}
					/>
				)}
			</div>
		)
	}

	return (
		<div className="text-md">
			{plan?.spend !== 0 ? (
				<GBadge text={<span>{formatSpend(300)}</span>} color={color} />
			) : (
				<RiCloseLine className="h-5 w-5 mr-2 text-t-secondary" />
			)}
		</div>
	)
}

const EventColumn = (row: ProductAccountDTO) => {
	const status = row?.billing?.subscription?.status
	const type = row?.billing?.subscription?.type
	const date = row?.billing?.subscription?.next_billing_date
	const nextPlan = row?.billing?.subscription?.next_plan
	const endsAt = row?.billing?.subscription?.ends_at
	const endedAt = row?.billing?.subscription?.ended_at

	let title: ReactElement | string = ''
	const subtitle = moment(date || endsAt || endedAt).format('llll')

	if (status === 'ACTIVE') {
		if (type === 'TRIAL' && !nextPlan) {
			title = (
				<span>
					Cancels{' '}
					<span className="font-bold">
						<GDateTime date={endsAt || endedAt} />
					</span>
				</span>
			)
		} else {
			title = (
				<span>
					Starts{' '}
					<span className="font-bold">
						<GDateTime date={date} />
					</span>
				</span>
			)
		}
	}

	return (
		<div className="flex flex-1 justify-start">
			<div className="flex flex-col space-y-1 text-md">
				<div className="font-medium font-md text-t-default">{title}</div>
				{subtitle && <div className="text-sm text-t-secondary">{subtitle}</div>}
			</div>
		</div>
	)
}

const Notices = (props: { account: ProductAccountDTO }) => {
	const { account } = props

	const cancelReq = account.cancel_request
	const offerReq = account.offer_request

	const hasNotice = cancelReq || offerReq

	return (
		<div className="flex flex-col items-start space-y-2">
			{account.offer_request && <GBadge text={'Offer'} color="purple" />}
			{account.cancel_request && <GBadge text="Cancelling" color="red" />}
			{!account.billing?.subscription?.plan && (
				<GBadge text="No plan" color="amber" />
			)}
			{!hasNotice && <GBadge text="None" color="gray" />}
		</div>
	)
}
