import { ProductAccountDTO } from 'api-models'
import { WorkspaceAvatar } from 'components'
import { GButton, GSwitcher } from 'components/basic-blocks/'
import { GBadge } from 'components/basic-blocks/g-badge'
import { useMemo } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { RiArrowRightSLine, RiCheckLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { classNames, sanitizeURL, setItemsToLocalStorage } from 'utils'
import { capitalizeFirstLetter } from '../../../utils/text'
import { getAccountBadgeColorByStatus } from '../../../utils/user'
import { useAccountStore, useAssetStore, useUserStore } from 'store'
import { useShallow } from 'zustand/react/shallow'

export const AccountSwitcher = () => {
	const navigate = useNavigate()

	const {
		accounts,
		setAccount,
		account,
		setWantsToSeeDemoData,
		getSubscriptionByAccountId
	} = useAccountStore(
		useShallow((s) => ({
			accounts: s.accounts,
			setAccount: s.setAccount,
			account: s.account,
			setWantsToSeeDemoData: s.setWantsToSeeDemoData,
			getSubscriptionByAccountId: s.getSubscriptionByAccountId
		}))
	)
	const { accountsWithAssets, setAsset, setAssetForId, setDemoMode } =
		useAssetStore(
			useShallow((s) => ({
				accountsWithAssets: s.accountsWithAssets,
				setAsset: s.setAsset,
				setAssetForId: s.setAssetForId,
				setDemoMode: s.setDemoMode
			}))
		)
	const { user } = useUserStore((s) => ({ user: s.user }))
	const userAccessInfo = useMemo(() => {
		return account?.user_access?.find(
			(userAccess) => userAccess.user_id === user?.id
		)
	}, [account])

	const text =
		userAccessInfo?.access_level === 'READ'
			? 'Analyst'
			: userAccessInfo?.access_level === 'WRITE'
			? 'Manager'
			: userAccessInfo?.access_level === 'OWNER'
			? 'Owner'
			: 'Other'
	const color =
		userAccessInfo?.access_level === 'READ'
			? 'green'
			: userAccessInfo?.access_level === 'WRITE'
			? 'purple'
			: userAccessInfo?.access_level === 'OWNER'
			? 'primary'
			: 'primary'
	return (
		<>
			<div className="asset-switcher hidden md:flex items-center w-full">
				<GSwitcher<ProductAccountDTO>
					items={accounts}
					selectedItem={<SelectedItem />}
					filter={(item, query) =>
						item.name.toLowerCase().includes(query.toLowerCase())
					}
					renderOption={(i, handleClose) => renderOption(i, handleClose)}
					footer={<SwitcherContentFooter />}
					placeholder="Filter workspaces..."
					disableSearch
					position="right-start"
					offset={[0, -22]}
				/>
				<div className="block md:hidden">
					<GSwitcher<ProductAccountDTO>
						width="w-full"
						items={accounts}
						selectedItem={<SelectedItem />}
						filter={(item, query) =>
							item.name.toLowerCase().includes(query.toLowerCase())
						}
						renderOption={(i) => i.name.toString()}
						footer={<SwitcherContentFooter />}
						placeholder="Filter workspaces..."
						responsive={true}
						disableSearch
					/>
				</div>
			</div>
		</>
	)

	function renderOption(option: ProductAccountDTO, handleClose: () => void) {
		const path = window.location.pathname.split('/')
		const pageIdentifier = path[5]

		const settingsIdentifier = path.includes('settings')
		const workspaceIdentifier = path.includes('workspace')
		const assetIdentifier = path.includes('asset')
		const settingsSubIdentifier =
			path.includes('settings') && path[path.length - 1]

		const handleSelectingAccount = async (account: ProductAccountDTO) => {
			setAccount(account)
			account?.billing?.subscription && getSubscriptionByAccountId(account.id)
			const selectedAccount = accountsWithAssets.find(
				(item) => item.id === account?.id
			)
			const workspaceId = account.id
			const assetId = selectedAccount?.assets?.length
				? selectedAccount?.assets[0]?.id
				: 'website-demo'

			setItemsToLocalStorage([
				{ key: 'selected_asset', value: assetId },
				{ key: 'selected_account', value: workspaceId }
			])

			setWantsToSeeDemoData(false)
			settingsIdentifier
				? assetId === 'website-demo' && assetIdentifier
					? navigate(`/settings/workspace/${workspaceId}/preferences`)
					: navigate(
							`/settings${
								!workspaceIdentifier && !assetIdentifier ? '/user' : ''
							}${workspaceIdentifier ? `/workspace/${workspaceId}` : ''}${
								assetIdentifier ? `/asset/${assetId}` : ''
							}/${settingsSubIdentifier}`
					  )
				: navigate(
						`/workspace/${workspaceId}/asset/${assetId}/${
							pageIdentifier ? pageIdentifier : ''
						}`
				  )
			handleClose()

			if (selectedAccount && assetId === 'website-demo') {
				await setAssetForId(selectedAccount?.id, 'website-demo')
			} else if (selectedAccount?.assets?.length) {
				setDemoMode(false)
				setAsset(selectedAccount?.assets[0])
			}
		}

		const buildBadgeText = (account: ProductAccountDTO) => {
			if (account.status === 'INACTIVE') return 'Inactive'
			if (account.status === 'SETUP') {
				return account.setup?.step === 'subscription'
					? 'Pending activation'
					: 'Setup'
			}
			if (account?.billing?.subscription?.type === 'TRIAL') return 'Trial'
			return capitalizeFirstLetter(account.status)
		}
		const badgeText = buildBadgeText(option)

		return (
			<div
				key={option.id}
				onClick={() => handleSelectingAccount(option)}
				className={classNames(
					'text-t-heading font-bold hover:bg-gray-50 group flex items-center py-3 px-4 text-lg space-x-3 cursor-pointer w-full',
					`${option.id === account?.id && 'bg-gray-100'}`
				)}
			>
				<div
					className={`flex items-center justify-between gap-4 space-y-0 w-full ${
						option.id === account?.id && 'bg-gray-100'
					}}`}
				>
					<div className="flex items-center justify-start w-full">
						<div className="min-w-[2.5rem] pr-2">
							<WorkspaceAvatar workspace={option} size={7} />
						</div>
						<div>
							<div className="truncate max-w-[200px] text-default text-base font-bold">
								{sanitizeURL(option?.name)}
							</div>
							{option?.billing?.organization?.description && (
								<div className="text-sm text-[#69707D] truncate max-w-[200px]">
									{option?.billing?.organization?.description}
								</div>
							)}
						</div>
						{badgeText ? (
							<div className="pl-2">
								<GBadge
									className={classNames('rounded-md align-center')}
									text={badgeText}
									color={getAccountBadgeColorByStatus(
										option.status,
										option?.billing?.subscription?.type
									)}
								/>
							</div>
						) : null}
					</div>
					{option.id === account?.id && (
						<div>
							<RiCheckLine className="text-primary-500 w-4 h-4" />
						</div>
					)}
				</div>
			</div>
		)
	}

	function SelectedItem() {
		return (
			<div className="w-full flex items-center text-lg text-t-menu hover:bg-gray-50 max-w-full cursor-pointer">
				<div className="flex items-center w-full ">
					{account && (
						<div className="h-8 flex px-2 items-center justify-between rounded-md">
							<WorkspaceAvatar workspace={account} size={8} />
						</div>
					)}
					<div className="text-left items-center flex-col flex-1 hidden md:block">
						{!account?.name ? (
							<p
								className={
									'text-md font-bold text-t-heading py-4 px-2 truncate'
								}
							>
								Workspaces
							</p>
						) : (
							<p className={'text-md font-bold text-t-heading w-32 truncate'}>
								{sanitizeURL(account?.name)}
							</p>
						)}

						{account && (
							<GBadge className="rounded-md" color={color} text={text} />
						)}
					</div>
				</div>
				<div>
					<RiArrowRightSLine className="h-4 w-4 text-gray-400" />
				</div>
			</div>
		)
	}

	function SwitcherContentFooter() {
		return (
			<div className="flex flex-grow p-2">
				<GButton
					className="w-full"
					color="white"
					variant="colored"
					borderColor="t-border-light"
					hoverColor="gray-50"
					icon={AiOutlinePlus}
					onClick={() => navigate('/start/standard')}
				>
					Create new workspace
				</GButton>
			</div>
		)
	}
}
