import {
	GoogleAdsAccountBasicInfoDTO,
	GoogleOAuthAccountLinkDTO,
	MCCAccountLinkDTO
} from 'api-models'
import {
	GButton,
	GListBox,
	GLoading,
	GToggle,
	GTooltip
} from 'components/basic-blocks'
import { GWindowedSelect } from 'components/basic-blocks/g-virtualized-select'
import { GoogleAuthConfig } from 'config'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { RiArrowRightSLine, RiQuestionLine } from 'react-icons/ri'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { useUiStore } from 'store'
import { addPPCAccountSchema } from '../validations'
import { GadsFormProps, GadsFormState } from './types'

export const GadsForm = (props: GadsFormProps) => {
	const { gadsConnections, closeDialog, setStep } = props

	const [state, setState] = useState<GadsFormState>({
		platform: 'GADS',
		selectedLink: undefined,
		accountNumber: undefined,
		errors: {},
		tracking: true,
		step: 'idle'
	})

	const { accountStore, assetStore } = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	const { account, createOAuthLink } = accountStore
	const { asset, createPPCGoogleAdsAccount } = assetStore

	const castingOptions = () => {
		const castedOptions: (GoogleOAuthAccountLinkDTO | MCCAccountLinkDTO)[] = []
		gadsConnections.forEach((item) => {
			if (item.type === 'oauth') {
				const castedItem = item as GoogleOAuthAccountLinkDTO
				castedOptions.push(castedItem)
			}
			if (item.type === 'mcc') {
				castedOptions.push(item as MCCAccountLinkDTO)
			}
		})
		return castedOptions
	}

	const handleAddPPCAccount = async () => {
		const errors = validate()
		setState({ ...state, errors: errors })
		if (errors) return

		if (state.selectedLink && state.accountNumber) {
			const payload = {
				link_id: state.selectedLink?.id, // account link ID
				account_number: state.accountNumber?.account_number,
				asset_id: asset!.id,
				set_tracking_template: state.tracking
			}
			try {
				const data = await createPPCGoogleAdsAccount(account!.id, payload)
				if (data.details.mcc_support_connection === 'PENDING') {
					setStep({
						step: 'LINK',
						adAccount: data
					})
				} else {
					closeDialog()
				}
			} catch (e) {
				return
			}
		}
	}
	const validate = () => {
		const options = { abortEarly: false }
		const { error } = addPPCAccountSchema.validate(
			{
				selectedLink: state.selectedLink?.id,
				accountNumber: state.accountNumber?.account_number
			},
			options
		)

		if (!error) return null

		const errors: Record<string, string> = {}

		error.details.forEach((item) => {
			errors[item.path[0]] = item.message
		})
		return errors
	}

	const handleMultiSelectChange = (
		val: MCCAccountLinkDTO | GoogleOAuthAccountLinkDTO
	) => {
		setState({ ...state, selectedLink: val, accountNumber: undefined })
	}
	const handleAccountNumberChange = (val: GoogleAdsAccountBasicInfoDTO) => {
		setState({ ...state, accountNumber: val })
	}

	useEffect(() => {
		if (state.step === 'waiting') {
			const interval = setInterval(async () => {
				const code = getAuthCode()
				if (code) {
					clearInterval(interval)
					clearAuthCode()
					googleNewConnection(code)
				}
			}, 1000)
		}
	}, [state.step])

	const getAuthCode = () => {
		const code = window.localStorage.getItem('google_oauth_code')
		return code
	}

	const clearAuthCode = () => {
		window.localStorage.removeItem('google_oauth_code')
		setState({ ...state, step: 'idle' })
	}

	const googleNewConnection = async (code: string) => {
		try {
			await createOAuthLink(code, 'google')
		} catch (e) {
			console.log(e)
		}
	}

	const handleAuthorize = () => {
		window.open(GoogleAuthConfig.AUTH_URL, '_blank')
		setState({ ...state, step: 'waiting' })
	}

	return (
		<>
			<div className="bg-gray-50 rounded-lg px-6 py-6 space-y-4 flex flex-col justify-center">
				<div className="pb-2 text-sm space-y-4">
					<span className="text-gray-400 font-normal">
						{'Select how ClickGUARD will access Google Ads'}
					</span>
				</div>
				<div className="flex items-end">
					<div
						className={`mt-2 mr-5 ${
							castingOptions().length == 0 ? 'min-w-max' : 'w-64'
						}`}
					>
						{gadsConnections ? (
							<GListBox<MCCAccountLinkDTO | GoogleOAuthAccountLinkDTO>
								disabled={castingOptions().length === 0}
								label="Access"
								value={state?.selectedLink}
								options={castingOptions()}
								placeholder={
									castingOptions().length > 0
										? 'Select Link'
										: 'No access links for Google Ads'
								}
								onChange={(val) => handleMultiSelectChange(val)}
								renderLabel={(list) => <>{list.name}</>}
								error={state.errors?.selectedLink}
							/>
						) : (
							<GLoading />
						)}
					</div>
					<GButton
						className="flex-start !py-[7.5px]"
						size="sm"
						color="neutral"
						variant="contained"
						type="text"
						label="New authorization"
						onClick={() => {
							handleAuthorize()
						}}
						loading={isLoading('ACCOUNT_ADD_ACCESS')}
					/>
				</div>

				<div className="my-4">
					{state?.selectedLink && (
						<div className="space-y-4">
							<div className="w-1/2">
								<GWindowedSelect
									label="Google Ads account number"
									options={[...(state?.selectedLink?.clients || [])]}
									value={state?.accountNumber || undefined}
									onChange={(newValue) =>
										handleAccountNumberChange(
											newValue as GoogleAdsAccountBasicInfoDTO
										)
									}
									placeholder="Select Google Ads account number"
									isOptionSelected={(option) =>
										(option as GoogleAdsAccountBasicInfoDTO).account_number ===
										state?.accountNumber?.account_number
									}
									formatOptionLabel={(option) =>
										(option as GoogleAdsAccountBasicInfoDTO).account_number
									}
									error={state.errors?.accountNumber}
									filterOption={(option, query) => {
										return query
											? (
													option as FilterOptionOption<GoogleAdsAccountBasicInfoDTO>
											  ).data.account_number
													?.toLocaleLowerCase()
													.includes(query)
											: true
									}}
								/>
							</div>

							<div className="flex space-x-1 items-center">
								<GToggle
									checked={state.tracking}
									onChange={(v) => setState({ ...state, tracking: v })}
									label="Automatically configure click tracking"
								/>
								<GTooltip content="Sets ClickGUARD tracking template on your Google Ads account">
									<RiQuestionLine className="text-t-secondary w-3" />
								</GTooltip>
							</div>
						</div>
					)}
				</div>
				<div className="flex justify-center pt-2">
					<GButton
						label={`Add ${state.accountNumber?.account_number || ''}`}
						color="primary"
						size="sm"
						className="px-8"
						icon={RiArrowRightSLine}
						iconPlacement="right"
						onClick={() => handleAddPPCAccount()}
						disabled={!state.accountNumber}
						loading={isLoading('ASSET_ADD_ACCOUNT')}
					/>
				</div>
			</div>
		</>
	)
}
