import {
	AdminMetricsTimelineDTO,
	AdminUserMetrcisStatsDataDTO
} from 'api-models'
import moment from 'moment'
import { changePercentage, formatDecimalNumber, formatNumber } from 'utils'
import { formatDuration } from 'utils/time'
import { AdminTimelineDataType } from '../types'
import {
	FormattedAdminMetricsStatsDTO,
	FormattedAdminTimelineDTO
} from './types'

export const formatData = (
	data: AdminUserMetrcisStatsDataDTO
): FormattedAdminMetricsStatsDTO => {
	return {
		...data,
		total_signups_formatted: formatNumber(data.total_signups),
		confirmed_signups_formatted: formatNumber(data.confirmed_signups),
		new_signups_formatted: formatNumber(data.new_signups),
		team_addition_formatted: formatNumber(data.team_additions),

		new_setups_formatted: formatNumber(data.new_setups),
		first_time_setups_formatted: formatNumber(data.first_time_setups),
		new_trials_formatted: formatNumber(data.new_trials),
		first_time_trials_formatted: formatNumber(data.first_time_trials),
		new_subscriptions_formatted: formatNumber(data.new_subscriptions),
		first_time_subscriptions_formatted: formatNumber(
			data.first_time_subscriptions
		),

		cancelled_setups_formatted: formatNumber(data.cancelled_setups),
		cancelled_trial_formatted: formatNumber(data.cancelled_trials),
		cancelled_subscription_formatted: formatNumber(
			data.cancelled_subscriptions
		),
		avg_subscription_spend_formatted: formatNumber(data.avg_sub_spend),
		avg_detect_spend_formatted: formatNumber(data.avg_detected_spend),
		limit_breaches_formatted: formatNumber(data.limit_breaches),
		discounted_subscription_formatted: formatNumber(data.disconted_subs),

		lite_formatted: formatDecimalNumber(data.sub_lite),
		pro_formatted: formatDecimalNumber(data.sub_pro),
		standard_formatted: formatDecimalNumber(data.sub_standard),
		legacy_formatted: formatDecimalNumber(data.sub_legacy),
		custom_formatted: formatDecimalNumber(data.sub_custom),

		trial_cancelled_duration_formatted: formatDuration(
			data.avg_cancelled_trail_dur,
			'days'
		),
		subscription_cancelled_duration_formatted: formatDuration(
			data.avg_cancelled_sub_dur,
			'months'
		),
		complicated_formatted: formatNumber(data.can_reason_too_complicated),
		no_benefits_formatted: formatNumber(data.can_reason_no_benefits),
		expensive_formatted: formatNumber(data.can_reason_expensive),
		other_formatted: formatNumber(data.can_reason_other),
		alternate_formatted: formatNumber(data.can_reson_alternate),
		stopped_ppc_formatted: formatNumber(data.can_reson_stopped_ppc),

		avg_completion_time_formatted: formatDuration(
			data.avg_completion_time,
			'days'
		),
		median_completion_time_formatted: formatDuration(
			data.median_completion_time,
			'days'
		),
		add_ppc_account_formatted: formatNumber(data.abandonment_account),
		link_ppc_platform_formatted: formatNumber(data.abandonment_link),
		selected_plan_formatted: formatNumber(data.abandonment_plan)
	}
}

export const formatPercentages = (
	current: AdminUserMetrcisStatsDataDTO,
	previous: AdminUserMetrcisStatsDataDTO
): AdminUserMetrcisStatsDataDTO => {
	return {
		total_signups: changePercentage(
			current.total_signups,
			previous.total_signups
		),
		confirmed_signups: changePercentage(
			current.confirmed_signups,
			previous.confirmed_signups
		),
		new_signups: changePercentage(current.new_setups, previous.new_setups),
		team_additions: changePercentage(
			current.team_additions,
			previous.team_additions
		),
		new_setups: changePercentage(current.new_setups, previous.new_setups),
		first_time_setups: changePercentage(
			current.first_time_setups,
			previous.first_time_setups
		),
		new_trials: changePercentage(current.new_trials, previous.new_trials),
		first_time_trials: changePercentage(
			current.first_time_trials,
			previous.first_time_trials
		),
		new_subscriptions: changePercentage(
			current.new_subscriptions,
			previous.new_subscriptions
		),
		first_time_subscriptions: changePercentage(
			current.first_time_subscriptions,
			previous.first_time_subscriptions
		),
		singup_confirmation_ratio: changePercentage(
			current.singup_confirmation_ratio,
			previous.singup_confirmation_ratio
		),
		signup_setup_ratio: changePercentage(
			current.signup_setup_ratio,
			previous.signup_setup_ratio
		),
		setup_trial_ratio: changePercentage(
			current.setup_trial_ratio,
			previous.setup_trial_ratio
		),
		trial_subscription_ratio: changePercentage(
			current.trial_subscription_ratio,
			previous.trial_subscription_ratio
		),
		cancelled_setups: changePercentage(
			current.cancelled_setups,
			previous.cancelled_setups
		),
		cancelled_trials: changePercentage(
			current.cancelled_trials,
			previous.cancelled_trials
		),
		cancelled_subscriptions: changePercentage(
			current.cancelled_subscriptions,
			previous.cancelled_subscriptions
		),
		trial_churn_rate: changePercentage(
			current.trial_churn_rate,
			previous.trial_churn_rate
		),
		subscription_churn_rate: changePercentage(
			current.subscription_churn_rate,
			previous.subscription_churn_rate
		),
		avg_completion_time: changePercentage(
			current.avg_completion_time,
			previous.avg_completion_time
		),
		median_completion_time: changePercentage(
			current.median_completion_time,
			previous.median_completion_time
		),
		abandonment_link: changePercentage(
			current.abandonment_link,
			previous.abandonment_link
		),
		abandonment_account: changePercentage(
			current.abandonment_account,
			previous.abandonment_account
		),
		abandonment_plan: changePercentage(
			current.abandonment_plan,
			previous.abandonment_plan
		),
		avg_sub_spend: changePercentage(
			current.avg_sub_spend,
			previous.avg_sub_spend
		),
		avg_detected_spend: changePercentage(
			current.avg_detected_spend,
			previous.avg_detected_spend
		),
		limit_breaches: changePercentage(
			current.limit_breaches,
			previous.limit_breaches
		),
		disconted_subs: changePercentage(
			current.disconted_subs,
			previous.disconted_subs
		),
		sub_lite: changePercentage(current.sub_lite, previous.sub_lite),
		sub_standard: changePercentage(current.sub_standard, previous.sub_standard),
		sub_pro: changePercentage(current.sub_pro, previous.sub_pro),
		sub_custom: changePercentage(current.sub_custom, previous.sub_custom),
		sub_legacy: changePercentage(current.sub_legacy, previous.sub_legacy),
		avg_cancelled_trail_dur: changePercentage(
			current.avg_cancelled_trail_dur,
			previous.avg_cancelled_trail_dur
		),
		avg_cancelled_sub_dur: changePercentage(
			current.avg_cancelled_sub_dur,
			previous.avg_cancelled_sub_dur
		),
		can_reason_too_complicated: changePercentage(
			current.can_reason_too_complicated,
			previous.can_reason_too_complicated
		),
		can_reason_doesnt_work: changePercentage(
			current.can_reason_doesnt_work,
			previous.can_reason_doesnt_work
		),
		can_reason_no_benefits: changePercentage(
			current.can_reason_no_benefits,
			previous.can_reason_no_benefits
		),
		can_reason_expensive: changePercentage(
			current.can_reason_expensive,
			previous.can_reason_expensive
		),
		can_reason_other: changePercentage(
			current.can_reason_other,
			previous.can_reason_other
		),
		can_reson_alternate: changePercentage(
			current.can_reson_alternate,
			previous.can_reson_alternate
		),
		can_reson_stopped_ppc: changePercentage(
			current.can_reson_stopped_ppc,
			previous.can_reson_stopped_ppc
		)
	}
}

export const formatTimelineData = (
	data: AdminMetricsTimelineDTO[]
): FormattedAdminTimelineDTO[] => {
	const newSignups = [] as AdminTimelineDataType[]
	const newConfirmedSignups = [] as AdminTimelineDataType[]
	const newSetups = [] as AdminTimelineDataType[]
	const newTrials = [] as AdminTimelineDataType[]
	const newSubscriptions = [] as AdminTimelineDataType[]
	const cancelledSetups = [] as AdminTimelineDataType[]
	const cancelledTrials = [] as AdminTimelineDataType[]
	const cancelledSubscriptions = [] as AdminTimelineDataType[]

	data.forEach((item) => {
		newSignups.push({
			value: item.count.new_signups,
			date: item.date
		})
		newConfirmedSignups.push({
			value: item.count.new_confirmed_signups,
			date: item.date
		})
		newSetups.push({ value: item.count.new_setups, date: item.date })
		newTrials.push({ value: item.count.new_trials, date: item.date })
		newSubscriptions.push({
			value: item.count.new_subscriptions,
			date: item.date
		})
		cancelledSetups.push({ value: item.count.cancelled_setup, date: item.date })
		cancelledTrials.push({
			value: item.count.cancelled_trials,
			date: item.date
		})
		cancelledSubscriptions.push({
			value: item.count.cancelled_subscription,
			date: item.date
		})
	})

	return [
		{
			id: 'New signups (user)',
			data: newSignups.map((item) => ({
				x: moment(item.date).format('YYYY-MM-DD'),
				y: Number(item.value)
			}))
		},
		{
			id: 'New confirmed signups (user)',
			data: newConfirmedSignups.map((item) => ({
				x: moment(item.date).format('YYYY-MM-DD'),
				y: Number(item.value)
			}))
		},
		{
			id: 'New setups (workspaces)',
			data: newSetups.map((item) => ({
				x: moment(item.date).format('YYYY-MM-DD'),
				y: Number(item.value)
			}))
		},
		{
			id: 'New TRIALs (workspaces)',
			data: newTrials.map((item) => ({
				x: moment(item.date).format('YYYY-MM-DD'),
				y: Number(item.value)
			}))
		},
		{
			id: 'New subscriptions (workspaces)',
			data: newSubscriptions.map((item) => ({
				x: moment(item.date).format('YYYY-MM-DD'),
				y: Number(item.value)
			}))
		},
		{
			id: 'Cancelled/deleted setups (workspaces)',
			data: cancelledSetups.map((item) => ({
				x: moment(item.date).format('YYYY-MM-DD'),
				y: Number(item.value)
			}))
		},
		{
			id: 'Cancelled TRIALs (workspaces)',
			data: cancelledTrials.map((item) => ({
				x: moment(item.date).format('YYYY-MM-DD'),
				y: Number(item.value)
			}))
		},
		{
			id: 'Cancelled subscriptions (workspaces)',
			data: cancelledSubscriptions.map((item) => ({
				x: moment(item.date).format('YYYY-MM-DD'),
				y: Number(item.value)
			}))
		}
	]
}
