import { RiArrowDownSLine, RiArrowUpSLine, RiCodeLine } from 'react-icons/ri'
import { classNames } from 'utils'
import { GTableColumn, GTableHeaderProps } from '../types'

export function TableHeader<T>(props: GTableHeaderProps<T>) {
	const { sort, column, reactColumn, onSort } = props
	const raiseSort = (column: GTableColumn<T>) => {
		if (onSort && column.sortKey) {
			if (column.sortKey === sort) return onSort(`-${column.sortKey}`)
			onSort(column.sortKey)
		}
	}
	const renderSortIcon = (column: GTableColumn<T>) => {
		if (`-${column.sortKey}` !== sort && column.sortKey !== sort)
			return <RiCodeLine className="h-3 w-3 rotate-90" />
		if (sort === column.sortKey) return <RiArrowUpSLine className="h-3 w-3" />
		if (sort === `-${column.sortKey}`)
			return <RiArrowDownSLine className="h-3 w-3" />
		return <RiCodeLine className="h-3 w-3 rotate-90" />
	}

	return (
		<th
			{...reactColumn.getHeaderProps()}
			className={classNames(
				'px-4 py-3  text-left text-md font-semibold',
				column.hidden ? 'hidden' : '',
				column.mobileHide ? 'hidden md:table-cell' : '',
				column.tabletHide ? 'hidden lg:table-cell' : ''
			)}
		>
			<div
				className={classNames(
					'flex w-full h-full px-2 items-center',
					column.className || ''
				)}
			>
				{column.header ? column.header() : column.label}
				{column.sortKey && (
					<span
						className="mx-2 cursor-pointer"
						onClick={() => raiseSort(column)}
					>
						{renderSortIcon(column)}
					</span>
				)}
			</div>
		</th>
	)
}

export default TableHeader

// const renderSortIcon = (column: GTableColumn<T>) => {
// 	if (column.path !== sort.path) return <RiCodeLine className='h-3 w-3 rotate-90' />
// 	if (sort.order === 'asc') return <RiArrowUpSLine className="h-3 w-3" />
// 	return <RiArrowDownSLine className="h-3 w-3" />
// }

// const raiseSort = (column: GTableColumn<T>) => {
// 	const path = column.path
// 	if (!path) {
// 		return
// 	}
// 	const order = sort.order === 'asc' ? 'desc' : 'asc'
// 	if (typeof onSort === 'function') {
// 		onSort(path, order)
// 	}
// }
