export const formatReportsLinks = (
	array: { format: string; link: string }[]
) => {
	const updatedArray: { format: string; link: string }[] = []
	if (!array || array.length == 0) {
		return []
	}
	array.forEach((item) => {
		if (item.format === 'PDF') {
			updatedArray.unshift(item)
		} else {
			updatedArray.push(item)
		}
	})
	return updatedArray
}
