"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BingAdsCampaignTypeDTO = exports.BingAdsCampaignStatusDTO = exports.BingAdsAccountPauseReasonDTO = exports.BingAdsAccountStatusDTO = void 0;
var BingAdsAccountStatusDTO;
(function (BingAdsAccountStatusDTO) {
    BingAdsAccountStatusDTO["Draft"] = "Draft";
    BingAdsAccountStatusDTO["Active"] = "Active";
    BingAdsAccountStatusDTO["Inactive"] = "Inactive";
    BingAdsAccountStatusDTO["Pause"] = "Pause";
    BingAdsAccountStatusDTO["Pending"] = "Pending";
    BingAdsAccountStatusDTO["Suspended"] = "Suspended";
})(BingAdsAccountStatusDTO = exports.BingAdsAccountStatusDTO || (exports.BingAdsAccountStatusDTO = {}));
var BingAdsAccountPauseReasonDTO;
(function (BingAdsAccountPauseReasonDTO) {
    BingAdsAccountPauseReasonDTO[BingAdsAccountPauseReasonDTO["USER"] = 1] = "USER";
    BingAdsAccountPauseReasonDTO[BingAdsAccountPauseReasonDTO["BILLING"] = 2] = "BILLING";
    BingAdsAccountPauseReasonDTO[BingAdsAccountPauseReasonDTO["USER_AND_BILLING"] = 4] = "USER_AND_BILLING";
})(BingAdsAccountPauseReasonDTO = exports.BingAdsAccountPauseReasonDTO || (exports.BingAdsAccountPauseReasonDTO = {}));
var BingAdsCampaignStatusDTO;
(function (BingAdsCampaignStatusDTO) {
    BingAdsCampaignStatusDTO["Active"] = "Active";
    BingAdsCampaignStatusDTO["Paused"] = "Paused";
    BingAdsCampaignStatusDTO["BudgetPaused"] = "BudgetPaused";
    BingAdsCampaignStatusDTO["BudgetAndManualPaused"] = "BudgetAndManualPaused";
    BingAdsCampaignStatusDTO["Deleted"] = "Deleted";
    BingAdsCampaignStatusDTO["Suspended"] = "Suspended";
})(BingAdsCampaignStatusDTO = exports.BingAdsCampaignStatusDTO || (exports.BingAdsCampaignStatusDTO = {}));
var BingAdsCampaignTypeDTO;
(function (BingAdsCampaignTypeDTO) {
    BingAdsCampaignTypeDTO["Search"] = "Search";
    BingAdsCampaignTypeDTO["Shopping"] = "Shopping";
    BingAdsCampaignTypeDTO["DynamicSearchAds"] = "DynamicSearchAds";
    BingAdsCampaignTypeDTO["Audience"] = "Audience";
    BingAdsCampaignTypeDTO["Hotel"] = "Hotel";
})(BingAdsCampaignTypeDTO = exports.BingAdsCampaignTypeDTO || (exports.BingAdsCampaignTypeDTO = {}));
