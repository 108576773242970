import { PageTitle } from '../../../utils/page-title'
import { PageHeader } from '../../../components/page-header'
import { GButton, GInput } from '../../../components/basic-blocks'
import { twMerge } from 'tailwind-merge'
import { IoBriefcaseOutline } from 'react-icons/io5'
import { ChangeEvent, Dispatch, useMemo, useState } from 'react'
import { getAdminRoleOptions, validateTeamInvites } from './types'
import { useAdminStore, useUserStore } from '../../../store'
import { toast } from 'react-toastify'
import { RiTeamLine } from 'react-icons/ri'
import { MdKeyboardArrowDown } from 'react-icons/md'

export const InviteAdminTeam = ({
	setPage
}: {
	setPage: Dispatch<'list' | 'invite'>
}) => {
	const user = useUserStore((s) => s.user)
	const sendAdminTeamInvite = useAdminStore((s) => s.sendAdminTeamInvite)
	const [loading, setLoading] = useState(false)

	const [invite, setInvite] = useState<
		{ email: string; access: 'BASIC' | 'FULL' | 'SUPER' | '' }[]
	>([
		{
			email: '',
			access: ''
		},
		{
			email: '',
			access: ''
		},
		{
			email: '',
			access: ''
		}
	])

	const [errors, setError] = useState<
		{ email: undefined | string; access: undefined | string }[]
	>([
		{
			email: undefined,
			access: undefined
		},
		{
			email: undefined,
			access: undefined
		},
		{
			email: undefined,
			access: undefined
		}
	])

	const isDisabledButton = useMemo(() => {
		return invite.every((e) => !e.email && !e.access)
	}, [invite])

	const handleSendInvites = async () => {
		const { errors, isValid } = validateTeamInvites(invite, user?.email || '')
		setError(errors)
		const errorsArray = Object.values(errors).filter((e) => e.access || e.email)
		if (errorsArray.length > 1) return toast.error('Please fill in all fields')
		if (errorsArray.length === 1)
			return toast.error(
				errorsArray[0].access
					? 'Please select access level'
					: 'Please enter valid email'
			)
		const invites: any = invite.filter((e) => e.email && e.access)
		if (isValid && invites.length === 0) {
			return toast.error('Please add at least one email and access level')
		}
		if (isValid) {
			setLoading(true)
			const invite = await sendAdminTeamInvite(invites)
			setLoading(false)
			if (invite) setPage('list')
		}
	}
	return (
		<>
			<PageTitle pageName="Team" />
			<div className="flex items-center justify-between pr-8">
				<PageHeader
					title="Invite People"
					subtitle="All invited members have access to the ClickGUARD admin panel "
				/>
				<GButton
					label="Team Members"
					icon={RiTeamLine}
					color="primary"
					onClick={() => setPage('list')}
				/>
			</div>
			<div className="max-w-3xl px-4 sm:px-6 md:px-8">
				<div className="bg-white rounded-lg">
					<div className="flex items-center bg-gray-50 p-3 gap-[120px] text-base font-bold text-t-secondary ">
						<p className="w-[130px]">Email Address</p>
						<p className="w-[200px]">Access</p>
					</div>
					<div className="px-2.5 pb-4 flex flex-col gap-1">
						{[1, 2, 3].map((_, i) => (
							<InviteAdminTeamItem
								errors={errors}
								key={i}
								state={invite}
								setState={setInvite}
								index={i}
							/>
						))}
					</div>
				</div>
				<div className="mt-12">
					<GButton
						className="w-[168px] h-[36px]"
						labelClassName="font-bold text-lg"
						label="Send Invites"
						icon={IoBriefcaseOutline}
						disabled={isDisabledButton}
						color={isDisabledButton ? 'disabled' : 'primary'}
						loading={loading}
						onClick={handleSendInvites}
					/>
				</div>
			</div>
		</>
	)
}

const InviteAdminTeamItem = ({
	setState,
	state,
	index,
	errors
}: {
	index: number
	state: { email: string; access: 'BASIC' | 'FULL' | 'SUPER' | '' }[]
	errors: { email: string | undefined; access: string | undefined }[]
	setState: Dispatch<
		{ email: string; access: 'BASIC' | 'FULL' | 'SUPER' | '' }[]
	>
}) => {
	const user = useUserStore((s) => s.user)
	const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const newAccess = e.target.value as 'BASIC' | 'FULL' | 'SUPER'
		const newState = [...state]
		newState[index].access = newAccess
		setState(newState)
	}

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		const email = e.target.value as 'BASIC' | 'FULL' | 'SUPER'
		const newState = [...state]
		newState[index].email = email
		setState(newState)
	}
	return (
		<div className="flex items-center gap-11 py-3">
			<div className="">
				<GInput
					type="email"
					placeholder="example@email.com"
					onChange={handleEmailChange}
					className="w-[200px] h-[36px]"
					error={errors[index].email}
					borderError={true}
				/>
			</div>
			<div className="w-[200px] h-[36px] relative">
				<select
					value={state[index].access}
					className={twMerge(
						'cursor-pointer bg-transparent disabled:bg-gray-100 text-base focus:ring-primary-500 focus:border-primary-500 block  rounded-md',
						'font-medium placeholder:text-t-secondary w-full px-3 py-2',
						errors[index].access ? 'border-red-800 ' : 'border-t-input-border'
					)}
					onChange={handleChange}
				>
					<option value="" className="text-t-secondary">
						Please select
					</option>
					{getAdminRoleOptions(user).map(({ value, name }) => (
						<option key={value} value={value}>
							{name}
						</option>
					))}
				</select>
				<MdKeyboardArrowDown className="absolute h-4 w-4 text-gray-400 right-[10px] top-[10px]" />
			</div>
		</div>
	)
}
