import { AdAccountDTO, GoogleAdsCampaignDTO } from 'api-models'
import { GSection } from 'components/basic-blocks'
import { InfoItemGroup } from 'components/basic-blocks/g-info-table'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { useUiStore } from 'store'

export const CampaignsSection = (props: { ppcAccount: AdAccountDTO }) => {
	const { ppcAccount } = props
	const { adminStore } = useStores()
	const isLoading = useUiStore((s) => s.isLoading)
	const { getCampaignsById } = adminStore
	const [campaigns, setCampaigns] = useState<GoogleAdsCampaignDTO[]>([])

	useEffect(() => {
		;(async () => {
			try {
				const data = await getCampaignsById(ppcAccount.id)
				data && setCampaigns(data)
			} catch (e: any) {
				return
			}
		})()
	}, [ppcAccount])

	return (
		<GSection loading={isLoading('ADMIN_GET_CAMPAIGNS')}>
			<InfoItemGroup
				title="Campaigns"
				className="-mt-5 border-t-0 rounded-t-lg"
			>
				<CampaignsList campaigns={campaigns} />
			</InfoItemGroup>
		</GSection>
	)
}

const CampaignsList = (props: { campaigns: GoogleAdsCampaignDTO[] }) => {
	const { campaigns } = props
	if (campaigns.length === 0)
		return (
			<div className="py-4 flex w-full items-center justify-center -mb-4">
				No ad-account available
			</div>
		)
	return (
		<div className="py-4 divide-y max-h-72 overflow-auto">
			{campaigns.map((campaign) => {
				return <CampaignItem campaign={campaign} key={campaign.id} />
			})}
		</div>
	)
}

const CampaignItem = (props: { campaign: GoogleAdsCampaignDTO }) => {
	const { campaign } = props
	const color =
		campaign.status === 'ENABLED'
			? 'border-green-500'
			: campaign.status === 'PAUSED'
			? 'border-red-500'
			: 'border-gray-500'
	return (
		<div className="flex items-center gap-4 px-4 py-2">
			<div className={`border-2 w-5 h-5 rounded-full ${color}`}></div>
			<div className="flex flex-col">
				<div>{campaign.name}</div>
				<div className="text-t-secondary">
					{campaign.type?.charAt(0).toLocaleUpperCase() +
						campaign.type?.slice(1).toLocaleLowerCase()}
				</div>
			</div>
		</div>
	)
}
