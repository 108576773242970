import { toJS } from 'mobx'
import { useEffect, useState } from 'react'
import { RiCloseLine } from 'react-icons/ri'
import { ProductAccountDTO } from '../../../../api-models'
import { useStores } from '../../hooks'
import { formatCurrency, formatSpend } from '../../utils'
import { GBadge } from '../basic-blocks/g-badge'

interface IProPriceRef {
	monthly: number | null
	quarterly: number | null
	spend: number | null
	yearly: number | null
}

export const PriceReference = () => {
	const { billingStore } = useStores()
	const { plans } = billingStore
	const [planList, setPlanList] = useState<IProPriceRef[]>([])

	const groupPricesBySpend = (): IProPriceRef[] => {
		const proPlan = toJS(plans.find((item) => item.name === 'ClickGUARD Pro'))
		if (proPlan) {
			const result: any = []

			proPlan.prices.forEach((price) => {
				const spend = price.spend

				let spendObj = result.find((obj: any) => obj.spend === spend)

				if (!spendObj) {
					spendObj = {
						spend: spend,
						monthly: null,
						yearly: null,
						quarterly: null
					}
					result.push(spendObj)
				}

				if (price.interval === 'month' && price.interval_count === 1) {
					spendObj.monthly = price.price
				} else if (price.interval === 'year') {
					spendObj.yearly = price.price
				} else if (price.interval === 'month' && price.interval_count === 3) {
					spendObj.quarterly = price.price
				}
			})

			return result
		}
		return []
	}

	useEffect(() => {
		if (plans) {
			const data = groupPricesBySpend()
			setPlanList(data)
		}
	}, [plans])

	return (
		<div className="relative overflow-x-auto mt-4">
			<table className="w-full text-sm text-left">
				<thead className="text-sm font-bold text-t-secondary">
					<tr>
						<th scope="col" className="px-6 py-1 w-[50%] ">
							Ad spend
						</th>
						<th scope="col" className="px-6 py-1">
							Monthly
						</th>
						<th scope="col" className="px-6 py-1">
							Quarterly
						</th>
						<th scope="col" className="px-6 py-1">
							Yearly
						</th>
					</tr>
				</thead>
				<tbody className="font-bold text-t-default text-sm">
					{planList.map(({ monthly, quarterly, yearly, spend }, index) => (
						<tr
							key={index}
							className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}
						>
							<td className="px-6 py-1">
								{spend ? `${spend / 1000}k` : 'N/A'}
							</td>
							<td className="px-6 py-1">
								{monthly ? formatCurrency(monthly) : 'N/A'}
							</td>
							<td className="px-6 py-1">
								{quarterly ? formatCurrency(quarterly) : 'N/A'}
							</td>
							<td className="px-6 py-1">
								{yearly ? formatCurrency(yearly) : 'N/A'}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

export const PriceBadge = ({ account }: { account: ProductAccountDTO }) => {
	return (
		<GBadge
			className="rounded"
			text={
				<span>
					{formatCurrency(account?.billing?.subscription?.plan?.price)} /
					{account?.billing?.subscription?.plan?.interval}
				</span>
			}
			color="green"
		/>
	)
}

export const SpendBadge = ({ account }: { account: ProductAccountDTO }) => {
	return (
		<>
			{account.billing?.subscription?.plan?.spend ? (
				<GBadge
					className="rounded"
					text={
						<span>
							{formatSpend(account.billing?.subscription?.plan?.spend)}
						</span>
					}
					color="purple"
				/>
			) : (
				<RiCloseLine className="h-5 w-5 mr-2 text-t-secondary" />
			)}
		</>
	)
}

export const RenderWorkspaceName = ({
	account_id,
	account
}: {
	account_id?: string
	account?: ProductAccountDTO[]
}) => {
	const data = account?.filter((acc) => acc.id === account_id)
	if (!account_id) return <div>New Workspace</div>
	return (
		<>
			<div>
				{data && data?.length > 0
					? data[0]?.name ?? data[0]?.user_info?.name
					: 'New Workspace'}
			</div>
		</>
	)
}
