import { GTooltip } from 'components/basic-blocks'
import { GMetricLine } from 'components/basic-blocks/g-metric-line/g-metric-line'
import { SimpleBarChart } from 'components/charts/simple-bar-chart'
import { RiQuestionLine } from 'react-icons/ri'
import { color } from 'utils/colors'
import { CancelledWorkspacesPanelProps } from './types'

export const CancelledWorkspacesPanel = ({
	data,
	percentages
}: CancelledWorkspacesPanelProps) => {
	return (
		<section className="bg-white px-4 py-4 text-t-default rounded min-w-[320px] h-fit">
			<div className="text-t-default text-md flex flex-row items-center gap-x-1 mb-4">
				<span className="font-bold">Cancelled workspaces</span>
				<GTooltip
					content={
						<div className="flex flex-col">
							<span>
								Statistics about workspaces that were cancelled during the
								selected period
							</span>
						</div>
					}
				>
					<RiQuestionLine className="w-4 h-4 text-t-dark" />
				</GTooltip>
			</div>
			<div className="flex flex-col gap-2">
				<GMetricLine
					title="Avg cancelled trial duration"
					value={data.trial_cancelled_duration_formatted}
					rate={percentages.avg_cancelled_trail_dur}
				/>
				<GMetricLine
					title="Avg subscription duration"
					value={data.subscription_cancelled_duration_formatted}
					rate={percentages.avg_cancelled_sub_dur}
				/>
			</div>
			<div className="mt-4">
				<SimpleBarChart
					data={[
						{
							id: 'plan',
							'Too complicated': data.can_reason_too_complicated,
							Expensive: data.can_reason_expensive,
							'Found alternate': data.alternate_formatted,
							'Stopped using PPC': data.stopped_ppc_formatted,
							'No benefits': data.can_reason_no_benefits,
							Other: data.can_reason_other
						}
					]}
					keys={[
						'Too complicated',
						'Expensive',
						'Found alternate',
						'Stopped using PPC',
						'No benefits',
						'Other'
					]}
					colors={[
						color('purple', 300),
						color('purple', 400),
						color('purple', 500),
						color('purple', 600),
						color('purple', 700),
						color('purple', 900)
					]}
					format="number"
					title={
						<div className="text-t-default text-md flex flex-row items-center gap-x-1">
							<span className="font-bold">Cancellation reasons</span>
						</div>
					}
				>
					<div className="flex flex-col h-full mt-4">
						<GMetricLine
							title="Too complicated"
							bulletColor="bg-purple-300"
							value={data.complicated_formatted}
							rate={percentages.can_reason_too_complicated}
						/>
						<GMetricLine
							title="Expensive"
							bulletColor="bg-purple-400"
							value={data.expensive_formatted}
							rate={percentages.can_reason_expensive}
						/>
						<GMetricLine
							title="Found alternate"
							bulletColor="bg-purple-500"
							value={data.alternate_formatted}
							rate={percentages.can_reson_alternate}
						/>
						<GMetricLine
							title="Stopped using PPC"
							bulletColor="bg-purple-600"
							value={data.stopped_ppc_formatted}
							rate={percentages.can_reson_stopped_ppc}
						/>
						<GMetricLine
							title="No benefits"
							bulletColor="bg-purple-800"
							value={data.no_benefits_formatted}
							rate={percentages.can_reason_no_benefits}
						/>

						<GMetricLine
							title="Other"
							bulletColor="bg-purple-900"
							value={data.other_formatted}
							rate={percentages.can_reason_other}
						/>
					</div>
				</SimpleBarChart>
			</div>
		</section>
	)
}
