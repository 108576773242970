import { GTooltip } from 'components/basic-blocks'
import { RiCheckboxBlankCircleFill } from 'react-icons/ri'
import { classNames } from 'utils'
import { ActivateAccountStepsProps } from './types'

export const ActivateAccountSteps = ({
	setStep,
	state: { step, protection_plan }
}: ActivateAccountStepsProps) => {
	const classes = (s: string) => {
		return classNames(
			step === s ? 'text-primary-500' : 'text-gray-100',
			'h-4 w-4 cursor-pointer'
		)
	}

	const onClick = (s: 'plan' | 'subscription') => {
		if (s === step) return
		setStep(s)
	}

	return (
		<div className="flex items-center space-x-2">
			<GTooltip content="Step 1: Pick protection plan">
				<RiCheckboxBlankCircleFill
					className={classes('plan')}
					onClick={() => onClick('plan')}
				/>
			</GTooltip>
			<GTooltip content="Step 2: Activate workspace">
				<RiCheckboxBlankCircleFill
					className={classes('subscription')}
					onClick={() => protection_plan && onClick('subscription')}
				/>
			</GTooltip>
		</div>
	)
}
