import {
	RiDownloadCloud2Line,
	RiLoader5Line,
	RiRefreshLine
} from 'react-icons/ri'
import { GNewTable } from '../../../../components/basic-blocks'
import { affiliateColumns } from './columns'
import { Filters } from './filters/filters'
import { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { AffiliateService } from '../../../../services/user/affiliate'
import { AffiliatePageFiltersContext } from './context'
import { toast } from 'react-toastify'

const PAGE_SIZE = 5

export const AffiliateUsers = ({
	hasData,
	dateRange
}: {
	hasData: boolean
	dateRange: { from: Date; to: Date }
}) => {
	const [skip, setSkip] = useState(0)
	const [downloadingExport, setDownloadingExport] = useState(false)
	const { filters } = useContext(AffiliatePageFiltersContext)
	const queryFilters = {
		skip,
		dateFrom: dateRange.from?.getTime(),
		dateTo: dateRange.to?.getTime(),
		...filters
	}

	useEffect(() => {
		setSkip(0)
	}, [filters])
	const cacheKey = (name: string) => {
		return [name, JSON.stringify(queryFilters)]
	}
	const { data, isLoading, isFetching, refetch } = useQuery(
		cacheKey('affiliate-dashboard-users'),
		() => AffiliateService.dashboardUsers(queryFilters),
		{
			enabled: hasData,
			cacheTime: 1000 * 60 * 5
		}
	)

	const pageListener = (skip: number) => {
		setSkip(skip)
	}

	const handleExport = async () => {
		try {
			setDownloadingExport(true)
			await AffiliateService.exportUsers({
				dateFrom: dateRange.from?.getTime(),
				dateTo: dateRange.to?.getTime()
			})
			setDownloadingExport(false)
		} catch (e) {
			toast.error('Error while exporting users')
			return
		}
	}
	return (
		<main className="border border-gray-100 rounded overflow-clip">
			<div className="flex justify-between bg-white py-4 px-6">
				<Filters />

				<div className="flex gap-2 items-start">
					<button
						disabled={!hasData}
						className="w-[99px] h-[30px] font-bold text-t-default bg-white py-2 px-4 flex items-center justify-center rounded-md border border-gray-200 hover:bg-gray-100 focus:outline-none"
						onClick={() => refetch()}
					>
						{isFetching ? (
							<RiLoader5Line className="animate-spin !shadow-none !bg-transparent border-none pointer-events-none h-4 w-4" />
						) : (
							<>
								<RiRefreshLine className="h-3.5 w-3.5 text-t-default mr-1.5" />
								Refresh
							</>
						)}
					</button>
					<button
						disabled={!hasData || downloadingExport}
						className="w-[93px] h-[30px] font-bold text-t-default bg-white py-2 px-4 flex items-center justify-center rounded-md border border-gray-200 hover:bg-gray-100 focus:outline-none"
						onClick={handleExport}
					>
						{downloadingExport ? (
							<RiLoader5Line className="animate-spin !shadow-none !bg-transparent border-none pointer-events-none h-4 w-4" />
						) : (
							<>
								<RiDownloadCloud2Line className="h-3.5 w-3.5 text-t-default mr-1.5" />
								Export
							</>
						)}
					</button>
				</div>
			</div>
			<div className="bg-white py-4">
				<GNewTable
					isLoading={isLoading || isFetching}
					pageListener={(e: number) => pageListener(e)}
					data={data?.data || []}
					search={JSON.stringify(filters)}
					columns={affiliateColumns}
					pageSize={PAGE_SIZE}
					totalCount={data?.total || 0}
					paginationClassName="mt-0 py-4 px-6"
				/>
			</div>
		</main>
	)
}
