import { useState } from 'react'
import { useUiStore } from 'store'
import { ReportRequestDTO } from '../../../../../../../api-models'
import {
	GButton,
	GToggle,
	GTooltip
} from '../../../../../components/basic-blocks'
import { FolderOpenLineIcon } from '../../../../../components/platform-icon'
import { useStores } from '../../../../../hooks'
import { ReportIcon, ReportTitle } from '../downloads-reports/downloads-reports'
import { ScheduleReportsDialog } from '../triggers/runReports'
import { reportType } from '../types'
import { capitalizeFirstLetter } from '../../../../../utils/text'
import { automatedIntervalOptions } from '../constants'

export const ScheduledReport = () => {
	const {
		assetStore: { reportRequests }
	} = useStores()

	return (
		<>
			{reportRequests.length === 0 && <NoReportScheduled />}
			<div className="flex flex-col gap-6">
				{reportRequests?.map((request) => (
					<ScheduledReportItem key={request.id} reportRequest={request} />
				))}
			</div>
		</>
	)
}

const ScheduledReportItem = ({
	reportRequest
}: {
	reportRequest: ReportRequestDTO
}) => {
	const [toggle, setToggle] = useState(reportRequest.status === 'ACTIVE')
	const [openSchedule, setOpenSchedule] = useState(false)
	const {
		accountStore,
		assetStore: { demoMode, toggleAutomatedReports, asset }
	} = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	const handleToggle = () => {
		if (accountStore.account && asset) {
			setToggle(!toggle)
			toggleAutomatedReports(
				accountStore.account.id,
				asset.id,
				reportRequest.id,
				!toggle
			)
		}
	}

	return (
		<div className="flex flex-row">
			<div className="flex gap-3 items-center w-[400px]">
				<div className="flex items-center gap-2">
					<ReportIcon report={reportRequest} />
					<span className="flex flex-col">
						<ReportTitle
							report={{
								...reportRequest,
								label: capitalizeFirstLetter(
									automatedIntervalOptions.find(
										(int) =>
											int.value.unit === reportRequest?.period?.unit &&
											int.value.value === reportRequest?.period?.value
									)?.label || ''
								)
							}}
						/>
						<span className="text-t-secondary">{asset?.name || ''}</span>
					</span>
				</div>
			</div>

			<div className="flex flex-row">
				<GTooltip
					content={
						demoMode
							? 'This action is disabled when demo data is displayed'
							: ''
					}
				>
					<>
						<div className="flex items-center gap-3">
							<GButton
								label="Configure"
								onClick={() => setOpenSchedule(!openSchedule)}
								color="neutral"
								variant="text"
								size="xs"
								className="min-w-[45px]"
								disabled={demoMode}
								labelClassName="text-sm font-bold text-t-default"
							/>
							<GToggle
								checked={toggle}
								disabled={
									!accountStore.hasFeature('reports_auto') ||
									isLoading('ASSET_SET_REPORT_TOGGLE')
								}
								onChange={handleToggle}
							/>
						</div>
						<ScheduleReportsDialog
							open={openSchedule}
							onClose={() => setOpenSchedule(false)}
							type={reportRequest.type as reportType}
							isConfigure={!!reportRequest}
							reportRequest={reportRequest}
						/>
					</>
				</GTooltip>
			</div>
		</div>
	)
}

const NoReportScheduled = () => {
	return (
		<div className="flex items-center justify-center flex-col py-6">
			<div className="flex items-center justify-center flex-col text-center w-[360px]">
				<FolderOpenLineIcon />
				<p className="py-4 font-bold text-t-secondary">No reports scheduled</p>
				<p className="text-t-secondary">
					Schedule reports to be automatically generated on specific dates and
					specific periods of time.
				</p>
			</div>
		</div>
	)
}
