import { Dialog, Transition } from '@headlessui/react'
import { AddNewSite } from 'components/add-new-site'
import { GButton, GInput, GSection } from 'components/basic-blocks'
import { GCheckbox } from 'components/basic-blocks/g-checkbox'
import { Fragment } from 'react'
import { RiArrowRightSLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { useAccountStore, useAssetStore } from 'store'
import { useShallow } from 'zustand/react/shallow'
import { AccountChecklistViewProps } from './types'

export const AccountChecklistView = ({
	open,
	close
}: AccountChecklistViewProps) => {
	const { asset, demoMode } = useAssetStore(
		useShallow((s) => {
			return { asset: s.asset, demoMode: s.demoMode }
		})
	)
	const { account, isInSetup } = useAccountStore(
		useShallow((s) => {
			return { account: s.account, isInSetup: s.isInSetup }
		})
	)

	const tc_installed = !!asset?.tc_installed
	const cc_installed = !!asset?.cc_installed
	const inSetup = isInSetup()
	const step = account?.setup?.step

	const website = asset && asset

	const trackingCode = !tc_installed

	const conversionCode = !cc_installed

	const isDone = demoMode
		? false
		: (inSetup && step !== 'done') || website || !trackingCode

	const navigate = useNavigate()

	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog as="div" className="relative z-20" onClose={close}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Dialog.Overlay className="fixed inset-0 bg-modal-background transition-opacity backdrop-blur-sm" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-2xl transform overflow-hidden transition-all">
								<GSection
									title="Complete your set up to get the most out of ClickGUARD"
									subtitle="It takes less than 5 minutes to get fully protected."
									titleClass="text-left"
								>
									<div className="pt-5 pb-2">
										<GCheckbox
											name={'Activate your Workspace'}
											labelClassName="font-bold"
											label={'Activate your Workspace'}
											checked={
												!demoMode &&
												account?.setup !== null &&
												account?.setup?.step !== 'link'
											}
											onChange={() => null}
											className="pb-3"
										/>
										<GInput
											placeholder="Click to connect now"
											icon={RiArrowRightSLine}
											iconPosition="end"
											value={(account && account.setup?.domain) || ''}
											onClick={() =>
												account
													? navigate(`/setup/${account?.id}`)
													: navigate('/start/standard')
											}
											onChange={() => null}
											inputClassName="bg-gray-100"
											disabled={
												!demoMode &&
												account?.setup !== null &&
												account?.setup?.step !== 'link'
											}
										/>
									</div>
									<div className="pt-5 pb-2">
										<GCheckbox
											name={'Add your first website'}
											labelClassName="font-bold"
											label={'Add your first website'}
											checked={!demoMode && !website}
											onChange={() => null}
											className="pb-3"
											disabled={demoMode || account?.setup === null}
										/>
										<AddNewSite>
											{(open) => (
												<GInput
													placeholder="Click to add now"
													inputClassName="bg-gray-100"
													icon={RiArrowRightSLine}
													value={(!demoMode && asset && asset?.name) || ''}
													onChange={() => null}
													onClick={() =>
														!demoMode &&
														!inSetup &&
														step === 'account' &&
														open()
													}
													disabled={!demoMode && !website}
												/>
											)}
										</AddNewSite>
									</div>
									<div className="pt-5 pb-2">
										<GCheckbox
											name={'Install Tracking Code'}
											labelClassName="font-bold"
											label={'Install Tracking Code'}
											checked={!demoMode && !trackingCode}
											onChange={() => null}
											className="pb-3"
											disabled={demoMode || website === null}
										/>
										<GInput
											placeholder="Click to install now"
											inputClassName="bg-gray-100"
											icon={RiArrowRightSLine}
											value={
												(!demoMode &&
													!trackingCode &&
													'Successfully installed') ||
												''
											}
											onClick={() => {
												if (!demoMode && !website) {
													navigate(
														`/settings/workspace/${account?.id}/asset/${asset?.id}/tracking-code`
													)
													close()
												}
											}}
											onChange={() => null}
											disabled={!demoMode && !trackingCode}
										/>
									</div>
									<div className="pt-5 pb-2">
										<GCheckbox
											name={'Install Conversion Code'}
											labelClassName="font-bold"
											label={'Install Conversion Code'}
											checked={!demoMode && !conversionCode}
											onChange={() => null}
											className="pb-3"
											disabled={demoMode || website === null}
										/>
										<GInput
											placeholder="Click to install now"
											inputClassName="bg-gray-100"
											icon={RiArrowRightSLine}
											value={
												(!demoMode &&
													!conversionCode &&
													'Successfully installed') ||
												''
											}
											onClick={() => {
												if (!demoMode && !website) {
													navigate(
														`/settings/workspace/${account?.id}/asset/${asset?.id}/tracking-code`
													)
													close()
												}
											}}
											onChange={() => null}
											disabled={!demoMode && !conversionCode}
										/>
									</div>
									<div className="mt-6 flex gap-2 justify-between">
										<div>
											<GButton
												label="Cancel"
												variant="text"
												size="sm"
												onClick={close}
											/>
										</div>
										<div className="flex justify-end gap-2">
											<GButton
												label="Complete"
												variant="contained"
												color="primary"
												size="sm"
												onClick={close}
												disabled={!isDone}
											/>
										</div>
									</div>
								</GSection>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
