import { UserAvatar } from 'components'
import { NavLink } from 'react-router-dom'
import { AccessSelector } from './access-selector'
import { AdminTeamUser } from './types'
import { classNames } from '../../../utils'

const commonClassName =
	'font-bold text-t-input-secondary justify-start w-max text-base'

export const columns = [
	{
		label: 'Full Name',
		render: (row: AdminTeamUser) => <Information user={row} />,
		className: commonClassName,
		width: '300px'
	},
	{
		label: 'Email',
		render: (row: AdminTeamUser) => <Email user={row} />,
		className: commonClassName,
		width: '300px'
	},
	{
		label: 'Access',
		render: (row: AdminTeamUser) => <AccessSelector user={row} />,
		className: commonClassName
	}
]

const Information = (props: { user: any }) => {
	const { user } = props

	return (
		<div className="flex space-x-2 items-center">
			<UserAvatar user={user} size={8} />
			<NavLink to={user.name ? `/admin/user/${user.id}` : '#'}>
				<div className="flex flex-col group cursor-pointer">
					<div
						className={classNames(
							'truncate max-w-xs font-semibold ',
							`${
								user?.name
									? 'text-primary-500 group-hover:text-primary-600'
									: 'text-gray-500'
							}`
						)}
					>
						{user?.name || user.user_email}
					</div>
				</div>
			</NavLink>
		</div>
	)
}

const Email = (props: { user: any }) => {
	const { user } = props

	return (
		<div className="w-full flex space-x-2 items-center text-gray-500">
			{user?.email || user.user_email}
		</div>
	)
}
