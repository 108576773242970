import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Onboarding } from 'services/user'
import { StartAgencyState } from './types'
import { StartAgencyView } from './view'

export const StartAgency = () => {
	const navigate = useNavigate()

	const [state, setState] = useState<StartAgencyState>({
		loading: false
	})

	const onNext = async () => {
		setState({ ...state, loading: true })

		const account = await Onboarding.createProductAccount({
			type: 'agency',
			brands: state.brands,
			platforms: state.platforms?.map((p) => p.value),
			spend: state.spend
		})

		navigate(`/setup/${account.id}`)

		setState({ ...state, loading: false })
	}

	return (
		<StartAgencyView
			state={state}
			setBrands={(d) => setState({ ...state, brands: d })}
			setPlatforms={(p) => setState({ ...state, platforms: p })}
			setSpend={(s) => setState({ ...state, spend: s })}
			onNext={onNext}
		/>
	)
}
