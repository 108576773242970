import { GSection, GTable } from 'components/basic-blocks'
import { AccountsSectionProps } from './types'
import { columnsForUser } from './user-columns'

export const Accounts = ({
	accounts,
	totalAccounts,
	pageListener,
	pageSize
}: AccountsSectionProps) => {
	return (
		<GSection>
			<GTable
				data={accounts}
				columns={columnsForUser}
				pageSize={pageSize}
				totalCount={totalAccounts}
				pageListener={(e: number) => pageListener(e)}
			/>
		</GSection>
	)
}
