import {
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuSubContent
} from '@radix-ui/react-dropdown-menu'
import { OptionSubContentProps } from '../../../context'
import { GRadio } from '../../../../../../../components/basic-blocks/g-radio'

const statusList = [
	{
		label: 'Custom Pro',
		value: 'custom_pro'
	},
	{
		label: 'Pro',
		value: 'pro'
	},
	{
		label: 'Standard',
		value: 'standard'
	},
	{
		label: 'lite',
		value: 'lite'
	},
	{
		label: 'Legacy',
		value: 'legacy'
	}
]

const key = 'tier'

export const SubscriptionPlanOption = {
	key,
	label: 'Subscription plan',
	subContent: ({ handleSubItemChange, mapState }: OptionSubContentProps) => (
		<DropdownMenuPortal>
			<DropdownMenuSubContent
				className="bg-white rounded border border-gray-200 p-4 max-h-[300px] overflow-y-scroll"
				sideOffset={20}
			>
				{statusList.map((o) => (
					<DropdownMenuItem key={o.value} asChild={true}>
						<GRadio
							name={o.label}
							value={o.value}
							label={o.label}
							checked={mapState.get(key) === o.value}
							onChange={(e) =>
								handleSubItemChange({ event: e, key, value: o.value })
							}
							className="p-1"
						/>
					</DropdownMenuItem>
				))}
			</DropdownMenuSubContent>
		</DropdownMenuPortal>
	)
}
