import { GNoticeType } from 'components/basic-blocks/g-notice/types'
import { NavLink } from 'react-router-dom'

interface buildWarningNoticesProps {
	tc_installed: boolean
	cc_installed: boolean
	hasDashboardPerformanceFeature: boolean
	accountId?: string
	assetId?: string
	contextKey: string
}
export const buildWarningNotices = ({
	tc_installed,
	cc_installed,
	hasDashboardPerformanceFeature,
	accountId,
	assetId,
	contextKey
}: buildWarningNoticesProps) => {
	const warningNotices: GNoticeType[] = []

	if (!tc_installed) {
		const key = 'tc-installed'
		const type = 'warning'

		if (!sessionStorage.getItem(`${contextKey}-${key}-${type}`)) {
			warningNotices.push({
				notice: (
					<>
						Tracking code is not installed yet.
						<NavLink
							className="text-primary-500 mx-1"
							to={`/settings/workspace/${accountId}/asset/${assetId}/tracking-code`}
						>
							Setup tracking code
						</NavLink>
						for more accurate tracking and complete data.
					</>
				),
				key,
				type,
				onDismiss: () => {
					sessionStorage.setItem(`${contextKey}-${key}-${type}`, 'true')
				}
			})
		}
	}
	if (hasDashboardPerformanceFeature && !cc_installed) {
		const key = 'cc-installed'
		const type = 'warning'

		if (!sessionStorage.getItem(`${contextKey}-${key}-${type}`)) {
			warningNotices.push({
				notice: (
					<>
						Conversions are not tracked yet.
						<NavLink
							className="text-primary-500 mx-1"
							to={`/settings/workspace/${accountId}/asset/${assetId}/tracking-code`}
						>
							Setup conversion code
						</NavLink>
						to track conversion events and stats.
					</>
				),
				key,
				type,
				onDismiss: () => {
					sessionStorage.setItem(`${contextKey}-${key}-${type}`, 'true')
				}
			})
		}
	}
	return warningNotices
}
