import { Notices } from 'components/notice/notices'
import { PageHeader } from 'components/page-header'
import { sanitizeURL } from 'utils'
import { useStores } from '../../../../hooks'
import { PageTitle } from '../../../../utils/page-title'
import { lazy, Suspense } from 'react'
import RemoveWebsiteSection from './remove-website/remove-website'
import { GLazyLoading } from '../../../../components/basic-blocks'
import { useAssetStore } from '../../../../store'
import { useShallow } from 'zustand/react/shallow'

const FormSection = lazy(() =>
	import('./form-section')
		.then((module) => {
			return { default: module.FormSection }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)

export const AssetSettingsPage = () => {
	const { accountStore } = useStores()
	const { account, isInSetup } = accountStore

	const { asset, adAccounts, demoMode } = useAssetStore(
		useShallow((s) => ({
			asset: s.asset,
			adAccounts: s.adAccounts,
			demoMode: s.demoMode
		}))
	)
	return (
		<div className="w-full">
			<PageTitle pageName="Website preferences" />
			<Notices
				isInSetup={isInSetup()}
				setupStep={account?.setup?.step}
				isInActive={account?.status === 'INACTIVE'}
				hasDashboardPerformanceFeature={accountStore.hasFeature(
					'dashboard_performance'
				)}
				tc_installed={!!asset?.tc_installed}
				cc_installed={!!asset?.cc_installed}
				accountId={account?.id}
				assetId={asset?.id}
				demoMode={demoMode}
				mccLinkInviteAdAccounts={adAccounts}
			/>
			<PageHeader
				title={'Website preferences'}
				tagline="Configure basic preferences for"
				tags={[
					{
						label: demoMode ? 'Demo PPC account' : sanitizeURL(asset?.name)
					}
				]}
			/>
			<div className="max-w-3xl px-4 sm:px-6 md:px-8">
				<>
					<Suspense fallback={<GLazyLoading />}>
						<FormSection />
					</Suspense>
					<RemoveWebsiteSection />
				</>
			</div>
		</div>
	)
}
