import { GButton, GSection, GTooltip } from 'components/basic-blocks'
import { ModalTypes } from 'components/basic-blocks/g-modal/types'

import { useNavigate } from 'react-router-dom'
import { useUiStore, useAssetStore, useAccountStore, useUserStore } from 'store'
import { useShallow } from 'zustand/react/shallow'

const RemoveWebsiteSection = () => {
	const {
		asset,
		demoMode,
		setAsset,
		setDemoMode,
		accountsWithAssets,
		removeAsset
	} = useAssetStore(
		useShallow((state) => ({
			asset: state.asset,
			demoMode: state.demoMode,
			setAsset: state.setAsset,
			setDemoMode: state.setDemoMode,
			accountsWithAssets: state.accountsWithAssets,
			removeAsset: state.deleteAsset
		}))
	)

	const { account, userAccess } = useAccountStore(
		useShallow((state) => ({
			account: state.account,
			userAccess: state.userAccess
		}))
	)
	const { user } = useUserStore(useShallow((state) => ({ user: state.user })))
	const setModalContent = useUiStore((s) => s.setModalContent)
	const setModalState = useUiStore((s) => s.setModalState)
	const navigate = useNavigate()

	const deleteAsset = async () => {
		try {
			await removeAsset(account?.id, asset?.id)
			const filterAccount = accountsWithAssets
				.find((accountWithAss) => accountWithAss.id === account.id)
				.assets.filter((a) => a.id !== asset.id)
			let assetId = 'website-demo'
			if (filterAccount.length > 0) {
				const asset = filterAccount[0]
				setAsset(asset)
				assetId = asset.id
			} else {
				setAsset(null)
				setDemoMode(true)
			}
			setModalContent({})
			setModalState(false)
			navigate(`/workspace/${account.id}/asset/${assetId}`)
		} catch (e) {
			//
		}
	}

	const handleOpenDelete = () => {
		setModalContent({
			title: 'Remove website',
			description: 'The website and its data will be permanently removed',
			type: ModalTypes.DANGER,
			confirm: () => deleteAsset(),
			loading: 'ASSET_DELETE_SITE'
		})
	}

	return (
		<div className="mt-6">
			<GSection title="Danger zone" lineBreak={true} lineBreakColor="gray-100">
				<div className="flex flex-col">
					<div className="flex justify-between items-end mt-6">
						<p className="text-md">Remove website and all of its data</p>
						<GTooltip
							content={
								demoMode
									? 'This action is disabled when demo data is displayed'
									: userAccess(user?.id) === 'READ'
									? 'Analysts don’t have permission for this action'
									: undefined
							}
						>
							<GButton
								disabled={demoMode || userAccess(user?.id) === 'READ'}
								label="Delete"
								color="danger"
								labelClassName="font-bold text-t-button"
								className="h-[30px] w-[80px]"
								onClick={() => handleOpenDelete()}
							/>
						</GTooltip>
					</div>
				</div>
			</GSection>
		</div>
	)
}

export default RemoveWebsiteSection
