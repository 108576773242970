import { Listbox, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { RiArrowDownSLine, RiCheckLine } from 'react-icons/ri'
import { classNames } from 'utils'
import { GListBoxProps } from './types'

export function GListBox<T>(props: GListBoxProps<T>) {
	const {
		options,
		value,
		onChange,
		renderLabel,
		label,
		className,
		error,
		placeholder,
		disabled,
		labelClass
	} = props

	return (
		<div className={className}>
			<Listbox value={value} onChange={onChange} disabled={disabled}>
				{({ open }) => (
					<>
						{label && (
							<Listbox.Label className="block text-md font-medium text-gray-700 mb-1">
								{label}
							</Listbox.Label>
						)}
						<div
							className={classNames(
								'relative max-w-[295px]',
								open ? 'z-11' : ''
							)}
						>
							<Listbox.Button className="disabled:bg-gray-100 font-medium relative w-full bg-white hover:bg-gray-50 border border-t-input-border rounded-md shadow-sm pl-3 py-2 text-left focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 cursor-default">
								<span className="w-full truncate">
									{value ? (
										<div
											className={`truncate ${labelClass || 'text-gray-500'}`}
										>
											{renderLabel(value)}
										</div>
									) : (
										<div className="text-t-secondary">{placeholder}</div>
									)}
								</span>
								<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
									<RiArrowDownSLine
										className="h-4 w-4 text-t-default"
										aria-hidden="true"
									/>
								</span>
							</Listbox.Button>

							<Transition
								show={open}
								as={Fragment}
								leave="transition ease-in duration-100"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Listbox.Options className="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
									{options.map((option, i) => (
										<Listbox.Option
											key={'key' + i}
											className={({ active }) =>
												classNames(
													active
														? 'text-t-default bg-gray-100'
														: 'text-t-default',
													'cursor-default select-none relative py-2 pl-3 pr-9 list-none'
												)
											}
											value={option}
										>
											{({ selected, active }) => (
												<>
													<div className="flex">{renderLabel(option)}</div>

													{selected ? (
														<span
															className={classNames(
																active
																	? 'text-primary-600'
																	: 'text-primary-500',
																'absolute inset-y-0 right-0 flex items-center pr-4'
															)}
														>
															<RiCheckLine
																className="h-5 w-5"
																aria-hidden="true"
															/>
														</span>
													) : null}
												</>
											)}
										</Listbox.Option>
									))}
								</Listbox.Options>
							</Transition>
						</div>
					</>
				)}
			</Listbox>
			{error && <div className="mt-1 text-xs text-red-700">{error}</div>}
		</div>
	)
}
