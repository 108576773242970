import {
	ProductAccountDTO,
	ReferenceAdsAccountDTO,
	ReferenceDomainDTO,
	UserDTO
} from 'api-models'
import { UserAvatar } from 'components'
import { GDialog, GLoading, GToggle } from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'
import { GDateTime } from 'components/basic-blocks/g-datetime'
import { PlanNames } from 'config'
import { useStores } from 'hooks'
import moment from 'moment'
import { memo, useEffect, useState } from 'react'
import { RiShareBoxFill, RiShieldLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { useUiStore } from 'store'
import { isValidUrl, planStepText, removeFadPrefix } from 'utils'
import { SubscriptionColors } from 'utils/colors'
import { AccountInfoProps } from './types'

export const AccountInfo = memo((props: AccountInfoProps) => {
	const { children, data, updateReferences } = props
	const { adminStore } = useStores()
	const { loadUsers, loadAccounts, updateGadsReference } = adminStore
	const isLoading = useUiStore((s) => s.isLoading)

	const [dialogOpen, setDialogOpen] = useState(false)

	const renderDomainOrAccNumber = () => {
		const tempData: any = data
		if (tempData.domain) {
			const row = tempData as ReferenceDomainDTO
			return <Template title="Domain" value={<DomainColumn row={row} />} />
		}
		if (tempData.account_number) {
			const row = tempData as ReferenceAdsAccountDTO
			return (
				<div className="flex justify-between">
					<div className="w-1/2">
						<Template
							title="Account no."
							value={<AccountNoColumn row={row} />}
						/>
					</div>
					<div className="w-1/2">
						<Template title="Allow re-use" value={<AllowReuse row={row} />} />
					</div>
				</div>
			)
		}
	}

	const AccountNoColumn = (props: { row: ReferenceAdsAccountDTO }) => {
		const { row } = props
		return (
			<>
				<div className="flex flex-col gap-1">
					<div className="font-medium text-gray-400">
						{removeFadPrefix(row.account_number)}
					</div>
				</div>
			</>
		)
	}
	const DomainColumn = (props: { row: ReferenceDomainDTO }) => {
		const { row } = props
		return (
			<>
				<div className="flex items-center flex-row gap-2">
					<div className="font-medium text-gray-400 truncate max-w-[300px]">
						{row.domain}
					</div>
					{isValidUrl(row.domain) && (
						<a
							href={`//${row.domain}`}
							target="_blank"
							className="text-primary-500"
						>
							<RiShareBoxFill />
						</a>
					)}
				</div>
			</>
		)
	}

	const FirstSeenColumn = (props: {
		row: ReferenceDomainDTO | ReferenceAdsAccountDTO
	}) => {
		const { row } = props
		return (
			<>
				<div className="flex flex-col gap-1">
					<div className="font-medium text-gray-500">
						<GDateTime date={row.first_seen} />
					</div>
					<div className="text-sm text-gray-400">
						{moment(row.first_seen).format('MMMM Do, YYYY - hh:mm:ss A')}
					</div>
				</div>
			</>
		)
	}

	const LastSeenColumn = (props: {
		row: ReferenceDomainDTO | ReferenceAdsAccountDTO
	}) => {
		const { row } = props
		return (
			<>
				<div className="flex flex-col gap-1">
					<div className="font-medium text-gray-500">
						<GDateTime date={row.last_seen} />
					</div>
					<div className="text-sm text-gray-400">
						{moment(row.last_seen).format('MMMM Do, YYYY - hh:mm:ss A')}
					</div>
				</div>
			</>
		)
	}

	const Template = (props: { title: string; value: any }) => {
		const { title, value } = props
		return (
			<div className="flex flex-col justify-center mb-4 col-span-4 lg:col-span-2">
				<div className="text-xs font-medium text-gray-500 mb-2">{title}</div>
				<div className="text-sm text-gray-900 ">{value || 'N/A'}</div>
			</div>
		)
	}
	const ListOfUsers = () => {
		const [users, setUsers] = useState<{
			adminUsers?: UserDTO[]
		}>()
		useEffect(() => {
			;(async () => {
				const users = await loadUsers({ ids: [String(data.user_id)] })
				setUsers({ adminUsers: users?.data })
			})()
			return () => {
				setUsers(undefined)
			}
		}, [])

		if (isLoading('ADMIN_GET_USERS')) return <GLoading />
		if (!users?.adminUsers || users?.adminUsers?.length === 0)
			return (
				<div className="text-gray-900">No users linked to this PPC account</div>
			)

		return (
			<>
				{users.adminUsers.map((user) => (
					<div className="py-2">
						<Link to={`/admin/user/${user.id}`} key={user.id}>
							<div className="flex space-x-2 items-center">
								<UserAvatar user={user} size={8} />
								<div className="flex flex-col group cursor-pointer">
									<div className="font-semibold text-primary-500 group-hover:text-primary-600">
										{user.name}
									</div>
									<div className="text-gray-500">{user.email}</div>
								</div>
							</div>
						</Link>
					</div>
				))}
			</>
		)
	}
	const ListOfAccounts = () => {
		const [accounts, setAccounts] = useState<{
			adminAccounts: ProductAccountDTO[]
		}>()
		useEffect(() => {
			;(async () => {
				const accounts = await loadAccounts({ ids: [String(data.account_id)] })
				setAccounts({ adminAccounts: accounts?.data })
			})()
			return () => {
				setAccounts(undefined)
			}
		}, [])
		if (isLoading('ADMIN_GET_ACCOUNTS')) return <GLoading />
		if (!accounts || accounts?.adminAccounts?.length === 0)
			return (
				<div className="text-gray-900">
					No workspaces linked to this PPC account
				</div>
			)
		return (
			<>
				{accounts.adminAccounts.map((account) => {
					const status = account?.billing?.subscription?.status || 'NONE'
					const accountStatus = account.status

					if (accountStatus === 'SETUP') {
						return (
							<div className="py-2 grid grid-cols-2">
								<div className="flex justify-start items-center space-x-4">
									<div className="flex flex-col justify-center">
										<RiShieldLine className="text-t-secondary w-7 h-7" />
									</div>
									<div className="flex flex-col justify-center">
										<div className="font-medium text-t-secondary">
											{planStepText(account.setup?.step)}
										</div>
									</div>
								</div>
								<div className="flex justify-start items-center">
									<GBadge text={'SETUP'} color={SubscriptionColors['NONE']} />
								</div>
							</div>
						)
					}
					return (
						<div className="py-2 grid grid-cols-2">
							<Link to={`/admin/subscription/${account.id}`} key={account.id}>
								<div className="flex justify-start items-center space-x-4">
									<div className="flex flex-col justify-center">
										<RiShieldLine className="text-primary-500 w-7 h-7" />
									</div>
									<div className="flex flex-col justify-center">
										<div className="font-medium text-primary-500 hover:text-primary-600 cursor-pointer">
											{
												PlanNames[
													account?.billing?.subscription?.plan.tier || ''
												]
											}
										</div>
										<div className="text-xs text-gray-500">
											{account?.billing?.organization.name}
										</div>
									</div>
								</div>
							</Link>
							<div className="flex justify-start items-center">
								<GBadge text={status} color={SubscriptionColors[status]} />
							</div>
						</div>
					)
				})}
			</>
		)
	}

	const handleDialogClose = () => {
		setDialogOpen(false)
		updateReferences && updateReferences()
	}

	const AllowReuse = (props: { row: ReferenceAdsAccountDTO }) => {
		const [allowReuse, setAllowReuse] = useState(props.row.allow_multiple)

		const handleAllowReuseUpdate = async (v: boolean) => {
			try {
				setAllowReuse(!allowReuse)
				await updateGadsReference(props.row.account_number, {
					allow_multiple: !allowReuse
				})
			} catch (e) {
				setAllowReuse(allowReuse)
			}

			setAllowReuse(v)
		}

		return (
			<GToggle
				disabled={isLoading('ADMIN_UPDATE_REFERENCE')}
				description={
					"Enable to allow others to use this account even if it's already added to ClickGUARD."
				}
				checked={allowReuse}
				onChange={(v) => handleAllowReuseUpdate(v)}
			/>
		)
	}

	return (
		<>
			{children && children(() => setDialogOpen(true))}
			<GDialog
				maxWidth="2xl"
				open={dialogOpen}
				onClose={() => handleDialogClose()}
				title="Account Info"
			>
				<div className="mt-8 space-y-4">
					{renderDomainOrAccNumber()}

					<div className="flex justify-between">
						<div className="w-1/2">
							<Template
								title="First Seen"
								value={<FirstSeenColumn row={data} />}
							/>
						</div>
						<div className="w-1/2">
							<Template
								title="Last Seen"
								value={<LastSeenColumn row={data} />}
							/>
						</div>
					</div>
					<Template title="Users" value={<ListOfUsers />} />
					<Template title="Accounts" value={<ListOfAccounts />} />
				</div>
			</GDialog>
		</>
	)
})
