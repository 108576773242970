import { TagInfoDTO } from 'api-models'
import {
	GButton,
	GDialog,
	GInput,
	GListBox,
	GLoading
} from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'

import { useStores } from 'hooks'

import { ChangeEvent, useEffect, useState } from 'react'
import { useUiStore } from 'store'
import { actionTypes, ManageUserTagsProps, ManageUserTagsState } from './types'

export const ManageUserTags = (props: ManageUserTagsProps) => {
	const { children, userId, tags } = props
	const { adminStore } = useStores()
	const { createTag, removeTag, getSystemTags } = adminStore
	const isLoading = useUiStore((s) => s.isLoading)

	const [state, setState] = useState<ManageUserTagsState>({
		addTag: undefined,
		createTag: '',
		removeTag: undefined,
		systemTags: [],
		status: 'selecting',
		action: 'none'
	})
	const [dialogOpen, setDialogOpen] = useState(false)

	useEffect(() => {
		;(async () => {
			const systemTags = await getSystemTags()
			const filteredTags = systemTags.filter((systemTag: string) => {
				const isItHere = tags.find((tag) => systemTag === tag.tag_name)
				return !isItHere
			})

			setState({ ...state, systemTags: filteredTags })
		})()
	}, [tags])

	const resetForm = () => {
		setState({
			...state,
			removeTag: undefined,
			createTag: '',
			addTag: undefined,
			errors: {}
		})
	}

	const handleSwitchingDialoges = (
		status: ManageUserTagsState['status'],
		name?: actionTypes,
		tag?: string
	) => {
		if ((!tag || !tag.trim()) && name) {
			setState((prevState) => ({
				...prevState,
				errors: { [name]: 'Field Must have a value' }
			}))
			return
		}
		if ((!tag || tag.trim().length < 2) && name) {
			setState((prevState) => ({
				...prevState,
				errors: { [name]: 'Filed cannot be less than 2 character' }
			}))
			return
		}

		setState((prevState) => ({
			...prevState,
			action: name || 'none',
			status: status
		}))
	}

	const handleAdd = async (tag?: string) => {
		if (!tag) return
		try {
			await createTag(userId, tag)
			resetForm()
			handleSwitchingDialoges('selecting')
		} catch (e) {
			return
		}
	}
	const handleRemoveTag = async (tag?: string) => {
		if (!tag) return
		try {
			await removeTag(userId, tag)
			resetForm()
			handleSwitchingDialoges('selecting')
		} catch (e) {
			return
		}
	}

	const actionsMapper = {
		createTag: () => handleAdd(state.createTag),
		addTag: () => handleAdd(state.addTag),
		removeTag: () => handleRemoveTag(state.removeTag?.tag_name),
		none: () => console.log('no action')
	}
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setState({ ...state, [e.target.name]: e.target.value })
	}
	const handleTagSelected = (value: TagInfoDTO | string, name: string) => {
		setState({ ...state, [name]: value })
	}
	const handleDialogClose = () => {
		resetForm()
		handleSwitchingDialoges('selecting')
		setDialogOpen(false)
	}
	return (
		<>
			{children && children(() => setDialogOpen(true))}

			<GDialog
				open={dialogOpen}
				maxWidth="2xl"
				onClose={() => handleDialogClose()}
				title={state.status === 'selecting' ? 'User Tags' : 'Confirm action'}
				subtitle={state.status === 'selecting' ? 'Add or remove user tags' : ''}
			>
				<div className={state.status === 'selecting' ? '' : 'hidden'}>
					<div className="mt-8 space-y-4">
						{/* tags */}
						<div>
							<h2 className="text-t-dark font-semibold text-lg mb-2">
								Current tags
							</h2>
							<div className="flex gap-2 flex-wrap">
								{isLoading('ADMIN_GET_USER') ? (
									<GLoading className="w-3 h-3" />
								) : (
									<>
										{tags.map((tag, index) => (
											<div key={index}>
												<div className="flex flex-col mb-3">
													<GBadge
														text={tag.tag_name || 'Unknown'}
														color="gray"
													/>
												</div>
											</div>
										))}
									</>
								)}
							</div>
						</div>
						{/* Selectors */}
						<div className="">
							<div className="flex justify-start items-start gap-4">
								<div className="mr-14 mt-2 ">Select a tag to add</div>
								<div className="flex-1 h-16">
									<GListBox<string>
										value={state?.addTag}
										options={state.systemTags}
										placeholder={'Select tag'}
										onChange={(val) => handleTagSelected(val, 'addTag')}
										renderLabel={(tag) => <>{tag}</>}
										className="w-full"
										error={state.errors?.addTag}
										disabled={
											isLoading('ADMIN_GET_USER') ||
											isLoading('ADMIN_UPDATE_TAGS')
										}
									/>
								</div>
								<GButton
									label="Add"
									color="primary"
									size="sm"
									onClick={() => {
										handleSwitchingDialoges(
											'confirmation',
											'addTag',
											state?.addTag
										)
									}}
									loading={isLoading('ADMIN_UPDATE_TAGS')}
								/>
							</div>

							<div className="flex justify-start items-start  gap-4">
								<div className="mr-16 mt-2 pr-1">Create a new tag</div>
								<div className="flex-1 h-16">
									<GInput
										name="createTag"
										value={state.createTag}
										onChange={(e) => handleChange(e)}
										error={state.errors?.createTag}
										disabled={
											isLoading('ADMIN_GET_USER') ||
											isLoading('ADMIN_UPDATE_TAGS')
										}
									/>
								</div>
								<GButton
									label="Add"
									color="primary"
									size="sm"
									onClick={() => {
										handleSwitchingDialoges(
											'confirmation',
											'createTag',
											state.createTag
										)
									}}
									loading={isLoading('ADMIN_UPDATE_TAGS')}
								/>
							</div>
							<div className="flex justify-start items-start gap-4">
								<div className="mr-8 mt-2">Select a tag to remove</div>
								<div className="flex-1 h-16">
									<GListBox<TagInfoDTO>
										value={state?.removeTag}
										options={tags}
										placeholder={'Select tag'}
										onChange={(val) => handleTagSelected(val, 'removeTag')}
										renderLabel={(tag) => <>{tag.tag_name}</>}
										error={state.errors?.removeTag}
										disabled={
											isLoading('ADMIN_GET_USER') ||
											isLoading('ADMIN_UPDATE_TAGS')
										}
									/>
								</div>

								<GButton
									label="Remove"
									color="danger"
									size="sm"
									onClick={() => {
										handleSwitchingDialoges(
											'confirmation',
											'removeTag',
											state.removeTag?.tag_name
										)
									}}
									loading={isLoading('ADMIN_UPDATE_TAGS')}
									disabled={
										isLoading('ADMIN_GET_USER') ||
										isLoading('ADMIN_UPDATE_TAGS')
									}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={state.status === 'confirmation' ? '' : 'hidden'}>
					<p className="text-t-dark font-medium mt-8">
						Warning: Modifying user tags can affect system behavior for certain
						actions. Are you sure you want to proceed?
					</p>

					<div className="flex justify-end gap-4 mt-8">
						<GButton
							label="Cancel"
							onClick={() => handleSwitchingDialoges('selecting')}
						/>
						<GButton
							label="Confirm"
							color="primary"
							onClick={actionsMapper[state.action || 'none']}
							loading={isLoading('ADMIN_UPDATE_TAGS')}
						/>
					</div>
				</div>
			</GDialog>
		</>
	)
}
