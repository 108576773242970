import { AccessLogDTO, UserDTO, UserIdentityDTO } from 'api-models'
import { create } from 'zustand'

import { AdminUsers } from 'services/admin'
import { ApiClient } from 'services/api-client'
import { Users } from 'services/user'
import { ResetPasswordService } from 'services/user/reset-password'
import { UserGuidingService } from 'services/userguiding'
import { ZendeskService } from 'services/zendesk'
import { useAdminStore } from 'store/admin-store'
import { useUiStore } from 'store/ui-store'
import { parseError } from 'utils/errors'
import {
	ICreateAffiliate,
	ICreateAffiliateUser,
	IUpdateAffiliate
} from '../../services/user/types'
import { AffiliateService } from '../../services/user/affiliate'

interface UserStoreState {
	user: UserDTO | null
	loginMethods: UserIdentityDTO[]
	accessLogs: AccessLogDTO[]
	MFADetails: any
	visualPreferences: string | null
	setUser: (user: UserDTO) => void
	setLoginMethods: (methods: UserIdentityDTO[]) => void
	setAccessLogs: (logs: AccessLogDTO[]) => void
	setVisualPreferences: (preferences: string | null) => void
	initUser: (ref?: string | null) => Promise<UserDTO>
	createAffiliateProfile: (
		payload: ICreateAffiliate
	) => Promise<UserDTO | undefined>
	createAffiliateUser: (
		payload: ICreateAffiliateUser
	) => Promise<UserDTO | undefined>
	updateAffiliateProfile: (payload: IUpdateAffiliate) => Promise<void>
	addNewEmail: (payload: { email: string }) => Promise<void>
	resendVerificationEmail: (payload: { email: string }) => Promise<void>
	deleteEmail: (payload: { email: string }) => Promise<void>
	updateEmail: (payload: { email: string }) => Promise<void>
	changeMainEmail: (payload: { email: string }) => Promise<void>
	verifyEmailByCode: (code: string) => Promise<void>
	updateProfile: (payload: {
		name: string
		phone_number: string | null
		timezone: string
		country: string | null
	}) => Promise<void>
	updateNotifications: (payload: {
		app_notification_values: Array<string>
		email_notification_values: Array<string>
	}) => Promise<void>
	updatePhoto: (formData: Partial<FormData>) => Promise<void>
	getLoginMethods: () => Promise<void>
	deleteLoginMethod: (id: string, provider: string) => Promise<void>
	resetPasswordForLoginMethod: (connection: string) => Promise<void>
	getAccessLogs: () => Promise<void>
	getUsersEnrollementDetails: () => Promise<void>
	setMFADetails: (mfa: any) => void
	toggleMFA: (payload: { enabled: boolean }, msg?: string) => Promise<void>
	acceptInvite: (code: string) => Promise<void>
	passwordReset: (email: string) => Promise<void>
	changePassword: (
		password: string,
		confirmPassword: string,
		code: string
	) => Promise<boolean | undefined>
	getPasswordRequest: (code: string) => Promise<any>
	updateVisualPreferences: (preferences: string) => Promise<void>
}

export const useUserStore = create<UserStoreState>((set, get) => ({
	user: null,
	loginMethods: [],
	accessLogs: [],
	MFADetails: null,
	visualPreferences: null,

	setUser: (user: UserDTO) => {
		set({ user })
		get().setVisualPreferences(user.visual_preferences)
	},

	setLoginMethods: (methods: UserIdentityDTO[]) =>
		set({ loginMethods: methods }),

	setAccessLogs: (logs: AccessLogDTO[]) => set({ accessLogs: logs }),

	setVisualPreferences: (preferences: string | null) =>
		set({ visualPreferences: preferences }),

	initUser: async (ref?: string | null) => {
		const imper_userId =
			useAdminStore.getState().imperId ||
			JSON.parse(localStorage.getItem('imper_userId') as string)

		let user = imper_userId
			? await AdminUsers.getById(imper_userId)
			: await Users.get()

		if (ref) {
			await Users.updateLegacyTag({ ref: ref })
			user = imper_userId
				? await AdminUsers.getById(imper_userId)
				: await Users.get()
		}
		ZendeskService.init()
		UserGuidingService.init(user)
		if (imper_userId) {
			ApiClient.setImpersonation(imper_userId)
		}
		get().setUser(user)
		return user
	},

	addNewEmail: async (payload: { email: string }) => {
		useUiStore.getState().setActionState('USER_ADD_NEW_EMAIL', 'loading')
		try {
			await Users.addNewEmail(payload)
			get().initUser()
			useUiStore
				.getState()
				.setActionState(
					'USER_ADD_NEW_EMAIL',
					'success',
					'Email address added. Check your inbox for verification link.'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_ADD_NEW_EMAIL',
					'error',
					err.response.data.message ? parseError(err) : 'Error adding new email'
				)
		}
	},
	createAffiliateProfile: async (payload): Promise<UserDTO | undefined> => {
		try {
			useUiStore.getState().setActionState('USER_CREATE_AFFILIATE', 'loading')
			const data = await AffiliateService.createProfile(payload)
			useUiStore
				.getState()
				.setActionState(
					'USER_CREATE_AFFILIATE',
					'success',
					'Affiliate profile created'
				)
			get().initUser()
			return data
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_CREATE_AFFILIATE',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error creating affiliate'
				)
		}
	},
	createAffiliateUser: async (payload): Promise<UserDTO | undefined> => {
		try {
			useUiStore.getState().setActionState('USER_CREATE_AFFILIATE', 'loading')
			const user = await AffiliateService.createAffiliateUser(payload)
			useUiStore
				.getState()
				.setActionState(
					'USER_CREATE_AFFILIATE',
					'success',
					'Affiliate profile created'
				)
			return user
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_CREATE_AFFILIATE',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error creating affiliate'
				)
		}
	},
	updateAffiliateProfile: async (payload): Promise<void> => {
		try {
			useUiStore.getState().setActionState('USER_UPDATE_AFFILIATE', 'loading')
			await AffiliateService.updateProfile(payload)
			useUiStore
				.getState()
				.setActionState(
					'USER_UPDATE_AFFILIATE',
					'success',
					'Affiliate profile updated'
				)
			get().initUser()
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_UPDATE_AFFILIATE',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error updated affiliate'
				)
		}
	},
	resendVerificationEmail: async (payload: { email: string }) => {
		useUiStore
			.getState()
			.setActionState('USER_RESEND_VERIFICATION_EMAIL', 'loading')
		try {
			await Users.resendVerificationEmail(payload)
			useUiStore
				.getState()
				.setActionState(
					'USER_RESEND_VERIFICATION_EMAIL',
					'success',
					'Verification email sent'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_RESEND_VERIFICATION_EMAIL',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error sending verification'
				)
		}
	},
	deleteEmail: async (payload: { email: string }) => {
		useUiStore.getState().setActionState('USER_DELETE_EMAIL', 'loading')
		try {
			await Users.deleteEmail(payload)
			get().initUser()
			useUiStore
				.getState()
				.setActionState('USER_DELETE_EMAIL', 'success', 'Email address removed')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_DELETE_EMAIL',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error removing email address'
				)
		}
	},
	updateEmail: async (payload: { email: string }) => {
		useUiStore.getState().setActionState('USER_SET_EMAIL', 'loading')
		try {
			await Users.updateEmail(payload)
			get().initUser()
			useUiStore
				.getState()
				.setActionState(
					'USER_SET_EMAIL',
					'success',
					'Your contact email is updated'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_SET_EMAIL',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error updating email address'
				)
		}
	},
	changeMainEmail: async (payload: { email: string }) => {
		useUiStore.getState().setActionState('USER_CHANGE_MAIN_EMAIL', 'loading')
		try {
			await Users.changeMainEmail(payload)
			useUiStore
				.getState()
				.setActionState(
					'USER_CHANGE_MAIN_EMAIL',
					'success',
					'Email address changed'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_CHANGE_MAIN_EMAIL',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error changing email address'
				)
			throw Error
		}
	},
	verifyEmailByCode: async (code: string) => {
		useUiStore.getState().setActionState('USER_SET_EMAIL', 'loading')
		try {
			await Users.verifyEmailByCode(code)
			useUiStore.getState().setActionState('USER_SET_EMAIL', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_SET_EMAIL',
					'error',
					err.response.data.message ? parseError(err) : 'Error verifying email'
				)
			throw Error
		}
	},
	updateProfile: async (payload: {
		name: string
		phone_number: string | null
		timezone: string
		country: string | null
	}) => {
		useUiStore.getState().setActionState('USER_SAVE_PROFILE', 'loading')
		try {
			await Users.updateProfile(payload)
			get().initUser()

			useUiStore
				.getState()
				.setActionState('USER_SAVE_PROFILE', 'success', 'Your profile is saved')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_SAVE_PROFILE',
					'error',
					err.response.data.message ? parseError(err) : 'Error updating profile'
				)
		}
	},
	updateNotifications: async (payload: {
		app_notification_values: Array<string>
		email_notification_values: Array<string>
	}) => {
		useUiStore.getState().setActionState('USER_SAVE_NOTIFICATIONS', 'loading')
		try {
			await Users.updateNotifications(payload)
			get().initUser()

			useUiStore
				.getState()
				.setActionState(
					'USER_SAVE_NOTIFICATIONS',
					'success',
					'Your notification preferences are saved'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_SAVE_NOTIFICATIONS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error updating notifications preferences'
				)
		}
	},
	updatePhoto: async (formData: Partial<FormData>) => {
		useUiStore.getState().setActionState('USER_SAVE_PROFILE', 'loading')
		try {
			await Users.updatePhoto(formData)
			get().initUser()

			useUiStore.getState().setActionState('USER_SAVE_PROFILE', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_SAVE_PROFILE',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error updating profile photo'
				)
		}
	},
	getLoginMethods: async () => {
		try {
			useUiStore.getState().setActionState('USER_GET_LOGIN_METHODS', 'loading')
			const loginMethods = await Users.getLoginMethods()
			get().setLoginMethods(loginMethods)
			useUiStore.getState().setActionState('USER_GET_LOGIN_METHODS', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_GET_LOGIN_METHODS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error loading login methods'
				)
		}
	},
	deleteLoginMethod: async (id: string, provider: string) => {
		try {
			useUiStore
				.getState()
				.setActionState('USER_REMOVE_LOGIN_METHOD', 'loading')
			await Users.deleteLoginMethod(id, provider)
			get().setLoginMethods(get().loginMethods.filter((item) => item.id !== id))
			useUiStore
				.getState()
				.setActionState(
					'USER_REMOVE_LOGIN_METHOD',
					'success',
					'Login method successfully removed'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_REMOVE_LOGIN_METHOD',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error deleting login method'
				)
		}
	},
	resetPasswordForLoginMethod: async (connection: string) => {
		try {
			useUiStore.getState().setActionState('USER_RESET_PASSWORD', 'loading')
			await Users.resetPasswordForLoginMethod(connection)
			useUiStore
				.getState()
				.setActionState(
					'USER_RESET_PASSWORD',
					'success',
					'Instructions for resetting password were sent to your email address'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_RESET_PASSWORD',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error resetting password login method'
				)
		}
	},
	getAccessLogs: async () => {
		try {
			useUiStore.getState().setActionState('USER_GET_LOGIN_HISTORY', 'loading')
			const logs = await Users.getAccessLogs()
			get().setAccessLogs(logs)
			useUiStore.getState().setActionState('USER_GET_LOGIN_HISTORY', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_GET_LOGIN_HISTORY',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error loading access logs'
				)
		}
	},
	getUsersEnrollementDetails: async () => {
		try {
			useUiStore.getState().setActionState('USER_GET_MFA', 'loading')
			const mfa = await Users.getUsersEnrollementDetails()
			get().setMFADetails(mfa)
			useUiStore.getState().setActionState('USER_GET_MFA', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_GET_MFA',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error loading user enrollment details'
				)
		}
	},
	setMFADetails: (mfa: any) => {
		set({ MFADetails: mfa })
	},
	toggleMFA: async (payload: { enabled: boolean }, msg?: string) => {
		try {
			useUiStore.getState().setActionState('USER_SET_MFA', 'loading')
			await Users.toggleMFA(payload)
			useUiStore.getState().setActionState('USER_SET_MFA', 'success', msg)
			!payload.enabled && get().initUser()
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_SET_MFA',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to update MFA'
				)
			throw new Error()
		}
	},
	acceptInvite: async (code: string) => {
		try {
			useUiStore.getState().setActionState('USER_ACCEPT_INVITE', 'loading')
			await Users.acceptUserAccessInvite(code)
			useUiStore.getState().setActionState('USER_ACCEPT_INVITE', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_ACCEPT_INVITE',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to accept invitation'
				)
			throw new Error()
		}
	},
	passwordReset: async (email: string) => {
		try {
			useUiStore.getState().setActionState('USER_PASSWORD_RESET', 'loading')
			await ResetPasswordService.sendPasswordReset(email)
			useUiStore
				.getState()
				.setActionState(
					'USER_PASSWORD_RESET',
					'success',
					'Instructions for resetting password were sent to your email address'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_PASSWORD_RESET',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to accept invitation'
				)
		}
	},
	changePassword: async (
		password: string,
		confirmPassword: string,
		code: string
	) => {
		try {
			useUiStore.getState().setActionState('USER_PASSWORD_CHANGE', 'loading')
			await ResetPasswordService.changePassword(password, confirmPassword, code)
			useUiStore
				.getState()
				.setActionState(
					'USER_PASSWORD_CHANGE',
					'success',
					'password changed successfully please login'
				)
			return true
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_PASSWORD_CHANGE',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to change password'
				)
		}
	},
	getPasswordRequest: async (code: string) => {
		try {
			useUiStore.getState().setActionState('USER_PASSWORD_REQUEST', 'loading')
			const data = await ResetPasswordService.getPasswordRequest(code)
			useUiStore.getState().setActionState('USER_PASSWORD_REQUEST', 'success')
			return data
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'USER_PASSWORD_REQUEST',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to change password'
				)
		}
	},
	updateVisualPreferences: async (preferences: string) => {
		try {
			Users.updateVisualPreferences(preferences)
			get().setVisualPreferences(preferences)
		} catch (e) {
			console.log(e)
		}
	}
}))
