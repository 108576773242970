import {
	ICreateOffer,
	ICreateOfferErrors,
	ICreateOfferPayload,
	IUpdateOfferPayload
} from './types'

export const validateCurrentItem = (
	index: number,
	offerList: ICreateOffer[]
): ICreateOfferErrors | null => {
	const errors: ICreateOfferErrors = {
		price: '',
		spend: '',
		interval_count: '',
		amount_off: '',
		days: '',
		expired_at: '',
		months: ''
	}

	if (offerList[index].price < 1) {
		errors['price'] = 'Price is required'
	} else {
		errors['price'] = ''
	}

	if (offerList[index].spend < 1000) {
		errors['spend'] = 'Spend can"t be less then 1000'
	} else {
		errors['spend'] = ''
	}
	if (offerList[index].interval_count < 1) {
		errors['interval_count'] = 'Interval" can"t be less then 1'
	} else {
		errors['interval_count'] = ''
	}

	if (offerList[index].toggleButton.discount) {
		if (
			Number(offerList[index].amount_off) < 1 &&
			Number(offerList[index].percent_off) < 1
		) {
			errors['amount_off'] = 'Amount can"t be less than 1'
		}
	} else {
		errors['amount_off'] = ''
	}

	if (
		offerList[index].toggleButton.discount &&
		offerList[index].duration === 'repeating'
	) {
		Number(offerList[index].months) < 1 &&
			(errors['months'] = 'month can"t be less 1')
	} else {
		errors['months'] = ''
	}

	if (offerList[index]?.toggleButton.trial) {
		offerList[index]?.days < 1 && (errors['days'] = 'Days can"t be less than 1')
	} else {
		errors['days'] = ''
	}
	if (offerList[index]?.toggleButton.exp) {
		offerList[index].expired_at < 1 &&
			(errors['expired_at'] = 'Exp can"t be less than 1')
	} else {
		errors['expired_at'] = ''
	}

	return errors
}

export const getCreateOfferPayload = (
	data: ICreateOffer[],
	user_id: string,
	account_id: string | null,
	offer_request_id: string | null
) => {
	const payload: ICreateOfferPayload = {
		user_id,
		account_id,
		offer_request_id,
		offers: data.map((offer) => {
			return {
				interval: offer.interval,
				tier: offer.tier,
				publish: offer.publish,
				price: Number(offer.price * 100),
				spend: Number(offer.spend),
				interval_count: Number(offer.interval_count),
				coupon: offer.toggleButton.discount
					? {
							months: Number(offer.months),
							amount_off: Number(offer.amount_off * 100),
							duration: offer.duration,
							percent_off: Number(offer.percent_off)
					  }
					: null,
				days: offer.toggleButton.trial ? Number(offer.days) : null,
				expired_at: offer.toggleButton.exp ? Number(offer.expired_at) : null
			}
		})
	}
	return payload
}

export const getOfferUpdatePayload = (
	updateOffer: ICreateOffer,
	account_id: string | null
) => {
	const payload: IUpdateOfferPayload = {
		account_id,
		spend: Number(updateOffer.spend),
		price: Number(updateOffer.price * 100),
		interval: updateOffer.interval,
		publish: updateOffer.publish,
		interval_count: Number(updateOffer.interval_count),
		days: updateOffer.days ? Number(updateOffer.days) : null,
		expired_at: updateOffer.toggleButton.exp
			? Number(updateOffer.expired_at)
			: null,
		coupon: updateOffer.toggleButton.discount
			? {
					months: Number(updateOffer.months),
					amount_off: Number(updateOffer.amount_off * 100),
					duration: updateOffer.duration,
					percent_off: Number(updateOffer.percent_off)
			  }
			: null
	}

	return payload
}
