import { UserAccessInfoDTO } from 'api-models'
import { GButton } from 'components/basic-blocks/g-button'
import { ModalTypes } from 'components/basic-blocks/g-modal/types'
import { GRadio } from 'components/basic-blocks/g-radio'
import { useStores } from 'hooks'
import { ChangeEvent, useState } from 'react'
import { RiQuestionLine } from 'react-icons/ri'
import { useUiStore } from 'store'
import { GTooltip } from '../../../../components/basic-blocks'

export const ManagerMember = (props: { row: UserAccessInfoDTO }) => {
	const { row } = props
	const {
		accountStore,
		userStore: { user }
	} = useStores()
	const { user_name, user_id } = row
	const setModalState = useUiStore((s) => s.setModalState)
	const setModalContent = useUiStore((s) => s.setModalContent)

	const [roleChange, setRoleChange] = useState(false)

	const { deleteAccountUser, account, userAccessLevel, isOwnerAccess } =
		accountStore

	const renderDeleteMemberModal = () => {
		setModalContent({
			title: 'Delete User',
			description: `Are you sure you want to delete ${
				user_name ? user_name : 'this user'
			}?`,
			type: ModalTypes.DANGER,
			showIcon: false,
			confirmLabel: 'Delete',
			confirm: () => confirmDeleteMember()
		})
	}

	const renderManageMemberModal = () => {
		setRoleChange(!roleChange)
		setModalContent({
			title: 'Manager Member',
			type: ModalTypes.INFO,
			actionsEnabled: false,
			showIcon: false,
			body: <ModalBody row={row} deleteModal={renderDeleteMemberModal} />
		})
	}

	const confirmDeleteMember = () => {
		deleteAccountUser(account!.id, user_id)
		setModalState(false)
	}

	return (
		<GTooltip
			content={
				userAccessLevel(user!.id) === 'READ'
					? 'Analysts don’t have permission for this action'
					: userAccessLevel(user!.id) === 'WRITE'
					? 'Manager don’t have permission for this action'
					: ''
			}
		>
			<GButton
				disabled={!isOwnerAccess(user!.id)}
				label="Manage"
				labelClassName="font-bold"
				variant="text"
				className="h-[25px]"
				color={
					userAccessLevel(user!.id) === 'READ' ? 'neutral' : 'buttonPrimary'
				}
				size="sm"
				onClick={() => renderManageMemberModal()}
			/>
		</GTooltip>
	)
}

const ModalBody = (props: {
	row: UserAccessInfoDTO
	deleteModal: () => void
}) => {
	const { row, deleteModal } = props
	const { access_level, user_id } = row

	const {
		accountStore,
		userStore: { user }
	} = useStores()
	const setModalState = useUiStore((s) => s.setModalState)
	const { updateAccountUser, account, isOwnerAccess } = accountStore

	const [role, setRole] = useState<any>(access_level || 'READ')

	const RemoveButton = () => {
		return (
			<GButton
				label="Remove"
				variant="outlined"
				color="danger"
				onClick={() => handleRemoveButtonClick()}
			/>
		)
	}

	const handleRemoveButtonClick = () => {
		setModalState(false)
		deleteModal()
	}

	const handleRoleChange = (
		e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
	) => {
		const { value } = e.target
		setRole(value)
	}

	const confirmUpdateMember = () => {
		const payload = {
			access_level: role
		}
		updateAccountUser(account!.id, user_id, payload)
		setModalState(false)
	}

	return (
		<>
			<div className="my-4 w-full">
				<div className="block text-sm font-medium text-gray-700">Name</div>
				<div className="block text-sm font-medium text-gray-700">
					{row.user_name}
				</div>
			</div>
			<div className="my-4 w-full">
				<div className="block text-sm font-medium text-gray-700">Email</div>
				<div className="block text-sm font-medium text-gray-700">
					{row.user_email}
				</div>
			</div>
			<div className="flex flex-col gap-1 w-full">
				<div className="text-sm font-medium text-gray-700">Team role</div>
				<GRadio
					checked={role === 'WRITE'}
					label="Manager"
					name="team-role"
					value={'WRITE'}
					onChange={(e) => handleRoleChange(e)}
				/>
				<GRadio
					checked={role === 'READ'}
					label="Analyst"
					name="team-role"
					value={'READ'}
					onChange={(e) => handleRoleChange(e)}
				/>

				{isOwnerAccess(user!.id) && (
					<>
						<div className="text-sm font-medium text-gray-700 flex items-center gap-1">
							Transfer ownership
							<GTooltip content="Transferring ownership to another member will make them a sole workspace owner, and your role will be reduced to manager.">
								<RiQuestionLine className="text-gray-500 h-3 w-3" />
							</GTooltip>
						</div>
						<GRadio
							checked={role === 'OWNER'}
							label="Owner"
							name="team-role"
							value={'OWNER'}
							onChange={(e) => handleRoleChange(e)}
						/>
					</>
				)}
			</div>
			<div className="flex justify-between mt-5">
				<div>
					<RemoveButton />
				</div>
				<div>
					<GButton
						label="Cancel"
						onClick={() => setModalState(false)}
						className="mr-5"
					>
						Cancel
					</GButton>

					<GButton
						variant="contained"
						label="Save"
						color="primary"
						size="sm"
						onClick={() => confirmUpdateMember()}
					/>
				</div>
			</div>
		</>
	)
}
