import { ProductAccountDTO } from 'api-models'
import { WorkspaceAvatar } from 'components'
import { GTooltip } from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'
import { GDateTime } from 'components/basic-blocks/g-datetime'
import { GTableColumn } from 'components/basic-blocks/g-table/types'
import { PlanNames } from 'config'
import moment from 'moment'
import {
	RiCloseLine,
	RiEditBoxLine,
	RiErrorWarningLine,
	RiQuestionLine
} from 'react-icons/ri'
import { NavLink } from 'react-router-dom'
import {
	formatCurrency,
	formatPriceInterval,
	formatSpend,
	planStepText,
	sanitizeURL
} from 'utils'
import { SubscriptionColors } from 'utils/colors'
import { ReactElement } from 'react'

export const columnsForUser: Array<GTableColumn<ProductAccountDTO>> = [
	{
		label: 'Workspace',
		render: (row: ProductAccountDTO) => <WorkspaceColumn row={row} />
	},
	{
		label: 'Status',
		render: (row: ProductAccountDTO) => statusColumn(row),
		className: 'justify-center'
	},
	{ label: 'Price', render: (row: ProductAccountDTO) => priceColumn(row) },
	{ label: 'Ad spend', render: (row: ProductAccountDTO) => adSpendColumn(row) },
	{
		label: 'Next event',
		path: 'next_event',
		render: (row: ProductAccountDTO) => eventColumn(row),
		sortable: true
	},
	{
		label: 'Created',
		path: 'created_at',
		render: (row: ProductAccountDTO) => renderCreated(row),
		sortable: true
	},
	{
		label: 'Notice',
		render: (row: ProductAccountDTO) => <Notices account={row} />,
		className: 'justify-center'
	}
]

const WorkspaceColumn = (props: { row: ProductAccountDTO }) => {
	const { row } = props
	const link = `/admin/subscription/${props.row.id}`
	const planName = PlanNames[props.row?.billing?.subscription?.plan.tier || '']
	const status = props.row.status

	return (
		<div className="flex justify-center items-center space-x-4">
			<div className="min-w-[2rem]">
				<WorkspaceAvatar size={8} name={row?.name} src={row?.avatar} />
			</div>
			<div className="flex flex-col justify-center">
				<NavLink to={link}>
					<div className="font-medium text-primary-500 hover:text-primary-600 cursor-pointer truncate max-w-[200px]">
						{sanitizeURL(props.row.name)}
					</div>
				</NavLink>

				{status !== 'SETUP' && (
					<div className="text-xs text-gray-500">{planName || ''}</div>
				)}
			</div>
		</div>
	)
}

const statusColumn = (row: ProductAccountDTO) => {
	const status = row?.billing?.subscription?.status || 'NONE'
	const type = row?.billing?.subscription?.type || 'NONE'
	const accountStatus = row.status

	if (accountStatus === 'SETUP') {
		return <GBadge text={'SETUP'} color={SubscriptionColors.NONE} />
	}
	if (type === 'TRIAL' && status === 'ACTIVE') {
		return <GBadge text={'TRIAL'} color={SubscriptionColors.TRIAL} />
	}
	return <GBadge text={status} color={SubscriptionColors[status]} />
}

const priceColumn = (row: ProductAccountDTO) => {
	const status = row?.billing?.subscription?.status || 'NONE'
	const type = row?.billing?.subscription?.type || 'NONE'
	const accountStatus = row.status
	const plan = row.billing?.subscription?.plan
	const nextPlan = row.billing?.subscription?.next_plan

	let color: string
	if (status === 'ACTIVE') {
		color = 'green'
	} else {
		color = 'gray'
	}

	if (status === 'SWITCHING') {
		return (
			<div>
				<div className="flex flex-col justify-start gap-2">
					<GBadge
						text={
							<span>
								{formatCurrency(plan?.price)}{' '}
								{formatPriceInterval(plan?.interval, plan?.interval_count)}
							</span>
						}
						color="gray"
					/>
					<GBadge
						text={
							<span>
								{formatCurrency(nextPlan?.price)}{' '}
								{formatPriceInterval(
									nextPlan?.interval,
									nextPlan?.interval_count
								)}
							</span>
						}
						color="green"
					/>
				</div>
			</div>
		)
	}

	return (
		<div className="flex flex-col justify-start gap-2">
			{accountStatus === 'SETUP' ? (
				<div
					className={
						'text-center inline-flex rounded-full py-1 text-sm font-semibold text-t-secondary'
					}
				>
					{accountStatus === 'SETUP' && (
						<RiCloseLine className="h-5 w-5 mr-2 text-t-secondary" />
					)}
				</div>
			) : type === 'TRIAL' && nextPlan?.price !== undefined ? (
				<GBadge
					className={'rounded-md align-center'}
					text={
						<span>
							{formatCurrency(nextPlan?.price)}{' '}
							{formatPriceInterval(
								nextPlan?.interval,
								nextPlan?.interval_count
							)}
						</span>
					}
					color={color}
				/>
			) : type === 'TRIAL' && nextPlan?.price === undefined ? (
				<GBadge
					className={'rounded-md align-center'}
					text="No plan"
					color={'amber'}
				/>
			) : (
				<GBadge
					text={
						<span>
							{formatCurrency(plan?.price)}{' '}
							{formatPriceInterval(plan?.interval, plan?.interval_count)}
						</span>
					}
					color={color}
				/>
			)}
		</div>
	)
}

const adSpendColumn = (row: ProductAccountDTO) => {
	const accountStatus = row.status
	const status = row?.billing?.subscription?.status || 'NONE'
	const plan = row.billing?.subscription?.plan
	const type = row?.billing?.subscription?.type
	const nextPlan = row.billing?.subscription?.next_plan

	let color: string
	if (status === 'ACTIVE') {
		color = 'purple'
	} else {
		color = 'gray'
	}

	if (status === 'SWITCHING') {
		return (
			<div>
				<div className="flex flex-col justify-start gap-2">
					<GBadge text={<span>{formatSpend(plan?.spend)}</span>} color="gray" />
					<GBadge
						text={<span>{formatSpend(nextPlan?.spend)}</span>}
						color="purple"
					/>
				</div>
			</div>
		)
	}

	if (type === 'TRIAL') {
		return (
			<div className="text-md">
				{nextPlan && nextPlan?.spend !== 0 ? (
					<GBadge
						className={'rounded-md align-center'}
						text={<span>{formatSpend(nextPlan?.spend)}</span>}
						color={color}
					/>
				) : (
					<GBadge
						className={'rounded-md align-center'}
						text="No plan"
						color={'amber'}
					/>
				)}
			</div>
		)
	}

	if (accountStatus === 'SETUP' || accountStatus === 'INACTIVE') {
		return (
			<div>
				<div className="flex items-center w-full">
					<RiCloseLine className="h-5 w-5 mr-2 text-t-secondary" />
					None
				</div>
			</div>
		)
	}

	return (
		<div className="text-xs">
			<GBadge text={<span>{formatSpend(plan?.spend)}</span>} color={color} />
		</div>
	)
}

const eventColumn = (row: ProductAccountDTO) => {
	const nextPlan = row.billing?.subscription?.next_plan

	const status = row?.billing?.subscription?.status
	const type = row?.billing?.subscription?.type
	const accountStatus = row?.status
	const endsAt = row?.billing?.subscription?.ends_at
	const endedAt = row?.billing?.subscription?.ended_at

	const date = row?.billing?.subscription?.next_billing_date

	let title: ReactElement | string = ''
	const subtitle = moment(date).format('llll')

	if (accountStatus === 'SETUP') return <GBadge text="NONE" />

	if (status === 'ACTIVE') {
		if (type === 'TRIAL' && !nextPlan) {
			title = (
				<span>
					Cancels{' '}
					<span className="font-bold">
						<GDateTime date={endsAt || endedAt} />
					</span>
				</span>
			)
		} else {
			title = (
				<span>
					Starts{' '}
					<span className="font-bold">
						<GDateTime date={date} />
					</span>
				</span>
			)
		}
	}
	if (status === 'SWITCHING') {
		title = (
			<span>
				Starting <strong>{nextPlan?.name}</strong>{' '}
				<span className="font-semibold">
					<GDateTime date={date} />
				</span>
			</span>
		)
	}
	if (status === 'CANCELLING') {
		title = (
			<span>
				Cancelling{' '}
				<span className="font-semibold">
					<GDateTime date={date} />
				</span>
			</span>
		)
	}
	if (status === 'CANCELLED') {
		title = (
			<span>
				Cancelled{' '}
				<span className="font-semibold">
					<GDateTime date={endedAt || endsAt} />
				</span>
			</span>
		)
	}

	return (
		<div className="flex flex-1 justify-start">
			<div className="flex flex-col space-y-1 text-xs">
				<div className="font-medium text-gray-700">{title}</div>
				<GTooltip content={subtitle}>
					{<div className="text-xs text-gray-500"></div>}
				</GTooltip>
				{/* {subtitle && <div className="text-xs text-gray-500">{subtitle}</div>} */}
			</div>
		</div>
	)
}
const renderCreated = (account: ProductAccountDTO) => {
	return (
		<GTooltip
			content={
				<div className="flex flex-col">
					<div className="">
						{moment(account.created_at).format('MMMM Do YYYY - h:mm a')}
					</div>
				</div>
			}
		>
			<div className="flex justify-center text-xs">
				<div>{moment(account.created_at).format('MMMM Do YYYY')}</div>
			</div>
		</GTooltip>
	)
}

const Notices = (props: { account: ProductAccountDTO }) => {
	const { account } = props
	const custom = account.billing?.subscription?.plan.tier === 'custom_pro'
	const limitBreached = account.limit_breached
	const cancelReq = account.cancel_request
	const offerReq = account.offer_request
	const setup = account.status === 'SETUP'

	const hasNotice = custom || limitBreached || cancelReq || offerReq || setup

	return (
		<div className="flex items-center space-x-2">
			{account.offer_request && (
				<GTooltip
					content={`Request for ${formatSpend(
						account.offer_request.spend
					)} ad spend`}
				>
					<RiEditBoxLine className="w-6 h-6 text-purple-500" />
				</GTooltip>
			)}
			{account.cancel_request && (
				<GTooltip
					content={`Requested cancellation: ${account.cancel_request.note}`}
				>
					<RiErrorWarningLine className="w-6 h-6 text-red-500" />
				</GTooltip>
			)}
			{account.limit_breached && (
				<GTooltip content={'Spend limit breached'}>
					<RiQuestionLine className="w-6 h-6 text-amber-500" />
				</GTooltip>
			)}
			{account.billing?.subscription?.plan.tier == 'custom_pro' && (
				<GTooltip
					content={`Ad spend: ${formatSpend(
						account.billing.subscription.plan?.spend
					)}`}
				>
					<RiQuestionLine className="w-6 h-6 text-gray-500" />
				</GTooltip>
			)}
			{setup && (
				<GTooltip content={planStepText(account.setup?.step)}>
					<RiQuestionLine className="w-6 h-6 text-gray-500" />
				</GTooltip>
			)}
			{!hasNotice && !setup && (
				<div className="flex items-center w-full">
					<RiCloseLine className="h-5 w-5 mr-2 text-t-secondary" />
				</div>
			)}
		</div>
	)
}
