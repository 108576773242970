import { ChangeEvent, useState } from 'react'
import { IoCheckmark } from 'react-icons/io5'
import { LiaExclamationCircleSolid } from 'react-icons/lia'
import { RiQuestionLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import timezones from 'timezones-list'
import { useShallow } from 'zustand/react/shallow'
import {
	GButton,
	GComboBox,
	GInput,
	GSection,
	GTooltip
} from '../../../../components/basic-blocks'
import { GCheckbox } from '../../../../components/basic-blocks/g-checkbox'
import { ICreateAffiliate } from '../../../../services/user/types'
import { useUserStore } from '../../../../store'
import { isNoClickGuardAffiliate } from '../../../../utils/user'
import { validateEmail } from '../../../../utils/validation'

export const AffiliateSetting = () => {
	const navigate = useNavigate()
	const { createAffiliate, user, updateAffiliate } = useUserStore(
		useShallow((s) => ({
			user: s.user,
			createAffiliate: s.createAffiliateProfile,
			updateAffiliate: s.updateAffiliateProfile
		}))
	)
	const tzList = timezones.map((item) => item.tzCode)
	const [userData, setUserData] = useState<ICreateAffiliate>({
		first_name:
			user?.affiliate_profile?.first_name || user?.name?.split(' ')[0] || '',
		last_name:
			user?.affiliate_profile?.last_name || user?.name?.split(' ')[1] || '',
		email: user?.affiliate_profile?.email || user?.email || '',
		timezone: user?.affiliate_profile?.timezone || user?.timezone || '',
		token: 'www.clickguard.com?/via=' + (user?.affiliate_profile?.token || ''),
		paypal_email: user?.affiliate_profile?.paypal_email || user?.email || '',
		notifications: false
	})

	const [error, setError] = useState({
		first_name: undefined,
		last_name: undefined,
		email: undefined,
		timezone: undefined,
		token: undefined,
		paypal_email: undefined
	})

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target
		if (name === 'token' && value.length < 24) {
			return
		}
		setUserData({ ...userData, [name]: value })
	}

	const handleSubmit = async () => {
		let isValid = true
		const { token, notifications, ...rest } = userData
		Object.keys(rest).forEach((value) => {
			if (!userData[value as keyof ICreateAffiliate]) {
				isValid = false
				let emailError: string | undefined = '*Required field'
				if (value.includes('email')) {
					emailError = validateEmail(userData[value] as string)
				}
				setError((prev) => ({ ...prev, [value]: emailError }))
			} else {
				setError((prev) => ({ ...prev, [value]: undefined }))
			}
		})
		if (!token?.split('=')[1]) {
			isValid = false
			setError((prev) => ({ ...prev, token: '*Required field' } as any))
		} else {
			setError((prev) => ({ ...prev, token: undefined } as any))
		}
		if (!isValid) return
		if (user?.affiliate_profile?.affiliate_id) {
			await updateAffiliate({ ...rest, notifications })
			return
		}
		const data = await createAffiliate({
			...rest,
			notifications,
			token: token.split('=')[1]
		})
		if (data) {
			if (isNoClickGuardAffiliate(user)) {
				navigate('/affiliate/legal')
			} else {
				navigate('/workspace/affiliate?tab=dashboard')
			}
		}
	}

	return (
		<GSection
			lineBreak={true}
			containerClassName="max-w-3xl"
			subtitle={
				<div className=" pt-2 text-sm flex items-center gap-x-2">
					<LiaExclamationCircleSolid className="h-4 w-4" />
					Details auto populated from your ClickGUARD Account. The fields are
					editable.
				</div>
			}
		>
			<div className="py-3 flex flex-col justify-center gap-y-6">
				<div className="flex justify-between items-center">
					<p className="text-t-secondary text-base font-bold">First name</p>
					<div className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
						<GInput
							name="first_name"
							placeholder="Doe"
							inputClassName="min-w-[360px] text-t-default w-full font-medium"
							value={userData.first_name}
							onChange={handleChange}
							error={error.first_name}
						/>
					</div>
				</div>
				<div className="flex justify-between items-center">
					<p className="text-t-secondary text-base font-bold">Last name</p>
					<div className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
						<GInput
							name="last_name"
							placeholder="Joe"
							inputClassName="min-w-[360px] text-t-default w-full font-medium"
							value={userData.last_name}
							onChange={handleChange}
							error={error.last_name}
						/>
					</div>
				</div>
				<div className="flex justify-between items-center">
					<p className="text-t-secondary text-base font-bold">Email Address</p>
					<div className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
						<GInput
							name="email"
							inputClassName="min-w-[360px] text-t-default w-full font-medium"
							value={userData.email}
							onChange={handleChange}
							error={error.email}
						/>
					</div>
				</div>
				<div className="flex justify-between items-center">
					<p className="text-t-secondary text-base font-bold">Timezone</p>
					<div className="mt-1 text-md text-t-default sm:mt-0 sm:col-span-2">
						<GComboBox
							name="timezone"
							options={tzList}
							value={userData.timezone}
							inputClass="min-w-[360px] text-base font-medium"
							onChange={(e) =>
								handleChange({
									target: { name: 'timezone', value: e }
								} as any)
							}
							filter={(item, query) =>
								item.toLowerCase().includes(query.toLocaleLowerCase())
							}
							renderOption={(i) => i}
							renderValue={(i) => i}
							error={error.timezone}
						/>
					</div>
				</div>
				<div className="flex justify-between items-center">
					<div className="flex gap-x-2 items-center text-t-secondary text-base font-bold">
						Affiliate Link
						<GTooltip content="Generate your unique referral slug to share with others and earn commissions.">
							<RiQuestionLine />
						</GTooltip>
					</div>
					<div className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
						<GInput
							name="token"
							disabled={!!user?.affiliate_profile?.affiliate_id}
							inputClassName="min-w-[360px] text-t-default w-full font-medium"
							value={userData.token}
							onChange={handleChange}
							error={error.token}
						/>
					</div>
				</div>
				<div className="flex justify-between items-center">
					<div className="flex gap-x-2 items-center text-t-secondary text-base font-bold">
						Paypal email{' '}
						<GTooltip content="This will be used to send your earned commissions securely.">
							<RiQuestionLine />
						</GTooltip>
					</div>
					<div className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
						<GInput
							name="paypal_email"
							inputClassName="min-w-[360px] text-t-default w-full font-medium"
							value={userData.paypal_email}
							onChange={handleChange}
							error={error.paypal_email}
						/>
					</div>
				</div>
				<div className="flex justify-between items-center">
					<p className="flex gap-x-2 items-center text-t-secondary text-base font-bold">
						Notifications
					</p>
					<div className="mt-1 w-[360px] flex items-center justify-start">
						<GCheckbox
							label="Email me when a new commission is earned"
							name="notifications"
							checked={userData.notifications}
							onChange={(e) =>
								setUserData({ ...userData, notifications: e.target.checked })
							}
						/>
						<div className="ml-2 mt-1">
							<GTooltip content="Receive timely notifications by email whenever a new commission is earned.">
								<RiQuestionLine />
							</GTooltip>
						</div>
					</div>
				</div>
				<div className="flex justify-center items-center">
					<GButton
						icon={
							!user?.affiliate_profile?.affiliate_id ? IoCheckmark : undefined
						}
						// loading={
						// 	isLoading('USER_UPDATE_AFFILIATE') ||
						// 	isLoading('USER_CREATE_AFFILIATE')
						// }
						className="w-[188px] h-[36px]"
						variant="contained"
						label={
							user?.affiliate_profile?.affiliate_id
								? 'Save details'
								: 'Activate affiliate link'
						}
						onClick={handleSubmit}
						// label=""
						color="primary"
						labelClassName="font-bold"
					/>
				</div>
			</div>
		</GSection>
	)
}
