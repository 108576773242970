import { Popover } from '@headlessui/react'
import { SearchIcon } from '@heroicons/react/solid'
import { Placement } from '@popperjs/core'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { GInput } from '../g-input'
import { GSlideOver } from '../g-slide-over'
import { GTransition } from '../g-transition'

interface GSwitcherProps<T> {
	items: T[]
	accounts?: T[]
	selectedItem: any
	footer: ReactElement | Element | string
	header?: ReactElement | Element | string
	filter?: (i: T, s: string) => boolean
	renderOption: (
		i: T,
		handleClose: () => void
	) => ReactElement | Element | string
	placeholder: string
	responsive?: boolean
	disableSearch?: boolean
	width?: string
	position?: Placement
	offset?: [number, number]
}

export const GSwitcher = <T,>(props: GSwitcherProps<T>) => {
	const {
		items,
		selectedItem,
		width,
		footer,
		renderOption,
		placeholder,
		filter,
		responsive = false,
		disableSearch = false,
		position,
		offset,
		header
	} = props

	const [open, setOpen] = useState(false)
	const [referenceElement, setReferenceElement] =
		useState<HTMLDivElement | null>(null)
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
		null
	)
	const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
	const [searchQuery, setSearchQuery] = useState('')
	const [filteredItems, setFilteredItems] = useState<T[]>(items)
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		modifiers: [
			{ name: 'arrow', options: { element: arrowElement } },
			{
				name: 'offset',
				options: {
					offset: offset || [0, 0]
				}
			}
		],
		placement: position || 'bottom-start'
	})

	useEffect(() => {
		setFilteredItems(items)
	}, [items])

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(e.target.value)
		filterList(e.target.value)
	}

	const resetChanges = () => {
		setSearchQuery('')
		setFilteredItems(items)
	}

	const handleClose = (close: () => void) => {
		return () => {
			resetChanges()
			close()
		}
	}

	const filterList = (e: string) => {
		const filteredOptions =
			searchQuery === '' || !filter ? items : items.filter((i) => filter(i, e))
		setFilteredItems(filteredOptions)
	}

	if (responsive)
		return (
			<>
				<div
					className=""
					onClick={() => {
						setOpen(true)
					}}
				>
					{selectedItem}
				</div>
				<GSlideOver
					position="right"
					open={open}
					setOpen={setOpen}
					className="flex md:hidden z-51 "
				>
					<div className="w-72 p-4 z-50 overflow-y-auto overflow-x-hidden">
						<div className="flex flex-col">
							<div className="flex-1 flex flex-col">
								{/* <Header /> */}
								<div className="flex-1 flex flex-col mb-2">
									{/* <Body /> */}
								</div>
								{/* {footer && <div className="flex border-t">
								{footer}
							</div>} */}
							</div>
						</div>
					</div>
				</GSlideOver>
			</>
		)

	return (
		<Popover className="w-full">
			{() => (
				<>
					<Popover.Button as="div" className={'w-full ml-0 top-6'}>
						<div ref={setReferenceElement} className="w-full">
							{selectedItem}
						</div>
					</Popover.Button>
					<Popover.Panel
						ref={setPopperElement}
						style={styles.popper}
						{...attributes.popper}
						className={`bg-white ${width} border  border-t-border-lighter rounded-lg shadow-lg absolute z-20 ml-0 !left-6 mt-2`}
					>
						{({ close }) => (
							<>
								<GTransition show={true}>
									<div className="flex flex-col">
										<div className="flex-1 flex flex-col">
											{!disableSearch && (
												<div className="bg-white-100 p-2 space-y-2">
													<GInput
														value={searchQuery}
														icon={SearchIcon}
														placeholder={placeholder}
														onChange={(e) => handleChange(e)}
													/>
												</div>
											)}
											{header && <div>{header}</div>}
											<div className="flex-1 flex flex-col rounded-md mr-1 my-4 max-h-56 overflow-auto">
												{filteredItems.length > 0 ? (
													<div className="mx-2">
														{filteredItems?.map(
															(item) =>
																renderOption &&
																renderOption(item, handleClose(close))
														)}
													</div>
												) : (
													<div className="flex justify-center items-center text-t-secondary">
														No items
													</div>
												)}
											</div>
											{footer && <div className="flex border-t">{footer}</div>}
										</div>
									</div>
								</GTransition>
								<div ref={setArrowElement} style={styles.arrow} />
							</>
						)}
					</Popover.Panel>
				</>
			)}
		</Popover>
	)
}
