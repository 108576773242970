import { GLoading, GTransition } from 'components/basic-blocks'
import { PageHeader } from 'components/page-header'
import { TabItem } from 'components/tabs/types'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { InfoTab } from './info-tab/info-tab'
import { NotesTab } from './notes-tab'

export const AdminAccountPage = () => {
	const { accountId } = useParams()
	const { adminStore } = useStores()
	const {
		setAccountById: setAccountById,
		account,
		clearAccount,
		getNotes
	} = adminStore
	const navigate = useNavigate()

	const [search] = useSearchParams('tab=info')

	const [total, setTotal] = useState(0)

	const fetchData = async (skip: number) => {
		if (!accountId) return
		const { total } = await getNotes(accountId, {
			type: 'website',
			skip: skip,
			limit: 6
		})
		setTotal(total)
	}

	useEffect(() => {
		;(async () => {
			await fetchData(0)
		})()
	}, [])

	const tabs: Array<TabItem> = [
		{
			key: 'info',
			label: 'Information',
			to: () => `/admin/subscription/${accountId}/?tab=info`
		},
		{
			key: 'notes',
			label: (
				<div>
					Notes{' '}
					<span
						className={`${
							search.get('tab') === 'notes'
								? 'bg-badge-selected text-badge-text-selected'
								: 'bg-badge-default text-badge-text-t-default'
						} text-sm font-medium mr-2 mx-2.5 px-1.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300`}
					>
						{total || 0}
					</span>
				</div>
			),
			to: () => `/admin/subscription/${accountId}/?tab=notes`
		}
	]

	const [selectedTab, setSelectedTab] = useState(
		search.get('tab') || tabs[0].key
	)

	const init = async () => {
		if (accountId) {
			try {
				await setAccountById(accountId)
			} catch (err) {
				console.log(err)
				navigate('/admin/subscriptions')
			}
		}
	}

	useEffect(() => {
		init()
		return () => {
			clearAccount()
		}
	}, [accountId])

	useEffect(() => {
		const tab = search.get('tab') || tabs[0].key
		setSelectedTab(tab)
	}, [search])

	if (!account) {
		return <GLoading />
	}

	return (
		<div className="">
			<PageHeader title={account.name} tabs={tabs} currentTab={selectedTab} />
			<div className="wi-full px-4 sm:px-6 md:px-8">
				<GTransition swap show={selectedTab === 'info'}>
					<InfoTab account={account} />
				</GTransition>
				<GTransition swap show={selectedTab === 'notes'}>
					<NotesTab topicId={account.id} total={total} setTotal={setTotal} />
				</GTransition>
			</div>
		</div>
	)
}
