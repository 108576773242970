import './sentry-config'
import { Auth0Provider } from '@auth0/auth0-react'
import { Auth0Config, PostHogConfig } from 'config'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { PostHogProvider as PostHog } from 'posthog-js/react'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import * as serviceWorker from './serviceWorker'
import { App } from './App'
import { parentNodeDebugger } from './utils/parent-node-debugger'


const options = {
	api_host: PostHogConfig.REACT_APP_PUBLIC_POSTHOG_HOST,
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false // TODO consider
		}
	}
})

const RootApp = () => {
	const navigate = useNavigate()
	parentNodeDebugger()

	useEffect(() => {
		const metaTag = document.querySelector("meta[name='viewport']")
		if (metaTag) {
			if (
				window.innerWidth < 1024 &&
				window.location.pathname.includes('admin')
			) {
				metaTag.setAttribute('content', 'width=device-width, initial-scale=0.1')
			} else {
				metaTag.setAttribute('content', 'width=device-width, initial-scale=1')
			}
		}
	}, [])

	return (
		<Auth0Provider
			domain={Auth0Config.domain}
			clientId={Auth0Config.client_id}
			redirectUri={window.location.origin}
			audience={Auth0Config.audience}
			scope={Auth0Config.scope}
			useRefreshTokens={true}
			onRedirectCallback={(state) => {
				navigate(state?.returnTo || '/')
			}}
		>
			<QueryClientProvider client={queryClient}>
				<PostHog
					options={options}
					apiKey={PostHogConfig.REACT_APP_PUBLIC_POSTHOG_KEY}

				>
					<App />
					<ToastContainer
						position="bottom-center"
						autoClose={5000}
						hideProgressBar={true}
						newestOnTop={false}
						theme={'colored'}
						className="text-sm"
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						limit={1}
					/>
					<ReactQueryDevtools initialIsOpen={false} />
				</PostHog>
			</QueryClientProvider>
		</Auth0Provider>
	)
}

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<RootApp />
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
