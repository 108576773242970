import moment from 'moment/moment'
import { useEffect, useMemo, useState } from 'react'
import { useUiStore } from 'store'
import { ReportRequestPeriodDTO } from '../../../../../../../api-models'
import { WebsiteAvatar } from '../../../../../components'
import {
	DateSelector,
	GButton,
	GDialog,
	GInput,
	GListBox,
	GMultiSelect
} from '../../../../../components/basic-blocks'
import { PlatformIcon } from '../../../../../components/platform-icon'
import { useStores } from '../../../../../hooks'
import { capitalizeFirstLetter } from '../../../../../utils/text'
import {
	automatedIntervalOptions,
	content,
	onDemandIntervalOptions
} from '../constants'
import {
	IRunReportsProps,
	IRunReportState,
	IScheduleReportsProps,
	IScheduleReportState,
	ReportMethod,
	ReportScopeType
} from '../types'

const defaultAdAccountOption = {
	id: 'ALL',
	name: 'Entire website'
}

const defaultCampaignOption = {
	id: 'ALL',
	name: 'Entire PPC account'
}

export const RunReportsDialog = ({ onClose, open, type }: IRunReportsProps) => {
	const {
		assetStore,
		accountStore: { account }
	} = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	const [error, setError] = useState({
		scope: ''
	})
	const { accountAdAccounts, getReports, runReport, asset, campaigns } =
		assetStore
	const [state, setState] = useState<IRunReportState>({
		label: '',
		scope: [],
		scope_type: {
			name: asset?.name || '',
			id: asset?.id || '',
			icon: asset?.avatar || '',
			type: ReportScopeType.DOMAIN
		},
		period: onDemandIntervalOptions[0]
	})

	const adAccount: Array<{
		id: string
		name: string
		icon: string
		type: ReportScopeType
	}> = useMemo(() => {
		if (asset) {
			const acc = accountAdAccounts.filter((acc) => acc.asset_id === asset.id)
			setState({ ...state, scope: [] })
			return acc.map((adAcc) => {
				return {
					id: adAcc?.id ?? '',
					name: adAcc?.name ?? '',
					icon: adAcc?.platform ?? '',
					type: ReportScopeType.PPC
				}
			})
		}
		return []
	}, [accountAdAccounts, asset])

	const filteredCampaigns: Array<{ id: string; name: string }> = useMemo(() => {
		if (
			adAccount &&
			campaigns &&
			state?.scope_type.type === ReportScopeType.PPC
		) {
			const cam = campaigns.filter(
				(c) => c.ad_account_id === state.scope_type.id
			)
			const data = cam.map((c) => {
				return {
					id: c.id,
					name: c.name
				}
			})
			return [defaultCampaignOption, ...data]
		}
		return [defaultCampaignOption]
	}, [accountAdAccounts, state.scope_type, asset])

	const scopeOptions = useMemo(() => {
		const scope_type = [...adAccount]
		if (asset) {
			scope_type.unshift({
				id: asset.id,
				name: asset?.name ?? '',
				icon: asset?.avatar ?? '',
				type: ReportScopeType.DOMAIN
			})

			setState({
				...state,
				scope_type: {
					id: asset?.id ?? '',
					name: asset?.name ?? '',
					icon: asset?.avatar ?? '',
					type: ReportScopeType.DOMAIN
				}
			})
		}
		return scope_type
	}, [asset, adAccount])

	const handleOptions = (e: Array<{ id: string; name: string }>) => {
		const defaultOption =
			state.scope_type?.type === ReportScopeType.DOMAIN
				? defaultAdAccountOption
				: defaultCampaignOption
		const isAll = e.find((e) => e.id === 'ALL')
		const isAllAlreadyExist = state.scope.find((e) => e.id == 'ALL')
		if (!isAll) {
			setState({
				...state,
				scope: e
			})
		} else if (e.length > 1 && isAllAlreadyExist) {
			setState({
				...state,
				scope: e.filter((e) => e.id !== 'ALL')
			})
		} else {
			setState({
				...state,
				scope: [defaultOption]
			})
		}
	}

	const handleSubmit = async () => {
		if (state.scope.length === 0) {
			setError({ scope: 'Select at least 1 option' })
			return
		}
		if (!state.scope_type) return
		const scope = state.scope.filter((e) => e.id !== 'ALL')
		const payload = {
			from: state.period.value.from.getTime(),
			to: state.period.value.to.getTime(),
			type,
			scope: scope.length === 0 ? null : scope.map((s) => s.id),
			scope_type: state.scope_type,
			label: state.label,
			trafficType: 'PAID',
			method: ReportMethod.ON_DEMAND
		}
		if (!asset || !account) return

		try {
			await runReport(account.id, asset.id, payload)
			await getReports(account.id, asset.id, null)
			onClose()
		} catch (e) {
			return onClose()
		}
	}

	useEffect(() => {
		setState({
			...state,
			label: '',
			scope: [],
			period: onDemandIntervalOptions[0]
		})
	}, [])

	return (
		<GDialog
			open={open}
			onClose={onClose}
			title={
				<p className="font-bold text-xl">{`Run ${content[type].title}`}</p>
			}
			subtitle={
				<p className="text-base pb-4">
					{`${capitalizeFirstLetter(content[type].title)} shows the ${content[
						type
					].subTitle.toLowerCase()}`}
				</p>
			}
			showFullSubTitle={true}
			className="sm:max-w-2xl"
			showClose={false}
			lineBreak={true}
		>
			<div className="flex flex-col gap-y-6 mt-6">
				<div className="flex justify-start items-center gap-8">
					<div className="w-full">
						<GListBox<{
							id: string
							name: string
							icon: string
							type: ReportScopeType
						} | null>
							label={<span className="font-bold text-base">Report scope</span>}
							name="interval"
							value={state.scope_type}
							onChange={(e) =>
								e && setState({ ...state, scope_type: e, scope: [] })
							}
							options={scopeOptions}
							renderLabel={(list) => (
								<RenderOptions
									name={list?.name ?? ''}
									icon={list?.icon ?? ''}
									showIcon={true}
								/>
							)}
						/>
					</div>
					<div className="w-full">
						<GMultiSelect<{ id: string; name: string; icon?: string }>
							className="mt-4"
							loopKey="value"
							label=""
							renderLabel={(list: {
								id: string
								name: string
								icon?: string
							}) => (
								<RenderOptions
									name={list.name}
									icon={list.icon}
									showIcon={state.scope_type?.type === ReportScopeType.DOMAIN}
								/>
							)}
							placeholder="Select all that apply"
							renderSelected={(s: Array<{ id: string; name: string }>) =>
								s.map((i) => i.name).join(', ')
							}
							value={state.scope}
							options={
								state.scope_type?.type === ReportScopeType.DOMAIN
									? [defaultAdAccountOption, ...adAccount]
									: filteredCampaigns
							}
							onChange={(e) => handleOptions(e)}
							error={error.scope}
						/>
					</div>
				</div>
				<div className="w-full">
					<GListBox<{ label?: string; value: { from: Date; to: Date } }>
						label={
							<span className="font-bold text-base">Reporting period</span>
						}
						name="interval"
						value={state.period}
						onChange={(e) => setState({ ...state, period: e })}
						options={onDemandIntervalOptions}
						renderLabel={(list) => <div className="w-42">{list?.label}</div>}
					/>
				</div>
				<div className="w-full">
					<GInput
						label="Custom label"
						name="Custom label"
						placeholder="Optional"
						value={state.label}
						onChange={(e) => setState({ ...state, label: e.target.value })}
					/>
				</div>
				<p className="text-base">
					Report will be scheduled immediately, and you will be notified when
					it’s ready to download.
				</p>
			</div>
			<div className="flex justify-between mt-6">
				<GButton
					className="h-[36px] w-[95px]"
					labelClassName="font-bold text-lg"
					label="Cancel"
					onClick={onClose}
				/>
				<GButton
					className="h-[36px]"
					labelClassName="font-bold text-lg"
					label="Run report"
					color="primary"
					onClick={handleSubmit}
					loading={isLoading('ASSET_RUN_REPORT')}
				/>
			</div>
		</GDialog>
	)
}

export const ScheduleReportsDialog = ({
	onClose,
	open,
	type,
	isConfigure = false,
	reportRequest
}: IScheduleReportsProps) => {
	const {
		assetStore,
		accountStore: { account }
	} = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	const {
		campaigns,
		accountAdAccounts,
		getReportRequests,
		runAutoReport,
		editReport,
		deleteReportRequest,
		asset
	} = assetStore
	const [state, setState] = useState<IScheduleReportState>({
		label: reportRequest?.label || '',
		scope_type: {
			name: asset?.name || '',
			id: asset?.id || '',
			icon: asset?.avatar || '',
			type: ReportScopeType.DOMAIN
		},
		scope: [],
		period: automatedIntervalOptions[0],
		scheduled_for: moment().add(7, 'days').toDate()
	})
	const [error, setError] = useState({
		scope: ''
	})
	const filterAdAccount: Array<{
		id: string
		name: string
		icon: string
		type: ReportScopeType
	}> = useMemo(() => {
		if (asset) {
			const acc = accountAdAccounts.filter((acc) => acc.asset_id === asset.id)
			setState({ ...state, scope: [] })
			return acc.map((adAcc) => {
				return {
					id: adAcc?.id ?? '',
					name: adAcc?.name ?? '',
					icon: adAcc?.platform ?? '',
					type: ReportScopeType.PPC
				}
			})
		}
		return []
	}, [accountAdAccounts, asset])

	const filteredCampaigns: Array<{ id: string; name: string }> = useMemo(() => {
		if (
			filterAdAccount &&
			campaigns &&
			state?.scope_type?.type === ReportScopeType.PPC
		) {
			const cam = campaigns.filter(
				(c) => c.ad_account_id === state.scope_type.id
			)
			const data = cam.map((c) => {
				return {
					id: c.id,
					name: c.name
				}
			})
			return [defaultCampaignOption, ...data]
		}
		return [defaultCampaignOption]
	}, [accountAdAccounts, state.scope_type])

	const scopeOptions = useMemo(() => {
		const scope_type = [...filterAdAccount]
		if (asset) {
			scope_type.unshift({
				id: asset.id,
				name: asset?.name ?? '',
				icon: asset?.avatar ?? '',
				type: ReportScopeType.DOMAIN
			})

			setState({
				...state,
				scope_type: {
					id: asset?.id ?? '',
					name: asset?.name ?? '',
					icon: asset?.avatar ?? '',
					type: ReportScopeType.DOMAIN
				}
			})
		}
		return scope_type
	}, [asset, filterAdAccount])

	const handleOptions = (e: Array<{ id: string; name: string }>) => {
		const defaultOption =
			state.scope_type.type === ReportScopeType.DOMAIN
				? defaultAdAccountOption
				: defaultCampaignOption
		const isAll = e.find((e) => e.id === 'ALL')
		const isAllAlreadyExist = state.scope.find((e) => e.id == 'ALL')
		if (!isAll) {
			setState({
				...state,
				scope: e
			})
		} else if (e.length > 1 && isAllAlreadyExist) {
			setState({
				...state,
				scope: e.filter((e) => e.id !== 'ALL')
			})
		} else {
			setState({
				...state,
				scope: [defaultOption]
			})
		}
	}

	const handleSubmit = async () => {
		if (state.scope.length === 0) {
			setError({ scope: 'Select at least 1 option' })
			return
		}
		const scope = state.scope.filter((e) => e.id !== 'ALL')
		const payload = {
			type,
			scope_type: state.scope_type,
			scope: scope.length !== 0 ? scope.map((adAcc) => adAcc.id) : null,
			label: state.label,
			scheduled_for: state.scheduled_for.getTime(),
			period: state.period.value
		}
		if (!account || !asset) return

		try {
			if (isConfigure && reportRequest?.id) {
				await editReport(account.id, asset.id, reportRequest.id, payload)
			} else {
				await runAutoReport(account.id, asset.id, payload)
			}
			await getReportRequests(account.id, asset.id, ReportMethod.AUTOMATED)
			onClose()
		} catch (e) {
			return onClose()
		}
	}

	const handleDelete = async () => {
		try {
			if (account && asset && reportRequest) {
				await deleteReportRequest(account.id, asset.id, reportRequest.id)
				await getReportRequests(account.id, asset.id, ReportMethod.AUTOMATED)
				onClose()
			}
		} catch (e) {
			return onClose()
		}
	}

	useEffect(() => {
		if (reportRequest && scopeOptions) {
			const scopeType = scopeOptions.find(
				(e) => e.id === reportRequest.scope_type.id
			) || {
				id: asset?.id || '',
				name: asset?.name ?? '',
				icon: asset?.avatar ?? '',
				type: ReportScopeType.DOMAIN
			}
			setState({
				label: reportRequest.label || '',
				scope_type: scopeType,
				scope: [],
				period:
					automatedIntervalOptions.find(
						(int) =>
							int.value.unit === reportRequest?.period?.unit &&
							int.value.value === reportRequest?.period?.value
					) || automatedIntervalOptions[0],
				scheduled_for: moment(reportRequest.scheduled_for).toDate()
			})
			setError({ scope: '' })
		}
	}, [reportRequest, scopeOptions])

	return (
		<GDialog
			open={open}
			onClose={onClose}
			title={
				<p className="font-bold text-xl">{`Schedule ${content[type].title}`}</p>
			}
			subtitle={
				<p className="text-base pb-4">
					{`${capitalizeFirstLetter(content[type].title)} shows the ${content[
						type
					].subTitle.toLowerCase()}`}
				</p>
			}
			showFullSubTitle={true}
			className="sm:max-w-2xl"
			showClose={false}
			lineBreak={true}
		>
			<div className="flex flex-col gap-y-6 mt-6 ">
				<div className="flex justify-start items-center gap-8">
					<div className="w-full max-w-[295px]">
						<GListBox<{
							id: string
							name: string
							icon: string
							type: ReportScopeType
						} | null>
							label={<span className="font-bold text-base">Report scope</span>}
							name="interval"
							value={state.scope_type}
							onChange={(e) =>
								e && setState({ ...state, scope_type: e, scope: [] })
							}
							options={scopeOptions}
							renderLabel={(list) => (
								<RenderOptions
									name={list?.name ?? ''}
									icon={list?.icon ?? ''}
									showIcon={true}
								/>
							)}
						/>
					</div>
					<div className="w-full">
						<GMultiSelect<{ id: string; name: string; icon?: string }>
							className="mt-4"
							loopKey="value"
							label=""
							renderLabel={(list: {
								id: string
								name: string
								icon?: string
							}) => (
								<RenderOptions
									name={list.name}
									icon={list.icon}
									showIcon={state.scope_type?.type === ReportScopeType.DOMAIN}
								/>
							)}
							placeholder="Select all that apply"
							renderSelected={(s: Array<{ id: string; name: string }>) =>
								s.map((i) => i.name).join(', ')
							}
							value={state.scope}
							options={
								state.scope_type.type === ReportScopeType.DOMAIN
									? [defaultAdAccountOption, ...filterAdAccount]
									: filteredCampaigns
							}
							onChange={(e) => handleOptions(e)}
							error={error.scope}
						/>
					</div>
				</div>
				<div className="flex justify-start items-center gap-8">
					<div className="w-full">
						<GListBox<{ label?: string; value: ReportRequestPeriodDTO }>
							label={
								<span className="font-bold text-base">Reporting interval</span>
							}
							name="interval"
							value={state.period}
							onChange={(e) => setState({ ...state, period: e })}
							options={automatedIntervalOptions}
							renderLabel={(option) => (
								<div className="w-42">{option.label}</div>
							)}
						/>
					</div>
					<div className="w-full">
						<DateSelector
							label={<span className="font-bold text-base">Starting date</span>}
							onChange={(e) => setState({ ...state, scheduled_for: e })}
							minDate={moment().add(7, 'days').toDate()}
							value={state.scheduled_for}
							className="w-full justify-between text-t-default"
						/>
					</div>
				</div>
				<div className="w-full">
					<GInput
						label="Custom label"
						name="Custom label"
						placeholder="Optional"
						value={state.label}
						onChange={(e) => setState({ ...state, label: e.target.value })}
					/>
				</div>
				<p className="text-base">
					Report will start running on{' '}
					{moment(
						state.scheduled_for || moment().add(7, 'days').toDate()
					).format('MMMM Do YYYY')}
					, and will automatically re-run every&nbsp;
					{(state.period.label ?? '').toLowerCase().replace(/every|ly/g, '')}.
				</p>
			</div>
			<div className="flex justify-between mt-6">
				<div className="flex gap-2">
					<GButton
						className="h-[36px] w-[95px]"
						labelClassName="font-bold text-lg text-t-default"
						label="Cancel"
						onClick={onClose}
					/>
					{isConfigure && (
						<GButton
							label={'Delete'}
							color="danger"
							variant="text"
							className="h-[36px]"
							labelClassName="font-bold text-lg"
							loading={
								isLoading('ASSET_RUN_REPORT') ||
								isLoading('ASSET_DELETE_REPORT') ||
								isLoading('ASSET_SET_REPORT')
							}
							size="md"
							onClick={handleDelete}
						/>
					)}
				</div>

				<GButton
					className="h-[36px]"
					labelClassName="font-bold text-lg"
					label="Schedule"
					color="primary"
					onClick={handleSubmit}
					loading={
						isLoading('ASSET_RUN_REPORT') ||
						isLoading('ASSET_DELETE_REPORT') ||
						isLoading('ASSET_SET_REPORT')
					}
				/>
			</div>
		</GDialog>
	)
}

const RenderOptions = ({
	icon,
	name,
	showIcon
}: {
	name: string
	icon?: string
	showIcon: boolean
}) => {
	return (
		<div className="flex items-center gap-2">
			{showIcon && !name.toLowerCase().includes('entire') && (
				<RenderIcon icon={icon} name={name} />
			)}
			<p className="truncate  max-w-[230px]">{name ?? ''}</p>
		</div>
	)
}

const RenderIcon = ({ icon, name }: { icon?: string; name: string }) => {
	if (icon === 'GADS') {
		return <PlatformIcon platform="GADS" className="w-4 h-4" />
	}
	if (icon === 'FADS') {
		return <PlatformIcon platform="FADS" className="w-4 h-4" />
	}
	if (icon === 'BADS') {
		return <PlatformIcon platform="BADS" className="w-4 h-4" />
	}
	return (
		<WebsiteAvatar website={{ name: name }} src={icon} name={name} size={4} />
	)
}
