import { ReferenceAdsAccountDTO } from 'api-models'
import { GButton, GInput, GSection, GTable } from 'components/basic-blocks'
import { PageHeader } from 'components/page-header'
import { TabItem } from 'components/tabs/types'
import { useStores } from 'hooks'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { RiRefreshLine } from 'react-icons/ri'
import { useSearchParams } from 'react-router-dom'
import { useUiStore } from 'store'
import { PageTitle } from 'utils/page-title'
import { columns } from './google-tab/columns'
import { PPCAccountsReference } from './google-tab/types'
import { State } from './types'

export const AdminPPCAccountsReferencesPage = () => {
	const tabs: Array<TabItem> = [
		{ key: 'gads', label: 'Google', to: () => '/admin/ad-accounts?tab=gads' }
	]

	const { adminStore } = useStores()
	const { getGadsReferences } = adminStore
	const isLoading = useUiStore((s) => s.isLoading)

	const [search] = useSearchParams('tab=gads')
	const [selectedTab, setSelectedTab] = useState(
		search.get('tab') || tabs[0].key
	)

	const [state, setState] = useState<State>({
		query: '',
		selectedTab: search.get('tab') || tabs[0].key,
		skip: 0,
		pageSize: 10
	})

	useEffect(() => {
		setSelectedTab(search.get('tab') || tabs[0].key)
	}, [search])

	const currentQuery = state.query
		? { search: state.query, skip: state.skip }
		: { skip: state.skip, limit: state.pageSize }

	const pageListener = (page: number) => {
		setState({ ...state, skip: page })
	}

	const [gadsReferences, setGadsReferences] = useState<PPCAccountsReference>({
		total: 0,
		data: []
	})

	const getAndSetGadsReferences = async () => {
		const gadsReferences = await getGadsReferences({ ...currentQuery })
		setGadsReferences(gadsReferences || { total: 0, data: [] })
	}

	const debounceQuery = useCallback(
		debounce(async (query) => {
			const domainsReferences = await getGadsReferences({
				search: query,
				skip: state.skip,
				limit: state.pageSize
			})
			setGadsReferences(domainsReferences || { total: 0, data: [] })
		}, 500),
		[getGadsReferences]
	)

	useEffect(() => {
		;(async () => {
			try {
				await getAndSetGadsReferences()
			} catch (e) {
				return
			}
		})()
	}, [state.skip])

	return (
		<>
			<PageTitle pageName="PPC accounts" />
			<PageHeader
				title="PPC account references"
				actionPlacement="top"
				tabs={tabs}
				currentTab={selectedTab}
				action={
					<div className="w-full flex pr-3.5 space-x-4 items-center justify-between">
						<GButton
							icon={RiRefreshLine}
							size="sm"
							onClick={() => getGadsReferences(currentQuery)}
						>
							Refresh
						</GButton>
						<GInput
							placeholder="Find PPC account..."
							onChange={(e) => {
								setState({ ...state, query: e.target.value })
								debounceQuery(e.target.value)
							}}
						/>
					</div>
				}
			/>
			<div className="w-full pt-6 px-4 sm:px-6">
				<GSection loading={isLoading('ADMIN_GET_REFERENCES')}>
					<GTable<ReferenceAdsAccountDTO>
						data={gadsReferences.data}
						columns={columns(getAndSetGadsReferences)}
						pageListener={(e: number) => pageListener(e)}
						pageSize={state.pageSize}
						totalCount={gadsReferences.total}
					/>
				</GSection>
			</div>
		</>
	)
}
