import { GDateTimeProps } from './types'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { twMerge } from 'tailwind-merge'

export const GDatePicker = ({
	date,
	onChange,
	error,
	className,
	placeholder
}: GDateTimeProps) => {
	return (
		<div className="flex flex-col justify-center">
			<DatePicker
				selected={date}
				className={twMerge(
					'bg-transparent disabled:bg-gray-100 focus:ring-primary-500 focus:border-primary-500 block border-t-input-border text-t-default rounded-md font-medium placeholder:text-t-secondary w-full',
					className
				)}
				onChange={onChange}
				placeholderText={placeholder}
			/>
			{error && <p className={twMerge('mt-1 text-xs text-red-700')}>{error}</p>}
		</div>
	)
}
