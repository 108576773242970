import axios from 'axios'
import {
	GButton,
	GDialog,
	GInput,
	GLoading,
	GTransition
} from 'components/basic-blocks'
import { ApiConfig } from 'config'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ZendeskService } from 'services/zendesk'
import DarkLogo from '../../../assets/img/cg-logo-white.svg'
import { CFCLead } from '../types'
import { CFCReportView } from './view'

const client = axios.create({
	baseURL: ApiConfig.API_CFC_URL
})

export const ClickFraudReport = () => {
	const { id } = useParams()
	const navigate = useNavigate()

	useEffect(() => {
		ZendeskService.hide()
	}, [])

	useEffect(() => {
		if (id) load(id)
	}, [id])

	const [lead, setLead] = useState<CFCLead>()
	const [emailOpen, setEmailOpen] = useState(false)
	const [emailForm, setEmailForm] = useState<any>({
		sender: '',
		recipient: '',
		recipientEmail: '',
		errors: []
	})
	const [sendEmailLoading, setSendEmailLoading] = useState(false)

	const load = async (id: string) => {
		try {
			const response = await client.get(`/cfc/${id}`)
			setLead(response.data.data)
		} catch (err: any) {
			console.trace(err)
			toast.error(
				'Error loading the report. Make sure you have the correct link.'
			)
		}
	}

	const trackEvent = async (event: string) => {
		try {
			await client.post(`/cfc/${id}/event`, { event: event })
		} catch (err: any) {
			console.trace(err)
		}
	}

	const onStartTrial = async () => {
		trackEvent('cta-clicked')
		navigate(`/click-fraud-report/${id}/start-trial`)
	}

	const onDownload = async () => {
		trackEvent('download-clicked')
		window.open(lead!.pdf_url, '_blank')
	}

	const handleSendAsEmail = async () => {
		const errors = []
		if (!emailForm.sender) errors.push('sender')
		if (!emailForm.recipient) errors.push('recipient')
		if (!emailForm.recipientEmail) errors.push('recipientEmail')
		if (errors.length > 0) {
			setEmailForm({ ...emailForm, errors })
			return
		}

		setSendEmailLoading(true)

		trackEvent('email-clicked')

		try {
			await client.post(`/cfc/${id}/send`, {
				sender: emailForm.sender,
				delivery_name: emailForm.recipient,
				delivery_email: emailForm.recipientEmail
			})
			toast.success('Email sent!')
			setEmailOpen(false)
		} catch (err: any) {
			console.trace(err)
			toast.error('Error sending an email, please try again.')
		} finally {
			setSendEmailLoading(false)
		}
	}

	return (
		<div>
			<div className="screen:fixed print:absolute top-0 left-0 flex-shrink-0 flex justify-between items-center px-8 h-[72px] bg-[#1C1F2B] z-20 w-full screen:border-b border-b-[#282D3E]">
				<div className="flex gap-4 items-center">
					<img alt="logo" className="h-[28px]" src={DarkLogo} />
					<span className="text-[#F5F5F5] text-xl">
						Click Fraud Assessment Report
					</span>
				</div>

				<div className="flex justify-end items-center gap-4">
					<button
						className="print:hidden h-[30px] font-bold text-[#F5F5F5] bg-[#1C1F2B] py-2 px-4 flex items-center justify-center rounded-md border border-[#32384D] focus:outline-none"
						onClick={() => setEmailOpen(true)}
					>
						Send as email
					</button>

					{lead?.pdf_url ? (
						<button
							className="print:hidden h-[30px] font-bold text-[#F5F5F5] bg-[#1C1F2B] py-2 px-4 flex items-center justify-center rounded-md border border-[#32384D] focus:outline-none"
							onClick={onDownload}
						>
							Download PDF
						</button>
					) : null}
				</div>

				<GDialog
					title="Send as email"
					open={emailOpen}
					onClose={() => setEmailOpen(false)}
				>
					<div className="flex flex-col space-y-4 pt-4">
						<GInput
							className=""
							labelClassName="text-t-default mb-1 text-md font-bold"
							label="Your name"
							inputClassName="sm:text-sm placeholder:text-sm placeholder:text-t-default text-t-default w-full"
							placeholder="John Doe"
							value={emailForm.sender}
							disabled={false}
							error={
								emailForm.errors.includes('sender')
									? 'You need to input your name'
									: undefined
							}
							onChange={(e) =>
								setEmailForm({
									...emailForm,
									sender: e.target.value,
									errors: emailForm.errors.filter((e: string) => e !== 'sender')
								})
							}
						/>
						<GInput
							className=""
							labelClassName="text-t-default mb-1 text-md font-bold"
							label="Recipient name"
							inputClassName="sm:text-sm placeholder:text-sm placeholder:text-t-default text-t-default w-full"
							placeholder="Jane Doe"
							value={emailForm.recipient}
							disabled={false}
							error={
								emailForm.errors.includes('recipient')
									? 'You need to input a recipient name'
									: undefined
							}
							onChange={(e) =>
								setEmailForm({
									...emailForm,
									recipient: e.target.value,
									errors: emailForm.errors.filter(
										(e: string) => e !== 'recipient'
									)
								})
							}
						/>
						<GInput
							className=""
							labelClassName="text-t-default mb-1 text-md font-bold"
							label="Recipient email"
							inputClassName="sm:text-sm placeholder:text-sm placeholder:text-t-default text-t-default w-full"
							placeholder={'name@gmail.com'}
							value={emailForm.recipientEmail}
							disabled={false}
							error={
								emailForm.errors.includes('recipientEmail')
									? 'You need to input a valid email'
									: undefined
							}
							onChange={(e) =>
								setEmailForm({
									...emailForm,
									recipientEmail: e.target.value,
									errors: emailForm.errors.filter(
										(e: string) => e !== 'recipientEmail'
									)
								})
							}
						/>
					</div>
					<div className="py-6 text-t-secondary">
						Recipient will recieve a copy of this report in their inbox.
					</div>
					<GButton
						color="primary"
						iconPlacement="right"
						className="w-full"
						onClick={handleSendAsEmail}
						disabled={sendEmailLoading}
						loading={sendEmailLoading}
					>
						<span className="px-4">Send</span>
					</GButton>
				</GDialog>
			</div>
			<div className="w-full h-full mt-[72px] print:mt-[48px]">
				<GTransition show={!!lead}>
					<CFCReportView
						lead={lead!}
						startTrial={onStartTrial}
						download={onDownload}
					/>
				</GTransition>

				{!lead && (
					<div className="w-full h-full px-8 py-8">
						<GLoading />
					</div>
				)}
			</div>
		</div>
	)
}
