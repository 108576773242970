import {
	GButton,
	GComboBox,
	GInput,
	GSection
} from '../../../components/basic-blocks'
import { RxCross2 } from 'react-icons/rx'
import { GCheckbox } from '../../../components/basic-blocks/g-checkbox'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import timezones from 'timezones-list'
import { ChangeEvent, Dispatch, useState } from 'react'
import { LegacyUserService } from '../../../services/admin/legacy-user'
import { getLegacyFilterErrors, LegacyUserStatusOptions } from './types'
import moment from 'moment/moment'
import { GDatePicker } from '../../../components/basic-blocks/g-date-picker/g-date-picker'
import { capitalizeFirstLetter } from '../../../utils/text'

interface payloadDTO {
	[key: string]: string
	status: string
	timezone: string
	amount_lt: string
	amount_gt: string
	period_lt: string
	period_gt: string
	created_lt: string
	created_gt: string
}

const tzList = timezones.map((item) => item.tzCode)

export const BulkDownload = ({
	setShow,
	show
}: {
	show: boolean
	setShow: Dispatch<boolean>
}) => {
	const [loading, setLoading] = useState(false)
	const [filters, setFilters] = useState({
		status: true,
		timezone: false,
		amount: false,
		period: false,
		created: false
	})

	const [payload, setPayload] = useState<payloadDTO>({
		status: '',
		timezone: '',
		amount_lt: '',
		amount_gt: '',
		period_lt: '',
		period_gt: '',
		created_lt: '',
		created_gt: ''
	})

	const [errors, setErrors] = useState({
		status: '',
		timezone: '',
		amount_lt: '',
		amount_gt: '',
		period_lt: '',
		period_gt: '',
		created_lt: '',
		created_gt: ''
	})

	const handlePayload = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target
		setPayload((prev) => ({ ...prev, [name]: value }))
	}

	const handleFilter = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = e.target
		setFilters((prev) => ({ ...prev, [name]: checked }))
		const payloadCopy = payload
		Object.keys(payloadCopy).forEach((key) => {
			if (key.startsWith(name)) {
				payloadCopy[key] = ''
			}
		})
		setPayload(payloadCopy)
	}
	const handleDownload = async () => {
		try {
			const { errors, isValid } = getLegacyFilterErrors(payload, filters)
			setErrors(errors)
			if (isValid) {
				setLoading(true)
				await LegacyUserService.exportUser(payload)
				setLoading(false)
			}
		} catch (e) {
			console.log(e)
			setLoading(false)
		}
	}

	if (!show)
		return (
			<GButton
				size="sm"
				label="Or Bulk Download"
				color="neutral"
				iconPlacement="right"
				onClick={() => setShow(true)}
				labelClassName="font-bold text-t-default px-1"
				icon={AiOutlineCloudDownload}
			/>
		)
	return (
		<GSection
			title="Bulk Download"
			subtitle="Filter based on the criteria below and download in csv "
			actions={
				<div onClick={() => setShow(false)} className="mt-4 cursor-pointer">
					<RxCross2 className="w-4 h-4" />
				</div>
			}
			lineBreak={true}
		>
			<div className="flex justify-center gap-3 flex-col pt-6 border-b pb-2">
				<GCheckbox
					name="status"
					checked={filters.status}
					onChange={handleFilter}
					label="Current Subscription Status"
					labelClassName="font-bold"
				/>
				{filters.status && (
					<GComboBox
						className="text-t-default ml-6"
						options={LegacyUserStatusOptions}
						value={payload.status}
						onChange={(e) => {
							setPayload((prev) => ({ ...prev, status: e }))
						}}
						filter={(item, query) =>
							!!item?.toLowerCase().includes(query.toLocaleLowerCase())
						}
						error={errors.status}
						renderOption={(i) =>
							capitalizeFirstLetter(i).replace('_', ' ') || 'Select'
						}
						renderValue={(i) =>
							capitalizeFirstLetter(i).replace('_', ' ') || 'Select'
						}
					/>
				)}
				<GCheckbox
					name="timezone"
					checked={filters.timezone}
					onChange={handleFilter}
					label="Timezone"
					labelClassName="font-bold"
				/>
				{filters.timezone && (
					<GComboBox
						className="text-t-default ml-6"
						options={tzList}
						value={payload.timezone}
						onChange={(e) => {
							setPayload((prev) => ({ ...prev, timezone: e }))
						}}
						filter={(item, query) =>
							!!item?.toLowerCase().includes(query.toLocaleLowerCase())
						}
						error={errors.timezone}
						renderOption={(i) => i || 'Select'}
						renderValue={(i) => i || 'Select'}
					/>
				)}
				<GCheckbox
					name="amount"
					checked={filters.amount}
					onChange={handleFilter}
					label="Billing Amount (USD)"
					labelClassName="font-bold"
				/>
				{filters.amount && (
					<div className="flex items-center gap-6 ml-6">
						<GInput
							name="amount_gt"
							inputClassName="text-t-default w-full font-medium"
							value={payload.amount_gt}
							placeholder="From"
							error={errors.amount_gt}
							onChange={handlePayload}
						/>
						<GInput
							name="amount_lt"
							inputClassName="text-t-default w-full font-medium"
							value={payload.amount_lt}
							placeholder="To"
							error={errors.amount_lt}
							onChange={handlePayload}
						/>
					</div>
				)}
				<GCheckbox
					name="period"
					checked={filters.period}
					onChange={handleFilter}
					label="Total Subscription Period (Months)"
					labelClassName="font-bold"
				/>
				{filters.period && (
					<div className="flex items-center gap-6 ml-6">
						<GInput
							name="period_gt"
							inputClassName="text-t-default w-full font-medium"
							value={payload.period_gt}
							placeholder="From"
							error={errors.period_gt}
							onChange={handlePayload}
						/>
						<GInput
							name="period_lt"
							inputClassName="text-t-default w-full font-medium"
							value={payload.period_lt}
							placeholder="To"
							error={errors.period_lt}
							onChange={handlePayload}
						/>
					</div>
				)}
				<GCheckbox
					name="created"
					checked={filters.created}
					onChange={handleFilter}
					label="Account creation date"
					labelClassName="font-bold"
				/>
				{filters.created && (
					<div className="flex items-center gap-6 ml-6 pb-6">
						<GDatePicker
							date={
								payload.created_gt ? moment(payload.created_gt).toDate() : null
							}
							onChange={(e) =>
								setPayload((prev) => ({
									...prev,
									created_gt: e?.toString() || ''
								}))
							}
							placeholder="From"
							error={errors.created_gt}
						/>
						<GDatePicker
							date={
								payload.created_lt ? moment(payload.created_lt).toDate() : null
							}
							onChange={(e) =>
								setPayload((prev) => ({
									...prev,
									created_lt: e?.toString() || ''
								}))
							}
							error={errors.created_lt}
							placeholder="To"
						/>
					</div>
				)}
			</div>
			<div className="py-4 flex justify-end">
				<GButton
					size="sm"
					label="Download"
					onClick={handleDownload}
					color="neutral"
					loading={loading}
					iconPlacement="right"
					labelClassName="font-bold text-t-default px-1"
					icon={AiOutlineCloudDownload}
				/>
			</div>
		</GSection>
	)
}
