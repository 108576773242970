import { RiArrowRightSLine } from 'react-icons/ri'
import { PlatformIcon } from 'components/platform-icon'

export const DemoBreadcrumbs = () => {
	const getAvatar = () => {
		return 'https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://demo-site&size=32'
	}

	return (
		<div className="flex items-center px-4">
			<div className={'py-2 px-1 cursor-pointer'}>
				<div className="flex items-center">
					<img
						className={`inline-block h-${5} w-${5} rounded-full`}
						src={getAvatar() || 'https://eu.ui-avatars.com/api/?name=Demo site'}
						alt="website icon"
						onError={(e) =>
							(e.currentTarget.src =
								'https://eu.ui-avatars.com/api/?name=Demo site')
						}
					/>

					<p
						className={
							'mx-2 text-t-default max-w-[100px] truncate border-b-primary-300 border-b-2'
						}
					>
						{'Demo site'}
					</p>
				</div>
			</div>
			<>
				<RiArrowRightSLine color="#69707D" className="ml-2 mr-3" size={16} />
				<div className={'flex items-center cursor-pointer relative py-2 px-1'}>
					<div className="flex items-center">
						<PlatformIcon platform={'GADS'} className="w-4 h-4" />
						<span
							className={'text-t-default mx-2 truncate max-w-[100px] block'}
						>
							{'Demo PPC account GADS'}
						</span>
					</div>
				</div>
			</>
		</div>
	)
}
