"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BingAdsInfoStatus = void 0;
var BingAdsInfoStatus;
(function (BingAdsInfoStatus) {
    BingAdsInfoStatus["PENDING"] = "PENDING";
    BingAdsInfoStatus["INITIALIZING"] = "INITIALIZING";
    BingAdsInfoStatus["SYNCHRONIZING"] = "SYNCHRONIZING";
    BingAdsInfoStatus["NO_ACCESS"] = "NO_ACCESS";
    BingAdsInfoStatus["OK"] = "OK";
    BingAdsInfoStatus["FAILED_SYNC_STATS"] = "FAILED_SYNC_STATS";
})(BingAdsInfoStatus = exports.BingAdsInfoStatus || (exports.BingAdsInfoStatus = {}));
