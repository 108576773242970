import { Notices } from 'components/notice/notices'
import { PageHeader } from 'components/page-header'
import { useStores } from 'hooks'
import { ChangeEvent, useEffect, useState } from 'react'
import { RiDeleteBin2Line, RiRefreshLine } from 'react-icons/ri'
import { toast } from 'react-toastify'
import { useAssetStore, useUiStore } from 'store'
import timezones from 'timezones-list'
import { EmailAddresInfoDTO } from '../../../../../api-models'
import { UserAvatar } from '../../../components'
import {
	GButton,
	GComboBox,
	GConfirm,
	GInput,
	GLoading,
	GSection,
	GTooltip
} from '../../../components/basic-blocks'
import { parsePhoneNumber } from 'awesome-phonenumber'
import { GBadge } from '../../../components/basic-blocks/g-badge'
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
import { useThemeStore } from '../../../layout/navbar/store'
import { CountriesList } from '../../../config/countries'
import { useShallow } from 'zustand/react/shallow'

export const ProfileSetting = () => {
	const { userStore, assetStore, accountStore } = useStores()
	const { user, updateProfile, updatePhoto, addNewEmail } = userStore
	const isLoading = useUiStore((s) => s.isLoading)
	const { theme } = useThemeStore()

	if (!user) {
		return <GLoading />
	}
	const [userInfoState, setUserInfoState] = useState({
		fullName: user.name || '',
		phone: user?.phone_number?.replace(/\+/g, '') || '',
		timeZone: user.timezone || '',
		country: user.country || ''
	})
	const [userInfoErrors, SetUserInfoErrors] = useState({
		fullName: '',
		timeZone: '',
		phone: '',
		country: ''
	})

	const tzList = timezones.map((item) => item.tzCode)
	const countriesList = Object.values(Object.values(CountriesList)).map(
		(item) => item.name
	)
	const [file, setFile] = useState<File | null>(null)
	const [filePreview, setFilePreview] = useState<string | undefined>(undefined)

	const handleChange = (value: any, name: string) => {
		setUserInfoState({ ...userInfoState, [name]: value })
	}
	const handlePhoneChange = (data: any, value: string, name: string) => {
		if (value.startsWith(`${data.dialCode}`)) {
			setUserInfoState({ ...userInfoState, [name]: value })
		} else {
			setUserInfoState({ ...userInfoState, [name]: `${data.dialCode}` })
		}
	}

	useEffect(() => {
		console.log(userInfoState)
	}, [userInfoState])

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const selectedFile = e.target.files[0]
			if (selectedFile) {
				const selectedFilePreview = URL.createObjectURL(e.target.files[0])
				setFile(selectedFile)
				setFilePreview(selectedFilePreview)
			}
		}
	}

	const handleSave = async () => {
		if (!handleValidation()) return

		await updateProfile({
			name: userInfoState.fullName,
			phone_number: `+${userInfoState.phone}`,
			timezone: userInfoState.timeZone,
			country: userInfoState.country
		})
		if (file) {
			const formData = new FormData()
			formData.append('avatar', file, file.name)
			await updatePhoto(formData)
		}
	}

	const handleValidation = () => {
		const tempError = { fullName: '', phone: '', timeZone: '', country: '' }
		if (userInfoState.fullName.trim().length <= 1) {
			tempError.fullName = 'Please enter a valid name'
		}
		if (userInfoState.phone.trim().length <= 1) {
			tempError.phone = 'Please enter a valid phone number'
		} else {
			console.log(`+${userInfoState.phone}`)
			const phoneNumber = parsePhoneNumber(`+${userInfoState.phone}`)
			if (!phoneNumber.valid) {
				tempError.phone = 'Please enter a valid phone number'
			}
		}
		if (userInfoState.timeZone.trim().length <= 1) {
			tempError.timeZone = 'Please select a valid time zone'
		}
		if (userInfoState.country.trim().length <= 1) {
			tempError.country = 'Please select a valid Country'
		}

		SetUserInfoErrors({ ...tempError })
		return Object.values(tempError).every((x) => x == '')
	}

	const [newEmail, setNewEmail] = useState(false)
	const [emailValue, setEmailValue] = useState<string>('')

	const handleEmailInput = (e: any) => {
		setEmailValue(e.target.value)
	}

	const handleNewEmail = () => {
		setNewEmail(true)
	}

	const handleClose = () => {
		setNewEmail(false)
	}

	const handleAddnewEmail = async () => {
		const emailExists = user.email_addresses.find(
			(email) => email.email === emailValue.trim()
		)
		if (emailExists) {
			toast.error(
				`This email has already been ${
					emailExists.email_verified ? 'verified' : ' invited'
				}`
			)
			return
		}
		try {
			await addNewEmail({ email: emailValue })
			setEmailValue('')
		} catch (error) {
			return
		}
	}

	const { account, isInSetup } = accountStore
	const { asset, adAccounts } = useAssetStore(
		useShallow((s) => ({
			asset: s.asset,
			adAccounts: s.adAccounts
		}))
	)

	return (
		<div className="w-full">
			<Notices
				isInSetup={isInSetup()}
				setupStep={account?.setup?.step}
				isInActive={account?.status === 'INACTIVE'}
				hasDashboardPerformanceFeature={accountStore.hasFeature(
					'dashboard_performance'
				)}
				tc_installed={asset ? !!asset?.tc_installed : true}
				cc_installed={asset ? !!asset?.cc_installed : true}
				accountId={account?.id}
				assetId={asset?.id}
				demoMode={false}
				hideDemoDataNotice={true}
				mccLinkInviteAdAccounts={adAccounts}
			/>
			<PageHeader
				title={'Profile'}
				subtitle={
					'Manage your personal profile information and email addresses'
				}
			/>
			<div className="max-w-3xl px-4 sm:px-6 md:px-8">
				<GSection
					title="Profile Information"
					lineBreak={true}
					lineBreakColor="gray-100"
					loading={isLoading('USER_SAVE_PROFILE')}
					actions={
						<div className="space-x-4">
							<GButton
								size="sm"
								label="Save"
								color="primary"
								variant="contained"
								labelClassName="font-bold text-base"
								className="h-[30px] w-[68px]"
								onClick={() => handleSave()}
								loading={isLoading('USER_SAVE_PROFILE')}
							/>
						</div>
					}
				>
					<dl className="-mx-4 sm:-mx-5 lg:-mx-6">
						<div className="pt-6 pb-3  px-4 sm:px-5 lg:px-6 sm:grid sm:grid-cols-3 sm:gap-4 items-center">
							<dt className="text-t-secondary text-base font-bold">
								Full name
							</dt>
							<dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
								<GInput
									name="fullName"
									inputClassName="text-t-default w-full font-medium"
									error={userInfoErrors.fullName}
									value={userInfoState.fullName}
									onChange={(e) => handleChange(e.target.value, 'fullName')}
								/>
							</dd>
						</div>
						<div className="py-3 sm:py-3 px-4 sm:px-5 lg:px-6 sm:grid sm:grid-cols-3 sm:gap-4 items-center">
							<dt className="text-t-secondary text-base font-bold">
								Profile picture
							</dt>
							<dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
								<UserAvatar user={user} src={filePreview} size={10} />
								<div className="flex items-center space-x-4">
									<GInput
										className="hidden"
										id="photo"
										name="photo"
										type="file"
										onChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleFileChange(e)
										}
									/>
									<label className="cursor-pointer" htmlFor="photo">
										<GButton
											variant="contained"
											size="sm"
											label="Change"
											labelClassName="font-bold"
											className="my-2 pointer-events-none text-t-default cursor-pointer h-[27px]"
										>
											Change
										</GButton>
									</label>
								</div>
							</dd>
						</div>
						<div className="py-3 sm:py-3 px-4 sm:px-5 lg:px-6 sm:grid sm:grid-cols-3 sm:gap-4 items-center">
							<dt className="text-t-secondary text-base font-bold">
								Phone number
							</dt>

							<dd className="mt-1 text-md text-t-default sm:mt-0 sm:col-span-2">
								<PhoneInput
									defaultErrorMessage="Invalid phone number"
									countryCodeEditable={false}
									inputStyle={{
										width: '100%',
										borderColor: theme === 'dark' ? '#282d3e' : '#e3e7fc',
										background: 'transparent'
									}}
									buttonStyle={{
										borderColor: theme === 'dark' ? '#282d3e' : '#e3e7fc',
										background: 'transparent'
									}}
									dropdownStyle={{
										borderColor: theme === 'dark' ? '#282d3e' : '#e3e7fc',
										background: theme === 'dark' ? '#242838' : '#FFFFFF'
									}}
									value={userInfoState.phone}
									onChange={(e, data) => handlePhoneChange(data, e, 'phone')}
								/>
								<p className="text-xs text-red-800">{userInfoErrors.phone}</p>
							</dd>
						</div>
						<div className="py-3 sm:py-3 px-4 sm:px-5 lg:px-6 sm:grid sm:grid-cols-3 sm:gap-4 items-center">
							<dt className="text-t-secondary text-base font-bold">Country</dt>
							<dd className="mt-1 text-md text-t-default sm:mt-0 sm:col-span-2">
								<GComboBox
									options={countriesList}
									inputClass="text-base font-medium"
									value={userInfoState.country}
									onChange={(e) => handleChange(e, 'country')}
									filter={(item, query) =>
										item.toLowerCase().includes(query.toLocaleLowerCase())
									}
									renderOption={(i) => i}
									renderValue={(i) => i}
									error={userInfoErrors.country}
								/>
							</dd>
						</div>
						<div className="py-3 sm:py-3 px-4 sm:px-5 lg:px-6 sm:grid sm:grid-cols-3 sm:gap-4 items-center">
							<dt className="text-t-secondary text-base font-bold">
								Time zone
							</dt>
							<dd className="mt-1 text-md text-t-default sm:mt-0 sm:col-span-2">
								<GComboBox
									options={tzList}
									value={userInfoState.timeZone}
									inputClass="text-base font-medium"
									onChange={(e) => handleChange(e, 'timeZone')}
									filter={(item, query) =>
										item.toLowerCase().includes(query.toLocaleLowerCase())
									}
									renderOption={(i) => i}
									renderValue={(i) => i}
									error={userInfoErrors.timeZone}
								/>
							</dd>
						</div>
					</dl>
				</GSection>

				<GSection
					title="Email addresses"
					lineBreak={true}
					lineBreakColor="gray-100"
					actions={
						<div className="space-x-4">
							<GButton
								label="Add email"
								size="sm"
								color="primary"
								variant="contained"
								labelClassName="font-bold text-base"
								className="h-[30px] w-[101px]"
								onClick={() => handleNewEmail()}
							/>
						</div>
					}
				>
					{newEmail && (
						<div className="flex flex-row justify-between bg-gray-50 my-3 py-2 px-6 -mx-6">
							<dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:col-span-1">
								<GInput
									className="w-64"
									placeholder="name@domain.com"
									value={emailValue}
									onChange={(e: ChangeEvent<HTMLInputElement>) =>
										handleEmailInput(e)
									}
								/>
							</dd>
							<div className="flex gap-3">
								<GButton
									size="sm"
									variant="text"
									type="text"
									label="Cancel"
									color="neutral"
									onClick={handleClose}
								/>
								<GButton
									size="sm"
									variant="contained"
									loading={isLoading('USER_ADD_NEW_EMAIL')}
									label="Add"
									color="primary"
									onClick={handleAddnewEmail}
								/>
							</div>
						</div>
					)}
					{user.email_addresses.map((email, idx) => (
						<div
							key={idx}
							className="pt-7 sm:grid sm:grid-cols-3 sm:gap-2 items-center"
						>
							<EmailItem email={email} />
						</div>
					))}
				</GSection>
			</div>
		</div>
	)
}

const EmailItem = ({ email }: { email: EmailAddresInfoDTO }) => {
	const { userStore } = useStores()
	const isLoading = useUiStore((s) => s.isLoading)
	const { resendVerificationEmail, deleteEmail } = userStore
	const [openVerificationConfirmation, setOpenVerificationConfirmation] =
		useState(false)
	const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
	const handleVerificationEmail = async (email: string) => {
		try {
			await resendVerificationEmail({ email })
			setOpenVerificationConfirmation(false)
		} catch (error) {
			setOpenVerificationConfirmation(false)
			return
		}
	}

	const handleDeleteEmail = async (email: string) => {
		try {
			await deleteEmail({ email })
			setOpenDeleteConfirmation(false)
		} catch (error) {
			return
		}
	}

	const deleteIcon = () => {
		return <RiDeleteBin2Line className="ml-0 mr-0 h-3.5 w-3.5" />
	}
	return (
		<>
			<dt className="text-base font-normal text-t-default">{email.email}</dt>
			<dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
				<div className="flex justify-end items-center space-x-4 w-full">
					<GBadge
						color={email.email_verified ? 'green' : 'amber'}
						text={
							<span className="font-bold px-1 font-sm">
								{email.email_verified ? 'Verified' : 'Not verified'}
							</span>
						}
					/>
					<div className="flex gap-3">
						{!email.email_verified ? (
							<GTooltip content="Resend confirmation email">
								<GButton
									size="xs"
									variant="text"
									label="Resend"
									icon={RiRefreshLine}
									color="primary"
									labelClassName="font-bold"
									className="h-[27px]"
									onClick={() => setOpenVerificationConfirmation(true)}
								/>
							</GTooltip>
						) : (
							<></>
						)}
						<GTooltip content="Remove email address">
							<GButton
								variant="text"
								size="sm"
								color="danger"
								type="icon"
								icon={deleteIcon}
								onClick={() => setOpenDeleteConfirmation(true)}
							/>
						</GTooltip>
					</div>
				</div>
				<GConfirm
					title="Resend verification code"
					description="You’re about to send another verification for this email. Check your inbox for a verification link. Proceed?"
					open={openVerificationConfirmation}
					setOpen={setOpenVerificationConfirmation}
					onConfirm={() => handleVerificationEmail(email.email)}
					loading={isLoading('USER_RESEND_VERIFICATION_EMAIL')}
				>
					<></>
				</GConfirm>
				<GConfirm
					title="Remove email address"
					description="This action will delete this email address from your profile. You can’t remove the email if it’s used for login method or notifications. Proceed?"
					open={openDeleteConfirmation}
					setOpen={setOpenDeleteConfirmation}
					onConfirm={() => handleDeleteEmail(email.email)}
					loading={isLoading('USER_DELETE_EMAIL')}
				>
					<></>
				</GConfirm>
			</dd>
		</>
	)
}
