import { GSection, GTooltip } from 'components/basic-blocks'
import { Notices } from 'components/notice/notices'
import { AppConfig } from 'config'
import { useEffect, useState } from 'react'
import { RiFileCopyLine, RiQuestionLine, RiShareBoxFill } from 'react-icons/ri'
import { useUiStore } from 'store'
import { GRadio } from '../../../../../components/basic-blocks/g-radio'
import { PageHeader } from '../../../../../components/page-header'
import {
	conversionCode,
	conversionCodeWithMessage,
	conversionCodeWithMessageAndValue,
	conversionScript,
	trackingScript
} from '../../../../../config/tracking-code'
import { useStores } from '../../../../../hooks'
import { PageTitle } from '../../../../../utils/page-title'
import { GtmAccountSelector } from '../../gtm'

export const TrackingSection = () => {
	const {
		assetStore: { asset, demoMode, gtmTag, getGtmTag, adAccounts },
		accountStore: { account, isInSetup, getGtmLinks },
		accountStore
	} = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	const [installType, setInstallType] = useState<'GTM' | 'MANUAL'>('MANUAL')
	useEffect(() => {
		gtmTag && setInstallType('GTM')
	}, [gtmTag])

	useEffect(() => {
		if (account?.id) {
			getGtmLinks(account.id)
			asset?.id && getGtmTag(account.id, asset.id)
		}
	}, [asset?.id, account?.id])

	const isValidUrl = (urlString: string) => {
		const urlPattern = new RegExp(
			'^(https?:\\/\\/)?' + // validate protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
				'(\\#[-a-z\\d_]*)?$',
			'i'
		) // validate fragment locator
		return urlPattern.test(urlString)
	}

	return (
		<div className="w-full">
			<PageTitle pageName="Tracking code" />
			<Notices
				isInSetup={isInSetup()}
				setupStep={account?.setup?.step}
				isInActive={account?.status === 'INACTIVE'}
				hasDashboardPerformanceFeature={accountStore.hasFeature(
					'dashboard_performance'
				)}
				tc_installed={!!asset?.tc_installed}
				cc_installed={!!asset?.cc_installed}
				accountId={account?.id}
				assetId={asset?.id}
				demoMode={demoMode}
				mccLinkInviteAdAccounts={adAccounts}
			/>
			<PageHeader
				title={'Tracking code'}
				subtitle={
					<p className="flex items-center">
						Setup tracking code on&nbsp;
						<span className="font-bold max-w-[200px] truncate flex items-center">
							{asset?.name}
							{asset?.name && isValidUrl(asset.name) && (
								<a
									href={`//${asset.name}`}
									target="_blank"
									className="pl-1 pr-1 text-primary-500"
								>
									<RiShareBoxFill />
								</a>
							)}{' '}
						</span>
						&nbsp;for click, behavior and conversion tracking
					</p>
				}
			/>
			<div className="max-w-3xl px-4 sm:px-6 md:px-8">
				<GSection
					title="Base installation"
					lineBreak={true}
					lineBreakColor="gray-100"
					loading={isLoading('ASSET_GET_GTM_TAG')}
				>
					<p className="text-t-secondary text-base mt-6">
						Setting up tracking code is required for tracking traffic and
						visitor behavior on your website.
					</p>
					<div className="my-6">
						<div className="flex items-center gap-1">
							<GRadio
								value={installType}
								checked={installType === 'GTM'}
								onChange={() => setInstallType('GTM')}
								labelClass="font-bold text-base"
								label="Install with Google Tag Manager"
								name="gtm"
							/>
							<GTooltip content="Install TC with Clickguard with Google Tag Manager">
								<RiQuestionLine className="text-gray-500 h-3 w-3" />
							</GTooltip>
						</div>
						<div className="flex items-center gap-1 mt-3">
							<GRadio
								value={installType}
								checked={installType === 'MANUAL'}
								onChange={() => setInstallType('MANUAL')}
								labelClass="font-bold text-base"
								label="Install manually"
								name="manual"
							/>
							<GTooltip content="To install manually, put the following code in the <head> section of your website">
								<RiQuestionLine className="text-gray-500 h-3 w-3" />
							</GTooltip>
						</div>
					</div>
					{installType === 'GTM' ? (
						<GtmAccountSelector />
					) : (
						<div>
							<p className="text-t-secondary text-base my-2">
								To install manually, put the following code in the &lt;head&gt;
								section of your website
							</p>
							<TrackerCodeTemplate
								font="font-normal"
								isCopy={false}
								content={{
									subtitle: 'Tracking script',
									code:
										account?.id && asset?.id
											? trackingScript(account!.id, asset!.id)
											: '< code sample >'
								}}
							/>
							<p className="text-t-secondary text-base my-2">
								Make sure this code is added to all your website pages
							</p>

							<p>
								Visit the{' '}
								<a
									className="mb-2 text-md text-primary-500 hover:text-primary-300 underline font-bold"
									href={AppConfig.KNOWLEDGEBASE_URL}
									target="_blank"
								>
									Knowledge base
								</a>{' '}
								for additional help.
							</p>
						</div>
					)}
				</GSection>
				<GSection
					title="Conversion tracking"
					lineBreak={true}
					lineBreakColor="gray-100"
				>
					<p className="text-t-secondary text-base mt-6">
						Setting up conversion tracking is recommended to gain better insight
						into performance of your PPC accounts.
					</p>
					<div>
						<p className="text-t-default font-bold text-base mt-6">
							Landing page conversion
						</p>
						<p className="text-t-secondary text-base my-2 mb-6">
							When loading a specific page should be reported as a conversion,
							like a "Thank You" page. Put the following code at the bottom of
							the page body.
						</p>
						<TrackerCodeTemplate
							isCopy={false}
							height={1}
							font="font-normal"
							content={{
								subtitle: 'Tracking script',
								code:
									account?.id && asset?.id
										? conversionScript()
										: '< code sample >'
							}}
						/>
						<p className="text-t-default font-bold text-base mt-6">
							Custom events
						</p>
						<p className="text-t-secondary text-base mt-2 mb-6">
							When triggering a conversion on other events, like clicking on a
							button or submitting a form, you need to execute following code
							when those events happen.
						</p>
						<TrackerCodeTemplate
							isCopy={false}
							font="font-normal"
							height={9}
							content={{
								subtitle: 'Tracking script',
								code:
									account?.id && asset?.id
										? `${conversionCode()}\n${conversionCodeWithMessage()}\n${conversionCodeWithMessageAndValue()}`
										: '< code sample >'
							}}
						/>
					</div>
				</GSection>
			</div>
		</div>
	)
}

export const TrackerCodeTemplate = (props: {
	content: any
	badge?: any
	height?: number
	isCopy?: boolean
	font?: string
}) => {
	const { content, height, isCopy = true, font } = props
	const [copyStatus, setCopyStatus] = useState(false)
	return (
		<div>
			<div className="flex gap-4 mt-2">
				<div className="relative w-full">
					<textarea
						rows={height || 3}
						cols={42}
						className={`text-t-secondary text-sm bg-gray-50 w-full py-4
							pl-4 pr-8 font-mono resize-none placeholder-gray-500 focus:ring-0 shadow-inner border-none rounded ${
								font || 'font-medium'
							}`}
						readOnly
						value={content.code}
					/>
					{isCopy && (
						<div
							className="absolute top-2 right-3 cursor-pointer"
							onClick={() => copy(content.code)}
						>
							<GTooltip
								content={copyStatus ? 'Copied!' : 'Copy'}
								position="top"
							>
								<RiFileCopyLine className="w-5 h-5 text-gray-400 hover:text-primary-500" />
							</GTooltip>
						</div>
					)}
				</div>
			</div>
		</div>
	)

	async function copy(text: string) {
		await navigator.clipboard.writeText(text)
		setCopyStatus(true)
		setTimeout(() => {
			setCopyStatus(false)
		}, 2000)
	}
}
