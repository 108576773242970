import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect'
import { useWindowResize } from 'hooks/useWindowResize'
import { useRef } from 'react'
import {
	AutoSizer,
	CellMeasurer,
	CellMeasurerCache,
	List
} from 'react-virtualized'
import { GVirtualizedListProps } from './types'

export const GVirtualizedList = (props: GVirtualizedListProps) => {
	const { length, children } = props
	const [windowWidth] = useWindowResize()
	const cache = useRef(
		new CellMeasurerCache({
			fixedWidth: false,
			defaultHeight: 50
		})
	)

	useDidUpdateEffect(() => {
		cache.current.clearAll()
	}, [windowWidth, length])

	return (
		<>
			<AutoSizer>
				{({ width, height }) => (
					<List
						width={width}
						height={height}
						rowHeight={cache.current.rowHeight}
						deferredMeasurementCache={cache.current}
						rowCount={length}
						rowRenderer={({ key, index, style, parent }) => {
							return (
								<CellMeasurer
									key={key}
									cache={cache.current}
									parent={parent}
									columnIndex={0}
									rowIndex={index}
								>
									<div style={style}>{children(index)}</div>
								</CellMeasurer>
							)
						}}
					/>
				)}
			</AutoSizer>
			<div className="invisible">
				{Array.from(Array(length).keys()).map((index) => (
					<div key={index}>{children(index)}</div>
				))}
			</div>
		</>
	)
}
