import { OfferRequestDTO } from 'api-models'
import { UserAvatar } from 'components'
import { GButton, GConfirm, GTooltip } from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'
import { GDateTime } from 'components/basic-blocks/g-datetime'
import { GTableColumn } from 'components/basic-blocks/g-table/types'
import { PlanNames } from 'config'
import { useStores } from 'hooks'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { RiLoginCircleLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { useUiStore, useUserStore } from 'store'
import { formatCurrency, formatPriceInterval, formatSpend } from 'utils'
import { planTierColorMapper } from 'utils/icons'
import { AdminCreateOffer } from '../../../../components/create-offer'
import { AdminAccessTypes, hasAdminAccess } from '../../../../utils/admin'

export const columns: Array<GTableColumn<OfferRequestDTO>> = [
	{
		label: '',
		path: 'requestFor',
		render: (row: OfferRequestDTO) => <RequestForColumn row={row} />,
		className: 'justify-start'
	},
	{
		label: 'User',
		path: 'owner',
		render: (row: OfferRequestDTO) => <OwnerColumn row={row} />,
		width: '300px',
		className: 'justify-center'
	},
	{
		label: 'Current plan',
		path: 'currentPrice',
		render: (row: OfferRequestDTO) => <CurrentPriceColumn row={row} />,
		width: '200px',
		className: 'justify-center'
	},
	{
		label: 'Requested',
		path: 'requestedSpend',
		render: (row: OfferRequestDTO) => <RequestedSpendColumn row={row} />,
		className: 'justify-center'
	},
	{
		label: 'Offer',
		path: 'offer',
		render: (row: OfferRequestDTO) => <OfferColumn row={row} />,
		className: 'justify-center'
	},
	{
		label: 'Request date',
		path: 'created_at',
		render: (row: OfferRequestDTO) => CreatedColumn(row),
		sortKey: 'created_at',
		className: 'justify-center'
	},
	{
		label: 'Dismiss offer',
		render: (row: OfferRequestDTO) => DismissOffer(row),
		className: 'justify-center'
	}
]

const OwnerColumn = (props: { row: OfferRequestDTO }) => {
	const { row } = props
	const navigate = useNavigate()
	const { adminStore } = useStores()
	const [loading, setLoading] = useState(false)

	const link = `/admin/user/${row.user_id}`
	const name = row.user_info?.name
	const email = row.user_info?.email

	const impersonate = async () => {
		setLoading(true)
		try {
			adminStore.setImpersonation(row.user_id)
			setLoading(false)
			navigate('/')
		} catch (e) {
			setLoading(false)
		}
	}

	useEffect(() => {
		return () => {
			setLoading(false)
		}
	}, [])

	return (
		<div className="flex space-x-2 items-center">
			<GTooltip
				persist
				content={
					<div className="w-72 flex flex-col">
						<div className="w-full flex justify-between items-center py-2">
							<div className="w-full flex justify-start items-center">
								<div className="mr-2">
									<GButton
										shape="square"
										type="icon"
										loading={loading}
										icon={RiLoginCircleLine}
										variant="contained"
										color="primary"
										size="xs"
										onClick={impersonate}
									>
										Impersonate
									</GButton>
								</div>
								<div className="flex space-x-2 items-center">
									<UserAvatar size={8} src={row.user_info?.avatar} />
									<div
										className="flex flex-col group cursor-pointer"
										onClick={() => navigate(link)}
									>
										<div className="font-semibold text-primary-500 group-hover:text-primary-600">
											{name}
										</div>
										<div className="text-gray-500">{email}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				}
			>
				<div className="flex -space-x-4">
					<div className="flex space-x-2 items-center">
						<UserAvatar size={8} src={row.user_info?.avatar} />
					</div>
				</div>
			</GTooltip>
		</div>
	)
}

const RequestForColumn = (props: { row: OfferRequestDTO }) => {
	const { row } = props

	const navigate = useNavigate()
	const link = `/admin/subscription/${row.account_id}`
	const planName = PlanNames[row?.tier || '']
	const planIcon = planTierColorMapper(row.tier || '', 3)

	return (
		<div className="flex justify-between items-center gap-2 w-full">
			<div className="flex items-center space-x-4">
				{row.account_id ? (
					<>
						{planIcon}
						<div className="flex flex-col justify-center">
							<div
								className="font-medium text-primary-500 hover:text-primary-600 cursor-pointer truncate max-w-[200px]"
								onClick={() => navigate(link)}
							>
								{row.account_info?.name || ''}
							</div>
							<div className="text-xs text-gray-500">{planName || ''}</div>
						</div>
					</>
				) : (
					<div>New workspace</div>
				)}
			</div>
		</div>
	)
}

const CurrentPriceColumn = (props: { row: OfferRequestDTO }) => {
	const { row } = props
	return (
		<div className="w-full flex flex-col justify-center items-center">
			<div className="pb-1">
				{row.account_info?.price ? (
					<GBadge
						text={
							<span>
								{formatCurrency(row.account_info?.price)}{' '}
								{formatPriceInterval(
									row.account_info?.interval,
									row.account_info?.interval_count
								)}
							</span>
						}
						color="green"
					/>
				) : (
					<GBadge text={<span>None</span>} color="gray" />
				)}
			</div>
			<div className="pt-1">
				{row.account_info?.spend ? (
					<GBadge
						text={<span>{formatSpend(row.account_info?.spend)} spend</span>}
						color="purple"
					/>
				) : null}
			</div>
		</div>
	)
}

const RequestedSpendColumn = (props: { row: OfferRequestDTO }) => {
	const { row } = props

	return (
		<>
			{row.spend ? (
				<div className="w-full flex flex-col justify-center items-center">
					<div className="pb-1">
						<GBadge
							text={<span>{formatSpend(row.spend)}</span>}
							color="purple"
						/>
					</div>
					<div className="pt-1">
						<GBadge text={<span>Billed {row.interval}</span>} color="gray" />
					</div>
				</div>
			) : (
				<GBadge text={<span>None</span>} color="gray" />
			)}
		</>
	)
}

const OfferColumn = (props: { row: OfferRequestDTO }) => {
	const { row } = props

	return (
		<>
			<AdminCreateOffer
				user={{ id: row.user_id, name: row.user_info?.name || '' }}
				account={
					row.account_id
						? {
								id: row.account_id,
								name: row.account_info?.name || '',
								spend: row?.account_info?.spend || null,
								price: row?.account_info?.price || null,
								interval: row?.account_info?.interval || null,
								interval_count: row?.account_info?.interval_count || null,
								planName: row?.account_info?.tier || null
						  }
						: undefined
				}
				offerRequest={row}
			>
				{(open) => (
					<GTooltip
						content={
							!hasAdminAccess(
								useUserStore.getState().user,
								AdminAccessTypes.make_offer
							)
								? 'You do not have permission to create offers'
								: ''
						}
					>
						<GButton
							label="Create offer"
							color="primary"
							disabled={
								!hasAdminAccess(
									useUserStore.getState().user,
									AdminAccessTypes.make_offer
								)
							}
							variant="contained"
							onClick={() => open()}
							size="xs"
						/>
					</GTooltip>
				)}
			</AdminCreateOffer>
		</>
	)
}

const CreatedColumn = (row: OfferRequestDTO) => {
	return (
		<div className="flex flex-col gap-1 justify-center">
			<div className="font-bold">
				<GDateTime date={row.created_at} />
			</div>
			<div className="text-xs">
				{moment(row.created_at).format('MMMM Do, YYYY - hh:mm:ss A')}
			</div>
		</div>
	)
}

const DismissOffer = (row: OfferRequestDTO) => {
	const [open, setOpen] = useState<boolean>(false)
	const {
		adminStore: { deleteOfferRequest }
	} = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	return (
		<div className="flex flex-col gap-1 justify-center">
			<GConfirm
				title={'Dismiss Offer Request'}
				description="Are you sure you want to remove this offer Request. This action cannot be undone. Proceed?"
				type="danger"
				open={open}
				setOpen={(e) => setOpen(e)}
				onConfirm={() => deleteOfferRequest(row.id)}
				loading={isLoading('ADMIN_DELETE_OFFER_REQUEST')}
			>
				<GTooltip
					content={
						!hasAdminAccess(
							useUserStore.getState().user,
							AdminAccessTypes.dismiss_offer
						)
							? 'You do not have permission to dismiss offers'
							: ''
					}
				>
					<GButton
						label="Dismiss offer"
						color="danger"
						disabled={
							!hasAdminAccess(
								useUserStore.getState().user,
								AdminAccessTypes.dismiss_offer
							)
						}
						variant="contained"
						onClick={() => setOpen(true)}
						size="xs"
					/>
				</GTooltip>
			</GConfirm>
		</div>
	)
}
