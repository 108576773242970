import Bing from '../../assets/img/bing.svg'
import Google from '../../assets/img/google.svg'
import Meta from '../../assets/img/meta.svg'
import GTM from '../../assets/img/google-tag-manager.svg'
import { ImgHTMLAttributes } from 'react'

const GoogleIcon = (props: ImgHTMLAttributes<HTMLImageElement>) => {
	return (
		<img
			src={Google}
			alt="Google"
			className="text-white w-5 h-5"
			data-testid="google-icon"
			{...props}
		/>
	)
}

const MetaIcon = (props: ImgHTMLAttributes<HTMLImageElement>) => {
	return (
		<img
			src={Meta}
			alt="Meta"
			className="text-white w-5 h-5"
			data-testid="meta-icon"
			{...props}
		/>
	)
}

const GtmIcon = (props: ImgHTMLAttributes<HTMLImageElement>) => {
	return (
		<img
			src={GTM}
			alt="goggle-tag-manager"
			className="text-white w-5 h-5"
			data-testid="meta-icon"
			{...props}
		/>
	)
}

const BingIcon = (props: ImgHTMLAttributes<HTMLImageElement>) => {
	return (
		<img src={Bing} alt="bing-icon" className="text-white w-5 h-5" {...props} />
	)
}

export { BingIcon, GoogleIcon, MetaIcon, GtmIcon }
