import { useAuth0 } from '@auth0/auth0-react'
import { ExclamationIcon } from '@heroicons/react/solid'
import { GButton } from 'components/basic-blocks'

export const ErrorPage = (props: { error: string }) => {
	const { logout, loginWithRedirect } = useAuth0()
	const getType = () => {
		return 'red'
	}
	const getTitle = () => {
		return props.error
	}
	return (
		<div className="w-full h-full flex flex-col items-center p-12 space-y-4">
			<div className={`rounded-md bg-${getType()}-100 p-8`}>
				<div className="flex space-x-4">
					<div className="flex-shrink-0">
						<ExclamationIcon
							className={`h-5 w-5 text-${getType()}-500`}
							aria-hidden="true"
						/>
					</div>
					<div className="">
						<h3 className={`text-sm font-medium text-${getType()}-800`}>
							{getTitle()}
						</h3>
					</div>
				</div>
			</div>
			<div className="flex space-x-4">
				<GButton onClick={logout}>Back to login</GButton>
				<GButton color="primary" onClick={loginWithRedirect}>
					Continue
				</GButton>
			</div>
		</div>
	)
}
