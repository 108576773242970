import { AccountLinkDTO, GoogleAdsAccountBasicInfoDTO } from 'api-models'
import { GDialog, GTransition } from 'components/basic-blocks'
import { BingAuthConfig, FacebookAuthConfig, GoogleAuthConfig } from 'config'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { AddPPCConnectionProps, State } from './types'
import { AddPPCConnectionView } from './view'

export const AddPPCConnection = (props: AddPPCConnectionProps) => {
	const { open, onClose, initialTab, selectedTab } = props
	const { accountStore } = useStores()
	const { createOAuthLink, createMCCLink, accountLinks } = accountStore

	const [state, setState] = useState<State>({
		platform: 'GADS',
		step: 'idle',
		selectedOption: 'oauth'
	})

	useEffect(() => {
		if (state.step === 'waiting') {
			const interval = setInterval(async () => {
				const code = getAuthCode()
				if (code) {
					clearInterval(interval)
					clearAuthCode()
					handleNewConnection(code)
				}
			}, 1000)
		}
	}, [state.step])

	const getAuthCode = () => {
		let code
		if (state.platform === 'GADS') {
			code = window.localStorage.getItem('google_oauth_code')
		} else if (state.platform === 'FADS') {
			code = window.localStorage.getItem('facebook_oauth_long_lived_token')
		} else {
			code = window.localStorage.getItem('bing_oauth_code')
		}
		return code
	}

	const clearAuthCode = () => {
		if (state.platform === 'GADS') {
			window.localStorage.removeItem('google_oauth_code')
		} else if (state.platform === 'FADS') {
			window.localStorage.removeItem('facebook_oauth_long_lived_token')
		} else {
			window.localStorage.removeItem('bing_oauth_code')
		}
		setState({ ...state, step: 'idle' })
	}

	const handleNewConnection = async (code: string) => {
		try {
			await createOAuthLink(
				code,
				state.platform === 'GADS'
					? 'google'
					: state.platform === 'FADS'
					? 'facebook'
					: 'bing'
			)
			onClose && onClose()
		} catch (e) {
			console.log(e)
		}
	}

	const googleAdsConnections = accountLinks.filter(
		(link) => link.platform === 'GADS'
	)

	const handleAuthorize = () => {
		if (state.platform === 'GADS') {
			window.open(GoogleAuthConfig.AUTH_URL, '_blank')
		} else if (state.platform === 'FADS') {
			window.open(FacebookAuthConfig.AUTH_URL, '_blank')
		} else {
			window.open(BingAuthConfig.AUTH_URL, '_blank')
		}
		setState({ ...state, step: 'waiting' })
	}

	const handleAddMCCAccount = async () => {
		if (state.selectedLink && state.accountNumber) {
			try {
				await createMCCLink(
					state.selectedLink.id,
					state.accountNumber.account_number
				)
				onClose && onClose()
			} catch (e: any) {
				console.log(e)
				if (e.code === 'ALREADY_EXISTS') {
					toast.error('You already have this MCC account added as a link')
				}
			}
		}
	}

	const handleMultiSelectChange = (val: AccountLinkDTO) => {
		setState({ ...state, selectedLink: val, accountNumber: undefined })
	}
	const handleAccountNumberChange = (val: GoogleAdsAccountBasicInfoDTO) => {
		setState({ ...state, accountNumber: val })
	}

	useEffect(() => {
		setState({
			...state,
			platform: selectedTab || 'GADS',
			selectedOption: initialTab
		})
	}, [selectedTab, initialTab])

	return (
		<GDialog
			title="New PPC access"
			subtitle="Add a new access connection to your workspace"
			open={open}
			onClose={onClose}
			maxWidth="2xl"
			className="sm:max-w-2xl"
		>
			<GTransition show={true} swap>
				<AddPPCConnectionView
					state={state}
					setState={setState}
					connections={googleAdsConnections}
					handleAuthorize={handleAuthorize}
					handleAddMCCAccount={handleAddMCCAccount}
					handleMultiSelectChange={handleMultiSelectChange}
					handleAccountNumberChange={handleAccountNumberChange}
				/>
			</GTransition>
		</GDialog>
	)
}
