import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'
import { ReactElement, ReactNode } from 'react'
import { GTransition } from '../g-transition'

interface IGCollapse {
	title?: string | ReactElement
	subtitle?: string | ReactElement
	children: ReactNode
	className?: string
	buttonClass?: string
	contentClass?: string
	titleClass?: string
	defaultShow?: ReactNode
	openPanel?: boolean
}

export const GCollapse = ({
	children,
	title,
	subtitle,
	className,
	contentClass,
	buttonClass,
	titleClass,
	defaultShow,
	openPanel
}: IGCollapse) => {
	return (
		<div className={`w-full rounded-2xl ${className}`}>
			<Disclosure>
				{({ open }) => (
					<>
						<Disclosure.Button
							className={
								buttonClass ||
								'flex w-full justify-between items-center rounded-lg bg-gray-50 px-4 py-2 text-left text-sm font-medium'
							}
						>
							<div className={titleClass || 'py-5 bg-[#F9FAFB] px-4'}>
								<span className="font-bold">{title}</span> <br />
								{subtitle}
							</div>
							<ChevronUpIcon
								color="bg-gray-100"
								className={`${
									open ? 'rotate-180 transform' : ''
								} h-5 w-5 text-gray-400`}
							/>
						</Disclosure.Button>
						{defaultShow && defaultShow}
						<GTransition show={open || !!openPanel}>
							<Disclosure.Panel
								className={contentClass || 'pb-2 text-sm text-gray-500'}
							>
								{children}
							</Disclosure.Panel>
						</GTransition>
					</>
				)}
			</Disclosure>
		</div>
	)
}
