import { classNames } from 'utils'

export const InfoItemGroup = (props: {
	title: string
	className?: string
	children: any
}) => {
	return (
		<>
			<dl
				className={classNames(
					'sm:divide-y sm:divide-gray-200 border-b border-t border-gray-200 -mx-6 bg-gray-50',
					props.className || ''
				)}
			>
				<div className="py-2 sm:py-4 px-6">
					<dt className="text-sm font-medium text-t-secondary">
						{props.title}
					</dt>
				</div>
			</dl>

			<dl className="sm:divide-y sm:divide-gray-200 -mx-6 text-xs">
				{props.children}
			</dl>
		</>
	)
}

export const InfoItem = (props: {
	label: string
	children: any
	className?: string
	color?: string
}) => {
	return (
		<div
			className={classNames(
				'py-2 sm:py-4 sm:grid sm:grid-cols-3 sm:gap-4 px-6',
				props.className || ''
			)}
		>
			{props.color ? (
				<dt className={`font-medium text-${props.color}-500`}>{props.label}</dt>
			) : (
				<dt className="font-medium text-t-secondary">{props.label}</dt>
			)}
			<dd className="mt-1 font-medium text-t-secondary sm:mt-0 sm:col-span-2">
				{props.children}
			</dd>
		</div>
	)
}
