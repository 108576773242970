import {
	AdAccountDTO,
	BingAccountLinkDTO,
	BingAdsAccountBasicInfoDTO,
	FacebookAccountLinkDTO,
	FacebookAdsAccountBasicInfoDTO,
	GoogleAdsAccountBasicInfoDTO,
	GoogleOAuthAccountLinkDTO
} from 'api-models'
import {
	GButton,
	GToggle,
	GTooltip,
	GTransition
} from 'components/basic-blocks'
import { GWindowedSelect } from 'components/basic-blocks/g-virtualized-select'
import { PlatformIconRI } from 'components/platform-icon'
import { useEffect, useState } from 'react'
import { RiQuestionLine } from 'react-icons/ri'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { removeFadPrefix } from 'utils'
import {
	AddAccountProps,
	StandardAddAccountProps,
	StandardAddAccountState
} from '../types'
import { useAccountStore } from 'store'
import { usePostHog } from 'posthog-js/react'

export const StandardAddAccount = ({
	state,
	addAccount
}: StandardAddAccountProps) => {
	const oAuthlink = state.googleOAuthLink as GoogleOAuthAccountLinkDTO
	const fblink = state.fbLink as FacebookAccountLinkDTO
	const bingOAuthLink = state.bingOAuthLink as BingAccountLinkDTO
	const posthog = usePostHog()

	const [data, setData] = useState<StandardAddAccountState>({
		error: null,
		selected: state.mccLink || null,
		client: null,
		tracking: true,
		set_custom_audience: true
	})
	const { getAllAccounts } = useAccountStore()
	const buildAddAccountParams = (
		platform: typeof state.platform
	): AddAccountProps => {
		const accountNumber = data.selected?.account_number || ''

		if (platform === 'GADS')
			return {
				number: accountNumber,
				linkId: oAuthlink.id,
				setTrackingTemplate: data.tracking,
				setCustomAudience: undefined
			}

		if (platform === 'FADS')
			return {
				number: accountNumber,
				linkId: fblink.id,
				setTrackingTemplate: undefined,
				setCustomAudience: data.set_custom_audience
			}

		// BADS
		return {
			number: accountNumber,
			linkId: bingOAuthLink.id,
			setTrackingTemplate: data.tracking,
			setCustomAudience: data.set_custom_audience
		}
	}

	const submit = async () => {
		if (!data.selected) return
		posthog?.capture('click-on-cta-onboarding-screen-2')
		if (state.mccLink && data.client) {
			const err = await addAccount({
				number: data.client.account_number,
				linkId: state.mccLink.id,
				setTrackingTemplate: data.tracking,
				setCustomAudience:
					state.platform === 'FADS' ? data.set_custom_audience : undefined
			})
			setData({ ...data, error: err || null })
			return
		}


		const err = await addAccount(buildAddAccountParams(state.platform))
		setData({ ...data, error: err || null })
		getAllAccounts()
	}

	useEffect(() => {
		if (state.mccLink) {
			setData({ ...data, selected: state.mccLink, client: null })
		}
	}, [state.mccLink])

	return (
		<div className="w-full flex flex-col items-center">
			<div className="w-full flex flex-col space-y-5 mb-4">
				<div className="flex items-center space-x-2 text-t-dark shadow rounded-lg px-4 py-2 bg-gray-100">
					<PlatformIconRI
						platform={state.platform as AdAccountDTO['platform']}
						className="w-6 h-6"
					/>
					<div className="flex flex-col text-sm">
						<span className="text-base font-bold text-t-default">
							{state.platform === 'GADS' && oAuthlink?.name}
							{state.platform === 'FADS' && fblink?.email}
							{state.platform === 'BADS' && bingOAuthLink?.email}
						</span>
						<span className="text-sm text-t-secondary"> Connected </span>
					</div>
				</div>
				<div className="text-base text-t-default">
					{state.platform === 'GADS' && 'Select your standard or MCC account.'}
					{state.platform === 'FADS' &&
						'Select a Meta Ads account to begin setup'}
					{state.platform === 'BADS' &&
						'Select a Bing Ads account to begin setup'}
				</div>
				<div>
					{state.platform === 'GADS' && (
						<GWindowedSelect
							labelClassName="!text-base !font-bold !text-t-default"
							label={'Ads account'}
							options={[...(oAuthlink?.clients || [])]}
							value={data.selected}
							isDisabled={state.loading || !!state.mccLink}
							onChange={(newValue) =>
								setData({
									...data,
									selected: newValue as GoogleAdsAccountBasicInfoDTO
								})
							}
							placeholder="Find account"
							isOptionSelected={(option) =>
								(option as GoogleAdsAccountBasicInfoDTO).account_number ===
								data.selected?.account_number
							}
							formatOptionLabel={(option) =>
								(option as GoogleAdsAccountBasicInfoDTO).account_number
							}
							filterOption={(option, query) => {
								return query
									? (
										option as FilterOptionOption<GoogleAdsAccountBasicInfoDTO>
									).data.account_number
										?.toLocaleLowerCase()
										.includes(query)
									: true
							}}
						/>
					)}
					{state.platform === 'FADS' && (
						<GWindowedSelect
							labelClassName="!text-base !font-bold !text-default"
							label={'Ad account'}
							options={[...(state?.fbLink?.clients || [])]}
							value={data.selected}
							isDisabled={state.loading || !!state.mccLink}
							onChange={(newValue) =>
								setData({
									...data,
									selected: newValue as FacebookAdsAccountBasicInfoDTO
								})
							}
							placeholder="Find account"
							isOptionSelected={(option) =>
								(option as FacebookAdsAccountBasicInfoDTO).name ===
								data.selected?.name ||
								(option as FacebookAdsAccountBasicInfoDTO).account_number ===
								data.selected?.account_number
							}
							formatOptionLabel={(option) =>
								`${removeFadPrefix(
									(option as FacebookAdsAccountBasicInfoDTO).account_number
								)} - ${(option as FacebookAdsAccountBasicInfoDTO).name}`
							}
							filterOption={(option, query) => {
								return query
									? (
										option as FilterOptionOption<FacebookAdsAccountBasicInfoDTO>
									).data.name
										?.toLocaleLowerCase()
										.includes(query) ||
									(
										option as FilterOptionOption<FacebookAdsAccountBasicInfoDTO>
									).data.account_number
										?.toLocaleLowerCase()
										.includes(query)
									: true
							}}
						/>
					)}
					{state.platform === 'BADS' && (
						<GWindowedSelect
							labelClassName="!text-base !font-bold !text-t-default"
							label={'Ads account'}
							options={[...(bingOAuthLink.clients || [])]}
							value={data.selected}
							isDisabled={state.loading || !!state.mccLink}
							onChange={(newValue) =>
								setData({
									...data,
									selected: newValue as BingAdsAccountBasicInfoDTO
								})
							}
							placeholder="Find account"
							isOptionSelected={(option) =>
								(option as BingAdsAccountBasicInfoDTO).account_number ===
								data.selected?.account_number
							}
							formatOptionLabel={(option) =>
								`${(option as FacebookAdsAccountBasicInfoDTO).account_number
								} - ${(option as FacebookAdsAccountBasicInfoDTO).name}`
							}
							filterOption={(option, query) => {
								return query
									? (
										option as FilterOptionOption<FacebookAdsAccountBasicInfoDTO>
									).data.name
										?.toLocaleLowerCase()
										.includes(query) ||
									(
										option as FilterOptionOption<FacebookAdsAccountBasicInfoDTO>
									).data.account_number
										?.toLocaleLowerCase()
										.includes(query)
									: true
							}}
						/>
					)}
				</div>
				{state.mccLink && (
					<div className="flex flex-col space-y-4">
						<div className="text-sm text-t-secondary">
							MCC account initialized, select a client account.
						</div>

						<GWindowedSelect
							labelClassName="!text-base !font-bold !text-t-default"
							label="Client account"
							options={[...(state.mccLink.clients || [])]}
							value={data.client || null}
							isDisabled={state.loading}
							onChange={(newValue) =>
								setData({
									...data,
									client: newValue as GoogleAdsAccountBasicInfoDTO
								})
							}
							placeholder="Find account"
							isOptionSelected={(option) =>
								(option as GoogleAdsAccountBasicInfoDTO).account_number ===
								data.client?.account_number
							}
							formatOptionLabel={(option) =>
								(option as GoogleAdsAccountBasicInfoDTO).account_number
							}
							filterOption={(option, query) => {
								return query
									? (
										option as FilterOptionOption<GoogleAdsAccountBasicInfoDTO>
									).data.account_number
										?.toLocaleLowerCase()
										.includes(query)
									: true
							}}
						/>
					</div>
				)}
				{state.platform === 'GADS' && (
					<div className="flex space-x-1 items-center">
						<GToggle
							labelClassName="text-t-default text-base"
							checked={data.tracking}
							onChange={(v) => setData({ ...data, tracking: v })}
							label="Automatically configure click tracking"
						/>
						<GTooltip content="Sets ClickGUARD tracking template on your Google Ads account">
							<RiQuestionLine className="text-t-secondary w-3" />
						</GTooltip>
					</div>
				)}
				{state.platform === 'FADS' && (
					<div className="flex space-x-1 items-center">
						<GToggle
							labelClassName="text-t-default text-base"
							checked={data.set_custom_audience}
							onChange={(c) => setData({ ...data, set_custom_audience: c })}
							label="Automatically set excluded audience in ad sets"
						/>
						<GTooltip
							content={
								"Updates targeting on ad sets to exclude ClickGUARD's audience of blocked users"
							}
						>
							<RiQuestionLine className="text-t-secondary w-3" />
						</GTooltip>
					</div>
				)}
				{state.platform === 'BADS' && (
					<>
						<div className="flex space-x-1 items-center">
							<GToggle
								labelClassName="text-t-default text-base"
								checked={data.tracking}
								onChange={(value) =>
									setData({
										...data,
										tracking: value
									})
								}
								label="Automatically configure click tracking"
							/>
							<GTooltip
								content={
									'Sets ClickGUARD tracking template on your Bing Ads account'
								}
							>
								<RiQuestionLine className="text-t-secondary w-3" />
							</GTooltip>
						</div>
						<div className="flex space-x-1 items-center">
							<GToggle
								labelClassName="text-t-default text-base"
								checked={data.set_custom_audience}
								onChange={(c) =>
									setData({
										...data,
										set_custom_audience: c
									})
								}
								label="Automatically set excluded audience"
							/>
							<GTooltip
								content={
									"Updates targeting on campaigns to exclude ClickGUARD's audience of blocked users"
								}
							>
								<RiQuestionLine className="text-t-secondary w-3" />
							</GTooltip>
						</div>
					</>
				)}
				<GTransition show={!!data.selected}>
					<div className="flex space-x-1 items-center justify-center">
						<GButton
							color="primary"
							onClick={() => submit()}
							className="w-full mt-4"
							disabled={
								state.loading || state.mccLink
									? !data.client?.account_number
									: !data.selected?.account_number
							}
							loading={state.loading}
						>
							<div className="flex items-center">Continue</div>
						</GButton>
					</div>
				</GTransition>
				{data.error && (
					<div className="flex space-x-1 items-center justify-center text-red-700">
						{data.error}
					</div>
				)}
			</div>
		</div>
	)
}
