import {
	AdAccountDTO,
	FacebookAdsCampaignDTO,
	GoogleAdsCampaignDTO,
	ProtectionInfoDTO
} from 'api-models'
import { lazy, Suspense, useEffect, useState } from 'react'

import { GLazyLoading, GLoading } from 'components/basic-blocks'
import { PageHeader } from 'components/page-header'
import { Breadcrumbs } from 'layout/navbar/breadcrumbs'
import { PageTitle } from 'utils/page-title'

import { getLevel } from 'layout/navbar/breadcrumbs/utils'
import { useAccountStore, useAssetStore } from 'store'
import { getEntityName } from 'utils/get-entity-name'
import { useShallow } from 'zustand/react/shallow'
import { Notices } from '../../../../components/notice/notices'
import { useAsset } from '../asset-base'
import { useSearchParams } from 'react-router-dom'

const ProtectionPanel = lazy(() =>
	import('./components/protection-panel')
		.then((module) => {
			return { default: module.ProtectionPanel }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)
const RulesPanel = lazy(() =>
	import('./components/rules-panel')
		.then((module) => {
			return { default: module.RulesPanel }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)

export const ProtectionPage = () => {
	const { accountId, assetId } = useAsset()
	const [searchParams] = useSearchParams()
	const campaignId = searchParams.get('breadCampaign')
	const { asset, demoMode, campaigns, adAccounts } = useAssetStore(
		useShallow((s) => ({
			asset: s.asset,
			demoMode: s.demoMode,
			campaigns: s.campaigns,
			adAccounts: s.adAccounts
		}))
	)
	const { account, isInSetup, hasFeature } = useAccountStore(
		useShallow((s) => ({
			account: s.account,
			isInSetup: s.isInSetup,
			hasFeature: s.hasFeature
		}))
	)

	const [selectedAdAccount, setSelectedAdAccount] =
		useState<AdAccountDTO | null>(null)
	const [selectedCampaign, setSelectedCampaign] = useState<
		GoogleAdsCampaignDTO | FacebookAdsCampaignDTO | null
	>(null)
	const [loading, setLoading] = useState(true)
	const [protection, setProtection] = useState<
		ProtectionInfoDTO | null | undefined
	>(null)

	const level = getLevel({
		isAdAccountSelected: !!selectedAdAccount,
		isCampaignSelected: !!selectedCampaign
	})
	const target =
		level === 'website'
			? asset
			: level === 'ad-account'
			? selectedAdAccount
			: selectedCampaign

	useEffect(() => {
		if (target) {
			setProtection(target.protection)
			setLoading(false)
		}
	}, [target])

	useEffect(() => {
		if (campaignId) {
			const campaignSelected =
				campaigns.find((campaign) => {
					return campaign.id === campaignId
				}) || null
			setSelectedCampaign(campaignSelected || null)
		}
	}, [campaigns, campaignId])

	return (
		<>
			<Notices
				isInSetup={isInSetup()}
				setupStep={account?.setup?.step}
				isInActive={account?.status === 'INACTIVE'}
				hasDashboardPerformanceFeature={hasFeature('dashboard_performance')}
				tc_installed={!!asset?.tc_installed}
				cc_installed={!!asset?.cc_installed}
				accountId={accountId}
				assetId={assetId}
				demoMode={demoMode}
				mccLinkInviteAdAccounts={adAccounts}
			/>
			<PageTitle pageName="Protection Rules" />
			<PageHeader
				title={'Protection Rules'}
				tagline="Configure automated protection settings and custom rules for"
				tags={[
					{
						label: getEntityName({
							selectedCampaign,
							adAccount: selectedAdAccount,
							asset,
							demoMode
						})
					}
				]}
				action={
					<div className="w-full pr-8 flex items-center justify-between pb-4">
						<Breadcrumbs
							asset={asset}
							selectedAdAccount={selectedAdAccount}
							setSelectedAdAccount={setSelectedAdAccount}
							showCampaignSelector={true}
							demoMode={demoMode}
						/>
					</div>
				}
			/>

			{loading ? (
				<GLoading />
			) : (
				<main className="px-6 flex flex-col xl:flex-row xl:gap-8">
					<Suspense fallback={<GLazyLoading />}>
						<ProtectionPanel
							level={level}
							protection={protection}
							target={target}
							inheritName={
								level === 'ad-account' ? asset?.name : selectedAdAccount?.name
							}
							platform={selectedAdAccount?.platform}
						/>
					</Suspense>
					<Suspense fallback={<GLazyLoading />}>
						<RulesPanel
							level={level}
							targetId={target?.id}
							targetName={target?.name}
							platform={selectedAdAccount?.platform}
						/>
					</Suspense>
				</main>
			)}
		</>
	)
}
