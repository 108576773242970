import { NameOption } from './name'
import { LimitBreachedOption } from './limit-breached'
import { WorkspaceStatusOption } from './workspace-status'
import { SubscriptionStatusOption } from './subscription-status'
import { SubscriptionPlanOption } from './subscription-plan'
import { DiscountedOption } from './discounted'
import { OfferOption } from './offer-request'
import { CancelOption } from './cancel-request'

export const options = [
	NameOption,
	WorkspaceStatusOption,
	SubscriptionStatusOption,
	SubscriptionPlanOption,
	CancelOption,
	OfferOption,
	LimitBreachedOption,
	DiscountedOption
]
