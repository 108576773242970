import { UserDTO } from 'api-models'
import { GButton, GSection, GTooltip } from 'components/basic-blocks'
import { OffersList } from 'components/offers-list'
import { RiFileEditLine } from 'react-icons/ri'
import { useUiStore, useUserStore } from 'store'
import { AdminCreateOffer } from '../../../../../../components/create-offer'
import { AdminAccessTypes, hasAdminAccess } from '../../../../../../utils/admin'

export const OffersSection = (props: { user: UserDTO }) => {
	const { user } = props
	const loginUser = useUserStore((s) => s.user)
	const isLoading = useUiStore((s) => s.isLoading)

	return (
		<GSection loading={isLoading('ADMIN_GET_OFFERS')}>
			<div className="flex justify-between items-center -mt-5 -mx-6 px-5 py-3 border-b rounded-t-lg border-gray-200 bg-gray-50 text-t-dark text-sm font-medium">
				<div>Offers</div>
				<div>
					<AdminCreateOffer user={{ id: user.id, name: user.name || '' }}>
						{(open) => (
							<GTooltip
								content={
									!hasAdminAccess(loginUser, AdminAccessTypes.make_offer)
										? 'You do not have permission to make an offer'
										: ''
								}
							>
								<GButton
									label="Make offer"
									icon={RiFileEditLine}
									color="primary"
									disabled={
										!hasAdminAccess(loginUser, AdminAccessTypes.make_offer)
									}
									variant="contained"
									onClick={() => open()}
									size="sm"
								/>
							</GTooltip>
						)}
					</AdminCreateOffer>
				</div>
			</div>
			<div className="-mx-6">
				<OffersList userId={user.id} />
			</div>
		</GSection>
	)
}
