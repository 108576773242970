import { PricingTierInfoDTO, PricingTierPriceInfoDTO } from 'api-models'
import { GWindowedSelect } from 'components/basic-blocks/g-virtualized-select'
import { useEffect, useState } from 'react'
import {
	RiShieldFlashLine,
	RiShieldLine,
	RiShieldStarLine
} from 'react-icons/ri'
import { classNames, formatCurrency } from 'utils'
import { ProtectionPlanTierProps } from '../types'
import { usePostHog } from 'posthog-js/react'

const interval = {
	monthly: 'month',
	quarterly: 'month',
	yearly: 'year'
}
const count = {
	monthly: 1,
	quarterly: 3,
	yearly: 1
}

export const ProtectionPlanTier = ({
	state,
	plan,
	setState
}: ProtectionPlanTierProps) => {
	const getPrices = () => {
		return plan.prices.filter(
			(p) =>
				p.interval === interval[state.billing_period] &&
				p.interval_count == count[state.billing_period]
		)
	}
	const posthog = usePostHog()

	const getBasePrice = () => {
		const prices = getPrices().filter((p) => p.price_monthly)
		return prices && prices[0]
	}

	const [price, setPrice] = useState<PricingTierPriceInfoDTO | null>(
		getBasePrice()
	)

	const select = (v?: PricingTierPriceInfoDTO) => {
		if (!v && state.selected_tier?.tier === plan.tier) {
			return
		}
		const s = { ...state }
		s.selected_tier = plan
		s.selected_price = v || price
		posthog?.capture(`click-on-${plan.tier}-plan`)
		setPrice(v || price)
		setState({ ...s, custom_plan: false })
	}

	const selectPrice = (val: PricingTierPriceInfoDTO) => {
		setPrice(val)

		if (state.selected_tier?.tier === plan.tier) {
			setState({ ...state, selected_price: val, custom_plan: false })
		}
	}

	useEffect(() => {
		if (state.custom_plan) {
			return
		}
		if (
			price?.interval === interval[state.billing_period] &&
			price?.interval_count == count[state.billing_period]
		) {
			return
		}

		const prices = getPrices()

		selectPrice(prices.find((p) => p.spend === price?.spend) || getBasePrice())
	}, [state.billing_period])

	return (
		<div
			onClick={() => select()}
			className={classNames(
				'w-full flex items-start bg-gray-50 rounded-lg cursor-pointer border-2 p-4 transition-all',
				state.selected_tier?.tier === plan.tier
					? 'border-primary-500'
					: 'border-transparent'
			)}
		>
			<div className="px-2">{iconMapper(plan)}</div>
			<div className="flex-1 flex flex-col space-y-2 px-2">
				<span className="font-semibold text-md">{plan.name}</span>

				{state.selected_tier?.tier !== plan.tier && (
					<span className="text-t-secondary">{plan.description}</span>
				)}

				{state.selected_tier?.tier === plan.tier && (
					<div className="-ml-12 pt-4">
						{plan.tier === 'lite' && (
							<div className="flex flex-col">
								<span className="text-t-secondary">
									Up to $5,000 monthly ad spend
								</span>
								<span className="text-t-secondary">Unlimited clicks</span>
								<span className="text-t-secondary">
									Single website, single PPC account
								</span>
								<span className="text-t-secondary">
									Basic protection settings
								</span>
							</div>
						)}
						{plan.tier === 'standard' && (
							<div className="flex flex-col">
								<span className="text-t-secondary">
									From $10,000 monthly ad spend
								</span>
								<span className="text-t-secondary">
									Upgradable up to $50,000 monthly ad spend
								</span>
								<span className="text-t-secondary">Unlimited clicks</span>
								<span className="text-t-secondary">
									Three websites with two PPC accounts
								</span>
								<span className="text-t-secondary">
									Advanced protection settings
								</span>
							</div>
						)}
						{plan.tier === 'pro' && (
							<div className="flex flex-col">
								<span className="text-t-secondary">
									From $10,000 monthly ad spend
								</span>
								<span className="text-t-secondary">
									Upgradable with a custom quote
								</span>
								<span className="text-t-secondary">Unlimited clicks</span>
								<span className="text-t-secondary">
									Unlimited websites and unlimited PPC accounts
								</span>
								<span className="text-t-secondary">
									Full protection settings
								</span>
							</div>
						)}
					</div>
				)}
			</div>
			<div className="flex flex-col items-end px-4 space-y-1 h-full">
				{state.selected_tier?.tier === plan.tier ? (
					<div className="flex flex-col h-full justify-between items-end">
						<div>
							<span className="text-t-secondary flex flex-col items-end text-sm">
								Monthly ad spend
							</span>
							<GWindowedSelect<PricingTierPriceInfoDTO>
								onChange={(v: any) => select(v)}
								options={getPrices()}
								value={price}
								getOptionValue={(v: any) => v.spend}
								formatOptionLabel={(v: any) =>
									formatCurrency(v.spend, 'dollar')
								}
								isOptionSelected={(v: any) => price?.spend === v.spend}
							/>
						</div>

						<div className="flex flex-col items-end">
							<div className="text-t-dark font-medium text-lg">
								{formatCurrency(price?.price, 'cent')}
							</div>
							<div className="text-t-secondary">{state.billing_period}</div>
						</div>
					</div>
				) : (
					<div className="font-medium ">
						{getBasePrice() ? (
							<div className="flex flex-col items-end justify-between">
								<span>
									{plan.tier !== 'lite' && 'from'}{' '}
									{formatCurrency(getBasePrice()?.price_monthly)} /month
								</span>
								{state.billing_period !== 'monthly' && (
									<span className="text-t-secondary text-xs">
										billed {state.billing_period}
									</span>
								)}
							</div>
						) : (
							<div className="text-t-secondary">Not available</div>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

const iconMapper = (plan: PricingTierInfoDTO) => {
	if (plan.tier === 'lite')
		return <RiShieldLine className="text-red-500 w-6 h-6" />
	if (plan.tier === 'standard')
		return <RiShieldFlashLine className="text-green-500 w-6 h-6" />
	if (plan.tier === 'pro')
		return <RiShieldStarLine className="text-primary-500 w-6 h-6" />
	return <RiShieldStarLine className="text-primary-500 w-6 h-6" />
}
