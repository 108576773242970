import Joi from 'joi'

export const adAccountSettingsFormSchema = Joi.object({
	active: Joi.boolean().required(),
	name: Joi.string().min(2).required().label('Name'),
	color: Joi.string().allow('').label('Color'),
	currency: Joi.string().required().label('Currency'),
	timeZone: Joi.string().required().label('TimeZone'),
	clickTracking: Joi.string().allow('').label('clickTracking'),
	access: Joi.required().messages({ 'any.required': 'Select a connection.' })
})
