import { Notices } from 'components/notice/notices'
import Joi from 'joi'
import { useEffect, useMemo, useState } from 'react'
import { BiBell } from 'react-icons/bi'
import { HiOutlineMail } from 'react-icons/hi'
import { useUiStore } from 'store'
import { GButton, GComboBox, GSection } from '../../../components/basic-blocks'
import { GCheckbox } from '../../../components/basic-blocks/g-checkbox'
import { PageHeader } from '../../../components/page-header'
import { ConfigValueItem } from '../../../config'
import { useStores } from '../../../hooks'

type notificationPrefType =
	| 'newsletter'
	| 'service'
	| 'links'
	| 'subscription'
	| 'team'
	| 'protection'
	| 'reports'

const notificationPreferences = {
	all: 'All notifications',
	newsletter: 'News and promotions',
	service: 'Service interruptions and alerts',
	links: 'Changes to PPC platform connections',
	subscription: 'Subscription and payment events',
	team: 'Additions and changes to team members',
	protection: 'Changes to protection settings',
	reports: 'Delivery of automated and on-demand reports'
}

const emailSchema = Joi.string()
	.email({ tlds: { allow: false } })
	.min(3)
	.max(30)
	.required()

export const NotificationSetting = () => {
	const { userStore, assetStore, accountStore } = useStores()
	const { updateNotifications, user, updateEmail } = userStore
	const { account, isInSetup } = accountStore
	const { asset, adAccounts } = assetStore
	const isLoading = useUiStore((s) => s.isLoading)

	const [errors, setErrors] = useState<null | string>()

	const preference = useMemo(() => {
		const preference = user?.preferences?.notifications
		if (preference?.length === 7) preference.push('all')
		return preference
	}, [user])
	const email_preference = useMemo(() => {
		const preference = user?.preferences?.email_notifications
		if (preference?.length === 7) preference.push('all')
		return preference
	}, [user])
	const [emailValue, setEmailValue] = useState({
		value: user?.contact_email,
		label: user?.contact_email
	})
	const [verifiedEmails, setVerifiedEmails] = useState<ConfigValueItem[]>([
		{ value: '', label: '' }
	])

	const [notifications, setNotifications] = useState<string[]>(preference || [])
	const [emailNotifications, setEmailNotifications] = useState<string[]>(
		email_preference || []
	)
	const validate = (value: string) => {
		const { error } = emailSchema.validate(value || emailValue)
		if (!error) return null
		return error.message
	}
	const handleEmailChange = (e: ConfigValueItem) => {
		setEmailValue(e)
		const errors = validate(e.value)
		setErrors(errors)
	}

	const handleChange = (
		e: boolean,
		name:
			| 'all'
			| 'newsletter'
			| 'service'
			| 'links'
			| 'subscription'
			| 'team'
			| 'protection'
			| 'reports'
	) => {
		if (name === 'all') {
			if (e) {
				// Set all notifications when 'all' is selected
				setNotifications([
					'all',
					'newsletter',
					'service',
					'links',
					'subscription',
					'team',
					'protection',
					'reports'
				])
			} else {
				setNotifications([])
			}
		} else {
			const form = notifications.filter((value) => value)
			if (!e) {
				const newForm = form.filter((value) => value !== name)
				setNotifications(newForm)
				if (newForm.length === 0 || newForm.includes('all')) {
					setNotifications(newForm.filter((value) => value !== 'all'))
				}
			} else {
				form.push(name)
				if (form.length === 7) {
					setNotifications([...form, 'all'])
				} else if (form.length === 8) {
					setNotifications(form.filter((value) => value !== 'all'))
				} else {
					setNotifications(form)
				}
			}
		}
	}
	const handleEmailNotificationChange = (
		e: boolean,
		name:
			| 'all'
			| 'newsletter'
			| 'service'
			| 'links'
			| 'subscription'
			| 'team'
			| 'protection'
			| 'reports'
	) => {
		if (name === 'all') {
			if (e) {
				// Set all notifications when 'all' is selected
				setEmailNotifications([
					'all',
					'newsletter',
					'service',
					'links',
					'subscription',
					'team',
					'protection',
					'reports'
				])
			} else {
				setEmailNotifications([])
			}
		} else {
			const form = emailNotifications.filter((value) => value)
			if (!e) {
				const newForm = form.filter((value) => value !== name)
				setEmailNotifications(newForm)
				if (newForm.length === 0 || newForm.includes('all')) {
					setEmailNotifications(newForm.filter((value) => value !== 'all'))
				}
			} else {
				form.push(name)
				if (form.length === 7) {
					setEmailNotifications([...form, 'all'])
				} else if (form.length === 8) {
					setEmailNotifications(form.filter((value) => value !== 'all'))
				} else {
					setEmailNotifications(form)
				}
			}
		}
	}

	const handleConfirm = async () => {
		const app_notification_values = notifications.filter(
			(value) => value !== 'all'
		)
		const email_notification_values = emailNotifications.filter(
			(value) => value !== 'all'
		)
		if (
			emailValue.value !== user?.contact_email &&
			emailValue?.value !== undefined
		) {
			await updateEmail({ email: emailValue.value })
		}
		if (
			app_notification_values.sort().join(',') !==
				user?.preferences?.notifications.sort().join(',') ||
			email_notification_values.sort().join(',') !==
				user?.preferences?.notifications.sort().join(',')
		) {
			await updateNotifications({
				app_notification_values,
				email_notification_values
			})
		}
	}

	useEffect(() => {
		const configEmailsArray: ConfigValueItem[] = []
		const verifiedEmail = user?.email_addresses.filter(
			(email) => email.email_verified
		)
		verifiedEmail?.forEach((item) =>
			configEmailsArray.push({ label: item.email, value: item.email })
		)
		setVerifiedEmails([...configEmailsArray])
	}, [user?.email_addresses])
	return (
		<div className="w-full">
			<Notices
				isInSetup={isInSetup()}
				isInActive={account?.status === 'INACTIVE'}
				setupStep={account?.setup?.step}
				hasDashboardPerformanceFeature={accountStore.hasFeature(
					'dashboard_performance'
				)}
				tc_installed={asset ? !!asset?.tc_installed : true}
				cc_installed={asset ? !!asset?.cc_installed : true}
				accountId={account?.id}
				assetId={asset?.id}
				demoMode={false}
				hideDemoDataNotice={true}
				mccLinkInviteAdAccounts={adAccounts}
			/>
			<PageHeader
				title={'Notifications'}
				subtitle={'Manage your notification preferences'}
			/>
			<div className="max-w-3xl px-4 sm:px-6 md:px-8">
				<GSection
					title="Notifications"
					lineBreak={true}
					lineBreakColor="gray-100"
					actions={
						<GButton
							label="Save"
							variant="contained"
							color="primary"
							labelClassName="font-bold text-base"
							className="h-[30px] w-[68px]"
							onClick={() => handleConfirm()}
							loading={isLoading('USER_SAVE_NOTIFICATIONS')}
						/>
					}
				>
					<div>
						<div className="flex justify-between mt-6 items-center">
							<p className="font-bold text-t-secondary text-base">
								Email address
							</p>
							<GComboBox
								className="w-[400px]"
								inputClass="text-base"
								value={emailValue}
								onChange={(e) => handleEmailChange(e as any)}
								filter={() => true}
								options={verifiedEmails}
								error={errors || ''}
								renderOption={(i) => i?.label ?? ''}
								renderValue={(i) => i?.label ?? ''}
							/>
						</div>
						<div className="flex justify-between mt-6">
							<div className="w-[600px]">
								<div className={'flex items-center justify-end pt-4'}>
									<div className="flex gap-10">
										<div className={'flex items-center gap-2'}>
											<BiBell className="w-4 h-4 text-t-default" />
											<p className="font-bold text-base">In app</p>
										</div>
										<div className={'flex items-center gap-2 mr-[-25px]'}>
											<HiOutlineMail className="w-4 h-4 text-t-default" />
											<p className="font-bold text-base">Email</p>
										</div>
									</div>
								</div>
								{Object.keys(notificationPreferences)?.map((key) => (
									<div
										className={'flex gap-2 items-center justify-between pt-4'}
										key={key}
									>
										{key === 'all' ? (
											<p className="text-t-secondary font-bold text-base w-[400px]">
												{notificationPreferences[key as notificationPrefType]}
											</p>
										) : (
											<p className="text-t-secondary font-normal text-base w-[400px]">
												{notificationPreferences[key as notificationPrefType]}
											</p>
										)}
										<GCheckbox
											name={key}
											checked={notifications.includes(key)}
											onChange={(v) =>
												handleChange(
													v.target.checked,
													key as notificationPrefType
												)
											}
										/>
										<GCheckbox
											name={key}
											checked={emailNotifications.includes(key)}
											onChange={(v) =>
												handleEmailNotificationChange(
													v.target.checked,
													key as notificationPrefType
												)
											}
										/>
									</div>
								))}
							</div>
						</div>
					</div>
				</GSection>
			</div>
		</div>
	)
}
