import { BingAccountLinkDTO, BingAdsAccountBasicInfoDTO } from 'api-models'
import {
	GButton,
	GListBox,
	GLoading,
	GToggle,
	GTooltip
} from 'components/basic-blocks'
import { GWindowedSelect } from 'components/basic-blocks/g-virtualized-select'
import { BingAuthConfig } from 'config'
import { useEffect, useState } from 'react'
import { RiArrowRightSLine, RiQuestionLine } from 'react-icons/ri'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { useAccountStore, useAssetStore, useUiStore } from 'store'
import { useShallow } from 'zustand/react/shallow'
import { addBingPPCAccountSchema } from '../validations'
import { BadsFormProps, BadsFormState } from './types'

export const BadsForm = (props: BadsFormProps) => {
	const { badsConnections, closeDialog } = props

	const [state, setState] = useState<BadsFormState>(() => ({
		platform: 'BADS',
		selectedLink: undefined,
		adAccount: undefined,
		errors: {},
		step: 'idle',
		set_custom_audience: true,
		set_tracking_template: true,
		tracking: true
	}))

	const isLoading = useUiStore((s) => s.isLoading)
	const { account, createOAuthLink } = useAccountStore(
		useShallow((s) => ({
			account: s.account,
			createOAuthLink: s.createOAuthLink
		}))
	)
	const { asset, createPPCBingAdsAccount } = useAssetStore(
		useShallow((s) => ({
			asset: s.asset,
			createPPCBingAdsAccount: s.createPPCBingAdsAccount
		}))
	)

	const handleAddPPCAccount = async () => {
		const errors = validate()
		setState({ ...state, errors: errors })
		if (errors) return

		if (state.selectedLink && state.adAccount) {
			const payload = {
				link_id: state.selectedLink?.id, // account link ID
				account_number: state.adAccount.account_number,
				asset_id: asset!.id,
				customer_id: state.selectedLink.external_id,
				ad_account_id: String(state.adAccount.id),
				set_tracking_template: state.set_tracking_template,
				set_custom_audience: state.set_custom_audience
			}
			try {
				await createPPCBingAdsAccount(account!.id, payload)
				closeDialog()
			} catch (e) {
				return
			}
		}
	}
	const validate = () => {
		const options = { abortEarly: false }
		const { error } = addBingPPCAccountSchema.validate(
			{
				selectedLink: state.selectedLink?.id,
				accountNumber: state.adAccount?.account_number
			},
			options
		)

		if (!error) return null

		const errors: Record<string, string> = {}

		error.details.forEach((item) => {
			errors[item.path[0]] = item.message
		})
		return errors
	}

	const handleSelectLink = (val: BingAccountLinkDTO) => {
		setState((prevState) => ({
			...prevState,
			selectedLink: val,
			adAccount: undefined
		}))
	}

	const handleAccountNumberChange = (adAccount: BingAdsAccountBasicInfoDTO) => {
		setState((prevState) => ({
			...prevState,
			adAccount
		}))
	}

	useEffect(() => {
		if (state.step === 'waiting') {
			const interval = setInterval(async () => {
				const code = getAuthCode()
				if (code) {
					clearInterval(interval)
					clearAuthCode()
					handleAddNewConnection(code)
				}
			}, 1000)
		}
	}, [state.step])

	const getAuthCode = () => {
		return window.localStorage.getItem('bing_oauth_code')
	}

	const clearAuthCode = () => {
		window.localStorage.removeItem('bing_oauth_code')
		setState({ ...state, step: 'idle' })
	}

	const handleAddNewConnection = async (code: string) => {
		try {
			await createOAuthLink(code, 'bing')
		} catch (e) {
			console.log(e)
		}
	}

	const handleAuthorize = () => {
		window.open(BingAuthConfig.AUTH_URL, '_blank')
		setState({ ...state, step: 'waiting' })
	}

	return (
		<>
			<div className="bg-gray-50 rounded-lg px-6 py-6 space-y-4 flex flex-col justify-center">
				<div className="pb-2 text-sm space-y-4">
					<span className="text-gray-400 font-normal">
						{'Select how ClickGUARD will access Bing Ads'}
					</span>
				</div>
				<div className="flex items-end">
					<div className="mt mr-5 w-64">
						{badsConnections ? (
							<GListBox<BingAccountLinkDTO>
								disabled={badsConnections.length === 0}
								label="Access"
								value={state?.selectedLink}
								options={badsConnections}
								placeholder={
									badsConnections.length > 0
										? 'Select Link'
										: 'No access links for Bing Ads'
								}
								onChange={(val) => handleSelectLink(val)}
								renderLabel={(list) => <>{list.email}</>}
								error={state.errors?.selectedLink}
							/>
						) : (
							<GLoading />
						)}
					</div>
					<GButton
						className="flex-start !py-[7.5px]"
						size="sm"
						color="neutral"
						variant="contained"
						type="text"
						label="New authorization"
						onClick={() => {
							handleAuthorize()
						}}
						loading={isLoading('ACCOUNT_ADD_ACCESS')}
					/>
				</div>

				<div className="my-4">
					{state?.selectedLink && (
						<div className="space-y-4">
							<div className="w-1/2">
								<GWindowedSelect
									label="Bing Ads account"
									options={[...(state?.selectedLink?.clients || [])]}
									value={state.adAccount || undefined}
									onChange={(newValue) =>
										handleAccountNumberChange(
											newValue as BingAdsAccountBasicInfoDTO
										)
									}
									placeholder="Select Bing Ads account"
									isOptionSelected={(option) =>
										(option as BingAdsAccountBasicInfoDTO).account_number ===
										state?.adAccount?.account_number
									}
									formatOptionLabel={(option) =>
										`${(option as BingAdsAccountBasicInfoDTO).name} -
											${(option as BingAdsAccountBasicInfoDTO).account_number}
										`
									}
									error={state.errors?.accountNumber}
									filterOption={(option, query) => {
										return query
											? (
													option as FilterOptionOption<BingAdsAccountBasicInfoDTO>
											  ).data.account_number
													.toLocaleLowerCase()
													.includes(query)
											: true
									}}
								/>
							</div>

							<>
								<div className="flex space-x-1 items-center">
									<GToggle
										labelClassName="text-t-default text-base"
										checked={state.set_tracking_template}
										onChange={(value) =>
											setState({
												...state,
												set_tracking_template: value
											})
										}
										label="Automatically configure click tracking"
									/>
									<GTooltip
										content={
											'Sets ClickGUARD tracking template on your Bing Ads account'
										}
									>
										<RiQuestionLine className="text-t-secondary w-3" />
									</GTooltip>
								</div>
								<div className="flex space-x-1 items-center">
									<GToggle
										labelClassName="text-t-default text-base"
										checked={state.set_custom_audience}
										onChange={(c) =>
											setState({
												...state,
												set_custom_audience: c
											})
										}
										label="Automatically set excluded audience"
									/>
									<GTooltip
										content={
											"Updates targeting on campaigns to exclude ClickGUARD's audience of blocked users"
										}
									>
										<RiQuestionLine className="text-t-secondary w-3" />
									</GTooltip>
								</div>
							</>
						</div>
					)}
				</div>
				<div className="flex justify-center pt-2">
					<GButton
						label={`Add ${state?.adAccount?.name || ''} ${
							state?.adAccount?.account_number || ''
						}`}
						color="primary"
						size="sm"
						className="px-8"
						icon={RiArrowRightSLine}
						iconPlacement="right"
						onClick={() => handleAddPPCAccount()}
						disabled={!state.adAccount}
						loading={isLoading('ASSET_ADD_ACCOUNT')}
					/>
				</div>
			</div>
		</>
	)
}
