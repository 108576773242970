import { GTooltip } from 'components/basic-blocks'
import { GMetricLine } from 'components/basic-blocks/g-metric-line/g-metric-line'
import { SimpleBarChart } from 'components/charts/simple-bar-chart'
import { RiQuestionLine } from 'react-icons/ri'
import { color } from 'utils/colors'
import { ActiveWorkspacesPanelProps } from './types'

export const ActiveWorkspacesPanel = ({
	data,
	percentages
}: ActiveWorkspacesPanelProps) => {
	return (
		<section className="bg-white px-4 py-4 text-t-default rounded min-w-[320px] h-fit">
			<div className="text-t-default text-md flex flex-row items-center gap-x-1 mb-4">
				<span className="font-bold">Active workspaces</span>
				<GTooltip
					content={
						<div className="flex flex-col">
							<span>
								Statistics about workspaces that were actively subscribed during
								the selected period
							</span>
						</div>
					}
				>
					<RiQuestionLine className="w-4 h-4 text-t-dark" />
				</GTooltip>
			</div>
			<div className="flex flex-col gap2">
				<GMetricLine
					title="Avg subscribed ad spend"
					value={data.avg_subscription_spend_formatted}
					rate={percentages.avg_sub_spend}
				/>
				<GMetricLine
					title="Avg detected ad spend"
					value={data.avg_detect_spend_formatted}
					rate={percentages.avg_detected_spend}
				/>
				<GMetricLine
					title="Limit breaches"
					value={data.limit_breaches_formatted}
					rate={percentages.limit_breaches}
				/>
				<GMetricLine
					title="Discounted subscriptions"
					value={data.discounted_subscription_formatted}
					rate={percentages.disconted_subs}
				/>
			</div>
			<div className="mt-4">
				<SimpleBarChart
					data={[
						{
							id: 'plan',
							Lite: data.lite_formatted,
							Standard: data.standard_formatted,
							Pro: data.pro_formatted,
							Legacy: data.legacy_formatted,
							Custom: data.custom_formatted
						}
					]}
					keys={['Lite', 'Standard', 'Pro', 'Legacy', 'Custom']}
					colors={[
						color('red', 500),
						color('green', 500),
						'#4269F5',
						color('amber', 500),
						color('purple', 500)
					]}
					format="number"
					title={
						<div className="text-t-default text-md flex flex-row items-center gap-x-1">
							<span className="font-bold">Subscription plans</span>
							<GTooltip
								content={
									<div className="flex flex-col">
										<span>Average daily plans</span>
									</div>
								}
							>
								<RiQuestionLine className="w-4 h-4 text-t-dark" />
							</GTooltip>
						</div>
					}
				>
					<div className="flex flex-col h-full mt-4">
						<GMetricLine
							title="Lite"
							bulletColor="bg-red-500"
							value={data.lite_formatted}
							rate={percentages.sub_lite}
						/>
						<GMetricLine
							title="Standard"
							bulletColor="bg-green-500"
							value={data.standard_formatted}
							rate={percentages.sub_standard}
						/>
						<GMetricLine
							title="Pro"
							bulletColor="bg-primary-400"
							value={data.pro_formatted}
							rate={percentages.sub_pro}
						/>
						<GMetricLine
							title="Legacy"
							bulletColor="bg-amber-500"
							value={data.legacy_formatted}
							rate={percentages.sub_legacy}
						/>
						<GMetricLine
							title="Custom"
							bulletColor="bg-purple-500"
							value={data.custom_formatted}
							rate={percentages.sub_custom}
						/>
					</div>
				</SimpleBarChart>
			</div>
		</section>
	)
}
