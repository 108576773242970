import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect'
import { useWindowResize } from 'hooks/useWindowResize'
import { useRef } from 'react'
import {
	AutoSizer,
	CellMeasurer,
	CellMeasurerCache,
	Index,
	IndexRange,
	InfiniteLoader,
	List
} from 'react-virtualized'

interface InfiniteLoaderListProps {
	list: any
	total: number
	pageSize: number
	children: (index: number) => any
	fetchData: (skip: number) => Promise<void>
}

export const InfiniteLoaderList = ({
	list,
	total,
	pageSize,
	children,
	fetchData
}: InfiniteLoaderListProps) => {
	const [windowWidth] = useWindowResize()
	const cache = useRef(
		new CellMeasurerCache({
			fixedWidth: false,
			defaultHeight: 50
		})
	)

	useDidUpdateEffect(() => {
		cache.current.clearAll()
	}, [windowWidth, list?.length])

	const isRowLoaded = ({ index }: Index) => {
		return !!list[index]
	}

	const loadMoreRows = async ({ startIndex }: IndexRange) => {
		if (list?.length < pageSize) return
		await fetchData(startIndex)
	}

	return (
		<>
			<AutoSizer>
				{({ width, height }) => (
					<InfiniteLoader
						isRowLoaded={isRowLoaded}
						loadMoreRows={loadMoreRows}
						rowCount={total}
					>
						{({ onRowsRendered, registerChild }) => (
							<List
								onRowsRendered={onRowsRendered}
								ref={registerChild}
								width={width}
								height={height}
								rowHeight={cache.current.rowHeight}
								deferredMeasurementCache={cache.current}
								rowCount={list?.length}
								rowRenderer={({ key, index, style, parent }) => {
									return (
										<CellMeasurer
											key={key}
											cache={cache.current}
											parent={parent}
											columnIndex={0}
											rowIndex={index}
										>
											<div style={style}>{children(index)}</div>
										</CellMeasurer>
									)
								}}
							/>
						)}
					</InfiniteLoader>
				)}
			</AutoSizer>
			<div className="invisible">
				{Array.from(Array(length).keys()).map((index) => (
					<div key={index}>{children(index)}</div>
				))}
			</div>
		</>
	)
}
