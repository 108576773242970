import { FacebookAdsAccountBasicInfoDTO } from 'api-models'
import { FacebookAccountLinkDTO } from 'api-models/src'
import {
	GButton,
	GListBox,
	GLoading,
	GToggle,
	GTooltip
} from 'components/basic-blocks'
import { GWindowedSelect } from 'components/basic-blocks/g-virtualized-select'
import { FacebookAuthConfig } from 'config'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { RiArrowRightSLine, RiQuestionLine } from 'react-icons/ri'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { useUiStore } from 'store'
import { removeFadPrefix } from 'utils'
import { addPPCAccountSchema } from '../validations'
import { FadsFormProps, FadsFormState } from './types'

export const FadsForm = (props: FadsFormProps) => {
	const { fadsConnections, closeDialog } = props

	const [state, setState] = useState<FadsFormState>({
		platform: 'FADS',
		selectedLink: undefined,
		accountNumber: undefined,
		accountName: '',
		errors: {},
		tracking: true,
		step: 'idle'
	})

	const { assetStore, accountStore } = useStores()
	const { account, createOAuthLink } = accountStore
	const { asset, createPPCFacebookAdsAccount } = assetStore
	const isLoading = useUiStore((s) => s.isLoading)

	const handleAddPPCAccount = async () => {
		const errors = validate()
		setState({ ...state, errors: errors })
		if (errors) return

		if (state.selectedLink && state.accountNumber) {
			const payload = {
				link_id: state.selectedLink?.id, // account link ID
				account_number: state.accountNumber,
				asset_id: asset!.id,
				set_custom_audience: state.tracking
			}
			try {
				await createPPCFacebookAdsAccount(account!.id, payload)
				closeDialog()
			} catch (e) {
				return
			}
		}
	}

	const validate = () => {
		const options = { abortEarly: false }
		const { error } = addPPCAccountSchema.validate(
			{
				selectedLink: state.selectedLink?.id,
				accountNumber: state.accountNumber
			},
			options
		)

		if (!error) return null

		const errors: Record<string, string> = {}

		error.details.forEach((item) => {
			errors[item.path[0]] = item.message
		})
		return errors
	}

	const handleMultiSelectChange = (val: FacebookAccountLinkDTO) => {
		setState({ ...state, selectedLink: val })
	}
	const handleAccountNumberChange = (val: FacebookAdsAccountBasicInfoDTO) => {
		setState({
			...state,
			accountName: val.name!,
			accountNumber: val.account_number
		})
	}

	useEffect(() => {
		if (state.step === 'waiting') {
			const interval = setInterval(async () => {
				const code = getAuthCode()
				if (code) {
					clearInterval(interval)
					clearAuthCode()
					facebookNewConnection(code)
				}
			}, 1000)
		}
	}, [state.step])

	const getAuthCode = () => {
		const code = window.localStorage.getItem('facebook_oauth_long_lived_token')
		return code
	}

	const clearAuthCode = () => {
		window.localStorage.removeItem('facebook_oauth_long_lived_token')
		setState({ ...state, step: 'idle' })
	}

	const facebookNewConnection = async (code: string) => {
		try {
			await createOAuthLink(code, 'facebook')
		} catch (e) {
			console.log(e)
		}
	}

	const handleAuthorize = () => {
		window.open(FacebookAuthConfig.AUTH_URL, '_blank')
		setState({ ...state, step: 'waiting' })
	}

	return (
		<>
			<div className="bg-gray-50 rounded-lg px-6 py-6 space-y-4 flex flex-col justify-center">
				<div className="pb-2 text-sm space-y-4">
					<span className="text-gray-400 font-normal">
						{'Select how ClickGUARD will access Meta Ads'}
					</span>
				</div>
				<div className="flex items-end">
					<div
						className={`mt-2 mr-5 ${fadsConnections.length == 0 ? 'min-w-max' : 'w-64'
							}`}
					>
						{fadsConnections ? (
							<GListBox<FacebookAccountLinkDTO>
								label="Access"
								value={state?.selectedLink}
								options={fadsConnections}
								disabled={fadsConnections.length === 0}
								placeholder={
									fadsConnections.length > 0
										? 'Select Link'
										: 'No access links for Meta Ads'
								}
								onChange={(val) => handleMultiSelectChange(val)}
								renderLabel={(list) => <>{list.email || 'No Email'}</>}
								error={state.errors?.selectedLink}
							/>
						) : (
							<GLoading />
						)}
					</div>
					<GButton
						className="flex-start !py-[7.5px]"
						size="sm"
						color="neutral"
						variant="contained"
						type="text"
						label="New authorization"
						onClick={() => handleAuthorize()}
						loading={isLoading('ACCOUNT_ADD_ACCESS')}
					/>
				</div>
				{console.log(state)}
				<div className="my-4">
					{state?.selectedLink && (
						<div className="space-y-4">
							<div className="w-1/2">
								<GWindowedSelect
									label="Meta Ads account"
									options={[...(state?.selectedLink?.clients || [])]}
									value={[...(state?.selectedLink?.clients || [])].filter(el => el.name === state.accountName) || undefined}
									onChange={(newValue) =>
										handleAccountNumberChange(
											newValue as FacebookAdsAccountBasicInfoDTO
										)
									}
									placeholder="Select your Meta Ads account"
									isOptionSelected={(option) =>
										(option as FacebookAdsAccountBasicInfoDTO)
											.account_number === state?.accountNumber
									}
									formatOptionLabel={(option) =>
										`${(option as FacebookAdsAccountBasicInfoDTO).name
										} - ${removeFadPrefix(
											(option as FacebookAdsAccountBasicInfoDTO).account_number
										)}`
									}
									error={state.errors?.accountNumber}
									filterOption={(option, query) => {
										return query
											? (
												option as FilterOptionOption<FacebookAdsAccountBasicInfoDTO>
											).data.account_number
												?.toLocaleLowerCase()
												.includes(query) ||
											(
												option as FilterOptionOption<FacebookAdsAccountBasicInfoDTO>
											).data.name
												?.toLocaleLowerCase()
												.includes(query)
											|| false
											: true
									}}
								/>
							</div>

							<div className="flex space-x-1 items-center">
								<GToggle
									checked={state.tracking}
									onChange={(v) => setState({ ...state, tracking: v })}
									label="Automatically set excluded audience in ad sets"
								/>
								<GTooltip
									content={
										"Updates targeting on ad sets to exclude ClickGUARD's audience of blocked users"
									}
								>
									<RiQuestionLine className="text-t-secondary w-3" />
								</GTooltip>
							</div>
						</div>
					)}
				</div>
				<div className="flex justify-center pt-2">
					<GButton
						// label={`Add ${fadAccountNumber(state) || ''}`}
						label={`Add ${state.platform === 'FADS'
							? state.accountName
							: state.accountNumber
							}`}
						color="primary"
						size="sm"
						className="px-8"
						icon={RiArrowRightSLine}
						iconPlacement="right"
						onClick={() => handleAddPPCAccount()}
						disabled={!state.accountNumber}
						loading={isLoading('ASSET_ADD_ACCOUNT')}
					/>
				</div>
			</div>
		</>
	)
}
