import { GButton, GTransition } from 'components/basic-blocks'
import { useStores } from 'hooks'

import { RiArrowGoBackLine } from 'react-icons/ri'

import { useNavigate } from 'react-router-dom'

import { useThemeStore } from 'layout/navbar/store'
import { removeFromLocalStorage } from 'utils'

export const Impersonation = () => {
	const navigate = useNavigate()
	const { userStore, adminStore } = useStores()

	const { theme } = useThemeStore()

	const stepOut = async () => {
		const id = userStore.user?.id
		await adminStore.clearImpersonation()
		removeFromLocalStorage(['selected_account', 'selected_asset'])
		navigate(`/admin/user/${id}`)
	}

	const BackIcon = () => {
		return <RiArrowGoBackLine className="h-3 w-3 mr-2" />
	}

	const LightButton = () => (
		<div className="bg-red-100 m-4 rounded px-4 py-2 flex space-x-2 text-sm items-center">
			<div className="mr-2">
				<GButton color="danger" size="xs" icon={BackIcon} onClick={stepOut}>
					Exit
				</GButton>
			</div>
			<div className="flex flex-col">
				<span className="text-sm font-light">Impersonating</span>
				<span className="text-sm font-bold truncate max-w-[200px]">
					{adminStore?.impersonation && userStore.user?.name}
				</span>
			</div>
		</div>
	)

	const DarkButton = () => (
		<div className="bg-gray-200 m-4 rounded px-4 py-2 flex space-x-2 text-sm items-center">
			<div className="mr-2">
				<GButton color="danger" size="xs" icon={BackIcon} onClick={stepOut}>
					Exit
				</GButton>
			</div>
			<div className="flex flex-col text-t-default">
				<span className="text-sm font-light">Impersonating</span>
				<span className="text-sm font-bold truncate max-w-[200px]">
					{adminStore?.impersonation && userStore.user?.name}
				</span>
			</div>
		</div>
	)

	return (
		<GTransition show={adminStore?.impersonation}>
			{theme === 'dark' ? <DarkButton /> : <LightButton />}
		</GTransition>
	)
}
