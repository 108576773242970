import { RiArrowRightDownLine, RiArrowRightUpLine } from 'react-icons/ri'

import { GBadge } from '../g-badge'
import { GRateBadgeProps } from './types'

export const GRateBadged = ({ rate }: GRateBadgeProps) => {
	const color = rate < 0 ? 'red' : 'green'
	const icon = rate < 0 ? RiArrowRightDownLine : RiArrowRightUpLine
	const formattedRate = `${String(rate).replace('-', '')}%`

	return <GBadge text={formattedRate} color={color} icon={icon} />
}
