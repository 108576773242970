import { AccountLinkDTO, BingAccountLinkDTO } from 'api-models'
import { GButton, GSection, GTooltip } from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'
import { PlatformIcon } from 'components/platform-icon'
import { Dispatch } from 'react'
import { ZendeskService } from 'services/zendesk'
import { useAccountStore } from 'store'
import { AdPlatforms } from 'utils/platform'
import { ConfigurePpcAccess } from './configure-ppc-access'

export const BingAdTabs = (props: {
	links: AccountLinkDTO[]
	isAnalyst: boolean
	setModalNewConnection: Dispatch<{
		platform: AdPlatforms
		MCC: 'oauth' | 'mcc-account'
		active: boolean
	}>
}) => {
	const { links, isAnalyst, setModalNewConnection } = props

	const wantsToSeeDemoData = useAccountStore((s) => s.wantsToSeeDemoData)

	const bingConnections = links.filter(
		(link) => link.type === 'oauth'
	) as BingAccountLinkDTO[]

	return (
		<div className="mt-8">
			<GSection
				title={'Bing Ads'}
				titleClass={bingConnections?.length > 0 ? 'mb-6' : 'mb-0'}
				lineBreak={bingConnections.length > 0}
				lineBreakColor="gray-100"
				actions={
					<GTooltip
						content={
							wantsToSeeDemoData
								? 'This action is disabled when demo data is displayed'
								: isAnalyst
								? "Analysts don't have permission for this action"
								: undefined
						}
					>
						<GButton
							variant="text"
							labelClassName="font-bold"
							color="primary"
							size="md"
							className="h-[30px] border-t-input-border"
							disabled={wantsToSeeDemoData || isAnalyst}
							label="New OAuth link"
							onClick={() =>
								setModalNewConnection({
									platform: 'BADS',
									active: true,
									MCC: 'oauth'
								})
							}
						/>
					</GTooltip>
				}
			>
				{bingConnections.length > 0 &&
					bingConnections.map((connection, index) => {
						return (
							<BingConnectingTemplate
								link={connection}
								key={index}
								isAnalyst={isAnalyst}
							/>
						)
					})}
			</GSection>
		</div>
	)
}

const BingConnectingTemplate = (props: {
	link: BingAccountLinkDTO
	isAnalyst: boolean
}) => {
	const { link, isAnalyst } = props

	const color = link.status === 'UNLINKED' ? 'amber' : 'green'

	return (
		<div className="flex justify-between items-center mt-6">
			<div className="flex justify-start items-center space-x-4">
				<PlatformIcon platform="BADS" className="w-6 h-6" />
				<div className="flex flex-col justify-start items-start ">
					<div className="text-sm leading-5 text-t-secondary">Bing OAuth</div>
					<div className="text-md text-t-default">{link.email}</div>
				</div>
			</div>
			<div className="flex justify-end items-end gap-4">
				<GBadge
					className="h-[28px] py-0.5 px-3"
					color={color}
					text={<span className="font-bold px-1 font-sm">{link.status}</span>}
				/>
				<ConfigurePpcAccess link={link} platform={'BADS'} isAnalyst={isAnalyst}>
					{(open) => (
						<GTooltip
							content={
								isAnalyst
									? "Analysts don't have permission for this action"
									: undefined
							}
						>
							<GButton
								variant="text"
								label="Configure"
								size="lg"
								color="buttonPrimary"
								className="h-[28px] w-[84px] disabled:bg-gray-200 disabled:text-t-secondary border-t-input-border"
								labelClassName="font-bold text-sm"
								disabled={isAnalyst}
								onClick={() => {
									open()
									ZendeskService.hide()
								}}
							/>
						</GTooltip>
					)}
				</ConfigurePpcAccess>
			</div>
		</div>
	)
}
