import {
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuSubContent
} from '@radix-ui/react-dropdown-menu'
import { OptionSubContentProps } from '../../../context'
import { GRadio } from '../../../../../../../components/basic-blocks/g-radio'

const options = [
	{ value: 'CANCELLED', label: 'Cancelled' },
	{ value: 'CANCELLING', label: 'Cancelling' },
	{ value: 'TRIAL', label: 'Trial' },
	{ value: 'ACTIVE', label: 'Active' },
	{ value: 'SIGNUP', label: 'Signup' }
]
const key = 'status'

export const StatusOption = {
	key,
	label: 'Status',
	subContent: ({ handleSubItemChange, mapState }: OptionSubContentProps) => (
		<DropdownMenuPortal>
			<DropdownMenuSubContent
				className="bg-white rounded border border-gray-200 p-2"
				sideOffset={20}
			>
				{options.map((o) => (
					<DropdownMenuItem key={o.value} asChild={true}>
						<GRadio
							name={o.label}
							value={o.value}
							label={o.label}
							checked={mapState.get(key) === o.value}
							onChange={(e) =>
								handleSubItemChange({ event: e, key, value: o.value })
							}
							className="p-1"
						/>
					</DropdownMenuItem>
				))}
			</DropdownMenuSubContent>
		</DropdownMenuPortal>
	)
}
