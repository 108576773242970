import { ProductAccountDTO } from 'api-models'
import { UserAvatar } from 'components'
import { GButton, GTooltip } from 'components/basic-blocks'
import { GTableColumn } from 'components/basic-blocks/g-table/types'
import { PlanNames } from 'config'
import { useStores } from 'hooks'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { RiCloseLine, RiLoginCircleLine, RiQuestionLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { formatSpend, planStepText, sanitizeURL } from 'utils'
import { planTierColorMapper } from 'utils/icons'
import { GDateTime } from 'components/basic-blocks/g-datetime'
import { GBadge } from 'components/basic-blocks/g-badge'

export const columns: Array<GTableColumn<ProductAccountDTO>> = [
	{
		label: '',
		render: (row: ProductAccountDTO) => <WorkspaceColumn row={row} />,
		className: 'justify-start'
	},
	{
		label: 'User',
		render: (row: ProductAccountDTO) => <OwnerColumn account={row} />,
		expand: true,
		width: '300px',
		className: 'justify-center'
	},
	{
		label: 'Step',
		render: (row: ProductAccountDTO) => <StepColumn account={row} />,
		className: 'justify-center',
		width: '200px'
	},
	{
		label: 'Domain',
		render: (row: ProductAccountDTO) => <DomainColumn account={row} />,
		className: 'justify-center',
		width: '200px'
	},
	{
		label: 'Info',
		render: (row: ProductAccountDTO) => <Notices account={row} />,
		className: 'justify-center'
	},
	{
		label: 'Start Date',
		path: 'created_at',
		render: (row: ProductAccountDTO) => <CreatedColumn account={row} />,
		sortKey: 'created_at',
		width: '150px',
		className: 'justify-center'
	}
]

const WorkspaceColumn = (props: { row: ProductAccountDTO }) => {
	const navigate = useNavigate()
	const link = `/admin/subscription/${props.row.id}`
	const planName = PlanNames[props.row?.billing?.subscription?.plan.tier || '']
	const planIcon = planTierColorMapper(
		props.row.billing?.subscription?.plan.tier,
		3
	)
	const trialPeriod = props.row?.billing?.subscription?.type === 'TRIAL'

	return (
		<div className="flex justify-center items-center space-x-2">
			<>
				{planIcon}
				<div className="flex flex-col justify-center">
					<div
						className="font-bold text-lg text-primary-500 hover:text-primary-600 cursor-pointer truncate max-w-[300px]"
						onClick={() => navigate(link)}
					>
						{sanitizeURL(props.row.name)}
					</div>
					<div className="text-md text-gray-500">{planName || ''}</div>
				</div>
				{trialPeriod && (
					<GBadge
						className="rounded-md bg-badge-selected align-center"
						text={
							<div className="w-full px-2 text-badge-text-t-default text-center">
								Trial
							</div>
						}
						color="[#e0e7ff]"
					/>
				)}
			</>
		</div>
	)
}
const OwnerColumn = (props: { account: ProductAccountDTO }) => {
	const navigate = useNavigate()
	const { account } = props
	const { adminStore } = useStores()
	const [loading, setLoading] = useState(false)

	const link = `/admin/user/${account.user_info?.user_id}`
	const name =
		account.status === 'SETUP' ? account.user_info?.name : account.billing?.name
	const email =
		account.status === 'SETUP'
			? account.user_info?.email
			: account.billing?.email

	const impersonate = async () => {
		setLoading(true)
		try {
			await adminStore.setImpersonation(account.user_info?.user_id ?? '')
			setLoading(false)
			navigate('/')
		} catch (e) {
			setLoading(false)
		}
	}

	useEffect(() => {
		return () => {
			setLoading(false)
		}
	}, [])

	const getUserAccessInfo = (user: any) => {
		return user?.access_level === 'READ'
			? 'Analyst'
			: user?.access_level === 'WRITE'
			? 'Manager'
			: user?.access_level === 'OWNER'
			? 'Owner'
			: 'Other'
	}

	const getUserBadgeColor = (user: any) => {
		return user?.access_level === 'READ'
			? 'green'
			: user?.access_level === 'WRITE'
			? 'purple'
			: user?.access_level === 'OWNER'
			? 'primary'
			: 'primary'
	}

	return (
		<div className="flex space-x-2 items-center">
			<GTooltip
				persist
				content={
					<div className="w-72 flex flex-col">
						{account?.user_access?.map((user: any, index: number) => (
							<div
								key={index}
								className="w-full flex justify-between items-center py-2"
							>
								<div className="w-full flex justify-start items-center">
									<div className="mr-2">
										<GButton
											shape="square"
											type="icon"
											loading={loading}
											icon={RiLoginCircleLine}
											variant="contained"
											color="primary"
											size="xs"
											onClick={impersonate}
										>
											Impersonate
										</GButton>
									</div>
									<div className="flex space-x-2 items-center">
										<UserAvatar size={8} src={user?.avatar} />
										<div
											className="flex flex-col group cursor-pointer"
											onClick={() => navigate(link)}
										>
											<div className="font-semibold text-primary-500 group-hover:text-primary-600">
												{name}
											</div>
											<div className="text-gray-500">{email}</div>
										</div>
									</div>
								</div>
								<GBadge
									className="rounded-md bg-badge-selected items-center"
									text={getUserAccessInfo(user)}
									color={getUserBadgeColor(user)}
								/>
							</div>
						))}
					</div>
				}
			>
				<div className="flex -space-x-4">
					{account?.user_access?.map((user: any, index: number) => (
						<div className="flex space-x-2 items-center" key={index}>
							<UserAvatar size={8} src={user?.avatar} />
						</div>
					))}
				</div>
			</GTooltip>
		</div>
	)
}

const CreatedColumn = (props: { account: ProductAccountDTO }) => {
	const { account } = props

	return (
		<div className="flex flex-col">
			<div className="font-bold">
				<GDateTime date={account.created_at} />
			</div>
			<div className="font-thin">
				{moment(account.created_at).format('MMMM Do YYYY - h:mm a')}
			</div>
		</div>
	)
}

const StepColumn = (props: { account: ProductAccountDTO }) => {
	const { account } = props
	const custom = account.billing?.subscription?.plan.tier === 'custom_pro'
	const limitBreached = account.limit_breached
	const cancelReq = account.cancel_request
	const offerReq = account.offer_request
	const setup = account.status === 'SETUP'

	const hasNotice = custom || limitBreached || cancelReq || offerReq || setup

	return (
		<div className="flex flex-col items-center space-x-2">
			<GBadge
				text={<span>{planStepText(account.setup?.step)}</span>}
				color="amber"
			/>
			{!hasNotice && !setup && (
				<div className="flex items-center w-full">
					<RiCloseLine className="h-5 w-5 mr-2 text-t-secondary" />
				</div>
			)}
		</div>
	)
}

const DomainColumn = (props: { account: ProductAccountDTO }) => {
	const { account } = props

	const domain = account.setup?.domain
	const domainName = domain?.split('://')[1]

	return (
		<div className="flex flex-col items-center space-x-2">
			{domainName ? (
				<GBadge text={<span>{domainName}</span>} color="gray" />
			) : (
				<GBadge text={<span>{'N/A'}</span>} color="gray" />
			)}
		</div>
	)
}

const Notices = (props: { account: ProductAccountDTO }) => {
	const { account } = props
	const sector = account.setup?.sector
	const industry = account.setup?.industry
	const platforms =
		account.setup?.platforms?.length !== 0
			? account.setup?.platforms?.join()
			: ''
	const adSpend = account.setup?.spend && formatSpend(account.setup?.spend)

	const toolTipContent = (
		<div className="flex flex-col gap-1">
			<div>
				{' '}
				Industry: {sector && `${sector},`} {industry && industry}{' '}
				{!sector && !industry && 'N/A'}
			</div>
			<div> Platforms: {platforms || 'N/A'}</div>
			<div> Ad spend: {adSpend || 'N/A'}</div>
		</div>
	)

	return (
		<div className="flex flex-col items-center space-x-2">
			<GTooltip content={toolTipContent}>
				<RiQuestionLine className="w-6 h-6 text-gray-500" />
			</GTooltip>
		</div>
	)
}
