import { AssetDTO, ProductAccountDTO } from 'api-models'
import { GSection } from 'components/basic-blocks'
import { GBadge } from 'components/basic-blocks/g-badge'
import { InfoItemGroup } from 'components/basic-blocks/g-info-table'
import { useStores } from 'hooks'
import { useEffect, useState } from 'react'
import { RiCloseLine, RiEarthLine, RiShareBoxFill } from 'react-icons/ri'
import { NavLink } from 'react-router-dom'
import { useUiStore } from 'store'
import { isValidUrl } from 'utils'

export const WebsitesSection = (props: { account: ProductAccountDTO }) => {
	const { account } = props
	const { adminStore } = useStores()
	const isLoading = useUiStore((s) => s.isLoading)
	const { getWebsites } = adminStore
	const [websites, setWebsites] = useState<AssetDTO[]>([])

	useEffect(() => {
		;(async () => {
			try {
				const data = await getWebsites({ account_id: account.id })
				data && setWebsites(data.data)
			} catch (e) {
				return
			}
		})()
	}, [account])

	return (
		<GSection loading={isLoading('ADMIN_GET_CAMPAIGNS')}>
			<InfoItemGroup title="Websites" className="-mt-5 border-t-0 rounded-t-lg">
				<WebsitesList websites={websites} />
			</InfoItemGroup>
		</GSection>
	)
}

const WebsitesList = (props: { websites: AssetDTO[] }) => {
	const { websites } = props
	if (websites.length === 0)
		return (
			<div className="py-4 flex w-full items-center justify-center -mb-5 text-t-secondary">
				No websites available
			</div>
		)
	return (
		<div className="-mb-5 divide-y max-h-72 overflow-auto">
			{websites.map((website) => {
				return <WebsiteItem website={website} key={website.id} />
			})}
		</div>
	)
}

const WebsiteItem = (props: { website: AssetDTO }) => {
	const { website } = props
	const link = `/admin/website/${website.id}`
	const missingTC = !website.tc_installed
	const missingCC = !website.cc_installed
	const hasNotice = missingTC || missingCC

	// const color= website.status==='ENABLED'?'border-green-500':website.status==='PAUSED'?'border-red-500':'border-gray-500'
	return (
		<div className="flex items-center justify-between gap-4 px-6 py-4">
			<div className="flex flex-row items-center gap-2">
				<NavLink to={link}>
					<div className="flex items-center space-x-4 cursor-pointer">
						<RiEarthLine
							className={'w-6 h-6 '}
							style={{ color: website.color || '#9c73d2' }}
						/>
						<div className="font-medium text-primary-500 hover:text-primary-600 cursor-pointer truncate max-w-[300px]">
							{website.name || 'N/A'}
						</div>
					</div>
				</NavLink>
				{isValidUrl(website.name) && (
					<a
						href={`//${website.name}`}
						target="_blank"
						className="text-primary-500"
					>
						<RiShareBoxFill />
					</a>
				)}
			</div>

			<div className="flex flex-col gap-2 justify-center items-center">
				{missingTC && (
					<div className="w-fit">
						<GBadge text={'Missing TC'} color="red" />
					</div>
				)}
				{missingCC && (
					<div className="w-fit">
						<GBadge text={'Missing CC'} color="amber" />
					</div>
				)}
				{!hasNotice && (
					<RiCloseLine className="h-5 w-5 mr-2 text-t-secondary" />
				)}
			</div>
		</div>
	)
}
