import { RiQuestionLine } from 'react-icons/ri'
import { twMerge } from 'tailwind-merge'
import { GTooltip } from '../g-tooltip'
import { GCheckboxProps } from './types'

export const GCheckbox = (props: GCheckboxProps) => {
	const {
		name,
		onChange,
		checked,
		label,
		description,
		disabled = false,
		helpText,
		className = '',
		checkboxClassName = '',
		labelClassName = ''
	} = props
	return (
		<div className={twMerge('relative flex items-start', className)}>
			<div className="flex items-center h-5 ">
				<input
					id={name}
					aria-describedby={`${name}-description`}
					name={name}
					type="checkbox"
					checked={checked}
					disabled={disabled}
					onChange={(e) => onChange(e)}
					className={twMerge(
						'rounded cursor-pointer h-4 w-4 text-white border-2 border-[#0D3EF2] bg-transparent checked:border-[#0D3EF2] focus:ring-white checked:focus:border-[#0D3EF2]',
						disabled
							? 'checked:border-[#69707D] checked:hover:border-2 checked:hover:border-[#69707D] checked:focus:border-[#69707D]'
							: '',
						checkboxClassName
					)}
				/>
			</div>
			<div className="ml-3 text-base">
				{label && (
					<label
						htmlFor={name}
						className={twMerge(
							'text-md text-t-default cursor-pointer',
							disabled && 'opacity-50',
							labelClassName
						)}
					>
						{label}
					</label>
				)}
				{description && (
					<p id={`${name}-description`} className="text-gray-500 text-sm">
						{description}
					</p>
				)}
			</div>
			{helpText && (
				<GTooltip content={helpText}>
					<RiQuestionLine className="ml-2 h-4 w-4 text-gray-500" />
				</GTooltip>
			)}
		</div>
	)
}
