import { ReportDTO, ReportRequestDTO, ReportRequestPeriodDTO } from 'api-models'
import { capitalizeFirstLetter } from '../../../../utils/text'

export interface OnDemandReportViewProps {
	type: reportType
}

export type reportType =
	| 'CAMPAIGN'
	| 'PLACEMENT'
	| 'KEYWORD'
	| 'DOMAIN'
	| 'TRAFFIC'
	| 'ENGAGEMENT'

export interface IRunReportsProps {
	open: boolean
	onClose: VoidFunction
	type: reportType
}

export enum ReportMethod {
	AUTOMATED = 'AUTOMATED',
	ON_DEMAND = 'ON_DEMAND'
}

export enum ReportScopeType {
	DOMAIN = 'DOMAIN',
	PPC = 'PPC'
}

export interface IScheduleReportsProps {
	open: boolean
	onClose: VoidFunction
	type: reportType
	isConfigure?: boolean
	reportRequest?: ReportRequestDTO
}

export interface IRunReportState {
	scope_type: { id: string; name: string; icon: string; type: ReportScopeType }
	scope: Array<{ id: string; name: string; icon?: string }>
	period: { label?: string; value: { from: Date; to: Date } }
	label: string
}

export interface IScheduleReportState {
	scope_type: { id: string; name: string; icon: string; type: ReportScopeType }
	scope: Array<{ id: string; name: string; icon?: string }>
	period: { label?: string; value: ReportRequestPeriodDTO }
	label: string
	scheduled_for: Date
}

export const generateReportTitle = (report: ReportDTO | ReportRequestDTO) => {
	if (!report || !report.type) return ''

	if (report.type === 'DOMAIN') {
		return report.scope_type?.type === 'PPC'
			? 'PPC account summary'
			: 'Website summary'
	}

	return capitalizeFirstLetter(report.type) + ' report'
}
