import {
	AdAccountDTO,
	AssetDTO,
	BlockDTO,
	FacebookAdsCampaignDTO,
	FormattedReportDTO,
	GoogleAdsAdAccountDTO,
	GoogleAdsCampaignDTO,
	ProductAccountDTO,
	ProtectionInfoDTO,
	ReportDTO,
	ReportRequestDTO,
	ReportRequestPeriodDTO
} from 'api-models'
import { create } from 'zustand'

import { GtmTagDto } from 'api-models/src/advertiser/gtm'
import { features } from 'config/features'
import { AdAccounts } from 'services/user'
import { Assets } from 'services/user/assets'
import { Reports } from 'services/user/reports'
import { AllAccountsAssets } from 'services/user/types'
import { useUiStore } from 'store/ui-store'
import { getPermissionTier } from 'utils'
import { parseError } from 'utils/errors'
import {
	ReportMethod,
	ReportScopeType,
	reportType
} from '../../pages/workspace/asset/reports/types'
import { GtmService } from '../../services/user/gtm'

interface AssetStoreState {
	asset?: AssetDTO | null
	allAssets: AssetDTO[]
	accountsWithAssets: AllAccountsAssets[]
	reportRequests: ReportRequestDTO[]
	recentReports: ReportDTO[]
	reports: FormattedReportDTO[]
	demoMode: boolean
	blocksData: BlockDTO[]
	totalBlocksData: any | number
	gtmTag: GtmTagDto | null
	adAccounts: AdAccountDTO[]
	accountAdAccounts: AdAccountDTO[]
	campaigns: (GoogleAdsCampaignDTO | FacebookAdsCampaignDTO)[]
	// Asset actions methods
	setAsset: (val: AssetDTO | null) => void
	setDemoMode: (val: boolean) => void
	setGtmTag: (val: GtmTagDto | null) => void
	setAllAssets: (val: AssetDTO[]) => void
	setAccountsWithAssets: (val: AllAccountsAssets[]) => void
	setAdAccounts: (val: AdAccountDTO[]) => void
	setAccountAdAccounts: (val: AdAccountDTO[]) => void
	setCampaigns: (val: (GoogleAdsCampaignDTO | FacebookAdsCampaignDTO)[]) => void
	setAssetForId: (
		accountId: string,
		id: string
	) => Promise<AssetDTO | undefined>
	getAllAssets: () => Promise<AllAccountsAssets[] | undefined>
	clearAssets: () => void
	clearAsset: () => void
	refreshAdAccounts: () => Promise<void>
	refreshCampaigns: (accountId: string) => Promise<void>
	canAddAsset: (account?: ProductAccountDTO | null) => boolean
	canAddAdAccount: (account?: ProductAccountDTO | null) => boolean
	createAsset: (
		accountId: string,
		payload: {
			name: string
			link_id: string
			account_number: string
		}
	) => Promise<AssetDTO | null>
	deleteAsset: (accountId: string, assetId: string) => Promise<void>
	// Account Report actions
	getReports: (
		productAccountId: string,
		assetId: string,
		type: reportType | null,
		adAccountId?: null | string,
		campaignId?: null | string,
		search?: null | string
	) => Promise<void>
	getReportRequests: (
		productAccountId: string,
		assetId: string,
		method: ReportMethod
	) => Promise<ReportRequestDTO[]>
	getRecentReports: (productAccountId: string, assetId: string) => Promise<void>
	setReports: (reports: FormattedReportDTO[]) => void
	setRecentReport: (reports: ReportDTO[]) => void
	setReportRequests: (reports: ReportRequestDTO[]) => void
	toggleAutomatedReports: (
		productAccountId: string,
		assetId: string,
		reportRequestID: string,
		value: boolean
	) => Promise<void>
	editReport: (
		productAccountId: string,
		assetId: string,
		reportRequestID: string,
		payload: {
			scope_type: { id: string; type: ReportScopeType }
			scope: Array<string> | null
			period: ReportRequestPeriodDTO
			scheduled_for: number
			label: string
		}
	) => Promise<void>
	runReport: (
		productAccountId: string,
		assetId: string,
		payload: {
			type: reportType
			scope: Array<string> | null
			scope_type: { id: string; type: ReportScopeType }
			from: number
			to: number
			label: string | null
			trafficType: string
			query?: Record<string, string> | null
		}
	) => Promise<void>
	runAutoReport: (
		productAccountId: string,
		assetId: string,
		payload: {
			type: reportType
			scope: Array<string> | null
			scope_type: { id: string; type: ReportScopeType }
			label: string | null
			scheduled_for: number
			period: ReportRequestPeriodDTO
		}
	) => Promise<void>
	deleteReportRequest: (
		productAccountId: string,
		assetId: string,
		requestId: string
	) => Promise<void>
	updateAssetSettings: (
		productAccountId: string,
		assetId: string,
		payload: {
			name: string
			timezone: string
			currency: string
			color: string
			description: string
		}
	) => Promise<void>
	// Account Protection actions
	setAccountProtection: (
		accountId: string,
		assetId: string,
		payload: ProtectionInfoDTO
	) => Promise<void>
	updateSettingsAccountProtection: (
		accountId: string,
		adAccountId: string,
		payload: { active: boolean }
	) => Promise<void>
	synchronizeAdAccount: (
		accountId: string,
		adAccountId: string,
		platform: string
	) => Promise<void>
	updateGadsAdAccountSettings: (
		accountId: string,
		adAccountId: string,
		payload: {
			name: string
			color: string
			currency: string
			timezone: string
			ignored_campaigns: string[]
			click_tracking: string
		}
	) => Promise<void>
	updateFadsAdAccountSettings: (
		accountId: string,
		adAccountId: string,
		payload: {
			name: string
			color: string
			currency: string
			timezone: string
			set_custom_audience: boolean
		}
	) => Promise<void>
	updateBadsAdAccountSettings: (
		accountId: string,
		adAccountId: string,
		payload: {
			name: string
			color: string
			currency: string
			timezone: string
			set_custom_audience: boolean
		}
	) => Promise<void>
	deleteAdAccountAccount: (
		accountId: string,
		adAccountId: string
	) => Promise<void>
	updateGadsSettingsAccessLink: (
		accountId: string,
		adAccountId: string,
		payload: { link_id: string }
	) => Promise<void>
	updateFadsSettingsAccessLink: (
		accountId: string,
		adAccountId: string,
		payload: { link_id: string }
	) => Promise<void>
	updateBadsSettingsAccessLink: (
		accountId: string,
		adAccountId: string,
		payload: { link_id: string }
	) => Promise<void>
	updateTrackingToggles: (
		accountId: string,
		adAccountId: string,
		payload: {
			set_tracking_template: boolean
			automated_tracking: boolean
		}
	) => Promise<void>
	setAdAccountProtection: (
		accountId: string,
		adAccountId: string,
		payload: ProtectionInfoDTO
	) => Promise<void>
	setCampaignProtection: (
		accountId: string,
		adAccountId: string,
		campaignId: string,
		payload: ProtectionInfoDTO,
		platform?: AdAccountDTO['platform']
	) => Promise<void>
	// site actions
	sendMccLinkRequest: (accountId: string, adAccountId: string) => Promise<void>
	skipMccSupport: (accountId: string, adAccountId: string) => Promise<void>
	skipMccSupportAll: (accountId: string) => Promise<void>
	sendMccLinkRequests: (accountId: string) => Promise<void>
	suppressMccSupportAll: (accountId: string) => Promise<void>
	// site actions
	createPPCGoogleAdsAccount: (
		accountId: string,
		payload: {
			asset_id: string
			link_id: string // account link ID
			account_number: string
			set_tracking_template: boolean
		}
	) => Promise<GoogleAdsAdAccountDTO>
	createPPCFacebookAdsAccount: (
		accountId: string,
		payload: {
			asset_id: string
			link_id: string // account link ID
			account_number: string
			set_custom_audience: boolean
		}
	) => Promise<void>
	createPPCBingAdsAccount: (
		accountId: string,
		payload: {
			asset_id: string
			link_id: string // account link ID
			account_number: string
			customer_id: string
			ad_account_id: string
		}
	) => Promise<void>
	updateAssetPhoto: (
		formData: Partial<FormData>,
		accountId: string
	) => Promise<void>
	getGtmAccounts: (accountId: string, assetId: string, linkId: string) => any
	getGtmContainers: (
		accountId: string,
		assetId: string,
		gtmAccountId: string,
		linkId: string
	) => any
	getGtmWorkspaces: (
		accountId: string,
		assetId: string,
		gtmAccountId: string,
		gtmContainerId: string,
		linkId: string
	) => any
	getGtmTag: (accountId: string, assetId: string) => any
	createGtmTag: (
		accountId: string,
		assetId: string,
		gtmAccountId: string,
		gtmContainerId: string,
		gtmWorkspaceId: string,
		linkId: string
	) => any
}

export const useAssetStore = create<AssetStoreState>((set, get) => ({
	asset: undefined,
	allAssets: [],
	accountsWithAssets: [],

	reportRequests: [],
	reports: [],
	recentReports: [],

	demoMode: false,

	blocksData: [],
	totalBlocksData: 0,
	gtmTag: null,

	adAccounts: [],
	accountAdAccounts: [],

	campaigns: [],

	setAsset: (val: AssetDTO | null) => {
		set({ asset: val })
		get().refreshAdAccounts()
	},

	setDemoMode: (val: boolean) => {
		set({ demoMode: val })
	},

	setGtmTag: (val: GtmTagDto | null) => {
		set({ gtmTag: val })
	},

	setAllAssets: (val: AssetDTO[]) => {
		set({ allAssets: val })
	},

	setAccountsWithAssets: (val: AllAccountsAssets[]) => {
		set({ accountsWithAssets: val })
	},

	setAdAccounts: (val: AdAccountDTO[]) => {
		set({ adAccounts: val })
	},

	setRecentReport: (reports: ReportDTO[]) => {
		set({ recentReports: reports })
	},

	setAccountAdAccounts: (val: AdAccountDTO[]) => {
		set({ accountAdAccounts: val })
	},

	setCampaigns: (val: (GoogleAdsCampaignDTO | FacebookAdsCampaignDTO)[]) => {
		set({ campaigns: val })
	},

	setAssetForId: async (accountId: string, id: string) => {
		const isDemo = id === 'website-demo'
		try {
			useUiStore.getState().setActionState('ACCOUNT_GET_ASSET', 'loading')
			const val = await Assets.getById(accountId, id)
			set({ asset: val })
			get().setDemoMode(isDemo)
			const hrefAccountId = window.location.href
				.split('/')
				.find((a) => a.includes('acc'))
			const localAccountId = window.localStorage.getItem('selected_account')
			const currentAccountId =
				accountId !== 'workspace-demo'
					? accountId
					: hrefAccountId || localAccountId || accountId

			const currentAsset =
				accountId === 'workspace-demo'
					? get().accountsWithAssets.find((a) => a.id === currentAccountId)
							?.assets?.[0] ?? null
					: val
			const adAccounts = await AdAccounts.find(currentAccountId, {
				asset_id: currentAsset?.id || 'website-demo'
			})
			get().setAdAccounts(adAccounts)

			if (!isDemo) {
				const campaigns = await Promise.all(
					adAccounts.map(async (adAccount) => {
						if (adAccount.platform === 'GADS') {
							return await AdAccounts.getGoogleCampaigns(
								accountId,
								adAccount.id
							)
						}
						if (adAccount.platform === 'FADS') {
							return await AdAccounts.getFacebookCampaigns(
								accountId,
								adAccount.id
							)
						} else {
							return await AdAccounts.getBingCampaigns(accountId, adAccount.id)
						}
					})
				)
				get().setCampaigns(campaigns.flat().concat())
			}

			useUiStore.getState().setActionState('ACCOUNT_GET_ASSET', 'idle')
			return val
		} catch (error: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_GET_ASSET',
					'error',
					error?.response?.data?.message
						? parseError(error)
						: 'Website not found'
				)
			console.log(error)
		}
	},

	getAllAssets: async () => {
		useUiStore.getState().setActionState('ACCOUNT_GET_ALL_ASSETS', 'loading')
		try {
			const allAccountsWithAssets = await Assets.findAllAssets()
			const sortedAccountsWithAssetsByStatus = allAccountsWithAssets.sort(
				(a, b) => {
					if (a.status === 'ACTIVE') return -1
					if (b.setup?.step === 'subscription') return 1
					return 0
				}
			)
			const assets: AssetDTO[] = []
			sortedAccountsWithAssetsByStatus.forEach((item) => {
				item.assets && assets.push(...item.assets)
			})
			get().setAccountsWithAssets(sortedAccountsWithAssetsByStatus)

			get().setAllAssets(assets)
			useUiStore.getState().setActionState('ACCOUNT_GET_ALL_ASSETS', 'idle')
			return sortedAccountsWithAssetsByStatus
		} catch (e) {
			useUiStore.getState().setActionState('ACCOUNT_GET_ALL_ASSETS', 'idle')
			return
		}
	},

	clearAssets: () => {
		set({ allAssets: [] })
	},

	clearAsset: () => {
		set({ asset: null })
	},

	refreshAdAccounts: async () => {
		const asset = get().asset
		if (!asset) {
			return
		}
		// const imperId = JSON.parse(localStorage.getItem('imper_userId') as string)

		try {
			useUiStore.getState().setActionState('ASSET_GET_ACCOUNTS', 'loading')
			const hrefAccountId = window.location.href
				.split('/')
				.find((a) => a.includes('acc'))
			const localAccountId = window.localStorage.getItem('selected_account')
			const accountId =
				asset.account_id !== 'workspace-demo'
					? asset.account_id
					: hrefAccountId || localAccountId || asset.account_id
			const currentAsset =
				asset.account_id === 'workspace-demo'
					? get().accountsWithAssets.find((a) => a.id === accountId)
							?.assets?.[0] ?? null
					: asset

			const val = await AdAccounts.find(accountId, {
				asset_id: currentAsset?.id || 'website-demo'
			})
			get().setAdAccounts(val)
			get().refreshCampaigns(asset.account_id!)
			useUiStore.getState().setActionState('ASSET_GET_ACCOUNTS', 'idle')
		} catch (e) {
			useUiStore.getState().setActionState('ASSET_GET_ACCOUNTS', 'idle')
			return
		}
	},

	refreshCampaigns: async (accountId: string) => {
		const isDemo = accountId === 'website-demo'
		if (get().adAccounts.length === 0 || isDemo) return

		try {
			const campaigns = await Promise.all(
				get().adAccounts.map(async (adAccount) => {
					if (adAccount.platform === 'GADS') {
						return await AdAccounts.getGoogleCampaigns(accountId, adAccount.id)
					}
					if (adAccount.platform === 'FADS') {
						return await AdAccounts.getFacebookCampaigns(
							accountId,
							adAccount.id
						)
					} else {
						return await AdAccounts.getBingCampaigns(accountId, adAccount.id)
					}
				})
			)
			get().setCampaigns(campaigns.flat().concat())
		} catch (e) {
			return
		}
	},

	canAddAsset: (account?: ProductAccountDTO | null) => {
		if (account?.billing?.subscription?.type === 'TRIAL') return true

		const tier = getPermissionTier(account?.billing?.subscription?.plan.tier)
		if (!tier) return false

		const value = features.asset_add[tier] || 0
		const assets = get().allAssets.length || 0
		return value > assets
	},

	canAddAdAccount: (account?: ProductAccountDTO | null) => {
		if (account?.billing?.subscription?.type === 'TRIAL') return true

		const tier = getPermissionTier(account?.billing?.subscription?.plan.tier)
		if (!tier) return false

		const value = features.ad_account_add[tier] || 0
		const assets = get().adAccounts.length || 0
		return value > assets
	},

	createAsset: async (
		accountId: string,
		payload: {
			name: string
			link_id: string
			account_number: string
		}
	) => {
		try {
			useUiStore.getState().setActionState('ACCOUNT_ADD_ASSET', 'loading')
			const asset = await Assets.create(accountId, payload)
			await Promise.all([get().getAllAssets(), get().refreshAdAccounts()])
			useUiStore
				.getState()
				.setActionState('ACCOUNT_ADD_ASSET', 'success', 'New website added')
			return asset
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ACCOUNT_ADD_ASSET',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to add new site'
				)
			throw new Error()
		}
	},

	deleteAsset: async (accountId: string, assetId: string) => {
		try {
			useUiStore.getState().setActionState('ASSET_DELETE_SITE', 'loading')
			await Assets.deleteAsset(accountId, assetId)
			await Promise.all([get().getAllAssets(), get().refreshAdAccounts()])

			useUiStore
				.getState()
				.setActionState(
					'ASSET_DELETE_SITE',
					'success',
					'Website successfully deleted'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_DELETE_SITE',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to delete site'
				)
			throw new Error()
		}
	},

	sendMccLinkRequest: async (accountId: string, adAccountId: string) => {
		try {
			useUiStore
				.getState()
				.setActionState('AD_ACCOUNT_MCC_LINK_REQUEST', 'loading')
			await AdAccounts.sendMccLinkRequest(accountId, adAccountId)
			await get().refreshAdAccounts()

			useUiStore
				.getState()
				.setActionState(
					'AD_ACCOUNT_MCC_LINK_REQUEST',
					'success',
					'Link request successfully sent'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'AD_ACCOUNT_MCC_LINK_REQUEST',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to send Link request'
				)
		}
	},

	sendMccLinkRequests: async (accountId: string) => {
		try {
			useUiStore
				.getState()
				.setActionState('AD_ACCOUNT_MCC_LINK_REQUEST', 'loading')
			await AdAccounts.sendMccLinkRequests(accountId)
			await get().refreshAdAccounts()

			useUiStore
				.getState()
				.setActionState(
					'AD_ACCOUNT_MCC_LINK_REQUEST',
					'success',
					'Link request successfully sent'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'AD_ACCOUNT_MCC_LINK_REQUEST',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to send Link request'
				)
		}
	},

	skipMccSupportAll: async (accountId: string) => {
		try {
			useUiStore
				.getState()
				.setActionState('AD_ACCOUNT_MCC_LINK_REQUEST', 'loading')
			await AdAccounts.skipMccSupportAll(accountId)
			await get().refreshAdAccounts()

			useUiStore
				.getState()
				.setActionState('AD_ACCOUNT_MCC_LINK_REQUEST', 'success')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'AD_ACCOUNT_MCC_LINK_REQUEST',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to send Link request'
				)
		}
	},

	skipMccSupport: async (accountId: string, adAccountId: string) => {
		try {
			useUiStore
				.getState()
				.setActionState('AD_ACCOUNT_MCC_LINK_REQUEST', 'loading')
			await AdAccounts.skipMccSupport(accountId, adAccountId)
			await get().refreshAdAccounts()

			useUiStore
				.getState()
				.setActionState('AD_ACCOUNT_MCC_LINK_REQUEST', 'success')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'AD_ACCOUNT_MCC_LINK_REQUEST',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to send Link request'
				)
		}
	},

	suppressMccSupportAll: async (accountId: string) => {
		try {
			useUiStore
				.getState()
				.setActionState('SKIP_AD_ACCOUNT_MCC_LINK_REQUEST', 'loading')
			await AdAccounts.suppressMccSupportAll(accountId)

			useUiStore
				.getState()
				.setActionState('SKIP_AD_ACCOUNT_MCC_LINK_REQUEST', 'success')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'SKIP_AD_ACCOUNT_MCC_LINK_REQUEST',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to skip Link request'
				)
		}
	},

	// Account Report action starts here ******************************************************************

	getReports: async (
		productAccountId: string,
		assetId: string,
		type: reportType | null,
		adAccountId: null | string = null,
		campaignId: null | string = null,
		search: null | string = null
	) => {
		try {
			useUiStore.getState().setActionState('ASSET_GET_REPORTS', 'loading')
			const reports = await Reports.get(
				productAccountId,
				assetId,
				type,
				adAccountId,
				campaignId,
				search
			)
			get().setReports(reports)
			useUiStore.getState().setActionState('ASSET_GET_REPORTS', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_GET_REPORTS',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to load reports'
				)
		}
	},
	getReportRequests: async (
		productAccountId: string,
		assetId: string,
		method: ReportMethod
	) => {
		try {
			useUiStore
				.getState()
				.setActionState('ASSET_GET_REPORT_REQUESTS', 'loading')
			const reports = await Reports.getRequests(
				productAccountId,
				assetId,
				method
			)
			get().setReportRequests(reports)
			useUiStore.getState().setActionState('ASSET_GET_REPORT_REQUESTS', 'idle')
			return reports
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_GET_REPORT_REQUESTS',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to load reports'
				)
			throw new Error()
		}
	},
	getRecentReports: async (productAccountId: string, assetId: string) => {
		try {
			useUiStore
				.getState()
				.setActionState('ASSET_GET_REPORT_REQUESTS', 'loading')
			const reports = await Reports.getRecentReports(productAccountId, assetId)
			get().setRecentReport(reports)
			useUiStore.getState().setActionState('ASSET_GET_REPORT_REQUESTS', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_GET_REPORT_REQUESTS',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to load reports'
				)
			throw new Error()
		}
	},

	setReports: (reports: FormattedReportDTO[]) => {
		set({ reports })
	},

	setReportRequests: (reports: ReportRequestDTO[]) => {
		set({ reportRequests: reports })
	},

	toggleAutomatedReports: async (
		productAccountId: string,
		assetId: string,
		reportRequestID: string,
		value: boolean
	) => {
		try {
			useUiStore.getState().setActionState('ASSET_SET_REPORT_TOGGLE', 'loading')
			await Reports.toggleAutomatedReports(
				productAccountId,
				assetId,
				reportRequestID,
				value
			)
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SET_REPORT_TOGGLE',
					'success',
					'Successfully updated scheduled report'
				)
			await get().getReportRequests(
				productAccountId,
				assetId,
				ReportMethod.AUTOMATED
			)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SET_REPORT_TOGGLE',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error while updating report'
				)
			console.log(err)
		}
	},

	editReport: async (
		productAccountId: string,
		assetId: string,
		reportRequestID: string,
		payload: {
			scope_type: { id: string; type: ReportScopeType }
			scope: Array<string> | null
			period: ReportRequestPeriodDTO
			scheduled_for: number
			label: string
		}
	) => {
		try {
			useUiStore.getState().setActionState('ASSET_SET_REPORT', 'loading')
			await Reports.editReport(
				productAccountId,
				assetId,
				reportRequestID,
				payload
			)
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SET_REPORT',
					'success',
					'Report has been updated'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SET_REPORT',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to update report'
				)
			throw new Error()
		}
	},

	runReport: async (
		productAccountId: string,
		assetId: string,
		payload: {
			type: reportType
			scope: Array<string> | null
			scope_type: { id: string; type: ReportScopeType }
			from: number
			to: number
			label: string | null
			trafficType: string
		}
	) => {
		try {
			useUiStore.getState().setActionState('ASSET_RUN_REPORT', 'loading')
			await Reports.runReport(productAccountId, assetId, payload)
			useUiStore
				.getState()
				.setActionState(
					'ASSET_RUN_REPORT',
					'success',
					'Report Scheduled successfully!'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_RUN_REPORT',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to run report'
				)
			throw new Error()
		}
	},

	runAutoReport: async (
		productAccountId: string,
		assetId: string,
		payload: {
			type: reportType
			scope: Array<string> | null
			scope_type: { id: string; type: ReportScopeType }
			label: string | null
			scheduled_for: number
			period: ReportRequestPeriodDTO
		}
	) => {
		try {
			useUiStore.getState().setActionState('ASSET_RUN_REPORT', 'loading')
			await Reports.runAutoReport(productAccountId, assetId, payload)
			useUiStore
				.getState()
				.setActionState(
					'ASSET_RUN_REPORT',
					'success',
					"Report is scheduled! You will be notified when it's ready."
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_RUN_REPORT',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to run report'
				)
			throw new Error()
		}
	},

	deleteReportRequest: async (
		productAccountId: string,
		assetId: string,
		requestId: string
	) => {
		try {
			useUiStore.getState().setActionState('ASSET_DELETE_REPORT', 'loading')
			await Reports.deleteReportRequest(productAccountId, assetId, requestId)
			useUiStore.getState().setActionState('ASSET_DELETE_REPORT', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_DELETE_REPORT',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to delete report request'
				)
			console.log(err)
		}
	},

	updateAssetSettings: async (
		productAccountId: string,
		assetId: string,
		payload: {
			name: string
			timezone: string
			currency: string
			color: string
			description: string
		}
	) => {
		try {
			useUiStore.getState().setActionState('ASSET_SAVE_SETTINGS', 'loading')
			await Assets.updateAssetSettings(productAccountId, assetId, payload)
			get().setAssetForId(productAccountId, assetId)
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SAVE_SETTINGS',
					'success',
					'Website settings updated'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SAVE_SETTINGS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to update website settings'
				)
			throw new Error()
		}
	},
	// Account Report action ends here ******************************************************************

	// Account Protection action starts here ******************************************************************

	setAccountProtection: async (
		accountId: string,
		assetId: string,
		payload: ProtectionInfoDTO
	) => {
		try {
			useUiStore.getState().setActionState('ASSET_SET_PROTECTION', 'loading')
			await Assets.setAccountProtection(accountId, assetId, payload)
			get().setAssetForId(accountId, assetId)
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SET_PROTECTION',
					'success',
					'Protection updated successfully'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SET_PROTECTION',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to update protection'
				)
			throw new Error()
		}
	},

	updateSettingsAccountProtection: async (
		accountId: string,
		adAccountId: string,
		payload: { active: boolean }
	) => {
		try {
			useUiStore.getState().setActionState('ASSET_SET_PROTECTION', 'loading')
			await AdAccounts.updateSettingsAccountProtection(
				accountId,
				adAccountId,
				payload
			)
			useUiStore.getState().setActionState('ASSET_SET_PROTECTION', 'success')
			await get().refreshAdAccounts()
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SET_PROTECTION',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to save ad account protection status'
				)
			throw new Error()
		}
	},

	synchronizeAdAccount: async (
		accountId: string,
		adAccountId: string,
		platform: string
	) => {
		try {
			useUiStore.getState().setActionState('ASSET_SYNC_ACCOUNT', 'loading')
			await AdAccounts.sync(accountId, adAccountId, platform)
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SYNC_ACCOUNT',
					'success',
					'Synchronization scheduled. Please wait a few minutes...'
				)
			get().refreshAdAccounts()
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SYNC_ACCOUNT',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to start synchronization'
				)
			throw new Error()
		}
	},

	updateGadsAdAccountSettings: async (
		accountId: string,
		adAccountId: string,
		payload: {
			name: string
			color: string
			currency: string
			timezone: string
			ignored_campaigns: string[]
			click_tracking: string
		}
	) => {
		try {
			useUiStore
				.getState()
				.setActionState('ASSET_SAVE_ACCOUNT_SETTINGS', 'loading')
			await AdAccounts.updateGadsAdAccountSettings(
				accountId,
				adAccountId,
				payload
			)
			get().refreshAdAccounts()
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SAVE_ACCOUNT_SETTINGS',
					'success',
					'PPC account settings updated'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SAVE_ACCOUNT_SETTINGS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to save settings'
				)
			throw new Error()
		}
	},

	updateFadsAdAccountSettings: async (
		accountId: string,
		adAccountId: string,
		payload: {
			name: string
			color: string
			currency: string
			timezone: string
			set_custom_audience: boolean
		}
	) => {
		try {
			useUiStore
				.getState()
				.setActionState('ASSET_SAVE_ACCOUNT_SETTINGS', 'loading')
			await AdAccounts.updateFadsAdAccountSettings(
				accountId,
				adAccountId,
				payload
			)
			get().refreshAdAccounts()
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SAVE_ACCOUNT_SETTINGS',
					'success',
					'PPC account settings updated'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SAVE_ACCOUNT_SETTINGS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to save settings'
				)
			throw new Error()
		}
	},

	updateBadsAdAccountSettings: async (
		accountId: string,
		adAccountId: string,
		payload: {
			name: string
			color: string
			currency: string
			timezone: string
			set_custom_audience: boolean
		}
	) => {
		try {
			useUiStore
				.getState()
				.setActionState('ASSET_SAVE_ACCOUNT_SETTINGS', 'loading')
			await AdAccounts.updateBadsAdAccountSettings(
				accountId,
				adAccountId,
				payload
			)
			get().refreshAdAccounts()
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SAVE_ACCOUNT_SETTINGS',
					'success',
					'PPC account settings updated'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SAVE_ACCOUNT_SETTINGS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to save settings'
				)
			throw new Error()
		}
	},

	deleteAdAccountAccount: async (accountId: string, adAccountId: string) => {
		try {
			useUiStore.getState().setActionState('ASSET_DELETE_AD_ACCOUNT', 'loading')
			await AdAccounts.deleteAdAccountAccount(accountId, adAccountId)
			useUiStore
				.getState()
				.setActionState(
					'ASSET_DELETE_AD_ACCOUNT',
					'success',
					'PPC account deleted'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_DELETE_AD_ACCOUNT',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to delete ppc account'
				)
			throw new Error()
		}
	},

	updateGadsSettingsAccessLink: async (
		accountId: string,
		adAccountId: string,
		payload: { link_id: string }
	) => {
		try {
			useUiStore
				.getState()
				.setActionState('ASSET_SAVE_ACCOUNT_SETTINGS', 'loading')
			await AdAccounts.updateGadsSettingsAccessLink(
				accountId,
				adAccountId,
				payload
			)
			get().refreshAdAccounts()
			useUiStore
				.getState()
				.setActionState('ASSET_SAVE_ACCOUNT_SETTINGS', 'success')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SAVE_ACCOUNT_SETTINGS',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to remove tag'
				)
			throw new Error()
		}
	},

	updateFadsSettingsAccessLink: async (
		accountId: string,
		adAccountId: string,
		payload: { link_id: string }
	) => {
		try {
			useUiStore
				.getState()
				.setActionState('ASSET_SAVE_ACCOUNT_SETTINGS', 'loading')
			await AdAccounts.updateFadsSettingsAccessLink(
				accountId,
				adAccountId,
				payload
			)
			get().refreshAdAccounts()
			useUiStore
				.getState()
				.setActionState('ASSET_SAVE_ACCOUNT_SETTINGS', 'success')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SAVE_ACCOUNT_SETTINGS',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to remove tag'
				)
			throw new Error()
		}
	},

	updateBadsSettingsAccessLink: async (
		accountId: string,
		adAccountId: string,
		payload: { link_id: string }
	) => {
		try {
			useUiStore
				.getState()
				.setActionState('ASSET_SAVE_ACCOUNT_SETTINGS', 'loading')
			await AdAccounts.updateBadsSettingsAccessLink(
				accountId,
				adAccountId,
				payload
			)
			get().refreshAdAccounts()
			useUiStore
				.getState()
				.setActionState('ASSET_SAVE_ACCOUNT_SETTINGS', 'success')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SAVE_ACCOUNT_SETTINGS',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to remove tag'
				)
			throw new Error()
		}
	},

	updateTrackingToggles: async (
		accountId: string,
		adAccountId: string,
		payload: {
			set_tracking_template: boolean
			automated_tracking: boolean
		}
	) => {
		try {
			useUiStore
				.getState()
				.setActionState('ASSET_SAVE_ACCOUNT_TRACKING', 'loading')
			await AdAccounts.updateTrackingToggles(accountId, adAccountId, payload)
			get().refreshAdAccounts()
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SAVE_ACCOUNT_TRACKING',
					'success',
					'Changes saved'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SAVE_ACCOUNT_TRACKING',
					'error',
					err.response.data.message ? parseError(err) : 'Failed to save changes'
				)
			throw new Error()
		}
	},

	setAdAccountProtection: async (
		accountId: string,
		adAccountId: string,
		payload: ProtectionInfoDTO
	) => {
		try {
			useUiStore.getState().setActionState('ASSET_SET_PROTECTION', 'loading')
			await AdAccounts.setAdAccountProtection(accountId, adAccountId, payload)
			get().refreshAdAccounts()
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SET_PROTECTION',
					'success',
					'Protection updated successfully.'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SET_PROTECTION',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to update protection'
				)
			throw new Error()
		}
	},

	setCampaignProtection: async (
		accountId: string,
		adAccountId: string,
		campaignId: string,
		payload: ProtectionInfoDTO,
		platform?: AdAccountDTO['platform']
	) => {
		try {
			useUiStore.getState().setActionState('CAMPAIGN_SET_PROTECTION', 'loading')
			await AdAccounts.setCampaignProtection(
				accountId,
				adAccountId,
				campaignId,
				payload,
				platform
			)
			get().refreshAdAccounts()
			useUiStore
				.getState()
				.setActionState(
					'CAMPAIGN_SET_PROTECTION',
					'success',
					'Protection updated successfully.'
				)
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'CAMPAIGN_SET_PROTECTION',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Failed to update protection'
				)
			throw new Error()
		}
	},

	// Account Protection action ends here ******************************************************************

	// site actions starts here ******************************************************************

	createPPCGoogleAdsAccount: async (
		accountId: string,
		payload: {
			asset_id: string
			link_id: string // account link ID
			account_number: string
			set_tracking_template: boolean
		}
	) => {
		try {
			useUiStore.getState().setActionState('ASSET_ADD_ACCOUNT', 'loading')
			const adAccount = await AdAccounts.createPPCGoogleAdsAccount(
				accountId,
				payload
			)
			get().refreshAdAccounts()
			useUiStore
				.getState()
				.setActionState('ASSET_ADD_ACCOUNT', 'success', 'PPC account added')
			return adAccount
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_ADD_ACCOUNT',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error adding ppc account'
				)
			throw new Error()
		}
	},

	createPPCFacebookAdsAccount: async (
		accountId: string,
		payload: {
			asset_id: string
			link_id: string // account link ID
			account_number: string
			set_custom_audience: boolean
		}
	) => {
		try {
			useUiStore.getState().setActionState('ASSET_ADD_ACCOUNT', 'loading')
			await AdAccounts.createPPCFacebookAdsAccount(accountId, payload)
			useUiStore
				.getState()
				.setActionState('ASSET_ADD_ACCOUNT', 'success', 'PPC account added')
			get().refreshAdAccounts()
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_ADD_ACCOUNT',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error adding ppc account'
				)
			throw new Error()
		}
	},

	createPPCBingAdsAccount: async (
		accountId: string,
		payload: {
			asset_id: string
			link_id: string // account link ID
			account_number: string
			customer_id: string
			ad_account_id: string
		}
	) => {
		try {
			useUiStore.getState().setActionState('ASSET_ADD_ACCOUNT', 'loading')
			await AdAccounts.createPPCBingAdsAccount(accountId, payload)
			useUiStore
				.getState()
				.setActionState('ASSET_ADD_ACCOUNT', 'success', 'PPC account added')
			get().refreshAdAccounts()
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_ADD_ACCOUNT',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error adding ppc account'
				)
			throw new Error()
		}
	},

	updateAssetPhoto: async (formData: Partial<FormData>, accountId: string) => {
		const asset = get().asset
		if (!asset) {
			return
		}
		useUiStore.getState().setActionState('ASSET_SAVE_ACCOUNT', 'loading')
		try {
			const avatar = await Assets.updateAssetPhoto(
				formData,
				accountId,
				asset.id
			)
			get().setAsset({ ...asset, avatar })

			useUiStore.getState().setActionState('ASSET_SAVE_ACCOUNT', 'idle')
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SAVE_ACCOUNT',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error updating profile photo'
				)
			throw new Error()
		}
	},

	getGtmAccounts: async (
		accountId: string,
		assetId: string,
		linkId: string
	) => {
		try {
			useUiStore.getState().setActionState('ASSET_GET_GTM_ACCOUNTS', 'loading')
			const accounts = await GtmService.getGtmAccounts(
				accountId,
				assetId,
				linkId
			)
			useUiStore.getState().setActionState('ASSET_GET_GTM_ACCOUNTS', 'success')
			return accounts
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_GET_GTM_ACCOUNTS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error creating auth link'
				)
			throw new Error()
		}
	},

	getGtmContainers: async (
		accountId: string,
		assetId: string,
		gtmAccountId: string,
		linkId: string
	) => {
		try {
			useUiStore
				.getState()
				.setActionState('ASSET_GET_GTM_CONTAINERS', 'loading')
			const link = await GtmService.getGtmContainers(
				accountId,
				assetId,
				gtmAccountId,
				linkId
			)

			useUiStore
				.getState()
				.setActionState('ASSET_GET_GTM_CONTAINERS', 'success')
			return link
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_GET_GTM_CONTAINERS',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error getting containers'
				)
			throw new Error()
		}
	},

	getGtmWorkspaces: async (
		accountId: string,
		assetId: string,
		gtmAccountId: string,
		gtmContainerId: string,
		linkId: string
	) => {
		try {
			useUiStore
				.getState()
				.setActionState('ASSET_GET_GTM_WORKSPACES', 'loading')
			const link = await GtmService.getGtmWorkspaces(
				accountId,
				assetId,
				gtmAccountId,
				gtmContainerId,
				linkId
			)
			useUiStore
				.getState()
				.setActionState('ASSET_GET_GTM_WORKSPACES', 'success')
			return link
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_GET_GTM_WORKSPACES',
					'error',
					err.response.data.message
						? parseError(err)
						: 'Error getting workspaces'
				)
			throw new Error()
		}
	},

	getGtmTag: async (accountId: string, assetId: string) => {
		try {
			useUiStore.getState().setActionState('ASSET_GET_GTM_TAG', 'loading')
			const tag = await GtmService.getGtmTag(accountId, assetId)
			useUiStore.getState().setActionState('ASSET_GET_GTM_TAG', 'success')
			get().setGtmTag(tag)
			return tag
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_GET_GTM_TAG',
					'error',
					err.response.data.message ? parseError(err) : 'Error getting Tag'
				)
			throw new Error()
		}
	},

	createGtmTag: async (
		accountId: string,
		assetId: string,
		gtmAccountId: string,
		gtmContainerId: string,
		gtmWorkspaceId: string,
		linkId: string
	) => {
		try {
			useUiStore.getState().setActionState('ASSET_SET_GTM_TAGS', 'loading')
			const link = await GtmService.createTag(
				accountId,
				assetId,
				gtmAccountId,
				gtmContainerId,
				gtmWorkspaceId,
				linkId
			)
			useUiStore.getState().setActionState('ASSET_SET_GTM_TAGS', 'success')
			get().getGtmTag(accountId, assetId)
			get().setAssetForId(accountId, assetId)
			return link
		} catch (err: any) {
			useUiStore
				.getState()
				.setActionState(
					'ASSET_SET_GTM_TAGS',
					'error',
					err.response.data.message ? parseError(err) : 'Error creating Tag'
				)
			throw new Error()
		}
	}
}))
