import { BlacklistPageFiltersContextProvider } from './context'
import { lazy, Suspense } from 'react'
import { GLazyLoading } from '../../../../components/basic-blocks'

const BlacklistPageView = lazy(() =>
	import('./view')
		.then((module) => {
			return { default: module.BlacklistPageView }
		})
		.catch((error) => {
			console.error('Failed to load:', error)
			return { default: () => <div>Error loading the component.....</div> }
		})
)
export const BlacklistPage = () => {
	return (
		<BlacklistPageFiltersContextProvider>
			<Suspense fallback={<GLazyLoading />}>
				<BlacklistPageView />
			</Suspense>
		</BlacklistPageFiltersContextProvider>
	)
}
