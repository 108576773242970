import { useReducer } from 'react'

export const initialState = {
	filter: '',
	focused: false,
	loading: false
}

const reducer = (
	state: {
		filter: string
		focused: boolean
		loading: boolean
	},
	action: { type: string; payload: string; focus: boolean; loading: boolean }
) => {
	switch (action.type) {
		case 'SET_FILTER':
			return {
				...state,
				filter: action.payload,
				focused: action.focus,
				loading: action.loading
			}
		case 'SET_FOCUS':
			return {
				...state,
				focused: action.focus
			}
		case 'SET_LOADING':
			return {
				...state,
				loading: action.loading
			}
		default:
			return state
	}
}

export const useAnalyticsReducer = () => {
	const [state, dispatch] = useReducer(reducer, initialState)

	return { state, dispatch }
}
