import { AccountsPageFiltersContextProvider } from './context'
import { AdminAccountsPageView } from './view'

export const AdminAccountsPage = () => {
	return (
		<AccountsPageFiltersContextProvider>
			<AdminAccountsPageView />
		</AccountsPageFiltersContextProvider>
	)
}
