import { GButton, GTransition } from 'components/basic-blocks'
import { usePostHog } from 'posthog-js/react'
import { RiStackLine } from 'react-icons/ri'
import BingIcon from '../../assets/img/bing.svg'
import GoogleIcon from '../../assets/img/google.svg'
import MetaIcon from '../../assets/img/meta.svg'
import { PlatformLinkProps } from './types'

export const PlatformLink = ({
	state,
	setPlatform,
	authorizePlatform,
	reset,
	hideOtherPlatforms = false
}: PlatformLinkProps) => {
	const isLoading = state.waiting || state.loading
	const platform = state.platform
	const posthog = usePostHog()
	return (
		<div className="w-full flex flex-col items-center">
			<div className="w-full flex justify-between items-center border border-t-border-light min-h-[78px] my-3 rounded">
				<div className={'w-full flex justify-start items-center ml-7'}>
					<img src={GoogleIcon} alt="Google" className="w-6 h-6 mr-3" />
					<div className="text-xl font-bold">Google Ads</div>
				</div>
				<div>
					<GButton
						color="primary"
						iconPlacement="right"
						className="max-h-[30px] mr-6"
						onClick={() => {
							posthog?.capture('click-on-authorize-google-ads')
							setPlatform('GADS')
							authorizePlatform('GADS')
						}}
						disabled={platform === 'GADS' && isLoading}
						loading={platform === 'GADS' && isLoading}
					>
						<span>
							{platform === 'GADS' && state.waiting
								? 'Waiting for authorization...'
								: 'Authorize'}
						</span>
					</GButton>
				</div>
			</div>
			<div className="w-full flex justify-between items-center border border-t-border-light min-h-[78px] my-3 rounded">
				<div className={'w-full flex justify-start items-center ml-7'}>
					<img src={MetaIcon} alt="Meta" className="w-6 h-6 mr-3" />
					<div className="text-xl font-bold">Meta Ads</div>
				</div>
				<div>
					<GButton
						color="primary"
						iconPlacement="right"
						className="max-h-[30px] mr-6"
						onClick={() => {
							posthog?.capture('click-on-authorize-meta-ads')
							setPlatform('FADS')
							authorizePlatform('FADS')
						}}
						disabled={platform === 'FADS' && isLoading}
						loading={platform === 'FADS' && isLoading}
					>
						<span>
							{platform === 'FADS' && state.waiting
								? 'Waiting for authorization...'
								: 'Authorize'}
						</span>
					</GButton>
				</div>
			</div>
			<div className="w-full flex justify-between items-center border border-t-border-light min-h-[78px] my-3 rounded">
				<div className={'w-full flex justify-start items-center ml-7'}>
					<img src={BingIcon} alt="Bing" className="w-6 h-6 mr-3" />
					<div className="text-xl font-bold">Bing Ads</div>
				</div>
				<div>
					<GButton
						color="primary"
						iconPlacement="right"
						className="max-h-[30px] mr-6"
						onClick={() => {
							posthog?.capture('click-on-authorize-bing-ads')
							setPlatform('BADS')
							authorizePlatform('BADS')
						}}
						disabled={platform === 'BADS' && isLoading}
						loading={platform === 'BADS' && isLoading}
					>
						<span>
							{platform === 'BADS' && state.waiting
								? 'Waiting for authorization...'
								: 'Authorize'}
						</span>
					</GButton>
				</div>
			</div>
			{!hideOtherPlatforms && (
				<div className="w-full flex justify-between items-center border border-t-border-light min-h-[78px] my-3 text-t-secondary rounded">
					<div className={'w-1/2 flex justify-start items-center ml-7'}>
						<RiStackLine className="w-6 h-6 text-t-secondary mr-3" />
						<span className="w-full text-xl font-bold">Other platforms</span>
					</div>
					<div className="text-xs mr-6">Coming soon</div>
				</div>
			)}

			<GTransition show={state.waiting}>
				<div className="flex flex-col items-center text-md">
					<span>Waiting for authorization...</span>
					<span className="text-red-700 cursor-pointer" onClick={() => reset()}>
						Cancel
					</span>
				</div>
			</GTransition>
		</div>
	)
}
