import { ProductAccountDTO } from 'api-models'
import { GButton, GSection, GTooltip } from 'components/basic-blocks'
import { OffersList } from 'components/offers-list'
import { RiFileEditLine } from 'react-icons/ri'
import { useUiStore, useUserStore } from 'store'
import { AdminCreateOffer } from '../../../../../components/create-offer'
import { AdminAccessTypes, hasAdminAccess } from '../../../../../utils/admin'

export const OffersSection = (props: { account: ProductAccountDTO }) => {
	const { account } = props
	const loginUser = useUserStore((s) => s.user)

	const isLoading = useUiStore((s) => s.isLoading)

	return (
		<GSection loading={isLoading('ADMIN_GET_OFFERS')}>
			<div className="flex justify-between items-center -mt-5 -mx-6 px-5 py-2 border-b rounded-t-lg border-card-border bg-gray-50 text-t-dark text-sm font-medium">
				<div>Offers</div>
				<div>
					<AdminCreateOffer
						user={{
							id: account.user_info?.user_id || '',
							name: account.user_info?.name || ''
						}}
						account={{
							id: account.id,
							name: account.name || '',
							spend: account?.billing?.subscription?.plan?.spend || null,
							interval: account?.billing?.subscription?.plan?.interval || null,
							price: account?.billing?.subscription?.plan?.price || null,
							planName: account?.billing?.subscription?.plan?.name || null,
							interval_count:
								account?.billing?.subscription?.plan?.interval_count || null
						}}
					>
						{(open) => (
							<GTooltip
								content={
									!hasAdminAccess(loginUser, AdminAccessTypes.make_offer)
										? 'You do not have permission to make an offer'
										: ''
								}
							>
								<GButton
									label="Make offer"
									icon={RiFileEditLine}
									color="primary"
									disabled={
										!hasAdminAccess(loginUser, AdminAccessTypes.make_offer)
									}
									variant="contained"
									onClick={() => open()}
									size="xs"
								/>
							</GTooltip>
						)}
					</AdminCreateOffer>
				</div>
			</div>
			<div className="-mx-6">
				<OffersList accountId={account.id} />
			</div>
		</GSection>
	)
}
