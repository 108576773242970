import { RiCloseLine } from 'react-icons/ri'

export interface SelectedFiltersProps {
	mapState: Map<any, any>
	removeFilter: (key: string) => void
}

const formatSelectedFilters = (key: string, value: any) => {
	switch (key) {
		case 'status': {
			return `Status: ${value}`
		}
		case 'tier': {
			return `Status: ${value}`
		}
		default:
			return ''
	}
}

export const SelectedFilters = ({
	mapState,
	removeFilter
}: SelectedFiltersProps) => {
	return (
		<div className="flex flex-wrap gap-2">
			{Array.from(mapState.entries())
				.filter((e) => !['from', 'to'].includes(e[0]))
				.map(([key, value]) => (
					<div
						key={key}
						className="flex items-center gap-1.5 bg-gray-50 py-1.5 px-3 text-t-default font-normal rounded-lg"
					>
						{formatSelectedFilters(key, value)}
						<RiCloseLine
							className="w-4 h-4 text-t-default cursor-pointer"
							onClick={() => removeFilter(key)}
						/>
					</div>
				))}
		</div>
	)
}
