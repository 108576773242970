import { IAffiliateDashboardUsers } from './types'
import { GBadge } from '../../../../components/basic-blocks/g-badge'
import { SubscriptionColors } from '../../../../utils/colors'
import moment from 'moment/moment'
import { capitalizeFirstLetter } from '../../../../utils/text'

const commonClassName =
	'justify-start font-bold text-t-input-secondary text-base'
export const affiliateColumns = [
	{
		label: 'Name',
		key: 'name',
		render: (row: IAffiliateDashboardUsers) => <UserName row={row} />,
		className: `${commonClassName} pl-4`,
		parentClassName: 'px-2 min-w-[250px]'
	},
	{
		label: 'Status',
		key: 'status',
		render: (row: IAffiliateDashboardUsers) => <SubscriptionStatus row={row} />,
		className: commonClassName
	},
	{
		label: 'Tier',
		key: 'tier',
		render: (row: IAffiliateDashboardUsers) => <Tier row={row} />,
		className: commonClassName
	},
	{
		label: 'Trial Date',
		key: 'trial_date',
		render: (row: IAffiliateDashboardUsers) => <Date date={row.trial_date} />,
		className: commonClassName
	},
	{
		label: 'Conversion Date',
		key: 'conversion_date',
		render: (row: IAffiliateDashboardUsers) => (
			<Date date={row.conversion_date} />
		),
		className: commonClassName
	},
	{
		label: 'Sale',
		key: 'sale',
		render: (row: IAffiliateDashboardUsers) => <Sale row={row} />,
		className: commonClassName
	},
	{
		label: 'Commission',
		key: 'commission',
		render: (row: IAffiliateDashboardUsers) => <Commission row={row} />,
		className: commonClassName
	}
]
const SubscriptionStatus = ({ row }: { row: IAffiliateDashboardUsers }) => {
	const { status } = row

	if (!status) {
		return <div className="text-base font-bold text-t-secondary">N/A</div>
	}

	if (status === 'SIGNUP') {
		return <GBadge text={'SETUP'} color={SubscriptionColors.NONE} />
	}

	return <GBadge text={status} color={SubscriptionColors[status]} />
}

const UserName = ({ row }: { row: IAffiliateDashboardUsers }) => {
	const { name } = row

	return <div className="text-base font-bold">{name}</div>
}

const Commission = ({ row }: { row: IAffiliateDashboardUsers }) => {
	const { commission } = row

	if (!commission) {
		return <div className="text-base font-bold text-t-secondary">N/A</div>
	}

	return (
		<GBadge text={commission} className="text-base font-bold" color="green" />
	)
}

const Sale = ({ row }: { row: IAffiliateDashboardUsers }) => {
	const { sale } = row

	if (!sale) {
		return <div className="text-base font-medium text-t-secondary">N/A</div>
	}

	return (
		<GBadge
			text={`${sale.amount}/${sale.interval}`}
			className="text-base font-medium "
			color="purple"
		/>
	)
}

const Date = ({ date }: { date: string | undefined }) => {
	if (!date)
		return <div className="font-normal text-base text-t-secondary">N/A</div>
	return (
		<div className="font-normal w-full flex space-x-2 items-center">
			{moment(date).format('ddd, MMM D, YYYY')}
		</div>
	)
}

const Tier = ({ row }: { row: IAffiliateDashboardUsers }) => {
	const { tier } = row
	if (!tier)
		return <div className="font-normal text-base text-t-secondary">N/A</div>

	return (
		<div className="font-normal text-base text-t-secondary">
			{capitalizeFirstLetter(tier)}
		</div>
	)
}
