import { useEffect, useState } from 'react'
import {
	RiArrowLeftSLine,
	RiArrowRightSLine,
	RiCloseLine
} from 'react-icons/ri'

import { classNames } from 'utils'
import { GTooltip } from '../g-tooltip'
import { GNoticeProps, GNoticeType } from './types'

import { useThemeStore } from 'layout/navbar/store'

const buildClassName = (type = 'warning', theme: string) => {
	const commonStyles =
		'flex justify-between items-center py-2 px-4 rounded text-md font-bold'
	let typeStyles = ''

	if (type === 'warning')
		typeStyles =
			theme === 'dark'
				? 'bg-light-background text-amber-400'
				: 'bg-amber-100 text-amber-700'
	if (type === 'critical')
		typeStyles =
			theme === 'dark'
				? 'bg-light-background text-red-400'
				: 'bg-red-100 text-red-700'
	if (type === 'positive')
		typeStyles =
			theme === 'dark'
				? 'bg-light-background text-green-400'
				: 'bg-green-100 text-green-700'

	return classNames(commonStyles, typeStyles)
}

export const GNotice = ({ notices, ...props }: GNoticeProps) => {
	const [currentNoticeIndex, setCurrentNoticeIndex] = useState(0)
	const [currentNotices, setCurrentNotices] = useState<GNoticeType[]>([])
	const { theme } = useThemeStore()

	useEffect(() => {
		setCurrentNotices(notices)
	}, [notices])

	const currentNotice = currentNotices[currentNoticeIndex]
	const showSwitchingComponent = currentNotices.length > 1
	const disablePrevious = currentNoticeIndex === 0
	const disableNext = currentNoticeIndex === currentNotices.length - 1

	const switchPreviousNotice = () => {
		setCurrentNoticeIndex((prev) => prev - 1)
	}
	const switchNextNotice = () => {
		setCurrentNoticeIndex((prev) => prev + 1)
	}
	const dismiss = () => {
		setCurrentNotices((prev) => [
			...prev.filter((notice) => notice.key !== currentNotice.key)
		])
		if (currentNoticeIndex !== 0) {
			setCurrentNoticeIndex((prev) => prev - 1)
		}

		if (currentNotice.onDismiss) currentNotice.onDismiss()
	}

	if (!currentNotice) return null

	return (
		<div className="max-w-full mx-auto px-4 sm:px-6 pt-6">
			<div className={buildClassName(currentNotice.type, theme)} {...props}>
				<div className="flex items-center">
					{showSwitchingComponent && (
						<div className="flex items-center mr-2" data-testid="switching">
							<button
								type="button"
								onClick={switchPreviousNotice}
								data-testid="switch-previous"
								disabled={disablePrevious}
							>
								<RiArrowLeftSLine
									className={classNames(
										'w-5 h-6',
										disablePrevious ? 'text-gray-500' : ''
									)}
								/>
							</button>
							{currentNoticeIndex + 1} / {currentNotices.length}
							<button
								type="button"
								onClick={switchNextNotice}
								data-testid="switch-next"
								disabled={disableNext}
							>
								<RiArrowRightSLine
									className={classNames(
										'w-5 h-6',
										disableNext ? 'text-gray-500' : ''
									)}
								/>
							</button>
						</div>
					)}
					<div>{currentNotice.notice}</div>
				</div>
				{currentNotice.hideDismiss ? (
					<></>
				) : (
					<GTooltip
						content="Dismiss"
						data-testid="dismiss-button"
						onClick={dismiss}
					>
						<RiCloseLine className="w-5 h-6" />
					</GTooltip>
				)}
			</div>
		</div>
	)
}
