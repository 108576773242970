import { Notices } from 'components/notice/notices'
import { PageHeader } from 'components/page-header'
import { useEffect, useMemo, useState } from 'react'
import { useAccountStore, useAssetStore } from 'store'
import { getEntityName } from 'utils/get-entity-name'
import { useShallow } from 'zustand/react/shallow'
import { AdAccountDTO } from '../../../../../../api-models/src'
import { Breadcrumbs } from '../../../../layout/navbar/breadcrumbs'
import { AdAccounts } from '../../../../services/user'
import { PageTitle } from '../../../../utils/page-title'
import { GSection, GTransition } from '../../../../components/basic-blocks'
import { Tabs } from '../../../../components/tabs'
import { useSearchParams } from 'react-router-dom'
import { ReportMethod } from './types'
import { UpcomingReports } from './upcomming-reports/upcomming-reports'
import { RecentReports } from './recent-reports/recent-report'
import { AvailableReports } from './available-reports/available-reports'
import { DownloadsReports } from './downloads-reports/downloads-reports'
import { ScheduledReport } from './schdule-reports/scheduled-report'

export const ReportsPage = () => {
	const [search] = useSearchParams()
	const [tab, setTab] = useState('available')
	const {
		asset,
		setAccountAdAccounts,
		demoMode,
		getReportRequests,
		getRecentReports,
		adAccounts
	} = useAssetStore(
		useShallow((s) => ({
			asset: s.asset,
			setAccountAdAccounts: s.setAccountAdAccounts,
			demoMode: s.demoMode,
			getReportRequests: s.getReportRequests,
			getRecentReports: s.getRecentReports,
			adAccounts: s.adAccounts
		}))
	)
	const { account, isInSetup, hasFeature } = useAccountStore(
		useShallow((s) => ({
			account: s.account,
			isInSetup: s.isInSetup,
			hasFeature: s.hasFeature
		}))
	)

	const [searchParams] = useSearchParams()
	const campaignId = searchParams.get('breadCampaign')

	const [adAccount, setAdAccount] = useState<AdAccountDTO | null>(null)
	useEffect(() => {
		const fetchData = async () => {
			if (account?.id) {
				const adAccounts = await AdAccounts.find(account?.id, {})
				setAccountAdAccounts(adAccounts)
			}
		}

		fetchData()
	}, [account])

	useEffect(() => {
		setTab(search.get('tab') || 'available')
	}, [search])

	useEffect(() => {
		if (account && asset) {
			getRecentReports(account.id, asset.id)
			getReportRequests(account.id, asset.id, ReportMethod.AUTOMATED)
		}
	}, [account, asset])

	const tabs = useMemo(() => {
		return [
			{
				label: 'Available Reports',
				key: 'available',
				to: () =>
					`/workspace/${account?.id}/asset/${asset?.id}/reports?tab=available`
			},
			{
				label: 'Downloads',
				key: 'downloads',
				to: () =>
					`/workspace/${account?.id}/asset/${asset?.id}/reports?tab=downloads`
			},
			{
				label: 'Scheduled Reports',
				key: 'scheduled',
				to: () =>
					`/workspace/${account?.id}/asset/${asset?.id}/reports?tab=scheduled`
			}
		]
	}, [account, asset])

	const pageActions = (
		<div className="w-full pr-8 flex items-center justify-between pb-4">
			<Breadcrumbs
				asset={asset}
				selectedAdAccount={adAccount}
				setSelectedAdAccount={setAdAccount}
				showCampaignSelector={true}
				demoMode={demoMode}
			/>
		</div>
	)

	return (
		<div className="w-full">
			<PageTitle pageName="Reports" />
			<Notices
				isInSetup={isInSetup()}
				setupStep={account?.setup?.step}
				isInActive={account?.status === 'INACTIVE'}
				hasDashboardPerformanceFeature={hasFeature('dashboard_performance')}
				tc_installed={!!asset?.tc_installed}
				cc_installed={!!asset?.cc_installed}
				accountId={account?.id}
				assetId={asset?.id}
				demoMode={demoMode}
				mccLinkInviteAdAccounts={adAccounts}
			/>
			<PageHeader
				title={'Reports'}
				tagline="Configure and schedule reporting for"
				tags={[
					{
						label: getEntityName({
							demoMode,
							selectedCampaign: null,
							adAccount,
							asset
						})
					}
				]}
				action={pageActions}
			/>
			<div className="flex flex-col gap-2 w-full px-4 sm:px-6 md:px-8">
				<div className="w-full flex gap-6">
					<UpcomingReports />
					<RecentReports />
				</div>
				<GSection>
					<Tabs current={tab} tabs={tabs} />
					<GTransition show={tab === 'available'}>
						<p className="text-base py-4 text-t-secondary">
							Schedule reports or run on-demand reports to be generated
							immediately
						</p>
						<AvailableReports />
					</GTransition>
					<GTransition show={tab === 'downloads'}>
						<p className="text-base py-4 text-t-secondary">
							History of all previously generated reports
						</p>
						<DownloadsReports
							adAccount={adAccount?.id || null}
							campaign_id={campaignId || null}
						/>
					</GTransition>
					<GTransition show={tab === 'scheduled'}>
						<p className="text-base py-4 text-t-secondary">
							Scheduled reports that are generated on a set interval
						</p>
						<ScheduledReport />
					</GTransition>
				</GSection>
			</div>
		</div>
	)
}
