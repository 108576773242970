import { ReferenceAdsAccountDTO } from 'api-models'
import { AccountInfo } from 'components/account-info'
import { GDateTime } from 'components/basic-blocks/g-datetime'
import { GTableColumn } from 'components/basic-blocks/g-table/types'
import moment from 'moment'
import { ImCheckmark, ImCross } from 'react-icons/im'

export const columns: (
	getAndSetGadsReferences: () => void
) => Array<GTableColumn<ReferenceAdsAccountDTO>> = (
	getAndSetGadsReferences
) => {
	return [
		{
			label: 'Account no.',
			path: 'accountNo',
			render: (row: ReferenceAdsAccountDTO) => (
				<AccountNoColumn
					row={row}
					updateGadsReferences={getAndSetGadsReferences}
				/>
			)
		},
		{
			label: 'First seen',
			path: 'firstSeen',
			render: (row: ReferenceAdsAccountDTO) => <FirstSeenColumn row={row} />
		},
		{
			label: 'Last seen',
			path: 'lastSeen',
			render: (row: ReferenceAdsAccountDTO) => <LastSeenColumn row={row} />
		},
		{
			label: 'Users',
			path: 'users',
			render: (row: ReferenceAdsAccountDTO) => <UsersColumn row={row} />,
			className: 'justify-center'
		},
		{
			label: 'Accounts',
			path: 'accounts',
			render: (row: ReferenceAdsAccountDTO) => <AccountsColumn row={row} />,
			className: 'justify-center'
		},
		{
			label: 'Manager',
			path: 'manager',
			render: (row: ReferenceAdsAccountDTO) => <ManagerColumn row={row} />,
			className: 'justify-center'
		}
	]
}

const AccountNoColumn = (props: {
	row: ReferenceAdsAccountDTO
	updateGadsReferences: () => void
}) => {
	const { row, updateGadsReferences } = props
	return (
		<>
			<AccountInfo data={row} updateReferences={updateGadsReferences}>
				{(open) => (
					<div className="flex flex-col gap-1" onClick={() => open()}>
						<div className="font-medium text-primary-400 hover:bg-gray-100 px-2 py-1 cursor-pointer rounded-lg">
							{row.account_number.replace('act_', '')}
						</div>
					</div>
				)}
			</AccountInfo>
		</>
	)
}

const FirstSeenColumn = (props: { row: ReferenceAdsAccountDTO }) => {
	const { row } = props
	return (
		<>
			<div className="flex flex-col gap-1">
				<div className="font-medium text-gray-500">
					<GDateTime date={row.first_seen} />
				</div>
				<div className="text-sm text-gray-400">
					{moment(row.first_seen).format('MMMM Do, YYYY - hh:mm:ss A')}
				</div>
			</div>
		</>
	)
}

const LastSeenColumn = (props: { row: ReferenceAdsAccountDTO }) => {
	const { row } = props
	return (
		<>
			<div className="flex flex-col gap-1">
				<div className="font-medium text-gray-500">
					<GDateTime date={row.last_seen} />
				</div>
				<div className="text-sm text-gray-400">
					{moment(row.last_seen).format('MMMM Do, YYYY - hh:mm:ss A')}
				</div>
			</div>
		</>
	)
}

const UsersColumn = (props: { row: ReferenceAdsAccountDTO }) => {
	const { row } = props
	return (
		<>
			<div className="flex flex-col gap-1">
				<div className="font-medium text-gray-400">{row.user_id.length}</div>
			</div>
		</>
	)
}
const ManagerColumn = (props: { row: ReferenceAdsAccountDTO }) => {
	const { row } = props
	return row.manager ? (
		<ImCheckmark className="text-green-500 w-5 h-5" />
	) : (
		<ImCross className="text-red-500 w-3 h-3" />
	)
}

const AccountsColumn = (props: { row: ReferenceAdsAccountDTO }) => {
	const { row } = props
	return (
		<>
			<div className="flex flex-col gap-1">
				<div className="font-medium text-gray-400 ">
					{row.account_id.length}
				</div>
			</div>
		</>
	)
}
