import {
	BingAccountLinkDTO,
	BingAdsAccountBasicInfoDTO,
	FacebookAccountLinkDTO,
	FacebookAdsAccountBasicInfoDTO,
	GoogleAdsAccountBasicInfoDTO,
	GoogleOAuthAccountLinkDTO,
	MCCAccountLinkDTO
} from 'api-models'
import { useMemo, useState } from 'react'
import { RiGoogleLine } from 'react-icons/ri'
import { SiGoogleads } from 'react-icons/si'

import { GBadge } from 'components/basic-blocks/g-badge'
import { PlatformIconRI } from 'components/platform-icon'
import { useUiStore } from 'store'
import { AdPlatforms, AdPlatformTypesDTO } from 'utils/platform'
import { GButton, GDialog, GTransition } from '../../../components/basic-blocks'
import { GCheckbox } from '../../../components/basic-blocks/g-checkbox'
import { GCollapse } from '../../../components/basic-blocks/g-collapse/g-collapse'
import Drawer from '../../../components/basic-blocks/g-drawer/g-drawer'
import {
	BingAuthConfig,
	FacebookAuthConfig,
	GoogleAuthConfig
} from '../../../config'
import { useStores } from '../../../hooks'
import { ZendeskService } from '../../../services/zendesk'

interface IConfigurePpcAccess {
	children: (open: () => void) => void
	link:
		| GoogleOAuthAccountLinkDTO
		| FacebookAccountLinkDTO
		| MCCAccountLinkDTO
		| BingAccountLinkDTO
	platform: AdPlatforms | 'MCC'
	isAnalyst: boolean
}

export const ConfigurePpcAccess = (props: IConfigurePpcAccess) => {
	const { children, platform, isAnalyst, link } = props
	const { accountStore } = useStores()
	const isLoading = useUiStore((s) => s.isLoading)

	const [openDelete, setOpenDelete] = useState(false)
	const [deactivatePPC, setDeactivatePPC] = useState(false)
	const { deleteAccountLink, syncAccountLink, account } = accountStore
	const [dialogOpen, setDialogOpen] = useState(false)

	const connectedAccounts = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return link?.connected_accounts?.filter(
			(account: AdPlatformTypesDTO) => account.status === 'ACTIVE'
		)
	}, [link])

	const handleDelete = async () => {
		await deleteAccountLink(link.id, deactivatePPC)
		setOpenDelete(false)
	}

	const handleSync = async () => {
		await syncAccountLink(link.id)
	}

	const handleReauthorize = (platform: AdPlatforms) => {
		if (platform === 'GADS') handleGoogleReAuthorize()
		if (platform === 'FADS') handleFacebookReAuthorize()
		if (platform === 'BADS') handleBingReAuthorize()
	}

	const handleGoogleReAuthorize = () => {
		window.localStorage.setItem('account-id', account ? account.id : '')
		window.localStorage.setItem('google-reauth', '1')
		window.localStorage.setItem('link-id', link.id)
		window.open(GoogleAuthConfig.AUTH_URL, '_blank')
	}

	const handleFacebookReAuthorize = () => {
		window.localStorage.setItem('account-id', account ? account.id : '')
		window.localStorage.setItem('facebook-reauth', '1')
		window.localStorage.setItem('link-id', link.id)
		window.open(FacebookAuthConfig.AUTH_URL, '_blank')
	}

	const handleBingReAuthorize = () => {
		window.localStorage.setItem('account-id', account ? account.id : '')
		window.localStorage.setItem('bing-reauth', '1')
		window.localStorage.setItem('link-id', link.id)
		window.open(BingAuthConfig.AUTH_URL, '_blank')
	}

	const handleDialogClose = () => {
		setDialogOpen(false)
		ZendeskService.show()
	}

	const statusColor = () => {
		return link.status === 'UNLINKED' ? 'amber' : 'green'
	}
	return (
		<>
			{children && children(() => setDialogOpen(true))}
			<Drawer
				isOpen={dialogOpen}
				handleClose={handleDialogClose}
				label="Connection settings"
				headerClassName="pt-6"
				labelClassName="text-xl"
			>
				<GTransition show={true} swap>
					<div className="w-[750px] mx-auto bg-white px-6 mt-6 py-6">
						<div className="bg-gray-50 px-3 py-2 flex justify-between">
							<div className="flex">
								{platform === 'MCC' ? (
									<SiGoogleads className="text-green-500 w-6 h-6" />
								) : (
									<PlatformIconRI platform={platform} className="w-6 h-6" />
								)}{' '}
								<p className="text-t-default font-bold text-xl">
									&nbsp; &nbsp;{'name' in link ? link.name : link?.email}
									<span className="text-lg font-normal text-t-secondary px-2">
										&nbsp;{' '}
										{'account_number' in link ? link?.account_number : ''}
									</span>
								</p>
							</div>
							<GButton
								label={'Synchronize'}
								disabled={isAnalyst}
								color={isAnalyst ? 'neutral' : 'primary'}
								variant="text"
								labelClassName={'font-bold'}
								size="md"
								className="h-[30px]"
								loading={isLoading('ACCOUNT_SYNC_ACCESS')}
								onClick={() => handleSync()}
							/>
						</div>
						<div className="flex flex-col justify-between h-[calc(100vh-130px)] px-4">
							<div>
								{platform === 'MCC' && (
									<div className="flex items-center mt-6">
										<p className="min-w-[350px] font-bold text-t-secondary text-base">
											Access via
										</p>
										<div className="flex justify-between items-center w-full">
											<div className="flex gap-2 items-center">
												<RiGoogleLine className="text-red-500 w-5 h-5" />
												<p className="flex flex-col">
													<span className="text-t-secondary text-sm">
														Google OAuth
													</span>
													<span>
														{'link' in link
															? ('link' in link && link.link.email) ||
															  link.link.mcc_account_number
															: ''}
													</span>
												</p>
											</div>
										</div>
									</div>
								)}
								{platform !== 'MCC' && (
									<div className="flex items-center mt-6">
										<p className="min-w-[350px] font-bold text-t-secondary text-base">
											Email
										</p>
										<p className="text-t-default text-base">
											{('name' in link && link.name) ||
												('email' in link && link.email)}
										</p>
									</div>
								)}
								<div className={'flex items-start mt-6'}>
									<p
										className={
											'min-w-[350px] font-bold text-t-secondary text-base'
										}
									>
										Status
									</p>
									<div className="flex flex-1 gap-2 items-center justify-between">
										<div className="flex flex-col items-start gap-2">
											<GBadge
												text={link.status}
												color={statusColor()}
												className="py-1 px-3.5 w-max"
											/>
											{platform !== 'FADS' && link.status === 'UNLINKED' && (
												<p className="text-red-500 font-bold">
													{platform === 'GADS'
														? '	The access token was revoked'
														: platform === 'MCC'
														? 'Parent connection lost access to this MCC account'
														: ''}
												</p>
											)}
										</div>
										{link.status === 'UNLINKED' && platform !== 'MCC' && (
											<div>
												<GButton
													variant="text"
													label="Reauthorize"
													size="sm"
													color="buttonPrimary"
													labelClassName="font-bold"
													onClick={() => handleReauthorize(platform)}
												/>
											</div>
										)}
									</div>
								</div>
								<div className="flex mt-6 items-start">
									<p className="min-w-[350px] font-bold text-t-secondary text-base">
										Available accounts
									</p>
									<div className={'w-full'}>
										{link?.clients && link?.clients?.length > 0 ? (
											<GCollapse
												buttonClass="flex w-full justify-between items-center rounded-lg text-left text-sm font-medium"
												titleClass="font-bold pb-2 text-base"
												title={`${link?.clients?.length || 0} accounts`}
												defaultShow={
													<div className="w-full">
														{link?.clients?.length &&
															link?.clients
																?.slice(0, 5)
																?.map(
																	(acc: AdPlatformTypesDTO | any, index) => (
																		<div
																			key={`${acc?.account_number} + ${index}`}
																			className="flex justify-between items-center gap-2 py-2 text-t-secondary text-base"
																		>
																			<p className="flex items-center gap-2">
																				<span>
																					{acc?.account_number?.replace(
																						'act_',
																						''
																					)}
																				</span>
																			</p>
																			<p>{acc?.name}</p>
																		</div>
																	)
																)}
													</div>
												}
											>
												<div className="w-full">
													{link?.clients.length > 0 &&
														link?.clients
															?.slice(5)
															?.map(
																(
																	acc:
																		| GoogleAdsAccountBasicInfoDTO
																		| FacebookAdsAccountBasicInfoDTO
																		| BingAdsAccountBasicInfoDTO,
																	index
																) => (
																	<div
																		key={`${acc?.account_number} + ${index}acc`}
																		className="flex justify-between items-center gap-2 py-2 text-t-secondary text-base"
																	>
																		<p className="flex items-center gap-2">
																			<span>
																				{acc?.account_number?.replace(
																					'act_',
																					''
																				)}
																			</span>
																		</p>
																		<p>{acc?.name}</p>
																	</div>
																)
															)}
												</div>
											</GCollapse>
										) : (
											<p className="font-bold pb-2">None</p>
										)}
									</div>
								</div>
								<div className="flex py-6 items-start">
									<p className="min-w-[350px] font-bold text-t-secondary text-base">
										Added accounts
									</p>
									{connectedAccounts && connectedAccounts?.length > 0 ? (
										<GCollapse
											buttonClass="flex w-full justify-between items-center rounded-lg text-left text-sm font-medium"
											titleClass="font-bold pb-2 text-base"
											title={`${connectedAccounts?.length || 0} accounts`}
											defaultShow={
												<div className="w-full">
													{connectedAccounts.length &&
														connectedAccounts
															?.slice(0, 5)
															?.map(
																(
																	acc: AdPlatformTypesDTO | any,
																	index: number
																) => (
																	<div
																		key={`${acc?.account_number} + ${index}-accq`}
																		className="flex justify-between items-center gap-2 py-2 text-t-secondary text-base"
																	>
																		<p className="flex items-center gap-2">
																			<span>{acc?.details?.name}</span>
																			<span>
																				{acc?.details?.account_number?.replace(
																					'act_',
																					''
																				)}
																			</span>
																		</p>
																		<p>{acc?.assets[index]?.name}</p>
																	</div>
																)
															)}
												</div>
											}
										>
											<div className="w-full">
												{connectedAccounts.length > 0 &&
													connectedAccounts
														?.slice(5)
														?.map(
															(
																acc: AdPlatformTypesDTO | any,
																index: number
															) => (
																<div
																	key={`${acc?.account_number} + ${index}-connecy`}
																	className="flex justify-between items-center gap-2 py-2 text-t-secondary text-base"
																>
																	<p className="flex items-center gap-2">
																		<span>{acc?.details?.name}</span>
																		<span>
																			{acc?.details?.account_number?.replace(
																				'act_',
																				''
																			)}
																		</span>
																	</p>
																	<p>{acc?.assets[5 + index]?.name}</p>
																</div>
															)
														)}
											</div>
										</GCollapse>
									) : (
										<p className="font-bold pb-2">None</p>
									)}
								</div>
								<div className="flex py-6 items-start border-t ">
									<p className="min-w-[350px] font-bold text-t-secondary text-base">
										Delete connection
									</p>
									<div>
										<GButton
											disabled={isAnalyst}
											label={'Delete'}
											color={isAnalyst ? 'neutral' : 'danger'}
											className="h-[25px] w-[70px] text-white"
											labelClassName="font-bold"
											variant="contained"
											size="sm"
											loading={isLoading('ACCOUNT_REMOVE_ACCESS')}
											onClick={() => setOpenDelete(true)}
										/>
										<GDialog
											open={isAnalyst ? false : openDelete}
											onClose={() => setOpenDelete(false)}
											showClose={false}
											maxWidth="2xl"
										>
											<div>
												<p className="font-bold text-xl border-b pb-4">
													Delete connection
												</p>
												<p className="py-6">
													By removing this connection ClickGUARD will not be
													able to access your PPC accounts. Are you sure you
													want to delete this connection?{' '}
												</p>
												<div className="flex items-center py-2">
													<GCheckbox
														name={'deactivate'}
														checked={deactivatePPC}
														onChange={(v) => setDeactivatePPC(v.target.checked)}
													/>
													<p className="font-medium">
														Deactivate all added PPC accounts using this
														connection
													</p>
												</div>
												<div className="flex justify-between items-center pt-6">
													<GButton
														disabled={isAnalyst}
														label={'Cancel'}
														color={'neutral'}
														labelClassName="font-bold"
														variant="text"
														size="md"
														onClick={() => setOpenDelete(false)}
													/>
													<GButton
														disabled={isAnalyst}
														label={'Delete'}
														color={isAnalyst ? 'neutral' : 'danger'}
														variant="contained"
														size="md"
														loading={isLoading('ACCOUNT_REMOVE_ACCESS')}
														onClick={handleDelete}
													/>
												</div>
											</div>
										</GDialog>
									</div>
								</div>
							</div>
							<div className="flex items-center justify-between min-h-[70px] px-4 border-t border-gray-100">
								<GButton
									variant="text"
									label="Close"
									size="md"
									color="neutral"
									labelClassName="font-bold"
									onClick={handleDialogClose}
								/>
							</div>
						</div>
					</div>
				</GTransition>
			</Drawer>
		</>
	)
}
