import { useEffect, useState } from 'react'
import { LiaCloudDownloadAltSolid } from 'react-icons/lia'
import { useAccountStore, useAssetStore } from 'store'
import {
	FormattedReportDTO,
	ReportRequestDTO
} from '../../../../../../../api-models'
import { WebsiteAvatar } from '../../../../../components'
import { GInput, GTooltip } from '../../../../../components/basic-blocks'
import { GBadge } from '../../../../../components/basic-blocks/g-badge'
import {
	FolderOpenLineIcon,
	PlatformIcon
} from '../../../../../components/platform-icon'
import { useStores } from '../../../../../hooks'
import { Reports } from '../../../../../services/user/reports'
import {
	capitalizeFirstLetter,
	truncateString
} from '../../../../../utils/text'
import { ReportMethod } from '../types'
import { CiSearch } from 'react-icons/ci'
import { GDateTime } from '../../../../../components/basic-blocks/g-datetime'
import { useShallow } from 'zustand/react/shallow'
import { formatReportsLinks } from '../utils'

export const DownloadsReports = ({
	adAccount,
	campaign_id
}: {
	adAccount: string | null
	campaign_id: string | null
}) => {
	const { asset, reports, getReports, demoMode } = useAssetStore(
		useShallow((s) => ({
			asset: s.asset,
			reports: s.reports,
			getReports: s.getReports,
			demoMode: s.demoMode
		}))
	)
	const account = useAccountStore((s) => s.account)
	const [clearId, setClearId] = useState<NodeJS.Timeout | undefined>(undefined)
	const [reportRequest, setReportRequest] = useState<ReportRequestDTO[]>([])
	const [search, setSearch] = useState('')

	async function reFetchReportsRequest() {
		if (account && asset) {
			const requests = await Reports.getRequests(
				account.id,
				asset.id,
				ReportMethod.ON_DEMAND
			)
			setReportRequest(requests)
			if (!requests.length) {
				await getReports(account.id, asset.id, null)
			}
		}
	}

	useEffect(() => {
		if (demoMode && asset) {
			getReports(asset?.account_id, asset?.id, null)
		}
		if (account?.id && asset?.id) {
			getReports(account.id, asset.id, null)
		}
	}, [account?.id, asset?.id])

	useEffect(() => {
		if (reports.length) {
			const data = reports.filter(
				(req) => req.status === 'PENDING' || req.status === 'IN_PROGRESS'
			)
			if (data) {
				setReportRequest(data as any)
			}
		}
	}, [reports])

	useEffect(() => {
		if (account?.id && asset?.id) {
			getReports(account.id, asset.id, null, adAccount, campaign_id)
		}
	}, [adAccount, campaign_id])

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (account?.id && asset?.id) {
				getReports(account.id, asset.id, null, adAccount, campaign_id, search)
			}
		}, 1000)
		return () => clearTimeout(timeout)
	}, [search])

	useEffect(() => {
		let intervalId: NodeJS.Timeout | undefined
		if (!reportRequest.length) {
			clearId && clearTimeout(clearId)
		}
		if (reportRequest.length) {
			clearId && clearTimeout(clearId)
			intervalId = setInterval(reFetchReportsRequest, 5000)
			setClearId(intervalId)
		}
		return () => {
			intervalId && clearTimeout(intervalId)
			clearId && clearTimeout(clearId)
			setClearId(undefined)
		}
	}, [reportRequest])

	return (
		<>
			<GInput
				placeholder="Search for report ..."
				className="w-[400px]"
				icon={CiSearch}
				inputClassName="text-base font-normal"
				id="search"
				value={search}
				onChange={(e) => setSearch(e.target.value)}
			/>
			{reports.length === 0 && <NoDownloadReport />}
			{reports.length > 0 ? (
				reports.map((report, index) => (
					<DownloadReportItem report={report} key={`${report.id}-${index}`} />
				))
			) : (
				<></>
			)}
		</>
	)
}

const NoDownloadReport = () => {
	return (
		<div className="flex items-center justify-center flex-col py-6">
			<div className="flex items-center justify-center flex-col text-center w-[360px]">
				<FolderOpenLineIcon />
				<p className="py-4 font-bold text-t-secondary">No reports</p>
				<p className="text-t-secondary">
					All your generated reports will appear here. You can download them at
					any time.
				</p>
			</div>
		</div>
	)
}

const DownloadReportItem = ({ report }: { report: FormattedReportDTO }) => {
	const {
		assetStore: { demoMode }
	} = useStores()
	return (
		<div className="flex flex-row pt-6 gap-6">
			<div className="flex gap-3 items-center w-[400px]">
				<div className="flex items-center gap-2">
					<ReportIcon report={report} />
					<span className="flex flex-col">
						<ReportTitle report={report} />
						<span className="text-base text-t-secondary truncate max-w-[300px]">
							{report.description}
						</span>
					</span>
				</div>
			</div>
			<div className="flex flex-row">
				<GTooltip
					content={
						demoMode
							? 'This action is disabled when demo data is displayed'
							: ''
					}
				>
					<div>
						<div className="flex items-center justify-center gap-3">
							{formatReportsLinks(report?.links).map(
								({ link, format }: { format: string; link: string },index) => (
									<a
										href={link}
										key={`${link}-${format}-${index}`}
										target="_blank"
										className="flex justify-center gap-x-2 items-center h-[24px] py-3 w-[64px] bg-white rounded-lg hover:bg-gray-50 cursor-pointer border"
									>
										<LiaCloudDownloadAltSolid className="h-4 w-4 text-primary-500" />
										<span className="text-sm font-bold text-t-default">
											{format}
										</span>
									</a>
								)
							)}
							{report.status && report.status !== 'DONE' && (
								<GBadge text={'Generating...'} color="amber" />
							)}
						</div>
						<div className="text-t-default text-sm text-left pt-1">
							<GDateTime date={report.generated_at || report.scheduled_for} />
						</div>
					</div>
				</GTooltip>
			</div>
		</div>
	)
}

export const ReportIcon = ({
	report
}: {
	report: FormattedReportDTO | ReportRequestDTO
}) => {
	const {
		assetStore: { adAccounts, asset }
	} = useStores()
	let adAccount = null
	if ('ad_account_ids' in report && report?.ad_account_ids?.length) {
		const id = report?.ad_account_ids[0] || ''
		adAccount = adAccounts.find((acc) => acc.id === id)
	}
	if (report?.scope_type?.type === 'PPC') {
		adAccount = adAccounts.find((acc) => acc.id === report.scope_type.id)
	}

	if (adAccount?.platform) {
		return (
			<div className="bg-gray-100 rounded-md p-1">
				<div
					className="flex flex-col justify-center items-center"
					data-testid={`ad-account-container-${adAccount?.platform}}`}
				>
					<PlatformIcon
						platform={adAccount?.platform as any}
						className="w-5 h-5"
					/>
				</div>
			</div>
		)
	}
	if (asset) {
		return <WebsiteAvatar website={asset} size={5} />
	}
	return <></>
}

export const ReportTitle = ({
	report,
	className
}: {
	report: FormattedReportDTO | ReportRequestDTO
	className?: string
}) => {
	return (
		<div className="flex items-center gap-2">
			<span className={`${className || 'text-base text-t-default font-bold'}`}>
				{report?.type === 'DOMAIN' && report?.scope_type?.type === 'PPC'
					? 'PPC account summary'
					: report?.type === 'DOMAIN' && 'Website summary'}
				{report.type !== 'DOMAIN' && capitalizeFirstLetter(report.type)} report
			</span>
			{report?.label && (
				<GBadge
					className="py-1 px-2"
					text={truncateString(report.label || '', 20)}
				/>
			)}
		</div>
	)
}
