import { GTooltip } from 'components/basic-blocks'
import { PlatformIconRI } from 'components/platform-icon'
import { RiMoreLine } from 'react-icons/ri'
import { PlatformSelectorProps } from './types'

export const PlatformSelector = (props: PlatformSelectorProps) => {
	const { platform, setPlatform, account } = props

	return (
		<div className="my-4">
			<p className="font-medium text-md text-t-secondary">
				Select advertising platform
			</p>
			<div className="flex justify-between items-center gap-2 my-2 font-medium">
				<div
					onClick={() => setPlatform('GADS')}
					className={`flex flex-1 justify-center items-center py-2 rounded-md cursor-pointer ${
						platform === 'GADS' ? 'bg-gray-200 text-t-dark' : 'text-t-secondary'
					}`}
				>
					<PlatformIconRI
						platform="GADS"
						className="w-6 h-6 mr-2 font-semibold"
					/>
					<div>Google Ads</div>
				</div>
				{account?.billing?.subscription?.plan?.tier === 'legacy' ? (
					<>
						<GTooltip content={'This option is unavailable for legacy plans'}>
							<div
								className={`flex flex-1 justify-center items-center py-2 rounded-md cursor-pointer ${
									platform === 'other platforms'
										? 'bg-gray-200 text-t-dark'
										: 'text-t-secondary'
								}`}
							>
								<PlatformIconRI platform="FADS" className="w-6 h-6 mr-2" />
								<div>Meta Ads</div>
							</div>
						</GTooltip>
						<GTooltip content={'This option is unavailable for legacy plans'}>
							<div
								className={`flex flex-1 justify-center items-center py-2 rounded-md cursor-pointer ${
									platform === 'other platforms'
										? 'bg-gray-200 text-t-dark'
										: 'text-t-secondary'
								}`}
							>
								<PlatformIconRI platform="BADS" className="w-6 h-6 mr-2" />
								<div>Bing Ads</div>
							</div>
						</GTooltip>
					</>
				) : (
					<>
						<div
							onClick={() => setPlatform('FADS')}
							className={`flex flex-1 justify-center items-center py-2 rounded-md cursor-pointer ${
								platform === 'FADS'
									? 'bg-gray-200 text-t-dark'
									: 'text-t-secondary'
							}`}
						>
							<PlatformIconRI platform="FADS" className="w-6 h-6 mr-2" />
							<div>Meta Ads</div>
						</div>
						<div
							onClick={() => setPlatform('BADS')}
							className={`flex flex-1 justify-center items-center py-2 rounded-md cursor-pointer ${
								platform === 'BADS'
									? 'bg-gray-200 text-t-dark'
									: 'text-t-secondary'
							}`}
						>
							<PlatformIconRI platform="BADS" className="w-6 h-6 mr-2" />
							<div>Bing Ads</div>
						</div>
					</>
				)}
				<div className="flex flex-1 justify-center">
					<GTooltip content={'Coming soon'}>
						<div
							className={`flex flex-1 justify-center items-center py-2 rounded-md cursor-pointer ${
								platform === 'other platforms'
									? 'bg-gray-200 text-t-dark'
									: 'text-t-secondary'
							}`}
						>
							<RiMoreLine className="w-6 h-6 mr-2 font-semibold" />
							<div>Other platforms</div>
						</div>
					</GTooltip>
				</div>
			</div>
		</div>
	)
}
