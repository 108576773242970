import { forwardRef, useEffect, useState } from 'react'
import { useTable } from 'react-table'
import { TableVirtuoso } from 'react-virtuoso'
import { classNames } from '../../../utils'
import { NewTableBody } from './table-body/new-table-body'
import { NewTableHeader } from './table-header/new-table-header'
import { GAdminTableProps } from './types'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import {
	AiOutlineArrowLeft,
	AiOutlineArrowRight,
	AiOutlineCloudDownload
} from 'react-icons/ai'
import { BiRefresh } from 'react-icons/bi'
import { planTierColorMapper } from 'utils/icons'
import { formatCurrency, formatPriceInterval } from 'utils'

import { GBadge } from 'components/basic-blocks/g-badge'

export function GAdminDashboardTable<T>(props: GAdminTableProps<T>) {
	const {
		columns,
		data,
		showHeader = true,
		emptyState,
		title,
		setSkip,
		skip,
		totalCount,
		refreshAction,
		isDownloadable,
		downloadAction,
		actionButtonsPlacement = 'top',
		potentialMRR,
		MRRBreakdownByPlan
	} = props

	const [rowData, setRowData] = useState<Array<any>>([])
	const [columnData, setColumnData] = useState<Array<any>>([])
	const [expanded, setExpanded] = useState(false)

	const getTableHeader = () => {
		return columns.map((column, index) => {
			return {
				accessor: `col${index + 1}`
			}
		})
	}
	const handleData = () => {
		const columnData = []
		let singleData = {}
		for (let i = 0; i < data.length; i++) {
			for (let j = 0; j < columns.length; j++) {
				singleData = {
					...singleData,
					[`col${j + 1}`]: data[i]
				}
			}
			columnData.push(singleData)
			singleData = {}
		}
		return columnData
	}

	useEffect(() => {
		if (data.length > 0) {
			setRowData(handleData())
			setColumnData(getTableHeader())
		} else {
			setRowData([])
		}
	}, [data, columns, skip])

	const tableInstance = useTable({ columns: columnData, data: rowData })
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		tableInstance

	return (
		<div className="bg-white rounded w-full">
			<div className=" w-full flex justify-between py-4 items-center">
				<span className="text-t-default font-bold">{title}</span>
				<div className="flex justify-between items-center">
					<button
						className="flex border-solid border-2 border-[#E5E7EB] items-center justify-center rounded-md px-2 mx-2"
						onClick={refreshAction}
					>
						<BiRefresh />
						<div className="font-bold text-t-default mx-1">Refresh</div>
					</button>
					{isDownloadable && (
						<button
							className="flex border-solid border-2 border-[#E5E7EB] items-center justify-center rounded-md px-2 mx-2"
							onClick={downloadAction}
						>
							<AiOutlineCloudDownload />
							<div className="font-bold text-t-default mx-1">Download</div>
						</button>
					)}

					{actionButtonsPlacement === 'top' && (
						<>
							{Boolean(totalCount) && totalCount && (
								<>
									<button
										disabled={skip === 0}
										className={`flex border-solid border-2 border-[#E5E7EB] ${
											skip === 0 && 't-color-tab-border'
										} items-center justify-center rounded-md px-2 mx-2`}
										onClick={() => setSkip(skip - 50)}
									>
										<AiOutlineArrowLeft />
										<div className="font-bold text-t-default mx-1">
											Previous
										</div>
									</button>

									<button
										disabled={totalCount - (skip + 50) <= 0}
										className={`flex border-solid border-2 border-[#E5E7EB] ${
											totalCount - (skip + 50) <= 0 && 't-color-tab-border'
										} } items-center justify-center rounded-md px-2 mx-2`}
										onClick={() =>
											setSkip(
												totalCount - (skip + 50) <= 0
													? totalCount - 50
													: skip + 50
											)
										}
									>
										<AiOutlineArrowRight />
										<div className="font-bold text-t-default mx-1">Next</div>
									</button>
								</>
							)}
						</>
					)}
				</div>
			</div>
			<div className="w-full flex items-center justify-start">
				{potentialMRR && (
					<div className="w-full">
						{potentialMRR}
						{MRRBreakdownByPlan && (
							<>
								<div className="flex justify-end overflow-hidden">
									<div className="flex items-center justify-center cursor-pointer p-1 hover:bg-slate-100 rounded-full h-fit">
										<div onClick={() => setExpanded(!expanded)}>
											{expanded ? (
												<RiArrowUpSLine className="text-t-secondary w-6 h-6 " />
											) : (
												<RiArrowDownSLine className="text-t-secondary w-6 h-6" />
											)}
										</div>
									</div>
								</div>
								{expanded && (
									<div className="h-fit pb-4">
										<table className="min-w-full table-auto">
											<thead className="bg-gray-50 sticky top-0 z-5 text-t-secondary">
												<tr>
													<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
													<th className="px-4 py-3 text-right text-sm font-semibold text-t-secondary">
														Plan price
													</th>
													<th className="px-4 py-3 text-right text-sm font-semibold text-t-secondary">
														No. of trials
													</th>
													<th className="px-4 py-3 text-right text-sm font-semibold text-t-secondary">
														SubTotals
													</th>
												</tr>
											</thead>
											<tbody className="divide-y divide-gray-100">
												{MRRBreakdownByPlan.map((item: any) => (
													<tr>
														<td className="px-6 py-4 text-left text-sm font-medium text-gray-500">
															<div className="flex items-center space-x-2">
																{planTierColorMapper(item.tier, 3)}
																<div className="text-md text-t-default">
																	{item.plan}
																</div>
															</div>
														</td>
														<td className="px-4 py-4 text-right text-md font-semibold text-t-secondary">
															<div className="flex justify-end items-center space-x-2">
																<GBadge
																	text={
																		<span>
																			<span>
																				{formatCurrency(item?.price)}{' '}
																				{formatPriceInterval(
																					item?.interval,
																					item?.interval_count
																				)}
																			</span>
																		</span>
																	}
																	color="green"
																/>
															</div>
														</td>
														<td className="px-4 py-4 text-right text-md font-semibold text-t-secondary">
															<div className="flex justify-end items-center space-x-2">
																<GBadge text={item.users} color="purple" />
															</div>
														</td>
														<td className="px-4 py-4 text-right text-md font-semibold text-t-secondary">
															<div className="flex justify-end items-center space-x-2">
																<GBadge
																	text={formatCurrency(item.potentialMRR)}
																	color="green"
																/>
															</div>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								)}
							</>
						)}
					</div>
				)}
			</div>
			<div
				className={`inline-block min-w-full align-middle overflow-x-auto ${
					expanded && 'blur-sm'
				}`}
			>
				{rows?.length > 0 && (
					<TableVirtuoso
						useWindowScroll
						totalCount={rows.length}
						components={{
							Table: ({ style, ...props }) => (
								<table
									className="min-w-full table-auto"
									{...getTableProps()}
									{...props}
									style={{ ...style, tableLayout: 'fixed' }}
								/>
							),
							TableBody: forwardRef(({ style, ...props }, ref) => (
								<tbody
									className={classNames(
										showHeader ? 'divide-y divide-gray-100' : ''
									)}
									{...getTableBodyProps()}
									{...props}
									ref={ref}
								/>
							)),
							TableHead: forwardRef(({ style, ...props }, ref) => (
								<thead
									className="bg-gray-50 sticky top-0 z-5 text-t-secondary"
									{...props}
									ref={ref}
								/>
							)),
							TableRow: (props) => {
								const index = props['data-index']
								const row = rows[index]
								return (
									<tr
										{...props}
										{...row.getRowProps()}
										className="text-t-default"
									/>
								)
							}
						}}
						fixedHeaderContent={() => {
							if (showHeader) {
								return headerGroups.map((headerGroup) => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column, index) => (
											<NewTableHeader
												key={index}
												column={columns[index]}
												reactColumn={column}
											/>
										))}
									</tr>
								))
							}
						}}
						itemContent={(index) => {
							const row = rows[index]
							prepareRow(row)
							return row.cells.map((cell, index) => {
								return (
									<NewTableBody
										key={index}
										cell={cell}
										column={columns[index]}
									/>
								)
							})
						}}
					/>
				)}
				{data?.length === 0 &&
					(emptyState || (
						<div className="text-center py-4">
							<span className="text-sm text-gray-500">
								Nothing to show here
							</span>
						</div>
					))}
				{actionButtonsPlacement === 'bottom' && (
					<div className=" w-full flex justify-end py-4 items-center">
						{Boolean(totalCount) && totalCount && (
							<>
								<button
									disabled={skip === 0}
									className={`flex border-solid border-2 border-[#E5E7EB] ${
										skip === 0 && 't-color-tab-border'
									} items-center justify-center rounded-md px-2 mx-2`}
									onClick={() => setSkip(skip - 50)}
								>
									<AiOutlineArrowLeft />
									<div className="font-bold text-t-default mx-1">Previous</div>
								</button>

								<button
									disabled={totalCount - (skip + 50) <= 0}
									className={`flex border-solid border-2 border-[#E5E7EB] ${
										totalCount - (skip + 50) <= 0 && 't-color-tab-border'
									} } items-center justify-center rounded-md px-2 mx-2`}
									onClick={() =>
										setSkip(
											totalCount - (skip + 50) <= 0
												? totalCount - 50
												: skip + 50
										)
									}
								>
									<AiOutlineArrowRight />
									<div className="font-bold text-t-default mx-1">Next</div>
								</button>
							</>
						)}
					</div>
				)}
			</div>
		</div>
	)
}
