import { useState } from 'react'
import { useQuery } from 'react-query'

import { GDateRange, GLoading } from 'components/basic-blocks'
import { AdminStats } from 'services/admin/stats'
import { PageTitle } from 'utils/page-title'
import { AdminDateFilterRangeType } from '../types'
import { formatData, formatPercentages, formatTimelineData } from './utils'
import { MetricsView } from './metrics-view'

export const AdminMetricsPage = () => {
	const [dateFilterRange, setDateFilterRange] =
		useState<AdminDateFilterRangeType>({})

	const cacheKey = (key: string) => [
		key,
		dateFilterRange.dateFrom,
		dateFilterRange.dateTo
	]
	const isReady = dateFilterRange.dateFrom && dateFilterRange.dateTo

	const { data, isFetching } = useQuery(
		cacheKey('admin-metrics-stats'),
		() =>
			AdminStats.getAdminMetricsStats({
				...dateFilterRange
			}),
		{ enabled: !!isReady }
	)
	const { data: timelineData, isFetching: timelineIsFetching } = useQuery(
		cacheKey('admin-metrics-timeline'),
		() =>
			AdminStats.getAdminMetricsTimeline({
				...dateFilterRange
			}),
		{ enabled: !!isReady }
	)

	const isLoading = isFetching || !data || timelineIsFetching || !timelineData

	return (
		<>
			<PageTitle pageName="Metrics" />
			<div className="w-full h-full p-8">
				<div className="mb-6">
					<h2 className="text-t-heading text-3xl font-bold">Metrics</h2>
					<div className="flex items-end justify-between">
						<span className="text-lg font-medium text-t-default">
							Business metrics regarding customer acquisition, churn and other
							workspace stats.
						</span>

						<GDateRange
							align="right"
							minDate={new Date('2023-02-01')}
							onApply={(e) =>
								setDateFilterRange({
									dateFrom: e.from.getTime(),
									dateTo: e.to.getTime()
								})
							}
							loading={false}
						/>
					</div>
				</div>

				{isLoading || !data || !timelineData ? (
					<GLoading />
				) : (
					<MetricsView
						data={formatData(data.current)}
						timelineData={formatTimelineData(timelineData)}
						percentages={formatPercentages(data.current, data.previous)}
					/>
				)}
			</div>
		</>
	)
}
