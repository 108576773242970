import { BsDot } from 'react-icons/bs'
import { ImCross } from 'react-icons/im'

export const AffiliateLegal = () => {
	return (
		<div className="max-w-3xl p-6 bg-white rounded-md sm:rounded-lg mb-6 border border-t-border-light">
			<p className="text-lg font-bold">Cash and More with ClickGUARD </p>
			<div className="flex flex-col justify-center gap-y-4 mt-6">
				<div>
					<p className="text-base font-bold pb-0.5">1. Eligibility:</p>
					<p className="flex items-baseline gap-x-2">
						<BsDot /> Affiliates must be current customers of ClickGUARD or
						approved non-customers.
					</p>
					<p className="flex items-baseline gap-x-2">
						<BsDot /> Affiliates must be in compliance with all applicable laws
						and regulations.
					</p>
				</div>
				<div>
					<p className="text-base font-bold pb-0.5">
						2. Commission Structure::
					</p>
					<p className="flex items-baseline gap-x-2">
						<BsDot /> Affiliates will earn a 20% commission on all referred
						sales for the lifetime duration of the referred account.
					</p>
					<p className="flex items-baseline gap-x-2">
						<BsDot /> Commissions will be calculated based on the gross sale
						amount, excluding chargebacks.
					</p>
				</div>
				<div>
					<p className="text-base font-bold pb-0.5">
						3. Per-Trial Sign Up Rewards:
					</p>
					<p className="flex items-baseline gap-x-2">
						<BsDot /> Earn $7 for every valid trial sign-up, even if it doesn't
						convert to a purchase. We define a 'valid' trial as one where the
						user connects any of their Ad Accounts and logs in at least once
						during the trial period.
					</p>
				</div>
				<div>
					<p className="text-base font-bold pb-0.5">4. Cookie Lifespan: :</p>
					<p className="flex items-baseline gap-x-2">
						<BsDot /> Referral cookies have a lifespan of 60 days. Commissions
						will only be credited for sales made within this timeframe.
					</p>
				</div>
				<div>
					<p className="text-base font-bold pb-0.5">
						5. Special Bonus for High Performers:
					</p>
					<p className="flex items-baseline gap-x-2">
						<BsDot /> Go above and beyond, and we'll recognize your efforts.
						Affiliates who bring in more than $10,000 in annual revenue will
						receive a one-off $1000 bonus. For affiliates with customers whose
						ad spend exceeds $500,000, we've got an additional reward waiting
						for you!
					</p>
				</div>
				<div>
					<p className="text-base font-bold pb-0.5">6. Payouts:</p>
					<p className="flex items-baseline gap-x-2">
						<BsDot /> Payouts will be made on a monthly basis via Paypal, and
						sent out on the 18th of every month.
					</p>
					<p className="flex items-baseline gap-x-2">
						<BsDot /> Affiliates are responsible for providing accurate and
						up-to-date payment information.
					</p>
				</div>
				<div>
					<p className="text-base font-bold pb-0.5">7. Limitations::</p>
					<p className="flex items-baseline gap-x-2">
						<BsDot /> You are welcome to promote ClickGUARD with any transparent
						strategy that aligns with our core values, except for the following:
					</p>
					<div className="p-4">
						<p className="flex items-baseline gap-x-2">
							<ImCross className="text-red-500" />
							Run online paid ads on behalf of ClickGUARD that drive direct
							traffic to the ClickGUARD website: Google ads, Meta ads, Bing ads,
							etc.*
						</p>
						<p className="flex items-baseline gap-x-2">
							<ImCross className="text-red-500" />
							Modify ClickGUARD’s logos, banners, or colors.
						</p>
						<p className="flex items-baseline gap-x-2">
							<ImCross className="text-red-500" />
							Offer unofficial discounts or coupons
						</p>
						<p className="flex items-baseline gap-x-2">
							<ImCross className="text-red-500" />
							Misrepresent our name and/or product to attract leads
						</p>
						<p className="flex items-baseline gap-x-2">
							<ImCross className="text-red-500" />
							Represent or speak on behalf of ClickGUARD. *
						</p>
						<p className="text-base pt-4">
							Note: You can leverage online ads as long as you drive traffic to
							your own online channels such as a website, YouTube channel, etc.
							Remember, you are not to utilize the ClickGUARD brand name or the
							term “click fraud” on your ad campaigns.
						</p>
					</div>
				</div>
				<div>
					<p className="text-base font-bold pb-0.5">8. Termination:</p>
					<p className="flex items-baseline gap-x-2">
						<BsDot /> ClickGUARD reserves the right to terminate the affiliate
						relationship at any time for violation of these terms and
						conditions.
					</p>
					<p className="flex items-baseline gap-x-2">
						<BsDot /> Termination may result in the forfeiture of unpaid
						commissions.
					</p>
				</div>
				<div>
					<p className="text-base font-bold pb-0.5">9. Modifications:</p>
					<p className="flex items-baseline gap-x-2">
						<BsDot /> ClickGUARD reserves the right to modify the terms and
						conditions of the Affiliate Program at any time.
					</p>
					<p className="flex items-baseline gap-x-2">
						<BsDot /> Affiliates will be notified of any changes, and continued
						participation implies acceptance of the modified terms.
					</p>
				</div>
				<div>
					<p>
						By participating in the ClickGUARD Affiliate Program, you
						acknowledge that you have read, understood, and agreed to these
						terms and conditions.
					</p>
				</div>
			</div>
		</div>
	)
}
